import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router/index.js";
import axios from "axios";
import store from "./store/index.js";
import mitt from "mitt";
import cloneDeep from "lodash/cloneDeep";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { localInsightLightTheme } from "@/themes/LI_light_theme.js";

// declare these
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "light",
    themes: {
      localInsightLightTheme,
      light: {
        variables: {
          "font-family": "Inter, sans-serif",
        },
      },
    },
  },
});
const app = createApp(App);

// global methods / props
app.config.globalProperties.$cloneDeep = cloneDeep;
app.config.globalProperties.emit = mitt();
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$axios.defaults.withCredentials = true;
app.config.globalProperties.$axios.defaults.baseURL =
  location.protocol + "//" + process.env.VUE_APP_API_PATH;

//allow API call mocking in dev
async function enableMocking() {
  if (!["dev", "stg"].includes(process.env.VUE_APP_ENVIRONMENT)) {
    return; //bypass mocking
  }

  const { mockingWorker } = await import("./mocks/browser");

  // resolves promise once the Mocking Service Worker is up and ready to intercept requests.
  return mockingWorker.start({
    //routes not defined in mock handlers to default to real backend calls
    onUnhandledRequest() {
      return;
    },
  });
}

// add these to the app
app.use(router);
app.use(store);
app.use(vuetify);

// Log the user out if they try to access an API they don't have access to.
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    //return enforced timeout/cancelled requests to caller
    if (
      Object.prototype.hasOwnProperty.call(error, "code") &&
      error.code === "ECONNABORTED"
    ) {
      return Promise.reject(error);
    }

    //return script initiated abortController cancellation request to caller
    if (error.code === "ERR_CANCELED") {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      store.commit("resetConfig");
      axios
        .post("/logout")
        .catch(function (error) {
          // it would appear there is something wrong...probably an invalid bearer token
          console.error("(logout) invalid user details and or token" + error);
        })
        .then(function () {
          // clear the bearer cookie and reload the app
          document.cookie =
            "BEARER_TOKEN=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          router.go();
        });
    } else {
      return Promise.reject(error);
    }
  },
);

// open the portal!
enableMocking().then(() => {
  app.mount("#app");
});
