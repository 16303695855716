<template>
  <v-card-text ref="mainContainer" class="text-left mb-5 main-container">
    <v-row>
      <!-- Navigation Column -->
      <v-col cols="3">
        <v-list style="background-color: #fcfcfc00" class="sticky-navigation">
          <!-- Overview -->
          <v-list-item>
            <v-list-item-title>
              <a
                ref="overviewLink"
                href="#overView"
                @click.prevent="scrollToSection('overView')"
                :class="[
                  'non-indented',
                  { 'active-nav': activeSection === 'overView' },
                ]"
              >
                Overview
              </a>
            </v-list-item-title>
          </v-list-item>

          <!-- Base URL group -->
          <v-list-item>
            <v-list-item-title>
              <a
                href="#baseUrl"
                @click.prevent="scrollToSection('baseUrl')"
                :class="[
                  'non-indented',
                  { 'active-nav': activeSection === 'baseUrl' },
                ]"
              >
                Base URL
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-4">
            <v-list-item-title>
              <a
                href="#versioning"
                @click.prevent="scrollToSection('versioning')"
                :class="[
                  'indented',
                  { 'active-nav': activeSection === 'versioning' },
                ]"
              >
                Versioning
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-4">
            <v-list-item-title>
              <a
                href="#rateLimits"
                @click.prevent="scrollToSection('rateLimits')"
                :class="[
                  'indented',
                  { 'active-nav': activeSection === 'rateLimits' },
                ]"
              >
                Rate Limits
              </a>
            </v-list-item-title>
          </v-list-item>

          <!-- Authentication group -->
          <v-list-item>
            <v-list-item-title>
              <a
                href="#authentication"
                @click.prevent="scrollToSection('authentication')"
                :class="[
                  'non-indented',
                  { 'active-nav': activeSection === 'authentication' },
                ]"
              >
                Authentication
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-4">
            <v-list-item-title>
              <a
                href="#headers"
                @click.prevent="scrollToSection('headers')"
                :class="[
                  'indented',
                  { 'active-nav': activeSection === 'headers' },
                ]"
              >
                Headers
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-4">
            <v-list-item-title>
              <a
                href="#obtainingAnAPIKey"
                @click.prevent="scrollToSection('obtainingAnAPIKey')"
                :class="[
                  'indented',
                  { 'active-nav': activeSection === 'obtainingAnAPIKey' },
                ]"
              >
                Obtaining an API Key
              </a>
            </v-list-item-title>
          </v-list-item>

          <!-- Endpoints group -->
          <v-list-item>
            <v-list-item-title>
              <a
                href="#endpoints"
                @click.prevent="scrollToSection('endpoints')"
                :class="[
                  'non-indented',
                  { 'active-nav': activeSection === 'endpoints' },
                ]"
              >
                Endpoints
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-4">
            <v-list-item-title>
              <a
                href="#retrieveIndicatorData"
                @click.prevent="scrollToSection('retrieveIndicatorData')"
                :class="[
                  'indented',
                  { 'active-nav': activeSection === 'retrieveIndicatorData' },
                ]"
              >
                Retrieve indicator data
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-8">
            <v-list-item-title>
              <a
                href="#requestBodyParameters"
                @click.prevent="scrollToSection('requestBodyParameters')"
                :class="[
                  'indented',
                  { 'active-nav': activeSection === 'requestBodyParameters' },
                ]"
              >
                Request Body Parameters
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-8">
            <v-list-item-title>
              <a
                href="#exampleRequest"
                @click.prevent="scrollToSection('exampleRequest')"
                :class="[
                  'indented',
                  { 'active-nav': activeSection === 'exampleRequest' },
                ]"
              >
                Example Request
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-8">
            <v-list-item-title>
              <a
                href="#responseStructure"
                @click.prevent="scrollToSection('responseStructure')"
                :class="[
                  'indented',
                  { 'active-nav': activeSection === 'responseStructure' },
                ]"
              >
                Response Structure
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="ml-8">
            <v-list-item-title>
              <a
                href="#errorHandling"
                @click.prevent="scrollToSection('errorHandling')"
                :class="[
                  'indented',
                  { 'active-nav': activeSection === 'errorHandling' },
                ]"
              >
                Error Handling
              </a>
            </v-list-item-title>
          </v-list-item>

          <!-- Support -->
          <v-list-item>
            <v-list-item-title>
              <a
                href="#support"
                @click.prevent="scrollToSection('support')"
                :class="[
                  'non-indented',
                  { 'active-nav': activeSection === 'support' },
                ]"
              >
                Support
              </a>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>

      <!-- Content Column -->
      <v-col class="pa-0" cols="9">
        <!-- Overview -->
        <v-card flat tile class="card-border rounded" tabindex="0">
          <v-card-item>
            <!-- Note the added id and "section" class -->
            <v-card-title
              ref="overView"
              id="overView"
              class="section-header section"
              v-html="chosenBasics.overview.title"
            />
          </v-card-item>
          <v-card-text class="text" v-html="chosenBasics.overview.text_1" />
        </v-card>

        <!-- Base URL group -->
        <v-card flat tile class="card-border rounded mt-5" tabindex="0">
          <v-card-item>
            <v-card-title
              ref="baseUrl"
              id="baseUrl"
              class="section-header section"
              v-html="chosenBasics.baseURL.title"
            />
          </v-card-item>
          <v-card-text class="text">
            {{ chosenBasics.baseURL.text_1 }}
            <div class="url-box mt-3">
              {{ chosenBasics.baseURL.text_2 }}
            </div>
          </v-card-text>
          <v-card-item>
            <v-card-title
              ref="versioning"
              id="versioning"
              class="section-subheader section"
              v-html="chosenBasics.versioning.title"
            />
          </v-card-item>
          <v-card-text class="text" v-html="chosenBasics.versioning.text_1" />
          <v-card-item>
            <v-card-title
              ref="rateLimits"
              id="rateLimits"
              class="section-subheader section"
              v-html="chosenBasics.rateLimits.title"
            />
          </v-card-item>
          <v-card-text class="text" v-html="chosenBasics.rateLimits.text_1" />
        </v-card>

        <!-- Authentication -->
        <v-card flat tile class="card-border rounded mt-5" tabindex="0">
          <v-card-item>
            <v-card-title
              ref="authentication"
              id="authentication"
              class="section-header section"
              v-html="chosenBasics.authentication.title"
            />
          </v-card-item>
          <v-card-text
            class="text pb-0"
            v-html="chosenBasics.authentication.text_1"
          />
          <v-card-text class="pt-0">
            <CodeBlock
              :code="chosenBasics.authentication.text_2"
              :disable="true"
            />
          </v-card-text>
          <v-card-item>
            <v-card-title
              ref="headers"
              id="headers"
              class="section-subsubheader section"
              v-html="chosenBasics.headers.title"
            />
          </v-card-item>
          <v-card-text class="text pb-0" v-html="chosenBasics.headers.text_1" />
          <v-card-item>
            <v-table class="border" density="compact">
              <thead>
                <tr>
                  <th
                    v-for="(header, index) in tableBuilder(
                      chosenBasics.headers.table,
                    ).headers"
                    :key="index"
                    class="text-left table-header"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, rowIndex) in tableBuilder(
                    chosenBasics.headers.table,
                  ).rows"
                  :key="rowIndex"
                >
                  <td
                    v-for="(cell, cellIndex) in row"
                    :key="cellIndex"
                    v-html="cell"
                    class="table-data"
                  />
                </tr>
              </tbody>
            </v-table>
          </v-card-item>
          <v-card-item>
            <v-card-title
              ref="obtainingAnAPIKey"
              id="obtainingAnAPIKey"
              class="section-subheader section"
              v-html="chosenBasics.obtainingAnAPIKey.title"
            />
          </v-card-item>
          <v-card-text
            class="text"
            v-html="chosenBasics.obtainingAnAPIKey.text_1"
          />
        </v-card>

        <!-- Endpoints -->
        <v-card flat tile class="card-border rounded mt-5" tabindex="0">
          <v-card-item>
            <v-card-title
              ref="endpoints"
              id="endpoints"
              class="section-header section"
              v-html="chosenBasics.endpoints.title"
            />
          </v-card-item>
          <v-card-item>
            <v-card-title
              ref="retrieveIndicatorData"
              id="retrieveIndicatorData"
              class="section-subheader section"
              v-html="chosenDoc.endpoints.text_1"
            />
          </v-card-item>
          <v-card-text class="text pb-0" v-html="chosenDoc.endpoints.text_2" />
          <v-card-text class="pt-1">
            <v-chip size="small" class="chip" variant="flat">{{
              chosenDoc.endpoints.text_3
            }}</v-chip>
            <span v-html="chosenDoc.endpoints.text_4"></span>
          </v-card-text>
          <v-card-item>
            <v-card-title
              ref="requestBodyParameters"
              id="requestBodyParameters"
              class="section-subsubheader section"
              v-html="chosenBasics.requestBodyParameters.title"
            />
          </v-card-item>
          <v-card-text
            class="text pb-0"
            v-html="chosenBasics.requestBodyParameters.text_1"
          />
          <v-card-item>
            <v-table class="border" density="compact">
              <thead>
                <tr>
                  <th
                    v-for="(header, index) in tableBuilder(
                      chosenDoc.requestBodyParameters.table,
                    ).headers"
                    :key="index"
                    class="text-left table-header"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, rowIndex) in tableBuilder(
                    chosenDoc.requestBodyParameters.table,
                  ).rows"
                  :key="rowIndex"
                >
                  <td
                    v-for="(cell, cellIndex) in row"
                    :key="cellIndex"
                    v-html="cell"
                    class="table-data"
                  />
                </tr>
              </tbody>
            </v-table>
          </v-card-item>
          <v-card-item>
            <v-card-title
              ref="exampleRequest"
              id="exampleRequest"
              class="section-subsubheader section"
              v-html="chosenBasics.exampleRequest.title"
            />
          </v-card-item>
          <v-card-text
            class="text pb-0"
            v-html="chosenBasics.exampleRequest.text_1"
          />
          <v-card-text class="pt-0">
            <CodeBlock
              :code="chosenDoc.exampleRequest.text_2"
              :disable="true"
            />
          </v-card-text>
          <v-card-item>
            <v-card-title
              ref="responseStructure"
              id="responseStructure"
              class="section-subsubheader section"
              v-html="chosenBasics.responseStructure.title"
            />
          </v-card-item>
          <v-card-text
            class="text pb-0"
            v-html="chosenBasics.responseStructure.text_1"
          />
          <v-card-text class="pt-0">
            <CodeBlock
              initialLanguage="JSON"
              :code="chosenDoc.responseStructure.text_2"
              :disable="true"
            />
          </v-card-text>
          <v-card-item>
            <v-card-title
              ref="errorHandling"
              id="errorHandling"
              class="section-subsubheader section"
              v-html="chosenBasics.errorHandling.title"
            />
          </v-card-item>
          <v-card-text
            class="text pb-0"
            v-html="chosenBasics.errorHandling.text_1"
          />
          <v-card-item>
            <v-table class="border" density="compact">
              <thead>
                <tr>
                  <th
                    v-for="(header, index) in tableBuilder(
                      chosenBasics.errorHandling.table,
                    ).headers"
                    :key="index"
                    class="text-left table-header"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, rowIndex) in tableBuilder(
                    chosenBasics.errorHandling.table,
                  ).rows"
                  :key="rowIndex"
                >
                  <td
                    v-for="(cell, cellIndex) in row"
                    :key="cellIndex"
                    v-html="cell"
                    class="table-data"
                  />
                </tr>
              </tbody>
            </v-table>
          </v-card-item>
        </v-card>

        <!-- Support -->
        <v-card flat tile class="card-border rounded mt-5 mb-5" tabindex="0">
          <v-card-item>
            <v-card-title
              ref="support"
              id="support"
              class="section-header section"
              v-html="chosenBasics.support.title"
            />
          </v-card-item>
          <v-card-text class="text" v-html="chosenBasics.support.text_1" />
        </v-card>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
// import ALL documentation schemas
import BasicsV1 from "@/dataStoreDocumentation/BasicsV1";
import StandardGetV1 from "@/dataStoreDocumentation/StandardGetV1";
// for displayiung code
import CodeBlock from "@/components/CodeBlock.vue";
export default {
  name: "DatastoreDocumentation",
  data: () => ({
    basicInfoV1: BasicsV1,
    standardGetInfoV1: StandardGetV1,
    activeSection: "",
    manualActive: false,
    firstInteraction: false,
  }),
  props: {
    basicInfo: {
      type: String,
      required: false,
      default: "basicInfoV1",
    },
    docInfo: {
      type: String,
      required: false,
      default: "standardGetInfoV1",
    },
  },
  computed: {
    chosenBasics() {
      return this[this.basicInfo];
    },
    chosenDoc() {
      return this[this.docInfo];
    },
  },
  components: {
    CodeBlock,
  },
  methods: {
    addScrollListener() {
      const el = this.$refs.mainContainer.$el || this.$refs.mainContainer;
      el.addEventListener("scroll", this.handleScroll);
    },
    handleScroll() {
      if (!this.firstInteraction) {
        this.removeScrollListener();
      }
    },
    removeScrollListener() {
      const el = this.$refs.mainContainer.$el || this.$refs.mainContainer;
      el.removeEventListener("scroll", this.handleScroll);
      this.firstInteraction = true;
    },
    scrollToSection(section) {
      this.activeSection = section;
      this.manualActive = true;
      if (!this.firstInteraction) {
        this.removeScrollListener();
      }
      setTimeout(() => {
        this.manualActive = false;
      }, 1000);

      // Scroll smoothly to the target section.
      let element = this.$refs[section];
      if (element) {
        if (element.$el) {
          element = element.$el;
        }
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    tableBuilder(info) {
      // Extract header columns using a regex pattern.
      const headerRegex = /^col_(\d+)_header$/;
      const headerColumns = [];
      for (const key in info) {
        const match = key.match(headerRegex);
        if (match) {
          headerColumns.push({
            col: parseInt(match[1], 10),
            value: info[key],
          });
        }
      }
      headerColumns.sort((a, b) => a.col - b.col);
      const headers = headerColumns.map((item) => item.value);
      const rowRegex = /^col_(\d+)_row_(\d+)$/;
      const rowNumbersSet = new Set();
      for (const key in info) {
        const match = key.match(rowRegex);
        if (match) {
          rowNumbersSet.add(parseInt(match[2], 10));
        }
      }
      const sortedRowNumbers = Array.from(rowNumbersSet).sort((a, b) => a - b);
      const rows = sortedRowNumbers.map((rowNum) => {
        return headerColumns.map(({ col }) => info[`col_${col}_row_${rowNum}`]);
      });
      return { headers, rows };
    },
  },
  mounted() {
    // add this..this is so when the user first looks at the page the wrong navigation item isn't highlighted ('firstInteraction' flag)
    this.addScrollListener();

    // set this, this is for the scrolling navigation highlights
    const options = {
      root: null,
      threshold: 0.25,
    };
    // set this, this is for the scrolling navigation highlights
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // Only update activeSection from the observer if the user hasn't clicked recently.
        if (
          !this.manualActive &&
          this.firstInteraction &&
          entry.isIntersecting
        ) {
          this.activeSection = entry.target.id;
        }
      });
    }, options);

    // Observe all sections (elements with class "section"), this is for the scrolling navigation highlights
    const sections = document.querySelectorAll(".section");
    sections.forEach((section) => {
      observer.observe(section);
    });
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

.non-indented {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  margin-left: 2px;
}

.indented {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  margin-left: 2px;
}

.active-nav {
  font-weight: 500;
  color: rgba(14, 91, 153, 1);
}

.section-header {
  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
  vertical-align: middle;
  color: rgba(77, 77, 77, 1);
}

.section-subheader {
  font-family: Inter;
  font-weight: 600;
  font-size: 18px;
  vertical-align: middle;
  color: rgba(77, 77, 77, 1);
}

.section-subsubheader {
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  vertical-align: middle;
  color: rgba(77, 77, 77, 1);
}

.text {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  vertical-align: middle;
  color: rgba(77, 77, 77, 1);
}

::v-deep .keys {
  font-family: Inconsolata;
  font-weight: 400;
  font-size: 14px;
  color: rgba(38, 89, 40, 1);
}

.card-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.url-box {
  width: 730px;
  height: 48px;
  gap: 10px;
  border-width: 1px;
  border-radius: 4px;
  padding: 16px;
  background: rgba(249, 249, 249, 1);
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-family: Inconsolata;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0%;
}

.table-headers {
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
}

.table-data {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
}

.chip {
  background: rgba(191, 224, 248, 1);
  color: rgba(9, 63, 107, 1);
  margin-right: 4px;
}

.main-container {
  overflow-y: auto;
  height: calc(100vh - 210px);
}

.sticky-navigation {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}
</style>
