<template>
  <v-card-actions class="pa-0">
    <v-text-field
      v-model="computedFilters.searchString"
      variant="outlined"
      density="compact"
      rounded="0"
      hide-details
      style="background-color: white"
      label="Search indicators"
      @keydown.enter="() => this.$emit('search')"
      autocomplete="off"
    ></v-text-field>
    <v-btn
      color="primary"
      tile
      variant="elevated"
      @click="() => this.$emit('search')"
    >
      search
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name: "IndicatorSearch",
  data() {
    return {};
  },
  props: ["filters"],
  computed: {
    computedFilters: {
      get() {
        return this.filters;
      },
      set(val) {
        this.$emit("update:filters", val);
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>
