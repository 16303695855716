<template>
  <v-card-text class="text-left pa-0">
    <!-- Search box -->
    <div class="pa-0 my-4">
      <IndicatorSearch v-model:filters="filters" @search="search" />
    </div>
    <v-card class="my-2" elevation="0" style="background-color: #f9f9f9">
      <!-- Tags -->
      <IndicatorSearchTags
        v-model:tagsProp="filters.tags"
        @search="search"
        :filterBlackList="['data_type']"
      />

      <v-card-text class="py-0 px-2">
        <!-- Filters -->
        <v-row
          v-if="indicators?.length || fetchingIndicators"
          class="px-0 my-2 align-center"
        >
          <v-col cols="9" class="text-h6 text pb-0">
            {{
              fetchingIndicators
                ? "Searching..."
                : `We found ${totalResultsCount} results that match your query`
            }}
          </v-col>
          <v-col cols="3 pb-0">
            <v-select
              v-model="filters.sortBy"
              :items="[
                { title: 'Ascending (Asc)', value: 'asc' },
                { title: 'Descending (Desc)', value: 'desc' },
              ]"
              no-data-text="No filters available"
              hide-details
              rounded="0"
              style="background-color: white"
              @update:modelValue="search"
              label="Sort"
              variant="outlined"
              density="compact"
            />
          </v-col>
        </v-row>

        <!-- Suggested prompts -->
        <v-card-text
          v-show="
            (!indicators?.length || indicators.length == 0) &&
            !noResults &&
            !fetchingIndicators
          "
          class="pa-0"
        >
          <IndicatorSearchSuggestions
            v-model:filtersProp="filters"
            @search="search"
            getSuggestionsAPI="datastore/catalogue/suggestions"
          />
        </v-card-text>

        <!-- Fetched indicators -->
        <v-card-text v-if="indicators?.length" class="pa-0">
          <IndicatorCard
            v-for="indicator in indicators"
            :key="indicator.id"
            :indicator="indicator"
          >
            <template #firstColumn>
              <!-- API URL -->
              <v-card-actions class="px-0 py-2">
                <v-icon icon="mdi-code-tags" />
                <v-card-text class="pa-3">
                  <div style="font-weight: 600">Indicator code</div>
                  <div
                    @click.stop="copyIndicator(indicator)"
                    class="text-body-1"
                    style="color: #1867c0; cursor: pointer; width: fit-content"
                  >
                    {{ indicator.indicator_code }}
                    <v-icon
                      :icon="
                        indicator.isUrlCopied ? 'mdi-check' : 'mdi-content-copy'
                      "
                      class="ml-1"
                      color="primary"
                      size="small"
                    />
                  </div>
                </v-card-text>
              </v-card-actions>

              <!-- Extra buttons -->
              <div class="d-flex pt-2">
                <v-spacer />
                <v-btn
                  color="success"
                  variant="elevated"
                  rounded="0"
                  @click="addToQuery(indicator)"
                  class="mr-2"
                >
                  build query
                </v-btn>
              </div>
            </template>
          </IndicatorCard>
        </v-card-text>

        <!-- Skeleton laoders for indicator cards. We can see them while searching and then we chuck them underneath for lazy loading -->
        <v-card-text
          v-if="
            fetchingIndicators ||
            (indicators?.length && !areAllIndicatorsFetched)
          "
          class="pa-0 paginatedSkeletons"
        >
          <IndicatorCard v-for="n in 5" :key="n" :loading="true">
            <!-- Extra buttons loading -->
            <template #firstColumn>
              <div class="d-flex pt-2">
                <v-spacer />
                <v-btn
                  disabled
                  color="success"
                  prepend-icon="mdi-plus"
                  variant="elevated"
                  rounded="0"
                  class="mr-2"
                >
                  add to query
                </v-btn>
              </div>
            </template>
          </IndicatorCard>
        </v-card-text>

        <!-- No results found -->
        <v-card-text v-if="noResults" class="pa-0 pt-2">
          <v-alert
            color="warning"
            icon="mdi-information"
            size="10px"
            prominent
            class="datastoreNoResults"
          >
            <div style="font-weight: 500">No search results found</div>
            <div>
              We were unable to find any search results for your query, please
              try again with a different parameters
            </div>
          </v-alert>
        </v-card-text>
      </v-card-text>
    </v-card>

    <!-- dialog - replace indicator -->
    <DynamicDialog
      :show="dialogIndicatorQueryOverwriting"
      @dialogOk="addToQuery(clickedIndicator, true)"
      @dialogCancel="dialogIndicatorQueryOverwriting = false"
      @update:showDyamicDialog="
        (state) => (dialogIndicatorQueryOverwriting = state)
      "
      max-width="550"
      okBtnColor="success"
      cancelBtnColor="none"
      cancelBtnVariant="outlined"
    >
      <template v-slot:title>Replace Current Indicator?</template>
      <template v-slot:content>
        <p>
          Adding this new indicator will replace the one currently in your
          query. Any customisations or settings applied to the existing
          indicator will be lost.
        </p>
      </template>
      <template v-slot:okBtnTitle>Replace Indicator</template>
    </DynamicDialog>
  </v-card-text>
</template>
<script>
import IndicatorCard from "./IndicatorCard.vue";
import DynamicDialog from "@/components/DynamicDialog.vue";
import IndicatorSearchTags from "@/components/IndicatorSearchTags.vue";
import IndicatorSearch from "@/components/IndicatorSearch.vue";
import IndicatorSearchSuggestions from "@/components/IndicatorSearchSuggestions.vue";
import { copyToClipboard } from "../mixins/CopyToClipboard";
import { systemMessages } from "@/mixins/SystemMessages";
import { useDisplay } from "vuetify";

export default {
  name: "DatastoreCatalogue",
  components: {
    IndicatorCard,
    DynamicDialog,
    IndicatorSearchTags,
    IndicatorSearch,
    IndicatorSearchSuggestions,
  },
  mixins: [systemMessages],
  data: () => ({
    pageHeight: useDisplay().height,
    indicators: [],
    filters: {
      searchString: "",
      tags: [],
      sortBy: "asc",
    },
    fetchingIndicators: false,
    areAllIndicatorsFetched: false,
    noResults: false,
    paginatedFetch: false,
    totalResultsCount: 0,
    dialogIndicatorQueryOverwriting: false,
    clickedIndicator: null,
    debouncedSearch: null,
    abortController: null,
  }),
  computed: {
    selectedIndicator: {
      get() {
        return this.$store.getters["datastore/selectedIndicator"];
      },
      set(val) {
        this.$store.commit("datastore/setSelectedIndicator", val);
      },
    },
  },
  mounted() {
    this.filters.searchString = this.$route.query?.s || "";
    this.filters.sortBy = this.$route.query?.sort || "asc";
    this.filters.tags = JSON.parse(this.$route.query?.tags || "[]");

    // if anything is coming through the URL - run search
    if (this.filters.searchString || this.filters.tags?.length) {
      this.search();
    }
  },
  methods: {
    async search() {
      //only search if there is a search string and/or filters set
      if (
        (this.filters["searchString"] || this.filters.tags?.length) &&
        !this.fetchingIndicators
      ) {
        // Send search string to analytics
        this.$axios.put("/send-analytics", {
          event: "datastore_indicator_searches",
          search_term: this.filters.searchString,
        });
        this.indicators = [];

        this.filters.limit = this.getAvailableSlotsForIndicators();
        this.noResults = false;

        try {
          this.fetchingIndicators = true;
          const response = await this.$axios.post(
            "/datastore/catalogue",
            this.filters,
          );

          //set indicators and totals details
          this.indicators.push(...response.data.indicators);
          this.areAllIndicatorsFetched = response.data.areAllIndicatorsFetched;
          this.totalResultsCount = response.data.totalCount;

          this.noResults = !this.indicators?.length;

          //return a response so that paginated call knows search has finished
          return response;
        } catch (error) {
          this.errorMsg({
            title: "There was an error searching indicators",
            message: error.response?.data?.message,
          });
        } finally {
          this.fetchingIndicators = false;
        }
      } else {
        //reset search to suggestions (no search string of filters set)
        this.noResults = false;
        this.indicators = [];
      }
    },
    async getPaginatedIndicators() {
      // do nothing if there are no more indicators to fetch || a search is in progress || there are no search params
      if (
        this.areAllIndicatorsFetched ||
        this.paginatedFetch ||
        (!this.filters["searchString"] && !this.filters.tags?.length)
      ) {
        return;
      }

      this.paginatedFetch = true;

      const filters = this.$cloneDeep(this.filters);
      filters.limit = this.getAvailableSlotsForIndicators();
      filters.offset = this.indicators?.length;

      //trigger an indicator search with offset from current results
      try {
        const response = await this.$axios.post("datastore/catalogue", filters);

        this.indicators.push(...response.data.indicators);
        this.areAllIndicatorsFetched = response.data.areAllIndicatorsFetched;
      } catch (error) {
        console.error(error);
      } finally {
        this.paginatedFetch = false;
      }
    },
    // Fetch indicators based on the user's display height
    getAvailableSlotsForIndicators() {
      const INDICATOR_CARD = 94;
      const freeSpace = this.pageHeight - 300;

      return Math.floor(freeSpace / INDICATOR_CARD); // plus extra
    },
    copyIndicator(indicator) {
      copyToClipboard(indicator.indicator_code);

      this.indicators.find((i) => i.id === indicator.id).isUrlCopied = true;

      this.successPill({
        icon: "mdi-content-copy",
        title: "Copied to clipboard",
      });
      setTimeout(() => {
        this.indicators.find((i) => i.id === indicator.id).isUrlCopied = false;
      }, 3000);
    },
    addToQuery(indicator, force = false) {
      if (force) {
        this.selectedIndicator = indicator;
        this.dialogIndicatorQueryOverwriting = false;
        this.$router.push("/datastore/query-builder");
        return;
      }

      this.clickedIndicator = indicator;

      if (this.selectedIndicator) {
        this.dialogIndicatorQueryOverwriting = true;
      } else {
        this.selectedIndicator = JSON.parse(JSON.stringify(indicator));
        this.$router.push("/datastore/query-builder");
      }
    },
    async getSearchSuggestions() {
      try {
        const response = await this.$axios.get(
          "/datastore/catalogue/suggestions",
        );

        this.suggestedPrompts = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    /** extracts and sets search string and tags from filter suggestion data */
    setSearchSuggestionFilters(suggestion) {
      let allFilters = [];
      //compile suggestion filter tags
      suggestion.filters.forEach((f) => {
        const filterParts = f.split("|").map((item) => item.trim());

        let filter = {
          title: "",
          filterBy: null,
          operator: null,
          text: null,
          date: null,
          dateLabel: null,
          country: null,
        };
        if (filterParts[0] == "coverage") {
          const [type, country] = [...filterParts];
          filter.filterBy = type;
          filter.country = [country];
        }

        if (filterParts[0] === "source") {
          const [type, operator, text] = [...filterParts];
          filter.filterBy = type;
          filter.operator = operator;
          filter.text = text;
        }

        if (filterParts[0] === "timepoint") {
          const [type, operator, date] = [...filterParts];
          const [year, month] = [...date.split("-")];
          filter.filterBy = type;
          filter.operator = operator;
          filter.date = date;
          filter.dateLabel =
            month.charAt(0).toUpperCase() + month.slice(1) + " " + year;
        }

        allFilters.push(filter);
      });

      //update current filter tags and search string
      this.filters.tags = allFilters;

      //search string
      if (suggestion.search) {
        this.filters.searchString = suggestion.search;
      }
    },
  },
  watch: {
    filters: {
      handler(val) {
        //console.log("Filters updated:", JSON.stringify(val, null, 2)); // Pretty-print the filters object

        //set querystring params when search/filter tags change
        this.$router.push({
          path: "/datastore/catalogue",
          query: {
            s: val.searchString,
            sort: val.sortBy,
            tags: JSON.stringify(val.tags),
          },
        });
      },
      deep: true,
    },
  },
};
</script>
<style scoped></style>
