<template>
  <v-expansion-panels v-model="isOpened" class="my-4 indicatorCard">
    <v-expansion-panel
      style="border: solid 1px #c2c2c2; border-radius: 8px"
      :value="true"
    >
      <!-- Header -->
      <v-expansion-panel-title
        class="expansionTitlePanel pl-0 pr-6"
        :style="{ borderBottom: isOpened ? 'solid 1px #c2c2c2' : '' }"
      >
        <template v-slot:default>
          <!-- skeleton loader  -->
          <v-card-actions v-if="loading" style="width: 80%">
            <v-skeleton-loader
              type="list-item-two-line"
              style="background-color: inherit"
              width="100%"
            />
          </v-card-actions>

          <v-card-actions style="width: 99%" v-else>
            <div
              class="circle mr-3 ml-2"
              :style="{ backgroundColor: circleColor }"
              v-tooltip="{ text: circleTooltip }"
            />
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center">
                <div class="text font-weight-medium text-body-1">
                  {{ indicator.indicator_name }}
                </div>
              </div>
              <div v-if="indicator.tags" class="d-flex mt-2 align-center">
                <v-icon icon="mdi-tag-outline mr-1" size="17px" />
                <p class="text text-caption">{{ indicator.tags }}</p>
              </div>
            </div>
            <v-spacer />
            <!-- if client ID is not null or 1 it's custom data -->
            <v-chip
              v-if="indicator.client_id !== null"
              variant="elevated"
              class="mr-1"
              elevation="0"
              color="warning"
              >Custom Data</v-chip
            >
          </v-card-actions>
        </template>
      </v-expansion-panel-title>

      <!-- Content under the panel -->
      <v-expansion-panel-text class="mb-4">
        <!-- loading skeleton -->
        <v-row v-if="loading">
          <v-col cols="9" class="py-0 pl-0 pr-2">
            <div class="infoValue" v-for="n in 4" :key="n">
              <v-skeleton-loader
                class="pa-0 ma-0"
                max-width="200"
                type="subtitle"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="pa-0 ma-0"
                type="subtitle"
              ></v-skeleton-loader>
            </div>
            <!-- Custom content -->
            <slot name="firstColumn" />
          </v-col>
          <v-divider vertical class="mt-2" />
          <v-col cols="3">
            <v-skeleton-loader
              v-for="n in 5"
              :key="n"
              type="list-item-two-line"
              style="background-color: inherit"
            />
          </v-col>
        </v-row>

        <!-- Information -->
        <v-row v-else class="indicatorInfo">
          <v-col cols="9" class="py-0 pl-0 pr-2">
            <div style="height: 87%">
              <!-- About -->
              <v-card-actions class="px-0 py-6">
                <v-icon icon="mdi-information-outline" />
                <v-card-text class="pa-0">
                  <div class="semi-bold">About</div>
                  <div class="text-body-1">
                    {{ indicator.indicator_description }}
                  </div>
                </v-card-text>
              </v-card-actions>
              <!-- Warnings -->
              <v-card-actions v-if="indicator.warnings" class="px-0 py-2">
                <v-icon icon="mdi-alert" />
                <v-card-text class="pa-0">
                  <div class="semi-bold">Warnings</div>
                  <div class="text-body-1">
                    {{ indicator.warnings }}
                  </div>
                </v-card-text>
              </v-card-actions>
              <!-- Source -->
              <v-card-actions class="px-0 py-2">
                <v-icon icon="mdi-bullhorn-variant-outline" />
                <v-card-text class="pa-0">
                  <div class="semi-bold">Source</div>
                  <div class="text-body-1">
                    {{ indicator.source }}
                  </div>
                </v-card-text>
              </v-card-actions>
            </div>

            <!-- Custom columns -->
            <div style="height: 13%">
              <slot name="firstColumn" />
            </div>
          </v-col>
          <v-divider vertical class="mt-2" />
          <v-col cols="3" class="pl-4">
            <!-- Timepoint (sometimes users won't have access to multiple timepoints so we display this) -->
            <v-card-actions v-if="indicator.timepoint" class="px-0 py-2">
              <v-icon icon="mdi-calendar" />
              <v-card-text class="pa-0">
                <div class="semi-bold">Timepoint</div>
                <div class="text-body-1">
                  {{ indicator.timepoint }}
                </div>
              </v-card-text>
            </v-card-actions>

            <!-- Latest time point -->
            <v-card-actions v-if="indicator.latest_timepoint" class="px-0 py-2">
              <v-icon icon="mdi-calendar-clock" />
              <v-card-text class="pa-0">
                <div class="semi-bold">Latest time point</div>
                <div class="text-body-1">
                  {{ indicator.latest_timepoint }}
                </div>
              </v-card-text>
            </v-card-actions>

            <!-- Time points -->
            <v-card-actions v-if="indicator.timepoint_count" class="px-0 py-2">
              <v-icon icon="mdi-timeline-plus" />
              <v-card-text class="pa-0">
                <div class="semi-bold">Time points</div>
                <div class="text-body-1">
                  {{ indicator.timepoint_count }}
                </div>
              </v-card-text>
            </v-card-actions>

            <!-- Time point availability range -->
            <v-card-actions
              v-if="indicator.time_point_availability_range"
              class="px-0 py-2"
            >
              <v-icon icon="mdi-calendar-range" />
              <v-card-text class="pa-0">
                <div class="semi-bold">Time point availability range</div>
                <div class="text-body-1">
                  {{ indicator.time_point_availability_range }}
                </div>
              </v-card-text>
            </v-card-actions>

            <!-- Update Frequency -->
            <v-card-actions v-if="indicator.update_frequency" class="px-0 py-2">
              <v-icon icon="mdi-update" />
              <v-card-text class="pa-0">
                <div class="semi-bold">Update Frequency</div>
                <div class="text-body-1">
                  {{ indicator.update_frequency }}
                </div>
              </v-card-text>
            </v-card-actions>

            <!-- Coverage -->
            <v-card-actions v-if="indicator.coverage" class="px-0 py-2">
              <v-icon icon="mdi-earth" />
              <v-card-text class="pa-0">
                <div class="semi-bold">Coverage</div>
                <div class="text-body-1">
                  {{ indicator.coverage }}
                </div>
              </v-card-text>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "IndicatorCard",
  data: () => ({
    isOpened: false,
  }),
  mounted() {
    this.isOpened = this.opened; // sync with the passed prop
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    indicator: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    opened: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    circleColor() {
      return this.isRecent() ? "#3EDBCF" : "#51627C";
    },
    circleTooltip() {
      return this.isRecent()
        ? "New time point data added in the last 30 days."
        : "No new time point data added in the last 30 days.";
    },
  },
  methods: {
    isRecent() {
      const createdAt = new Date(
        this.indicator.latest_timepoint || this.indicator.timepoint,
      );
      const now = new Date();
      const diffTime = now - createdAt;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return diffDays < 30;
    },
  },
};
</script>

<style scoped>
.v-row {
  margin-bottom: 0 !important;
}
.v-card-actions {
  gap: 0;
}

.circle {
  width: 11px;
  height: 11px;
  border-radius: 10px;
  z-index: 10; /* Add z-index to ensure it is above other elements */
}

.expansionTitlePanel {
  max-height: 77px !important;
  min-height: 10px !important;
}
.indicatorInfo .v-card-text {
  width: 100px;
}

.indicatorInfo .v-icon {
  margin-right: 12px;
  margin-top: 8px;
}

.indicatorInfo .v-card-actions {
  align-items: start;
}

* {
  color: #4d4d4d;
}

.tooltip {
  color: white !important;
}

.semi-bold {
  font-weight: 600;
}
.v-chip__content {
  color: #8b4c04;
}
</style>
