<template>
  <v-container fluid :style="'height:' + (height - 50) + 'px; overflow: auto;'">
    <v-card tile min-height="100%">
      <v-card-text class="text-left">
        <v-card-title class="pl-0"
          ><h1 class="h1">Client Manager</h1></v-card-title
        >
        <v-card-actions class="pb-0">
          <v-divider></v-divider>
        </v-card-actions>
        This is where you can onboard and manage clients. You can add new custom
        client config in the Client Custom Config Options module and then update
        it here.
      </v-card-text>
      <v-card-actions class="pa-0">
        <!-- dropdown card/tile -->
        <v-card tile width="100%" class="pt-3">
          <v-card-subtitle class="text-left mb-4"
            >Select Client</v-card-subtitle
          >
          <v-card-actions>
            <v-autocomplete
              id="searchClients"
              v-model="selectedSearchClient"
              :items="clients"
              item-title="client_name"
              item-value="id"
              label="Search for Clients"
              placeholder="Start typing to Search"
              prepend-icon="mdi-magnify"
              return-object
              style="max-width: 800px"
              density="compact"
              rounded="0"
              clearable
              variant="outlined"
              autofocus
              autocomplete="off"
            ></v-autocomplete>
          </v-card-actions>
          <v-card-actions v-if="allSiteConfigs">
            <v-spacer
              style="width: 100%; max-width: 445px !important"
            ></v-spacer>
            <v-btn
              variant="elevated"
              color="success"
              tile
              @click="downloadAllUsers"
              class="mr-4"
              :loading="downloadingClients"
              aria-label="download all clients"
            >
              download all clients
            </v-btn>
            <span>
              <Create
                :schema="fieldSchema"
                :clients="clients"
                @add-client="addClient"
              />
            </span>
          </v-card-actions>
        </v-card>
      </v-card-actions>
      <v-card-text v-if="showPanel" class="pa-0 mt-2">
        <v-row dense class="mt-3">
          <v-col cols="6" class="px-3">
            <!-- demo config-->
            <v-row dense class="mb-3" v-if="clientObject.client_type == 'demo'">
              <v-card tile class="pb-2 w-100">
                <v-card-subtitle class="text-left">
                  {{ clientObjectClone.client_name }} - Demo Config
                </v-card-subtitle>
                <v-card-actions>
                  <v-form ref="form" v-model="validation" style="width: 100%">
                    <span
                      v-for="(item, key) in fieldSchema"
                      :key="key"
                      class="text-left"
                    >
                      <Field
                        v-if="item.demoField == true"
                        :schema="fieldSchema[key]"
                        v-model:value="clientObject[key]"
                      />
                    </span>
                  </v-form>
                </v-card-actions>
              </v-card>
            </v-row>
            <v-row dense>
              <!-- standard config -->
              <v-card tile class="pb-2 w-100">
                <v-card-subtitle class="text-left"
                  >{{ clientObjectClone.client_name }} - Standard
                  Config</v-card-subtitle
                >
                <v-card-actions>
                  <v-form ref="form" v-model="validation" style="width: 100%">
                    <span
                      v-for="(item, key) in fieldSchema"
                      :key="key"
                      class="text-left"
                    >
                      <span v-if="item.demoField != true">
                        <!--     map scheme      -->
                        <div v-if="key === 'map_colour_scheme_id'">
                          <div>Colour scheme on the Map:</div>
                          <div
                            v-for="(colour, index) in allColourSchemes
                              .filter(
                                (colourScheme) =>
                                  colourScheme.id ===
                                  clientObject.map_colour_scheme_id,
                              )[0]
                              .scheme.split('-')"
                            style=""
                            :key="index"
                            class="colour-block"
                          >
                            <div
                              style=""
                              class="inner-block"
                              :style="{ backgroundColor: '#' + colour }"
                            ></div>
                          </div>
                          <v-icon
                            aria-label="edit"
                            style="cursor: pointer"
                            title="Edit map colour scheme"
                            @click="selectColourScheme(1)"
                            class="mb-5 ml-2"
                            >mdi-pencil
                          </v-icon>
                        </div>
                        <!--     dashboard scheme       -->
                        <div
                          v-else-if="key === 'dashboard_colour_scheme_id'"
                          class="mb-4"
                        >
                          <div>Colour scheme on the Dashboard:</div>
                          <div
                            v-for="(colour, index) in allColourSchemes
                              .filter(
                                (colourScheme) =>
                                  colourScheme.id ===
                                  clientObject.dashboard_colour_scheme_id,
                              )[0]
                              .scheme.split('-')"
                            style=""
                            :key="index"
                            class="colour-block"
                          >
                            <div
                              style=""
                              class="inner-block"
                              :style="{ backgroundColor: '#' + colour }"
                            ></div>
                          </div>
                          <v-icon
                            aria-label="edit"
                            style="cursor: pointer"
                            title="Edit dashboard colour scheme"
                            @click="selectColourScheme(2)"
                            class="mb-5 ml-2"
                            >mdi-pencil
                          </v-icon>
                        </div>
                        <Field
                          v-else
                          :schema="fieldSchema[key]"
                          v-model:value="clientObject[key]"
                        />
                      </span>
                    </span>
                  </v-form>
                </v-card-actions>
                <v-card-actions>
                  <v-btn
                    variant="elevated"
                    color="error"
                    tile
                    @click="confirmDeleteDialog = true"
                    aria-label="delete client"
                  >
                    delete client
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    variant="elevated"
                    color="info"
                    tile
                    @click="showModulesLoader = true"
                    class="mr-1"
                    aria-label="Modules"
                  >
                    Modules
                  </v-btn>
                  <v-btn
                    variant="elevated"
                    color="success"
                    tile
                    @click="updateConfig()"
                    aria-label="Save Changes"
                  >
                    Save Changes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="6" class="px-3">
            <v-row dense>
              <!-- custom config -->

              <v-card tile class="pb-2 w-100">
                <v-card-subtitle class="text-left"
                  >{{ clientObjectClone.client_name }} - Custom
                  Config</v-card-subtitle
                >
                <v-card-actions>
                  <v-form
                    ref="configForm"
                    v-model="validation"
                    style="width: 100%"
                  >
                    <span v-for="item in ccc" :key="item.id">
                      <Field :schema="item" v-model:value="item.value" />
                    </span>
                  </v-form>
                </v-card-actions>
              </v-card>
              <v-card tile class="pb-2 w-100">
                <v-card-subtitle class="mt-2 text-left"
                  >Dual Access for {{ clientObjectClone.client_name }} (you
                  can't edit public users here)
                </v-card-subtitle>
                <v-card-actions>
                  <v-autocomplete
                    v-model="selectedDualAccess"
                    :items="searchUserResults"
                    no-data-text="no results"
                    :loading="searching"
                    v-model:search="search"
                    flat
                    return-object
                    item-title="name"
                    item-value="id"
                    clearable
                    variant="outlined"
                    density="compact"
                    rounded="0"
                    class="fields"
                    label="Add users"
                    autocomplete="off"
                  ></v-autocomplete>
                </v-card-actions>
                <v-card-actions>
                  <v-select
                    v-model="removeUser"
                    :items="dualAccessUsers"
                    no-data-text="No users selected"
                    item-title="name"
                    variant="outlined"
                    density="compact"
                    rounded="0"
                    class="fields"
                    label="Users with Access (select to remove)"
                    return-object
                  ></v-select>
                </v-card-actions>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    variant="elevated"
                    color="success"
                    tile
                    @click="updateConfig()"
                    aria-label="Save Changes"
                  >
                    Save Changes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!--   select colour scheme   -->
    <v-dialog v-model="colorSchemeDialog" width="400" persistent>
      <v-card>
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 text-center"
        >
          <v-spacer>Color scheme for {{ selectedColourScheme.key }}</v-spacer>
        </v-card-title>
        <v-card-text
          class="selectColorScheme"
          style="height: 52vh; overflow: auto"
        >
          <v-radio-group v-model="selectedColourScheme.id" class="mb-4">
            <div
              v-for="colourScheme in allColourSchemes"
              :key="colourScheme.id"
            >
              <v-radio
                :value="colourScheme.id"
                :key="colourScheme.id"
                style="display: flex; justify-content: center"
              >
                <template v-slot:label>
                  <v-card-actions class="pb-0">
                    <div
                      v-for="(colour, index) in colourScheme.scheme.split('-')"
                      :key="index"
                      class="colour-block"
                    >
                      <div
                        class="inner-block"
                        :style="{
                          backgroundColor: '#' + colour,
                        }"
                      ></div>
                    </div>
                  </v-card-actions>
                </template>
              </v-radio>
            </div>
          </v-radio-group>
        </v-card-text>
        <v-card>
          <v-card-actions>
            <v-btn
              variant="elevated"
              color="error"
              tile
              @click="selectColourScheme(null)"
              aria-label="cancel"
            >
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              variant="elevated"
              color="success"
              tile
              @click="selectColourScheme(3)"
              aria-label="confirm"
            >
              confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showModulesLoader" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Fetching list of available modules
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checkingUsersInClient"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text class="text-center mt-1">
          Checking if there are any users in this client
        </v-card-text>
        <v-card-actions>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDeleteDialog" width="550">
      <v-card>
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 text-center"
        >
          <v-spacer>Confirm Deletion</v-spacer>
        </v-card-title>
        <v-card v-if="!signedInUsers.length">
          <v-card-actions>
            <v-card-text style="text-align: center">
              Are you sure you want to delete this client? You
              <i>cannot</i> undo this action!
            </v-card-text>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              variant="elevated"
              color="success"
              tile
              @click="confirmDeleteDialog = false"
              aria-label="cancel"
            >
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              variant="elevated"
              color="error"
              tile
              @click="deleteClient(0)"
              aria-label="Delete"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-actions>
            <!--    alert    -->
            <v-alert type="warning" width="100%" class="mt-3">
              The users listed below are currently signed in to this client.<br />
              <div>
                Manually reassign these users to another client via the
                database, or delete them from the system using the button below.
                However, please note that if you choose to delete them, these
                users and all of their data will be lost.
              </div>
            </v-alert>
          </v-card-actions>
          <v-card-actions>
            <v-data-table
              :headers="[
                { title: 'id', value: 'id' },
                { title: 'Name', value: 'name' },
                { title: 'Email', value: 'email' },
              ]"
              :items="signedInUsers"
              :items-per-page="-1"
              class="elevation-1"
            >
            </v-data-table>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              variant="elevated"
              color="success"
              tile
              @click="confirmDeleteDialog = false"
              aria-label="cancel"
            >
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              variant="elevated"
              color="error"
              tile
              @click="deleteClient(1)"
              aria-label="delete users"
            >
              delete users
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showModulesDialog" scrollable max-width="300px">
      <v-card>
        <v-card-title class="text-center">Select Modules</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px">
          <span v-for="(avail_module, index) in availableModules" :key="index">
            <v-checkbox
              :value="avail_module.id"
              :key="index"
              density="compact"
              rounded="0"
              :label="avail_module.label"
              @change="checkModuleChange"
              :return-object="true"
              v-model="clientObject.modules"
            >
            </v-checkbox>
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="showModulesDialog = false"
            aria-label="Close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showModuleChangeConfirmDialog"
      persistent
      max-width="395"
    >
      <v-card>
        <v-card-title class="text-h5"> Confirm Removal? </v-card-title>
        <v-card-text>Are you sure you want to remove this module?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="green darken-1"
            @click="
              showModuleChangeConfirmDialog = false;
              updateConfig();
            "
            aria-label="Yes"
          >
            Yes
          </v-btn>
          <v-btn
            variant="elevated"
            color="red darken-1"
            @click="
              showModuleChangeConfirmDialog = false;
              resetModuleChoices();
            "
            aria-label="No"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Field from "@/components/Fields.vue";
import Create from "@/components/ClientManagerCreate";
import CMShema from "@/schemas/ClientManager";
import { useDisplay } from "vuetify";
import { exportCSVFile } from "@/mixins/ExportCSVFile";

export default {
  name: "ClientManager",
  data: () => ({
    height: useDisplay().height,
    removeUser: null,
    dualAccessUsers: [],
    search: "",
    selectedDualAccess: null,
    searching: false,
    searchUserResults: [],
    fieldSchema: CMShema,
    clients: [],
    clientID: null,
    clientObject: {},
    allSiteConfigs: null,
    ccco: [],
    ccc: {},
    cccv: [],
    clientObjectClone: {},
    showCreateNew: false,
    showModulesLoader: false,
    showModuleChangeConfirmDialog: false,
    showModulesDialog: false,
    showModulesSelectionList: false,
    showPanel: false,
    validation: false,
    fetchedAvailableModules: false,
    availableModules: [],
    confirmDeleteDialog: false,
    checkingUsersInClient: false,
    signedInUsers: [],
    colorSchemeDialog: false,
    allColourSchemes: [],
    selectedColourScheme: {
      id: null,
      key: null,
    },
    selectedSearchClient: null,
    downloadingClients: false,
  }),
  components: {
    Field,
    Create,
  },
  mounted() {
    this.getCustomClientOptions();
  },
  methods: {
    searchUsers(v) {
      this.searching = true;
      this.$axios
        .get("/users/search/" + v)
        .then(
          function (response) {
            // handle success
            this.searchUserResults = response.data;
            this.searching = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.searching = false;
          }.bind(this),
        );
    },
    addClient(newClient) {
      this.clients.push(newClient);
    },
    checkModuleChange(event) {
      if (this.clientObjectClone.modules.length > event.length) {
        // the stored backup copy of the modules array has more elements a module has been removed
        this.showModuleChangeConfirmDialog = true;
      } else {
        // if there are more elements a module has been added
        this.updateConfig();
      }
    },
    resetModuleChoices() {
      this.clientObject.modules = this.$cloneDeep(
        this.clientObjectClone.modules,
      );
    },
    updateConfig() {
      // reset these
      this.resetApiValidationErrors();
      this.resetCustomConfigApiValidationErrors();

      this.$refs.configForm.validate().then(({ valid: isConfigFormValid }) => {
        this.$refs.form.validate().then(({ valid: isFormValid }) => {
          if (isFormValid && isConfigFormValid) {
            this.emit.emit("systemMessage", {
              title: "Updating",
              message: "Updating Client Config",
              timeout: 4000,
              colour: "warning",
            });
            // make the call!
            this.$axios
              .put("/clients/" + this.clientID, this.clientObject)
              .then((response) => {
                // call this to update the custom config
                this.upsertClient();
                // now update all of this
                this.clientObject = response.data;
                this.clientObjectClone = this.$cloneDeep(this.clientObject);
                // do this incase the user has updated the client name (this will change the client name in the dropdown menu)
                for (let index = 0; index < this.clients.length; index++) {
                  if (this.clients[index].id === this.clientID) {
                    this.clients[index].client_name =
                      this.clientObject.client_name;
                    break;
                  }
                }
              })
              .catch((error) => {
                this.emit.emit("systemMessage", {
                  message: error.response.data.message,
                  title: "Error! Some or all Client Config Update Failed",
                  timeout: -1,
                  colour: "error",
                });
                this.resetModuleChoices();
                this.apiValidationErrors(error.response.data.errors);
              });
          }
        });
      });
    },
    upsertClient() {
      // if this passes validation then call the api
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          // make the call!
          this.$axios
            .put("/client-config/" + this.clientID, this.ccc)
            .then((response) => {
              this.emit.emit("systemMessage", {
                title: "Success!",
                message: "Update Complete",
                timeout: 3000,
                colour: "success",
              });
              // update the selected config
              this.ccc = response.data;
              this.emit.emit("systemBusy", false);
            })
            .catch((error) => {
              this.ccc = error.response.data;
              this.emit.emit("systemBusy", false);
              this.emit.emit("systemMessage", {
                title: "Please check form for errors",
                message: "Error! Some or all Client Config Update Failed",
                timeout: -1,
                colour: "error",
              });
            });
        }
      });
    },
    getAllSiteConfigs() {
      this.$axios
        .get("/site-config-slim")
        .then(
          function (response) {
            // handle success
            this.getAllClients();
            this.allSiteConfigs = response.data;
            this.fieldSchema.assigned_site_configs.selectItems =
              this.allSiteConfigs;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get site configs",
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
    getAllClients() {
      this.$axios
        .get("/clients")
        .then(
          function (response) {
            // handle success
            this.clients = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all clients",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getDualAccess() {
      this.$axios
        .get("/user-client-memberships/" + this.clientID)
        .then(
          function (response) {
            // handle success
            this.dualAccessUsers = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get dual access users",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getSelectedClient() {
      this.emit.emit("systemBusy", true);
      this.showPanel = false;
      // get the colour schemes
      this.getColourSchemes();
      this.$axios
        .get("/clients/" + this.clientID)
        .then(
          function (response) {
            // handle success
            this.getCustomClientConfigValues();
            this.getDualAccess();
            this.clientObject = response.data;
            this.clientObjectClone = this.$cloneDeep(this.clientObject);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get selected client",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getCustomClientOptions() {
      this.$axios
        .get("/client-config-options")
        .then(
          function (response) {
            // handle success
            this.getAllSiteConfigs();
            this.ccco = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get custom client config options",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getCustomClientConfigValues() {
      this.$axios
        .get("/client-config/search/" + this.clientID)
        .then(
          function (response) {
            // handle success
            this.cccv = response.data;
            this.mergeCustomOptionsWithClientValues();
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get custom client config values",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    // MergeCustom Client Config Options With Client Values
    mergeCustomOptionsWithClientValues() {
      for (let i = 0; i < this.ccco.length; i++) {
        this.ccc[this.ccco[i].id] = {
          config_item_id: this.ccco[i].id,
          client_id: this.clientID,
          id: this.ccco[i].id,
          label: this.ccco[i].name,
          fieldType: this.ccco[i].fieldType,
          hint: this.ccco[i].description,
          apiResponseError: "",
          readOnly: false,
          show: true,
          value: this.setCCCValue(this.ccco[i].id),
        };
      }
      this.emit.emit("systemBusy", false);
      this.showPanel = true;
    },
    setCCCValue(configItemID) {
      for (let i = 0; i < this.cccv.length; i++) {
        if (this.cccv[i].config_item_id === configItemID) {
          return this.cccv[i].value;
        }
      }
      return null;
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetCustomConfigApiValidationErrors() {
      for (var field in this.ccc) {
        this.ccc[field].apiResponseError = null;
      }
    },
    resetApiValidationErrors() {
      for (var field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
    getAvailableModules() {
      this.$axios
        .get("/available-modules")
        .then(
          function (response) {
            // handle success
            this.availableModules = response.data;
            this.showModulesLoader = false;
            this.showModulesDialog = true;
            this.fetchedAvailableModules = true;
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            this.showModulesLoader = false;
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all available modules",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    deleteClient(deleteUsers) {
      this.confirmDeleteDialog = false;
      this.checkingUsersInClient = true;

      // make the call!
      this.$axios
        .delete("/delete-client/" + this.clientID + "/" + deleteUsers)
        .then(
          function (response) {
            // handle success
            this.checkingUsersInClient = false;

            // if there are signed it users
            if (Object.prototype.hasOwnProperty.call(response.data, "users")) {
              this.signedInUsers = response.data.users;
              this.confirmDeleteDialog = true;
            } else {
              this.emit.emit("systemMessage", {
                title: "Client Deleted",
                message: "Success!",
                timeout: 3000,
                colour: "green",
              });
              this.showPanel = false;
              this.clients = response.data;
              this.clientID = null;
              this.selectedSearchClient = null;
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get dual access users",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    clearData() {
      this.signedInUsers = [];
    },
    getColourSchemes() {
      this.$axios
        .get("/all-colour-schemes")
        .then(
          function (response) {
            // handle success
            this.allColourSchemes = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get colour schemes",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    selectColourScheme(item) {
      // grab all colour schemes if we haven't already
      if (!this.allColourSchemes.length) this.getColourSchemes();

      switch (item) {
        case 1:
          // map colour scheme
          this.selectedColourScheme = {
            key: "Map",
            id: this.clientObject.map_colour_scheme_id,
          };
          this.colorSchemeDialog = true;
          break;
        case 2:
          // dashboard colour scheme
          this.selectedColourScheme = {
            key: "Dashboard",
            id: this.clientObject.dashboard_colour_scheme_id,
          };
          this.colorSchemeDialog = true;
          break;
        case 3:
          // confirm colour scheme
          if (this.selectedColourScheme.key === "Map") {
            this.clientObject.map_colour_scheme_id =
              this.selectedColourScheme.id;
          } else {
            this.clientObject.dashboard_colour_scheme_id =
              this.selectedColourScheme.id;
          }
          this.colorSchemeDialog = false;
          break;
        default:
          // cancel colour scheme
          this.selectedColourScheme = { key: null, id: null };
          this.colorSchemeDialog = false;
      }
    },
    downloadAllUsers() {
      this.downloadingClients = true;
      this.$axios
        .get("/download-all-clients")
        .then((response) => {
          // build the “headers” array by using the keys from response
          let headers = Object.keys(response.data[0]);

          let data = response.data;
          let d = new Date();
          let filename = `full_client_details_${d.getDate()}_${d.toLocaleString("default", { month: "long" })}_${d.getFullYear()}`;

          // call this
          exportCSVFile(headers, data, filename);
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Failed to get clients",
            timeout: -1,
            colour: "red",
          });
        })
        .finally(() => {
          this.downloadingClients = false;
        });
    },
  },
  watch: {
    search(val) {
      console.log(val);
      val && val !== this.selectedAreaSearch && this.searchUsers(val);
    },
    removeUser: {
      handler(val) {
        if (val) {
          this.$axios
            .delete("/user-client-memberships/" + this.clientID + "/" + val.id)
            .then((response) => {
              this.dualAccessUsers = response.data;
              this.removeUser = null;
              this.selectedDualAccess = null;
            })
            .catch((error) => {
              this.emit.emit("systemBusy", false);
              this.emit.emit(
                "systemMessage",
                error.response.data.message,
                "Error! Failed to remove user",
                0,
                error.response.status === 422 ? "blue" : "red",
              );
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      },
      deep: true,
    },
    selectedDualAccess: {
      handler(val) {
        if (val) {
          this.$axios
            .post("/user-client-memberships", {
              user_id: val.id,
              client_id: this.clientID,
            })
            .then((response) => {
              this.dualAccessUsers = response.data;
              this.selectedDualAccess = null;
              this.removeUser = null;
              this.search = null;
            })
            .catch((error) => {
              this.emit.emit("systemBusy", false);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Some or all Client Config Update Failed",
                timeout: -1,
                colour: "red",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      },
      deep: true,
    },
    clientID: {
      handler() {
        if (this.clientID) {
          this.getSelectedClient();
          this.clearData();
        } else {
          this.showPanel = false;
        }
      },
      deep: true,
    },
    showModulesLoader(val) {
      if (!val) return;
      // check if we have already fetched the data from the api
      if (this.fetchedAvailableModules) {
        // if we have the data show the module selection dialog
        this.showModulesLoader = false;
        this.showModulesDialog = true;
      } else {
        // if not fetch the data
        this.getAvailableModules();
      }
    },
    selectedSearchClient(val) {
      if (val) {
        this.clientID = this.selectedSearchClient.id;
      } else {
        this.clientID = null;
      }
    },
  },
};
</script>

<style scoped>
.colour-block {
  display: inline-block;
  margin-top: 5px;
}

.inner-block {
  width: 50px;
  height: 25px;
}
</style>
<style>
.selectColorScheme .v-label {
  opacity: 1 !important;
}
</style>
