<template>
  <v-container class="d-flex justify-center">
    <v-row justify="center" align="start">
      <v-col cols="auto">
        <v-img
          max-height="60"
          class="mt-10 mb-8"
          :src="require('@/assets/localInsight.png')"
          title="Local Insight Logo"
          alt="Local Insight Logo"
        ></v-img>
        <v-card
          class="mx-auto"
          max-width="500"
          style="border-top: 7px solid #3edbcf"
        >
          <v-card-item>
            <v-card-title
              class="text-left font-weight-bold pt-2 pl-1"
              style="color: #51627c"
              >Pardon the interruption</v-card-title
            >
          </v-card-item>

          <v-card-text class="text-left pl-5 pr-5">
            <p>
              To continue using the Local Insight platform, please review and
              accept our Terms of Service and Privacy Policy
            </p>
            <p class="mt-3 font-weight-bold">I have read and agree to:</p>
            <v-checkbox
              v-model="termsAndConditions"
              style="height: 50px"
              color="#0e5b99"
            >
              <template v-slot:label>
                <div>
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <a
                        class="custom-link"
                        :href="vueAppTermsOfServiceUrl"
                        target="_blank"
                        v-bind="props"
                        @click.stop
                      >
                        Terms and Conditions
                      </a>
                    </template>
                    Opens in new window
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="privacyPolicy"
              style="height: 50px"
              color="#0e5b99"
            >
              <template v-slot:label>
                <div>
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <a
                        class="custom-link"
                        :href="privacyPolicyUrl"
                        target="_blank"
                        v-bind="props"
                        @click.stop
                      >
                        Privacy Policy
                      </a>
                    </template>
                    Opens in new window
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions class="pl-5 pr-5 pb-5">
            <v-btn
              :disabled="!termsAndConditions || !privacyPolicy ? true : false"
              block
              color="info"
              variant="elevated"
              rounded="0"
              :loading="acceptBusy"
              @click="accepted"
            >
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-btn
          variant="text"
          color="error"
          class="mt-5 font-weight-bold"
          :loading="logoutBusy"
          @click="logOut"
        >
          Decline and Sign Out
        </v-btn>
        <v-alert
          v-if="error"
          density="compact"
          title="Error!"
          type="error"
          class="mt-5 text-left"
          :text="errorMessage"
        ></v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TermsAndConditions",
  data: () => ({
    termsAndConditions: false,
    privacyPolicy: false,
    acceptBusy: false,
    logoutBusy: false,
    error: false,
    errorMessage: null,
  }),
  components: {},
  computed: {
    vueAppTermsOfServiceUrl: {
      get() {
        return process.env.VUE_APP_TERMS_OF_SERVICE_URL;
      },
    },
    privacyPolicyUrl: {
      get() {
        return process.env.VUE_APP_PRIVACY_POLICY_URL;
      },
    },
  },
  props: {
    model: null,
    item: {},
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    relatedModelResults: {},
  },
  mounted() {},
  methods: {
    logOut() {
      this.logoutBusy = true;
      this.$emit("logout");
    },
    accepted() {
      this.error = false;
      this.acceptBusy = true;
      this.$axios
        .put("/accept-terms-and-conditions", {
          termsAndConditions: this.termsAndConditions,
          privacyPolicy: this.privacyPolicy,
        })
        .then(() => {
          // handle success
          location.reload();
        })
        .catch((error) => {
          // handle error response
          this.errorMessage = error.response.data.message;
          this.error = true;
          this.acceptBusy = false;
        });
    },
  },
};
</script>

<style scoped>
.custom-link {
  color: #0e5b99 !important;
  text-decoration: none;
}

.custom-link:hover {
  color: #0e5b99 !important;
}
</style>
