export const Debounce = {
  methods: {
    debounce(func = null, wait = 500) {
      let timeout = null;
      return (...args) => {
        return new Promise((resolve, reject) => {
          if (timeout) {
            clearTimeout(timeout);
          }

          timeout = setTimeout(() => {
            try {
              let output = func(...args);
              timeout = null;
              resolve(output);
            } catch (err) {
              reject(err);
            }
          }, wait);
        });
      };
    },
  },
};
