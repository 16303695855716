<template>
  <v-card elevation="0" class="pa-0 h-100 grayedBackground">
    <HeaderModule title="My Account" width="1224px" icon="account" />

    <v-card
      class="mx-auto pa-0 mt-5 grayedBackground"
      elevation="0"
      max-width="800px"
    >
      <!-- Account details -->
      <v-card class="border-thin" elevation="0">
        <!-- Header -->
        <v-card-title class="cardTitle pa-0 pr-4">
          <v-card-actions>
            <v-icon icon="mdi-account-edit" class="ma-2" size="24" />
            <div class="cardTitle">Account details</div>
          </v-card-actions>
        </v-card-title>

        <v-card-text class="border-t-thin pa-0">
          <v-row class="text-left ma-0">
            <v-col cols="8">
              <v-text-field
                density="compact"
                rounded="0"
                variant="outlined"
                class="fields"
                v-model="user_object.name"
                :rules="rules.name"
                label="Name"
                autocomplete="off"
              ></v-text-field>
              <v-btn
                color="success"
                variant="elevated"
                @click="
                  action = 1;
                  dialog = true;
                "
                aria-label="Update name"
                :disabled="user_object?.name === userProfile?.name"
                rounded="0"
              >
                Update name
              </v-btn>
              <br />
              <v-btn
                class="mt-4"
                color="info"
                variant="elevated"
                @click="
                  dialog = true;
                  action = 2;
                "
                aria-label="reset password"
                rounded="0"
              >
                change password
              </v-btn>
            </v-col>
            <v-divider vertical />
            <v-col cols="4" class="grayedText">
              <v-card-actions class="px-0 py-2 align-start">
                <v-icon icon="mdi-email-outline" class="mt-2 mr-1" />
                <v-card-text class="pa-0">
                  <div>
                    <b>Email</b>
                  </div>
                  <div style="word-break: break-word">
                    {{ userProfile?.email }}
                  </div>
                </v-card-text>
              </v-card-actions>

              <v-card-actions class="px-0 py-2 align-start">
                <v-icon icon="mdi-calendar" class="mt-2 mr-1" />
                <v-card-text class="pa-0">
                  <div>
                    <b>User since</b>
                  </div>
                  <div>
                    <!-- Convert date into human language -->
                    {{
                      new Date(userProfile?.created_at).toLocaleDateString(
                        "en-GB",
                        { day: "numeric", month: "long", year: "numeric" },
                      )
                    }}
                  </div>
                </v-card-text>
              </v-card-actions>

              <v-card-actions class="px-0 py-2 align-start">
                <v-icon icon="mdi-briefcase-outline" class="mt-2 mr-1" />
                <v-card-text class="pa-0">
                  <div>
                    <b>Role</b>
                    <v-tooltip>
                      <template #default>
                        <div style="text-align: center">
                          Please reach out to one of your group
                          administrators<br />
                          if you need to update your role or account email
                        </div>
                      </template>
                      <template v-slot:activator="{ props }">
                        <v-icon
                          v-bind="props"
                          class="ml-1"
                          icon="mdi-help-circle"
                          size="x-small"
                        />
                      </template>
                    </v-tooltip>
                  </div>
                  <div>{{ role }}</div>
                </v-card-text>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- TODO: below is there UI we'll add in the future -->
      <!--      &lt;!&ndash; Email preferences &ndash;&gt;
      <v-card class="border-thin mt-6" elevation="0">
        <v-row class="text-left ma-0">
          <v-col class="d-flex">
            <v-icon icon="mdi-email-outline" />
            <div class="text-subtitle-2 ml-3">Email preferences</div>
          </v-col>
        </v-row>
        <v-card-actions
          v-for="(preference, index) in emailPreferences"
          :key="index"
          class="text-left border-t-thin ma-0"
        >
          <v-card-actions class="w-100 pa-0">
            <v-checkbox
              v-model="user_object[index]"
              hide-details
              color="primary"
            />
            <v-card-text class="pl-0">
              <div class="text-body-2">{{ preference.title }}</div>
              <div class="text-caption">
                {{ preference.text }}
              </div>
            </v-card-text>
          </v-card-actions>
        </v-card-actions>

        &lt;!&ndash; Update button &ndash;&gt;
        <v-card-actions class="border-thin pa-4">
          <v-spacer />
          <v-btn color="success" variant="elevated">update preferences</v-btn>
        </v-card-actions>
      </v-card>

      &lt;!&ndash; Delete account &ndash;&gt;
      <v-card class="mt-6" elevation="0">
        <v-card-actions>
          <v-spacer>
            <v-btn color="error" variant="text" class="font-weight-bold"
              >delete account</v-btn
            >
          </v-spacer>
        </v-card-actions>
      </v-card>-->
    </v-card>

    <v-dialog v-model="dialog" scrollable persistent max-width="500px">
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h6 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title
              >{{ action === 1 ? "Confirm your password" : "Reset password" }}
            </v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-card-text v-if="action === 1" class="text-center mt-4 pb-1">
                <v-text-field
                  :type="showPW ? 'text' : 'password'"
                  v-model="user_object.password"
                  :rules="rules.password"
                  :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Password"
                  required
                  density="compact"
                  rounded="0"
                  variant="outlined"
                  class="fields"
                  @click:append="showPW = !showPW"
                  @keyup.enter="submit()"
                  autocomplete="off"
                ></v-text-field>
              </v-card-text>
              <v-card-text
                v-else
                class="text-center mt-4 mb-4"
                style="font-size: 1.07em"
              >
                You will be sent an email with a link to reset your password.
                Are you sure you want to continue?
              </v-card-text>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions v-if="action === 1">
          <v-btn
            color="success"
            variant="elevated"
            tile
            @click="
              dialog = false;
              user_object.password = '';
            "
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            variant="elevated"
            tile
            @click="submit()"
            aria-label="confirm"
          >
            confirm
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn
            color="success"
            variant="elevated"
            rounded="0"
            @click="dialog = false"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="info"
            variant="elevated"
            rounded="0"
            @click="resetPassword()"
            aria-label="reset"
            :disabled="requestNewPWSpinner"
            :loading="requestNewPWSpinner"
          >
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
            Send Link
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import HeaderModule from "@/components/HeaderModule.vue";

export default {
  name: "MyAccount",
  data: () => ({
    user_object: {},
    requestNewPWSpinner: false,
    dialog: false,
    action: 0,
    showPW: false,
    emailPreferences: {
      updates_announcements: {
        title: "Product Updates and Announcements",
        text: "Stay informed about the latest features, platform improvements, and important updates to keep your experience running smoothly.",
      },
      education_material: {
        title: "Content and Educational Material",
        text: "Discover expert tips, tutorials, and industry insights to help you get the most out of our platform and stay ahead of the curve.",
      },
      surveys_feedback_requests: {
        title: "Surveys and Feedback Requests",
        text: "Share your thoughts and shape the future of our product by participating in quick surveys and giving us valuable feedback.",
      },
    },
  }),
  components: {
    HeaderModule,
  },
  computed: {
    userProfile: {
      get() {
        return this.$store.getters.userProfile;
      },
      set(value) {
        this.$store.commit("setUserProfile", value);
      },
    },
    rules() {
      return {
        name: [
          (value) => !!value || "Required.",
          (value) =>
            (value && value.length <= 255) || "Maximum of 255 characters",
          (value) => (value && value.length >= 3) || "Minimum of 2 characters",
        ],
        password: [(value) => !!value || "password required"],
      };
    },
    role() {
      // values of the "permission_level". User gets the highest role assigned to them across all clients
      const roles = {
        1: "Group Admin OCSI Staff",
        2: "Group Admin",
        3: "Power User",
        4: "View Reports",
        5: "Public User",
      };
      return roles[this.$store.getters.permission_level] || "Unknown Role";
    },
  },
  mounted() {
    this.updateForm();
  },
  methods: {
    submit() {
      // snackbar
      this.emit.emit("systemMessage", {
        title: "Updating your name",
        message: "Updating",
        timeout: 4000,
        colour: "warning",
      });

      // send the data to the API
      this.$axios
        .put("/update-user-details", this.user_object)
        .then((response) => {
          this.dialog = false;
          this.emit.emit("systemMessage", {
            title: "User profile updated",
            message: "Success!",
            timeout: 3000,
            colour: "success",
          });

          // update the data
          this.userProfile = response.data;

          // and update the form in the template
          this.updateForm();
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error updating user profile",
            timeout: -1,
            colour: "error",
          });
        })
        .finally(() => {
          // reset the dialog
          this.user_object.password = "";
        });
    },
    updateForm() {
      this.user_object = Object.assign({}, this.userProfile);
    },
    resetPassword() {
      this.requestNewPWSpinner = true;
      let resetPasswordEmail = {
        email: this.$store.state.config.userProfile.email,
      };
      // make the call!
      this.$axios
        .post("/user-reset-password", resetPasswordEmail)
        .then(() => {
          this.emit.emit("systemMessage", {
            message:
              "Check email: " +
              resetPasswordEmail.email +
              " for password reset link",
            title: "Check email",
            timeout: -1,
            colour: "info",
          });
          this.requestNewPWSpinner = false;
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Password reset failed",
            timeout: -1,
            colour: "error",
          });
          this.requestNewPWSpinner = false;
        })
        .finally(() => {
          this.dialog = false;
        });
    },
  },
};
</script>

<style scoped></style>
