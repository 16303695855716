<template>
  <div class="text-center">
    <v-dialog v-model="computedSettingsDialog" max-width="800px" scrollable>
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          <v-btn
            icon
            dark
            @click="computedSettingsDialog = false"
            aria-label="Close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Map Settings </v-toolbar-title>
        </v-toolbar>
        <v-container class="ma-0 pa-0">
          <div class="text-subtitle-1 mt-3 ml-5 mb-2">
            Display data for all areas or focus on 'hotspot' areas.
          </div>
          <v-radio-group v-model="computedHotspotFlag" hide-details>
            <v-list class="ma-0 pa-0">
              <v-list-item
                :ripple="true"
                :class="
                  computedHotspotFlag === 'all'
                    ? 'hoverable-item selected-item'
                    : 'hoverable-item'
                "
                @click="computedHotspotFlag = 'all'"
              >
                <template v-slot:prepend>
                  <v-radio value="all" color="blue-darken-3"></v-radio>
                </template>
                <v-list-item>
                  <v-list-item-title class="pt-3"
                    >Display all data</v-list-item-title
                  >
                  <v-list-item-subtitle class="list-item-subtitle">
                    <ul>
                      <li>
                        Colours represent regions in five different colour
                        bands.
                      </li>
                      <li>
                        Each band represents 20% of the data distribution.
                      </li>
                      <li>
                        The entire map might appear in a single colour if all
                        regions fall into the same band.
                      </li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list-item>
              <v-list-item
                :ripple="true"
                :class="
                  computedHotspotFlag === 'hotspot'
                    ? 'hoverable-item selected-item'
                    : 'hoverable-item'
                "
                @click="computedHotspotFlag = 'hotspot'"
              >
                <template v-slot:prepend>
                  <v-radio value="hotspot" color="blue-darken-3"></v-radio>
                </template>
                <v-list-item>
                  <v-list-item-title class="pt-3"
                    >Display hotspot data</v-list-item-title
                  >
                  <v-list-item-subtitle class="list-item-subtitle">
                    <ul>
                      <li>Only the top 20% of regions are highlighted.</li>
                      <li>
                        Five colour bands indicate regions in the top 1%, 1-5%,
                        5-10%,10-15%, and 15-20%
                      </li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list-item>
              <v-divider v-if="hasBoundary" class="mt-0"></v-divider>
            </v-list>
          </v-radio-group>
          <v-switch
            v-if="hasBoundary"
            label="Show Group Boundary"
            v-model="computedboundaryFlag"
            inset
            color="primary"
            class="pa-0 ma-0 ml-6 mt-5"
          ></v-switch>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-card-subtitle
            v-if="this.$store.state.displayOnsSource"
            class="source"
          >
            <p>
              Source: Office for National Statistics licensed under the Open
              Government Licence v.3.0
            </p>
            <p>
              Contains OS data © Crown copyright and database right
              {{ year }}
            </p>
          </v-card-subtitle>
          <v-spacer></v-spacer>
          <v-btn
            tile
            color="error"
            variant="elevated"
            @click="computedSettingsDialog = false"
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MAPSETTINGS",
  data: () => ({
    selectedItem: null,
    items: [
      {
        value: "option1",
        title: "Option 1",
        bullets: [
          "First point for option 1",
          "Second point for option 1",
          "Third point for option 1",
        ],
      },
      {
        value: "option2",
        title: "Option 2",
        bullets: ["First point for option 2", "Second point for option 2"],
      },
    ],
  }),
  computed: {
    hasBoundary() {
      return this.$store.getters.customClientConfig.has_boundary;
    },
    year() {
      return new Date().getFullYear();
    },
    //a computed to tell if !hasDefaultView or resettingDefaultView
    computedDisabledReset: function () {
      return !this.hasDefaultView || this.resettingDefaultView;
    },
    computedHotspotFlag: {
      get() {
        return this.hotspotFlag;
      },
      set(val) {
        this.$emit("update:hotspotFlag", val);
      },
    },
    computedboundaryFlag: {
      get() {
        return this.boundaryFlag;
      },
      set(val) {
        this.$emit("update:boundaryFlag", val);
      },
    },
    computedSettingsDialog: {
      get() {
        return this.mapSettingsDialog;
      },
      set(value) {
        this.$emit("update:mapSettingsDialog", value);
      },
    },
  },
  components: {
    // FormsInputsAndControls
  },
  props: {
    mapSettingsDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    boundaryFlag: {
      type: Boolean,
      required: true,
      default: false,
    },
    hotspotFlag: {
      type: String,
      required: true,
    },
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
.hoverable-item {
  transition: background-color 0.4s ease-in-out;
}
.hoverable-item:hover {
  background-color: rgba(187, 222, 251);
  transition: background-color 0.4s ease-in-out;
}
.selected-item {
  background-color: rgba(187, 222, 251);
}
.list-item-subtitle {
  display: block !important; /* Ensures subtitle takes up full width */
  white-space: normal !important; /* Prevents text truncation */
  overflow: visible !important; /* Ensures all content is visible */
  height: 64px;
}
.source {
  font-size: 10px;
}
</style>
