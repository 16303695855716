<template>
  <div>
    <!-- Header of the picker -->
    <v-card-text class="pa-0 mb-3 pl-4 text-body-2" style="height: 40px">
      <div class="font-weight-thin">{{ headerText }}</div>
      <div class="font-weight-medium d-flex mt-1">
        <a class="date mr-2" @click="show_year = false">{{
          months[modelValue?.month] || "Month"
        }}</a>

        <a class="date mr-2" @click="show_year = true">{{
          modelValue?.year || "Year"
        }}</a>
      </div>
    </v-card-text>

    <v-divider class="mb-1" />
    <v-date-picker-years
      v-if="show_year"
      v-model="modelYear"
      class="pa-0 bg-white"
      color="#8ACEFF9C"
      elevation="24"
      @update:modelValue="show_year = false"
    />

    <v-date-picker-months
      v-else
      v-model="modelMonth"
      class="pa-0 bg-white"
      color="#8ACEFF9C"
      elevation="24"
    />
  </div>
</template>

<script>
export default {
  name: "YearMonthPicker",
  data: () => ({
    show_year: true,
    months: {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    },
  }),
  props: {
    /** Model value of the component
     *  @returns { year: <YEAR>, month: <MONTH>, label: <LABEL> }
     */
    modelValue: {
      required: true,
    },
    headerText: {
      type: String,
      default: "",
      required: false,
    },
  },
  computed: {
    modelYear: {
      get() {
        return this.modelValue?.year || new Date().getFullYear();
      },
      set(val) {
        this.$emit("update:modelValue", {
          ...(this.modelValue || {}),
          year: val,
          label: `${this.months[this.modelMonth + 1]} ${val}`, // Update label
          sqlVersion: `${val}-${this.months[this.modelMonth + 1]}`, // Update sqlVersion
        });
      },
    },
    modelMonth: {
      get() {
        return this.modelValue?.month - 1; // Adjust the displayed month
      },
      set(val) {
        this.$emit("update:modelValue", {
          ...(this.modelValue || {}),
          month: val + 1, // Increment the month value by 1 when emitting
          label: `${this.months[val + 1]} ${this.modelYear}`, // "Jan 2024"
          sqlVersion: `${this.modelYear}-${this.months[val + 1]}`, // "2024-1"
        });
      },
    },
  },
  mounted() {
    // default to January
    this.modelMonth = 0;
  },
};
</script>

<style scoped>
.date {
  color: #1867c0;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
