<template>
  <v-window v-model="step">
    <v-window-item :value="1">
      <v-container class="pr-0 mr-0" fill-height>
        <v-row style="margin: -16px">
          <v-col class="loginLhc d-flex align-center justify-center">
            <v-form ref="login" v-model="valid" lazy-validation>
              <v-toolbar-title
                class="display-1 text-center font-weight-medium my-10"
              >
                {{ greeting() }}
              </v-toolbar-title>
              <v-text-field
                id="2"
                type="email"
                v-model="loginDetails.email"
                :rules="emailRules"
                label="Email"
                required
                variant="outlined"
                density="compact"
                rounded="0"
                class="fields mb-2"
                autocomplete="off"
              ></v-text-field>
              <v-text-field
                id="4"
                :type="showPW ? 'text' : 'password'"
                v-model="loginDetails.password"
                :rules="passwordRules"
                :append-inner-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
                label="Password"
                required
                variant="outlined"
                density="compact"
                rounded="0"
                @click:append-inner="showPW = !showPW"
                @keyup.enter="login"
                class="fields mb-2"
                autocomplete="off"
              ></v-text-field>
              <div class="mb-3 text-center">
                <v-btn
                  id="login"
                  width="350px"
                  tile
                  :disabled="!valid"
                  :color="this.$store.state.config.siteConfig.toolbar_colour"
                  variant="elevated"
                  :style="{ color: isBrightColour ? 'black' : 'white' }"
                  @click="login"
                  rounded="0"
                  aria-label="Log in"
                  :loading="logginInSpinner"
                >
                  Log in
                </v-btn>
              </div>
              <div class="mb-3 text-center">
                <v-btn
                  class="text-subtitle-1"
                  width="350px"
                  variant="text"
                  tile
                  color="#4f637d"
                  @click="passwordAdminDialog = true"
                  aria-label="reset password"
                >
                  Reset password
                </v-btn>
              </div>
            </v-form>
          </v-col>
          <v-col
            class="loginRhc d-flex align-center justify-center col-lg-7"
            :style="'background:' + backgroundColor"
          >
            <div class="d-flex flex-column align-center justify-center">
              <div
                v-if="
                  this.$store.state.config.siteConfig.page_title ===
                  'Local Insight'
                "
                class="zoomInDown"
              >
                <!--    White or black logo depending on the toolbar colour   -->
                <v-img
                  v-if="isBrightColour"
                  :src="require('@/assets/localInsight.png')"
                  :width="250"
                  :height="75"
                  class="mx-2"
                  contain
                  alt="Site Logo"
                ></v-img>
                <v-img
                  v-else
                  :src="
                    require('@/assets/LI_logo_Horizontal_darkbackground.png')
                  "
                  :width="250"
                  :height="75"
                  class="mx-2"
                  contain
                  alt="Site Logo"
                ></v-img>
              </div>
              <v-img
                v-else
                class="mx-2 zoomInDown"
                :width="250"
                :height="75"
                contain
                :src="$store.state.config.siteConfig.logo"
              ></v-img>
              <p
                :style="{
                  color: isBrightColour ? 'black' : 'white',
                  width: demoWidth,
                }"
                style="font-size: 27px; font-weight: 300"
                class="mt-6 welcomeText"
              >
                Welcome to {{ this.$store.state.config.siteConfig.page_title }}
              </p>
              <p
                v-if="!this.$store.state.config.siteConfig.is_public_site"
                :style="{
                  color: isBrightColour ? 'black' : 'white',
                  width: demoWidth,
                }"
                style="font-size: 15px; font-weight: 300"
                class="mt-5"
              >
                Local Insight is a user-friendly platform that brings together
                small-area data, helping you to make informed decisions for the
                benefit of your community.
              </p>
              <p
                v-if="!this.$store.state.config.siteConfig.is_public_site"
                :style="{
                  color: isBrightColour ? 'black' : 'white',
                  width: demoWidth,
                }"
                style="font-size: 15px; font-weight: 300"
                class="mt-5"
              >
                Unlock the power of place-based data and book a personalised
                demo today
              </p>
              <v-btn
                v-if="!this.$store.state.config.siteConfig.is_public_site"
                class="text-subtitle-1 mt-5"
                style="min-height: 1.75em"
                variant="outlined"
                tile
                :href="demoLink"
                aria-label="book a demo"
              >
                Book a demo
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-dialog v-model="passwordAdminDialog" width="400" persistent>
          <v-card
            ><v-toolbar
              :style="
                'background-color: ' +
                this.$store.state.config.siteConfig.toolbar_colour
              "
            >
              <v-toolbar-title class="text-center">{{
                passwordAdminDisplay.header
              }}</v-toolbar-title></v-toolbar
            >
            <v-card-text class="text-left text-body-2">
              {{ passwordAdminDisplay.message }}
            </v-card-text>
            <v-card-actions
              v-if="passwordAdminDisplay.step == 'request'"
              class="text-left"
              style="justify-content: center"
            >
              <v-form ref="pwrqst" v-model="valid" lazy-validation>
                <v-text-field
                  id="1"
                  type="email"
                  v-model="forgottenPWEmail"
                  :rules="emailRules"
                  label="Please enter your email address"
                  required
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  class="fields"
                  autocomplete="off"
                ></v-text-field>
              </v-form>
            </v-card-actions>
            <v-card-actions v-else-if="passwordAdminDisplay.step == 'update'">
              <v-form ref="pwrst" v-model="valid" lazy-validation>
                <v-text-field
                  type="password"
                  v-model="newPW"
                  :rules="[
                    newPW.length >= 8
                      ? true
                      : 'Password at least 8 characters long',
                  ]"
                  label="New Password"
                  required
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  class="fields"
                  autocomplete="off"
                ></v-text-field>
                <v-text-field
                  type="password"
                  v-model="confirmNewPW"
                  :rules="[
                    confirmNewPW == newPW ? true : 'Passwords must match!',
                  ]"
                  label="Confirm Password"
                  required
                  variant="outlined"
                  density="compact"
                  rounded="0"
                  class="fields"
                  autocomplete="off"
                ></v-text-field>
              </v-form>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="success"
                class=""
                tile
                variant="elevated"
                @click="passwordAdminDialog = false"
                aria-label="close"
              >
                close
              </v-btn>
              <v-spacer />
              <v-btn
                :disabled="buttonSpinner"
                :loading="buttonSpinner"
                color="error"
                tile
                variant="elevated"
                @click="
                  passwordAdminDisplay.step === 'request'
                    ? requestNewPassword()
                    : submitNewPassword()
                "
                v-if="passwordAdminDisplay.button"
                aria-label="submit"
              >
                {{ passwordAdminDisplay.button }}
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-window-item>
  </v-window>
</template>

<script>
import { getURLParam } from "@/mixins/GetURLParam";
import { setCookie } from "@/mixins/SetCookie";
import { getCookie } from "@/mixins/GetCookie";
import { systemMessages } from "@/mixins/SystemMessages";

export default {
  name: "USERLOGIN",
  mixins: [systemMessages],
  data: () => ({
    logginInSpinner: false,
    passwordAdminDialog: false,
    buttonSpinner: false,
    forgottenPWEmail: null,
    newPW: "",
    confirmNewPW: null,
    requestSent: false,
    resetComplete: false,
    loginDetails: {
      email: "",
      password: "",
    },
    valid: false, // Start with false
    showPW: false,
    emailRules: [
      (v) => {
        const result = !!v || "Please enter your email address";
        return result;
      },
      (v) => {
        const result =
          /.+@.+\..+/.test(v) || "Please enter a valid email address";
        return result;
      },
    ],
    passwordRules: [
      (v) => {
        const result = !!v || "Please enter your password";
        return result;
      },
    ],
    step: 1,
    validation: false,
    referrer: null,
  }),
  props: {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.referrer = from;
    });
  },
  mounted() {
    if (getURLParam("token")) {
      this.passwordAdminDialog = true;
    }
  },
  computed: {
    passwordAdminDisplay: {
      get() {
        var token = getURLParam("token");
        if (token && !this.resetComplete) {
          return {
            step: "update",
            header: "Reset Your Password",
            message: "Please Reset your password using the fields below",
            button: "Reset Password",
            token: token,
          };
        } else if (token && this.resetComplete) {
          return {
            step: "resetComplete",
            header: "Reset Your Password",
            message: "Password Updated! Please log in using your new password.",
            button: null,
            token: null,
          };
        } else if (this.requestSent) {
          return {
            step: "requestSent",
            header: "Forgotten Password",
            message:
              "We have sent you an email with a link to reset your password, be sure to check your junk mail if it does not arrive in the next few minutes",
            button: null,
            token: null,
          };
        } else {
          return {
            step: "request",
            header: "Forgotten Password",
            message:
              "Forgot your password? Don't worry, it happens! Please enter your email address and request a new one.",
            button: "Request",
            token: token,
          };
        }
      },
    },
    backgroundColor() {
      if (this.$store.state.config.siteConfig.id === 7) {
        return "linear-gradient(to bottom, #4f637d, #48939e, #3ed5cb)";
      } else {
        return (
          "linear-gradient(to bottom, #4f637d," +
          this.$store.state.config.siteConfig.toolbar_colour +
          " )"
        );
      }
    },
    isBrightColour() {
      let backgroundColor = this.$store.state.config.siteConfig.toolbar_colour;
      let r = parseInt(backgroundColor.substr(1, 2), 16);
      let g = parseInt(backgroundColor.substr(3, 2), 16);
      let b = parseInt(backgroundColor.substr(5, 2), 16);

      // measure brightness
      let brightness = (r * 299 + g * 587 + b * 114) / 1000;

      // if brightness is greater than 174, it's a light colour
      return brightness > 174;
    },
    demoWidth() {
      if (this.$vuetify.display.mdAndDown) {
        return "90%";
      }
      if (this.$vuetify.display.lgAndDown) {
        return "75%";
      }
      return "50%";
    },
    demoLink() {
      //Aus needs different link - may need centrally localized config if too much of this pops up
      if (
        this.$store.state.config.siteConfig.site_country.toUpperCase() === "AUS"
      ) {
        return "https://communityinsightaustralia.org/contact-us/";
      }
      return "https://localinsight.org/book-a-demo?utm_source=local-insight-platform+&utm_medium=button&utm_campaign=book-demo";
    },
  },
  methods: {
    requestNewPassword() {
      if (this.$refs.pwrqst.validate()) {
        // set these
        this.buttonSpinner = true;
        let forgottenEmail = {
          email: this.forgottenPWEmail,
        };
        // call api
        this.$axios
          .post("/password-forgotten", forgottenEmail)
          .then(() => {
            // success from ajax call
            this.requestSent = true;
            this.buttonSpinner = false;
          })
          .catch((error) => {
            // handle error response
            this.buttonSpinner = false;
            this.systemError = error.response.statusText;
            this.apiValidationErrors(error.response.data.errors);
            this.emit.emit("systemMessage", {
              title: "There is a problem with that email address...",
              message: error.response.data.message,
              timeout: 5000,
              colour: "warning",
            });
          });
      }
    },
    submitNewPassword() {
      if (this.$refs.pwrst.validate()) {
        // set these
        this.buttonSpinner = true;
        let newPassword = {
          token: this.passwordAdminDisplay.token,
          password: this.newPW,
          password_confirmation: this.confirmNewPW,
        };
        // call api
        this.$axios
          .post("/password-reset", newPassword)
          .then(() => {
            // success from ajax call
            this.resetComplete = true;
            this.buttonSpinner = false;
            setTimeout(() => {
              window.history.replaceState(
                null,
                "",
                window.location.origin + "/#/login",
              );
            }, 500);
          })
          .catch((error) => {
            setTimeout(() => {
              window.history.replaceState(
                null,
                "",
                window.location.origin + "/#/login",
              );
            }, 500);
            // handle error response
            this.buttonSpinner = false;
            this.systemError = error.response.statusText;
            this.emit.emit("systemMessage", {
              title: "Please contact support",
              message: error.response.data.message,
              timeout: 5000,
              colour: "error",
            });
          });
      }
    },
    greeting() {
      var date = new Date();
      var hrs = date.getHours();
      if (hrs < 4) return "Hello Night Owl!";
      else if (hrs >= 4 && hrs <= 6) return "Good Morning Early Bird!";
      else if (hrs >= 6 && hrs <= 12) return "Good Morning!";
      else if (hrs >= 12 && hrs <= 17) return "Good Afternoon!";
      else if (hrs >= 17 && hrs <= 24) return "Good Evening!";
    },
    login() {
      document.cookie =
        "OI_PUBLIC_SITE=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      if (this.$refs.login.validate()) {
        this.logginInSpinner = true;
        // make the call!
        this.$axios
          .post("/login", this.loginDetails)
          .then((response) => {
            this.closeMsg(); //close any open snackbars

            // set the bearer token cookie for 2 hours, add it to the axios auth header
            setCookie("BEARER_TOKEN", response.data.token, 2);
            this.$axios.defaults.headers.common["Authorization"] =
              "Bearer " + getCookie("BEARER_TOKEN");

            /**
             * call App.vue getClientAndUserConfig to fetch user/site details
             * pass in any referrer route obect captured by beforeRouteEnter when component
             * invoked
             */
            this.emit.emit("getClientAndUserConfig", this.referrer);
            setTimeout(() => {
              this.logginInSpinner = false;
            }, 5000);
          })
          .catch((error) => {
            this.logginInSpinner = false;
            this.systemError = error.response.statusText;
            if (error.response.status === 403) {
              this.suspendedMsg({
                title: error.response.data.title,
                message: error.response.data.message,
              });
            } else {
              this.emit.emit("systemMessage", {
                title: "Wrong Credentials!",
                message: error.response.data.message,
                timeout: 5000,
                colour: "warning",
              });
            }
          });
      }
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
p {
  font-family: Inter;
}
.fields {
  text-align: left;
  width: 350px;
}
.loginRhc {
  height: 100vh;
  font-size: 84px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  border-bottom-left-radius: 500px !important;
}

.loginLhc {
  height: 100vh;
  background-color: #fff;
}

@media only screen and (max-width: 824px) {
  .loginRhc {
    display: none !important;
  }
  .fields {
    width: 310px;
  }
}

.loginLhc {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*zoom logo*/
.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
</style>
