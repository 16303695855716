<template>
  <div class="h-100 mt-4 ml-4 mb-4">
    <v-row>
      <!--- Themes Card -->
      <v-col cols="12" md="4">
        <!-- title -->
        <v-card
          class="text-left border-b rounded-t-lg py-1"
          rounded="0"
          style="height: 54px !important"
        >
          <v-card-title>
            <div class="d-flex justify-space-between">
              <span>Themes</span>
              <!-- reset button (Group Admins only) -->
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn
                    icon="mdi-dots-vertical"
                    height="24px"
                    width="24px"
                    variant="text"
                    v-bind="props"
                  />
                </template>

                <v-list class="pa-0">
                  <v-list-item
                    @click="showCreateThemeDialog = true"
                    @keydown.enter="showCreateThemeDialog = true"
                    prepend-icon="mdi-plus"
                  >
                    Create theme
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-if="!fetchingThemes && this.$store.state.isGroupAdmin"
                    color="error"
                    @click="showResetThemesDialog = true"
                    @keydown.enter="showResetThemesDialog = true"
                    style="color: #b00020"
                    prepend-icon="mdi-restore"
                  >
                    Restore default themes
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card-title>
        </v-card>
        <v-card
          :style="{
            overflowY: 'auto',
            height: `${pageHeight - 329}px`,
            overflowX: 'hidden',
          }"
          class="rounded-b-0"
        >
          <v-card-text class="pa-0">
            <v-skeleton-loader
              v-if="fetchingThemes == true"
              class="mx-auto"
              type="list-item, list-item, list-item, list-item, list-item, list-item"
            ></v-skeleton-loader>
            <v-list v-if="fetchingThemes == false" class="pa-0">
              <Draggable
                v-model="themes"
                item-key="id"
                @end="updateDraggablePosition('themes')"
                :disabled="themesUpdating"
              >
                <template #item="{ element, index }">
                  <v-list-item
                    :key="element.id"
                    density="compact"
                    class="theme-btn pa-0 text-none justify-space-between"
                    :class="{ 'theme-selected': selectedThemeId == element.id }"
                    :active="selectedThemeId == element.id"
                    width="100%"
                    min-height="20px"
                    @click="selectTheme(element.id)"
                    @keydown="
                      updateDraggableByKeyPress($event, index, 'themes')
                    "
                    :id="'themes_' + index"
                  >
                    <v-btn
                      title="drag to re-order"
                      aria-label="drag to re-order"
                      style="cursor: move !important"
                      variant="text"
                      icon="mdi-drag"
                    />
                    <div
                      style="
                        text-align: left;
                        word-wrap: break-word;
                        white-space: normal;
                        width: 71%;
                        overflow: hidden;
                      "
                    >
                      {{ element.name }}
                    </div>
                    <v-spacer />
                    <v-chip size="small" class="mr-2">{{
                      element.number_of_indicators
                    }}</v-chip>
                  </v-list-item>
                </template>
              </Draggable>
            </v-list>
          </v-card-text>
        </v-card>
        <v-card class="rounded-t-0 rounded-b-lg pa-0">
          <v-card-actions class="pa-0">
            <v-btn
              block
              height="52px"
              prepend-icon="mdi-plus"
              color="primary"
              @click="showCreateThemeDialog = true"
              >Create Theme</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Indicators Card -->
      <v-col cols="12" md="8">
        <!-- theme title-->
        <v-card
          class="text-left border-b rounded-t-lg pa-0"
          rounded="0"
          style="height: 54px !important"
        >
          <v-card-title class="d-flex align-center pl-0">
            <!-- theme edit/delete -->
            <v-menu location="end">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-if="selectedThemeId && !themesUpdating && !fetchingThemes"
                  id="theme-actions"
                  class="ml-2 d-flex"
                  v-bind="props"
                  variant="text"
                  rounded="xl"
                  style="max-width: 75%"
                >
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      max-width: 590px;
                    "
                  >
                    {{ selectedThemeName }}
                  </div>
                  <v-icon
                    style="flex-shrink: 0; margin-left: 8px"
                    icon="mdi-dots-vertical"
                  />
                </v-btn>
              </template>
              <v-list density="compact" class="pa-0" width="210px">
                <v-list-item class="pa-0">
                  <v-btn
                    variant="text"
                    class="w-100 justify-start"
                    @click="showThemeRenameDialog = true"
                  >
                    <v-icon icon="mdi-pencil" size="24"></v-icon>
                    <span class="ml-3 text-body-1">Rename</span>
                  </v-btn>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item class="pa-0">
                  <v-btn
                    variant="text"
                    class="w-100 justify-start text-error"
                    @click="showDeleteThemeDialog = true"
                  >
                    <v-icon icon="mdi-delete" size="24"></v-icon>
                    <span class="ml-3 text-body-1">Delete</span>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer />
            <v-btn
              color="info"
              height="37px"
              prepend-icon="mdi-plus"
              rounded="0"
              @click="addIndicators"
            >
              Add Indicator
            </v-btn>
          </v-card-title>
        </v-card>
        <v-card
          :style="{
            overflowY: 'auto',
            height: `${pageHeight - 277}px`,
            overflowX: 'hidden',
          }"
          class="rounded-t-0 rounded-b-lg"
        >
          <v-card-text class="pa-0">
            <!-- indicators skeleton loader -->
            <v-skeleton-loader
              v-if="fetchingIndicators == true || fetchingThemes == true"
              class="mx-auto"
              type="table-thead, table-tbody"
            ></v-skeleton-loader>
            <v-card-actions
              v-if="
                indicators.length === 0 &&
                !fetchingIndicators &&
                !fetchingThemes
              "
              class="mx-auto d-flex justify-center align-center"
              style="width: 360px; min-height: 70vh"
            >
              <AvatarState avatarSize="102" icon="mdi-layers" iconSize="54">
                <template #title>This theme is empty</template>
                <template #body>
                  Add indicators to this theme to organise your data and uncover
                  meaningful patterns in your maps and dashboards.
                </template>
                <template #footer>
                  <div>
                    <v-btn
                      prepend-icon="mdi-plus"
                      variant="elevated"
                      color="primary"
                      @click="addIndicators"
                      rounded="0"
                      class="w-100 mt-5 mb-1"
                      >Add indicators</v-btn
                    >
                  </div>
                  <div>
                    <v-btn
                      variant="text"
                      density="compact"
                      color="primary"
                      class="w-50 mt-3"
                      height="30px"
                      href="https://support.localinsight.org/en/articles/270653-adding-a-new-indicator-to-a-theme"
                      target="_blank"
                      block
                      >Learn more</v-btn
                    >
                  </div>
                </template>
              </AvatarState>
            </v-card-actions>

            <!-- indicators table -->
            <v-data-table
              :headers="headers"
              :items="indicators"
              fixed-header
              id="indicatorTable"
              v-if="
                indicators.length > 0 && !fetchingIndicators && !fetchingThemes
              "
            >
              <!-- indicator rows -->
              <template #body>
                <Draggable
                  v-model="indicators"
                  item-key="id"
                  style="display: contents"
                  @end="updateDraggablePosition('indicators')"
                  :disabled="indicatorsUpdating"
                >
                  <!--  to customize draggable table rows with `item.string` we need to add a custom component as that lets us to have template in template -->
                  <template v-slot:item="{ element, index }">
                    <data-table-row-handler :item="element" :headers="headers">
                      <!-- draggable -->
                      <template v-slot:[`item.ordering`]="{}">
                        <v-btn
                          variant="text"
                          class="mr-3 drag-handle"
                          icon="mdi-drag"
                          :id="'indicators_' + index"
                          aria-describedby="indicator-position-change-instructions"
                          aria-label="drag to re-order"
                          style="cursor: move !important"
                          label="drag to re-order"
                          title="drag to re-order"
                          @keydown="
                            updateDraggableByKeyPress(
                              $event,
                              index,
                              'indicators',
                            )
                          "
                        />
                      </template>
                      <!-- indicator name -->
                      <template v-slot:[`item.indicatorName`]="{ item }">
                        <div class="text-left indicatorName">
                          {{
                            standardMetadata[item.indicatorCode]?.indicator_name
                          }}
                        </div>
                      </template>
                      <!-- metadata-->
                      <template v-slot:[`item.metadata`]="{ item }">
                        <v-btn
                          variant="text"
                          id="metaDataIcon"
                          icon="mdi-information"
                          title="metadata information"
                          aria-label="metadata information"
                          label="metadata information"
                          @click="
                            selectedIndicatorMeta = item;
                            showIndicatorMetaDialog = true;
                          "
                        >
                          <template v-slot:default>
                            <v-icon color="rgba(14, 91, 153, 1)"></v-icon>
                          </template>
                        </v-btn>
                      </template>
                      <!-- indicator type -->
                      <template v-slot:[`item.indicatorType`]="{ item }">
                        <v-chip
                          :class="
                            standardMetadata[item.indicatorCode]?.client_id
                              ? 'custom-chip'
                              : 'ocsi-chip'
                          "
                        >
                          {{
                            standardMetadata[item.indicatorCode]?.client_id
                              ? "Custom Data"
                              : "OCSI Data"
                          }}
                        </v-chip>
                      </template>
                      <!-- map checkbox -->
                      <template v-slot:[`item.map`]>
                        <v-layout
                          style="display: flex; justify-content: center"
                        >
                          <v-checkbox
                            v-model="element.map"
                            :id="'mapcheckbox_' + index"
                            tabindex="0"
                            hide-details
                            class="narrowedCheckbox"
                            color="primary"
                            aria-label="show on Map"
                            title="Show on Map"
                            @change="updateSingleIndicator(element)"
                            :disabled="indicatorsUpdating"
                          />
                        </v-layout>
                      </template>
                      <!-- dashboard checkbox -->
                      <template v-slot:[`item.dashboard`]>
                        <v-layout
                          style="display: flex; justify-content: center"
                        >
                          <v-checkbox
                            v-model="element.dashboard"
                            :id="'dashboardcheckbox_' + index"
                            tabindex="0"
                            hide-details
                            class="narrowedCheckbox"
                            color="primary"
                            aria-label="show on dashboard"
                            title="Show on Dashboard"
                            @change="updateSingleIndicator(element)"
                            :disabled="indicatorsUpdating"
                          />
                        </v-layout>
                      </template>
                      <!-- public site checkbox -->
                      <template v-slot:[`item.publicSite`]>
                        <v-layout
                          style="display: flex; justify-content: center"
                        >
                          <v-checkbox
                            v-model="element.publicSite"
                            tabindex="0"
                            hide-details
                            class="narrowedCheckbox"
                            color="primary"
                            :id="'publicSitecheckbox_' + index"
                            aria-label="show on Public Site"
                            title="Show on your public site"
                            @change="updateSingleIndicator(element)"
                            :disabled="indicatorsUpdating"
                          />
                        </v-layout>
                      </template>
                      <!-- indicator remove -->
                      <template v-slot:[`item.remove`]="{}">
                        <v-icon
                          class="mr-5 deleteIndicator"
                          small
                          color="rgba(176, 0, 32, 1)"
                          aria-label="delete indicator"
                          aria-hidden="false"
                          label="delete indicator from theme"
                          title="delete indicator from theme"
                          @click.stop="
                            deleteIndicatorId = element.id;
                            showDeleteIndicatorDialog = true;
                          "
                          @keydown.enter="
                            deleteIndicatorId = element.id;
                            showDeleteIndicatorDialog = true;
                          "
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </data-table-row-handler>
                  </template>
                </Draggable>
              </template>
              <!-- hide footer -->
              <template #bottom></template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Create Theme Dialog -->
    <v-dialog v-model="showCreateThemeDialog" max-width="500px">
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          class="text-h6"
        >
          <v-btn
            tile
            icon="mdi-close"
            dark
            @click="showCreateThemeDialog = false"
            aria-label="Close"
          />
          <v-toolbar-title>Create Theme</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="mb-0 pb-0">
          <p>Enter a name for this theme</p>
          <v-text-field
            class="mt-3"
            v-model="newThemeName"
            label="Theme name"
            density="compact"
            rounded="0"
            variant="outlined"
            :rules="[(v) => !!v || 'Theme name is required']"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="mt-0 pt-0">
          <v-btn text @click="showCreateThemeDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!newThemeName"
            color="success"
            tile
            variant="elevated"
            @click="createTheme"
          >
            Create Theme
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Indicator Confirmation Dialog -->
    <DynamicDialog
      :show="showDeleteIndicatorDialog"
      @dialogOk="deleteIndicator()"
      @dialogCancel="showDeleteIndicatorDialog = false"
      @update:showDyamicDialog="(state) => (showDeleteDialog = state)"
      max-width="500"
      okBtnColor="error"
      cancelBtnColor="none"
      cancelBtnVariant="text"
    >
      <template v-slot:title>Remove Indicator</template>
      <template v-slot:content>
        <p class="pb-4">
          Are you sure you want to remove this indicator from the theme?
        </p>
      </template>
      <template v-slot:okBtnTitle>REMOVE INDICATOR</template>
    </DynamicDialog>

    <!-- indicator metadata dialog -->
    <IndicatorDetailsDialog
      v-model:dialog="showIndicatorMetaDialog"
      :indicator="standardMetadata[selectedIndicatorMeta?.indicatorCode]"
    />

    <!-- theme rename dialog -->
    <DynamicDialog
      :show="showThemeRenameDialog"
      @dialogOk="updateThemeDetails()"
      @dialogCancel="showThemeRenameDialog = false"
      @update:showDyamicDialog="(state) => (showDeleteDialog = state)"
      :okBtnDisabled="selectedThemeName.length == 0"
      max-width="500"
      okBtnColor="success"
      cancelBtnColor="none"
      cancelBtnVariant="text"
    >
      <template v-slot:title>Rename Theme</template>
      <template v-slot:content>
        <p class="pb-4">Rename this theme</p>
        <v-text-field
          class="mt-2"
          density="compact"
          rounded="0"
          @keydown.enter="updateThemeDetails()"
          label="Theme Name"
          variant="outlined"
          required
          v-model="selectedThemeName"
          autocomplete="off"
        ></v-text-field>
      </template>
      <template v-slot:okBtnTitle>RENAME THEME</template>
    </DynamicDialog>

    <!-- theme delete dialog -->
    <DynamicDialog
      :show="showDeleteThemeDialog"
      @dialogOk="deleteTheme()"
      @dialogCancel="showDeleteThemeDialog = false"
      @update:showDyamicDialog="(state) => (showDeleteDialog = state)"
      max-width="500"
      okBtnColor="error"
      cancelBtnColor="none"
      cancelBtnVariant="text"
    >
      <template v-slot:title>Delete Theme</template>
      <template v-slot:content>
        <p class="pb-4">
          Are you sure you want to permanently delete the theme: "{{
            selectedThemeName
          }}"?
        </p>
        <p class="pb-4">
          This action cannot be undone and any associated indicators will no
          longer be accessible under this theme.
        </p>
      </template>
      <template v-slot:okBtnTitle>DELETE THEME</template>
    </DynamicDialog>

    <!-- reset themes dialog -->
    <DynamicDialog
      :show="showResetThemesDialog"
      @dialogOk="resetThemes()"
      @dialogCancel="showResetThemesDialog = false"
      @update:showDyamicDialog="(state) => (showDeleteDialog = state)"
      max-width="500"
      okBtnColor="error"
      cancelBtnColor="none"
      cancelBtnVariant="text"
    >
      <template v-slot:title>Confirm Reset</template>
      <template v-slot:content>
        <p class="pb-4">
          Are you sure you want to reset all themes to the default set? This
          will also reset your Default Dashboard Themes. This action cannot be
          undone.
        </p>
      </template>
      <template v-slot:okBtnTitle>RESET TO DEFAULT THEMES</template>
    </DynamicDialog>
  </div>
</template>
<script>
import { useDisplay } from "vuetify";
import Draggable from "vuedraggable";
import { systemMessages } from "@/mixins/SystemMessages";
import { Debounce } from "@/mixins/Debounce";
import DataTableRowHandler from "@/components/DataTableRowHandler.vue";
import AvatarState from "@/components/AvatarState.vue";
import DynamicDialog from "@/components/DynamicDialog";
import IndicatorDetailsDialog from "@/components/IndicatorDetailsDialog";

export default {
  name: "ThemeManagerManage",
  components: {
    Draggable,
    DataTableRowHandler,
    DynamicDialog,
    IndicatorDetailsDialog,
    AvatarState,
  },
  mixins: [systemMessages, Debounce],
  data: () => ({
    pageHeight: useDisplay().height,
    fetchingThemes: false,
    fetchingIndicators: false,
    themesPreviousState: [],
    selectedThemeId: null,
    indicatorsPreviousState: [],
    standardMetadata: {},
    debouncedUpdateDraggablePosition: null,
    themesUpdating: false,
    indicatorsUpdating: false,
    showCreateThemeDialog: false,
    newThemeName: "",
    showDeleteIndicatorDialog: false,
    deleteIndicatorId: null,
    showIndicatorMetaDialog: false,
    selectedIndicatorMeta: null,
    showThemeRenameDialog: false,
    showDeleteThemeDialog: false,
    showResetThemesDialog: false,
  }),
  mounted() {
    this.debouncedUpdateDraggablePosition = this.debounce(
      this.updateDraggablePosition,
      1000,
    );
    if (this.$route.params.themeId) {
      this.selectTheme(this.$route.params.themeId);
    }
    this.fetchThemes().then(() => {
      if (this.themes.length && !this.selectedThemeId) {
        this.selectTheme(this.themes[0].id);
      }
    });
  },
  computed: {
    selectedThemeName: {
      get() {
        return (
          this.themes.find((t) => t.id == this.selectedThemeId)?.name || ""
        );
      },
      set(val) {
        this.themes.find((t) => t.id == this.selectedThemeId).name = val;
      },
    },
    themes: {
      get() {
        return this.$store.getters["themesManager/themes"];
      },
      set(val) {
        this.$store.commit("themesManager/setThemes", val);
      },
    },
    indicators: {
      get() {
        return this.$store.getters["themesManager/indicators"];
      },
      set(val) {
        this.$store.commit("themesManager/setIndicators", val);
      },
    },
    headers: {
      get() {
        let result = [
          {
            title: "",
            value: "ordering",
            sortable: false,
          },
          {
            title: "Indicator name",
            value: "indicatorName",
            sortable: false,
          },
          {
            title: "",
            value: "metadata",
            sortable: false,
          },
          {
            title: "Indicator Type",
            value: "indicatorType",
            sortable: false,
          },
          { title: "Map", value: "map", align: "center", sortable: false },
          {
            title: "Default Dashboard",
            value: "dashboard",
            align: "center",
            sortable: false,
          },
          {
            title: "",
            value: "remove",
            sortable: false,
          },
        ];
        if (this.$store.state.config.siteConfig.is_public_site) {
          result.splice(result.length - 1, 0, {
            title: "Public Site",
            value: "publicSite",
            align: "center",
            sortable: false,
          });
        }
        return result;
      },
    },
  },
  methods: {
    /** fetch all themes from the backend */
    async fetchThemes() {
      try {
        this.fetchingThemes = true;
        const response = await this.$axios.get("/themes-manager-themes");
        this.themes = response.data;
        this.fetchingThemes = false;
      } catch (error) {
        this.fetchingThemes = false;
        this.errorMsg({
          title: "Error! Failed to get all the themes",
          message: error.response.data.message,
        });
      }
    },
    selectTheme(themeId, selectedTabRoute = "manage") {
      this.selectedThemeId = themeId;
      this.$router.push({
        name: "Themes Manager",
        params: { selectedTabRoute, themeId },
      });
    },
    createTheme() {
      this.emit.emit("systemMessage", {
        title: "Creating new theme",
        message: "Please wait",
        timeout: -1,
        colour: "warning",
      });

      const newTheme = { name: this.newThemeName };

      this.$axios
        .post("/themes", newTheme)
        .then(
          function (response) {
            // handle success
            this.showCreateThemeDialog = false;
            this.emit.emit("systemMessage", {
              title: "Success!",
              message: "New theme created",
              timeout: 3000,
              colour: "green",
            });
            this.fetchThemes(); // Refresh the themes list
            this.newThemeName = "";

            // Navigate to the newly created theme and select it
            const newThemeId = response.data.id;
            this.selectTheme(newThemeId);
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to create new theme",
              message: error.response.data.message,
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    /** fetch indicators for selected theme */
    async fetchindicators(themeId) {
      try {
        this.fetchingIndicators = true;
        const response = await this.$axios.get(
          "/themes-manager-indicators/" + themeId,
        );
        //set indicator details
        this.indicators = response.data.indicators;
        //set indicator meta data detail from response
        this.standardMetadata = {};
        response.data.metadata.forEach((element) => {
          this.standardMetadata[element.indicator_code] = element;
        });
        this.fetchingIndicators = false;
      } catch (error) {
        this.fetchingIndicators = false;
        this.errorMsg({
          title: "Error! Failed to get the indicators for the selected theme",
          message: error.response.data.message,
        });
      }
    },
    /** update a single theme indicator fillable attributes */
    async updateSingleIndicator(indicator) {
      try {
        this.indicatorsUpdating = true;

        this.warningPill({
          icon: "mdi-content-save",
          title: `Updating indicator`,
          timeout: -1,
        });

        await this.$axios.put(`/themes-manager-theme-indicators`, [indicator]);

        this.indicatorsUpdating = false;

        this.successPill({
          icon: "mdi-content-save",
          title: `indicator updated`,
        });
      } catch {
        this.indicatorsUpdating = false;

        this.errorPill({
          icon: "mdi-content-save",
          title: `Error! Failed to update indicator`,
        });
      }
    },
    /** update order of draggable items (themes/indicators) in backend when dragged to new position */
    async updateDraggablePosition(type = null) {
      if (type !== "themes" && type !== "indicators") {
        return;
      }

      let itemsArray = this[type];
      let updateUrl = {
        themes: "themes-manager-theme-order",
        indicators: "themes-manager-theme-indicators", //this actually updates all indicator fillable properties too...
      };

      try {
        this[type + "Updating"] = true;

        this.warningPill({
          icon: "mdi-content-save",
          title: `Updating ${type} order`,
          timeout: -1,
        });

        this[type + "PreviousState"] = JSON.parse(JSON.stringify(itemsArray));

        itemsArray.forEach((element, index) => {
          element.position = index + 1;
        });

        const response = await this.$axios.put(
          `/${updateUrl[type]}`,
          itemsArray,
        );

        this[type + "Updating"] = false;

        this[type] =
          type === "indicators" ? response.data.indicators : response.data;
        this.successPill({
          icon: "mdi-content-save",
          title: `${type} order updated`,
        });
      } catch {
        this[type + "Updating"] = false;

        this[type] = JSON.parse(JSON.stringify(this[type + "PreviousState"]));
        this.errorPill({
          icon: "mdi-content-save",
          title: `Error! Failed to update ${type} order`,
        });
      }
    },
    /** update order of draggables (themes/indicators) in backend if changed via keypress (A11y) */
    async updateDraggableByKeyPress(event, index, type) {
      if (type !== "themes" && type !== "indicators") {
        return;
      }

      let direction = null;

      //capture up or down arrow press
      switch (event.keyCode) {
        case 38:
          direction = "up";
          break;
        case 40:
          direction = "down";
          break;
      }

      //if we're at top or bottom of the themes list or an update is in progress - do nothing
      if (
        direction === null ||
        (direction === "down" && index + 1 > this[type].length - 1) ||
        (direction === "up" && index - 1 == -1) ||
        this[type + "Updating"]
      ) {
        event.stopPropagation();
        return;
      }

      //update the themes array order, refocus new button position and update in backend
      this[type] = this.reorderArray(this[type], index, direction);

      this.$nextTick(() => {
        if (type === "themes") {
          document.getElementById(type + "_" + index).focus();
        }
        /**
         * because table rows are rendered in <data-table-row-handler>
         * component, this isn't necessarily updated at this point.
         * have to manipulate index for focussing indicators unless
         * add some kind of updated event and listener on component
         */
        if (type === "indicators") {
          if (direction === "up") {
            document.getElementById(type + "_" + (index - 1)).focus();
          }
          if (direction === "down") {
            document.getElementById(type + "_" + (index + 1)).focus();
          }
        }
      });

      this.debouncedUpdateDraggablePosition(type);
    },
    /** function to reorder array based on index and direction of movement */
    reorderArray(arr, index, direction) {
      if (index < 0 || index >= arr.length) {
        return arr;
      }
      if (direction !== "up" && direction !== "down") {
        return arr;
      }
      if (direction === "up" && index === 0) {
        return arr;
      }
      if (direction === "down" && index === arr.length - 1) {
        return arr;
      }
      const elementToMove = arr.splice(index, 1)[0];
      if (direction === "up") {
        arr.splice(index - 1, 0, elementToMove);
      } else if (direction === "down") {
        arr.splice(index + 1, 0, elementToMove);
      }
      return arr;
    },
    addIndicators() {
      this.$router.push({
        name: "Themes Manager",
        params: { selectedTabRoute: "search" },
      });
    },
    /** delete a single indicator from a theme */
    async deleteIndicator() {
      try {
        this.showDeleteIndicatorDialog = false;
        this.indicatorsUpdating = true;

        this.warningPill({
          icon: "mdi-delete",
          title: `Removing indicator`,
          timeout: -1,
        });

        const response = await this.$axios.delete(
          `/themes-manager-indicators/${this.deleteIndicatorId}`,
        );

        await this.fetchThemes();

        this.indicatorsUpdating = false;
        this.deleteIndicatorId = null;

        this.indicators = response.data.indicators;
        this.standardMetadata = {};
        response.data.metadata.forEach((element) => {
          this.standardMetadata[element.indicator_code] = element;
        });

        this.successPill({
          icon: "mdi-delete",
          title: `indicator removed`,
        });
      } catch {
        this.indicatorsUpdating = false;
        this.deleteIndicatorId = null;
        this.showDeleteIndicatorDialog = false;

        this.errorPill({
          icon: "mdi-delete",
          title: `Error! Failed to remove indicator`,
        });
      }
    },
    /** update a single theme details (name) */
    async updateThemeDetails() {
      try {
        this.showThemeRenameDialog = false;
        this.warningPill({
          icon: "mdi-content-save",
          title: `Updating Theme`,
          timeout: -1,
        });

        await this.$axios.put(`/themes-manager-theme/${this.selectedThemeId}`, {
          name: this.selectedThemeName,
        });

        this.successPill({
          icon: "mdi-content-save",
          title: `Theme Updated`,
        });
      } catch {
        this.showThemeRenameDialog = false;
        this.errorPill({
          icon: "mdi-content-save",
          title: `Error! Failed to update theme`,
        });
      }
    },
    /** delete a theme */
    async deleteTheme() {
      try {
        this.showDeleteThemeDialog = false;
        this.themesUpdating = true;

        this.warningPill({
          icon: "mdi-delete",
          title: `Deleting Theme`,
          timeout: -1,
        });

        await this.$axios.delete(`/themes/${this.selectedThemeId}`);

        await this.fetchThemes();

        this.$nextTick(() => {
          const firstTheme = this.themes[0];

          this.themesUpdating = false;
          this.selectedThemeId = firstTheme.id;
          this.selectedThemeName = firstTheme.name;
        });

        this.successPill({
          icon: "mdi-delete",
          title: `Theme Deleted`,
        });
      } catch {
        this.themesUpdating = false;
        this.showDeleteThemeDialog = false;

        this.errorPill({
          icon: "mdi-delete",
          title: `Error! Failed to delete theme`,
        });
      }
    },
    /** reset themes list to default (destructive) */
    async resetThemes() {
      try {
        this.showResetThemesDialog = false;
        this.fetchingThemes = true;
        this.themesUpdating = true;

        this.warningPill({
          icon: "mdi-refresh",
          title: `Resetting Themes`,
          timeout: -1,
        });

        await this.$axios.put("/reset-to-default-themes");

        await this.fetchThemes();

        this.fetchingThemes = false;
        this.themesUpdating = false;

        this.successPill({
          icon: "mdi-refresh",
          title: `Themes reset`,
        });
      } catch {
        this.fetchingThemes = false;
        this.themesUpdating = false;
        this.showDeleteThemeDialog = false;

        this.errorPill({
          icon: "mdi-refresh",
          title: `Error! Failed to reset themes`,
        });
      }
    },
  },
  watch: {
    selectedThemeId: {
      handler() {
        this.fetchindicators(this.selectedThemeId);
      },
    },
    "$route.params.themeId": {
      handler(newVal) {
        if (newVal) {
          this.selectedThemeId = newVal;
          this.fetchindicators(newVal);
        }
      },
    },
  },
};
</script>
<style scoped>
.v-card {
  position: relative; /* Ensure the parent container has relative positioning */
  display: flex;
  flex-direction: column;
}
.v-card-text {
  flex: 1; /* Allow the text section to grow and take up available space */
}
.sticky {
  border-top: 1px solid #ccc;
  position: sticky;
  background-color: white;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding-bottom: 0;
  margin-top: auto; /* Push the sticky element to the bottom */
}

.custom-chip {
  background-color: rgba(253, 217, 175, 1);
  color: rgba(139, 76, 4, 1);
}

.ocsi-chip {
  background-color: rgba(191, 224, 248, 1);
  color: rgba(9, 63, 107, 1);
}
</style>

<style>
.theme-btn .v-list-item__content {
  align-items: center !important;
  display: flex !important;
}
/* some dodgy styles overwriting to shrink down the size of the checkboxes */
.narrowedCheckbox .v-input__control {
  height: 39px !important;
  min-height: 10px !important;
}
.narrowedCheckbox .v-selection-control {
  min-height: 39px !important;
  height: 39px;
}
.narrowedCheckbox .v-selection-control__wrapper {
  height: 39px !important;
}
.narrowedCheckbox .v-selection-control__input:hover::before {
  opacity: 0;
}
.theme-selected .v-list-item__content {
  background-color: #b0d7f1;
  transition: background-color 0.3s;
  color: black !important;
}
.theme-selected {
  color: white !important;
}
#theme-actions .v-btn__content {
  justify-content: start !important;
}
</style>
