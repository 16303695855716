export const systemMessages = {
  methods: {
    successMsg({ title = null, message = null, timeout = 3000 } = {}) {
      this.emit.emit("systemMessage", {
        title: title,
        message: message,
        colour: "success",
        timeout: timeout,
      });
    },

    successPill({
      icon = null,
      title = null,
      message = null,
      timeout = 3000,
      showClose = true,
    } = {}) {
      this.emit.emit("systemMessage", {
        title: title,
        message: message,
        colour: "success",
        timeout: timeout,
        location: "bottom",
        rounded: "pill",
        icon: icon,
        width: null,
        showClose: showClose,
      });
    },

    infoPill({
      icon = null,
      title = null,
      message = null,
      timeout = 3000,
      showClose = true,
    } = {}) {
      this.emit.emit("systemMessage", {
        title: title,
        message: message,
        colour: "info",
        timeout: timeout,
        location: "bottom",
        rounded: "pill",
        icon: icon,
        width: null,
        showClose: showClose,
      });
    },

    errorMsg({ title = null, message = null, timeout = -1 } = {}) {
      this.emit.emit("systemMessage", {
        title: title,
        message: message,
        colour: "red",
        timeout: timeout,
      });
    },

    errorPill({
      icon = null,
      title = null,
      message = null,
      timeout = -1,
    } = {}) {
      this.emit.emit("systemMessage", {
        title: title,
        message: message,
        colour: "red",
        timeout: timeout,
        location: "bottom",
        rounded: "pill",
        icon: icon,
        width: null,
      });
    },

    warnMsg({ title = null, message = null, timeout = null } = {}) {
      this.emit.emit("systemMessage", {
        title: title,
        message: message,
        colour: "warning",
        timeout: timeout,
      });
    },

    warningPill({
      icon = null,
      title = null,
      message = null,
      timeout = null,
    } = {}) {
      this.emit.emit("systemMessage", {
        title: title,
        message: message,
        colour: "warning",
        timeout: timeout,
        location: "bottom",
        rounded: "pill",
        icon: icon,
        width: null,
      });
    },

    closeMsg() {
      this.emit.emit("closeSystemMessage");
    },

    suspendedMsg({ title = null, timeout = -1 } = {}) {
      this.emit.emit("systemMessage", {
        icon: "mdi-alert",
        title: title,
        message:
          "This account has been suspended.  If you think this is a mistake please contact support for further assistantance",
        colour: "inverted-warning",
        textColour: "on-inverted-warning",
        timeout: timeout,
        showClose: false,
        contactSupportBtn: true,
      });
    },
  },
};
