<template>
  <div>
    <h1>Chart Sandbox</h1>
    <p>Dev env only sandbox to view chart behaviour</p>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="chartsWidth"
            label="Charts Width (vw)"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="chartsHeight"
            label="Charts Height (px)"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="chartsType"
            label="Chart Type"
            :items="[
              'Bar',
              'Stacked Bar',
              'Horizontal Bar',
              'Line',
              'Pyramid',
              'Aus Pyramid',
            ]"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-card v-if="chartsType" style="overflow-y: auto; height: 80vh">
      <div v-for="i in reportThemes.length" :key="i">
        <div v-for="(item, j) in reportThemes[i - 1].config" :key="j">
          <div v-if="item.chartType" :id="j.toString()">
            <!-- Charts -->
            <Charts
              v-if="
                item.chartType === chartsType ||
                reportThemes[i - 1].name === 'Aus Pyramid'
              "
              :load="true"
              :item="item"
              :indicatorValues="indicatorValues"
              :comparators="comparatorAreaCodes"
              :comparatorsList="comparatorsList"
              :reportThemeObject="reportThemes[i - 1]"
              :chartWidthProp="chartsWidth + 'vw'"
              :chartHeight="chartsHeight + 'px'"
            />
          </div>
        </div>
      </div>
    </v-card>
    <v-card></v-card>
  </div>
</template>

<script>
import Charts from "@/components/Charts";

export default {
  name: "ChartSandbox",
  data: () => ({
    chartsWidth: 75,
    chartsHeight: 500,
    chartsType: null,
    clones: {},
    reportThemes: [
      {
        id: 125,
        name: "Access and Transport sample",
        slug: "access-and-transport-sample",
        title: "Access and Transport",
        description:
          "<p>The following data is a summary of the main access and transport related indicators.</p><p>The table below shows the proportion of households according to their ownership of a car or van. This is based on responses to the car ownership question in the 2021 Census which asks information on the number of cars or vans owned, or are available for use, by one or more members of a household. This includes company cars and vans available for private use and is based on details for private households only. Cars or vans used by residents of communal establishments are not counted.</p><p>In Brighton and Hove, 37.45% of households have no access to a car or van. This is greater than the proportion without access to a car or van in Essex (15.88%) and greater than the proportion without access to a car or van in East Sussex (19.19%).</p></p>",
        clientID: 1,
        created_at: "2023-08-21T14:01:31.000000Z",
        updated_at: "2024-02-08T12:28:19.000000Z",
        use_for_summary: true,
        allow_for_custom: false,
        country: "England",
        config: [
          {
            id: 686,
            reportThemeID: 125,
            text: null,
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: "16881281919314125",
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 0,
            timeSeries: false,
            itemName: "Car or van ownership",
            itemSource: "UK Census",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 2780,
                indicatorHTMLTableRef: "16881281919314125",
                chartRef: null,
                indicatorCode: "ks404ew0002*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1,
                indicatorName: "Households with no car (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 2781,
                indicatorHTMLTableRef: "16881281919314125",
                chartRef: null,
                indicatorCode: "ks404ew0003*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 2,
                indicatorName: "1 car or van in household (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 2782,
                indicatorHTMLTableRef: "16881281919314125",
                chartRef: null,
                indicatorCode: "ks404ew0004*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 3,
                indicatorName: "2 cars or vans in household (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 2783,
                indicatorHTMLTableRef: "16881281919314125",
                chartRef: null,
                indicatorCode: "ks404ew_new3*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 4,
                indicatorName: "Households with 3+ cars (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
            ],
          },
          {
            id: 700,
            reportThemeID: 125,
            text: "<p>The bar chart below shows the proportion of households with no car or van in the 2021 Census alongside the proportions from previous Censuses to identify changes over time.</p><p>In 2011, 38.19% of households in Brighton and Hove had no access to a car or van. This is similar to the proportion without access to a car or van in 2021 (37.45%).</p><p></p></p>",
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 1,
            timeSeries: false,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [],
          },
          {
            id: 685,
            reportThemeID: 125,
            text: null,
            chartType: "Bar",
            chartRef: "16698134215515125",
            chartDescription: "<p>Households with no car or van</p>",
            chartXLabel: null,
            chartYLabel: "Percent %",
            syncChartWithHTMLTableRef: null,
            inverseChartData: true,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 2,
            timeSeries: false,
            itemName: "Households with no car or van",
            itemSource: "UK Census",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 2779,
                indicatorHTMLTableRef: null,
                chartRef: "16698134215515125",
                indicatorCode: "ks404ew0002*dr",
                indicatorDate: "2011-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 866,
                indicatorName: "Households with no car  (E&W Census 2011)",
                source: "Census 2011",
                date: "2011-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2011",
              },
              {
                id: 2778,
                indicatorHTMLTableRef: null,
                chartRef: "16698134215515125",
                indicatorCode: "ks404ew0002*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1138,
                indicatorName: "Households with no car (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        name: "Unemployment sample",
        slug: "unemployment-sample",
        title: "Unemployment",
        description:
          "<p>The following data shows the key unemployment indicators.</p><p>The table below shows the total number and proportion of people receiving Universal Credit (UC) or Job Seekers Allowance (JSA) by age and sex. The proportion of working age people receiving benefits for unemployment in Brighton and Hove was 4.33% in Aug-2024. This is higher than the average across Essex (3.30%) and higher than the average across East Sussex (3.74%).</p></p>",
        clientID: 1,
        created_at: "2022-09-06T17:12:42.000000Z",
        updated_at: "2024-02-08T12:42:04.000000Z",
        use_for_summary: true,
        allow_for_custom: false,
        country: "England",
        config: [
          {
            id: 32,
            reportThemeID: 2,
            text: null,
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: "16663530611644",
            HTMLTableType: 2,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 0,
            timeSeries: false,
            itemName: "Unemployment Benefit Claimants",
            itemSource: "Department for Work and Pensions (DWP)",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 2913,
                indicatorHTMLTableRef: "16663530611644",
                chartRef: null,
                indicatorCode: "claimant_count*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-08-01",
                decimalPlaces: 2,
                dateCoverageText: "Aug-2024",
              },
              {
                id: 2914,
                indicatorHTMLTableRef: "16663530611644",
                chartRef: null,
                indicatorCode: "claimant_count_1824*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 2,
                indicatorName:
                  "Youth unemployment (18-24 receiving JSA or Universal Credit)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-08-01",
                decimalPlaces: 2,
                dateCoverageText: "Aug-2024",
              },
              {
                id: 2915,
                indicatorHTMLTableRef: "16663530611644",
                chartRef: null,
                indicatorCode: "claimant_count_50pl*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 3,
                indicatorName:
                  "Older person unemployment (50+ receiving JSA or Universal Credit)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-08-01",
                decimalPlaces: 2,
                dateCoverageText: "Aug-2024",
              },
              {
                id: 2916,
                indicatorHTMLTableRef: "16663530611644",
                chartRef: null,
                indicatorCode: "claimant_count_female*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 4,
                indicatorName:
                  "Unemployment benefit (JSA and Universal Credit), female",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-08-01",
                decimalPlaces: 2,
                dateCoverageText: "Aug-2024",
              },
              {
                id: 2917,
                indicatorHTMLTableRef: "16663530611644",
                chartRef: null,
                indicatorCode: "claimant_count_male*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 5,
                indicatorName:
                  "Unemployment benefit (JSA and Universal Credit), male",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-08-01",
                decimalPlaces: 2,
                dateCoverageText: "Aug-2024",
              },
            ],
          },
          {
            id: 126,
            reportThemeID: 2,
            text: "<p>The chart below shows the total proportion of people receiving unemployment benefit (JSA or Universal Credit) between Jan-2020 and Aug-2024.</p><p>The total unemployment rate in Brighton and Hove has increased from 2.70% in Jan-2020 to 4.33% in Aug-2024.</p></p>",
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 1,
            timeSeries: false,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [],
          },
          {
            id: 62,
            reportThemeID: 2,
            text: null,
            chartType: "Line",
            chartRef: "1669103534594",
            chartDescription:
              "<p>Unemployment benefit (JSA and Universal Credit)</p>",
            chartXLabel: null,
            chartYLabel: "Percent %",
            syncChartWithHTMLTableRef: null,
            inverseChartData: true,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 2,
            timeSeries: true,
            itemName: "Unemployment Benefit Claimants (time series)",
            itemSource: "Department for Work and Pensions (DWP)",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-01-01",
                decimalPlaces: 2,
                dateCoverageText: "Jan-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-02-01",
                decimalPlaces: 2,
                dateCoverageText: "Feb-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-03-01",
                decimalPlaces: 2,
                dateCoverageText: "Mar-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-04-01",
                decimalPlaces: 2,
                dateCoverageText: "Apr-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-05-01",
                decimalPlaces: 2,
                dateCoverageText: "May-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-06-01",
                decimalPlaces: 2,
                dateCoverageText: "Jun-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-07-01",
                decimalPlaces: 2,
                dateCoverageText: "Jul-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-08-01",
                decimalPlaces: 2,
                dateCoverageText: "Aug-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-09-01",
                decimalPlaces: 2,
                dateCoverageText: "Sep-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-10-01",
                decimalPlaces: 2,
                dateCoverageText: "Oct-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-11-01",
                decimalPlaces: 2,
                dateCoverageText: "Nov-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2020-12-01",
                decimalPlaces: 2,
                dateCoverageText: "Dec-2020",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "Jan-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-02-01",
                decimalPlaces: 2,
                dateCoverageText: "Feb-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-02-02",
                decimalPlaces: 2,
                dateCoverageText: "Feb-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-03-01",
                decimalPlaces: 2,
                dateCoverageText: "Mar-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-03-03",
                decimalPlaces: 2,
                dateCoverageText: "Mar-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-04-01",
                decimalPlaces: 2,
                dateCoverageText: "Apr-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-05-01",
                decimalPlaces: 2,
                dateCoverageText: "May-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-06-01",
                decimalPlaces: 2,
                dateCoverageText: "Jun-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-07-01",
                decimalPlaces: 2,
                dateCoverageText: "Jul-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-08-01",
                decimalPlaces: 2,
                dateCoverageText: "Aug-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-09-01",
                decimalPlaces: 2,
                dateCoverageText: "Sep-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-10-01",
                decimalPlaces: 2,
                dateCoverageText: "Oct-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-11-01",
                decimalPlaces: 2,
                dateCoverageText: "Nov-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2021-12-01",
                decimalPlaces: 2,
                dateCoverageText: "Dec-2021",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-01-01",
                decimalPlaces: 2,
                dateCoverageText: "Jan-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-02-01",
                decimalPlaces: 2,
                dateCoverageText: "Feb-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-03-01",
                decimalPlaces: 2,
                dateCoverageText: "Mar-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-04-01",
                decimalPlaces: 2,
                dateCoverageText: "Apr-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-05-01",
                decimalPlaces: 2,
                dateCoverageText: "May-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-06-01",
                decimalPlaces: 2,
                dateCoverageText: "Jun-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-07-01",
                decimalPlaces: 2,
                dateCoverageText: "Jul-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-08-01",
                decimalPlaces: 2,
                dateCoverageText: "Aug-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-09-01",
                decimalPlaces: 2,
                dateCoverageText: "Sep-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-10-01",
                decimalPlaces: 2,
                dateCoverageText: "Oct-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-11-01",
                decimalPlaces: 2,
                dateCoverageText: "Nov-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2022-12-01",
                decimalPlaces: 2,
                dateCoverageText: "Dec-2022",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-01-01",
                decimalPlaces: 2,
                dateCoverageText: "Jan-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-02-01",
                decimalPlaces: 2,
                dateCoverageText: "Feb-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-03-01",
                decimalPlaces: 2,
                dateCoverageText: "Mar-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-04-01",
                decimalPlaces: 2,
                dateCoverageText: "Apr-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-05-01",
                decimalPlaces: 2,
                dateCoverageText: "May-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-06-01",
                decimalPlaces: 2,
                dateCoverageText: "Jun-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-07-01",
                decimalPlaces: 2,
                dateCoverageText: "Jul-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-08-01",
                decimalPlaces: 2,
                dateCoverageText: "Aug-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-09-01",
                decimalPlaces: 2,
                dateCoverageText: "Sep-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-10-01",
                decimalPlaces: 2,
                dateCoverageText: "Oct-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-11-01",
                decimalPlaces: 2,
                dateCoverageText: "Nov-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2023-12-01",
                decimalPlaces: 2,
                dateCoverageText: "Dec-2023",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-01-01",
                decimalPlaces: 2,
                dateCoverageText: "Jan-2024",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-02-01",
                decimalPlaces: 2,
                dateCoverageText: "Feb-2024",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-03-01",
                decimalPlaces: 2,
                dateCoverageText: "Mar-2024",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-04-01",
                decimalPlaces: 2,
                dateCoverageText: "Apr-2024",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-05-01",
                decimalPlaces: 2,
                dateCoverageText: "May-2024",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-06-01",
                decimalPlaces: 2,
                dateCoverageText: "Jun-2024",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-07-01",
                decimalPlaces: 2,
                dateCoverageText: "Jul-2024",
              },
              {
                id: 3548,
                indicatorHTMLTableRef: null,
                chartRef: "1669103534594",
                indicatorCode: "claimant_count*dr",
                indicatorDate: "2020-01-01,2050-01-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 792,
                indicatorName:
                  "Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)",
                source: "Department for Work and Pensions (DWP)",
                date: "2024-08-01",
                decimalPlaces: 2,
                dateCoverageText: "Aug-2024",
              },
            ],
          },
        ],
      },
      {
        id: 1,
        name: "Population sample",
        slug: "population-sample",
        title: "Population",
        description:
          "<p>The following data is a summary of key population demographics.</p><p>The table below provides an overview of people living in Brighton and Hove. These population figures provide detail of the structure of the population by broad age bands and by sex. Finally, it also includes a measure of population density by hectare.</p><p>The total population of Brighton and Hove was 277,103 people in 2021.</p><p>Of the total population in Brighton and Hove in 2021, 15.06% are children aged under 16, 70.87% are adults aged 16-64, and 14.07% are over 65 years old.</p></p>",
        clientID: 1,
        created_at: "2022-09-06T17:12:42.000000Z",
        updated_at: "2024-02-08T12:40:36.000000Z",
        use_for_summary: true,
        allow_for_custom: false,
        country: "England",
        config: [
          {
            id: 43,
            reportThemeID: 1,
            text: null,
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: "16669760262213",
            HTMLTableType: 2,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 0,
            timeSeries: false,
            itemName: "Population overview",
            itemSource: "UK Census",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 2950,
                indicatorHTMLTableRef: "16669760262213",
                chartRef: null,
                indicatorCode: "census11_p07_all",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1,
                indicatorName: "All people (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 0,
                dateCoverageText: "2021",
              },
              {
                id: 2903,
                indicatorHTMLTableRef: "16669760262213",
                chartRef: null,
                indicatorCode: "census11_p06_all*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 2,
                indicatorName: "All Females (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 2904,
                indicatorHTMLTableRef: "16669760262213",
                chartRef: null,
                indicatorCode: "census11_p05_all*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 3,
                indicatorName: "All Males (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 2924,
                indicatorHTMLTableRef: "16669760262213",
                chartRef: null,
                indicatorCode: "census11_015*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 4,
                indicatorName: "Population aged under 16 (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 2921,
                indicatorHTMLTableRef: "16669760262213",
                chartRef: null,
                indicatorCode: "census11_1664*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 5,
                indicatorName: "Population aged 16 to 64 (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 2922,
                indicatorHTMLTableRef: "16669760262213",
                chartRef: null,
                indicatorCode: "census11_65pl*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 6,
                indicatorName: "Population aged 65+ (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 2911,
                indicatorHTMLTableRef: "16669760262213",
                chartRef: null,
                indicatorCode: "census11_p07_all*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 7,
                indicatorName:
                  "Population Density (Persons per sq km) (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
            ],
          },
          {
            id: 1533,
            reportThemeID: 1,
            text: "<blockquote><h3>Index of Multiple Deprivation (IMD)</h3></blockquote><p></p><p>The following visualisations draw upon data from the Index of Multiple Deprivation (IMD) to give insight into vulnerable populations in Brighton and Hove, Essex, East Sussex. The Indices of Deprivation 2019 are a relative measure of deprivation for small areas (Lower-layer Super Output Areas) across England. The overall Index of Multiple Deprivation 2019 combines together indicators under seven different domains of deprivation: Income Deprivation; Employment Deprivation; Education Skills and Training Deprivation; Health Deprivation and Disability; Crime; Barriers to Housing and Services and Living Environment Deprivation. A higher score indicates that an area is experiencing high levels of deprivation.</p><p>The table below shows the IMD score and rank (average LSOA Rank, where a lower rank indicates that an area is experiencing high levels of deprivation) for Brighton and Hove, Essex and East Sussex.</p><p>Brighton and Hove has an IMD score of 20.76. This means that Brighton and Hove has higher levels of deprivation to Essex (17.02) and similar levels of deprivation to East Sussex (19.85).</p></p>",
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 1,
            timeSeries: false,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [],
          },
          {
            id: 1534,
            reportThemeID: 1,
            text: null,
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: "1697119384692",
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 2,
            timeSeries: false,
            itemName: "Index of Multiple Deprivation (IMD)",
            itemSource:
              "Ministry of Housing Communities and Local Government (MHCLG) 2019",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 4923,
                indicatorHTMLTableRef: "1697119384692",
                chartRef: null,
                indicatorCode: "imd19_score",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1,
                indicatorName: "Index of Multiple Deprivation 2019 (IMD) Score",
                source:
                  "Ministry of Housing Communities and Local Government (MHCLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
              {
                id: 4924,
                indicatorHTMLTableRef: "1697119384692",
                chartRef: null,
                indicatorCode: "imd19_rank",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 2,
                indicatorName: "Index of Multiple Deprivation (IMD) 2019 Rank",
                source:
                  "Ministry of Housing Communities and Local Government (MHCLG)",
                date: "2019-01-01",
                decimalPlaces: 0,
                dateCoverageText: "2019",
              },
            ],
          },
          {
            id: 1535,
            reportThemeID: 1,
            text: "<p>The bar chart below explores this further, showing the proportion of people living in neighbourhoods grouped into deciles according to level of deprivation derived from the IMD. A higher score on the IMD indicates that an area is experiencing greater levels of deprivation. To clarify, all neighbourhoods in England are grouped into ten equal sized groups “deciles”; neighbourhoods in decile 1 fall within the most deprived 10% of neighbourhoods nationally, whilst neighbourhoods in decile 10 fall within the least deprived 10% of neighbourhoods nationally.</p></p>",
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 3,
            timeSeries: false,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [],
          },
          {
            id: 1536,
            reportThemeID: 1,
            text: null,
            chartType: "Bar",
            chartRef: "16971197544639",
            chartDescription: "<p>IMD 2019 deprivation deciles</p>",
            chartXLabel: null,
            chartYLabel: "Percentage %",
            syncChartWithHTMLTableRef: null,
            inverseChartData: true,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 4,
            timeSeries: false,
            itemName: "Proportion of people in each deprivation decile",
            itemSource:
              "Ministry of Housing Communities and Local Government (MHCLG) 2019",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 4925,
                indicatorHTMLTableRef: null,
                chartRef: "16971197544639",
                indicatorCode: "imd19pop_dec1*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 564,
                indicatorName: "IMD 2019 Decile 1",
                source: "Communities and Local Government (CLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
              {
                id: 4926,
                indicatorHTMLTableRef: null,
                chartRef: "16971197544639",
                indicatorCode: "imd19pop_dec2*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1128,
                indicatorName: "IMD 2019 Decile 2",
                source: "Communities and Local Government (CLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
              {
                id: 4927,
                indicatorHTMLTableRef: null,
                chartRef: "16971197544639",
                indicatorCode: "imd19pop_dec3*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1157,
                indicatorName: "IMD 2019 Decile 3",
                source: "Communities and Local Government (CLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
              {
                id: 4928,
                indicatorHTMLTableRef: null,
                chartRef: "16971197544639",
                indicatorCode: "imd19pop_dec4*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1170,
                indicatorName: "IMD 2019 Decile 4",
                source: "Communities and Local Government (CLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
              {
                id: 4929,
                indicatorHTMLTableRef: null,
                chartRef: "16971197544639",
                indicatorCode: "imd19pop_dec5*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1179,
                indicatorName: "IMD 2019 Decile 5",
                source: "Communities and Local Government (CLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
              {
                id: 4930,
                indicatorHTMLTableRef: null,
                chartRef: "16971197544639",
                indicatorCode: "imd19pop_dec6*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1197,
                indicatorName: "IMD 2019 Decile 6",
                source: "Communities and Local Government (CLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
              {
                id: 4931,
                indicatorHTMLTableRef: null,
                chartRef: "16971197544639",
                indicatorCode: "imd19pop_dec7*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1204,
                indicatorName: "IMD 2019 Decile 7",
                source: "Communities and Local Government (CLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
              {
                id: 4932,
                indicatorHTMLTableRef: null,
                chartRef: "16971197544639",
                indicatorCode: "imd19pop_dec8*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1205,
                indicatorName: "IMD 2019 Decile 8",
                source: "Communities and Local Government (CLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
              {
                id: 4933,
                indicatorHTMLTableRef: null,
                chartRef: "16971197544639",
                indicatorCode: "imd19pop_dec9*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1215,
                indicatorName: "IMD 2019 Decile 9",
                source: "Communities and Local Government (CLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
              {
                id: 4934,
                indicatorHTMLTableRef: null,
                chartRef: "16971197544639",
                indicatorCode: "imd19pop_dec10*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1219,
                indicatorName: "IMD 2019 Decile 10",
                source: "Communities and Local Government (CLG)",
                date: "2019-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2019",
              },
            ],
          },
        ],
      },
      {
        id: 4,
        name: "Housing sample",
        slug: "housing-sample",
        title: "Housing",
        description:
          '<p>The following data is a summary of key housing related indicators.</p><p>The table below shows the mean house prices by accommodation type across Brighton and Hove and the comparator areas for four key dwelling types (flats, semi-detached houses, terraced houses and detached houses) from Jun-2023 to May-2024.</p><p>The average property price for all dwelling types in Brighton and Hove is £490,642, this is higher than the average across Essex (£398,989) and higher than the average across East Sussex (£389,784).</p><p><i>Data on house prices is from the <a href="https://www.gov.uk/government/collections/price-paid-data" target="_blank" rel="noopener noreferrer nofollow">Land Registry open data price-paid dataset</a>. Please note some small areas may not have had a transaction recorded within the twelve month period leading to gaps in the table below.</i></p></p>',
        clientID: 1,
        created_at: "2022-10-24T15:09:34.000000Z",
        updated_at: "2024-02-08T12:39:23.000000Z",
        use_for_summary: true,
        allow_for_custom: false,
        country: "England",
        config: [
          {
            id: 39,
            reportThemeID: 4,
            text: null,
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: "1666779639875",
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 0,
            timeSeries: false,
            itemName: "Average house prices",
            itemSource: "UK Land Registry",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 2897,
                indicatorHTMLTableRef: "1666779639875",
                chartRef: null,
                indicatorCode: "landregistry_a_sumpricepaid_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1,
                indicatorName: "Average house price (12 months)",
                source: "Land Registry",
                date: "2023-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2898,
                indicatorHTMLTableRef: "1666779639875",
                chartRef: null,
                indicatorCode: "landregistry_d_sumpricepaid_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 2,
                indicatorName:
                  "Average house price for detached houses (12 months)",
                source: "Land Registry",
                date: "2023-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2899,
                indicatorHTMLTableRef: "1666779639875",
                chartRef: null,
                indicatorCode: "landregistry_f_sumpricepaid_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 3,
                indicatorName: "Average house price for flats (12 months)",
                source: "Land Registry",
                date: "2023-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2900,
                indicatorHTMLTableRef: "1666779639875",
                chartRef: null,
                indicatorCode: "landregistry_s_sumpricepaid_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 4,
                indicatorName:
                  "Average house price for semi-detached houses (12 months)",
                source: "Land Registry",
                date: "2023-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2901,
                indicatorHTMLTableRef: "1666779639875",
                chartRef: null,
                indicatorCode: "landregistry_t_sumpricepaid_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 5,
                indicatorName:
                  "Average house price for terraced houses (12 months)",
                source: "Land Registry",
                date: "2023-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2023 to May-2024",
              },
            ],
          },
          {
            id: 127,
            reportThemeID: 4,
            text: "<p>The chart below shows the tenure of housing in Brighton and Hove, Essex, and East Sussex.</p><p>Tenure types include;</p><ul><li><p>‘Owner occupied’ housing includes accommodation that is either owned outright, owned with a mortgage or loan, or shared ownership (paying part rent and part mortgage).</p></li><li><p>‘Social rented’ housing includes accommodation that is rented from a council (Local Authority) or a Housing Association, Housing Co-operative, Charitable Trust, Non-profit housing company or Registered Social Landlord.</p></li><li><p>‘Rented from the Council includes accommodation rented from the Local Authority.</p></li><li><p>‘Housing Association or Social Landlord’ includes rented from Registered Social Landlord, Housing Association, Housing Co-operative, Charitable Trust and non-profit housing Company.</p></li><li><p>‘Private rented or letting agency’ includes accommodation that is rented from a private landlord or letting agency.</p></li><li><p>‘Other Rented’ includes employer of a household member and relative or friend of a household member and living rent free.</p><p></p></li></ul><p>In Brighton and Hove 52.24% of households are owner-occupied, which is lower than the proportion of households in Essex (69.86%), and a lower proportion than East Sussex (69.29%).</p><p>Overall, 9.54% of households in Brighton and Hove are rented from the local authority, 5.32% are rented from a housing association and 30.62% are privately rented.</p></p>",
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 1,
            timeSeries: false,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [],
          },
          {
            id: 68,
            reportThemeID: 4,
            text: null,
            chartType: "Bar",
            chartRef: "16698082683325",
            chartDescription: "<p>Housing tenure breakdowns by type</p>",
            chartXLabel: null,
            chartYLabel: "Percent %",
            syncChartWithHTMLTableRef: null,
            inverseChartData: true,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 2,
            timeSeries: false,
            itemName: "Housing tenure",
            itemSource: "UK Census",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 295,
                indicatorHTMLTableRef: null,
                chartRef: "16698082683325",
                indicatorCode: "owneroccupied_2001*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 636,
                indicatorName: "Owner occupied housing (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 296,
                indicatorHTMLTableRef: null,
                chartRef: "16698082683325",
                indicatorCode: "ks402ew0005*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 637,
                indicatorName: "Housing rented from council (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 297,
                indicatorHTMLTableRef: null,
                chartRef: "16698082683325",
                indicatorCode: "ks402ew0006*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 638,
                indicatorName:
                  "Housing rented from a Housing Association (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 298,
                indicatorHTMLTableRef: null,
                chartRef: "16698082683325",
                indicatorCode: "ks402ew0007*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 639,
                indicatorName:
                  "Private rented housing: Private landlord or letting agency (Census 2021)",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
            ],
          },
        ],
      },
      {
        id: 7,
        name: "Crime sample",
        slug: "crime-summary",
        title: "Crime",
        description:
          '<p>The information below shows the level of recorded crime in Brighton and Hove and comparator areas. This is based on data for individual crime incidents published via the <a href="https://www.police.uk" target="_blank" rel="noopener noreferrer nofollow">Police UK open data portal,</a> which has been linked by Local Insight to selected neighbourhoods.</p><p>The table below shows counts and rates for the main crime types and anti-social behaviour incidents between Jun-2023 to May-2024.</p><p>There were 44,702 total crime offences in Brighton and Hove between Jun-2023 to May-2024, with the overall crime rate being 160.8 per 1,000 population. This is higher than the average across Essex (107.9) and higher than the average across East Sussex (110.9).</p></p>',
        clientID: 1,
        created_at: "2022-10-28T17:03:48.000000Z",
        updated_at: "2024-02-08T11:02:02.000000Z",
        use_for_summary: true,
        allow_for_custom: false,
        country: "England",
        config: [
          {
            id: 44,
            reportThemeID: 7,
            text: null,
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: "16669767201836",
            HTMLTableType: 2,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 0,
            timeSeries: false,
            itemName: "Crime and Anti-Social Behaviour Incidents",
            itemSource: "Police UK (Police recorded crime figures)",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 6773,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2858,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "anti_social_behaviour_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 2,
                indicatorName: "Anti-social behaviour (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2859,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "bicycle_theft_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 3,
                indicatorName: "Bicycle theft offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2860,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "burglary_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 5,
                indicatorName: "Burglary (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2861,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode:
                  "criminal_damage_and_arson_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 6,
                indicatorName: "Criminal damage (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2863,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "drugs_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 7,
                indicatorName: "Drug crime offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2864,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "other_crime_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Other crime offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2865,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "other_theft_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 9,
                indicatorName: "Other theft offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2869,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "shoplifting_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 10,
                indicatorName: "Shoplifting offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2866,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "possession_of_weapons_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 11,
                indicatorName:
                  "Possession of weapons offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2867,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "public_order_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 12,
                indicatorName: "Public order offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2868,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "robbery_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 13,
                indicatorName: "Robbery recorded offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2870,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "theft_from_the_person_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 14,
                indicatorName:
                  "Theft from the person offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2871,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode: "vehicle_crime_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 15,
                indicatorName: "Vehicle crime (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
              {
                id: 2872,
                indicatorHTMLTableRef: "16669767201836",
                chartRef: null,
                indicatorCode:
                  "violence_and_sexual_offences_police_uk_12_month*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 16,
                indicatorName:
                  "Violent crime and sexual offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
            ],
          },
          {
            id: 128,
            reportThemeID: 7,
            text: '<p>The line chart below shows a time series made up of 12 month totals of neighbourhood-level incidents of criminal offences. The figure is a rate per 1,000 residents. The first data point covers Sep-2018 to Aug-2019 and the final data point covers Jun-2023 to May-2024.</p><p>The overall crime rate in Brighton and Hove has increased from 121.5 per 1,000 people between Sep-2018 to Aug-2019 to 160.8 per 1,000 people between Jun-2023 to May-2024.</p><p><i>Please note there can be missing data in Police.uk crime counts for certain areas. This is often due to issues with IT system changes at various police forces such as Devon and Cornwall, and Greater Manchester currently, which affects their reporting of figures to Police UK. Please see the <a href="https://data.police.uk/changelog/" target="_blank" rel="noopener noreferrer nofollow">Police UK change log</a> for details of current known issues.</i></p></p>',
            chartType: null,
            chartRef: null,
            chartDescription: null,
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: false,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 1,
            timeSeries: false,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [],
          },
          {
            id: 131,
            reportThemeID: 7,
            text: null,
            chartType: "Line",
            chartRef: "16819170424177",
            chartDescription: "<p>12 month total crime rate (time series)</p>",
            chartXLabel: null,
            chartYLabel: "Crimes per 1,000 population",
            syncChartWithHTMLTableRef: null,
            inverseChartData: true,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 2,
            timeSeries: true,
            itemName: "12 month total crime rate (time series)",
            itemSource: "Police UK",
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: true,
            indicators: [
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2018-09-01",
                decimalPlaces: 1,
                dateCoverageText: "Sep-2018 to Aug-2019",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2018-12-01",
                decimalPlaces: 1,
                dateCoverageText: "Dec-2018 to Nov-2019",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2019-03-01",
                decimalPlaces: 1,
                dateCoverageText: "Mar-2019 to Feb-2020",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2019-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2019 to May-2020",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2019-09-01",
                decimalPlaces: 1,
                dateCoverageText: "Sep-2019 to Aug-2020",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2019-12-01",
                decimalPlaces: 1,
                dateCoverageText: "Dec-2019 to Nov-2020",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2020-03-01",
                decimalPlaces: 1,
                dateCoverageText: "Mar-2020 to Feb-2021",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2020-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2020 to May-2021",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2020-09-01",
                decimalPlaces: 1,
                dateCoverageText: "Sep-2020 to Aug-2021",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2020-12-01",
                decimalPlaces: 1,
                dateCoverageText: "Dec-2020 to Nov-2021",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2021-03-01",
                decimalPlaces: 1,
                dateCoverageText: "Mar-2021 to Feb-2022",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2021-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2021 to May-2022",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2021-09-01",
                decimalPlaces: 1,
                dateCoverageText: "Sep-2021 to Aug-2022",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2021-12-01",
                decimalPlaces: 1,
                dateCoverageText: "Dec-2021 to Nov-2022",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2022-03-01",
                decimalPlaces: 1,
                dateCoverageText: "Mar-2022 to Feb-2023",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2022-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2022 to May-2023",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2022-09-01",
                decimalPlaces: 1,
                dateCoverageText: "Sep-2022 to Aug-2023",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2022-12-01",
                decimalPlaces: 1,
                dateCoverageText: "Dec-2022 to Nov-2023",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2023-03-01",
                decimalPlaces: 1,
                dateCoverageText: "Mar-2023 to Feb-2024",
              },
              {
                id: 301,
                indicatorHTMLTableRef: null,
                chartRef: "16819170424177",
                indicatorCode: "total_crime_police_uk_12_month*dr",
                indicatorDate: "asc",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 640,
                indicatorName: "Total crime offences (12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 1,
                dateCoverageText: "Jun-2023 to May-2024",
              },
            ],
          },
        ],
      },
      {
        id: 307,
        name: "Horizontal Bar Chart",
        slug: "horizontal-bar-chart",
        title: null,
        description: null,
        clientID: 1,
        created_at: "2024-09-27T07:39:18.000000Z",
        updated_at: "2024-09-27T11:11:06.000000Z",
        use_for_summary: false,
        allow_for_custom: false,
        country: "England",
        config: [
          {
            id: 2547,
            reportThemeID: 307,
            text: null,
            chartType: "Horizontal Bar",
            chartRef: "17274229378853",
            chartDescription: "Drug crime offences",
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: true,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 1,
            timeSeries: true,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2020-12-01",
                decimalPlaces: 0,
                dateCoverageText: "Dec-2020 to Nov-2021",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-03-01",
                decimalPlaces: 0,
                dateCoverageText: "Mar-2021 to Feb-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2021 to May-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-09-01",
                decimalPlaces: 0,
                dateCoverageText: "Sep-2021 to Aug-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-12-01",
                decimalPlaces: 0,
                dateCoverageText: "Dec-2021 to Nov-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2022-03-01",
                decimalPlaces: 0,
                dateCoverageText: "Mar-2022 to Feb-2023",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "Police UK",
                date: "2022-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2022 to May-2023",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "Police UK",
                date: "2022-09-01",
                decimalPlaces: 0,
                dateCoverageText: "Sep-2022 to Aug-2023",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "Police UK",
                date: "2022-12-01",
                decimalPlaces: 0,
                dateCoverageText: "Dec-2022 to Nov-2023",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "Police UK",
                date: "2023-03-01",
                decimalPlaces: 0,
                dateCoverageText: "Mar-2023 to Feb-2024",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2023 to May-2024",
              },
            ],
          },
        ],
      },
      {
        id: 307,
        name: "Horizontal Bar Chart",
        slug: "horizontal-bar-chart",
        title: null,
        description: null,
        clientID: 1,
        created_at: "2024-09-27T07:39:18.000000Z",
        updated_at: "2024-09-27T11:11:06.000000Z",
        use_for_summary: false,
        allow_for_custom: false,
        country: "England",
        config: [
          {
            id: 2547,
            reportThemeID: 307,
            text: null,
            chartType: "Horizontal Bar",
            chartRef: "17274229378853",
            chartDescription: "Drug crime offences (Custom 4 month)",
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: true,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 1,
            timeSeries: true,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2020-12-01",
                decimalPlaces: 0,
                dateCoverageText: "Dec-2020 to Nov-2021",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-03-01",
                decimalPlaces: 0,
                dateCoverageText: "Mar-2021 to Feb-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2021 to May-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-09-01",
                decimalPlaces: 0,
                dateCoverageText: "Sep-2021 to Aug-2022",
              },
            ],
          },
        ],
      },
      {
        id: 307,
        name: "Stacked Bar Chart",
        slug: "stacked-bar-chart",
        title: null,
        description: null,
        clientID: 1,
        created_at: "2024-09-27T07:39:18.000000Z",
        updated_at: "2024-09-27T11:11:06.000000Z",
        use_for_summary: false,
        allow_for_custom: false,
        country: "England",
        config: [
          {
            id: 2547,
            reportThemeID: 307,
            text: null,
            chartType: "Stacked Bar",
            chartRef: "17274229378853",
            chartDescription: "Stacked Drug crime offences (Custom 4 month)",
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: true,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 1,
            timeSeries: true,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2020-12-01",
                decimalPlaces: 0,
                dateCoverageText: "Dec-2020 to Nov-2021",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-03-01",
                decimalPlaces: 0,
                dateCoverageText: "Mar-2021 to Feb-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2021 to May-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-09-01",
                decimalPlaces: 0,
                dateCoverageText: "Sep-2021 to Aug-2022",
              },
            ],
          },
        ],
      },
      {
        id: 307,
        name: "Stacked Bar Chart",
        slug: "stacked-bar-chart",
        title: null,
        description: null,
        clientID: 1,
        created_at: "2024-09-27T07:39:18.000000Z",
        updated_at: "2024-09-27T11:11:06.000000Z",
        use_for_summary: false,
        allow_for_custom: false,
        country: "England",
        config: [
          {
            id: 2547,
            reportThemeID: 307,
            text: null,
            chartType: "Stacked Bar",
            chartRef: "17274229378853",
            chartDescription: "Stacked Drug crime offences (Custom 4 month)",
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: true,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 1,
            timeSeries: true,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            indicators: [
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2020-12-01",
                decimalPlaces: 0,
                dateCoverageText: "Dec-2020 to Nov-2021",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-03-01",
                decimalPlaces: 0,
                dateCoverageText: "Mar-2021 to Feb-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2021 to May-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-09-01",
                decimalPlaces: 0,
                dateCoverageText: "Sep-2021 to Aug-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2021-12-01",
                decimalPlaces: 0,
                dateCoverageText: "Dec-2021 to Nov-2022",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "police.uk",
                date: "2022-03-01",
                decimalPlaces: 0,
                dateCoverageText: "Mar-2022 to Feb-2023",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "Police UK",
                date: "2022-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2022 to May-2023",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "Police UK",
                date: "2022-09-01",
                decimalPlaces: 0,
                dateCoverageText: "Sep-2022 to Aug-2023",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "Police UK",
                date: "2022-12-01",
                decimalPlaces: 0,
                dateCoverageText: "Dec-2022 to Nov-2023",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "Police UK",
                date: "2023-03-01",
                decimalPlaces: 0,
                dateCoverageText: "Mar-2023 to Feb-2024",
              },
              {
                id: 7190,
                indicatorHTMLTableRef: null,
                chartRef: "17274229378853",
                indicatorCode: "drugs_police_uk_12_month",
                indicatorDate: "2020-11-01,2023-09-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                indicatorName: "Drug crime offences (rollling 12 month total)",
                source: "Police UK",
                date: "2023-06-01",
                decimalPlaces: 0,
                dateCoverageText: "Jun-2023 to May-2024",
              },
            ],
          },
        ],
      },
      {
        id: 305,
        name: "pyramid",
        slug: "pyramid",
        title: null,
        description: null,
        clientID: 1,
        created_at: "2024-10-18T09:33:07.000000Z",
        updated_at: "2024-10-18T09:33:23.000000Z",
        use_for_summary: false,
        allow_for_custom: false,
        country: "England",
        config: [
          {
            id: 2630,
            reportThemeID: 305,
            text: null,
            chartType: "Pyramid",
            chartRef: "17292440038367",
            chartDescription: "Population By Age and Gender",
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: true,
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 1,
            timeSeries: true,
            itemName: null,
            itemSource: null,
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            left_label: "Female",
            right_label: "Male",
            comparator: "C1",
            indicators: [
              {
                id: 7502,
                indicatorHTMLTableRef: null,
                chartRef: "17292440038367",
                indicatorCode: "mye_16_64_females*dr",
                indicatorDate: "2022-06-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 1,
                y_label: "16-64",
                indicatorName: "Female population aged 16 to 64",
                source: "Office for National Statistics (ONS)",
                date: "2022-06-01",
                decimalPlaces: 2,
                dateCoverageText: "2022",
              },
              {
                id: 7501,
                indicatorHTMLTableRef: null,
                chartRef: "17292440038367",
                indicatorCode: "mye_16_64_males*dr",
                indicatorDate: "2022-06-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 2,
                y_label: "16-64",
                indicatorName: "Male population aged 16 to 64",
                source: "Office for National Statistics (ONS)",
                date: "2022-06-01",
                decimalPlaces: 2,
                dateCoverageText: "2022",
              },
              {
                id: 7499,
                indicatorHTMLTableRef: null,
                chartRef: "17292440038367",
                indicatorCode: "mye_65plus_females*dr",
                indicatorDate: "2022-06-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 3,
                y_label: "65++",
                indicatorName: "Female population aged 65 and over",
                source: "Office for National Statistics (ONS)",
                date: "2022-06-01",
                decimalPlaces: 2,
                dateCoverageText: "2022",
              },
              {
                id: 7500,
                indicatorHTMLTableRef: null,
                chartRef: "17292440038367",
                indicatorCode: "mye_65plus_males*dr",
                indicatorDate: "2022-06-01",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 4,
                y_label: "65++",
                indicatorName: "Male population aged 65 and over",
                source: "Office for National Statistics (ONS)",
                date: "2022-06-01",
                decimalPlaces: 2,
                dateCoverageText: "2022",
              },
            ],
          },
        ],
      },
      {
        id: 305,
        name: "Aus Pyramid",
        slug: "aus-pyramid",
        title: "TEST REPORT THEME",
        description:
          "<p> (DOM) - MB - Mackay = 67</p><p>Australian Capital Territory = 173,618</p><p>New South Wales = 3,124,151</p><p></p></p>",
        clientID: 1,
        created_at: "2024-07-23T12:17:30.000000Z",
        updated_at: "2024-10-25T13:39:52.000000Z",
        use_for_summary: false,
        allow_for_custom: true,
        country: "Australia",
        config: [
          {
            id: 323,
            reportThemeID: 1,
            text: null,
            chartType: "Pyramid",
            chartRef: "17298635925179",
            itemName: "Indigenous Population",
            itemSource: "tralian Bureau of Statistics (ABS)",
            chartDescription: "Indigenous Population by Age and Gender",
            chartXLabel: null,
            chartYLabel: null,
            syncChartWithHTMLTableRef: null,
            inverseChartData: true, // true
            chartXCommaSeperatedValues: null,
            chartYCommaSeperatedValues: null,
            indicatorHTMLTableRef: null,
            HTMLTableType: 1,
            indicatorHTMLTableDescription: null,
            domainHTMLTableColOneText: null,
            domainHTMLTableColTwoText: null,
            order: 2,
            timeSeries: true, // true
            showComparators: true,
            kpi_config: null,
            display_data_point_markers: false,
            left_label: "Female",
            right_label: "Male",
            comparator: "C1",
            indicators: [
              {
                id: 650,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_65_y_ov_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 4,
                y_label: "Aged 65 +",
                indicatorName: "Indigenous females aged 65+ years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 638,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_65_y_ov_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 6,
                y_label: "Aged 65 +",
                indicatorName: "Indigenous males aged 65+ years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 651,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_60_64_y_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 7,
                y_label: "Aged 60 to 64",
                indicatorName: "Indigenous females aged 60 to 64 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 637,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_60_64_y_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 8,
                y_label: "Aged 60 to 64",
                indicatorName: "Indigenous males aged 60 to 64 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 649,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_55_59_y_Indigenous_F*dr",
                indicatorDate: null,
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 9,
                y_label: "Aged 55 to 59",
                indicatorName: "Indigenous females aged 55 to 59 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 636,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_55_59_y_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 10,
                y_label: "Aged 55 to 59",
                indicatorName: "Indigenous males aged 55 to 59 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 653,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_50_54_y_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 11,
                y_label: "Aged 50 to 54",
                indicatorName: "Indigenous females aged 50 to 54 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 635,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_50_54_y_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 12,
                y_label: "Aged 50 to 54",
                indicatorName: "Indigenous males aged 50 to 54 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 646,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_45_49_y_Indigenous_F*dr",
                indicatorDate: null,
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 13,
                y_label: "Aged 45 to 49",
                indicatorName: "Indigenous females aged 45 to 49 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 634,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_45_49_y_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 14,
                y_label: "Aged 45 to 49",
                indicatorName: "Indigenous males aged 45 to 49 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 655,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_40_44_y_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 15,
                y_label: "Aged 40 to 44",
                indicatorName: "Indigenous females aged 40 to 44 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 633,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_40_44_y_Indigenous_M*dr",
                indicatorDate: null,
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 16,
                y_label: "Aged 40 to 44",
                indicatorName: "Indigenous males aged 40 to 44 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 644,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_35_39_y_Indigenous_F*dr",
                indicatorDate: null,
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 17,
                y_label: "Aged 35 to 39",
                indicatorName: "Indigenous females aged 35 to 39 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 631,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_35_39_y_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 18,
                y_label: "Aged 35 to 39",
                indicatorName: "Indigenous males aged 35 to 39 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 642,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_30_34_y_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 19,
                y_label: "Aged 30 to 34",
                indicatorName: "Indigenous females aged 30 to 34 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 659,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_30_34_y_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 20,
                y_label: "Aged 30 to 34",
                indicatorName: "Indigenous males aged 30 to 34 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 641,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_25_29_y_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 21,
                y_label: "Aged 25 to 29",
                indicatorName: "Indigenous females aged 25 to 29 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 630,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_25_29_y_Indigenous_M*dr",
                indicatorDate: null,
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 22,
                y_label: "Aged 25 to 29",
                indicatorName: "Indigenous males aged 25 to 29 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 640,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_20_24_y_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 23,
                y_label: "Aged 20 to 24",
                indicatorName: "Indigenous females aged 20 to 24 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 628,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_20_24_y_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 24,
                y_label: "Aged 20 to 24",
                indicatorName: "Indigenous males aged 20 to 24 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 639,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_15_19_y_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 25,
                y_label: "Aged 15 to 19",
                indicatorName: "Indigenous females aged 15 to 19 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 627,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_15_19_y_Indigenous_M*dr",
                indicatorDate: null,
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 26,
                y_label: "Aged 15 to 19",
                indicatorName: "Indigenous males aged 15 to 19 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 625,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_10_14_y_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 27,
                y_label: "Aged 10 to 14",
                indicatorName: "Indigenous females aged 10 to 14 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 657,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_10_14_y_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 28,
                y_label: "Aged 10 to 14",
                indicatorName: "Indigenous males aged 10 to 14 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 623,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_5_9_y_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 29,
                y_label: "Aged 5 to 9",
                indicatorName: "Indigenous females aged 5 to 9 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 624,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_5_9_y_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 30,
                y_label: "Aged 5 to 9",
                indicatorName: "Indigenous males aged 5 to 9 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 621,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_0_4_y_Indigenous_F*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 33,
                y_label: "Aged 0 to 4",
                indicatorName: "Indigenous females aged 0 to 4 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
              {
                id: 622,
                indicatorHTMLTableRef: null,
                chartRef: "17298635925179",
                indicatorCode: "A_0_4_y_Indigenous_M*dr",
                indicatorDate: "0",
                chartColour: null,
                domainHTMLTableColTwo: false,
                order: 32,
                y_label: "Aged 0 to 4",
                indicatorName: "Indigenous males aged 0 to 4 years",
                source: "Census 2021",
                date: "2021-01-01",
                decimalPlaces: 2,
                dateCoverageText: "2021",
              },
            ],
          },
        ],
      },
    ],
    comparatorAreaCodes: ["CSTM5160", "E10000012", "E10000011"],
    pyramidIndicatorValues: {
      "CSTM5160.mye_16_64_females*dr.2022-06-01.Female population aged 16 to 64":
        "100,222",
      "E10000012.mye_16_64_females*dr.2022-06-01.Female population aged 16 to 64":
        "18,204,953",
      "E10000011.mye_16_64_females*dr.2022-06-01.Female population aged 16 to 64":
        "2,933,379",
      "CSTM5160.mye_16_64_males*dr.2022-06-01.Male population aged 16 to 64":
        "96,885",
      "E10000012.mye_16_64_males*dr.2022-06-01.Male population aged 16 to 64":
        "17,696,258",
      "E10000011.mye_16_64_males*dr.2022-06-01.Male population aged 16 to 64":
        "2,854,079",
      "CSTM5160.mye_65plus_females*dr.2022-06-01.Female population aged 65 and over":
        "21,465",
      "E10000012.mye_65plus_females*dr.2022-06-01.Female population aged 65 and over":
        "5,756,066",
      "E10000011.mye_65plus_females*dr.2022-06-01.Female population aged 65 and over":
        "1,005,926",
      "CSTM5160.mye_65plus_males*dr.2022-06-01.Male population aged 65 and over":
        "18,053",
      "E10000012.mye_65plus_males*dr.2022-06-01.Male population aged 65 and over":
        "4,873,801",
      "E10000011.mye_65plus_males*dr.2022-06-01.Male population aged 65 and over":
        "841,069",
      "CSTM5160.mye_16_64_females*dr.2022-06-01.Female population aged 16 to 64.rate":
        "36.06%", //xx f
      "E10000012.mye_16_64_females*dr.2022-06-01.Female population aged 16 to 64.rate":
        "31.88%",
      "E10000011.mye_16_64_females*dr.2022-06-01.Female population aged 16 to 64.rate":
        "31.27%",
      "CSTM5160.mye_16_64_males*dr.2022-06-01.Male population aged 16 to 64.rate":
        "34.86%", //xx m
      "E10000012.mye_16_64_males*dr.2022-06-01.Male population aged 16 to 64.rate":
        "30.99%",
      "E10000011.mye_16_64_males*dr.2022-06-01.Male population aged 16 to 64.rate":
        "30.43%",
      "CSTM5160.mye_65plus_females*dr.2022-06-01.Female population aged 65 and over.rate":
        "7.72%", //xx f
      "E10000012.mye_65plus_females*dr.2022-06-01.Female population aged 65 and over.rate":
        "10.08%",
      "E10000011.mye_65plus_females*dr.2022-06-01.Female population aged 65 and over.rate":
        "10.72%",
      "CSTM5160.mye_65plus_males*dr.2022-06-01.Male population aged 65 and over.rate":
        "6.49%", //xx m
      "E10000012.mye_65plus_males*dr.2022-06-01.Male population aged 65 and over.rate":
        "8.53%",
      "E10000011.mye_65plus_males*dr.2022-06-01.Male population aged 65 and over.rate":
        "8.97%",
    },
    ausComparatorAreaCodes: ["ASTE8", "ASTE1", "CSTM55"],
    ausPyramidIndicatorValues: {
      "CSTM55.P_Tot_Married.2021-01-01.G05: PERSONS_Total_Married": "67",
      "ASTE8.P_Tot_Married.2021-01-01.G05: PERSONS_Total_Married": "173,618",
      "ASTE1.P_Tot_Married.2021-01-01.G05: PERSONS_Total_Married": "3,124,151",
      "CSTM55.A_65_y_ov_Indigenous_F*dr.2021-01-01.Indigenous females aged 65+ years":
        "2",
      "ASTE8.A_65_y_ov_Indigenous_F*dr.2021-01-01.Indigenous females aged 65+ years":
        "198",
      "ASTE1.A_65_y_ov_Indigenous_F*dr.2021-01-01.Indigenous females aged 65+ years":
        "9,561",
      "CSTM55.A_65_y_ov_Indigenous_M*dr.2021-01-01.Indigenous males aged 65+ years":
        "0",
      "ASTE8.A_65_y_ov_Indigenous_M*dr.2021-01-01.Indigenous males aged 65+ years":
        "191",
      "ASTE1.A_65_y_ov_Indigenous_M*dr.2021-01-01.Indigenous males aged 65+ years":
        "8,408",
      "CSTM55.A_60_64_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 60 to 64 years":
        "1",
      "ASTE8.A_60_64_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 60 to 64 years":
        "145",
      "ASTE1.A_60_64_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 60 to 64 years":
        "5,273",
      "CSTM55.A_60_64_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 60 to 64 years":
        "0",
      "ASTE8.A_60_64_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 60 to 64 years":
        "103",
      "ASTE1.A_60_64_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 60 to 64 years":
        "4,779",
      "CSTM55.A_55_59_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 55 to 59 years":
        "0",
      "ASTE8.A_55_59_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 55 to 59 years":
        "155",
      "ASTE1.A_55_59_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 55 to 59 years":
        "6,241",
      "CSTM55.A_55_59_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 55 to 59 years":
        "0",
      "ASTE8.A_55_59_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 55 to 59 years":
        "174",
      "ASTE1.A_55_59_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 55 to 59 years":
        "5,515",
      "CSTM55.A_50_54_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 50 to 54 years":
        "0",
      "ASTE8.A_50_54_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 50 to 54 years":
        "214",
      "ASTE1.A_50_54_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 50 to 54 years":
        "7,195",
      "CSTM55.A_50_54_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 50 to 54 years":
        "0",
      "ASTE8.A_50_54_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 50 to 54 years":
        "199",
      "ASTE1.A_50_54_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 50 to 54 years":
        "6,304",
      "CSTM55.A_45_49_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 45 to 49 years":
        "1",
      "ASTE8.A_45_49_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 45 to 49 years":
        "217",
      "ASTE1.A_45_49_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 45 to 49 years":
        "7,363",
      "CSTM55.A_45_49_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 45 to 49 years":
        "1",
      "ASTE8.A_45_49_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 45 to 49 years":
        "226",
      "ASTE1.A_45_49_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 45 to 49 years":
        "6,563",
      "CSTM55.A_40_44_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 40 to 44 years":
        "0",
      "ASTE8.A_40_44_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 40 to 44 years":
        "276",
      "ASTE1.A_40_44_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 40 to 44 years":
        "6,840",
      "CSTM55.A_40_44_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 40 to 44 years":
        "0",
      "ASTE8.A_40_44_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 40 to 44 years":
        "228",
      "ASTE1.A_40_44_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 40 to 44 years":
        "6,270",
      "CSTM55.A_35_39_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 35 to 39 years":
        "0",
      "ASTE8.A_35_39_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 35 to 39 years":
        "278",
      "ASTE1.A_35_39_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 35 to 39 years":
        "7,781",
      "CSTM55.A_35_39_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 35 to 39 years":
        "0",
      "ASTE8.A_35_39_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 35 to 39 years":
        "286",
      "ASTE1.A_35_39_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 35 to 39 years":
        "7,279",
      "CSTM55.A_30_34_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 30 to 34 years":
        "0",
      "ASTE8.A_30_34_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 30 to 34 years":
        "367",
      "ASTE1.A_30_34_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 30 to 34 years":
        "8,941",
      "CSTM55.A_30_34_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 30 to 34 years":
        "1",
      "ASTE8.A_30_34_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 30 to 34 years":
        "339",
      "ASTE1.A_30_34_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 30 to 34 years":
        "8,610",
      "CSTM55.A_25_29_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 25 to 29 years":
        "2",
      "ASTE8.A_25_29_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 25 to 29 years":
        "396",
      "ASTE1.A_25_29_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 25 to 29 years":
        "10,577",
      "CSTM55.A_25_29_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 25 to 29 years":
        "0",
      "ASTE8.A_25_29_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 25 to 29 years":
        "443",
      "ASTE1.A_25_29_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 25 to 29 years":
        "10,492",
      "CSTM55.A_20_24_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 20 to 24 years":
        "1",
      "ASTE8.A_20_24_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 20 to 24 years":
        "469",
      "ASTE1.A_20_24_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 20 to 24 years":
        "11,893",
      "CSTM55.A_20_24_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 20 to 24 years":
        "0",
      "ASTE8.A_20_24_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 20 to 24 years":
        "469",
      "ASTE1.A_20_24_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 20 to 24 years":
        "12,094",
      "CSTM55.A_15_19_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 15 to 19 years":
        "0",
      "ASTE8.A_15_19_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 15 to 19 years":
        "451",
      "ASTE1.A_15_19_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 15 to 19 years":
        "13,399",
      "CSTM55.A_15_19_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 15 to 19 years":
        "0",
      "ASTE8.A_15_19_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 15 to 19 years":
        "442",
      "ASTE1.A_15_19_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 15 to 19 years":
        "14,115",
      "CSTM55.A_10_14_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 10 to 14 years":
        "0",
      "ASTE8.A_10_14_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 10 to 14 years":
        "360",
      "ASTE1.A_10_14_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 10 to 14 years":
        "15,281",
      "CSTM55.A_10_14_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 10 to 14 years":
        "0",
      "ASTE8.A_10_14_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 10 to 14 years":
        "477",
      "ASTE1.A_10_14_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 10 to 14 years":
        "15,932",
      "CSTM55.A_5_9_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 5 to 9 years":
        "0",
      "ASTE8.A_5_9_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 5 to 9 years":
        "450",
      "ASTE1.A_5_9_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 5 to 9 years":
        "14,865",
      "CSTM55.A_5_9_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 5 to 9 years":
        "1",
      "ASTE8.A_5_9_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 5 to 9 years":
        "492",
      "ASTE1.A_5_9_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 5 to 9 years":
        "16,250",
      "CSTM55.A_0_4_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 0 to 4 years":
        "2",
      "ASTE8.A_0_4_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 0 to 4 years":
        "464",
      "ASTE1.A_0_4_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 0 to 4 years":
        "15,576",
      "CSTM55.A_0_4_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 0 to 4 years":
        "0",
      "ASTE8.A_0_4_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 0 to 4 years":
        "430",
      "ASTE1.A_0_4_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 0 to 4 years":
        "14,664",
      // the data below here is what is used #######################
      "CSTM55.A_65_y_ov_Indigenous_F*dr.2021-01-01.Indigenous females aged 65+ years.rate":
        "0.16%", // F
      "ASTE8.A_65_y_ov_Indigenous_F*dr.2021-01-01.Indigenous females aged 65+ years.rate":
        "0.09%",
      "ASTE1.A_65_y_ov_Indigenous_F*dr.2021-01-01.Indigenous females aged 65+ years.rate":
        "0.23%",
      "CSTM55.A_65_y_ov_Indigenous_M*dr.2021-01-01.Indigenous males aged 65+ years.rate":
        "0.15%", // M
      "ASTE8.A_65_y_ov_Indigenous_M*dr.2021-01-01.Indigenous males aged 65+ years.rate":
        "0.09%",
      "ASTE1.A_65_y_ov_Indigenous_M*dr.2021-01-01.Indigenous males aged 65+ years.rate":
        "0.21%",
      "CSTM55.A_60_64_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 60 to 64 years.rate":
        "0.08%", // F
      "ASTE8.A_60_64_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 60 to 64 years.rate":
        "0.06%",
      "ASTE1.A_60_64_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 60 to 64 years.rate":
        "0.13%",
      "CSTM55.A_60_64_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 60 to 64 years.rate":
        "0.00%", // M
      "ASTE8.A_60_64_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 60 to 64 years.rate":
        "0.05%",
      "ASTE1.A_60_64_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 60 to 64 years.rate":
        "0.12%",
      "CSTM55.A_55_59_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 55 to 59 years.rate":
        "0.00%", // F
      "ASTE8.A_55_59_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 55 to 59 years.rate":
        "0.07%",
      "ASTE1.A_55_59_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 55 to 59 years.rate":
        "0.15%",
      "CSTM55.A_55_59_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 55 to 59 years.rate":
        "0.15%", // M
      "ASTE8.A_55_59_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 55 to 59 years.rate":
        "0.08%",
      "ASTE1.A_55_59_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 55 to 59 years.rate":
        "0.14%",
      "CSTM55.A_50_54_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 50 to 54 years.rate":
        "0.17%", // F
      "ASTE8.A_50_54_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 50 to 54 years.rate":
        "0.09%",
      "ASTE1.A_50_54_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 50 to 54 years.rate":
        "0.18%",
      "CSTM55.A_50_54_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 50 to 54 years.rate":
        "0.00%", // M
      "ASTE8.A_50_54_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 50 to 54 years.rate":
        "0.09%",
      "ASTE1.A_50_54_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 50 to 54 years.rate":
        "0.16%",
      "CSTM55.A_45_49_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 45 to 49 years.rate":
        "0.10%", // F
      "ASTE8.A_45_49_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 45 to 49 years.rate":
        "0.09%",
      "ASTE1.A_45_49_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 45 to 49 years.rate":
        "0.18%",
      "CSTM55.A_45_49_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 45 to 49 years.rate":
        "0.11%", // M
      "ASTE8.A_45_49_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 45 to 49 years.rate":
        "0.10%",
      "ASTE1.A_45_49_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 45 to 49 years.rate":
        "0.16%",
      "CSTM55.A_40_44_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 40 to 44 years.rate":
        "0.00%", // F
      "ASTE8.A_40_44_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 40 to 44 years.rate":
        "0.12%",
      "ASTE1.A_40_44_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 40 to 44 years.rate":
        "0.17%",
      "CSTM55.A_40_44_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 40 to 44 years.rate":
        "0.00%", // M
      "ASTE8.A_40_44_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 40 to 44 years.rate":
        "0.10%",
      "ASTE1.A_40_44_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 40 to 44 years.rate":
        "0.16%",
      "CSTM55.A_35_39_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 35 to 39 years.rate":
        "0.00%", // F
      "ASTE8.A_35_39_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 35 to 39 years.rate":
        "0.12%",
      "ASTE1.A_35_39_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 35 to 39 years.rate":
        "0.19%",
      "CSTM55.A_35_39_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 35 to 39 years.rate":
        "0.00%", // M
      "ASTE8.A_35_39_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 35 to 39 years.rate":
        "0.13%",
      "ASTE1.A_35_39_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 35 to 39 years.rate":
        "0.18%",
      "CSTM55.A_30_34_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 30 to 34 years.rate":
        "0.00%", // F
      "ASTE8.A_30_34_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 30 to 34 years.rate":
        "0.16%",
      "ASTE1.A_30_34_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 30 to 34 years.rate":
        "0.22%",
      "CSTM55.A_30_34_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 30 to 34 years.rate":
        "0.11%", // M
      "ASTE8.A_30_34_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 30 to 34 years.rate":
        "0.15%",
      "ASTE1.A_30_34_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 30 to 34 years.rate":
        "0.22%",
      "CSTM55.A_25_29_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 25 to 29 years.rate":
        "0.18%", // F
      "ASTE8.A_25_29_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 25 to 29 years.rate":
        "0.17%",
      "ASTE1.A_25_29_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 25 to 29 years.rate":
        "0.16%",
      "CSTM55.A_25_29_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 25 to 29 years.rate":
        "0.15%", // M
      "ASTE8.A_25_29_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 25 to 29 years.rate":
        "0.20%",
      "ASTE1.A_25_29_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 25 to 29 years.rate":
        "0.26%",
      "CSTM55.A_20_24_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 20 to 24 years.rate":
        "0.16%", // F
      "ASTE8.A_20_24_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 20 to 24 years.rate":
        "0.20%",
      "ASTE1.A_20_24_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 20 to 24 years.rate":
        "0.19%",
      "CSTM55.A_20_24_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 20 to 24 years.rate":
        "0.00%", // M
      "ASTE8.A_20_24_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 20 to 24 years.rate":
        "0.21%",
      "ASTE1.A_20_24_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 20 to 24 years.rate":
        "0.10%",
      "CSTM55.A_15_19_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 15 to 19 years.rate":
        "0.00%", // F
      "ASTE8.A_15_19_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 15 to 19 years.rate":
        "0.20%",
      "ASTE1.A_15_19_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 15 to 19 years.rate":
        "0.13%",
      "CSTM55.A_15_19_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 15 to 19 years.rate":
        "0.00%", // M
      "ASTE8.A_15_19_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 15 to 19 years.rate":
        "0.20%",
      "ASTE1.A_15_19_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 15 to 19 years.rate":
        "0.15%",
      "CSTM55.A_10_14_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 10 to 14 years.rate":
        "0.00%", // F
      "ASTE8.A_10_14_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 10 to 14 years.rate":
        "0.16%",
      "ASTE1.A_10_14_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 10 to 14 years.rate":
        "0.11%",
      "CSTM55.A_10_14_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 10 to 14 years.rate":
        "0.00%", // M
      "ASTE8.A_10_14_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 10 to 14 years.rate":
        "0.21%",
      "ASTE1.A_10_14_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 10 to 14 years.rate":
        "0.40%",
      "CSTM55.A_5_9_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 5 to 9 years.rate":
        "0.00%", // F
      "ASTE8.A_5_9_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 5 to 9 years.rate":
        "0.20%",
      "ASTE1.A_5_9_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 5 to 9 years.rate":
        "0.36%",
      "CSTM55.A_5_9_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 5 to 9 years.rate":
        "0.15%", // M
      "ASTE8.A_5_9_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 5 to 9 years.rate":
        "0.22%",
      "ASTE1.A_5_9_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 5 to 9 years.rate":
        "0.12%",
      "CSTM55.A_0_4_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 0 to 4 years.rate":
        "0.17%", // F-???
      "ASTE8.A_0_4_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 0 to 4 years.rate":
        "0.19%",
      "ASTE1.A_0_4_y_Indigenous_F*dr.2021-01-01.Indigenous females aged 0 to 4 years.rate":
        "0.16%",
      "CSTM55.A_0_4_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 0 to 4 years.rate":
        "0.00%", // M-??
      "ASTE8.A_0_4_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 0 to 4 years.rate":
        "0.21%",
      "ASTE1.A_0_4_y_Indigenous_M*dr.2021-01-01.Indigenous males aged 0 to 4 years.rate":
        "0.29%",
    },
    indicatorValues: {
      "CSTM5160.census11_p07_all.2021-01-01.All people (Census 2021)":
        "277,103",
      "E10000012.census11_p07_all.2021-01-01.All people (Census 2021)":
        "1,503,521",
      "E10000011.census11_p07_all.2021-01-01.All people (Census 2021)":
        "545,847",
      "CSTM5160.census11_p06_all*dr.2021-01-01.All Females (Census 2021)":
        "141,540",
      "E10000012.census11_p06_all*dr.2021-01-01.All Females (Census 2021)":
        "771,189",
      "E10000011.census11_p06_all*dr.2021-01-01.All Females (Census 2021)":
        "283,700",
      "CSTM5160.census11_p05_all*dr.2021-01-01.All Males (Census 2021)":
        "135,563",
      "E10000012.census11_p05_all*dr.2021-01-01.All Males (Census 2021)":
        "732,332",
      "E10000011.census11_p05_all*dr.2021-01-01.All Males (Census 2021)":
        "262,147",
      "CSTM5160.census11_015*dr.2021-01-01.Population aged under 16 (Census 2021)":
        "41,738",
      "E10000012.census11_015*dr.2021-01-01.Population aged under 16 (Census 2021)":
        "279,345",
      "E10000011.census11_015*dr.2021-01-01.Population aged under 16 (Census 2021)":
        "90,208",
      "CSTM5160.census11_1664*dr.2021-01-01.Population aged 16 to 64 (Census 2021)":
        "196,389",
      "E10000012.census11_1664*dr.2021-01-01.Population aged 16 to 64 (Census 2021)":
        "914,197",
      "E10000011.census11_1664*dr.2021-01-01.Population aged 16 to 64 (Census 2021)":
        "313,161",
      "CSTM5160.census11_65pl*dr.2021-01-01.Population aged 65+ (Census 2021)":
        "38,976",
      "E10000012.census11_65pl*dr.2021-01-01.Population aged 65+ (Census 2021)":
        "309,979",
      "E10000011.census11_65pl*dr.2021-01-01.Population aged 65+ (Census 2021)":
        "142,478",
      "CSTM5160.census11_p07_all*dr.2021-01-01.Population Density (Persons per sq km) (Census 2021)":
        "277,103",
      "E10000012.census11_p07_all*dr.2021-01-01.Population Density (Persons per sq km) (Census 2021)":
        "1,503,521",
      "E10000011.census11_p07_all*dr.2021-01-01.Population Density (Persons per sq km) (Census 2021)":
        "545,847",
      "CSTM5160.imd19_score.2019-01-01.Index of Multiple Deprivation 2019 (IMD) Score":
        "20.76",
      "E10000012.imd19_score.2019-01-01.Index of Multiple Deprivation 2019 (IMD) Score":
        "17.02",
      "E10000011.imd19_score.2019-01-01.Index of Multiple Deprivation 2019 (IMD) Score":
        "19.85",
      "CSTM5160.imd19_rank.2019-01-01.Index of Multiple Deprivation (IMD) 2019 Rank":
        "16,607",
      "E10000012.imd19_rank.2019-01-01.Index of Multiple Deprivation (IMD) 2019 Rank":
        "19,225",
      "E10000011.imd19_rank.2019-01-01.Index of Multiple Deprivation (IMD) 2019 Rank":
        "17,191",
      "CSTM5160.imd19pop_dec1*dr.2019-01-01.IMD 2019 Decile 1": "23,396",
      "E10000012.imd19pop_dec1*dr.2019-01-01.IMD 2019 Decile 1": "50,384",
      "E10000011.imd19pop_dec1*dr.2019-01-01.IMD 2019 Decile 1": "37,436",
      "CSTM5160.imd19pop_dec2*dr.2019-01-01.IMD 2019 Decile 2": "25,496",
      "E10000012.imd19pop_dec2*dr.2019-01-01.IMD 2019 Decile 2": "73,256",
      "E10000011.imd19pop_dec2*dr.2019-01-01.IMD 2019 Decile 2": "40,796",
      "CSTM5160.imd19pop_dec3*dr.2019-01-01.IMD 2019 Decile 3": "27,834",
      "E10000012.imd19pop_dec3*dr.2019-01-01.IMD 2019 Decile 3": "120,351",
      "E10000011.imd19pop_dec3*dr.2019-01-01.IMD 2019 Decile 3": "46,434",
      "CSTM5160.imd19pop_dec4*dr.2019-01-01.IMD 2019 Decile 4": "21,166",
      "E10000012.imd19pop_dec4*dr.2019-01-01.IMD 2019 Decile 4": "143,944",
      "E10000011.imd19pop_dec4*dr.2019-01-01.IMD 2019 Decile 4": "54,870",
      "CSTM5160.imd19pop_dec5*dr.2019-01-01.IMD 2019 Decile 5": "25,317",
      "E10000012.imd19pop_dec5*dr.2019-01-01.IMD 2019 Decile 5": "171,455",
      "E10000011.imd19pop_dec5*dr.2019-01-01.IMD 2019 Decile 5": "64,384",
      "CSTM5160.imd19pop_dec6*dr.2019-01-01.IMD 2019 Decile 6": "44,171",
      "E10000012.imd19pop_dec6*dr.2019-01-01.IMD 2019 Decile 6": "161,385",
      "E10000011.imd19pop_dec6*dr.2019-01-01.IMD 2019 Decile 6": "73,962",
      "CSTM5160.imd19pop_dec7*dr.2019-01-01.IMD 2019 Decile 7": "39,512",
      "E10000012.imd19pop_dec7*dr.2019-01-01.IMD 2019 Decile 7": "162,203",
      "E10000011.imd19pop_dec7*dr.2019-01-01.IMD 2019 Decile 7": "62,453",
      "CSTM5160.imd19pop_dec8*dr.2019-01-01.IMD 2019 Decile 8": "38,480",
      "E10000012.imd19pop_dec8*dr.2019-01-01.IMD 2019 Decile 8": "191,719",
      "E10000011.imd19pop_dec8*dr.2019-01-01.IMD 2019 Decile 8": "79,042",
      "CSTM5160.imd19pop_dec9*dr.2019-01-01.IMD 2019 Decile 9": "23,044",
      "E10000012.imd19pop_dec9*dr.2019-01-01.IMD 2019 Decile 9": "186,889",
      "E10000011.imd19pop_dec9*dr.2019-01-01.IMD 2019 Decile 9": "50,795",
      "CSTM5160.imd19pop_dec10*dr.2019-01-01.IMD 2019 Decile 10": "15,657",
      "E10000012.imd19pop_dec10*dr.2019-01-01.IMD 2019 Decile 10": "183,458",
      "E10000011.imd19pop_dec10*dr.2019-01-01.IMD 2019 Decile 10": "34,513",
      "CSTM5160.claimant_count*dr.2024-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "8,525",
      "E10000012.claimant_count*dr.2024-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "30,345",
      "E10000011.claimant_count*dr.2024-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,760",
      "CSTM5160.claimant_count_1824*dr.2024-08-01.Youth unemployment (18-24 receiving JSA or Universal Credit)":
        "1,385",
      "E10000012.claimant_count_1824*dr.2024-08-01.Youth unemployment (18-24 receiving JSA or Universal Credit)":
        "4,985",
      "E10000011.claimant_count_1824*dr.2024-08-01.Youth unemployment (18-24 receiving JSA or Universal Credit)":
        "1,965",
      "CSTM5160.claimant_count_50pl*dr.2024-08-01.Older person unemployment (50+ receiving JSA or Universal Credit)":
        "2,125",
      "E10000012.claimant_count_50pl*dr.2024-08-01.Older person unemployment (50+ receiving JSA or Universal Credit)":
        "7,175",
      "E10000011.claimant_count_50pl*dr.2024-08-01.Older person unemployment (50+ receiving JSA or Universal Credit)":
        "3,100",
      "CSTM5160.claimant_count_female*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), female":
        "3,635",
      "E10000012.claimant_count_female*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), female":
        "14,775",
      "E10000011.claimant_count_female*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), female":
        "5,465",
      "CSTM5160.claimant_count_male*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), male":
        "4,890",
      "E10000012.claimant_count_male*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), male":
        "15,570",
      "E10000011.claimant_count_male*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), male":
        "6,300",
      "CSTM5160.claimant_count*dr.2020-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "5,610",
      "E10000012.claimant_count*dr.2020-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "20,535",
      "E10000011.claimant_count*dr.2020-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "8,895",
      "CSTM5160.claimant_count*dr.2020-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "5,890",
      "E10000012.claimant_count*dr.2020-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "21,550",
      "E10000011.claimant_count*dr.2020-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "9,175",
      "CSTM5160.claimant_count*dr.2020-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "5,880",
      "E10000012.claimant_count*dr.2020-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "21,805",
      "E10000011.claimant_count*dr.2020-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "9,180",
      "CSTM5160.claimant_count*dr.2020-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,350",
      "E10000012.claimant_count*dr.2020-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "39,305",
      "E10000011.claimant_count*dr.2020-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "16,670",
      "CSTM5160.claimant_count*dr.2020-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,180",
      "E10000012.claimant_count*dr.2020-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "54,595",
      "E10000011.claimant_count*dr.2020-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "20,775",
      "CSTM5160.claimant_count*dr.2020-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "13,465",
      "E10000012.claimant_count*dr.2020-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "51,260",
      "E10000011.claimant_count*dr.2020-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "19,480",
      "CSTM5160.claimant_count*dr.2020-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,035",
      "E10000012.claimant_count*dr.2020-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "52,395",
      "E10000011.claimant_count*dr.2020-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "20,000",
      "CSTM5160.claimant_count*dr.2020-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,410",
      "E10000012.claimant_count*dr.2020-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "54,125",
      "E10000011.claimant_count*dr.2020-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "20,575",
      "CSTM5160.claimant_count*dr.2020-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,510",
      "E10000012.claimant_count*dr.2020-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "53,460",
      "E10000011.claimant_count*dr.2020-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "20,365",
      "CSTM5160.claimant_count*dr.2020-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "13,845",
      "E10000012.claimant_count*dr.2020-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "50,575",
      "E10000011.claimant_count*dr.2020-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "19,225",
      "CSTM5160.claimant_count*dr.2020-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "13,935",
      "E10000012.claimant_count*dr.2020-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "50,915",
      "E10000011.claimant_count*dr.2020-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "19,680",
      "CSTM5160.claimant_count*dr.2020-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,000",
      "E10000012.claimant_count*dr.2020-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "50,765",
      "E10000011.claimant_count*dr.2020-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "19,570",
      "CSTM5160.claimant_count*dr.2021-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "13,655",
      "E10000012.claimant_count*dr.2021-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "49,335",
      "E10000011.claimant_count*dr.2021-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "19,255",
      "CSTM5160.claimant_count*dr.2021-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,325",
      "E10000012.claimant_count*dr.2021-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "52,055",
      "E10000011.claimant_count*dr.2021-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "20,250",
      "CSTM5160.claimant_count*dr.2021-02-02.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,265",
      "E10000012.claimant_count*dr.2021-02-02.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "51,700",
      "E10000011.claimant_count*dr.2021-02-02.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "20,160",
      "CSTM5160.claimant_count*dr.2021-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,365",
      "E10000012.claimant_count*dr.2021-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "51,500",
      "E10000011.claimant_count*dr.2021-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "20,190",
      "CSTM5160.claimant_count*dr.2021-03-03.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,245",
      "E10000012.claimant_count*dr.2021-03-03.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "51,205",
      "E10000011.claimant_count*dr.2021-03-03.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "20,035",
      "CSTM5160.claimant_count*dr.2021-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,000",
      "E10000012.claimant_count*dr.2021-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "50,770",
      "E10000011.claimant_count*dr.2021-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "19,760",
      "CSTM5160.claimant_count*dr.2021-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "13,080",
      "E10000012.claimant_count*dr.2021-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "46,840",
      "E10000011.claimant_count*dr.2021-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "18,450",
      "CSTM5160.claimant_count*dr.2021-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,920",
      "E10000012.claimant_count*dr.2021-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "42,420",
      "E10000011.claimant_count*dr.2021-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "16,855",
      "CSTM5160.claimant_count*dr.2021-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,705",
      "E10000012.claimant_count*dr.2021-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "41,075",
      "E10000011.claimant_count*dr.2021-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "16,390",
      "CSTM5160.claimant_count*dr.2021-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,165",
      "E10000012.claimant_count*dr.2021-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "39,440",
      "E10000011.claimant_count*dr.2021-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "15,710",
      "CSTM5160.claimant_count*dr.2021-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,665",
      "E10000012.claimant_count*dr.2021-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "36,855",
      "E10000011.claimant_count*dr.2021-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,840",
      "CSTM5160.claimant_count*dr.2021-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,155",
      "E10000012.claimant_count*dr.2021-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "35,020",
      "E10000011.claimant_count*dr.2021-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,560",
      "CSTM5160.claimant_count*dr.2021-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "9,940",
      "E10000012.claimant_count*dr.2021-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "33,660",
      "E10000011.claimant_count*dr.2021-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "14,360",
      "CSTM5160.claimant_count*dr.2021-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "9,590",
      "E10000012.claimant_count*dr.2021-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "32,785",
      "E10000011.claimant_count*dr.2021-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "13,890",
      "CSTM5160.claimant_count*dr.2022-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "9,345",
      "E10000012.claimant_count*dr.2022-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "32,025",
      "E10000011.claimant_count*dr.2022-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "13,825",
      "CSTM5160.claimant_count*dr.2022-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "9,360",
      "E10000012.claimant_count*dr.2022-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "31,925",
      "E10000011.claimant_count*dr.2022-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "13,855",
      "CSTM5160.claimant_count*dr.2022-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "9,070",
      "E10000012.claimant_count*dr.2022-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "30,105",
      "E10000011.claimant_count*dr.2022-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "13,405",
      "CSTM5160.claimant_count*dr.2022-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "8,475",
      "E10000012.claimant_count*dr.2022-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "27,765",
      "E10000011.claimant_count*dr.2022-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "12,250",
      "CSTM5160.claimant_count*dr.2022-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "8,105",
      "E10000012.claimant_count*dr.2022-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "26,840",
      "E10000011.claimant_count*dr.2022-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "12,075",
      "CSTM5160.claimant_count*dr.2022-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,895",
      "E10000012.claimant_count*dr.2022-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,905",
      "E10000011.claimant_count*dr.2022-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,535",
      "CSTM5160.claimant_count*dr.2022-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,820",
      "E10000012.claimant_count*dr.2022-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,675",
      "E10000011.claimant_count*dr.2022-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,420",
      "CSTM5160.claimant_count*dr.2022-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,785",
      "E10000012.claimant_count*dr.2022-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,680",
      "E10000011.claimant_count*dr.2022-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,375",
      "CSTM5160.claimant_count*dr.2022-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,810",
      "E10000012.claimant_count*dr.2022-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,580",
      "E10000011.claimant_count*dr.2022-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,370",
      "CSTM5160.claimant_count*dr.2022-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,760",
      "E10000012.claimant_count*dr.2022-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,075",
      "E10000011.claimant_count*dr.2022-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,280",
      "CSTM5160.claimant_count*dr.2022-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,860",
      "E10000012.claimant_count*dr.2022-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,425",
      "E10000011.claimant_count*dr.2022-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,385",
      "CSTM5160.claimant_count*dr.2022-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,920",
      "E10000012.claimant_count*dr.2022-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,125",
      "E10000011.claimant_count*dr.2022-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,200",
      "CSTM5160.claimant_count*dr.2023-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,730",
      "E10000012.claimant_count*dr.2023-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "24,925",
      "E10000011.claimant_count*dr.2023-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,225",
      "CSTM5160.claimant_count*dr.2023-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,760",
      "E10000012.claimant_count*dr.2023-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,285",
      "E10000011.claimant_count*dr.2023-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,360",
      "CSTM5160.claimant_count*dr.2023-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,705",
      "E10000012.claimant_count*dr.2023-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,580",
      "E10000011.claimant_count*dr.2023-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,240",
      "CSTM5160.claimant_count*dr.2023-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,740",
      "E10000012.claimant_count*dr.2023-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "26,145",
      "E10000011.claimant_count*dr.2023-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,210",
      "CSTM5160.claimant_count*dr.2023-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,455",
      "E10000012.claimant_count*dr.2023-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,395",
      "E10000011.claimant_count*dr.2023-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,715",
      "CSTM5160.claimant_count*dr.2023-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,700",
      "E10000012.claimant_count*dr.2023-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,855",
      "E10000011.claimant_count*dr.2023-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,805",
      "CSTM5160.claimant_count*dr.2023-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,810",
      "E10000012.claimant_count*dr.2023-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,960",
      "E10000011.claimant_count*dr.2023-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,700",
      "CSTM5160.claimant_count*dr.2023-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,815",
      "E10000012.claimant_count*dr.2023-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,665",
      "E10000011.claimant_count*dr.2023-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,535",
      "CSTM5160.claimant_count*dr.2023-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,800",
      "E10000012.claimant_count*dr.2023-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "25,955",
      "E10000011.claimant_count*dr.2023-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,490",
      "CSTM5160.claimant_count*dr.2023-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,810",
      "E10000012.claimant_count*dr.2023-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "26,015",
      "E10000011.claimant_count*dr.2023-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,465",
      "CSTM5160.claimant_count*dr.2023-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,845",
      "E10000012.claimant_count*dr.2023-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "26,645",
      "E10000011.claimant_count*dr.2023-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,675",
      "CSTM5160.claimant_count*dr.2023-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,935",
      "E10000012.claimant_count*dr.2023-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "26,620",
      "E10000011.claimant_count*dr.2023-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,825",
      "CSTM5160.claimant_count*dr.2024-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "8,120",
      "E10000012.claimant_count*dr.2024-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "27,055",
      "E10000011.claimant_count*dr.2024-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,950",
      "CSTM5160.claimant_count*dr.2024-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "8,275",
      "E10000012.claimant_count*dr.2024-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "27,890",
      "E10000011.claimant_count*dr.2024-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,185",
      "CSTM5160.claimant_count*dr.2024-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "8,060",
      "E10000012.claimant_count*dr.2024-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "27,955",
      "E10000011.claimant_count*dr.2024-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,010",
      "CSTM5160.claimant_count*dr.2024-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,930",
      "E10000012.claimant_count*dr.2024-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "27,575",
      "E10000011.claimant_count*dr.2024-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,765",
      "CSTM5160.claimant_count*dr.2024-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,795",
      "E10000012.claimant_count*dr.2024-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "27,700",
      "E10000011.claimant_count*dr.2024-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "10,825",
      "CSTM5160.claimant_count*dr.2024-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "7,925",
      "E10000012.claimant_count*dr.2024-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "28,260",
      "E10000011.claimant_count*dr.2024-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,045",
      "CSTM5160.claimant_count*dr.2024-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "8,415",
      "E10000012.claimant_count*dr.2024-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "30,440",
      "E10000011.claimant_count*dr.2024-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants)":
        "11,745",
      "CSTM5160.landregistry_a_sumpricepaid_12_month*dr.2023-06-01.Average house price (12 months)":
        "1,189,316,598",
      "E10000012.landregistry_a_sumpricepaid_12_month*dr.2023-06-01.Average house price (12 months)":
        "5,226,360,706",
      "E10000011.landregistry_a_sumpricepaid_12_month*dr.2023-06-01.Average house price (12 months)":
        "2,236,972,884",
      "CSTM5160.landregistry_d_sumpricepaid_12_month*dr.2023-06-01.Average house price for detached houses (12 months)":
        "219,693,408",
      "E10000012.landregistry_d_sumpricepaid_12_month*dr.2023-06-01.Average house price for detached houses (12 months)":
        "2,196,236,782",
      "E10000011.landregistry_d_sumpricepaid_12_month*dr.2023-06-01.Average house price for detached houses (12 months)":
        "1,029,590,315",
      "CSTM5160.landregistry_f_sumpricepaid_12_month*dr.2023-06-01.Average house price for flats (12 months)":
        "383,827,109",
      "E10000012.landregistry_f_sumpricepaid_12_month*dr.2023-06-01.Average house price for flats (12 months)":
        "504,227,082",
      "E10000011.landregistry_f_sumpricepaid_12_month*dr.2023-06-01.Average house price for flats (12 months)":
        "315,945,214",
      "CSTM5160.landregistry_s_sumpricepaid_12_month*dr.2023-06-01.Average house price for semi-detached houses (12 months)":
        "237,860,251",
      "E10000012.landregistry_s_sumpricepaid_12_month*dr.2023-06-01.Average house price for semi-detached houses (12 months)":
        "1,492,573,955",
      "E10000011.landregistry_s_sumpricepaid_12_month*dr.2023-06-01.Average house price for semi-detached houses (12 months)":
        "492,744,893",
      "CSTM5160.landregistry_t_sumpricepaid_12_month*dr.2023-06-01.Average house price for terraced houses (12 months)":
        "347,935,830",
      "E10000012.landregistry_t_sumpricepaid_12_month*dr.2023-06-01.Average house price for terraced houses (12 months)":
        "1,033,322,887",
      "E10000011.landregistry_t_sumpricepaid_12_month*dr.2023-06-01.Average house price for terraced houses (12 months)":
        "398,692,462",
      "CSTM5160.owneroccupied_2001*dr.2021-01-01.Owner occupied housing (Census 2021)":
        "63,414",
      "E10000012.owneroccupied_2001*dr.2021-01-01.Owner occupied housing (Census 2021)":
        "437,629",
      "E10000011.owneroccupied_2001*dr.2021-01-01.Owner occupied housing (Census 2021)":
        "166,388",
      "CSTM5160.ks402ew0005*dr.2021-01-01.Housing rented from council (Census 2021)":
        "11,587",
      "E10000012.ks402ew0005*dr.2021-01-01.Housing rented from council (Census 2021)":
        "45,368",
      "E10000011.ks402ew0005*dr.2021-01-01.Housing rented from council (Census 2021)":
        "10,835",
      "CSTM5160.ks402ew0006*dr.2021-01-01.Housing rented from a Housing Association (Census 2021)":
        "6,464",
      "E10000012.ks402ew0006*dr.2021-01-01.Housing rented from a Housing Association (Census 2021)":
        "43,161",
      "E10000011.ks402ew0006*dr.2021-01-01.Housing rented from a Housing Association (Census 2021)":
        "15,644",
      "CSTM5160.ks402ew0007*dr.2021-01-01.Private rented housing: Private landlord or letting agency (Census 2021)":
        "37,167",
      "E10000012.ks402ew0007*dr.2021-01-01.Private rented housing: Private landlord or letting agency (Census 2021)":
        "87,593",
      "E10000011.ks402ew0007*dr.2021-01-01.Private rented housing: Private landlord or letting agency (Census 2021)":
        "41,803",
      "CSTM5160.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total)":
        "44,702",
      "E10000012.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total)":
        "163,886",
      "E10000011.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total)":
        "61,101",
      "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total)":
        "6,312",
      "E10000012.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total)":
        "14,588",
      "E10000011.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total)":
        "9,069",
      "CSTM5160.bicycle_theft_police_uk_12_month*dr.2023-06-01.Bicycle theft offences (12 month total)":
        "959",
      "E10000012.bicycle_theft_police_uk_12_month*dr.2023-06-01.Bicycle theft offences (12 month total)":
        "1,499",
      "E10000011.bicycle_theft_police_uk_12_month*dr.2023-06-01.Bicycle theft offences (12 month total)":
        "351",
      "CSTM5160.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total)":
        "1,367",
      "E10000012.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total)":
        "7,333",
      "E10000011.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total)":
        "2,058",
      "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total)":
        "3,197",
      "E10000012.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total)":
        "12,903",
      "E10000011.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total)":
        "6,242",
      "CSTM5160.drugs_police_uk_12_month*dr.2023-06-01.Drug crime offences (12 month total)":
        "1,551",
      "E10000012.drugs_police_uk_12_month*dr.2023-06-01.Drug crime offences (12 month total)":
        "5,145",
      "E10000011.drugs_police_uk_12_month*dr.2023-06-01.Drug crime offences (12 month total)":
        "1,347",
      "CSTM5160.other_crime_police_uk_12_month*dr.2023-06-01.Other crime offences (12 month total)":
        "820",
      "E10000012.other_crime_police_uk_12_month*dr.2023-06-01.Other crime offences (12 month total)":
        "3,453",
      "E10000011.other_crime_police_uk_12_month*dr.2023-06-01.Other crime offences (12 month total)":
        "1,232",
      "CSTM5160.other_theft_police_uk_12_month*dr.2023-06-01.Other theft offences (12 month total)":
        "3,639",
      "E10000012.other_theft_police_uk_12_month*dr.2023-06-01.Other theft offences (12 month total)":
        "12,604",
      "E10000011.other_theft_police_uk_12_month*dr.2023-06-01.Other theft offences (12 month total)":
        "4,044",
      "CSTM5160.shoplifting_police_uk_12_month*dr.2023-06-01.Shoplifting offences (12 month total)":
        "5,144",
      "E10000012.shoplifting_police_uk_12_month*dr.2023-06-01.Shoplifting offences (12 month total)":
        "10,681",
      "E10000011.shoplifting_police_uk_12_month*dr.2023-06-01.Shoplifting offences (12 month total)":
        "5,436",
      "CSTM5160.possession_of_weapons_police_uk_12_month*dr.2023-06-01.Possession of weapons offences (12 month total)":
        "540",
      "E10000012.possession_of_weapons_police_uk_12_month*dr.2023-06-01.Possession of weapons offences (12 month total)":
        "1,726",
      "E10000011.possession_of_weapons_police_uk_12_month*dr.2023-06-01.Possession of weapons offences (12 month total)":
        "640",
      "CSTM5160.public_order_police_uk_12_month*dr.2023-06-01.Public order offences (12 month total)":
        "4,250",
      "E10000012.public_order_police_uk_12_month*dr.2023-06-01.Public order offences (12 month total)":
        "12,557",
      "E10000011.public_order_police_uk_12_month*dr.2023-06-01.Public order offences (12 month total)":
        "5,153",
      "CSTM5160.robbery_police_uk_12_month*dr.2023-06-01.Robbery recorded offences (12 month total)":
        "443",
      "E10000012.robbery_police_uk_12_month*dr.2023-06-01.Robbery recorded offences (12 month total)":
        "1,369",
      "E10000011.robbery_police_uk_12_month*dr.2023-06-01.Robbery recorded offences (12 month total)":
        "394",
      "CSTM5160.theft_from_the_person_police_uk_12_month*dr.2023-06-01.Theft from the person offences (12 month total)":
        "686",
      "E10000012.theft_from_the_person_police_uk_12_month*dr.2023-06-01.Theft from the person offences (12 month total)":
        "1,011",
      "E10000011.theft_from_the_person_police_uk_12_month*dr.2023-06-01.Theft from the person offences (12 month total)":
        "347",
      "CSTM5160.vehicle_crime_police_uk_12_month*dr.2023-06-01.Vehicle crime (12 month total)":
        "1,912",
      "E10000012.vehicle_crime_police_uk_12_month*dr.2023-06-01.Vehicle crime (12 month total)":
        "13,633",
      "E10000011.vehicle_crime_police_uk_12_month*dr.2023-06-01.Vehicle crime (12 month total)":
        "2,722",
      "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total)":
        "13,882",
      "E10000012.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total)":
        "65,384",
      "E10000011.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total)":
        "22,066",
      "CSTM5160.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total)":
        "35,276",
      "E10000012.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total)":
        "160,785",
      "E10000011.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total)":
        "46,875",
      "CSTM5160.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total)":
        "36,218",
      "E10000012.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total)":
        "161,080",
      "E10000011.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total)":
        "47,758",
      "CSTM5160.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total)":
        "37,004",
      "E10000012.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total)":
        "161,580",
      "E10000011.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total)":
        "49,251",
      "CSTM5160.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total)":
        "30,522",
      "E10000012.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total)":
        "144,652",
      "E10000011.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total)":
        "41,296",
      "CSTM5160.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total)":
        "37,787",
      "E10000012.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total)":
        "160,091",
      "E10000011.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total)":
        "49,755",
      "CSTM5160.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total)":
        "37,365",
      "E10000012.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total)":
        "160,881",
      "E10000011.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total)":
        "49,324",
      "CSTM5160.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total)":
        "35,960",
      "E10000012.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total)":
        "159,560",
      "E10000011.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total)":
        "48,539",
      "CSTM5160.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total)":
        "36,032",
      "E10000012.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total)":
        "162,516",
      "E10000011.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total)":
        "48,918",
      "CSTM5160.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total)":
        "34,943",
      "E10000012.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total)":
        "159,510",
      "E10000011.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total)":
        "48,127",
      "CSTM5160.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total)":
        "35,173",
      "E10000012.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total)":
        "156,521",
      "E10000011.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total)":
        "47,985",
      "CSTM5160.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total)":
        "35,567",
      "E10000012.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total)":
        "154,059",
      "E10000011.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total)":
        "46,939",
      "CSTM5160.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total)":
        "34,898",
      "E10000012.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total)":
        "151,164",
      "E10000011.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total)":
        "45,102",
      "CSTM5160.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total)":
        "33,946",
      "E10000012.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total)":
        "149,884",
      "E10000011.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total)":
        "44,481",
      "CSTM5160.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total)":
        "32,850",
      "E10000012.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total)":
        "147,595",
      "E10000011.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total)":
        "43,821",
      "CSTM5160.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total)":
        "32,574",
      "E10000012.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total)":
        "144,646",
      "E10000011.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total)":
        "43,977",
      "CSTM5160.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total)":
        "39,421",
      "E10000012.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total)":
        "171,758",
      "E10000011.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total)":
        "54,108",
      "CSTM5160.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total)":
        "47,038",
      "E10000012.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total)":
        "178,162",
      "E10000011.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total)":
        "64,272",
      "CSTM5160.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total)":
        "47,739",
      "E10000012.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total)":
        "186,545",
      "E10000011.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total)":
        "65,509",
      "CSTM5160.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total)":
        "48,115",
      "E10000012.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total)":
        "182,596",
      "E10000011.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total)":
        "65,592",
      "CSTM5160.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021)":
        "20,351",
      "E10000012.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021)":
        "102,130",
      "E10000011.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021)":
        "45,192",
      "CSTM5160.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021)":
        "31,446",
      "E10000012.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021)":
        "148,422",
      "E10000011.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021)":
        "65,361",
      "CSTM5160.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021)":
        "20,804",
      "E10000012.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021)":
        "107,180",
      "E10000011.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021)":
        "43,480",
      "CSTM5160.disability_5a_4*dr.2021-01-01.Not disabled under the Equality Act: No long term physical or mental health conditions (Census 2021)":
        "204,502",
      "E10000012.disability_5a_4*dr.2021-01-01.Not disabled under the Equality Act: No long term physical or mental health conditions (Census 2021)":
        "1,145,789",
      "E10000011.disability_5a_4*dr.2021-01-01.Not disabled under the Equality Act: No long term physical or mental health conditions (Census 2021)":
        "391,814",
      "CSTM5160.ks301ew0008*dr.2021-01-01.Very good health (Census 2021)":
        "139,078",
      "E10000012.ks301ew0008*dr.2021-01-01.Very good health (Census 2021)":
        "730,257",
      "E10000011.ks301ew0008*dr.2021-01-01.Very good health (Census 2021)":
        "244,464",
      "CSTM5160.ks301ew0009*dr.2021-01-01.Good health (Census 2021)": "91,878",
      "E10000012.ks301ew0009*dr.2021-01-01.Good health (Census 2021)":
        "515,958",
      "E10000011.ks301ew0009*dr.2021-01-01.Good health (Census 2021)":
        "191,683",
      "CSTM5160.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021)":
        "3,272",
      "E10000012.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021)":
        "15,834",
      "E10000011.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021)":
        "6,888",
      "CSTM5160.ks301ew0011*dr.2021-01-01.Bad health (Census 2021)": "10,757",
      "E10000012.ks301ew0011*dr.2021-01-01.Bad health (Census 2021)": "54,440",
      "E10000011.ks301ew0011*dr.2021-01-01.Bad health (Census 2021)": "24,261",
      "CSTM5160.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021)":
        "244,548",
      "E10000012.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021)":
        "1,295,938",
      "E10000011.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021)":
        "469,449",
      "CSTM5160.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021)":
        "5,430",
      "E10000012.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021)":
        "37,418",
      "E10000011.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021)":
        "15,637",
      "CSTM5160.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021)":
        "357",
      "E10000012.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021)":
        "2,028",
      "E10000011.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021)":
        "860",
      "CSTM5160.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP)":
        "15,894",
      "E10000012.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP)":
        "71,992",
      "E10000011.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP)":
        "29,950",
      "CSTM5160.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021)":
        "29,215",
      "E10000012.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021)":
        "230,974",
      "E10000011.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021)":
        "78,950",
      "CSTM5160.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021)":
        "8,052",
      "E10000012.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021)":
        "69,118",
      "E10000011.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021)":
        "25,590",
      "CSTM5160.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021)":
        "5,339",
      "E10000012.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021)":
        "34,111",
      "E10000011.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021)":
        "13,152",
      "CSTM5160.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021)":
        "16,545",
      "E10000012.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021)":
        "142,834",
      "E10000011.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021)":
        "49,001",
      "CSTM5160.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021)":
        "25,067",
      "E10000012.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021)":
        "193,117",
      "E10000011.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021)":
        "68,015",
      "CSTM5160.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021)":
        "46,359",
      "E10000012.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021)":
        "212,917",
      "E10000011.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021)":
        "78,092",
      "CSTM5160.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021)":
        "104,790",
      "E10000012.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021)":
        "341,104",
      "E10000011.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021)":
        "142,839",
      "CSTM5160.bres_employees_fulltime*sh.2022-01-01.Full-time employees":
        "93,000",
      "E10000012.bres_employees_fulltime*sh.2022-01-01.Full-time employees":
        "405,000",
      "E10000011.bres_employees_fulltime*sh.2022-01-01.Full-time employees":
        "116,000",
      "CSTM5160.bres_employees_parttime*sh.2022-01-01.Part-time employees":
        "54,000",
      "E10000012.bres_employees_parttime*sh.2022-01-01.Part-time employees":
        "200,000",
      "E10000011.bres_employees_parttime*sh.2022-01-01.Part-time employees":
        "65,000",
      "CSTM5160.bres_employment_pubsector*dr.2022-01-01.Public sector employees":
        "28,894",
      "E10000012.bres_employment_pubsector*dr.2022-01-01.Public sector employees":
        "87,936",
      "E10000011.bres_employment_pubsector*dr.2022-01-01.Public sector employees":
        "35,184",
      "CSTM5160.bres_employment_privsector*dr.2022-01-01.Private sector employees":
        "117,750",
      "E10000012.bres_employment_privsector*dr.2022-01-01.Private sector employees":
        "517,235",
      "E10000011.bres_employment_privsector*dr.2022-01-01.Private sector employees":
        "146,041",
      "CSTM5160.bres_employment*dr.2022-01-01.Jobs density (jobs as a percentage of the working age population)":
        "150,000",
      "E10000012.bres_employment*dr.2022-01-01.Jobs density (jobs as a percentage of the working age population)":
        "622,000",
      "E10000011.bres_employment*dr.2022-01-01.Jobs density (jobs as a percentage of the working age population)":
        "191,000",
      "CSTM5160.civic_assets_rank.2023-01-01.Community Needs Index 2023: Civic Assets rank":
        "25,698",
      "E10000012.civic_assets_rank.2023-01-01.Community Needs Index 2023: Civic Assets rank":
        "11,425",
      "E10000011.civic_assets_rank.2023-01-01.Community Needs Index 2023: Civic Assets rank":
        "13,557",
      "CSTM5160.active_community_rank.2023-01-01.Community Needs Index 2023: Active and Engaged Community rank":
        "22,366",
      "E10000012.active_community_rank.2023-01-01.Community Needs Index 2023: Active and Engaged Community rank":
        "16,783",
      "E10000011.active_community_rank.2023-01-01.Community Needs Index 2023: Active and Engaged Community rank":
        "21,438",
      "CSTM5160.connectedness_rank.2023-01-01.Community Needs Index 2023: Connectedness rank":
        "13,767",
      "E10000012.connectedness_rank.2023-01-01.Community Needs Index 2023: Connectedness rank":
        "13,034",
      "E10000011.connectedness_rank.2023-01-01.Community Needs Index 2023: Connectedness rank":
        "8,112",
      "CSTM5160.community_needs_rank.2023-01-01.Community Needs Index 2023: Community Needs rank":
        "22,368",
      "E10000012.community_needs_rank.2023-01-01.Community Needs Index 2023: Community Needs rank":
        "12,496",
      "E10000011.community_needs_rank.2023-01-01.Community Needs Index 2023: Community Needs rank":
        "12,687",
      "CSTM5160.id19_airquality.2016-01-01.IoD 2019 Air quality indicator":
        "0.994",
      "E10000012.id19_airquality.2016-01-01.IoD 2019 Air quality indicator":
        "0.854",
      "E10000011.id19_airquality.2016-01-01.IoD 2019 Air quality indicator":
        "0.737",
      "CSTM5160.id19_airquality_benzene.2016-01-01.IoD 2019 Benzene (component of air quality indicator)":
        "0.120",
      "E10000012.id19_airquality_benzene.2016-01-01.IoD 2019 Benzene (component of air quality indicator)":
        "0.075",
      "E10000011.id19_airquality_benzene.2016-01-01.IoD 2019 Benzene (component of air quality indicator)":
        "0.077",
      "CSTM5160.id19_airqualitynitrogennioxide.2016-01-01.IoD 2019 Nitrogen dioxide (component of air quality indicator)":
        "0.417",
      "E10000012.id19_airqualitynitrogennioxide.2016-01-01.IoD 2019 Nitrogen dioxide (component of air quality indicator)":
        "0.344",
      "E10000011.id19_airqualitynitrogennioxide.2016-01-01.IoD 2019 Nitrogen dioxide (component of air quality indicator)":
        "0.255",
      "CSTM5160.id19_airqualityparticulates.2016-01-01.IoD 2019 Particulates (component of air quality indicator)":
        "0.429",
      "E10000012.id19_airqualityparticulates.2016-01-01.IoD 2019 Particulates (component of air quality indicator)":
        "0.408",
      "E10000011.id19_airqualityparticulates.2016-01-01.IoD 2019 Particulates (component of air quality indicator)":
        "0.384",
      "CSTM5160.id19_airquality_sulphurdioxide.2016-01-01.IoD 2019 Sulphur dioxide (component of air quality indicator)":
        "0.028",
      "E10000012.id19_airquality_sulphurdioxide.2016-01-01.IoD 2019 Sulphur dioxide (component of air quality indicator)":
        "0.027",
      "E10000011.id19_airquality_sulphurdioxide.2016-01-01.IoD 2019 Sulphur dioxide (component of air quality indicator)":
        "0.021",
      "CSTM5160.id19_rta.2015-01-01.IoD 2019 Road traffic accidents indicator":
        "0.641",
      "E10000012.id19_rta.2015-01-01.IoD 2019 Road traffic accidents indicator":
        "0.615",
      "E10000011.id19_rta.2015-01-01.IoD 2019 Road traffic accidents indicator":
        "0.919",
      "CSTM5160.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021)":
        "45,460",
      "E10000012.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021)":
        "99,454",
      "E10000011.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021)":
        "46,070",
      "CSTM5160.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021)":
        "51,874",
      "E10000012.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021)":
        "255,505",
      "E10000011.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021)":
        "100,507",
      "CSTM5160.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021)":
        "19,070",
      "E10000012.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021)":
        "191,930",
      "E10000011.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021)":
        "66,460",
      "CSTM5160.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021)":
        "4,998",
      "E10000012.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021)":
        "79,584",
      "E10000011.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021)":
        "27,082",
      "CSTM5160.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011)":
        "46,415",
      "E10000012.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011)":
        "104,522",
      "E10000011.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011)":
        "50,674",
      "CSTM5160.census11_p06_all*dr.2021-01-01.All Females (Census 2021).rate":
        "51.08%",
      "E10000012.census11_p06_all*dr.2021-01-01.All Females (Census 2021).rate":
        "51.29%",
      "E10000011.census11_p06_all*dr.2021-01-01.All Females (Census 2021).rate":
        "51.97%",
      "CSTM5160.census11_p05_all*dr.2021-01-01.All Males (Census 2021).rate":
        "48.92%",
      "E10000012.census11_p05_all*dr.2021-01-01.All Males (Census 2021).rate":
        "48.71%",
      "E10000011.census11_p05_all*dr.2021-01-01.All Males (Census 2021).rate":
        "48.03%",
      "CSTM5160.census11_015*dr.2021-01-01.Population aged under 16 (Census 2021).rate":
        "15.06%",
      "E10000012.census11_015*dr.2021-01-01.Population aged under 16 (Census 2021).rate":
        "18.58%",
      "E10000011.census11_015*dr.2021-01-01.Population aged under 16 (Census 2021).rate":
        "16.53%",
      "CSTM5160.census11_1664*dr.2021-01-01.Population aged 16 to 64 (Census 2021).rate":
        "70.87%",
      "E10000012.census11_1664*dr.2021-01-01.Population aged 16 to 64 (Census 2021).rate":
        "60.80%",
      "E10000011.census11_1664*dr.2021-01-01.Population aged 16 to 64 (Census 2021).rate":
        "57.37%",
      "CSTM5160.census11_65pl*dr.2021-01-01.Population aged 65+ (Census 2021).rate":
        "14.07%",
      "E10000012.census11_65pl*dr.2021-01-01.Population aged 65+ (Census 2021).rate":
        "20.62%",
      "E10000011.census11_65pl*dr.2021-01-01.Population aged 65+ (Census 2021).rate":
        "26.10%",
      "CSTM5160.census11_p07_all*dr.2021-01-01.Population Density (Persons per sq km) (Census 2021).rate":
        "3,352.24",
      "E10000012.census11_p07_all*dr.2021-01-01.Population Density (Persons per sq km) (Census 2021).rate":
        "433.06",
      "E10000011.census11_p07_all*dr.2021-01-01.Population Density (Persons per sq km) (Census 2021).rate":
        "318.73",
      "CSTM5160.imd19pop_dec1*dr.2019-01-01.IMD 2019 Decile 1.rate": "8.24%",
      "E10000012.imd19pop_dec1*dr.2019-01-01.IMD 2019 Decile 1.rate": "3.49%",
      "E10000011.imd19pop_dec1*dr.2019-01-01.IMD 2019 Decile 1.rate": "6.87%",
      "CSTM5160.imd19pop_dec2*dr.2019-01-01.IMD 2019 Decile 2.rate": "8.98%",
      "E10000012.imd19pop_dec2*dr.2019-01-01.IMD 2019 Decile 2.rate": "5.07%",
      "E10000011.imd19pop_dec2*dr.2019-01-01.IMD 2019 Decile 2.rate": "7.49%",
      "CSTM5160.imd19pop_dec3*dr.2019-01-01.IMD 2019 Decile 3.rate": "9.80%",
      "E10000012.imd19pop_dec3*dr.2019-01-01.IMD 2019 Decile 3.rate": "8.33%",
      "E10000011.imd19pop_dec3*dr.2019-01-01.IMD 2019 Decile 3.rate": "8.52%",
      "CSTM5160.imd19pop_dec4*dr.2019-01-01.IMD 2019 Decile 4.rate": "7.45%",
      "E10000012.imd19pop_dec4*dr.2019-01-01.IMD 2019 Decile 4.rate": "9.96%",
      "E10000011.imd19pop_dec4*dr.2019-01-01.IMD 2019 Decile 4.rate": "10.07%",
      "CSTM5160.imd19pop_dec5*dr.2019-01-01.IMD 2019 Decile 5.rate": "8.91%",
      "E10000012.imd19pop_dec5*dr.2019-01-01.IMD 2019 Decile 5.rate": "11.87%",
      "E10000011.imd19pop_dec5*dr.2019-01-01.IMD 2019 Decile 5.rate": "11.82%",
      "CSTM5160.imd19pop_dec6*dr.2019-01-01.IMD 2019 Decile 6.rate": "15.55%",
      "E10000012.imd19pop_dec6*dr.2019-01-01.IMD 2019 Decile 6.rate": "11.17%",
      "E10000011.imd19pop_dec6*dr.2019-01-01.IMD 2019 Decile 6.rate": "13.58%",
      "CSTM5160.imd19pop_dec7*dr.2019-01-01.IMD 2019 Decile 7.rate": "13.91%",
      "E10000012.imd19pop_dec7*dr.2019-01-01.IMD 2019 Decile 7.rate": "11.22%",
      "E10000011.imd19pop_dec7*dr.2019-01-01.IMD 2019 Decile 7.rate": "11.47%",
      "CSTM5160.imd19pop_dec8*dr.2019-01-01.IMD 2019 Decile 8.rate": "13.55%",
      "E10000012.imd19pop_dec8*dr.2019-01-01.IMD 2019 Decile 8.rate": "13.27%",
      "E10000011.imd19pop_dec8*dr.2019-01-01.IMD 2019 Decile 8.rate": "14.51%",
      "CSTM5160.imd19pop_dec9*dr.2019-01-01.IMD 2019 Decile 9.rate": "8.11%",
      "E10000012.imd19pop_dec9*dr.2019-01-01.IMD 2019 Decile 9.rate": "12.93%",
      "E10000011.imd19pop_dec9*dr.2019-01-01.IMD 2019 Decile 9.rate": "9.33%",
      "CSTM5160.imd19pop_dec10*dr.2019-01-01.IMD 2019 Decile 10.rate": "5.51%",
      "E10000012.imd19pop_dec10*dr.2019-01-01.IMD 2019 Decile 10.rate":
        "12.70%",
      "E10000011.imd19pop_dec10*dr.2019-01-01.IMD 2019 Decile 10.rate": "6.34%",
      "CSTM5160.claimant_count*dr.2024-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.33%",
      "E10000012.claimant_count*dr.2024-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.30%",
      "E10000011.claimant_count*dr.2024-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.74%",
      "CSTM5160.claimant_count_1824*dr.2024-08-01.Youth unemployment (18-24 receiving JSA or Universal Credit).rate":
        "3.64%",
      "E10000012.claimant_count_1824*dr.2024-08-01.Youth unemployment (18-24 receiving JSA or Universal Credit).rate":
        "4.74%",
      "E10000011.claimant_count_1824*dr.2024-08-01.Youth unemployment (18-24 receiving JSA or Universal Credit).rate":
        "5.83%",
      "CSTM5160.claimant_count_50pl*dr.2024-08-01.Older person unemployment (50+ receiving JSA or Universal Credit).rate":
        "2.27%",
      "E10000012.claimant_count_50pl*dr.2024-08-01.Older person unemployment (50+ receiving JSA or Universal Credit).rate":
        "1.15%",
      "E10000011.claimant_count_50pl*dr.2024-08-01.Older person unemployment (50+ receiving JSA or Universal Credit).rate":
        "1.17%",
      "CSTM5160.claimant_count_female*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), female.rate":
        "3.63%",
      "E10000012.claimant_count_female*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), female.rate":
        "3.15%",
      "E10000011.claimant_count_female*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), female.rate":
        "3.37%",
      "CSTM5160.claimant_count_male*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), male.rate":
        "5.05%",
      "E10000012.claimant_count_male*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), male.rate":
        "3.45%",
      "E10000011.claimant_count_male*dr.2024-08-01.Unemployment benefit (JSA and Universal Credit), male.rate":
        "4.14%",
      "CSTM5160.claimant_count*dr.2020-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.70%",
      "E10000012.claimant_count*dr.2020-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.27%",
      "E10000011.claimant_count*dr.2020-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.80%",
      "CSTM5160.claimant_count*dr.2020-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.83%",
      "E10000012.claimant_count*dr.2020-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.38%",
      "E10000011.claimant_count*dr.2020-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.88%",
      "CSTM5160.claimant_count*dr.2020-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.83%",
      "E10000012.claimant_count*dr.2020-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.41%",
      "E10000011.claimant_count*dr.2020-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.89%",
      "CSTM5160.claimant_count*dr.2020-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.98%",
      "E10000012.claimant_count*dr.2020-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.35%",
      "E10000011.claimant_count*dr.2020-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.24%",
      "CSTM5160.claimant_count*dr.2020-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.82%",
      "E10000012.claimant_count*dr.2020-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.04%",
      "E10000011.claimant_count*dr.2020-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.53%",
      "CSTM5160.claimant_count*dr.2020-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.47%",
      "E10000012.claimant_count*dr.2020-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.67%",
      "E10000011.claimant_count*dr.2020-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.12%",
      "CSTM5160.claimant_count*dr.2020-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.75%",
      "E10000012.claimant_count*dr.2020-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.80%",
      "E10000011.claimant_count*dr.2020-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.29%",
      "CSTM5160.claimant_count*dr.2020-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.93%",
      "E10000012.claimant_count*dr.2020-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.99%",
      "E10000011.claimant_count*dr.2020-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.47%",
      "CSTM5160.claimant_count*dr.2020-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.98%",
      "E10000012.claimant_count*dr.2020-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.92%",
      "E10000011.claimant_count*dr.2020-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.40%",
      "CSTM5160.claimant_count*dr.2020-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.66%",
      "E10000012.claimant_count*dr.2020-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.60%",
      "E10000011.claimant_count*dr.2020-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.04%",
      "CSTM5160.claimant_count*dr.2020-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.70%",
      "E10000012.claimant_count*dr.2020-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.63%",
      "E10000011.claimant_count*dr.2020-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.19%",
      "CSTM5160.claimant_count*dr.2020-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.73%",
      "E10000012.claimant_count*dr.2020-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.62%",
      "E10000011.claimant_count*dr.2020-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.15%",
      "CSTM5160.claimant_count*dr.2021-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.98%",
      "E10000012.claimant_count*dr.2021-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.39%",
      "E10000011.claimant_count*dr.2021-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.14%",
      "CSTM5160.claimant_count*dr.2021-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "7.33%",
      "E10000012.claimant_count*dr.2021-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.69%",
      "E10000011.claimant_count*dr.2021-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.46%",
      "CSTM5160.claimant_count*dr.2021-02-02.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "7.29%",
      "E10000012.claimant_count*dr.2021-02-02.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.65%",
      "E10000011.claimant_count*dr.2021-02-02.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.43%",
      "CSTM5160.claimant_count*dr.2021-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "7.35%",
      "E10000012.claimant_count*dr.2021-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.63%",
      "E10000011.claimant_count*dr.2021-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.44%",
      "CSTM5160.claimant_count*dr.2021-03-03.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "7.28%",
      "E10000012.claimant_count*dr.2021-03-03.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.60%",
      "E10000011.claimant_count*dr.2021-03-03.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.39%",
      "CSTM5160.claimant_count*dr.2021-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "7.16%",
      "E10000012.claimant_count*dr.2021-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.55%",
      "E10000011.claimant_count*dr.2021-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.31%",
      "CSTM5160.claimant_count*dr.2021-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.69%",
      "E10000012.claimant_count*dr.2021-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.12%",
      "E10000011.claimant_count*dr.2021-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.89%",
      "CSTM5160.claimant_count*dr.2021-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "6.10%",
      "E10000012.claimant_count*dr.2021-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.64%",
      "E10000011.claimant_count*dr.2021-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.38%",
      "CSTM5160.claimant_count*dr.2021-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.99%",
      "E10000012.claimant_count*dr.2021-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.49%",
      "E10000011.claimant_count*dr.2021-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.23%",
      "CSTM5160.claimant_count*dr.2021-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.71%",
      "E10000012.claimant_count*dr.2021-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.31%",
      "E10000011.claimant_count*dr.2021-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.01%",
      "CSTM5160.claimant_count*dr.2021-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.45%",
      "E10000012.claimant_count*dr.2021-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.03%",
      "E10000011.claimant_count*dr.2021-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.74%",
      "CSTM5160.claimant_count*dr.2021-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.19%",
      "E10000012.claimant_count*dr.2021-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.83%",
      "E10000011.claimant_count*dr.2021-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.65%",
      "CSTM5160.claimant_count*dr.2021-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "5.08%",
      "E10000012.claimant_count*dr.2021-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.68%",
      "E10000011.claimant_count*dr.2021-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.58%",
      "CSTM5160.claimant_count*dr.2021-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.90%",
      "E10000012.claimant_count*dr.2021-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.58%",
      "E10000011.claimant_count*dr.2021-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.43%",
      "CSTM5160.claimant_count*dr.2022-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.74%",
      "E10000012.claimant_count*dr.2022-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.48%",
      "E10000011.claimant_count*dr.2022-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.40%",
      "CSTM5160.claimant_count*dr.2022-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.75%",
      "E10000012.claimant_count*dr.2022-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.47%",
      "E10000011.claimant_count*dr.2022-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.41%",
      "CSTM5160.claimant_count*dr.2022-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.60%",
      "E10000012.claimant_count*dr.2022-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.27%",
      "E10000011.claimant_count*dr.2022-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.26%",
      "CSTM5160.claimant_count*dr.2022-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.30%",
      "E10000012.claimant_count*dr.2022-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.02%",
      "E10000011.claimant_count*dr.2022-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.90%",
      "CSTM5160.claimant_count*dr.2022-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.11%",
      "E10000012.claimant_count*dr.2022-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.92%",
      "E10000011.claimant_count*dr.2022-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.84%",
      "CSTM5160.claimant_count*dr.2022-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.01%",
      "E10000012.claimant_count*dr.2022-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.82%",
      "E10000011.claimant_count*dr.2022-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.67%",
      "CSTM5160.claimant_count*dr.2022-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.97%",
      "E10000012.claimant_count*dr.2022-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.79%",
      "E10000011.claimant_count*dr.2022-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.63%",
      "CSTM5160.claimant_count*dr.2022-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.95%",
      "E10000012.claimant_count*dr.2022-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.79%",
      "E10000011.claimant_count*dr.2022-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.62%",
      "CSTM5160.claimant_count*dr.2022-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.96%",
      "E10000012.claimant_count*dr.2022-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.78%",
      "E10000011.claimant_count*dr.2022-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.62%",
      "CSTM5160.claimant_count*dr.2022-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.94%",
      "E10000012.claimant_count*dr.2022-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.73%",
      "E10000011.claimant_count*dr.2022-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.59%",
      "CSTM5160.claimant_count*dr.2022-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.99%",
      "E10000012.claimant_count*dr.2022-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.77%",
      "E10000011.claimant_count*dr.2022-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.62%",
      "CSTM5160.claimant_count*dr.2022-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.02%",
      "E10000012.claimant_count*dr.2022-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.73%",
      "E10000011.claimant_count*dr.2022-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.56%",
      "CSTM5160.claimant_count*dr.2023-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.92%",
      "E10000012.claimant_count*dr.2023-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.71%",
      "E10000011.claimant_count*dr.2023-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.57%",
      "CSTM5160.claimant_count*dr.2023-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.94%",
      "E10000012.claimant_count*dr.2023-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.75%",
      "E10000011.claimant_count*dr.2023-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.61%",
      "CSTM5160.claimant_count*dr.2023-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.91%",
      "E10000012.claimant_count*dr.2023-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.78%",
      "E10000011.claimant_count*dr.2023-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.57%",
      "CSTM5160.claimant_count*dr.2023-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.93%",
      "E10000012.claimant_count*dr.2023-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.84%",
      "E10000011.claimant_count*dr.2023-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.56%",
      "CSTM5160.claimant_count*dr.2023-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.78%",
      "E10000012.claimant_count*dr.2023-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.76%",
      "E10000011.claimant_count*dr.2023-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.41%",
      "CSTM5160.claimant_count*dr.2023-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.91%",
      "E10000012.claimant_count*dr.2023-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.81%",
      "E10000011.claimant_count*dr.2023-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.44%",
      "CSTM5160.claimant_count*dr.2023-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.96%",
      "E10000012.claimant_count*dr.2023-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.82%",
      "E10000011.claimant_count*dr.2023-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.40%",
      "CSTM5160.claimant_count*dr.2023-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.96%",
      "E10000012.claimant_count*dr.2023-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.79%",
      "E10000011.claimant_count*dr.2023-08-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.35%",
      "CSTM5160.claimant_count*dr.2023-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.96%",
      "E10000012.claimant_count*dr.2023-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.82%",
      "E10000011.claimant_count*dr.2023-09-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.34%",
      "CSTM5160.claimant_count*dr.2023-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.96%",
      "E10000012.claimant_count*dr.2023-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.83%",
      "E10000011.claimant_count*dr.2023-10-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.33%",
      "CSTM5160.claimant_count*dr.2023-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.98%",
      "E10000012.claimant_count*dr.2023-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.90%",
      "E10000011.claimant_count*dr.2023-11-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.39%",
      "CSTM5160.claimant_count*dr.2023-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.03%",
      "E10000012.claimant_count*dr.2023-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.90%",
      "E10000011.claimant_count*dr.2023-12-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.44%",
      "CSTM5160.claimant_count*dr.2024-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.12%",
      "E10000012.claimant_count*dr.2024-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "2.94%",
      "E10000011.claimant_count*dr.2024-01-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.48%",
      "CSTM5160.claimant_count*dr.2024-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.20%",
      "E10000012.claimant_count*dr.2024-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.03%",
      "E10000011.claimant_count*dr.2024-02-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.56%",
      "CSTM5160.claimant_count*dr.2024-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.09%",
      "E10000012.claimant_count*dr.2024-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.04%",
      "E10000011.claimant_count*dr.2024-03-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.50%",
      "CSTM5160.claimant_count*dr.2024-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.02%",
      "E10000012.claimant_count*dr.2024-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.00%",
      "E10000011.claimant_count*dr.2024-04-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.42%",
      "CSTM5160.claimant_count*dr.2024-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.95%",
      "E10000012.claimant_count*dr.2024-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.01%",
      "E10000011.claimant_count*dr.2024-05-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.44%",
      "CSTM5160.claimant_count*dr.2024-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.02%",
      "E10000012.claimant_count*dr.2024-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.07%",
      "E10000011.claimant_count*dr.2024-06-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.51%",
      "CSTM5160.claimant_count*dr.2024-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "4.27%",
      "E10000012.claimant_count*dr.2024-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.31%",
      "E10000011.claimant_count*dr.2024-07-01.Unemployment benefit claimants (Jobseekers Allowance and out of work Universal Credit claimants).rate":
        "3.73%",
      "CSTM5160.landregistry_a_sumpricepaid_12_month*dr.2023-06-01.Average house price (12 months).rate":
        "£490,642",
      "E10000012.landregistry_a_sumpricepaid_12_month*dr.2023-06-01.Average house price (12 months).rate":
        "£398,989",
      "E10000011.landregistry_a_sumpricepaid_12_month*dr.2023-06-01.Average house price (12 months).rate":
        "£389,784",
      "CSTM5160.landregistry_d_sumpricepaid_12_month*dr.2023-06-01.Average house price for detached houses (12 months).rate":
        "£838,524",
      "E10000012.landregistry_d_sumpricepaid_12_month*dr.2023-06-01.Average house price for detached houses (12 months).rate":
        "£581,785",
      "E10000011.landregistry_d_sumpricepaid_12_month*dr.2023-06-01.Average house price for detached houses (12 months).rate":
        "£601,396",
      "CSTM5160.landregistry_f_sumpricepaid_12_month*dr.2023-06-01.Average house price for flats (12 months).rate":
        "£337,876",
      "E10000012.landregistry_f_sumpricepaid_12_month*dr.2023-06-01.Average house price for flats (12 months).rate":
        "£211,150",
      "E10000011.landregistry_f_sumpricepaid_12_month*dr.2023-06-01.Average house price for flats (12 months).rate":
        "£209,652",
      "CSTM5160.landregistry_s_sumpricepaid_12_month*dr.2023-06-01.Average house price for semi-detached houses (12 months).rate":
        "£577,331",
      "E10000012.landregistry_s_sumpricepaid_12_month*dr.2023-06-01.Average house price for semi-detached houses (12 months).rate":
        "£393,819",
      "E10000011.landregistry_s_sumpricepaid_12_month*dr.2023-06-01.Average house price for semi-detached houses (12 months).rate":
        "£381,677",
      "CSTM5160.landregistry_t_sumpricepaid_12_month*dr.2023-06-01.Average house price for terraced houses (12 months).rate":
        "£566,671",
      "E10000012.landregistry_t_sumpricepaid_12_month*dr.2023-06-01.Average house price for terraced houses (12 months).rate":
        "£328,456",
      "E10000011.landregistry_t_sumpricepaid_12_month*dr.2023-06-01.Average house price for terraced houses (12 months).rate":
        "£324,404",
      "CSTM5160.owneroccupied_2001*dr.2021-01-01.Owner occupied housing (Census 2021).rate":
        "52.24%",
      "E10000012.owneroccupied_2001*dr.2021-01-01.Owner occupied housing (Census 2021).rate":
        "69.86%",
      "E10000011.owneroccupied_2001*dr.2021-01-01.Owner occupied housing (Census 2021).rate":
        "69.29%",
      "CSTM5160.ks402ew0005*dr.2021-01-01.Housing rented from council (Census 2021).rate":
        "9.54%",
      "E10000012.ks402ew0005*dr.2021-01-01.Housing rented from council (Census 2021).rate":
        "7.24%",
      "E10000011.ks402ew0005*dr.2021-01-01.Housing rented from council (Census 2021).rate":
        "4.51%",
      "CSTM5160.ks402ew0006*dr.2021-01-01.Housing rented from a Housing Association (Census 2021).rate":
        "5.32%",
      "E10000012.ks402ew0006*dr.2021-01-01.Housing rented from a Housing Association (Census 2021).rate":
        "6.89%",
      "E10000011.ks402ew0006*dr.2021-01-01.Housing rented from a Housing Association (Census 2021).rate":
        "6.52%",
      "CSTM5160.ks402ew0007*dr.2021-01-01.Private rented housing: Private landlord or letting agency (Census 2021).rate":
        "30.62%",
      "E10000012.ks402ew0007*dr.2021-01-01.Private rented housing: Private landlord or letting agency (Census 2021).rate":
        "13.98%",
      "E10000011.ks402ew0007*dr.2021-01-01.Private rented housing: Private landlord or letting agency (Census 2021).rate":
        "17.41%",
      "CSTM5160.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total).rate":
        "160.8",
      "E10000012.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total).rate":
        "107.9",
      "E10000011.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total).rate":
        "110.9",
      "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total).rate":
        "22.7",
      "E10000012.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total).rate":
        "9.6",
      "E10000011.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total).rate":
        "16.5",
      "CSTM5160.bicycle_theft_police_uk_12_month*dr.2023-06-01.Bicycle theft offences (12 month total).rate":
        "3.5",
      "E10000012.bicycle_theft_police_uk_12_month*dr.2023-06-01.Bicycle theft offences (12 month total).rate":
        "1.0",
      "E10000011.bicycle_theft_police_uk_12_month*dr.2023-06-01.Bicycle theft offences (12 month total).rate":
        "0.6",
      "CSTM5160.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total).rate":
        "11.3",
      "E10000012.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total).rate":
        "11.7",
      "E10000011.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total).rate":
        "8.6",
      "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total).rate":
        "11.5",
      "E10000012.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total).rate":
        "8.5",
      "E10000011.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total).rate":
        "11.3",
      "CSTM5160.drugs_police_uk_12_month*dr.2023-06-01.Drug crime offences (12 month total).rate":
        "5.6",
      "E10000012.drugs_police_uk_12_month*dr.2023-06-01.Drug crime offences (12 month total).rate":
        "3.4",
      "E10000011.drugs_police_uk_12_month*dr.2023-06-01.Drug crime offences (12 month total).rate":
        "2.4",
      "CSTM5160.other_crime_police_uk_12_month*dr.2023-06-01.Other crime offences (12 month total).rate":
        "3.0",
      "E10000012.other_crime_police_uk_12_month*dr.2023-06-01.Other crime offences (12 month total).rate":
        "2.3",
      "E10000011.other_crime_police_uk_12_month*dr.2023-06-01.Other crime offences (12 month total).rate":
        "2.2",
      "CSTM5160.other_theft_police_uk_12_month*dr.2023-06-01.Other theft offences (12 month total).rate":
        "13.1",
      "E10000012.other_theft_police_uk_12_month*dr.2023-06-01.Other theft offences (12 month total).rate":
        "8.3",
      "E10000011.other_theft_police_uk_12_month*dr.2023-06-01.Other theft offences (12 month total).rate":
        "7.3",
      "CSTM5160.shoplifting_police_uk_12_month*dr.2023-06-01.Shoplifting offences (12 month total).rate":
        "18.5",
      "E10000012.shoplifting_police_uk_12_month*dr.2023-06-01.Shoplifting offences (12 month total).rate":
        "7.0",
      "E10000011.shoplifting_police_uk_12_month*dr.2023-06-01.Shoplifting offences (12 month total).rate":
        "9.9",
      "CSTM5160.possession_of_weapons_police_uk_12_month*dr.2023-06-01.Possession of weapons offences (12 month total).rate":
        "1.9",
      "E10000012.possession_of_weapons_police_uk_12_month*dr.2023-06-01.Possession of weapons offences (12 month total).rate":
        "1.1",
      "E10000011.possession_of_weapons_police_uk_12_month*dr.2023-06-01.Possession of weapons offences (12 month total).rate":
        "1.2",
      "CSTM5160.public_order_police_uk_12_month*dr.2023-06-01.Public order offences (12 month total).rate":
        "15.3",
      "E10000012.public_order_police_uk_12_month*dr.2023-06-01.Public order offences (12 month total).rate":
        "8.3",
      "E10000011.public_order_police_uk_12_month*dr.2023-06-01.Public order offences (12 month total).rate":
        "9.4",
      "CSTM5160.robbery_police_uk_12_month*dr.2023-06-01.Robbery recorded offences (12 month total).rate":
        "1.6",
      "E10000012.robbery_police_uk_12_month*dr.2023-06-01.Robbery recorded offences (12 month total).rate":
        "0.9",
      "E10000011.robbery_police_uk_12_month*dr.2023-06-01.Robbery recorded offences (12 month total).rate":
        "0.7",
      "CSTM5160.theft_from_the_person_police_uk_12_month*dr.2023-06-01.Theft from the person offences (12 month total).rate":
        "2.5",
      "E10000012.theft_from_the_person_police_uk_12_month*dr.2023-06-01.Theft from the person offences (12 month total).rate":
        "0.7",
      "E10000011.theft_from_the_person_police_uk_12_month*dr.2023-06-01.Theft from the person offences (12 month total).rate":
        "0.6",
      "CSTM5160.vehicle_crime_police_uk_12_month*dr.2023-06-01.Vehicle crime (12 month total).rate":
        "6.9",
      "E10000012.vehicle_crime_police_uk_12_month*dr.2023-06-01.Vehicle crime (12 month total).rate":
        "9.0",
      "E10000011.vehicle_crime_police_uk_12_month*dr.2023-06-01.Vehicle crime (12 month total).rate":
        "4.9",
      "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total).rate":
        "49.9",
      "E10000012.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total).rate":
        "43.0",
      "E10000011.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total).rate":
        "40.1",
      "CSTM5160.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total).rate":
        "121.5",
      "E10000012.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total).rate":
        "108.8",
      "E10000011.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total).rate":
        "84.5",
      "CSTM5160.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total).rate":
        "124.7",
      "E10000012.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total).rate":
        "109.0",
      "E10000011.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total).rate":
        "86.1",
      "CSTM5160.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total).rate":
        "127.2",
      "E10000012.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total).rate":
        "108.5",
      "E10000011.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total).rate":
        "88.4",
      "CSTM5160.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total).rate":
        "104.9",
      "E10000012.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total).rate":
        "97.1",
      "E10000011.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total).rate":
        "74.1",
      "CSTM5160.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total).rate":
        "129.9",
      "E10000012.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total).rate":
        "107.5",
      "E10000011.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total).rate":
        "89.3",
      "CSTM5160.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total).rate":
        "128.5",
      "E10000012.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total).rate":
        "108.0",
      "E10000011.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total).rate":
        "88.5",
      "CSTM5160.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total).rate":
        "123.3",
      "E10000012.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total).rate":
        "106.5",
      "E10000011.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total).rate":
        "86.9",
      "CSTM5160.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total).rate":
        "123.5",
      "E10000012.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total).rate":
        "108.5",
      "E10000011.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total).rate":
        "87.5",
      "CSTM5160.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total).rate":
        "119.8",
      "E10000012.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total).rate":
        "106.5",
      "E10000011.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total).rate":
        "86.1",
      "CSTM5160.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total).rate":
        "120.6",
      "E10000012.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total).rate":
        "104.5",
      "E10000011.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total).rate":
        "85.9",
      "CSTM5160.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total).rate":
        "128.7",
      "E10000012.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total).rate":
        "102.3",
      "E10000011.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total).rate":
        "85.8",
      "CSTM5160.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total).rate":
        "126.3",
      "E10000012.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total).rate":
        "100.4",
      "E10000011.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total).rate":
        "82.5",
      "CSTM5160.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total).rate":
        "122.8",
      "E10000012.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total).rate":
        "99.5",
      "E10000011.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total).rate":
        "81.3",
      "CSTM5160.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total).rate":
        "118.9",
      "E10000012.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total).rate":
        "98.0",
      "E10000011.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total).rate":
        "80.1",
      "CSTM5160.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total).rate":
        "117.2",
      "E10000012.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total).rate":
        "95.2",
      "E10000011.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total).rate":
        "79.9",
      "CSTM5160.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total).rate":
        "141.8",
      "E10000012.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total).rate":
        "113.0",
      "E10000011.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total).rate":
        "98.2",
      "CSTM5160.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total).rate":
        "169.2",
      "E10000012.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total).rate":
        "117.2",
      "E10000011.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total).rate":
        "116.7",
      "CSTM5160.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total).rate":
        "171.7",
      "E10000012.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total).rate":
        "122.8",
      "E10000011.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total).rate":
        "119.0",
      "CSTM5160.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total).rate":
        "173.1",
      "E10000012.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total).rate":
        "120.2",
      "E10000011.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total).rate":
        "119.1",
      "CSTM5160.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021).rate":
        "7.34%",
      "E10000012.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021).rate":
        "6.79%",
      "E10000011.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021).rate":
        "8.28%",
      "CSTM5160.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021).rate":
        "11.35%",
      "E10000012.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021).rate":
        "9.87%",
      "E10000011.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021).rate":
        "11.97%",
      "CSTM5160.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021).rate":
        "7.51%",
      "E10000012.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021).rate":
        "7.13%",
      "E10000011.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021).rate":
        "7.97%",
      "CSTM5160.disability_5a_4*dr.2021-01-01.Not disabled under the Equality Act: No long term physical or mental health conditions (Census 2021).rate":
        "73.80%",
      "E10000012.disability_5a_4*dr.2021-01-01.Not disabled under the Equality Act: No long term physical or mental health conditions (Census 2021).rate":
        "76.21%",
      "E10000011.disability_5a_4*dr.2021-01-01.Not disabled under the Equality Act: No long term physical or mental health conditions (Census 2021).rate":
        "71.78%",
      "CSTM5160.ks301ew0008*dr.2021-01-01.Very good health (Census 2021).rate":
        "50.19%",
      "E10000012.ks301ew0008*dr.2021-01-01.Very good health (Census 2021).rate":
        "48.57%",
      "E10000011.ks301ew0008*dr.2021-01-01.Very good health (Census 2021).rate":
        "44.79%",
      "CSTM5160.ks301ew0009*dr.2021-01-01.Good health (Census 2021).rate":
        "33.16%",
      "E10000012.ks301ew0009*dr.2021-01-01.Good health (Census 2021).rate":
        "34.32%",
      "E10000011.ks301ew0009*dr.2021-01-01.Good health (Census 2021).rate":
        "35.12%",
      "CSTM5160.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021).rate":
        "1.18%",
      "E10000012.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021).rate":
        "1.05%",
      "E10000011.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021).rate":
        "1.26%",
      "CSTM5160.ks301ew0011*dr.2021-01-01.Bad health (Census 2021).rate":
        "3.88%",
      "E10000012.ks301ew0011*dr.2021-01-01.Bad health (Census 2021).rate":
        "3.62%",
      "E10000011.ks301ew0011*dr.2021-01-01.Bad health (Census 2021).rate":
        "4.44%",
      "CSTM5160.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021).rate":
        "92.16%",
      "E10000012.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021).rate":
        "91.24%",
      "E10000011.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021).rate":
        "90.13%",
      "CSTM5160.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021).rate":
        "2.05%",
      "E10000012.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021).rate":
        "2.63%",
      "E10000011.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021).rate":
        "3.00%",
      "CSTM5160.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021).rate":
        "0.86%",
      "E10000012.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021).rate":
        "0.73%",
      "E10000011.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021).rate":
        "0.95%",
      "CSTM5160.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP).rate":
        "8.06%",
      "E10000012.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP).rate":
        "7.83%",
      "E10000011.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP).rate":
        "9.52%",
      "CSTM5160.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021).rate":
        "12.41%",
      "E10000012.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021).rate":
        "18.87%",
      "E10000011.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021).rate":
        "17.33%",
      "CSTM5160.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021).rate":
        "3.42%",
      "E10000012.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021).rate":
        "5.65%",
      "E10000011.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021).rate":
        "5.62%",
      "CSTM5160.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021).rate":
        "2.27%",
      "E10000012.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021).rate":
        "2.79%",
      "E10000011.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021).rate":
        "2.89%",
      "CSTM5160.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021).rate":
        "7.03%",
      "E10000012.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021).rate":
        "11.67%",
      "E10000011.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021).rate":
        "10.75%",
      "CSTM5160.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021).rate":
        "10.65%",
      "E10000012.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021).rate":
        "15.78%",
      "E10000011.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021).rate":
        "14.93%",
      "CSTM5160.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021).rate":
        "19.70%",
      "E10000012.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021).rate":
        "17.39%",
      "E10000011.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021).rate":
        "17.14%",
      "CSTM5160.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021).rate":
        "44.52%",
      "E10000012.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021).rate":
        "27.86%",
      "E10000011.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021).rate":
        "31.35%",
      "CSTM5160.bres_employees_fulltime*sh.2022-01-01.Full-time employees.rate":
        "63.27%",
      "E10000012.bres_employees_fulltime*sh.2022-01-01.Full-time employees.rate":
        "66.94%",
      "E10000011.bres_employees_fulltime*sh.2022-01-01.Full-time employees.rate":
        "64.09%",
      "CSTM5160.bres_employees_parttime*sh.2022-01-01.Part-time employees.rate":
        "36.73%",
      "E10000012.bres_employees_parttime*sh.2022-01-01.Part-time employees.rate":
        "33.06%",
      "E10000011.bres_employees_parttime*sh.2022-01-01.Part-time employees.rate":
        "35.91%",
      "CSTM5160.bres_employment_pubsector*dr.2022-01-01.Public sector employees.rate":
        "19.70%",
      "E10000012.bres_employment_pubsector*dr.2022-01-01.Public sector employees.rate":
        "14.53%",
      "E10000011.bres_employment_pubsector*dr.2022-01-01.Public sector employees.rate":
        "19.41%",
      "CSTM5160.bres_employment_privsector*dr.2022-01-01.Private sector employees.rate":
        "80.30%",
      "E10000012.bres_employment_privsector*dr.2022-01-01.Private sector employees.rate":
        "85.47%",
      "E10000011.bres_employment_privsector*dr.2022-01-01.Private sector employees.rate":
        "80.59%",
      "CSTM5160.bres_employment*dr.2022-01-01.Jobs density (jobs as a percentage of the working age population).rate":
        "76.10%",
      "E10000012.bres_employment*dr.2022-01-01.Jobs density (jobs as a percentage of the working age population).rate":
        "67.66%",
      "E10000011.bres_employment*dr.2022-01-01.Jobs density (jobs as a percentage of the working age population).rate":
        "60.74%",
      "CSTM5160.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021).rate":
        "37.45%",
      "E10000012.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021).rate":
        "15.88%",
      "E10000011.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021).rate":
        "19.19%",
      "CSTM5160.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021).rate":
        "42.73%",
      "E10000012.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021).rate":
        "40.78%",
      "E10000011.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021).rate":
        "41.86%",
      "CSTM5160.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021).rate":
        "15.71%",
      "E10000012.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021).rate":
        "30.64%",
      "E10000011.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021).rate":
        "27.68%",
      "CSTM5160.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021).rate":
        "4.12%",
      "E10000012.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021).rate":
        "12.70%",
      "E10000011.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021).rate":
        "11.28%",
      "CSTM5160.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011).rate":
        "38.19%",
      "E10000012.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011).rate":
        "17.97%",
      "E10000011.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011).rate":
        "21.85%",
      "CSTM5160.drugs_police_uk_12_month.2020-12-01.Drug crime offences (rollling 12 month total)":
        "6035",
      "E10000012.drugs_police_uk_12_month.2020-12-01.Drug crime offences (rollling 12 month total)":
        "150,968",
      "E10000011.drugs_police_uk_12_month.2020-12-01.Drug crime offences (rollling 12 month total)":
        "28000",
      "CSTM5160.drugs_police_uk_12_month.2021-03-01.Drug crime offences (rollling 12 month total)":
        "12306",
      "E10000012.drugs_police_uk_12_month.2021-03-01.Drug crime offences (rollling 12 month total)":
        "143,818",
      "E10000011.drugs_police_uk_12_month.2021-03-01.Drug crime offences (rollling 12 month total)":
        "20,443",
      "CSTM5160.drugs_police_uk_12_month.2021-06-01.Drug crime offences (rollling 12 month total)":
        "30984",
      "E10000012.drugs_police_uk_12_month.2021-06-01.Drug crime offences (rollling 12 month total)":
        "108,975",
      "E10000011.drugs_police_uk_12_month.2021-06-01.Drug crime offences (rollling 12 month total)":
        "18,919",
      "CSTM5160.drugs_police_uk_12_month.2021-09-01.Drug crime offences (rollling 12 month total)":
        "984",
      "E10000012.drugs_police_uk_12_month.2021-09-01.Drug crime offences (rollling 12 month total)":
        "141,598",
      "E10000011.drugs_police_uk_12_month.2021-09-01.Drug crime offences (rollling 12 month total)":
        "915",
      "CSTM5160.drugs_police_uk_12_month.2021-12-01.Drug crime offences (rollling 12 month total)":
        "915",
      "E10000012.drugs_police_uk_12_month.2021-12-01.Drug crime offences (rollling 12 month total)":
        "143,496",
      "E10000011.drugs_police_uk_12_month.2021-12-01.Drug crime offences (rollling 12 month total)":
        "18,957",
      "CSTM5160.drugs_police_uk_12_month.2022-03-01.Drug crime offences (rollling 12 month total)":
        "883",
      "E10000012.drugs_police_uk_12_month.2022-03-01.Drug crime offences (rollling 12 month total)":
        "143,749",
      "E10000011.drugs_police_uk_12_month.2022-03-01.Drug crime offences (rollling 12 month total)":
        "19,227",
      "CSTM5160.drugs_police_uk_12_month.2022-06-01.Drug crime offences (rollling 12 month total)":
        "1,133",
      "E10000012.drugs_police_uk_12_month.2022-06-01.Drug crime offences (rollling 12 month total)":
        "180,175",
      "E10000011.drugs_police_uk_12_month.2022-06-01.Drug crime offences (rollling 12 month total)":
        "25,186",
      "CSTM5160.drugs_police_uk_12_month.2022-09-01.Drug crime offences (rollling 12 month total)":
        "1,417",
      "E10000012.drugs_police_uk_12_month.2022-09-01.Drug crime offences (rollling 12 month total)":
        "211,119",
      "E10000011.drugs_police_uk_12_month.2022-09-01.Drug crime offences (rollling 12 month total)":
        "30,880",
      "CSTM5160.drugs_police_uk_12_month.2022-12-01.Drug crime offences (rollling 12 month total)":
        "1,460",
      "E10000012.drugs_police_uk_12_month.2022-12-01.Drug crime offences (rollling 12 month total)":
        "206,407",
      "E10000011.drugs_police_uk_12_month.2022-12-01.Drug crime offences (rollling 12 month total)":
        "30,645",
      "CSTM5160.drugs_police_uk_12_month.2023-03-01.Drug crime offences (rollling 12 month total)":
        "1,596",
      "E10000012.drugs_police_uk_12_month.2023-03-01.Drug crime offences (rollling 12 month total)":
        "201,703",
      "E10000011.drugs_police_uk_12_month.2023-03-01.Drug crime offences (rollling 12 month total)":
        "30,564",
      "CSTM5160.drugs_police_uk_12_month.2023-06-01.Drug crime offences (rollling 12 month total)":
        "1,551",
      "E10000012.drugs_police_uk_12_month.2023-06-01.Drug crime offences (rollling 12 month total)":
        "182,435",
      "E10000011.drugs_police_uk_12_month.2023-06-01.Drug crime offences (rollling 12 month total)":
        "28,257",
    },
    comparatorsList: [
      {
        area_code: "CSTM5160",
        area_name: "Comparator 1",
      },
      {
        area_code: "E10000012",
        area_name: "Comparator 2",
      },
      {
        area_code: "E10000011",
        area_name: "Comparator 3",
      },
    ],
    ausComparatorsList: [
      {
        area_code: "ASTE8",
        area_name: "Aus Comparator 1",
      },
      {
        area_code: "ASTE1",
        area_name: "Aus Comparator 2",
      },
      {
        area_code: "CSTM55",
        area_name: "Aus Comparator 3",
      },
    ],
  }),
  components: { Charts },
  async mounted() {
    this.clones["indicatorValues"] = structuredClone(this.indicatorValues);
    this.clones["pyramidIndicatorValues"] = structuredClone(
      this.pyramidIndicatorValues,
    );
    this.clones["comparatorsList"] = structuredClone(this.comparatorsList);
    this.clones["comparatorAreaCodes"] = structuredClone(
      this.comparatorAreaCodes,
    );
    this.chartsType = "Aus Pyramid";
  },
  watch: {
    chartsType: {
      handler(val) {
        if (val === "Pyramid") {
          // hackattack (we don't want to load the aus pyramid!)
          this.reportThemes[this.reportThemes.length - 2].config[0].chartType =
            "Pyramid";
          this.reportThemes[this.reportThemes.length - 1].config[0].chartType =
            null;
          this.indicatorValues = this.clones["pyramidIndicatorValues"];
          this.comparatorsList = this.clones["comparatorsList"];
          this.comparatorAreaCodes = this.clones["comparatorAreaCodes"];
        } else if (val === "Aus Pyramid") {
          // hackattack (we don't want to load the uk pyramid!)
          this.reportThemes[this.reportThemes.length - 1].config[0].chartType =
            "Pyramid";
          this.reportThemes[this.reportThemes.length - 2].config[0].chartType =
            null;
          this.indicatorValues = this.ausPyramidIndicatorValues;
          this.comparatorsList = this.ausComparatorsList;
          this.comparatorAreaCodes = this.ausComparatorAreaCodes;
        } else {
          this.indicatorValues = this.clones["indicatorValues"];
          this.comparatorsList = this.clones["comparatorsList"];
          this.comparatorAreaCodes = this.clones["comparatorAreaCodes"];
        }
      },
    },
  },
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
