<template>
  <!-- geoJSON import card -->
  <v-card tile width="100%" pa-5>
    <v-card-text v-if="!popImported">
      OA Population Centroids must be uploaded first
    </v-card-text>
    <v-container fluid>
      <v-row align="center" class="mt-1">
        <v-text-field
          label="Area Code Field"
          density="compact"
          rounded="0"
          variant="outlined"
          class="ma-3"
          v-model="area_code"
          autocomplete="off"
        ></v-text-field>
        <v-text-field
          label="Area Name Field"
          density="compact"
          rounded="0"
          variant="outlined"
          class="ma-3"
          v-model="area_name"
          :disabled="this.type == 'Population Centroids'"
          autocomplete="off"
        ></v-text-field>
        <v-text-field
          label="Time Point"
          density="compact"
          rounded="0"
          variant="outlined"
          class="ma-3"
          v-model="timepoint"
          autocomplete="off"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-select
          :items="type_options"
          label="Geometry Type"
          density="compact"
          rounded="0"
          variant="outlined"
          class="ma-3 mt-0"
          v-model="type"
        ></v-select>
        <v-select
          label="Country"
          density="compact"
          variant="outlined"
          rounded="0"
          v-model="country"
          :items="['England', 'Wales', 'Scotland']"
        ></v-select>
        <v-file-input
          label=".geojson"
          accept=".geojson,.GEOJSON "
          type="file"
          density="compact"
          rounded="0"
          variant="outlined"
          class="ma-3 mt-0"
          v-model="file"
        ></v-file-input>
      </v-row>
      <v-row>
        <v-spacer />
        <v-btn
          variant="elevated"
          class="ma-3"
          color="blue darken-1"
          tile
          @click="handleFile()"
          :disabled="this.can_upload == false"
          aria-label="Import GeoJSON"
        >
          Import GeoJSON
        </v-btn>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      style="overflow: auto"
      transition="dialog-bottom-transition"
      persistent
      class="no-scroll-card"
    >
      <v-card style="overflow: hidden">
        <v-card-title v-if="!this.import_complete"
          >Importing {{ area_code }} {{ type }}</v-card-title
        >
        <v-card-title v-if="this.import_complete">Complete</v-card-title>
        <v-container v-if="!this.import_complete">
          <v-progress-linear indeterminate color="#51627C"></v-progress-linear>
        </v-container>
        <v-container>
          <v-row class="text-center">
            <v-col cols="12">
              <!-- <ProgressTracker
                :import_details="geojson_import"
                v-on:complete="import_complete = true"
              ></ProgressTracker> -->
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="this.import_complete">
          <v-row
            v-if="this.import_success"
            class="text-center d-flex justify-center mb-6"
          >
            <v-alert dense type="success"> {{ import_message }}</v-alert>
          </v-row>
          <v-row
            v-if="!this.import_success"
            class="text-center d-flex justify-center mb-6"
          >
            <v-alert dense type="error">{{ import_error }}</v-alert>
          </v-row>
        </v-container>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            style="width: 35%"
            class="ma-5"
            color="blue darken-1"
            tile
            @click="
              dialog = false;
              import_complete = false;
            "
            aria-label="Close"
          >
            Close
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// import ProgressTracker from "@/components/ProgressTracker";

export default {
  name: "GeoJSONImporter",
  data: () => ({
    file: null,
    type_options: ["Population Centroids"],
    area_code: null,
    area_name: null,
    type: null,
    country: null,
    popImported: false,
    dialog: false,
    canUpload: false,
    import_complete: false,
    import_message: "",
    import_success: false,
    import_error: null,
    geojson_import: {
      name: "geojson",
      puid: null,
      import_percentage: 0,
      results_expected: 0,
      results_final: 0,
      start: false,
    },
    timepoint: null,
  }),
  components: {
    // ProgressTracker,
  },
  props: {},
  computed: {
    can_upload() {
      let canUpload = false;
      if (this.type == "Population Centroids") {
        if (this.area_code && this.type && this.file && this.timepoint) {
          canUpload = true;
        }
      } else {
        if (
          this.area_code &&
          this.area_name &&
          this.type &&
          this.file &&
          this.timepoint
        ) {
          canUpload = true;
        }
      }
      return canUpload;
    },
  },
  mounted() {
    this.checkPopulationImported();
  },
  methods: {
    handleFile() {
      // set up tracker object
      this.geojson_import.puid = "geojson" + "_" + Date.now();
      this.geojson_import.import_percentage = 0;
      this.geojson_import.results_expected = 0;
      this.geojson_import.results_final = 0;
      this.geojson_import.start = false;

      // set up import data
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("area_code", this.area_code);
      formData.append("area_name", this.area_name);
      formData.append("timepoint", parseInt(this.timepoint));
      formData.append("type", this.type);
      formData.append("puid", this.geojson_import.puid);
      formData.append("country", this.country);

      // send to back end for processing
      this.busy = true;
      this.dialog = true;
      this.geojson_import.start = true;

      // make the call!
      this.$axios
        .post("/geojson-import", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          function (response) {
            this.import_complete = true;
            this.busy = false;
            this.checkPopulationImported();
            // reset the form
            //this.file = null;
            //this.area_code = null;
            //this.area_name = null;
            //this.timepoint = null;
            //this.type = null;
            this.geojson_import.start = false;
            this.import_message = response.data.message;
            this.import_success = response.data.message;
            this.import_error = response.data.error;
            // handle success
            console.log(response);
          }.bind(this),
        )
        .catch((error) => {
          this.busy = false;
          this.dialog = false;
          this.geojson_import.start = false;
          this.emit.emit("systemMessage", error.response.data.message);
        });
    },
    checkPopulationImported() {
      // get the boolean
      this.busy = true;
      // make the call!
      this.$axios
        .get("/check-pop")
        .then(
          function (response) {
            this.popImported = response.data;
          }.bind(this),
        )
        .catch((error) => {
          this.busy = false;
          this.emit.emit("systemMessage", error.response.data.message);
        });
    },
  },
  watch: {
    popImported: function () {
      if (this.popImported) {
        this.type_options = ["Population Centroids", "Boundaries"];
      }
    },
  },
};
</script>

<style scoped>
.no-scroll-card {
  overflow: hidden;
  max-height: 450px;
}
</style>
