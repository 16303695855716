<template>
  <v-container class="pa-0 ma-0">
    <!-- Authorisation -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="cardTitle border-b-thin border-b-md pa-0 pr-4">
        <v-card-actions>
          <v-icon
            :color="isAuthorisationValid ? 'green' : '#FB8C00'"
            class="ma-2"
          >
            {{ isAuthorisationValid ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div>Authorisation</div>
        </v-card-actions>
      </v-card-title>

      <!-- Content under the panel -->
      <v-card-text class="pb-3">
        <div class="text caption mt-3">
          To access the Datastore API, you need an API key to keep your requests
          secure and verified. Paste an existing token here if you already have
          one, or create a new token to proceed.
        </div>
        <!-- textbox -->
        <v-text-field
          v-model="selectedKey"
          class="mt-4"
          variant="outlined"
          density="compact"
          rounded="0"
          hide-details="true"
          label="API Key"
        ></v-text-field>
        <!-- Toggle and Button container -->
        <div class="d-flex align-items-center justify-space-between">
          <v-switch
            v-model="skipForNow"
            hide-details="true"
            label="Skip for now"
            density="compact"
            class="mt-4 ml-2"
          ></v-switch>
          <v-btn
            @click="$router.push('/datastore/api-keys')"
            variant="text"
            prepend-icon="mdi-plus"
            color="#0e5b99"
            rounded="0"
            class="create-api-key-btn mt-4"
          >
            Create API Key
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <!-- Indicator -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="cardTitle border-b-thin border-b-md pa-0 pr-4">
        <v-card-actions>
          <v-icon
            :color="isIndicatorComplete ? 'green' : '#FB8C00'"
            class="ma-2"
          >
            {{ isIndicatorComplete ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div>Indicator</div>
        </v-card-actions>
      </v-card-title>

      <!-- No indicator selected -->
      <v-card-text v-if="!selectedIndicator" class="text-left pa-4">
        <!-- Alert -->
        <v-alert color="info" icon="mdi-information">
          <div style="font-weight: 500">Add an indicator</div>
          <div>Add an indicator to build your query</div>
        </v-alert>
        <div class="button-container">
          <v-btn
            class="mt-4 create-api-key-btn"
            variant="elevated"
            prepend-icon="mdi-plus"
            color="#0e5b99"
            @click="$router.push('/datastore/catalogue')"
            rounded="0"
          >
            add indicator from catalogue
          </v-btn>
        </div>
      </v-card-text>

      <!-- Selected indicator -->
      <v-card-text v-else class="text-left pa-4">
        <v-card-actions class="pa-0">
          <div>
            <div class="text-subtitle-1" style="font-weight: 400">
              {{ selectedIndicator.indicator_name }}
            </div>
            <div class="text-caption" style="color: gray">
              <v-icon icon="mdi-code-tags" size="15px" />
              {{ selectedIndicator.indicator_code }}
            </div>
          </div>
          <v-spacer />
          <div>
            <v-icon
              @click="removeIndicatorDialog = true"
              icon="mdi-delete"
              color="error"
            />
          </div>
        </v-card-actions>

        <!-- Indicator timepoints -->
        <v-card-actions class="px-0">
          <v-autocomplete
            v-model="queryParameters.timepoints"
            label="Time point"
            :items="timepoints"
            variant="outlined"
            :no-data-text="
              loadingTimepoints
                ? 'Loading timepoints'
                : 'No timepoints available'
            "
            :loading="loadingTimepoints"
            class="py-4"
            item-value="dateSelection"
            item-title="label"
            hide-details
            density="compact"
            rounded="0"
          />
        </v-card-actions>

        <!-- Checkboxes -->
        <div class="text-subtitle-1" style="font-weight: 400">Values</div>
        <v-card-text class="pa-0">
          <div
            @click.stop="
              queryParameters.raw_values = !queryParameters.raw_values
            "
            class="d-flex pa-1 checkbox-container"
          >
            <v-checkbox
              v-model="queryParameters.raw_values"
              label="Raw values"
              hide-details
              color="primary"
            />
          </div>
          <div
            @click.stop="queryParameters.rates = !queryParameters.rates"
            class="d-flex pa-1 checkbox-container"
          >
            <v-checkbox
              v-model="queryParameters.rates"
              label="Rates"
              hide-details
              color="primary"
            />
          </div>
        </v-card-text>
      </v-card-text>
      <DateRangePicker
        v-model:dialogProp="pickDateRange"
        @setRange="setCustomTimeRange"
        validate-date-range
      />

      <DynamicDialog
        :show="removeIndicatorDialog"
        @dialogOk="removeIndicator()"
        @dialogCancel="removeIndicatorDialog = false"
        @update:showDyamicDialog="(state) => (removeIndicatorDialog = state)"
        max-width="450"
        okBtnColor="error"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>Remove indicator</template>
        <template v-slot:content>
          Are you sure you want to remove this indicator from the query?
        </template>
        <template v-slot:okBtnTitle>remove indicator</template>
      </DynamicDialog>
    </v-card>

    <!-- Areas -->
    <DatastoreQueryBuilderAreas v-model:selectedAreas="selectedCustomAreas" />

    <!-- Data Format and Options -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="cardTitle border-b-thin border-b-md pa-0 pr-4">
        <v-card-actions>
          <v-icon
            :color="isDataFormatOptionsValid ? 'green' : '#FB8C00'"
            class="ma-2"
          >
            {{ isDataFormatOptionsValid ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div>Data Format & Options</div>
        </v-card-actions>
      </v-card-title>

      <!-- Content under the panel -->
      <v-card-text class="py-2">
        <v-select
          v-model="queryParameters.data_format"
          :items="[
            { title: 'Stacked/long format', value: 'stacked' },
            { title: 'Wide format', value: 'wide' },
          ]"
          label="Data formatting"
          variant="outlined"
          density="compact"
          rounded="0"
          hide-details="true"
        ></v-select>
      </v-card-text>
      <v-card-text class="py-2">
        <v-select
          v-model="queryParameters.area_code"
          :items="[
            { title: 'Include area code', value: 'true' },
            { title: 'Don\'t include area code', value: 'false' },
          ]"
          label="Area code"
          variant="outlined"
          density="compact"
          rounded="0"
          hide-details="true"
        ></v-select>
      </v-card-text>
      <v-card-text class="mb-2 py-2">
        <v-select
          v-model="queryParameters.unit_labels"
          :items="[
            { title: 'Include unit labels', value: 'true' },
            { title: 'Don\'t include unit labels', value: 'false' },
          ]"
          label="Unit labels"
          variant="outlined"
          density="compact"
          rounded="0"
          hide-details="true"
        ></v-select
      ></v-card-text>
    </v-card>

    <!-- Query -->
    <v-card class="my-4 main">
      <!-- Header -->
      <v-card-title class="cardTitle border-b-thin border-b-md pa-0 pr-4">
        <v-card-actions>
          <v-icon :color="isQueryComplete ? 'green' : '#FB8C00'" class="ma-2">
            {{ isQueryComplete ? "mdi-check-circle" : "mdi-alert" }}
          </v-icon>
          <div class="font-weight-medium">Your Query</div>
        </v-card-actions>
      </v-card-title>

      <v-card-text class="text-left pa-4">
        <!-- Alert -->
        <v-alert v-if="!isQueryComplete" color="info" icon="mdi-information">
          <div style="font-weight: 500">Complete your query</div>
          Your query is missing some details. Complete the fields above to
          finish your query.
        </v-alert>

        <div v-else icon="mdi-check-circle" class="text caption">
          Your query is ready! Copy the HTTP API query below and use it in your
          preferred tool to access the data. Happy querying!

          <!-- Section for viewing and copying the snippet -->
          <CodeBlock
            :code="codeSnippet"
            @update:languageChanged="getSnippet"
            :omitLanguages="['JSON']"
          />
        </div>
      </v-card-text>
    </v-card>

    <v-card class="my-4 main">
      <v-btn
        width="100%"
        height="61"
        prepend-icon="mdi-refresh"
        color="error"
        @click="resetFormDialog = true"
        variant="text"
      >
        reset form
      </v-btn>

      <DynamicDialog
        :show="resetFormDialog"
        @dialogOk="resetForm()"
        @dialogCancel="resetFormDialog = false"
        @update:showDyamicDialog="(state) => (resetFormDialog = state)"
        max-width="450"
        okBtnColor="error"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>Confirm reset</template>
        <template v-slot:content>
          Are you sure you want to reset the form? This will clear all the
          information you’ve entered so far.?
        </template>
        <template v-slot:okBtnTitle>reset form</template>
      </DynamicDialog>
    </v-card>
  </v-container>
</template>

<script>
import { systemMessages } from "@/mixins/SystemMessages";
import DynamicDialog from "@/components/DynamicDialog.vue";
import DatastoreQueryBuilderAreas from "@/components/DatastoreQueryBuilderAreas.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { copyToClipboard } from "@/mixins/CopyToClipboard";
import CodeBlock from "@/components/CodeBlock.vue";

export default {
  mounted() {},
  name: "DatastoreQueryBuilder",
  mixins: [systemMessages],
  components: {
    DynamicDialog,
    DatastoreQueryBuilderAreas,
    DateRangePicker,
    CodeBlock,
  },
  data: () => ({
    queryParameters: {
      indicator: null,
      area: null,
      raw_values: false,
      rates: false,
      timepoints: null,
      response_type: null,
      data_format: null,
      area_code: null,
      unit_labels: null,
    },
    selectedKey: null,
    timepoints: [],
    removeIndicatorDialog: false,
    loadingTimepoints: false,
    pickDateRange: false,
    resetFormDialog: false,
    selectedCustomAreas: [],
    codeSnippet: "",
    snippetType: "Axios",
    apiUrl: "api.localinsight.org/dsapi/",
    apiEndpoint: "beta/indicators/get", //this will need to be dynamic eventually to handle multiple endpoints/api versions
    skipForNow: false,

    // timepoint range
    show_start_year: true,
    show_end_year: true,
    start_year: new Date().getFullYear(),
    start_month: 0,
    end_year: new Date().getFullYear(),
    end_month: 0,
    months: {
      0: "January",
      1: "February",
      2: "March",
      3: "April",
      4: "May",
      5: "June",
      6: "July",
      7: "August",
      8: "September",
      9: "October",
      10: "November",
      11: "December",
    },
  }),
  computed: {
    apiUrlScheme() {
      if (process.env.VUE_APP_ENVIRONMENT === "dev") {
        return "http://";
      }

      return "https://";
    },
    apiUrlEnvDomain() {
      if (process.env.VUE_APP_ENVIRONMENT !== "") {
        return process.env.VUE_APP_ENVIRONMENT + ".";
      }

      return "";
    },
    isAuthorisationValid() {
      return this.selectedKey;
    },
    isDataFormatOptionsValid() {
      return (
        this.queryParameters.data_format &&
        this.queryParameters.area_code &&
        this.queryParameters.unit_labels
      );
    },
    isIndicatorComplete() {
      return (
        this.selectedIndicator?.id &&
        (this.queryParameters?.raw_values || this.queryParameters?.rates) &&
        this.queryParameters?.timepoints
      );
    },
    isQueryComplete() {
      return (
        this.isAuthorisationValid &&
        this.isDataFormatOptionsValid &&
        this.isIndicatorComplete
      );
    },
    selectedIndicator: {
      get() {
        return this.$store.getters["datastore/selectedIndicator"];
      },
      set(val) {
        this.$store.commit("datastore/setSelectedIndicator", val);
      },
    },
  },
  methods: {
    getSnippet(snippetType = null) {
      this.snippetType = !snippetType ? this.snippetType : snippetType;
      if (this.snippetType === "CURL") {
        this.codeSnippet = this.curlSnippet();
      }

      if (this.snippetType === "Guzzle") {
        this.codeSnippet = this.guzzleSnippet();
      }

      if (this.snippetType === "Axios") {
        this.codeSnippet = this.axiosSnippet();
      }

      if (this.snippetType === "Python") {
        this.codeSnippet = this.pythonSnippet();
      }

      if (this.snippetType === "R Project") {
        this.codeSnippet = this.rProjectSnippet();
      }
    },
    copySnippet() {
      copyToClipboard(this.getSnippet);
      this.successPill({
        icon: "mdi-content-copy",
        title: "Copied to clipboard",
        showClose: false,
      });
    },
    async getIndicatorTimepoints() {
      if (this.loadingTimepoints) return;

      this.loadingTimepoints = true;
      try {
        const response = await this.$axios.get(
          `/datastore/get-indicator-timepoints/${this.selectedIndicator?.id}`,
        );

        this.timepoints = response.data.timepoints;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTimepoints = false;
      }
    },
    removeIndicator() {
      this.selectedIndicator = null;
      this.removeIndicatorDialog = false;
      this.timepoints = [];
    },
    setCustomTimeRange(range) {
      // push to the list if it doesn't exist
      if (
        !this.timepoints.some((timepoint) => timepoint.dateSelection === range)
      ) {
        this.timepoints.push(range);
      }

      // set the selected timepoint
      this.queryParameters.timepoints = range;

      this.pickDateRange = false;
    },
    resetForm() {
      this.selectedIndicator = null;
      this.timepoints = [];
      this.selectedCustomAreas = [];
      this.selectedKey = null;
      this.queryParameters = {
        indicator: null,
        area: null,
        raw_values: false,
        rates: false,
        timepoints: null,
        response_type: null,
        data_format: null,
        area_code: null,
        unit_labels: null,
      };

      this.resetFormDialog = false;
    },
    getApiReqestBodyParams() {
      //filter queryParameters object to remove any null entries
      let params = Object.fromEntries(
        Object.entries(this.queryParameters).filter((p) => {
          return p[1] !== null;
        }),
      );

      //get custom areas if selected
      if (this.selectedCustomAreas?.length) {
        params.area_ids = this.selectedCustomAreas
          .map((area) => area.id)
          .join(",");
      }

      return params;
    },
    curlSnippet() {
      // intentionally indented like this so it looks normal in the UI/UX
      return `
curl  -X POST ${this.apiUrlScheme + this.apiUrlEnvDomain + this.apiUrl + this.apiEndpoint} \\
      -H "Content-Type: application/json" \\
      -H "Authorization: Bearer ${this.selectedKey}" \\
      -d '${JSON.stringify(this.getApiReqestBodyParams())}'
      `;
    },
    guzzleSnippet() {
      // intentionally indented like this so it looks normal in the UI/UX
      return `
$client = new GuzzleHttp\\Client();

$result = $client->request('POST', '${this.apiUrlScheme + this.apiUrlEnvDomain + this.apiUrl + this.apiEndpoint}', [
'headers' => [
  'Content-Type' => 'application/json',
  'Authorization' => 'Bearer ${this.selectedKey}'
  ],
'body' => [${JSON.stringify(this.getApiReqestBodyParams(), null, 2).replace(/:\s/g, " => ")}]
]);
      `;
    },
    axiosSnippet() {
      // intentionally indented like this so it looks normal in the UI/UX
      return `
axios.post('${this.apiUrlScheme + this.apiUrlEnvDomain + this.apiUrl + this.apiEndpoint}',
${JSON.stringify(this.getApiReqestBodyParams(), null, 2)},
{
headers: {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ${this.selectedKey}'
}
})
`;
    },
    pythonSnippet() {
      // intentionally indented like this so it looks normal in the UI/UX
      return `
import requests

url = "${this.apiUrlScheme + this.apiUrlEnvDomain + this.apiUrl + this.apiEndpoint}"
payload = {
${JSON.stringify(this.getApiReqestBodyParams(), null, 2)}
}
headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ${this.selectedKey}'
}

response = requests.post(url, json=payload, headers=headers)

      `;
    },
    rProjectSnippet() {
      // intentionally indented like this so it looks normal in the UI/UX
      return `
library(httr)

url <- "${this.apiUrlScheme + this.apiUrlEnvDomain + this.apiUrl + this.apiEndpoint}"

payload <- list(
  ${JSON.stringify(this.getApiReqestBodyParams(), null, 2).replace(/:\s/g, " = ")}
)

response <- POST(
  url,
  body    = payload,
  encode  = "json",
  add_headers(
    "Content-Type"  = "application/json",
    "Authorization" = "Bearer ${this.selectedKey}"
  )
)
`;
    },
  },
  watch: {
    queryParameters: {
      handler() {
        this.getSnippet();
      },
      deep: true,
    },
    selectedIndicator: {
      handler(newVal, oldVal) {
        // hit on every indicator change
        if (newVal && newVal?.id !== oldVal?.id) {
          this.getIndicatorTimepoints();

          this.queryParameters.indicator = newVal.indicator_code;
        }
      },
      deep: true,
    },
    "queryParameters.timepoints": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (newVal === "date_range") {
          // open date picker
          this.pickDateRange = !this.pickDateRange;

          // reset these
          this.queryParameters.timepoints = null;
        }
      }
    },
    skipForNow(newVal) {
      if (newVal) {
        this.selectedKey = "YOUR_API_KEY";
      } else {
        this.selectedKey = null;
      }
    },
  },
};
</script>

<style scoped>
.circle {
  width: 11px;
  height: 11px;
  border-radius: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
.caption {
  text-align: left;
  color: #4d4d4d;
}
.main {
  width: calc(100% - 32px);
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
}
@media (min-width: 600px) {
  .main {
    width: auto;
  }
}
.select-key {
  color: #4d4d4d;
}

.checkbox-container {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.checkbox-container:nth-of-type(1) {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.checkbox-container:nth-of-type(2) {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.grey-background {
  background-color: #e0e0e0; /* Light grey background */
}
</style>
