<script>
import { Intercom, update, shutdown } from "@intercom/messenger-js-sdk";
import { createHmac } from "crypto";

export default {
  name: "IntercomWidget",
  /** renderless component - Intercom lib injects widget */
  render() {
    return this.$slots.default;
  },
  mounted() {
    this.setIntercomEnabled();
    this.initMessenger(); //start intercom when component called.
    this.emit.on("intercom:shutdown", this.shutdownMessenger); //listen for call to end session
  },
  data() {
    return {
      environments: ["stg", "prod"], //add VUE_APP_ENVIRONMENT envs here to enable intercom e.g. dev
      excludedRoutes: ["/", "/map", "/discovery-tool"], //add route paths here where intercom should NOT appear
      intercomEnabled: false,
      hmac: null,
      details: {},
    };
  },
  computed: {
    //fetch app config (user/company details) from vuex
    config: {
      get() {
        return this.$store.state.config;
      },
    },
    //check whether a user is logged in
    userLoggedIn: {
      get() {
        return (
          this.config.userProfile !== undefined &&
          this.config.userProfile?.is_public_site_user_profile !== true
        );
      },
    },
    //check whether user is a public site user (not logged in - public site user flag)
    publicSiteUser: {
      get() {
        return (
          this.config.userProfile?.is_public_site_user_profile !== undefined &&
          this.config.userProfile?.is_public_site_user_profile !== false
        );
      },
    },
    //hide messenger based on: route is in exclusion list OR a public site user not on login page
    hideLauncher: {
      get() {
        return (
          this.excludedRoutes.includes(this.$route.path) ||
          (this.publicSiteUser && this.$route.path != "/login")
        );
      },
    },
  },
  watch: {
    //watch for vue route changes
    $route() {
      this.routeChanged();
    },
  },
  methods: {
    /** initialise the intercom messenger integration and show or hide it */
    initMessenger() {
      if (this.intercomEnabled) {
        Intercom({
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          region: "eu",
        });
      }
    },
    /** update the messenger instance with user and company details  */
    updateMessenger() {
      if (
        this.intercomEnabled &&
        !this.publicSiteUser // Only send for logged in users
      ) {
        this.generateHMAC();
        let data = {
          hide_default_launcher: this.hideLauncher,
          user_hash: this.hmac,
          email: this.config.userProfile.email,
          name: this.config.userProfile.name,
          created_at: this.config.userProfile.created_at,
          company: {
            company_id: this.config.userProfile.client_id,
            name: this.config.customClientConfig.client_name,
            "Company sector": this.config.customClientConfig.client_sector,
            "Company type": this.config.customClientConfig.client_type,
            "Public sites": this.details?.public_sites,
            Locales: this.details?.locales,
            "Resubscription date":
              this.config.customClientConfig.contract_renewal_date,
          },
          user_id: this.config.userProfile.id,
          "Number of logins": this.config.userProfile.number_of_logins,
          "Last login": this.config.userProfile.last_login,
          "Account suspended": this.config.userProfile.account_suspended,
          "Module access": this.details?.module_access,
          "Module groups": this.details?.module_groups,
        };
        update(data);
      }
    },
    /** end the messenger instance session */
    shutdownMessenger() {
      if (this.intercomEnabled) {
        shutdown();
      }
    },
    /** set enabled/disabled flag based on current environment */
    setIntercomEnabled() {
      this.intercomEnabled = this.environments.includes(
        process.env.VUE_APP_ENVIRONMENT,
      );
    },
    /** generate HMAC (Hash-based Message Authentication Code) for user */
    generateHMAC() {
      const userIdentifier = this.config.userProfile.id.toString();

      this.hmac = createHmac("sha256", process.env.VUE_APP_INTERCOM_HMAC_KEY)
        .update(userIdentifier)
        .digest("hex");
    },
    async routeChanged() {
      //set launcher visibility
      update({ hide_default_launcher: this.hideLauncher });

      if (this.userLoggedIn) {
        //logged in user - fetch extra details from backend and update
        await this.fetchIntercomDetails();
        this.updateMessenger();
      }
    },
    // Get extra details and then update messenger
    fetchIntercomDetails() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/intercom-details")
          .then((response) => {
            this.data = response.data;
            resolve();
          })
          .catch((error) => {
            console.error("Error fetching intercom details:", error);
            reject();
          });
      });
    },
  },
};
</script>
