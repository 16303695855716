<template>
    <v-app
        id="inspire"
        class=""
    >

    <h1>Report Sandbox</h1>
    <p>
      Dev env only sandbox to view report behaviour without fetching and
      aggregating indicators
    </p>

    <!-- <v-btn variant="elevated" @click="showDialog">Show Report</v-btn> -->

    <ReportViewer 
        :isReportSandbox="true" 
        :staticReportObject="reportObject" 
        :staticIndicatorValues="indicatorValues" 
        :staticReportThemes="reportThemes"
        :staticComparatorsList="comparatorsList">
    </ReportViewer>

    </v-app>
</template>

<script>
import ReportViewer from "@/components/ReportViewer";

export default {
  name: "ReportSandbox",
  data: () => ({
    showReport: false,
    comparatorsList: [
      {
        area_code: "CSTM5160",
        area_name: "Comparator 1",
      },
      {
        area_code: "E92000001",
        area_name: "Comparator 2",
      },
      {
        area_code: "E12000008",
        area_name: "Comparator 3",
      },
    ],
    reportObject: {
      id: 10,
      clientID: 1,
      name: "Local Insight England Summary Report",
      slug: "eng-summary-report",
      description: "Summary report for England",
      default: true,
      live: true,
      created_at: "2023-03-30T14:18:50.000000Z",
      introduction: "",
      site_branding:
        '<p>Local Insight provides instant access to the information you need for the neighbourhoods you care about.</p>\n\n<p>We bring together small-area social and demographic data in one easy to use and intuitive platform. You can explore data for any area you are interested in through maps, dashboards and reports.</p>\n\n <p>Find out more at: <a href="https://localinsight.org">localinsight.org</a></p>',
      ocsi_branding:
        '<p>We combine expertise in research and technology to support organisations working for social good to make evidence-based decisions. We have worked with more than 250 public and community sector organisations from local to international level.</p>\n\n<p>Our research and services are based on unrivalled knowledge and use of place-based social, economic and demographic data.</p>\n\n<p>Find out more at: <a href="https://ocsi.uk">ocsi.uk</a></p>',
      about_ind:
        "<p>OCSI routinely collect all small-area data published by more than 50 government agencies and other reputable bodies.  We identify key indicators relevant to local authorities, grantmakers and charities to include within this report and the Local Insight platform. </p>\n<p>We manage and maintain all the data and provide comprehensive metadata so that you can be confident you are using the most up-to-date data.</p>",
      how_area_identified:
        "<p>This report is based on the “Brighton and Hove” area created by OCSI In House. There is a map image of the area at the start of this report. Source: Office for National Statistics licensed under the Open Government Licence v.3.0. Contains OS data © Crown copyright and database right [2024].</p>\n\n\n\n<p>We use sophisticated aggregation and apportioning methods to calculate the data values used in the charts and tables in this report.</p>\n\n<p>Alongside data for the “Brighton and Hove” area, we also display data for the selected comparator areas: England and South East.</p>",
      country: "E",
      which_country_themes: "England",
      custom_report_name: "Local Insight England Custom Report",
      discovery_tool: false,
      assigned_themes: [
        {
          id: 177,
          themeID: 1,
          reportID: 10,
          order: 1,
          country: "E",
        },
        {
          id: 179,
          themeID: 2,
          reportID: 10,
          order: 2,
          country: "E",
        },
        {
          id: 180,
          themeID: 4,
          reportID: 10,
          order: 3,
          country: "E",
        },
        {
          id: 181,
          themeID: 7,
          reportID: 10,
          order: 4,
          country: "E",
        },
        {
          id: 182,
          themeID: 8,
          reportID: 10,
          order: 5,
          country: "E",
        },
        {
          id: 183,
          themeID: 9,
          reportID: 10,
          order: 6,
          country: "E",
        },
        {
          id: 184,
          themeID: 6,
          reportID: 10,
          order: 7,
          country: "E",
        },
        {
          id: 187,
          themeID: 5,
          reportID: 10,
          order: 8,
          country: "E",
        },
        {
          id: 193,
          themeID: 125,
          reportID: 10,
          order: 9,
          country: "E",
        },
      ],
      custom_summary_reports: [],
    },
    indicatorValues: {
        "CSTM5160.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021)": "19,070",
        "E92000001.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021)": "6,106,970",
        "E12000008.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021)": "1,163,276",
        "CSTM5160.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021)": "4,998",
        "E92000001.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021)": "2,138,372",
        "E12000008.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021)": "448,675",
        "CSTM5160.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021)": "51,874",
        "E92000001.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021)": "9,674,645",
        "E12000008.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021)": "1,553,917",
        "CSTM5160.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021)": "45,460",
        "E92000001.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021)": "5,516,098",
        "E12000008.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021)": "642,098",
        "CSTM5160.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011)": "46,415",
        "E92000001.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011)": "5,691,251",
        "E12000008.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011)": "660,430",
        "CSTM5160.core_access_employment_trav_time_ptwalk.2019-01-01.Travel time to nearest employment centre by public transport/walk (with more than 500 jobs)": "8.0",
        "E92000001.core_access_employment_trav_time_ptwalk.2019-01-01.Travel time to nearest employment centre by public transport/walk (with more than 500 jobs)": "9.0",
        "E12000008.core_access_employment_trav_time_ptwalk.2019-01-01.Travel time to nearest employment centre by public transport/walk (with more than 500 jobs)": "9.0",
        "CSTM5160.core_access_employment_trav_time_ptwalk_5000.2019-01-01.Travel time to nearest employment centre by public transport/walk (with more than 5000 jobs)": "12.0",
        "E92000001.core_access_employment_trav_time_ptwalk_5000.2019-01-01.Travel time to nearest employment centre by public transport/walk (with more than 5000 jobs)": "16.0",
        "E12000008.core_access_employment_trav_time_ptwalk_5000.2019-01-01.Travel time to nearest employment centre by public transport/walk (with more than 5000 jobs)": "18.0",
        "CSTM5160.core_access_fe_trav_time_ptwalk.2019-01-01.Travel time to nearest Further Education Institution by public transport/walk": "19.0",
        "E92000001.core_access_fe_trav_time_ptwalk.2019-01-01.Travel time to nearest Further Education Institution by public transport/walk": "22.0",
        "E12000008.core_access_fe_trav_time_ptwalk.2019-01-01.Travel time to nearest Further Education Institution by public transport/walk": "24.0",
        "CSTM5160.core_access_gp_trav_time_ptwalk.2019-01-01.Travel time to nearest GP by public transport/walk": "9.0",
        "E92000001.core_access_gp_trav_time_ptwalk.2019-01-01.Travel time to nearest GP by public transport/walk": "13.0",
        "E12000008.core_access_gp_trav_time_ptwalk.2019-01-01.Travel time to nearest GP by public transport/walk": "14.0",
        "CSTM5160.core_access_hospital_trav_time_ptwalk.2019-01-01.Travel time to nearest Hospital by public transport/walk": "29.0",
        "E92000001.core_access_hospital_trav_time_ptwalk.2019-01-01.Travel time to nearest Hospital by public transport/walk": "41.0",
        "E12000008.core_access_hospital_trav_time_ptwalk.2019-01-01.Travel time to nearest Hospital by public transport/walk": "45.0",
        "CSTM5160.core_access_primary_school_trav_time_ptwalk.2019-01-01.Travel time to nearest Primary School by public transport/walk": "8.0",
        "E92000001.core_access_primary_school_trav_time_ptwalk.2019-01-01.Travel time to nearest Primary School by public transport/walk": "9.0",
        "E12000008.core_access_primary_school_trav_time_ptwalk.2019-01-01.Travel time to nearest Primary School by public transport/walk": "10.0",
        "CSTM5160.core_access_secondary_school_trav_time_ptwalk.2019-01-01.Travel time to nearest Secondary School by public transport/walk": "18.0",
        "E92000001.core_access_secondary_school_trav_time_ptwalk.2019-01-01.Travel time to nearest Secondary School by public transport/walk": "18.0",
        "E12000008.core_access_secondary_school_trav_time_ptwalk.2019-01-01.Travel time to nearest Secondary School by public transport/walk": "20.0",
        "CSTM5160.core_access_supermarket_trav_time_ptwalk.2019-01-01.Travel time to nearest supermarket by public transport/walk": "7.0",
        "E92000001.core_access_supermarket_trav_time_ptwalk.2019-01-01.Travel time to nearest supermarket by public transport/walk": "7.0",
        "E12000008.core_access_supermarket_trav_time_ptwalk.2019-01-01.Travel time to nearest supermarket by public transport/walk": "7.0",
        "CSTM5160.core_access_town_trav_time_ptwalk.2019-01-01.Travel time to nearest town centre by public transport/walk": "20.0",
        "E92000001.core_access_town_trav_time_ptwalk.2019-01-01.Travel time to nearest town centre by public transport/walk": "21.0",
        "E12000008.core_access_town_trav_time_ptwalk.2019-01-01.Travel time to nearest town centre by public transport/walk": "21.0",
        "CSTM5160.bargeo19_rank.2019-01-01.IoD 2019 Geographical Barriers Sub-domain Rank": "23,485",
        "E92000001.bargeo19_rank.2019-01-01.IoD 2019 Geographical Barriers Sub-domain Rank": "16,490",
        "E12000008.bargeo19_rank.2019-01-01.IoD 2019 Geographical Barriers Sub-domain Rank": "13,541",
        "CSTM5160.bargeo15_rank.2015-01-01.IoD 2015 Geographical Barriers Sub-domain Rank": "24,328",
        "E92000001.bargeo15_rank.2015-01-01.IoD 2015 Geographical Barriers Sub-domain Rank": "16,491",
        "E12000008.bargeo15_rank.2015-01-01.IoD 2015 Geographical Barriers Sub-domain Rank": "13,458",
        "CSTM5160.id19_road_food.2018-01-01.IoD 2019 Road distance to general store or supermarket indicator (km)": "0.377",
        "E92000001.id19_road_food.2018-01-01.IoD 2019 Road distance to general store or supermarket indicator (km)": "0.711",
        "E12000008.id19_road_food.2018-01-01.IoD 2019 Road distance to general store or supermarket indicator (km)": "0.774",
        "CSTM5160.id19_road_gp.2019-01-01.IoD 2019 Road distance to a GP surgery indicator (km)": "0.800",
        "E92000001.id19_road_gp.2019-01-01.IoD 2019 Road distance to a GP surgery indicator (km)": "1.304",
        "E12000008.id19_road_gp.2019-01-01.IoD 2019 Road distance to a GP surgery indicator (km)": "1.434",
        "CSTM5160.id19_road_po.2018-01-01.IoD 2019 Road distance to a post office indicator (km)": "0.731",
        "E92000001.id19_road_po.2018-01-01.IoD 2019 Road distance to a post office indicator (km)": "1.121",
        "E12000008.id19_road_po.2018-01-01.IoD 2019 Road distance to a post office indicator (km)": "1.254",
        "CSTM5160.id19_road_primary.2019-01-01.IoD 2019 Road distance to a primary school indicator (km)": "0.705",
        "E92000001.id19_road_primary.2019-01-01.IoD 2019 Road distance to a primary school indicator (km)": "0.853",
        "E12000008.id19_road_primary.2019-01-01.IoD 2019 Road distance to a primary school indicator (km)": "0.976",
        "CSTM5160.jobs_access_score.2021-01-01.Job access score": "281,448.78",
        "E92000001.jobs_access_score.2021-01-01.Job access score": "698,519.47",
        "E12000008.jobs_access_score.2021-01-01.Job access score": "620,634.19",
        "CSTM5160.p_p_food_index_rank.2024-07-01.Priority Places for Food Index (Rank)": "22,896",
        "E92000001.p_p_food_index_rank.2024-07-01.Priority Places for Food Index (Rank)": "16,898",
        "E12000008.p_p_food_index_rank.2024-07-01.Priority Places for Food Index (Rank)": "20,764",
        "CSTM5160.ppfood_domain_ecommerce_access_rank.2024-07-01.PPFI Ecommerce Access Domain (Rank)": "23,320",
        "E92000001.ppfood_domain_ecommerce_access_rank.2024-07-01.PPFI Ecommerce Access Domain (Rank)": "16,038",
        "E12000008.ppfood_domain_ecommerce_access_rank.2024-07-01.PPFI Ecommerce Access Domain (Rank)": "18,933",
        "CSTM5160.ppfood_domain_fuel_poverty_rank.2024-07-01.PPFI Fuel Poverty Domain (Rank)": "16,572",
        "E92000001.ppfood_domain_fuel_poverty_rank.2024-07-01.PPFI Fuel Poverty Domain (Rank)": "16,454",
        "E12000008.ppfood_domain_fuel_poverty_rank.2024-07-01.PPFI Fuel Poverty Domain (Rank)": "22,685",
        "CSTM5160.ppfood_domain_food_for_families_rank.2024-07-01.PPFI Food for Families Domain (Rank)": "18,428",
        "E92000001.ppfood_domain_food_for_families_rank.2024-07-01.PPFI Food for Families Domain (Rank)": "16,853",
        "E12000008.ppfood_domain_food_for_families_rank.2024-07-01.PPFI Food for Families Domain (Rank)": "21,448",
        "CSTM5160.ppfood_domain_nonsupermarket_proximity_rank.2024-07-01.PPFI Non-Supermarket Proximity Domain (Rank)": "22,204",
        "E92000001.ppfood_domain_nonsupermarket_proximity_rank.2024-07-01.PPFI Non-Supermarket Proximity Domain (Rank)": "17,154",
        "E12000008.ppfood_domain_nonsupermarket_proximity_rank.2024-07-01.PPFI Non-Supermarket Proximity Domain (Rank)": "12,154",
        "CSTM5160.ppfood_domain_supermarket_proximity_rank.2024-07-01.PPFI Supermarket Proximity Domain (Rank)": "21,032",
        "E92000001.ppfood_domain_supermarket_proximity_rank.2024-07-01.PPFI Supermarket Proximity Domain (Rank)": "17,094",
        "E12000008.ppfood_domain_supermarket_proximity_rank.2024-07-01.PPFI Supermarket Proximity Domain (Rank)": "15,088",
        "CSTM5160.ppfood_domain_supermarket_accessibility_rank.2024-07-01.PPFI Supermarket Accessibility Domain (Rank)": "27,553",
        "E92000001.ppfood_domain_supermarket_accessibility_rank.2024-07-01.PPFI Supermarket Accessibility Domain (Rank)": "17,059",
        "E12000008.ppfood_domain_supermarket_accessibility_rank.2024-07-01.PPFI Supermarket Accessibility Domain (Rank)": "14,141",
        "CSTM5160.ppfood_domain_socio_demographic_rank.2024-07-01.PPFI Socio-Demographic Barriers Domain (Rank)": "12,179",
        "E92000001.ppfood_domain_socio_demographic_rank.2024-07-01.PPFI Socio-Demographic Barriers Domain (Rank)": "16,612",
        "E12000008.ppfood_domain_socio_demographic_rank.2024-07-01.PPFI Socio-Demographic Barriers Domain (Rank)": "21,131",
        "CSTM5160.ahah_leisure.2024-09-01.AHAH Leisure Centres": "2.30",
        "E92000001.ahah_leisure.2024-09-01.AHAH Leisure Centres": "4.61",
        "E12000008.ahah_leisure.2024-09-01.AHAH Leisure Centres": "4.71",
        "CSTM5160.ahah_dentists.2024-09-01.AHAH Dentists": "1.48",
        "E92000001.ahah_dentists.2024-09-01.AHAH Dentists": "3.22",
        "E12000008.ahah_dentists.2024-09-01.AHAH Dentists": "3.41",
        "CSTM5160.ahah_gp_surgeries.2024-09-01.AHAH GP surgeries": "1.78",
        "E92000001.ahah_gp_surgeries.2024-09-01.AHAH GP surgeries": "3.06",
        "E12000008.ahah_gp_surgeries.2024-09-01.AHAH GP surgeries": "3.49",
        "CSTM5160.ahah_hospitals.2024-09-01.AHAH A&E Hospitals": "1.40",
        "E92000001.ahah_hospitals.2024-09-01.AHAH A&E Hospitals": "2.79",
        "E12000008.ahah_hospitals.2024-09-01.AHAH A&E Hospitals": "2.99",
        "CSTM5160.ahah_pharmacies.2024-09-01.AHAH Pharmacies": "1.31",
        "E92000001.ahah_pharmacies.2024-09-01.AHAH Pharmacies": "2.67",
        "E12000008.ahah_pharmacies.2024-09-01.AHAH Pharmacies": "2.99",
        "CSTM5160.ahah_fast_food.2024-09-01.AHAH Fast food outlets": "1.26",
        "E92000001.ahah_fast_food.2024-09-01.AHAH Fast food outlets": "2.79",
        "E12000008.ahah_fast_food.2024-09-01.AHAH Fast food outlets": "3.12",
        "CSTM5160.ahah_gambling.2024-09-01.AHAH Gambling outlets": "2.13",
        "E92000001.ahah_gambling.2024-09-01.AHAH Gambling outlets": "4.32",
        "E12000008.ahah_gambling.2024-09-01.AHAH Gambling outlets": "4.82",
        "CSTM5160.ahah_pubs_night_clubs.2024-09-01.AHAH Pubs/bars/nightclubs": "1.43",
        "E92000001.ahah_pubs_night_clubs.2024-09-01.AHAH Pubs/bars/nightclubs": "2.65",
        "E12000008.ahah_pubs_night_clubs.2024-09-01.AHAH Pubs/bars/nightclubs": "2.72",
        "CSTM5160.ahah_tobacconist.2024-09-01.AHAH Tobacconists and Vape Stores": "2.81",
        "E92000001.ahah_tobacconist.2024-09-01.AHAH Tobacconists and Vape Stores": "5.21",
        "E12000008.ahah_tobacconist.2024-09-01.AHAH Tobacconists and Vape Stores": "6.06",
        "CSTM5160.community_needs_rank.2023-01-01.Community Needs Index 2023: Community Needs rank": "22,368",
        "E92000001.community_needs_rank.2023-01-01.Community Needs Index 2023: Community Needs rank": "17,040",
        "E12000008.community_needs_rank.2023-01-01.Community Needs Index 2023: Community Needs rank": "17,783",
        "CSTM5160.active_community_rank.2023-01-01.Community Needs Index 2023: Active and Engaged Community rank": "22,366",
        "E92000001.active_community_rank.2023-01-01.Community Needs Index 2023: Active and Engaged Community rank": "16,883",
        "E12000008.active_community_rank.2023-01-01.Community Needs Index 2023: Active and Engaged Community rank": "18,939",
        "CSTM5160.civic_assets_rank.2023-01-01.Community Needs Index 2023: Civic Assets rank": "25,698",
        "E92000001.civic_assets_rank.2023-01-01.Community Needs Index 2023: Civic Assets rank": "17,060",
        "E12000008.civic_assets_rank.2023-01-01.Community Needs Index 2023: Civic Assets rank": "14,889",
        "CSTM5160.connectedness_rank.2023-01-01.Community Needs Index 2023: Connectedness rank": "13,767",
        "E92000001.connectedness_rank.2023-01-01.Community Needs Index 2023: Connectedness rank": "17,033",
        "E12000008.connectedness_rank.2023-01-01.Community Needs Index 2023: Connectedness rank": "18,412",
        "CSTM5160.biglottery_award_04_21_sum_exmill*dr.2021-05-01.The National Lottery Community Fund grant funding per 1,000 population (£) 2004-2021": "46,491,769",
        "E92000001.biglottery_award_04_21_sum_exmill*dr.2021-05-01.The National Lottery Community Fund grant funding per 1,000 population (£) 2004-2021": "2,147,483,647",
        "E12000008.biglottery_award_04_21_sum_exmill*dr.2021-05-01.The National Lottery Community Fund grant funding per 1,000 population (£) 2004-2021": "493,038,230",
        "CSTM5160.grant_funding_count*dr.2024-10-01.Total count of grants from major grant funders": "3,288",
        "E92000001.grant_funding_count*dr.2024-10-01.Total count of grants from major grant funders": "461,327",
        "E12000008.grant_funding_count*dr.2024-10-01.Total count of grants from major grant funders": "63,037",
        "CSTM5160.grant_funding_pounds*dr.2024-10-01.Total amount awarded from major grant funders per head": "780,070,479",
        "E92000001.grant_funding_pounds*dr.2024-10-01.Total amount awarded from major grant funders per head": "177,090,838,789",
        "E12000008.grant_funding_pounds*dr.2024-10-01.Total amount awarded from major grant funders per head": "22,780,974,205",
        "CSTM5160.voter_turnout.2022-01-01.Voter Turnout at Local Elections": "57.56%",
        "E92000001.voter_turnout.2022-01-01.Voter Turnout at Local Elections": "46.71%",
        "E12000008.voter_turnout.2022-01-01.Voter Turnout at Local Elections": "48.09%",
        "CSTM5160.charities*dr.2021-05-01.Total registered charities": "787",
        "E92000001.charities*dr.2021-05-01.Total registered charities": "157,978",
        "E12000008.charities*dr.2021-05-01.Total registered charities": "27,095",
        "CSTM5160.civic_eng_r.2021-01-01.Percentage of people taking part in any civic engagement": "49.79",
        "E92000001.civic_eng_r.2021-01-01.Percentage of people taking part in any civic engagement": "45.19",
        "E12000008.civic_eng_r.2021-01-01.Percentage of people taking part in any civic engagement": "45.94",
        "CSTM5160.cls_consult_r.2021-01-01.Not taken part in a consultation about local services or issues in your local area": "20.28",
        "E92000001.cls_consult_r.2021-01-01.Not taken part in a consultation about local services or issues in your local area": "19.21",
        "E12000008.cls_consult_r.2021-01-01.Not taken part in a consultation about local services or issues in your local area": "19.61",
        "CSTM5160.cls_club_r.2021-01-01.Not taken part in community groups clubs or organisations": "66.35",
        "E92000001.cls_club_r.2021-01-01.Not taken part in community groups clubs or organisations": "64.06",
        "E12000008.cls_club_r.2021-01-01.Not taken part in community groups clubs or organisations": "65.79",
        "CSTM5160.infl_r.2021-01-01.People who feel they can influence decision in their local area": "72.22",
        "E92000001.infl_r.2021-01-01.People who feel they can influence decision in their local area": "73.84",
        "E12000008.infl_r.2021-01-01.People who feel they can influence decision in their local area": "74.27",
        "CSTM5160.volunteer_r.2021-01-01.Percentage of people engaged in formal or informal volunteering in the last month": "63.98",
        "E92000001.volunteer_r.2021-01-01.Percentage of people engaged in formal or informal volunteering in the last month": "63.02",
        "E12000008.volunteer_r.2021-01-01.Percentage of people engaged in formal or informal volunteering in the last month": "64.21",
        "CSTM5160.cls_pull_neigh_r.2021-01-01.Disagree that: People in this neighbourhood pull together to improve the neighbourhood": "55.42",
        "E92000001.cls_pull_neigh_r.2021-01-01.Disagree that: People in this neighbourhood pull together to improve the neighbourhood": "60.23",
        "E12000008.cls_pull_neigh_r.2021-01-01.Disagree that: People in this neighbourhood pull together to improve the neighbourhood": "61.95",
        "CSTM5160.cls_keys_neigh_r.2021-01-01.Fairly or very uncomfortable with asking a neighbour to keep a set of keys to your home for emergencies": "55.77",
        "E92000001.cls_keys_neigh_r.2021-01-01.Fairly or very uncomfortable with asking a neighbour to keep a set of keys to your home for emergencies": "62.80",
        "E12000008.cls_keys_neigh_r.2021-01-01.Fairly or very uncomfortable with asking a neighbour to keep a set of keys to your home for emergencies": "65.15",
        "CSTM5160.cls_belong_neigh_r.2021-01-01.Do not feel belong very strongly to neighbourhood": "56.66",
        "E92000001.cls_belong_neigh_r.2021-01-01.Do not feel belong very strongly to neighbourhood": "62.92",
        "E12000008.cls_belong_neigh_r.2021-01-01.Do not feel belong very strongly to neighbourhood": "63.54",
        "CSTM5160.cls_chat_neigh_r.2021-01-01.Never chat to neighbours": "35.14",
        "E92000001.cls_chat_neigh_r.2021-01-01.Never chat to neighbours": "27.16",
        "E12000008.cls_chat_neigh_r.2021-01-01.Never chat to neighbours": "26.40",
        "CSTM5160.cls_shop_neigh_r.2021-01-01.Fairly or very uncomfortable with asking a neighbour to collect a few shopping essentials if you were ill and at home on your own": "46.98",
        "E92000001.cls_shop_neigh_r.2021-01-01.Fairly or very uncomfortable with asking a neighbour to collect a few shopping essentials if you were ill and at home on your own": "53.56",
        "E12000008.cls_shop_neigh_r.2021-01-01.Fairly or very uncomfortable with asking a neighbour to collect a few shopping essentials if you were ill and at home on your own": "55.08",
        "CSTM5160.cls_borrow_neigh_r.2021-01-01.Disagree that borrow things or exchange favours with neighbours": "31.49",
        "E92000001.cls_borrow_neigh_r.2021-01-01.Disagree that borrow things or exchange favours with neighbours": "36.13",
        "E12000008.cls_borrow_neigh_r.2021-01-01.Disagree that borrow things or exchange favours with neighbours": "36.63",
        "CSTM5160.ren_comm_assets*dr.2023-02-01.Density of Community owned assets": "3,572",
        "E92000001.ren_comm_assets*dr.2023-02-01.Density of Community owned assets": "431,648",
        "E12000008.ren_comm_assets*dr.2023-02-01.Density of Community owned assets": "57,011",
        "CSTM5160.liv15_rank.2015-01-01.IoD 2015 Living Environment Rank": "10,873",
        "E92000001.liv15_rank.2015-01-01.IoD 2015 Living Environment Rank": "16,433",
        "E12000008.liv15_rank.2015-01-01.IoD 2015 Living Environment Rank": "20,147",
        "CSTM5160.liv19_rank.2019-01-01.IoD 2019 Living Environment Rank": "13,933",
        "E92000001.liv19_rank.2019-01-01.IoD 2019 Living Environment Rank": "16,158",
        "E12000008.liv19_rank.2019-01-01.IoD 2019 Living Environment Rank": "18,876",
        "CSTM5160.indoor15_rank.2015-01-01.IoD 2015 Indoors Sub-domain Rank": "12,626",
        "E92000001.indoor15_rank.2015-01-01.IoD 2015 Indoors Sub-domain Rank": "16,112",
        "E12000008.indoor15_rank.2015-01-01.IoD 2015 Indoors Sub-domain Rank": "19,959",
        "CSTM5160.indoor19_rank.2019-01-01.IoD 2019 Indoors Sub-domain Rank": "13,716",
        "E92000001.indoor19_rank.2019-01-01.IoD 2019 Indoors Sub-domain Rank": "16,170",
        "E12000008.indoor19_rank.2019-01-01.IoD 2019 Indoors Sub-domain Rank": "19,123",
        "CSTM5160.outdoor15_rank.2015-01-01.IoD 2015 Outdoors Sub-domain Rank": "7,243",
        "E92000001.outdoor15_rank.2015-01-01.IoD 2015 Outdoors Sub-domain Rank": "16,321",
        "E12000008.outdoor15_rank.2015-01-01.IoD 2015 Outdoors Sub-domain Rank": "17,943",
        "CSTM5160.outdoor19_rank.2019-01-01.IoD 2019 Outdoors Sub-domain Rank": "12,925",
        "E92000001.outdoor19_rank.2019-01-01.IoD 2019 Outdoors Sub-domain Rank": "16,391",
        "E12000008.outdoor19_rank.2019-01-01.IoD 2019 Outdoors Sub-domain Rank": "16,189",
        "CSTM5160.atgs_england*dr.2024-08-01.Percentage of households with access to Green Space": "35,660.00",
        "E92000001.atgs_england*dr.2024-08-01.Percentage of households with access to Green Space": "5,958,424.00",
        "E12000008.atgs_england*dr.2024-08-01.Percentage of households with access to Green Space": "955,999.00",
        "CSTM5160.tree_cover.2022-01-01.Tree cover": "13.2%",
        "E92000001.tree_cover.2022-01-01.Tree cover": "14.0%",
        "E12000008.tree_cover.2022-01-01.Tree cover": "18.9%",
        "CSTM5160.private_outdoor_space*dr.2020-04-01.Addresses with private outdoor space": "115,156",
        "E92000001.private_outdoor_space*dr.2020-04-01.Addresses with private outdoor space": "21,851,072",
        "E12000008.private_outdoor_space*dr.2020-04-01.Addresses with private outdoor space": "3,541,846",
        "CSTM5160.access_park_playingfield.2020-04-01.Average distance to nearest Park, Public Garden, or Playing Field (m)": "307.54m",
        "E92000001.access_park_playingfield.2020-04-01.Average distance to nearest Park, Public Garden, or Playing Field (m)": "381.64m",
        "E12000008.access_park_playingfield.2020-04-01.Average distance to nearest Park, Public Garden, or Playing Field (m)": "390.00m",
        "CSTM5160.num_park_av.2020-04-01.Average number of Parks, Public Gardens, or Playing Fields within 1,000 m radius": "5",
        "E92000001.num_park_av.2020-04-01.Average number of Parks, Public Gardens, or Playing Fields within 1,000 m radius": "2",
        "E12000008.num_park_av.2020-04-01.Average number of Parks, Public Gardens, or Playing Fields within 1,000 m radius": "4",
        "CSTM5160.os_greenspace_total*dr.2017-05-01.Greenspace coverage, total": "820.8599",
        "E92000001.os_greenspace_total*dr.2017-05-01.Greenspace coverage, total": "289,963.2125",
        "E12000008.os_greenspace_total*dr.2017-05-01.Greenspace coverage, total": "60,916.8188",
        "CSTM5160.os_greenspace_parks_gardens*dr.2017-05-01.Greenspace coverage, public parks and gardens": "152.2120",
        "E92000001.os_greenspace_parks_gardens*dr.2017-05-01.Greenspace coverage, public parks and gardens": "103,328.2185",
        "E12000008.os_greenspace_parks_gardens*dr.2017-05-01.Greenspace coverage, public parks and gardens": "21,994.2367",
        "CSTM5160.ahah_blue_space.2024-09-01.AHAH Blue Space": "3.69",
        "E92000001.ahah_blue_space.2024-09-01.AHAH Blue Space": "4.02",
        "E12000008.ahah_blue_space.2024-09-01.AHAH Blue Space": "4.37",
        "CSTM5160.ahah_green_space_passive.2024-09-01.AHAH Green Space (passive)": "0.36",
        "E92000001.ahah_green_space_passive.2024-09-01.AHAH Green Space (passive)": "0.42",
        "E12000008.ahah_green_space_passive.2024-09-01.AHAH Green Space (passive)": "0.47",
        "CSTM5160.id19_airqualitynitrogennioxide.2016-01-01.IoD 2019 Nitrogen dioxide (component of air quality indicator)": "0.417",
        "E92000001.id19_airqualitynitrogennioxide.2016-01-01.IoD 2019 Nitrogen dioxide (component of air quality indicator)": "0.430",
        "E12000008.id19_airqualitynitrogennioxide.2016-01-01.IoD 2019 Nitrogen dioxide (component of air quality indicator)": "0.397",
        "CSTM5160.id19_airquality_benzene.2016-01-01.IoD 2019 Benzene (component of air quality indicator)": "0.120",
        "E92000001.id19_airquality_benzene.2016-01-01.IoD 2019 Benzene (component of air quality indicator)": "0.098",
        "E12000008.id19_airquality_benzene.2016-01-01.IoD 2019 Benzene (component of air quality indicator)": "0.108",
        "CSTM5160.id19_airquality_sulphurdioxide.2016-01-01.IoD 2019 Sulphur dioxide (component of air quality indicator)": "0.028",
        "E92000001.id19_airquality_sulphurdioxide.2016-01-01.IoD 2019 Sulphur dioxide (component of air quality indicator)": "0.034",
        "E12000008.id19_airquality_sulphurdioxide.2016-01-01.IoD 2019 Sulphur dioxide (component of air quality indicator)": "0.030",
        "CSTM5160.id19_airqualityparticulates.2016-01-01.IoD 2019 Particulates (component of air quality indicator)": "0.429",
        "E92000001.id19_airqualityparticulates.2016-01-01.IoD 2019 Particulates (component of air quality indicator)": "0.395",
        "E12000008.id19_airqualityparticulates.2016-01-01.IoD 2019 Particulates (component of air quality indicator)": "0.411",
        "CSTM5160.nfvi.2017-01-01.Neighbourhood Flood Vulnerability Index (NFVI)": "-0.34",
        "E92000001.nfvi.2017-01-01.Neighbourhood Flood Vulnerability Index (NFVI)": "-0.06",
        "E12000008.nfvi.2017-01-01.Neighbourhood Flood Vulnerability Index (NFVI)": "-0.38",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2023-09-01.Anti-social behaviour (12 month total)": "5,748",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2023-09-01.Anti-social behaviour (12 month total)": "786,681",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2023-09-01.Anti-social behaviour (12 month total)": "101,787",
        "CSTM5160.bicycle_theft_police_uk_12_month*dr.2023-09-01.Bicycle theft offences (12 month total)": "766",
        "E92000001.bicycle_theft_police_uk_12_month*dr.2023-09-01.Bicycle theft offences (12 month total)": "47,756",
        "E12000008.bicycle_theft_police_uk_12_month*dr.2023-09-01.Bicycle theft offences (12 month total)": "9,382",
        "CSTM5160.burglary_police_uk_12_month*dr.2023-09-01.Burglary (12 month total)": "1,095",
        "E92000001.burglary_police_uk_12_month*dr.2023-09-01.Burglary (12 month total)": "214,693",
        "E12000008.burglary_police_uk_12_month*dr.2023-09-01.Burglary (12 month total)": "29,574",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2023-09-01.Criminal damage (12 month total)": "2,561",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2023-09-01.Criminal damage (12 month total)": "386,622",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2023-09-01.Criminal damage (12 month total)": "66,704",
        "CSTM5160.drugs_police_uk_12_month*dr.2023-09-01.Drug crime offences (12 month total)": "1,339",
        "E92000001.drugs_police_uk_12_month*dr.2023-09-01.Drug crime offences (12 month total)": "135,391",
        "E12000008.drugs_police_uk_12_month*dr.2023-09-01.Drug crime offences (12 month total)": "21,588",
        "CSTM5160.other_crime_police_uk_12_month*dr.2023-09-01.Other crime offences (12 month total)": "709",
        "E92000001.other_crime_police_uk_12_month*dr.2023-09-01.Other crime offences (12 month total)": "101,595",
        "E12000008.other_crime_police_uk_12_month*dr.2023-09-01.Other crime offences (12 month total)": "16,829",
        "CSTM5160.other_theft_police_uk_12_month*dr.2023-09-01.Other theft offences (12 month total)": "3,024",
        "E92000001.other_theft_police_uk_12_month*dr.2023-09-01.Other theft offences (12 month total)": "366,286",
        "E12000008.other_theft_police_uk_12_month*dr.2023-09-01.Other theft offences (12 month total)": "57,190",
        "CSTM5160.possession_of_weapons_police_uk_12_month*dr.2023-09-01.Possession of weapons offences (12 month total)": "510",
        "E92000001.possession_of_weapons_police_uk_12_month*dr.2023-09-01.Possession of weapons offences (12 month total)": "47,232",
        "E12000008.possession_of_weapons_police_uk_12_month*dr.2023-09-01.Possession of weapons offences (12 month total)": "8,756",
        "CSTM5160.public_order_police_uk_12_month*dr.2023-09-01.Public order offences (12 month total)": "3,542",
        "E92000001.public_order_police_uk_12_month*dr.2023-09-01.Public order offences (12 month total)": "366,104",
        "E12000008.public_order_police_uk_12_month*dr.2023-09-01.Public order offences (12 month total)": "62,323",
        "CSTM5160.robbery_police_uk_12_month*dr.2023-09-01.Robbery recorded offences (12 month total)": "378",
        "E92000001.robbery_police_uk_12_month*dr.2023-09-01.Robbery recorded offences (12 month total)": "61,317",
        "E12000008.robbery_police_uk_12_month*dr.2023-09-01.Robbery recorded offences (12 month total)": "5,450",
        "CSTM5160.shoplifting_police_uk_12_month*dr.2023-09-01.Shoplifting offences (12 month total)": "4,400",
        "E92000001.shoplifting_police_uk_12_month*dr.2023-09-01.Shoplifting offences (12 month total)": "377,802",
        "E12000008.shoplifting_police_uk_12_month*dr.2023-09-01.Shoplifting offences (12 month total)": "68,182",
        "CSTM5160.theft_from_the_person_police_uk_12_month*dr.2023-09-01.Theft from the person offences (12 month total)": "645",
        "E92000001.theft_from_the_person_police_uk_12_month*dr.2023-09-01.Theft from the person offences (12 month total)": "91,796",
        "E12000008.theft_from_the_person_police_uk_12_month*dr.2023-09-01.Theft from the person offences (12 month total)": "6,707",
        "CSTM5160.vehicle_crime_police_uk_12_month*dr.2023-09-01.Vehicle crime (12 month total)": "1,497",
        "E92000001.vehicle_crime_police_uk_12_month*dr.2023-09-01.Vehicle crime (12 month total)": "304,623",
        "E12000008.vehicle_crime_police_uk_12_month*dr.2023-09-01.Vehicle crime (12 month total)": "45,678",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2023-09-01.Violent crime and sexual offences (12 month total)": "11,513",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2023-09-01.Violent crime and sexual offences (12 month total)": "1,778,535",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2023-09-01.Violent crime and sexual offences (12 month total)": "296,363",
        "CSTM5160.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total)": "35,276",
        "E92000001.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total)": "6,028,911",
        "E12000008.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total)": "812,423",
        "CSTM5160.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total)": "36,218",
        "E92000001.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total)": "5,898,641",
        "E12000008.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total)": "823,657",
        "CSTM5160.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total)": "37,004",
        "E92000001.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total)": "5,834,499",
        "E12000008.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total)": "847,180",
        "CSTM5160.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total)": "30,522",
        "E92000001.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total)": "4,772,989",
        "E12000008.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total)": "690,494",
        "CSTM5160.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total)": "37,787",
        "E92000001.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total)": "5,699,571",
        "E12000008.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total)": "843,571",
        "CSTM5160.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total)": "37,365",
        "E92000001.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total)": "5,716,663",
        "E12000008.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total)": "841,437",
        "CSTM5160.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total)": "35,960",
        "E92000001.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total)": "5,609,362",
        "E12000008.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total)": "825,443",
        "CSTM5160.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total)": "36,032",
        "E92000001.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total)": "5,543,184",
        "E12000008.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total)": "843,003",
        "CSTM5160.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total)": "34,943",
        "E92000001.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total)": "5,529,053",
        "E12000008.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total)": "838,902",
        "CSTM5160.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total)": "35,173",
        "E92000001.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total)": "5,230,810",
        "E12000008.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total)": "848,364",
        "CSTM5160.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total)": "35,567",
        "E92000001.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total)": "5,259,012",
        "E12000008.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total)": "857,722",
        "CSTM5160.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total)": "34,898",
        "E92000001.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total)": "5,312,778",
        "E12000008.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total)": "852,920",
        "CSTM5160.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total)": "33,946",
        "E92000001.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total)": "5,408,012",
        "E12000008.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total)": "850,501",
        "CSTM5160.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total)": "32,850",
        "E92000001.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total)": "5,511,899",
        "E12000008.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total)": "842,689",
        "CSTM5160.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total)": "32,574",
        "E92000001.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total)": "5,577,575",
        "E12000008.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total)": "837,782",
        "CSTM5160.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total)": "39,421",
        "E92000001.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total)": "6,791,273",
        "E12000008.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total)": "1,019,025",
        "CSTM5160.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total)": "47,038",
        "E92000001.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total)": "7,428,992",
        "E12000008.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total)": "1,112,823",
        "CSTM5160.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total)": "47,739",
        "E92000001.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total)": "7,690,836",
        "E12000008.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total)": "1,155,193",
        "CSTM5160.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total)": "48,115",
        "E92000001.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total)": "7,500,363",
        "E12000008.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total)": "1,135,971",
        "CSTM5160.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total)": "44,702",
        "E92000001.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total)": "6,746,334",
        "E12000008.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total)": "1,049,380",
        "CSTM5160.total_crime_police_uk_12_month*dr.2023-09-01.Total crime offences (12 month total)": "37,727",
        "E92000001.total_crime_police_uk_12_month*dr.2023-09-01.Total crime offences (12 month total)": "5,066,433",
        "E12000008.total_crime_police_uk_12_month*dr.2023-09-01.Total crime offences (12 month total)": "796,513",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2018-09-01.Anti-social behaviour (12 month total)": "7,385",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2018-09-01.Anti-social behaviour (12 month total)": "1,241,995",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2018-09-01.Anti-social behaviour (12 month total)": "135,026",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2018-12-01.Anti-social behaviour (12 month total)": "7,304",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2018-12-01.Anti-social behaviour (12 month total)": "1,210,837",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2018-12-01.Anti-social behaviour (12 month total)": "137,033",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2019-03-01.Anti-social behaviour (12 month total)": "7,241",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2019-03-01.Anti-social behaviour (12 month total)": "1,179,753",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2019-03-01.Anti-social behaviour (12 month total)": "139,707",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2019-06-01.Anti-social behaviour (12 month total)": "7,401",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2019-06-01.Anti-social behaviour (12 month total)": "1,154,012",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2019-06-01.Anti-social behaviour (12 month total)": "130,304",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2019-09-01.Anti-social behaviour (12 month total)": "10,039",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2019-09-01.Anti-social behaviour (12 month total)": "1,452,388",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2019-09-01.Anti-social behaviour (12 month total)": "171,354",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2019-12-01.Anti-social behaviour (12 month total)": "10,706",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2019-12-01.Anti-social behaviour (12 month total)": "1,542,235",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2019-12-01.Anti-social behaviour (12 month total)": "183,588",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2020-03-01.Anti-social behaviour (12 month total)": "11,288",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2020-03-01.Anti-social behaviour (12 month total)": "1,650,626",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2020-03-01.Anti-social behaviour (12 month total)": "199,877",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2020-06-01.Anti-social behaviour (12 month total)": "11,164",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2020-06-01.Anti-social behaviour (12 month total)": "1,491,520",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2020-06-01.Anti-social behaviour (12 month total)": "191,588",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2020-09-01.Anti-social behaviour (12 month total)": "10,279",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2020-09-01.Anti-social behaviour (12 month total)": "1,395,799",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2020-09-01.Anti-social behaviour (12 month total)": "177,347",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2020-12-01.Anti-social behaviour (12 month total)": "9,616",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2020-12-01.Anti-social behaviour (12 month total)": "1,238,712",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2020-12-01.Anti-social behaviour (12 month total)": "164,761",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2021-03-01.Anti-social behaviour (12 month total)": "9,009",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2021-03-01.Anti-social behaviour (12 month total)": "1,093,651",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2021-03-01.Anti-social behaviour (12 month total)": "145,433",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2021-06-01.Anti-social behaviour (12 month total)": "7,145",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2021-06-01.Anti-social behaviour (12 month total)": "1,000,179",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2021-06-01.Anti-social behaviour (12 month total)": "126,581",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2021-09-01.Anti-social behaviour (12 month total)": "5,971",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2021-09-01.Anti-social behaviour (12 month total)": "945,081",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2021-09-01.Anti-social behaviour (12 month total)": "117,536",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2021-12-01.Anti-social behaviour (12 month total)": "5,122",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2021-12-01.Anti-social behaviour (12 month total)": "937,764",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2021-12-01.Anti-social behaviour (12 month total)": "110,833",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2022-03-01.Anti-social behaviour (12 month total)": "4,421",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2022-03-01.Anti-social behaviour (12 month total)": "903,840",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2022-03-01.Anti-social behaviour (12 month total)": "104,898",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2022-06-01.Anti-social behaviour (12 month total)": "4,866",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2022-06-01.Anti-social behaviour (12 month total)": "1,022,013",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2022-06-01.Anti-social behaviour (12 month total)": "120,275",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2022-09-01.Anti-social behaviour (12 month total)": "5,642",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2022-09-01.Anti-social behaviour (12 month total)": "1,147,384",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2022-09-01.Anti-social behaviour (12 month total)": "132,710",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2022-12-01.Anti-social behaviour (12 month total)": "5,915",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2022-12-01.Anti-social behaviour (12 month total)": "1,111,848",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2022-12-01.Anti-social behaviour (12 month total)": "131,138",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2023-03-01.Anti-social behaviour (12 month total)": "6,216",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2023-03-01.Anti-social behaviour (12 month total)": "1,086,606",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2023-03-01.Anti-social behaviour (12 month total)": "130,535",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total)": "6,312",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total)": "1,002,698",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total)": "128,143",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2018-09-01.Violent crime and sexual offences (12 month total)": "9,583",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2018-09-01.Violent crime and sexual offences (12 month total)": "1,681,851",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2018-09-01.Violent crime and sexual offences (12 month total)": "254,836",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2018-12-01.Violent crime and sexual offences (12 month total)": "9,941",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2018-12-01.Violent crime and sexual offences (12 month total)": "1,672,021",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2018-12-01.Violent crime and sexual offences (12 month total)": "260,194",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2019-03-01.Violent crime and sexual offences (12 month total)": "10,304",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2019-03-01.Violent crime and sexual offences (12 month total)": "1,686,062",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2019-03-01.Violent crime and sexual offences (12 month total)": "271,592",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2019-06-01.Violent crime and sexual offences (12 month total)": "8,338",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2019-06-01.Violent crime and sexual offences (12 month total)": "1,372,550",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2019-06-01.Violent crime and sexual offences (12 month total)": "223,022",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2019-09-01.Violent crime and sexual offences (12 month total)": "10,253",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2019-09-01.Violent crime and sexual offences (12 month total)": "1,652,889",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2019-09-01.Violent crime and sexual offences (12 month total)": "276,150",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2019-12-01.Violent crime and sexual offences (12 month total)": "9,992",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2019-12-01.Violent crime and sexual offences (12 month total)": "1,668,836",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2019-12-01.Violent crime and sexual offences (12 month total)": "277,806",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2020-03-01.Violent crime and sexual offences (12 month total)": "9,389",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2020-03-01.Violent crime and sexual offences (12 month total)": "1,640,200",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2020-03-01.Violent crime and sexual offences (12 month total)": "272,217",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2020-06-01.Violent crime and sexual offences (12 month total)": "9,608",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2020-06-01.Violent crime and sexual offences (12 month total)": "1,701,177",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2020-06-01.Violent crime and sexual offences (12 month total)": "286,507",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2020-09-01.Violent crime and sexual offences (12 month total)": "9,586",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2020-09-01.Violent crime and sexual offences (12 month total)": "1,752,799",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2020-09-01.Violent crime and sexual offences (12 month total)": "293,473",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2020-12-01.Violent crime and sexual offences (12 month total)": "10,237",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2020-12-01.Violent crime and sexual offences (12 month total)": "1,702,735",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2020-12-01.Violent crime and sexual offences (12 month total)": "306,547",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2021-03-01.Violent crime and sexual offences (12 month total)": "10,754",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2021-03-01.Violent crime and sexual offences (12 month total)": "1,781,300",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2021-03-01.Violent crime and sexual offences (12 month total)": "321,472",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2021-06-01.Violent crime and sexual offences (12 month total)": "10,997",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2021-06-01.Violent crime and sexual offences (12 month total)": "1,828,885",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2021-06-01.Violent crime and sexual offences (12 month total)": "326,386",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2021-09-01.Violent crime and sexual offences (12 month total)": "10,993",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2021-09-01.Violent crime and sexual offences (12 month total)": "1,878,576",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2021-09-01.Violent crime and sexual offences (12 month total)": "326,256",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2021-12-01.Violent crime and sexual offences (12 month total)": "10,463",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2021-12-01.Violent crime and sexual offences (12 month total)": "1,907,738",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2021-12-01.Violent crime and sexual offences (12 month total)": "322,553",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2022-03-01.Violent crime and sexual offences (12 month total)": "10,355",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2022-03-01.Violent crime and sexual offences (12 month total)": "1,926,592",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2022-03-01.Violent crime and sexual offences (12 month total)": "318,424",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2022-06-01.Violent crime and sexual offences (12 month total)": "12,612",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2022-06-01.Violent crime and sexual offences (12 month total)": "2,355,620",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2022-06-01.Violent crime and sexual offences (12 month total)": "388,119",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2022-09-01.Violent crime and sexual offences (12 month total)": "14,875",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2022-09-01.Violent crime and sexual offences (12 month total)": "2,589,490",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2022-09-01.Violent crime and sexual offences (12 month total)": "424,924",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2022-12-01.Violent crime and sexual offences (12 month total)": "15,065",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2022-12-01.Violent crime and sexual offences (12 month total)": "2,667,426",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2022-12-01.Violent crime and sexual offences (12 month total)": "440,223",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2023-03-01.Violent crime and sexual offences (12 month total)": "15,314",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2023-03-01.Violent crime and sexual offences (12 month total)": "2,614,495",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2023-03-01.Violent crime and sexual offences (12 month total)": "432,799",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total)": "13,882",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total)": "2,348,974",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total)": "391,815",
        "CSTM5160.burglary_police_uk_12_month*dr.2018-09-01.Burglary (12 month total)": null,
        "E92000001.burglary_police_uk_12_month*dr.2018-09-01.Burglary (12 month total)": null,
        "E12000008.burglary_police_uk_12_month*dr.2018-09-01.Burglary (12 month total)": null,
        "CSTM5160.burglary_police_uk_12_month*dr.2018-12-01.Burglary (12 month total)": null,
        "E92000001.burglary_police_uk_12_month*dr.2018-12-01.Burglary (12 month total)": null,
        "E12000008.burglary_police_uk_12_month*dr.2018-12-01.Burglary (12 month total)": null,
        "CSTM5160.burglary_police_uk_12_month*dr.2019-03-01.Burglary (12 month total)": null,
        "E92000001.burglary_police_uk_12_month*dr.2019-03-01.Burglary (12 month total)": null,
        "E12000008.burglary_police_uk_12_month*dr.2019-03-01.Burglary (12 month total)": null,
        "CSTM5160.burglary_police_uk_12_month*dr.2019-06-01.Burglary (12 month total)": null,
        "E92000001.burglary_police_uk_12_month*dr.2019-06-01.Burglary (12 month total)": null,
        "E12000008.burglary_police_uk_12_month*dr.2019-06-01.Burglary (12 month total)": null,
        "CSTM5160.burglary_police_uk_12_month*dr.2019-09-01.Burglary (12 month total)": null,
        "E92000001.burglary_police_uk_12_month*dr.2019-09-01.Burglary (12 month total)": null,
        "E12000008.burglary_police_uk_12_month*dr.2019-09-01.Burglary (12 month total)": null,
        "CSTM5160.burglary_police_uk_12_month*dr.2019-12-01.Burglary (12 month total)": null,
        "E92000001.burglary_police_uk_12_month*dr.2019-12-01.Burglary (12 month total)": null,
        "E12000008.burglary_police_uk_12_month*dr.2019-12-01.Burglary (12 month total)": null,
        "CSTM5160.burglary_police_uk_12_month*dr.2020-03-01.Burglary (12 month total)": "1,052",
        "E92000001.burglary_police_uk_12_month*dr.2020-03-01.Burglary (12 month total)": "237,343",
        "E12000008.burglary_police_uk_12_month*dr.2020-03-01.Burglary (12 month total)": "31,668",
        "CSTM5160.burglary_police_uk_12_month*dr.2020-06-01.Burglary (12 month total)": "916",
        "E92000001.burglary_police_uk_12_month*dr.2020-06-01.Burglary (12 month total)": "227,250",
        "E12000008.burglary_police_uk_12_month*dr.2020-06-01.Burglary (12 month total)": "30,552",
        "CSTM5160.burglary_police_uk_12_month*dr.2020-09-01.Burglary (12 month total)": "876",
        "E92000001.burglary_police_uk_12_month*dr.2020-09-01.Burglary (12 month total)": "221,627",
        "E12000008.burglary_police_uk_12_month*dr.2020-09-01.Burglary (12 month total)": "29,433",
        "CSTM5160.burglary_police_uk_12_month*dr.2020-12-01.Burglary (12 month total)": "852",
        "E92000001.burglary_police_uk_12_month*dr.2020-12-01.Burglary (12 month total)": "203,184",
        "E12000008.burglary_police_uk_12_month*dr.2020-12-01.Burglary (12 month total)": "28,707",
        "CSTM5160.burglary_police_uk_12_month*dr.2021-03-01.Burglary (12 month total)": "873",
        "E92000001.burglary_police_uk_12_month*dr.2021-03-01.Burglary (12 month total)": "207,216",
        "E12000008.burglary_police_uk_12_month*dr.2021-03-01.Burglary (12 month total)": "29,464",
        "CSTM5160.burglary_police_uk_12_month*dr.2021-06-01.Burglary (12 month total)": "913",
        "E92000001.burglary_police_uk_12_month*dr.2021-06-01.Burglary (12 month total)": "215,022",
        "E12000008.burglary_police_uk_12_month*dr.2021-06-01.Burglary (12 month total)": "30,074",
        "CSTM5160.burglary_police_uk_12_month*dr.2021-09-01.Burglary (12 month total)": "935",
        "E92000001.burglary_police_uk_12_month*dr.2021-09-01.Burglary (12 month total)": "221,962",
        "E12000008.burglary_police_uk_12_month*dr.2021-09-01.Burglary (12 month total)": "30,670",
        "CSTM5160.burglary_police_uk_12_month*dr.2021-12-01.Burglary (12 month total)": "981",
        "E92000001.burglary_police_uk_12_month*dr.2021-12-01.Burglary (12 month total)": "228,887",
        "E12000008.burglary_police_uk_12_month*dr.2021-12-01.Burglary (12 month total)": "31,488",
        "CSTM5160.burglary_police_uk_12_month*dr.2022-03-01.Burglary (12 month total)": "1,047",
        "E92000001.burglary_police_uk_12_month*dr.2022-03-01.Burglary (12 month total)": "234,621",
        "E12000008.burglary_police_uk_12_month*dr.2022-03-01.Burglary (12 month total)": "31,542",
        "CSTM5160.burglary_police_uk_12_month*dr.2022-06-01.Burglary (12 month total)": "1,346",
        "E92000001.burglary_police_uk_12_month*dr.2022-06-01.Burglary (12 month total)": "293,926",
        "E12000008.burglary_police_uk_12_month*dr.2022-06-01.Burglary (12 month total)": "38,587",
        "CSTM5160.burglary_police_uk_12_month*dr.2022-09-01.Burglary (12 month total)": "1,628",
        "E92000001.burglary_police_uk_12_month*dr.2022-09-01.Burglary (12 month total)": "351,081",
        "E12000008.burglary_police_uk_12_month*dr.2022-09-01.Burglary (12 month total)": "44,705",
        "CSTM5160.burglary_police_uk_12_month*dr.2022-12-01.Burglary (12 month total)": "1,629",
        "E92000001.burglary_police_uk_12_month*dr.2022-12-01.Burglary (12 month total)": "343,763",
        "E12000008.burglary_police_uk_12_month*dr.2022-12-01.Burglary (12 month total)": "43,395",
        "CSTM5160.burglary_police_uk_12_month*dr.2023-03-01.Burglary (12 month total)": "1,556",
        "E92000001.burglary_police_uk_12_month*dr.2023-03-01.Burglary (12 month total)": "334,898",
        "E12000008.burglary_police_uk_12_month*dr.2023-03-01.Burglary (12 month total)": "42,867",
        "CSTM5160.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total)": "1,367",
        "E92000001.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total)": "292,148",
        "E12000008.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total)": "39,183",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2018-09-01.Criminal damage (12 month total)": "2,753",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2018-09-01.Criminal damage (12 month total)": "504,876",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2018-09-01.Criminal damage (12 month total)": "79,116",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2018-12-01.Criminal damage (12 month total)": "2,813",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2018-12-01.Criminal damage (12 month total)": "490,826",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2018-12-01.Criminal damage (12 month total)": "79,331",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2019-03-01.Criminal damage (12 month total)": "2,891",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2019-03-01.Criminal damage (12 month total)": "483,666",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2019-03-01.Criminal damage (12 month total)": "81,221",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2019-06-01.Criminal damage (12 month total)": "2,319",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2019-06-01.Criminal damage (12 month total)": "379,203",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2019-06-01.Criminal damage (12 month total)": "63,956",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2019-09-01.Criminal damage (12 month total)": "2,959",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2019-09-01.Criminal damage (12 month total)": "442,414",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2019-09-01.Criminal damage (12 month total)": "76,608",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2019-12-01.Criminal damage (12 month total)": "2,998",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2019-12-01.Criminal damage (12 month total)": "435,185",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2019-12-01.Criminal damage (12 month total)": "74,586",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2020-03-01.Criminal damage (12 month total)": "2,904",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2020-03-01.Criminal damage (12 month total)": "411,537",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2020-03-01.Criminal damage (12 month total)": "69,848",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2020-06-01.Criminal damage (12 month total)": "2,909",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2020-06-01.Criminal damage (12 month total)": "422,146",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2020-06-01.Criminal damage (12 month total)": "72,363",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2020-09-01.Criminal damage (12 month total)": "2,691",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2020-09-01.Criminal damage (12 month total)": "424,625",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2020-09-01.Criminal damage (12 month total)": "71,652",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2020-12-01.Criminal damage (12 month total)": "2,515",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2020-12-01.Criminal damage (12 month total)": "407,551",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2020-12-01.Criminal damage (12 month total)": "72,351",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2021-03-01.Criminal damage (12 month total)": "2,471",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2021-03-01.Criminal damage (12 month total)": "423,332",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2021-03-01.Criminal damage (12 month total)": "74,332",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2021-06-01.Criminal damage (12 month total)": "2,642",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2021-06-01.Criminal damage (12 month total)": "431,407",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2021-06-01.Criminal damage (12 month total)": "75,116",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2021-09-01.Criminal damage (12 month total)": "2,601",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2021-09-01.Criminal damage (12 month total)": "440,770",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2021-09-01.Criminal damage (12 month total)": "75,898",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2021-12-01.Criminal damage (12 month total)": "2,649",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2021-12-01.Criminal damage (12 month total)": "442,499",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2021-12-01.Criminal damage (12 month total)": "74,971",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2022-03-01.Criminal damage (12 month total)": "2,674",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2022-03-01.Criminal damage (12 month total)": "442,674",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2022-03-01.Criminal damage (12 month total)": "74,301",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2022-06-01.Criminal damage (12 month total)": "3,135",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2022-06-01.Criminal damage (12 month total)": "535,296",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2022-06-01.Criminal damage (12 month total)": "89,701",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2022-09-01.Criminal damage (12 month total)": "3,741",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2022-09-01.Criminal damage (12 month total)": "619,073",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2022-09-01.Criminal damage (12 month total)": "103,975",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2022-12-01.Criminal damage (12 month total)": "3,683",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2022-12-01.Criminal damage (12 month total)": "603,633",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2022-12-01.Criminal damage (12 month total)": "101,280",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2023-03-01.Criminal damage (12 month total)": "3,642",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2023-03-01.Criminal damage (12 month total)": "588,991",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2023-03-01.Criminal damage (12 month total)": "99,190",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total)": "3,197",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total)": "515,930",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total)": "89,259",
        "CSTM5160.census11_p04_65_69*dr.2021-01-01.People aged 65-69 (Census 2021)": "10,915",
        "E92000001.census11_p04_65_69*dr.2021-01-01.People aged 65-69 (Census 2021)": "2,767,496",
        "E12000008.census11_p04_65_69*dr.2021-01-01.People aged 65-69 (Census 2021)": "462,272",
        "CSTM5160.census11_p04_70_74*dr.2021-01-01.People aged 70-74 (Census 2021)": "10,272",
        "E92000001.census11_p04_70_74*dr.2021-01-01.People aged 70-74 (Census 2021)": "2,796,647",
        "E12000008.census11_p04_70_74*dr.2021-01-01.People aged 70-74 (Census 2021)": "481,549",
        "CSTM5160.census11_p04_75_79*dr.2021-01-01.People aged 75-79 (Census 2021)": "7,113",
        "E92000001.census11_p04_75_79*dr.2021-01-01.People aged 75-79 (Census 2021)": "2,038,774",
        "E12000008.census11_p04_75_79*dr.2021-01-01.People aged 75-79 (Census 2021)": "357,688",
        "CSTM5160.census11_p04_80_84*dr.2021-01-01.People aged 80-84 (Census 2021)": "5,129",
        "E92000001.census11_p04_80_84*dr.2021-01-01.People aged 80-84 (Census 2021)": "1,426,085",
        "E12000008.census11_p04_80_84*dr.2021-01-01.People aged 80-84 (Census 2021)": "249,372",
        "CSTM5160.census11_p04_85_89*dr.2021-01-01.People aged 85-89 (Census 2021)": "3,400",
        "E92000001.census11_p04_85_89*dr.2021-01-01.People aged 85-89 (Census 2021)": "872,200",
        "E12000008.census11_p04_85_89*dr.2021-01-01.People aged 85-89 (Census 2021)": "157,800",
        "CSTM5160.census11_p04_90pl*dr.2021-01-01.People aged 90 and over (Census 2021)": "2,200",
        "E92000001.census11_p04_90pl*dr.2021-01-01.People aged 90 and over (Census 2021)": "498,200",
        "E12000008.census11_p04_90pl*dr.2021-01-01.People aged 90 and over (Census 2021)": "95,300",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2001-01-01.Population aged 65+": "40,350",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2001-01-01.Population aged 65+": "7,834,148",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2001-01-01.Population aged 65+": "1,310,810",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2002-01-01.Population aged 65+": "39,566",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2002-01-01.Population aged 65+": "7,881,163",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2002-01-01.Population aged 65+": "1,318,062",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2003-01-01.Population aged 65+": "38,751",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2003-01-01.Population aged 65+": "7,929,309",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2003-01-01.Population aged 65+": "1,326,378",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2004-01-01.Population aged 65+": "37,981",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2004-01-01.Population aged 65+": "7,976,091",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2004-01-01.Population aged 65+": "1,336,004",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2005-01-01.Population aged 65+": "37,508",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2005-01-01.Population aged 65+": "8,028,281",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2005-01-01.Population aged 65+": "1,347,797",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2006-01-01.Population aged 65+": "36,733",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2006-01-01.Population aged 65+": "8,054,801",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2006-01-01.Population aged 65+": "1,354,557",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2007-01-01.Population aged 65+": "35,999",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2007-01-01.Population aged 65+": "8,125,455",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2007-01-01.Population aged 65+": "1,372,174",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2008-01-01.Population aged 65+": "35,672",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2008-01-01.Population aged 65+": "8,250,245",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2008-01-01.Population aged 65+": "1,398,445",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2009-01-01.Population aged 65+": "35,532",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2009-01-01.Population aged 65+": "8,400,551",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2009-01-01.Population aged 65+": "1,428,454",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2010-01-01.Population aged 65+": "35,618",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2010-01-01.Population aged 65+": "8,563,865",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2010-01-01.Population aged 65+": "1,461,781",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2011-01-01.Population aged 65+": "35,849",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2011-01-01.Population aged 65+": "8,729,933",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2011-01-01.Population aged 65+": "1,495,555",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2012-01-01.Population aged 65+": "36,609",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2012-01-01.Population aged 65+": "9,056,205",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2012-01-01.Population aged 65+": "1,557,722",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2013-01-01.Population aged 65+": "37,197",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2013-01-01.Population aged 65+": "9,304,855",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2013-01-01.Population aged 65+": "1,605,211",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2014-01-01.Population aged 65+": "37,736",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2014-01-01.Population aged 65+": "9,537,380",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2014-01-01.Population aged 65+": "1,648,827",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2015-01-01.Population aged 65+": "38,078",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2015-01-01.Population aged 65+": "9,711,496",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2015-01-01.Population aged 65+": "1,679,755",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2016-01-01.Population aged 65+": "38,373",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2016-01-01.Population aged 65+": "9,882,773",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2016-01-01.Population aged 65+": "1,710,668",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2017-01-01.Population aged 65+": "38,330",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2017-01-01.Population aged 65+": "10,030,489",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2017-01-01.Population aged 65+": "1,735,767",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2018-01-01.Population aged 65+": "38,504",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2018-01-01.Population aged 65+": "10,179,230",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2018-01-01.Population aged 65+": "1,761,765",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2019-01-01.Population aged 65+": "38,839",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2019-01-01.Population aged 65+": "10,250,828",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2019-01-01.Population aged 65+": "1,688,354",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2020-01-01.Population aged 65+": "39,017",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2020-01-01.Population aged 65+": "10,359,881",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2020-01-01.Population aged 65+": "1,707,887",
        "CSTM5160.pc_total*dr.2024-02-01.Pensioners in poverty (Pension Credit)": "5,786",
        "E92000001.pc_total*dr.2024-02-01.Pensioners in poverty (Pension Credit)": "1,153,410",
        "E12000008.pc_total*dr.2024-02-01.Pensioners in poverty (Pension Credit)": "147,763",
        "CSTM5160.pc_female*dr.2024-02-01.Pension Credit claimants, female": "3,537",
        "E92000001.pc_female*dr.2024-02-01.Pension Credit claimants, female": "762,943",
        "E12000008.pc_female*dr.2024-02-01.Pension Credit claimants, female": "99,085",
        "CSTM5160.pc_male*dr.2024-02-01.Pension Credit claimants, male": "2,248",
        "E92000001.pc_male*dr.2024-02-01.Pension Credit claimants, male": "390,465",
        "E12000008.pc_male*dr.2024-02-01.Pension Credit claimants, male": "48,679",
        "CSTM5160.pc_gc*dr.2024-02-01.Pension Credit claimants, Guarantee Element": "3,627",
        "E92000001.pc_gc*dr.2024-02-01.Pension Credit claimants, Guarantee Element": "653,838",
        "E12000008.pc_gc*dr.2024-02-01.Pension Credit claimants, Guarantee Element": "84,012",
        "CSTM5160.pc_gcandsc*dr.2024-02-01.Pension Credit claimants, Guarantee and Saving Element": "1,554",
        "E92000001.pc_gcandsc*dr.2024-02-01.Pension Credit claimants, Guarantee and Saving Element": "351,728",
        "E12000008.pc_gcandsc*dr.2024-02-01.Pension Credit claimants, Guarantee and Saving Element": "44,009",
        "CSTM5160.pc_sc*dr.2024-02-01.Pension Credit claimants, Saving Element": "603",
        "E92000001.pc_sc*dr.2024-02-01.Pension Credit claimants, Saving Element": "147,843",
        "E12000008.pc_sc*dr.2024-02-01.Pension Credit claimants, Saving Element": "19,741",
        "CSTM5160.pc_single*dr.2024-02-01.Pension Credit claimants who are single": "5,266",
        "E92000001.pc_single*dr.2024-02-01.Pension Credit claimants who are single": "1,002,157",
        "E12000008.pc_single*dr.2024-02-01.Pension Credit claimants who are single": "129,304",
        "CSTM5160.pc_withpartner*dr.2024-02-01.Pension Credit claimants who are with partner": "525",
        "E92000001.pc_withpartner*dr.2024-02-01.Pension Credit claimants who are with partner": "151,253",
        "E12000008.pc_withpartner*dr.2024-02-01.Pension Credit claimants who are with partner": "18,461",
        "CSTM5160.pc_total*dr.2010-11-01.Pensioners in poverty (Pension Credit)": "11,540",
        "E92000001.pc_total*dr.2010-11-01.Pensioners in poverty (Pension Credit)": "2,279,790",
        "E12000008.pc_total*dr.2010-11-01.Pensioners in poverty (Pension Credit)": "287,355",
        "CSTM5160.pc_total*dr.2011-02-01.Pensioners in poverty (Pension Credit)": "11,355",
        "E92000001.pc_total*dr.2011-02-01.Pensioners in poverty (Pension Credit)": "2,260,835",
        "E12000008.pc_total*dr.2011-02-01.Pensioners in poverty (Pension Credit)": "284,365",
        "CSTM5160.pc_total*dr.2011-05-01.Pensioners in poverty (Pension Credit)": "11,210",
        "E92000001.pc_total*dr.2011-05-01.Pensioners in poverty (Pension Credit)": "2,245,895",
        "E12000008.pc_total*dr.2011-05-01.Pensioners in poverty (Pension Credit)": "282,720",
        "CSTM5160.pc_total*dr.2011-08-01.Pensioners in poverty (Pension Credit)": "11,175",
        "E92000001.pc_total*dr.2011-08-01.Pensioners in poverty (Pension Credit)": "2,232,660",
        "E12000008.pc_total*dr.2011-08-01.Pensioners in poverty (Pension Credit)": "281,610",
        "CSTM5160.pc_total*dr.2011-11-01.Pensioners in poverty (Pension Credit)": "11,105",
        "E92000001.pc_total*dr.2011-11-01.Pensioners in poverty (Pension Credit)": "2,220,995",
        "E12000008.pc_total*dr.2011-11-01.Pensioners in poverty (Pension Credit)": "280,145",
        "CSTM5160.pc_total*dr.2012-02-01.Pensioners in poverty (Pension Credit)": "10,975",
        "E92000001.pc_total*dr.2012-02-01.Pensioners in poverty (Pension Credit)": "2,196,350",
        "E12000008.pc_total*dr.2012-02-01.Pensioners in poverty (Pension Credit)": "277,240",
        "CSTM5160.pc_total*dr.2012-05-01.Pensioners in poverty (Pension Credit)": "10,715",
        "E92000001.pc_total*dr.2012-05-01.Pensioners in poverty (Pension Credit)": "2,135,450",
        "E12000008.pc_total*dr.2012-05-01.Pensioners in poverty (Pension Credit)": "269,780",
        "CSTM5160.pc_total*dr.2012-08-01.Pensioners in poverty (Pension Credit)": "10,580",
        "E92000001.pc_total*dr.2012-08-01.Pensioners in poverty (Pension Credit)": "2,116,205",
        "E12000008.pc_total*dr.2012-08-01.Pensioners in poverty (Pension Credit)": "267,480",
        "CSTM5160.pc_total*dr.2012-11-01.Pensioners in poverty (Pension Credit)": "10,525",
        "E92000001.pc_total*dr.2012-11-01.Pensioners in poverty (Pension Credit)": "2,099,310",
        "E12000008.pc_total*dr.2012-11-01.Pensioners in poverty (Pension Credit)": "265,520",
        "CSTM5160.pc_total*dr.2013-02-01.Pensioners in poverty (Pension Credit)": "10,400",
        "E92000001.pc_total*dr.2013-02-01.Pensioners in poverty (Pension Credit)": "2,071,945",
        "E12000008.pc_total*dr.2013-02-01.Pensioners in poverty (Pension Credit)": "261,930",
        "CSTM5160.pc_total*dr.2013-05-01.Pensioners in poverty (Pension Credit)": "10,220",
        "E92000001.pc_total*dr.2013-05-01.Pensioners in poverty (Pension Credit)": "2,029,680",
        "E12000008.pc_total*dr.2013-05-01.Pensioners in poverty (Pension Credit)": "256,700",
        "CSTM5160.pc_total*dr.2013-08-01.Pensioners in poverty (Pension Credit)": "10,105",
        "E92000001.pc_total*dr.2013-08-01.Pensioners in poverty (Pension Credit)": "2,010,855",
        "E12000008.pc_total*dr.2013-08-01.Pensioners in poverty (Pension Credit)": "254,475",
        "CSTM5160.pc_total*dr.2013-11-01.Pensioners in poverty (Pension Credit)": "10,040",
        "E92000001.pc_total*dr.2013-11-01.Pensioners in poverty (Pension Credit)": "1,994,675",
        "E12000008.pc_total*dr.2013-11-01.Pensioners in poverty (Pension Credit)": "252,405",
        "CSTM5160.pc_total*dr.2014-02-01.Pensioners in poverty (Pension Credit)": "9,895",
        "E92000001.pc_total*dr.2014-02-01.Pensioners in poverty (Pension Credit)": "1,969,370",
        "E12000008.pc_total*dr.2014-02-01.Pensioners in poverty (Pension Credit)": "249,125",
        "CSTM5160.pc_total*dr.2014-05-01.Pensioners in poverty (Pension Credit)": "9,570",
        "E92000001.pc_total*dr.2014-05-01.Pensioners in poverty (Pension Credit)": "1,910,850",
        "E12000008.pc_total*dr.2014-05-01.Pensioners in poverty (Pension Credit)": "241,230",
        "CSTM5160.pc_total*dr.2014-08-01.Pensioners in poverty (Pension Credit)": "9,540",
        "E92000001.pc_total*dr.2014-08-01.Pensioners in poverty (Pension Credit)": "1,888,310",
        "E12000008.pc_total*dr.2014-08-01.Pensioners in poverty (Pension Credit)": "238,665",
        "CSTM5160.pc_total*dr.2014-11-01.Pensioners in poverty (Pension Credit)": "9,405",
        "E92000001.pc_total*dr.2014-11-01.Pensioners in poverty (Pension Credit)": "1,868,820",
        "E12000008.pc_total*dr.2014-11-01.Pensioners in poverty (Pension Credit)": "236,300",
        "CSTM5160.pc_total*dr.2015-02-01.Pensioners in poverty (Pension Credit)": "9,230",
        "E92000001.pc_total*dr.2015-02-01.Pensioners in poverty (Pension Credit)": "1,834,535",
        "E12000008.pc_total*dr.2015-02-01.Pensioners in poverty (Pension Credit)": "231,735",
        "CSTM5160.pc_total*dr.2015-05-01.Pensioners in poverty (Pension Credit)": "8,935",
        "E92000001.pc_total*dr.2015-05-01.Pensioners in poverty (Pension Credit)": "1,766,560",
        "E12000008.pc_total*dr.2015-05-01.Pensioners in poverty (Pension Credit)": "223,015",
        "CSTM5160.pc_total*dr.2015-08-01.Pensioners in poverty (Pension Credit)": "8,845",
        "E92000001.pc_total*dr.2015-08-01.Pensioners in poverty (Pension Credit)": "1,743,145",
        "E12000008.pc_total*dr.2015-08-01.Pensioners in poverty (Pension Credit)": "219,960",
        "CSTM5160.pc_total*dr.2015-11-01.Pensioners in poverty (Pension Credit)": "8,785",
        "E92000001.pc_total*dr.2015-11-01.Pensioners in poverty (Pension Credit)": "1,722,860",
        "E12000008.pc_total*dr.2015-11-01.Pensioners in poverty (Pension Credit)": "217,215",
        "CSTM5160.pc_total*dr.2016-02-01.Pensioners in poverty (Pension Credit)": "8,655",
        "E92000001.pc_total*dr.2016-02-01.Pensioners in poverty (Pension Credit)": "1,695,790",
        "E12000008.pc_total*dr.2016-02-01.Pensioners in poverty (Pension Credit)": "213,670",
        "CSTM5160.pc_total*dr.2016-05-01.Pensioners in poverty (Pension Credit)": "8,425",
        "E92000001.pc_total*dr.2016-05-01.Pensioners in poverty (Pension Credit)": "1,643,740",
        "E12000008.pc_total*dr.2016-05-01.Pensioners in poverty (Pension Credit)": "207,145",
        "CSTM5160.pc_total*dr.2016-08-01.Pensioners in poverty (Pension Credit)": "8,225",
        "E92000001.pc_total*dr.2016-08-01.Pensioners in poverty (Pension Credit)": "1,619,115",
        "E12000008.pc_total*dr.2016-08-01.Pensioners in poverty (Pension Credit)": "204,415",
        "CSTM5160.pc_total*dr.2016-11-01.Pensioners in poverty (Pension Credit)": "8,105",
        "E92000001.pc_total*dr.2016-11-01.Pensioners in poverty (Pension Credit)": "1,595,935",
        "E12000008.pc_total*dr.2016-11-01.Pensioners in poverty (Pension Credit)": "201,170",
        "CSTM5160.pc_total*dr.2017-02-01.Pensioners in poverty (Pension Credit)": "7,905",
        "E92000001.pc_total*dr.2017-02-01.Pensioners in poverty (Pension Credit)": "1,562,410",
        "E12000008.pc_total*dr.2017-02-01.Pensioners in poverty (Pension Credit)": "196,780",
        "CSTM5160.pc_total*dr.2017-05-01.Pensioners in poverty (Pension Credit)": "7,860",
        "E92000001.pc_total*dr.2017-05-01.Pensioners in poverty (Pension Credit)": "1,537,715",
        "E12000008.pc_total*dr.2017-05-01.Pensioners in poverty (Pension Credit)": "194,105",
        "CSTM5160.pc_total*dr.2017-08-01.Pensioners in poverty (Pension Credit)": "7,730",
        "E92000001.pc_total*dr.2017-08-01.Pensioners in poverty (Pension Credit)": "1,514,905",
        "E12000008.pc_total*dr.2017-08-01.Pensioners in poverty (Pension Credit)": "191,415",
        "CSTM5160.pc_total*dr.2017-11-01.Pensioners in poverty (Pension Credit)": "7,545",
        "E92000001.pc_total*dr.2017-11-01.Pensioners in poverty (Pension Credit)": "1,490,660",
        "E12000008.pc_total*dr.2017-11-01.Pensioners in poverty (Pension Credit)": "188,035",
        "CSTM5160.pc_total*dr.2018-02-01.Pensioners in poverty (Pension Credit)": "7,370",
        "E92000001.pc_total*dr.2018-02-01.Pensioners in poverty (Pension Credit)": "1,458,300",
        "E12000008.pc_total*dr.2018-02-01.Pensioners in poverty (Pension Credit)": "183,805",
        "CSTM5160.pc_total*dr.2018-05-01.Pensioners in poverty (Pension Credit)": "7,245",
        "E92000001.pc_total*dr.2018-05-01.Pensioners in poverty (Pension Credit)": "1,428,500",
        "E12000008.pc_total*dr.2018-05-01.Pensioners in poverty (Pension Credit)": "180,185",
        "CSTM5160.pc_total*dr.2018-08-01.Pensioners in poverty (Pension Credit)": "7,130",
        "E92000001.pc_total*dr.2018-08-01.Pensioners in poverty (Pension Credit)": "1,407,590",
        "E12000008.pc_total*dr.2018-08-01.Pensioners in poverty (Pension Credit)": "177,380",
        "CSTM5160.pc_total*dr.2018-11-01.Pensioners in poverty (Pension Credit)": "7,035",
        "E92000001.pc_total*dr.2018-11-01.Pensioners in poverty (Pension Credit)": "1,388,085",
        "E12000008.pc_total*dr.2018-11-01.Pensioners in poverty (Pension Credit)": "175,070",
        "CSTM5160.pc_total*dr.2019-02-01.Pensioners in poverty (Pension Credit)": "6,894",
        "E92000001.pc_total*dr.2019-02-01.Pensioners in poverty (Pension Credit)": "1,362,574",
        "E12000008.pc_total*dr.2019-02-01.Pensioners in poverty (Pension Credit)": "171,593",
        "CSTM5160.pc_total*dr.2019-05-01.Pensioners in poverty (Pension Credit)": "6,810",
        "E92000001.pc_total*dr.2019-05-01.Pensioners in poverty (Pension Credit)": "1,343,967",
        "E12000008.pc_total*dr.2019-05-01.Pensioners in poverty (Pension Credit)": "169,127",
        "CSTM5160.pc_total*dr.2019-08-01.Pensioners in poverty (Pension Credit)": "6,723",
        "E92000001.pc_total*dr.2019-08-01.Pensioners in poverty (Pension Credit)": "1,329,979",
        "E12000008.pc_total*dr.2019-08-01.Pensioners in poverty (Pension Credit)": "167,473",
        "CSTM5160.pc_total*dr.2019-11-01.Pensioners in poverty (Pension Credit)": "6,634",
        "E92000001.pc_total*dr.2019-11-01.Pensioners in poverty (Pension Credit)": "1,314,967",
        "E12000008.pc_total*dr.2019-11-01.Pensioners in poverty (Pension Credit)": "165,732",
        "CSTM5160.pc_total*dr.2020-02-01.Pensioners in poverty (Pension Credit)": "6,527",
        "E92000001.pc_total*dr.2020-02-01.Pensioners in poverty (Pension Credit)": "1,295,491",
        "E12000008.pc_total*dr.2020-02-01.Pensioners in poverty (Pension Credit)": "163,307",
        "CSTM5160.pc_total*dr.2020-05-01.Pensioners in poverty (Pension Credit)": "6,431",
        "E92000001.pc_total*dr.2020-05-01.Pensioners in poverty (Pension Credit)": "1,271,009",
        "E12000008.pc_total*dr.2020-05-01.Pensioners in poverty (Pension Credit)": "160,670",
        "CSTM5160.pc_total*dr.2020-08-01.Pensioners in poverty (Pension Credit)": "6,360",
        "E92000001.pc_total*dr.2020-08-01.Pensioners in poverty (Pension Credit)": "1,261,761",
        "E12000008.pc_total*dr.2020-08-01.Pensioners in poverty (Pension Credit)": "159,596",
        "CSTM5160.pc_total*dr.2020-11-01.Pensioners in poverty (Pension Credit)": "6,264",
        "E92000001.pc_total*dr.2020-11-01.Pensioners in poverty (Pension Credit)": "1,249,181",
        "E12000008.pc_total*dr.2020-11-01.Pensioners in poverty (Pension Credit)": "158,378",
        "CSTM5160.pc_total*dr.2021-02-01.Pensioners in poverty (Pension Credit)": "6,137",
        "E92000001.pc_total*dr.2021-02-01.Pensioners in poverty (Pension Credit)": "1,224,509",
        "E12000008.pc_total*dr.2021-02-01.Pensioners in poverty (Pension Credit)": "154,698",
        "CSTM5160.pc_total*dr.2021-05-01.Pensioners in poverty (Pension Credit)": "6,078",
        "E92000001.pc_total*dr.2021-05-01.Pensioners in poverty (Pension Credit)": "1,213,094",
        "E12000008.pc_total*dr.2021-05-01.Pensioners in poverty (Pension Credit)": "153,301",
        "CSTM5160.pc_total*dr.2021-08-01.Pensioners in poverty (Pension Credit)": "6,018",
        "E92000001.pc_total*dr.2021-08-01.Pensioners in poverty (Pension Credit)": "1,202,132",
        "E12000008.pc_total*dr.2021-08-01.Pensioners in poverty (Pension Credit)": "152,058",
        "CSTM5160.pc_total*dr.2021-11-01.Pensioners in poverty (Pension Credit)": "5,983",
        "E92000001.pc_total*dr.2021-11-01.Pensioners in poverty (Pension Credit)": "1,188,173",
        "E12000008.pc_total*dr.2021-11-01.Pensioners in poverty (Pension Credit)": "150,488",
        "CSTM5160.pc_total*dr.2022-02-01.Pensioners in poverty (Pension Credit)": "5,878",
        "E92000001.pc_total*dr.2022-02-01.Pensioners in poverty (Pension Credit)": "1,171,282",
        "E12000008.pc_total*dr.2022-02-01.Pensioners in poverty (Pension Credit)": "148,360",
        "CSTM5160.pc_total*dr.2022-05-01.Pensioners in poverty (Pension Credit)": "5,856",
        "E92000001.pc_total*dr.2022-05-01.Pensioners in poverty (Pension Credit)": "1,160,528",
        "E12000008.pc_total*dr.2022-05-01.Pensioners in poverty (Pension Credit)": "147,228",
        "CSTM5160.pc_total*dr.2022-08-01.Pensioners in poverty (Pension Credit)": "5,945",
        "E92000001.pc_total*dr.2022-08-01.Pensioners in poverty (Pension Credit)": "1,165,926",
        "E12000008.pc_total*dr.2022-08-01.Pensioners in poverty (Pension Credit)": "148,412",
        "CSTM5160.pc_total*dr.2022-11-01.Pensioners in poverty (Pension Credit)": "5,956",
        "E92000001.pc_total*dr.2022-11-01.Pensioners in poverty (Pension Credit)": "1,166,824",
        "E12000008.pc_total*dr.2022-11-01.Pensioners in poverty (Pension Credit)": "148,778",
        "CSTM5160.pc_total*dr.2023-02-01.Pensioners in poverty (Pension Credit)": "5,900",
        "E92000001.pc_total*dr.2023-02-01.Pensioners in poverty (Pension Credit)": "1,161,284",
        "E12000008.pc_total*dr.2023-02-01.Pensioners in poverty (Pension Credit)": "148,231",
        "CSTM5160.pc_total*dr.2023-05-01.Pensioners in poverty (Pension Credit)": "5,880",
        "E92000001.pc_total*dr.2023-05-01.Pensioners in poverty (Pension Credit)": "1,167,258",
        "E12000008.pc_total*dr.2023-05-01.Pensioners in poverty (Pension Credit)": "149,322",
        "CSTM5160.pc_total*dr.2023-08-01.Pensioners in poverty (Pension Credit)": "5,872",
        "E92000001.pc_total*dr.2023-08-01.Pensioners in poverty (Pension Credit)": "1,165,874",
        "E12000008.pc_total*dr.2023-08-01.Pensioners in poverty (Pension Credit)": "149,290",
        "CSTM5160.ks105ew0002*dr.2021-01-01.One person household: Aged 66 years and over (Census 2021)": "13,875",
        "E92000001.ks105ew0002*dr.2021-01-01.One person household: Aged 66 years and over (Census 2021)": "3,001,789",
        "E12000008.ks105ew0002*dr.2021-01-01.One person household: Aged 66 years and over (Census 2021)": "503,974",
        "CSTM5160.lc3206ew_65pl_bad_vr_health*dr.2021-01-01.People over the age of 65 with bad or very bad health (Census 2021)": "16,114",
        "E92000001.lc3206ew_65pl_bad_vr_health*dr.2021-01-01.People over the age of 65 with bad or very bad health (Census 2021)": "4,376,615",
        "E12000008.lc3206ew_65pl_bad_vr_health*dr.2021-01-01.People over the age of 65 with bad or very bad health (Census 2021)": "678,719",
        "CSTM5160.lc3205ew_ltti_65pl*dr.2021-01-01.People with a limiting long-term illness (aged 65+) (Census 2021)": "12,861",
        "E92000001.lc3205ew_ltti_65pl*dr.2021-01-01.People with a limiting long-term illness (aged 65+) (Census 2021)": "3,414,833",
        "E12000008.lc3205ew_ltti_65pl*dr.2021-01-01.People with a limiting long-term illness (aged 65+) (Census 2021)": "532,961",
        "CSTM5160.cs062_no_car_pensioner_hh*dr.2021-01-01.Lone pensioner households with no car or van (Census 2021)": "8,531",
        "E92000001.cs062_no_car_pensioner_hh*dr.2021-01-01.Lone pensioner households with no car or van (Census 2021)": "1,482,742",
        "E12000008.cs062_no_car_pensioner_hh*dr.2021-01-01.Lone pensioner households with no car or van (Census 2021)": "218,441",
        "CSTM5160.digital_exclusion_index.2021-01-01.Digital Exclusion Risk Index (DERI) score": "2.57",
        "E92000001.digital_exclusion_index.2021-01-01.Digital Exclusion Risk Index (DERI) score": "3.00",
        "E12000008.digital_exclusion_index.2021-01-01.Digital Exclusion Risk Index (DERI) score": "2.77",
        "CSTM5160.data_useage.2023-05-01.Median Broadband Data Use (GB)": "507.00GB",
        "E92000001.data_useage.2023-05-01.Median Broadband Data Use (GB)": "369.19GB",
        "E12000008.data_useage.2023-05-01.Median Broadband Data Use (GB)": "362.90GB",
        "CSTM5160.averagespeedmbps.2023-05-01.Broadband Download Speed (Mb/s)": "176.60Mb/s",
        "E92000001.averagespeedmbps.2023-05-01.Broadband Download Speed (Mb/s)": "152.10Mb/s",
        "E12000008.averagespeedmbps.2023-05-01.Broadband Download Speed (Mb/s)": "154.99Mb/s",
        "CSTM5160.averagespeedmbps_up.2023-05-01.Broadband Upload Speed (Mb/s)": "19.30Mb/s",
        "E92000001.averagespeedmbps_up.2023-05-01.Broadband Upload Speed (Mb/s)": "26.73Mb/s",
        "E12000008.averagespeedmbps_up.2023-05-01.Broadband Upload Speed (Mb/s)": "38.50Mb/s",
        "CSTM5160.broadband_belowuso*dr.2023-05-01.Premises with broadband speeds below the Universal Service Obligation (USO)": "10",
        "E92000001.broadband_belowuso*dr.2023-05-01.Premises with broadband speeds below the Universal Service Obligation (USO)": "32,975",
        "E12000008.broadband_belowuso*dr.2023-05-01.Premises with broadband speeds below the Universal Service Obligation (USO)": "4,708",
        "CSTM5160.iuc_e_cultural_creators*dr.2017-01-01.Internet User Classification: e-Cultural Creators": "8,892",
        "E92000001.iuc_e_cultural_creators*dr.2017-01-01.Internet User Classification: e-Cultural Creators": "831,275",
        "E12000008.iuc_e_cultural_creators*dr.2017-01-01.Internet User Classification: e-Cultural Creators": "118,237",
        "CSTM5160.iuc_e_rational_utilitarians*dr.2017-01-01.Internet User Classification: e-Rational Utilitarians": "11,542",
        "E92000001.iuc_e_rational_utilitarians*dr.2017-01-01.Internet User Classification: e-Rational Utilitarians": "9,124,137",
        "E12000008.iuc_e_rational_utilitarians*dr.2017-01-01.Internet User Classification: e-Rational Utilitarians": "2,037,439",
        "CSTM5160.iuc_passive_uncommitted*dr.2017-01-01.Internet User Classification: Passive and Uncommitted Users": "32,854",
        "E92000001.iuc_passive_uncommitted*dr.2017-01-01.Internet User Classification: Passive and Uncommitted Users": "11,363,032",
        "E12000008.iuc_passive_uncommitted*dr.2017-01-01.Internet User Classification: Passive and Uncommitted Users": "1,140,538",
        "CSTM5160.iuc_e_mainstream*dr.2017-01-01.Internet User Classification: e-Mainstream": "42,586",
        "E92000001.iuc_e_mainstream*dr.2017-01-01.Internet User Classification: e-Mainstream": "8,029,653",
        "E12000008.iuc_e_mainstream*dr.2017-01-01.Internet User Classification: e-Mainstream": "1,607,154",
        "CSTM5160.iuc_e_veterans*dr.2017-01-01.Internet User Classification: e-Veterans": "52,743",
        "E92000001.iuc_e_veterans*dr.2017-01-01.Internet User Classification: e-Veterans": "7,559,389",
        "E12000008.iuc_e_veterans*dr.2017-01-01.Internet User Classification: e-Veterans": "2,122,279",
        "CSTM5160.iuc_settled_offline*dr.2017-01-01.Internet User Classification: Settled Offline Communities": "1,472",
        "E92000001.iuc_settled_offline*dr.2017-01-01.Internet User Classification: Settled Offline Communities": "2,490,701",
        "E12000008.iuc_settled_offline*dr.2017-01-01.Internet User Classification: Settled Offline Communities": "452,478",
        "CSTM5160.iuc_digital_seniors*dr.2017-01-01.Internet User Classification: Digital Seniors": "9,413",
        "E92000001.iuc_digital_seniors*dr.2017-01-01.Internet User Classification: Digital Seniors": "4,719,433",
        "E12000008.iuc_digital_seniors*dr.2017-01-01.Internet User Classification: Digital Seniors": "648,204",
        "CSTM5160.iuc_e_professionals*dr.2017-01-01.Internet User Classification: e-Professionals": "107,739",
        "E92000001.iuc_e_professionals*dr.2017-01-01.Internet User Classification: e-Professionals": "3,147,797",
        "E12000008.iuc_e_professionals*dr.2017-01-01.Internet User Classification: e-Professionals": "468,415",
        "CSTM5160.iuc_e_withdrawn*dr.2017-01-01.Internet User Classification: e-Withdrawn": "6,490",
        "E92000001.iuc_e_withdrawn*dr.2017-01-01.Internet User Classification: e-Withdrawn": "4,899,445",
        "E12000008.iuc_e_withdrawn*dr.2017-01-01.Internet User Classification: e-Withdrawn": "193,090",
        "CSTM5160.iuc_youthful_urban_fringe*dr.2017-01-01.Internet User Classification: Youthful Urban Fringe": "14,424",
        "E92000001.iuc_youthful_urban_fringe*dr.2017-01-01.Internet User Classification: Youthful Urban Fringe": "3,452,745",
        "E12000008.iuc_youthful_urban_fringe*dr.2017-01-01.Internet User Classification: Youthful Urban Fringe": "292,933",
        "CSTM5160.id19_incpop_dec10*dr.2019-01-01.IoD 2019 Income Decile 10": "4,599",
        "E92000001.id19_incpop_dec10*dr.2019-01-01.IoD 2019 Income Decile 10": "5,440,161",
        "E12000008.id19_incpop_dec10*dr.2019-01-01.IoD 2019 Income Decile 10": "1,630,462",
        "CSTM5160.id19_incpop_dec9*dr.2019-01-01.IoD 2019 Income Decile 9": "24,835",
        "E92000001.id19_incpop_dec9*dr.2019-01-01.IoD 2019 Income Decile 9": "5,405,573",
        "E12000008.id19_incpop_dec9*dr.2019-01-01.IoD 2019 Income Decile 9": "1,233,122",
        "CSTM5160.id19_incpop_dec8*dr.2019-01-01.IoD 2019 Income Decile 8": "33,977",
        "E92000001.id19_incpop_dec8*dr.2019-01-01.IoD 2019 Income Decile 8": "5,476,672",
        "E12000008.id19_incpop_dec8*dr.2019-01-01.IoD 2019 Income Decile 8": "1,025,273",
        "CSTM5160.id19_incpop_dec7*dr.2019-01-01.IoD 2019 Income Decile 7": "40,388",
        "E92000001.id19_incpop_dec7*dr.2019-01-01.IoD 2019 Income Decile 7": "5,500,035",
        "E12000008.id19_incpop_dec7*dr.2019-01-01.IoD 2019 Income Decile 7": "1,120,981",
        "CSTM5160.id19_incpop_dec6*dr.2019-01-01.IoD 2019 Income Decile 6": "41,759",
        "E92000001.id19_incpop_dec6*dr.2019-01-01.IoD 2019 Income Decile 6": "5,569,164",
        "E12000008.id19_incpop_dec6*dr.2019-01-01.IoD 2019 Income Decile 6": "967,993",
        "CSTM5160.id19_incpop_dec5*dr.2019-01-01.IoD 2019 Income Decile 5": "39,853",
        "E92000001.id19_incpop_dec5*dr.2019-01-01.IoD 2019 Income Decile 5": "5,702,233",
        "E12000008.id19_incpop_dec5*dr.2019-01-01.IoD 2019 Income Decile 5": "979,204",
        "CSTM5160.id19_incpop_dec4*dr.2019-01-01.IoD 2019 Income Decile 4": "34,654",
        "E92000001.id19_incpop_dec4*dr.2019-01-01.IoD 2019 Income Decile 4": "5,693,139",
        "E12000008.id19_incpop_dec4*dr.2019-01-01.IoD 2019 Income Decile 4": "791,381",
        "CSTM5160.id19_incpop_dec3*dr.2019-01-01.IoD 2019 Income Decile 3": "24,418",
        "E92000001.id19_incpop_dec3*dr.2019-01-01.IoD 2019 Income Decile 3": "5,663,871",
        "E12000008.id19_incpop_dec3*dr.2019-01-01.IoD 2019 Income Decile 3": "645,162",
        "CSTM5160.id19_incpop_dec2*dr.2019-01-01.IoD 2019 Income Decile 2": "19,815",
        "E92000001.id19_incpop_dec2*dr.2019-01-01.IoD 2019 Income Decile 2": "5,618,706",
        "E12000008.id19_incpop_dec2*dr.2019-01-01.IoD 2019 Income Decile 2": "442,811",
        "CSTM5160.id19_incpop_dec1*dr.2019-01-01.IoD 2019 Income Decile 1": "23,857",
        "E92000001.id19_incpop_dec1*dr.2019-01-01.IoD 2019 Income Decile 1": "5,549,625",
        "E12000008.id19_incpop_dec1*dr.2019-01-01.IoD 2019 Income Decile 1": "244,372",
        "CSTM5160.ben_combs_wa_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 64)": "40,573",
        "E92000001.ben_combs_wa_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 64)": "8,248,752",
        "E12000008.ben_combs_wa_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 64)": "1,061,355",
        "CSTM5160.ben_combs_16_24_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 24)": "4,452",
        "E92000001.ben_combs_16_24_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 24)": "862,944",
        "E12000008.ben_combs_16_24_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 24)": "115,452",
        "CSTM5160.ben_combs_spa_tot_li*dr.2024-02-01.People aged 65+ claiming DWP Benefits (Benefit Combinations)": "0",
        "E92000001.ben_combs_spa_tot_li*dr.2024-02-01.People aged 65+ claiming DWP Benefits (Benefit Combinations)": "0",
        "E12000008.ben_combs_spa_tot_li*dr.2024-02-01.People aged 65+ claiming DWP Benefits (Benefit Combinations)": "0",
        "CSTM5160.ben_combs_wa_tot_li*dr.2018-05-01.Working age Benefit claimants (Benefit combinations)": "30,852",
        "E92000001.ben_combs_wa_tot_li*dr.2018-05-01.Working age Benefit claimants (Benefit combinations)": "5,741,224",
        "E12000008.ben_combs_wa_tot_li*dr.2018-05-01.Working age Benefit claimants (Benefit combinations)": "719,063",
        "CSTM5160.ben_combs_wa_tot_li*dr.2018-08-01.Working age Benefit claimants (Benefit combinations)": "31,010",
        "E92000001.ben_combs_wa_tot_li*dr.2018-08-01.Working age Benefit claimants (Benefit combinations)": "5,738,829",
        "E12000008.ben_combs_wa_tot_li*dr.2018-08-01.Working age Benefit claimants (Benefit combinations)": "717,078",
        "CSTM5160.ben_combs_wa_tot_li*dr.2018-11-01.Working age Benefit claimants (Benefit combinations)": "31,181",
        "E92000001.ben_combs_wa_tot_li*dr.2018-11-01.Working age Benefit claimants (Benefit combinations)": "5,757,716",
        "E12000008.ben_combs_wa_tot_li*dr.2018-11-01.Working age Benefit claimants (Benefit combinations)": "720,214",
        "CSTM5160.ben_combs_wa_tot_li*dr.2019-02-01.Working age Benefit claimants (Benefit combinations)": "31,059",
        "E92000001.ben_combs_wa_tot_li*dr.2019-02-01.Working age Benefit claimants (Benefit combinations)": "5,813,545",
        "E12000008.ben_combs_wa_tot_li*dr.2019-02-01.Working age Benefit claimants (Benefit combinations)": "729,125",
        "CSTM5160.ben_combs_wa_tot_li*dr.2019-05-01.Working age Benefit claimants (Benefit combinations)": "31,125",
        "E92000001.ben_combs_wa_tot_li*dr.2019-05-01.Working age Benefit claimants (Benefit combinations)": "5,871,405",
        "E12000008.ben_combs_wa_tot_li*dr.2019-05-01.Working age Benefit claimants (Benefit combinations)": "736,333",
        "CSTM5160.ben_combs_wa_tot_li*dr.2019-08-01.Working age Benefit claimants (Benefit combinations)": "31,598",
        "E92000001.ben_combs_wa_tot_li*dr.2019-08-01.Working age Benefit claimants (Benefit combinations)": "5,966,795",
        "E12000008.ben_combs_wa_tot_li*dr.2019-08-01.Working age Benefit claimants (Benefit combinations)": "748,796",
        "CSTM5160.ben_combs_wa_tot_li*dr.2019-11-01.Working age Benefit claimants (Benefit combinations)": "31,778",
        "E92000001.ben_combs_wa_tot_li*dr.2019-11-01.Working age Benefit claimants (Benefit combinations)": "6,050,349",
        "E12000008.ben_combs_wa_tot_li*dr.2019-11-01.Working age Benefit claimants (Benefit combinations)": "760,506",
        "CSTM5160.ben_combs_wa_tot_li*dr.2020-02-01.Working age Benefit claimants (Benefit combinations)": "32,182",
        "E92000001.ben_combs_wa_tot_li*dr.2020-02-01.Working age Benefit claimants (Benefit combinations)": "6,180,714",
        "E12000008.ben_combs_wa_tot_li*dr.2020-02-01.Working age Benefit claimants (Benefit combinations)": "776,504",
        "CSTM5160.ben_combs_wa_tot_li*dr.2020-05-01.Working age Benefit claimants (Benefit combinations)": "44,563",
        "E92000001.ben_combs_wa_tot_li*dr.2020-05-01.Working age Benefit claimants (Benefit combinations)": "8,113,618",
        "E12000008.ben_combs_wa_tot_li*dr.2020-05-01.Working age Benefit claimants (Benefit combinations)": "1,071,219",
        "CSTM5160.ben_combs_wa_tot_li*dr.2020-08-01.Working age Benefit claimants (Benefit combinations)": "46,064",
        "E92000001.ben_combs_wa_tot_li*dr.2020-08-01.Working age Benefit claimants (Benefit combinations)": "8,382,707",
        "E12000008.ben_combs_wa_tot_li*dr.2020-08-01.Working age Benefit claimants (Benefit combinations)": "1,100,901",
        "CSTM5160.ben_combs_wa_tot_li*dr.2020-11-01.Working age Benefit claimants (Benefit combinations)": "46,287",
        "E92000001.ben_combs_wa_tot_li*dr.2020-11-01.Working age Benefit claimants (Benefit combinations)": "8,469,012",
        "E12000008.ben_combs_wa_tot_li*dr.2020-11-01.Working age Benefit claimants (Benefit combinations)": "1,107,397",
        "CSTM5160.ben_combs_wa_tot_li*dr.2021-02-01.Working age Benefit claimants (Benefit combinations)": "46,800",
        "E92000001.ben_combs_wa_tot_li*dr.2021-02-01.Working age Benefit claimants (Benefit combinations)": "8,504,744",
        "E12000008.ben_combs_wa_tot_li*dr.2021-02-01.Working age Benefit claimants (Benefit combinations)": "1,112,980",
        "CSTM5160.ben_combs_wa_tot_li*dr.2021-05-01.Working age Benefit claimants (Benefit combinations)": "45,173",
        "E92000001.ben_combs_wa_tot_li*dr.2021-05-01.Working age Benefit claimants (Benefit combinations)": "8,360,887",
        "E12000008.ben_combs_wa_tot_li*dr.2021-05-01.Working age Benefit claimants (Benefit combinations)": "1,087,223",
        "CSTM5160.ben_combs_wa_tot_li*dr.2021-08-01.Working age Benefit claimants (Benefit combinations)": "43,831",
        "E92000001.ben_combs_wa_tot_li*dr.2021-08-01.Working age Benefit claimants (Benefit combinations)": "8,173,638",
        "E12000008.ben_combs_wa_tot_li*dr.2021-08-01.Working age Benefit claimants (Benefit combinations)": "1,057,119",
        "CSTM5160.ben_combs_wa_tot_li*dr.2021-11-01.Working age Benefit claimants (Benefit combinations)": "41,879",
        "E92000001.ben_combs_wa_tot_li*dr.2021-11-01.Working age Benefit claimants (Benefit combinations)": "7,956,891",
        "E12000008.ben_combs_wa_tot_li*dr.2021-11-01.Working age Benefit claimants (Benefit combinations)": "1,025,073",
        "CSTM5160.ben_combs_wa_tot_li*dr.2022-02-01.Working age Benefit claimants (Benefit combinations)": "40,742",
        "E92000001.ben_combs_wa_tot_li*dr.2022-02-01.Working age Benefit claimants (Benefit combinations)": "7,817,106",
        "E12000008.ben_combs_wa_tot_li*dr.2022-02-01.Working age Benefit claimants (Benefit combinations)": "1,004,617",
        "CSTM5160.ben_combs_wa_tot_li*dr.2022-05-01.Working age Benefit claimants (Benefit combinations)": "40,137",
        "E92000001.ben_combs_wa_tot_li*dr.2022-05-01.Working age Benefit claimants (Benefit combinations)": "7,744,901",
        "E12000008.ben_combs_wa_tot_li*dr.2022-05-01.Working age Benefit claimants (Benefit combinations)": "994,159",
        "CSTM5160.ben_combs_wa_tot_li*dr.2022-08-01.Working age Benefit claimants (Benefit combinations)": "40,187",
        "E92000001.ben_combs_wa_tot_li*dr.2022-08-01.Working age Benefit claimants (Benefit combinations)": "7,791,629",
        "E12000008.ben_combs_wa_tot_li*dr.2022-08-01.Working age Benefit claimants (Benefit combinations)": "1,002,766",
        "CSTM5160.ben_combs_wa_tot_li*dr.2022-11-01.Working age Benefit claimants (Benefit combinations)": "40,087",
        "E92000001.ben_combs_wa_tot_li*dr.2022-11-01.Working age Benefit claimants (Benefit combinations)": "7,827,412",
        "E12000008.ben_combs_wa_tot_li*dr.2022-11-01.Working age Benefit claimants (Benefit combinations)": "1,009,213",
        "CSTM5160.ben_combs_wa_tot_li*dr.2023-02-01.Working age Benefit claimants (Benefit combinations)": "39,811",
        "E92000001.ben_combs_wa_tot_li*dr.2023-02-01.Working age Benefit claimants (Benefit combinations)": "7,859,103",
        "E12000008.ben_combs_wa_tot_li*dr.2023-02-01.Working age Benefit claimants (Benefit combinations)": "1,013,602",
        "CSTM5160.ben_combs_wa_tot_li*dr.2023-05-01.Working age Benefit claimants (Benefit combinations)": "39,889",
        "E92000001.ben_combs_wa_tot_li*dr.2023-05-01.Working age Benefit claimants (Benefit combinations)": "7,893,845",
        "E12000008.ben_combs_wa_tot_li*dr.2023-05-01.Working age Benefit claimants (Benefit combinations)": "1,018,016",
        "CSTM5160.ben_combs_wa_tot_li*dr.2023-08-01.DWP Benefit claimants (aged 16 - 64)": "40,049",
        "E92000001.ben_combs_wa_tot_li*dr.2023-08-01.DWP Benefit claimants (aged 16 - 64)": "7,967,969",
        "E12000008.ben_combs_wa_tot_li*dr.2023-08-01.DWP Benefit claimants (aged 16 - 64)": "1,027,130",
        "CSTM5160.ben_combs_wa_tot_li*dr.2023-11-01.DWP Benefit claimants (aged 16 - 64)": "40,140",
        "E92000001.ben_combs_wa_tot_li*dr.2023-11-01.DWP Benefit claimants (aged 16 - 64)": "8,083,145",
        "E12000008.ben_combs_wa_tot_li*dr.2023-11-01.DWP Benefit claimants (aged 16 - 64)": "1,040,356",
        "CSTM5160.uc_tot_small*dr.2024-09-01.Universal Credit": "29,309",
        "E92000001.uc_tot_small*dr.2024-09-01.Universal Credit": "6,205,836",
        "E12000008.uc_tot_small*dr.2024-09-01.Universal Credit": "787,314",
        "CSTM5160.uc_no_work_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: No work requirements": "10,721",
        "E92000001.uc_no_work_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: No work requirements": "2,394,777",
        "E12000008.uc_no_work_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: No work requirements": "291,943",
        "CSTM5160.uc_plan_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Planning for work": "277",
        "E92000001.uc_plan_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Planning for work": "96,984",
        "E12000008.uc_plan_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Planning for work": "13,165",
        "CSTM5160.uc_prep_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Preparing for work": "1,787",
        "E92000001.uc_prep_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Preparing for work": "316,099",
        "E12000008.uc_prep_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Preparing for work": "41,784",
        "CSTM5160.uc_search_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Searching for work": "8,065",
        "E92000001.uc_search_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Searching for work": "1,488,545",
        "E12000008.uc_search_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Searching for work": "175,798",
        "CSTM5160.uc_work_with_no_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working No requirements": "5,028",
        "E92000001.uc_work_with_no_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working No requirements": "1,111,836",
        "E12000008.uc_work_with_no_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working No requirements": "166,094",
        "CSTM5160.uc_work_with_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working with requirements": "3,436",
        "E92000001.uc_work_with_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working with requirements": "797,472",
        "E12000008.uc_work_with_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working with requirements": "98,514",
        "CSTM5160.uc_total_households*dr.2024-05-01.Total households on Universal Credit": "23,937",
        "E92000001.uc_total_households*dr.2024-05-01.Total households on Universal Credit": "4,881,179",
        "E12000008.uc_total_households*dr.2024-05-01.Total households on Universal Credit": "618,684",
        "CSTM5160.uc_single_dep_child*dr.2024-05-01.Households on Universal Credit, Family type, single with dependent children": "5,880",
        "E92000001.uc_single_dep_child*dr.2024-05-01.Households on Universal Credit, Family type, single with dependent children": "1,702,643",
        "E12000008.uc_single_dep_child*dr.2024-05-01.Households on Universal Credit, Family type, single with dependent children": "221,131",
        "CSTM5160.uc_carer_ent*dr.2024-05-01.Households on Universal Credit, Carer Entitlement": "2,405",
        "E92000001.uc_carer_ent*dr.2024-05-01.Households on Universal Credit, Carer Entitlement": "631,954",
        "E12000008.uc_carer_ent*dr.2024-05-01.Households on Universal Credit, Carer Entitlement": "82,255",
        "CSTM5160.uc_child_ent*dr.2024-05-01.Households on Universal Credit, Child Entitlement": "7,662",
        "E92000001.uc_child_ent*dr.2024-05-01.Households on Universal Credit, Child Entitlement": "2,304,926",
        "E12000008.uc_child_ent*dr.2024-05-01.Households on Universal Credit, Child Entitlement": "303,877",
        "CSTM5160.uc_disab_child_ent*dr.2024-02-01.Households on Universal Credit, Disabled Child Entitlement": "1,006",
        "E92000001.uc_disab_child_ent*dr.2024-02-01.Households on Universal Credit, Disabled Child Entitlement": "290,710",
        "E12000008.uc_disab_child_ent*dr.2024-02-01.Households on Universal Credit, Disabled Child Entitlement": "44,036",
        "CSTM5160.uc_lcw*dr.2024-02-01.Households on Universal Credit - Limited Capability for Work Entitlement": "5,954",
        "E92000001.uc_lcw*dr.2024-02-01.Households on Universal Credit - Limited Capability for Work Entitlement": "1,182,425",
        "E12000008.uc_lcw*dr.2024-02-01.Households on Universal Credit - Limited Capability for Work Entitlement": "138,642",
        "CSTM5160.uc_housing_ent_soc*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Social rented": "5,530",
        "E92000001.uc_housing_ent_soc*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Social rented": "1,551,589",
        "E12000008.uc_housing_ent_soc*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Social rented": "200,281",
        "CSTM5160.uc_housing_ent_priv*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Private rented": "9,911",
        "E92000001.uc_housing_ent_priv*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Private rented": "1,389,264",
        "E12000008.uc_housing_ent_priv*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Private rented": "187,083",
        "CSTM5160.uc_housing_ent_tot*dr.2024-05-01.Households on Universal Credit with Housing Entitlement": "15,942",
        "E92000001.uc_housing_ent_tot*dr.2024-05-01.Households on Universal Credit with Housing Entitlement": "3,026,305",
        "E12000008.uc_housing_ent_tot*dr.2024-05-01.Households on Universal Credit with Housing Entitlement": "400,805",
        "CSTM5160.hb_tot*dr.2011-01-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-01-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-01-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-02-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-02-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-02-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-03-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-03-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-03-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-04-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-04-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-04-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-05-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-05-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-05-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-06-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-06-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-06-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-07-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-07-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-07-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-08-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-08-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-08-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-09-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-09-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-09-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-10-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-10-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-10-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-11-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-11-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-11-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2011-12-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2011-12-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2011-12-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-01-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-01-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-01-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-02-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-02-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-02-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-03-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-03-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-03-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-04-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-04-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-04-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-05-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-05-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-05-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-06-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-06-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-06-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-07-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-07-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-07-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-08-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-08-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-08-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-09-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-09-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-09-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-10-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-10-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-10-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-11-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-11-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-11-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2012-12-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2012-12-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2012-12-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-01-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-01-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-01-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-02-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-02-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-02-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-03-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-03-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-03-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-04-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-04-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-04-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-05-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-05-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-05-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-06-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-06-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-06-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-07-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-07-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-07-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-08-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-08-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-08-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-09-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-09-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-09-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-10-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-10-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-10-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-11-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-11-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-11-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2013-12-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2013-12-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2013-12-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-01-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-01-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-01-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-02-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-02-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-02-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-03-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-03-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-03-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-04-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-04-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-04-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-05-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-05-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-05-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-06-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-06-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-06-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-07-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-07-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-07-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-08-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-08-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-08-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-09-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-09-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-09-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-10-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-10-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-10-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-11-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-11-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-11-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2014-12-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2014-12-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2014-12-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2015-01-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2015-01-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2015-01-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2015-02-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2015-02-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2015-02-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2015-05-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2015-05-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2015-05-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2015-06-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2015-06-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2015-06-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2015-07-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2015-07-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2015-07-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2015-08-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2015-08-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2015-08-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2015-09-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2015-09-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2015-09-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2015-10-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2015-10-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2015-10-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2015-11-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2015-11-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2015-11-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2015-12-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2015-12-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2015-12-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-01-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-01-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-01-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-02-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-02-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-02-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-03-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-03-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-03-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-04-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-04-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-04-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-05-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-05-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-05-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-06-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-06-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-06-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-07-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-07-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-07-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-08-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-08-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-08-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-09-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-09-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-09-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-10-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-10-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-10-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2016-11-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2016-11-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2016-11-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-02-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-02-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-02-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-03-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-03-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-03-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-04-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-04-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-04-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-05-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-05-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-05-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-06-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-06-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-06-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-07-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-07-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-07-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-08-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-08-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-08-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-09-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-09-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-09-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-10-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-10-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-10-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-11-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-11-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-11-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2017-12-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2017-12-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2017-12-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-01-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-01-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-01-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-02-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-02-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-02-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-03-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-03-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-03-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-04-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-04-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-04-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-05-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-05-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-05-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-06-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-06-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-06-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-07-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-07-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-07-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-08-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-08-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-08-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-09-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-09-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-09-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-10-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-10-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-10-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-11-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-11-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-11-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2018-12-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2018-12-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2018-12-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-01-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-01-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-01-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-02-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-02-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-02-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-03-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-03-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-03-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-04-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-04-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-04-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-05-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-05-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-05-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-06-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-06-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-06-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-07-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-07-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-07-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-08-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-08-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-08-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-09-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-09-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-09-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-10-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-10-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-10-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-11-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-11-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-11-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2019-12-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2019-12-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2019-12-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2020-01-01.Housing Benefit": "17,507",
        "E92000001.hb_tot*dr.2020-01-01.Housing Benefit": "2,765,909",
        "E12000008.hb_tot*dr.2020-01-01.Housing Benefit": "358,727",
        "CSTM5160.hb_tot*dr.2020-02-01.Housing Benefit": "17,327",
        "E92000001.hb_tot*dr.2020-02-01.Housing Benefit": "2,731,761",
        "E12000008.hb_tot*dr.2020-02-01.Housing Benefit": "353,678",
        "CSTM5160.hb_tot*dr.2020-03-01.Housing Benefit": "17,243",
        "E92000001.hb_tot*dr.2020-03-01.Housing Benefit": "2,703,085",
        "E12000008.hb_tot*dr.2020-03-01.Housing Benefit": "350,011",
        "CSTM5160.hb_tot*dr.2020-04-01.Housing Benefit": "17,107",
        "E92000001.hb_tot*dr.2020-04-01.Housing Benefit": "2,679,263",
        "E12000008.hb_tot*dr.2020-04-01.Housing Benefit": "346,661",
        "CSTM5160.hb_tot*dr.2020-05-01.Housing Benefit": "16,940",
        "E92000001.hb_tot*dr.2020-05-01.Housing Benefit": "2,640,564",
        "E12000008.hb_tot*dr.2020-05-01.Housing Benefit": "342,462",
        "CSTM5160.hb_tot*dr.2020-06-01.Housing Benefit": "16,913",
        "E92000001.hb_tot*dr.2020-06-01.Housing Benefit": "2,622,456",
        "E12000008.hb_tot*dr.2020-06-01.Housing Benefit": "339,813",
        "CSTM5160.hb_tot*dr.2020-07-01.Housing Benefit": "16,829",
        "E92000001.hb_tot*dr.2020-07-01.Housing Benefit": "2,606,198",
        "E12000008.hb_tot*dr.2020-07-01.Housing Benefit": "337,556",
        "CSTM5160.hb_tot*dr.2020-08-01.Housing Benefit": "16,711",
        "E92000001.hb_tot*dr.2020-08-01.Housing Benefit": "2,581,843",
        "E12000008.hb_tot*dr.2020-08-01.Housing Benefit": "333,955",
        "CSTM5160.hb_tot*dr.2020-09-01.Housing Benefit": "16,630",
        "E92000001.hb_tot*dr.2020-09-01.Housing Benefit": "2,562,887",
        "E12000008.hb_tot*dr.2020-09-01.Housing Benefit": "331,382",
        "CSTM5160.hb_tot*dr.2020-10-01.Housing Benefit": "16,509",
        "E92000001.hb_tot*dr.2020-10-01.Housing Benefit": "2,542,203",
        "E12000008.hb_tot*dr.2020-10-01.Housing Benefit": "328,587",
        "CSTM5160.hb_tot*dr.2020-11-01.Housing Benefit": "16,351",
        "E92000001.hb_tot*dr.2020-11-01.Housing Benefit": "2,514,410",
        "E12000008.hb_tot*dr.2020-11-01.Housing Benefit": "325,168",
        "CSTM5160.hb_tot*dr.2020-12-01.Housing Benefit": "16,297",
        "E92000001.hb_tot*dr.2020-12-01.Housing Benefit": "2,496,700",
        "E12000008.hb_tot*dr.2020-12-01.Housing Benefit": "322,677",
        "CSTM5160.hb_tot*dr.2021-01-01.Housing Benefit": "16,257",
        "E92000001.hb_tot*dr.2021-01-01.Housing Benefit": "2,479,644",
        "E12000008.hb_tot*dr.2021-01-01.Housing Benefit": "320,520",
        "CSTM5160.hb_tot*dr.2021-02-01.Housing Benefit": "16,140",
        "E92000001.hb_tot*dr.2021-02-01.Housing Benefit": "2,462,715",
        "E12000008.hb_tot*dr.2021-02-01.Housing Benefit": "317,820",
        "CSTM5160.hb_tot*dr.2021-03-01.Housing Benefit": "16,110",
        "E92000001.hb_tot*dr.2021-03-01.Housing Benefit": "2,440,780",
        "E12000008.hb_tot*dr.2021-03-01.Housing Benefit": "314,840",
        "CSTM5160.hb_tot*dr.2021-04-01.Housing Benefit": "15,997",
        "E92000001.hb_tot*dr.2021-04-01.Housing Benefit": "2,424,004",
        "E12000008.hb_tot*dr.2021-04-01.Housing Benefit": "313,018",
        "CSTM5160.hb_tot*dr.2021-05-01.Housing Benefit": "15,833",
        "E92000001.hb_tot*dr.2021-05-01.Housing Benefit": "2,400,360",
        "E12000008.hb_tot*dr.2021-05-01.Housing Benefit": "309,392",
        "CSTM5160.hb_tot*dr.2021-06-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2021-06-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2021-06-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2021-07-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2021-07-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2021-07-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2021-08-01.Housing Benefit": "15,577",
        "E92000001.hb_tot*dr.2021-08-01.Housing Benefit": "2,343,229",
        "E12000008.hb_tot*dr.2021-08-01.Housing Benefit": "301,879",
        "CSTM5160.hb_tot*dr.2021-09-01.Housing Benefit": "15,459",
        "E92000001.hb_tot*dr.2021-09-01.Housing Benefit": "2,327,423",
        "E12000008.hb_tot*dr.2021-09-01.Housing Benefit": "299,578",
        "CSTM5160.hb_tot*dr.2021-10-01.Housing Benefit": "15,294",
        "E92000001.hb_tot*dr.2021-10-01.Housing Benefit": "2,298,074",
        "E12000008.hb_tot*dr.2021-10-01.Housing Benefit": "295,921",
        "CSTM5160.hb_tot*dr.2021-11-01.Housing Benefit": "15,213",
        "E92000001.hb_tot*dr.2021-11-01.Housing Benefit": "2,280,708",
        "E12000008.hb_tot*dr.2021-11-01.Housing Benefit": "293,349",
        "CSTM5160.hb_tot*dr.2021-12-01.Housing Benefit": "15,159",
        "E92000001.hb_tot*dr.2021-12-01.Housing Benefit": "2,264,587",
        "E12000008.hb_tot*dr.2021-12-01.Housing Benefit": "291,470",
        "CSTM5160.hb_tot*dr.2022-01-01.Housing Benefit": "15,102",
        "E92000001.hb_tot*dr.2022-01-01.Housing Benefit": "2,250,054",
        "E12000008.hb_tot*dr.2022-01-01.Housing Benefit": "289,573",
        "CSTM5160.hb_tot*dr.2022-02-01.Housing Benefit": "15,036",
        "E92000001.hb_tot*dr.2022-02-01.Housing Benefit": "2,234,603",
        "E12000008.hb_tot*dr.2022-02-01.Housing Benefit": "287,733",
        "CSTM5160.hb_tot*dr.2022-03-01.Housing Benefit": "14,989",
        "E92000001.hb_tot*dr.2022-03-01.Housing Benefit": "2,217,840",
        "E12000008.hb_tot*dr.2022-03-01.Housing Benefit": "285,139",
        "CSTM5160.hb_tot*dr.2022-04-01.Housing Benefit": "14,810",
        "E92000001.hb_tot*dr.2022-04-01.Housing Benefit": "2,194,533",
        "E12000008.hb_tot*dr.2022-04-01.Housing Benefit": "282,652",
        "CSTM5160.hb_tot*dr.2022-05-01.Housing Benefit": "14,764",
        "E92000001.hb_tot*dr.2022-05-01.Housing Benefit": "2,187,607",
        "E12000008.hb_tot*dr.2022-05-01.Housing Benefit": "281,444",
        "CSTM5160.hb_tot*dr.2022-06-01.Housing Benefit": "14,699",
        "E92000001.hb_tot*dr.2022-06-01.Housing Benefit": "2,178,835",
        "E12000008.hb_tot*dr.2022-06-01.Housing Benefit": "280,642",
        "CSTM5160.hb_tot*dr.2022-07-01.Housing Benefit": "14,593",
        "E92000001.hb_tot*dr.2022-07-01.Housing Benefit": "2,158,486",
        "E12000008.hb_tot*dr.2022-07-01.Housing Benefit": "277,614",
        "CSTM5160.hb_tot*dr.2022-08-01.Housing Benefit": "14,487",
        "E92000001.hb_tot*dr.2022-08-01.Housing Benefit": "2,144,521",
        "E12000008.hb_tot*dr.2022-08-01.Housing Benefit": "275,528",
        "CSTM5160.hb_tot*dr.2022-09-01.Housing Benefit": "14,412",
        "E92000001.hb_tot*dr.2022-09-01.Housing Benefit": "2,132,425",
        "E12000008.hb_tot*dr.2022-09-01.Housing Benefit": "274,005",
        "CSTM5160.hb_tot*dr.2022-10-01.Housing Benefit": "14,210",
        "E92000001.hb_tot*dr.2022-10-01.Housing Benefit": "2,111,722",
        "E12000008.hb_tot*dr.2022-10-01.Housing Benefit": "271,142",
        "CSTM5160.hb_tot*dr.2022-11-01.Housing Benefit": "14,159",
        "E92000001.hb_tot*dr.2022-11-01.Housing Benefit": "2,099,115",
        "E12000008.hb_tot*dr.2022-11-01.Housing Benefit": "269,396",
        "CSTM5160.hb_tot*dr.2022-12-01.Housing Benefit": "14,100",
        "E92000001.hb_tot*dr.2022-12-01.Housing Benefit": "2,088,502",
        "E12000008.hb_tot*dr.2022-12-01.Housing Benefit": "268,047",
        "CSTM5160.hb_tot*dr.2023-01-01.Housing Benefit": "14,040",
        "E92000001.hb_tot*dr.2023-01-01.Housing Benefit": "2,076,676",
        "E12000008.hb_tot*dr.2023-01-01.Housing Benefit": "266,535",
        "CSTM5160.hb_tot*dr.2023-02-01.Housing Benefit": "13,940",
        "E92000001.hb_tot*dr.2023-02-01.Housing Benefit": "2,065,290",
        "E12000008.hb_tot*dr.2023-02-01.Housing Benefit": "264,816",
        "CSTM5160.hb_tot*dr.2023-03-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2023-03-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2023-03-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2023-04-01.Housing Benefit": null,
        "E92000001.hb_tot*dr.2023-04-01.Housing Benefit": null,
        "E12000008.hb_tot*dr.2023-04-01.Housing Benefit": null,
        "CSTM5160.hb_tot*dr.2023-05-01.Housing Benefit": "13,774",
        "E92000001.hb_tot*dr.2023-05-01.Housing Benefit": "2,032,388",
        "E12000008.hb_tot*dr.2023-05-01.Housing Benefit": "260,537",
        "CSTM5160.hb_tot*dr.2023-06-01.Housing Benefit": "13,754",
        "E92000001.hb_tot*dr.2023-06-01.Housing Benefit": "2,026,766",
        "E12000008.hb_tot*dr.2023-06-01.Housing Benefit": "260,371",
        "CSTM5160.hb_tot*dr.2023-07-01.Housing Benefit": "13,655",
        "E92000001.hb_tot*dr.2023-07-01.Housing Benefit": "2,012,208",
        "E12000008.hb_tot*dr.2023-07-01.Housing Benefit": "258,156",
        "CSTM5160.hb_tot*dr.2023-08-01.Housing Benefit": "13,576",
        "E92000001.hb_tot*dr.2023-08-01.Housing Benefit": "2,005,507",
        "E12000008.hb_tot*dr.2023-08-01.Housing Benefit": "256,886",
        "CSTM5160.hb_tot*dr.2023-09-01.Housing Benefit": "13,514",
        "E92000001.hb_tot*dr.2023-09-01.Housing Benefit": "1,990,323",
        "E12000008.hb_tot*dr.2023-09-01.Housing Benefit": "255,236",
        "CSTM5160.hb_tot*dr.2023-10-01.Housing Benefit": "13,470",
        "E92000001.hb_tot*dr.2023-10-01.Housing Benefit": "1,980,951",
        "E12000008.hb_tot*dr.2023-10-01.Housing Benefit": "253,925",
        "CSTM5160.hb_tot*dr.2023-11-01.Housing Benefit": "13,450",
        "E92000001.hb_tot*dr.2023-11-01.Housing Benefit": "1,972,274",
        "E12000008.hb_tot*dr.2023-11-01.Housing Benefit": "252,766",
        "CSTM5160.hb_tot*dr.2023-12-01.Housing Benefit": "13,366",
        "E92000001.hb_tot*dr.2023-12-01.Housing Benefit": "1,936,735",
        "E12000008.hb_tot*dr.2023-12-01.Housing Benefit": "246,617",
        "CSTM5160.hb_tot*dr.2024-01-01.Housing Benefit": "13,319",
        "E92000001.hb_tot*dr.2024-01-01.Housing Benefit": "1,932,745",
        "E12000008.hb_tot*dr.2024-01-01.Housing Benefit": "246,262",
        "CSTM5160.hb_tot*dr.2024-02-01.Housing Benefit": "13,406",
        "E92000001.hb_tot*dr.2024-02-01.Housing Benefit": "1,926,054",
        "E12000008.hb_tot*dr.2024-02-01.Housing Benefit": "245,830",
        "CSTM5160.hb_tot*dr.2024-03-01.Housing Benefit": "13,242",
        "E92000001.hb_tot*dr.2024-03-01.Housing Benefit": "1,928,115",
        "E12000008.hb_tot*dr.2024-03-01.Housing Benefit": "247,721",
        "CSTM5160.hb_tot*dr.2024-04-01.Housing Benefit": "13,144",
        "E92000001.hb_tot*dr.2024-04-01.Housing Benefit": "1,923,344",
        "E12000008.hb_tot*dr.2024-04-01.Housing Benefit": "246,995",
        "CSTM5160.hb_tot*dr.2024-05-01.Housing Benefit": "13,086",
        "E92000001.hb_tot*dr.2024-05-01.Housing Benefit": "1,919,050",
        "E12000008.hb_tot*dr.2024-05-01.Housing Benefit": "246,451",
        "CSTM5160.ks601ew0002*dr.2021-01-01.Part-time employees (Census 2021)": "27,236",
        "E92000001.ks601ew0002*dr.2021-01-01.Part-time employees (Census 2021)": "5,475,751",
        "E12000008.ks601ew0002*dr.2021-01-01.Part-time employees (Census 2021)": "891,387",
        "CSTM5160.ks601ew0003*dr.2021-01-01.Full-time employees (Census 2021)": "74,452",
        "E92000001.ks601ew0003*dr.2021-01-01.Full-time employees (Census 2021)": "15,766,743",
        "E12000008.ks601ew0003*dr.2021-01-01.Full-time employees (Census 2021)": "2,660,090",
        "CSTM5160.ks601ew0004*dr.2021-01-01.Self-employed people (Census 2021)": "30,683",
        "E92000001.ks601ew0004*dr.2021-01-01.Self-employed people (Census 2021)": "4,390,029",
        "E12000008.ks601ew0004*dr.2021-01-01.Self-employed people (Census 2021)": "802,657",
        "CSTM5160.ks601ew0005*dr.2021-01-01.Economically active: Unemployed (Census 2021)": "7,349",
        "E92000001.ks601ew0005*dr.2021-01-01.Economically active: Unemployed (Census 2021)": "1,312,729",
        "E12000008.ks601ew0005*dr.2021-01-01.Economically active: Unemployed (Census 2021)": "188,892",
        "CSTM5160.ks601ew0006*dr.2021-01-01.Economically active: Full-time student (Census 2021)": "10,405",
        "E92000001.ks601ew0006*dr.2021-01-01.Economically active: Full-time student (Census 2021)": "1,056,250",
        "E12000008.ks601ew0006*dr.2021-01-01.Economically active: Full-time student (Census 2021)": "158,519",
        "CSTM5160.ks601ew0007*dr.2021-01-01.Economically inactive: Retired (Census 2021)": "35,982",
        "E92000001.ks601ew0007*dr.2021-01-01.Economically inactive: Retired (Census 2021)": "9,882,054",
        "E12000008.ks601ew0007*dr.2021-01-01.Economically inactive: Retired (Census 2021)": "1,697,916",
        "CSTM5160.ks601ew0008*dr.2021-01-01.Economically inactive: Student (including full-time students) (Census 2021)": "23,218",
        "E92000001.ks601ew0008*dr.2021-01-01.Economically inactive: Student (including full-time students) (Census 2021)": "2,595,453",
        "E12000008.ks601ew0008*dr.2021-01-01.Economically inactive: Student (including full-time students) (Census 2021)": "384,992",
        "CSTM5160.ks601ew0009*dr.2021-01-01.Economically inactive: Looking after home or family (Census 2021)": "8,464",
        "E92000001.ks601ew0009*dr.2021-01-01.Economically inactive: Looking after home or family (Census 2021)": "2,207,738",
        "E12000008.ks601ew0009*dr.2021-01-01.Economically inactive: Looking after home or family (Census 2021)": "333,553",
        "CSTM5160.ks601ew0010*dr.2021-01-01.Economically inactive: Long-term sick or disabled (Census 2021)": "10,400",
        "E92000001.ks601ew0010*dr.2021-01-01.Economically inactive: Long-term sick or disabled (Census 2021)": "1,874,300",
        "E12000008.ks601ew0010*dr.2021-01-01.Economically inactive: Long-term sick or disabled (Census 2021)": "234,601",
        "CSTM5160.ks601ew0011*dr.2021-01-01.Economically inactive: Other (Census 2021)": "7,176",
        "E92000001.ks601ew0011*dr.2021-01-01.Economically inactive: Other (Census 2021)": "1,445,910",
        "E12000008.ks601ew0011*dr.2021-01-01.Economically inactive: Other (Census 2021)": "201,978",
        "CSTM5160.ks604ew0002*dr.2021-01-01.Part-time: 15 hours or less worked (Census 2021)": "18,422",
        "E92000001.ks604ew0002*dr.2021-01-01.Part-time: 15 hours or less worked (Census 2021)": "2,731,449",
        "E12000008.ks604ew0002*dr.2021-01-01.Part-time: 15 hours or less worked (Census 2021)": "490,872",
        "CSTM5160.ks604ew0003*dr.2021-01-01.Part-time: 16 to 30 hours worked (Census 2021)": "28,832",
        "E92000001.ks604ew0003*dr.2021-01-01.Part-time: 16 to 30 hours worked (Census 2021)": "5,139,989",
        "E12000008.ks604ew0003*dr.2021-01-01.Part-time: 16 to 30 hours worked (Census 2021)": "829,962",
        "CSTM5160.ks604ew0004*dr.2021-01-01.Full-time: 31 to 48 hours worked (Census 2021)": "79,077",
        "E92000001.ks604ew0004*dr.2021-01-01.Full-time: 31 to 48 hours worked (Census 2021)": "15,608,863",
        "E12000008.ks604ew0004*dr.2021-01-01.Full-time: 31 to 48 hours worked (Census 2021)": "2,618,494",
        "CSTM5160.ks604ew0005*dr.2021-01-01.Full-time: 49 or more hours worked (Census 2021)": "12,960",
        "E92000001.ks604ew0005*dr.2021-01-01.Full-time: 49 or more hours worked (Census 2021)": "2,924,913",
        "E12000008.ks604ew0005*dr.2021-01-01.Full-time: 49 or more hours worked (Census 2021)": "532,451",
        "CSTM5160.ks608ew0002*dr.2021-01-01.Occupation group: Managers, directors and senior officials (Census 2021)": "19,701",
        "E92000001.ks608ew0002*dr.2021-01-01.Occupation group: Managers, directors and senior officials (Census 2021)": "3,403,916",
        "E12000008.ks608ew0002*dr.2021-01-01.Occupation group: Managers, directors and senior officials (Census 2021)": "665,423",
        "CSTM5160.ks608ew0003*dr.2021-01-01.Occupation group: Professional occupations (Census 2021)": "36,478",
        "E92000001.ks608ew0003*dr.2021-01-01.Occupation group: Professional occupations (Census 2021)": "5,356,649",
        "E12000008.ks608ew0003*dr.2021-01-01.Occupation group: Professional occupations (Census 2021)": "948,916",
        "CSTM5160.ks608ew0004*dr.2021-01-01.Occupation group: Associate professional and technical occupations (Census 2021)": "23,620",
        "E92000001.ks608ew0004*dr.2021-01-01.Occupation group: Associate professional and technical occupations (Census 2021)": "3,499,749",
        "E12000008.ks608ew0004*dr.2021-01-01.Occupation group: Associate professional and technical occupations (Census 2021)": "637,552",
        "CSTM5160.ks608ew0005*dr.2021-01-01.Occupation group: Administrative and secretarial occupations (Census 2021)": "10,344",
        "E92000001.ks608ew0005*dr.2021-01-01.Occupation group: Administrative and secretarial occupations (Census 2021)": "2,446,565",
        "E12000008.ks608ew0005*dr.2021-01-01.Occupation group: Administrative and secretarial occupations (Census 2021)": "418,153",
        "CSTM5160.ks608ew0006*dr.2021-01-01.Occupation group: Skilled trades occupations (Census 2021)": "10,753",
        "E92000001.ks608ew0006*dr.2021-01-01.Occupation group: Skilled trades occupations (Census 2021)": "2,683,139",
        "E12000008.ks608ew0006*dr.2021-01-01.Occupation group: Skilled trades occupations (Census 2021)": "447,317",
        "CSTM5160.ks608ew0007*dr.2021-01-01.Occupation group: Caring, leisure and other service occupations (Census 2021)": "12,075",
        "E92000001.ks608ew0007*dr.2021-01-01.Occupation group: Caring, leisure and other service occupations (Census 2021)": "2,447,148",
        "E12000008.ks608ew0007*dr.2021-01-01.Occupation group: Caring, leisure and other service occupations (Census 2021)": "406,891",
        "CSTM5160.ks608ew0008*dr.2021-01-01.Occupation group: Sales and customer service occupations (Census 2021)": "10,144",
        "E92000001.ks608ew0008*dr.2021-01-01.Occupation group: Sales and customer service occupations (Census 2021)": "1,972,553",
        "E12000008.ks608ew0008*dr.2021-01-01.Occupation group: Sales and customer service occupations (Census 2021)": "304,995",
        "CSTM5160.ks608ew0009*dr.2021-01-01.Occupation group: Process, plant and machine operatives (Census 2021)": "5,135",
        "E92000001.ks608ew0009*dr.2021-01-01.Occupation group: Process, plant and machine operatives (Census 2021)": "1,832,666",
        "E12000008.ks608ew0009*dr.2021-01-01.Occupation group: Process, plant and machine operatives (Census 2021)": "248,363",
        "CSTM5160.ks608ew0010*dr.2021-01-01.Occupation group: Elementary occupations (Census 2021)": "11,042",
        "E92000001.ks608ew0010*dr.2021-01-01.Occupation group: Elementary occupations (Census 2021)": "2,762,829",
        "E12000008.ks608ew0010*dr.2021-01-01.Occupation group: Elementary occupations (Census 2021)": "394,167",
        "CSTM5160.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021)": "29,215",
        "E92000001.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021)": "8,317,789",
        "E12000008.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021)": "1,162,221",
        "CSTM5160.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021)": "16,545",
        "E92000001.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021)": "4,456,198",
        "E12000008.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021)": "739,122",
        "CSTM5160.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021)": "25,067",
        "E92000001.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021)": "6,126,130",
        "E12000008.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021)": "1,046,800",
        "CSTM5160.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021)": "8,052",
        "E92000001.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021)": "2,446,935",
        "E12000008.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021)": "386,759",
        "CSTM5160.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021)": "46,359",
        "E92000001.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021)": "7,784,977",
        "E12000008.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021)": "1,313,552",
        "CSTM5160.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021)": "104,790",
        "E92000001.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021)": "15,606,458",
        "E12000008.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021)": "2,702,048",
        "CSTM5160.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021)": "5,339",
        "E92000001.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021)": "1,268,468",
        "E12000008.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021)": "204,078",
        "CSTM5160.ks501ew0002*dr.2011-01-01.People with no qualifications (E&W Census 2011)": "36,775",
        "E92000001.ks501ew0002*dr.2011-01-01.People with no qualifications (E&W Census 2011)": "9,656,810",
        "E12000008.ks501ew0002*dr.2011-01-01.People with no qualifications (E&W Census 2011)": "1,333,955",
        "CSTM5160.ks501ew0007*dr.2011-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications  (E&W Census 2011)": "84,423",
        "E92000001.ks501ew0007*dr.2011-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications  (E&W Census 2011)": "11,769,361",
        "E12000008.ks501ew0007*dr.2011-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications  (E&W Census 2011)": "2,093,693",
        "CSTM5160.polar_tundra_participation_num*dr.2019-09-01.Participation of state school pupils in higher education": "3,827",
        "E92000001.polar_tundra_participation_num*dr.2019-09-01.Participation of state school pupils in higher education": "1,110,817",
        "E12000008.polar_tundra_participation_num*dr.2019-09-01.Participation of state school pupils in higher education": "170,612",
        "CSTM5160.edu19_rank.2019-01-01.IoD 2019 Education, Skills and Training Rank": "18,757",
        "E92000001.edu19_rank.2019-01-01.IoD 2019 Education, Skills and Training Rank": "16,446",
        "E12000008.edu19_rank.2019-01-01.IoD 2019 Education, Skills and Training Rank": "18,524",
        "CSTM5160.educhi19_rank.2019-01-01.IoD 2019 Children and Young People Sub-domain Rank": "15,630",
        "E92000001.educhi19_rank.2019-01-01.IoD 2019 Children and Young People Sub-domain Rank": "16,433",
        "E12000008.educhi19_rank.2019-01-01.IoD 2019 Children and Young People Sub-domain Rank": "17,206",
        "CSTM5160.eduski19_rank.2019-01-01.IoD 2019 Adult Skills Sub-domain Rank": "22,523",
        "E92000001.eduski19_rank.2019-01-01.IoD 2019 Adult Skills Sub-domain Rank": "16,474",
        "E12000008.eduski19_rank.2019-01-01.IoD 2019 Adult Skills Sub-domain Rank": "19,620",
        "CSTM5160.id19_edupop_dec1*dr.2019-01-01.IoD 2019 Education Decile 1": "25,612",
        "E92000001.id19_edupop_dec1*dr.2019-01-01.IoD 2019 Education Decile 1": "5,493,853",
        "E12000008.id19_edupop_dec1*dr.2019-01-01.IoD 2019 Education Decile 1": "533,433",
        "CSTM5160.id19_edupop_dec2*dr.2019-01-01.IoD 2019 Education Decile 2": "22,852",
        "E92000001.id19_edupop_dec2*dr.2019-01-01.IoD 2019 Education Decile 2": "5,561,385",
        "E12000008.id19_edupop_dec2*dr.2019-01-01.IoD 2019 Education Decile 2": "668,279",
        "CSTM5160.id19_edupop_dec3*dr.2019-01-01.IoD 2019 Education Decile 3": "12,659",
        "E92000001.id19_edupop_dec3*dr.2019-01-01.IoD 2019 Education Decile 3": "5,598,285",
        "E12000008.id19_edupop_dec3*dr.2019-01-01.IoD 2019 Education Decile 3": "820,435",
        "CSTM5160.id19_edupop_dec4*dr.2019-01-01.IoD 2019 Education Decile 4": "28,907",
        "E92000001.id19_edupop_dec4*dr.2019-01-01.IoD 2019 Education Decile 4": "5,595,658",
        "E12000008.id19_edupop_dec4*dr.2019-01-01.IoD 2019 Education Decile 4": "845,536",
        "CSTM5160.id19_edupop_dec5*dr.2019-01-01.IoD 2019 Education Decile 5": "23,059",
        "E92000001.id19_edupop_dec5*dr.2019-01-01.IoD 2019 Education Decile 5": "5,618,025",
        "E12000008.id19_edupop_dec5*dr.2019-01-01.IoD 2019 Education Decile 5": "877,620",
        "CSTM5160.id19_edupop_dec6*dr.2019-01-01.IoD 2019 Education Decile 6": "19,953",
        "E92000001.id19_edupop_dec6*dr.2019-01-01.IoD 2019 Education Decile 6": "5,624,960",
        "E12000008.id19_edupop_dec6*dr.2019-01-01.IoD 2019 Education Decile 6": "936,172",
        "CSTM5160.id19_edupop_dec7*dr.2019-01-01.IoD 2019 Education Decile 7": "32,649",
        "E92000001.id19_edupop_dec7*dr.2019-01-01.IoD 2019 Education Decile 7": "5,617,581",
        "E12000008.id19_edupop_dec7*dr.2019-01-01.IoD 2019 Education Decile 7": "992,429",
        "CSTM5160.id19_edupop_dec8*dr.2019-01-01.IoD 2019 Education Decile 8": "38,881",
        "E92000001.id19_edupop_dec8*dr.2019-01-01.IoD 2019 Education Decile 8": "5,559,729",
        "E12000008.id19_edupop_dec8*dr.2019-01-01.IoD 2019 Education Decile 8": "1,032,564",
        "CSTM5160.id19_edupop_dec9*dr.2019-01-01.IoD 2019 Education Decile 9": "46,168",
        "E92000001.id19_edupop_dec9*dr.2019-01-01.IoD 2019 Education Decile 9": "5,537,319",
        "E12000008.id19_edupop_dec9*dr.2019-01-01.IoD 2019 Education Decile 9": "1,157,863",
        "CSTM5160.id19_edupop_dec10*dr.2019-01-01.IoD 2019 Education Decile 10": "37,415",
        "E92000001.id19_edupop_dec10*dr.2019-01-01.IoD 2019 Education Decile 10": "5,412,384",
        "E12000008.id19_edupop_dec10*dr.2019-01-01.IoD 2019 Education Decile 10": "1,216,430",
        "CSTM5160.idaci15_num*dr.2012-08-01.IoD 2015 Income Deprivation Affecting Children Index (IDACI) Score (rate)": null,
        "E92000001.idaci15_num*dr.2012-08-01.IoD 2015 Income Deprivation Affecting Children Index (IDACI) Score (rate)": null,
        "E12000008.idaci15_num*dr.2012-08-01.IoD 2015 Income Deprivation Affecting Children Index (IDACI) Score (rate)": null,
        "CSTM5160.idaci19_num*dr.2015-08-01.IoD 2019 Income Deprivation Affecting Children Index (IDACI) Score (rate)": "6,968",
        "E92000001.idaci19_num*dr.2015-08-01.IoD 2019 Income Deprivation Affecting Children Index (IDACI) Score (rate)": "1,777,626",
        "E12000008.idaci19_num*dr.2015-08-01.IoD 2019 Income Deprivation Affecting Children Index (IDACI) Score (rate)": "211,000",
        "CSTM5160.child_abso_lif_0_19*dr.2023-01-01.Children aged 0-19 in absolute low-income families": "5,788",
        "E92000001.child_abso_lif_0_19*dr.2023-01-01.Children aged 0-19 in absolute low-income families": "2,056,492",
        "E12000008.child_abso_lif_0_19*dr.2023-01-01.Children aged 0-19 in absolute low-income families": "231,786",
        "CSTM5160.child_abso_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in absolute low-income out of work families (as % of all in absolute low-income families)": "1,868",
        "E92000001.child_abso_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in absolute low-income out of work families (as % of all in absolute low-income families)": "691,726",
        "E12000008.child_abso_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in absolute low-income out of work families (as % of all in absolute low-income families)": "70,842",
        "CSTM5160.child_abso_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in absolute low-income in-work families (as % of all in absolute low-income families)": "3,918",
        "E92000001.child_abso_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in absolute low-income in-work families (as % of all in absolute low-income families)": "1,364,762",
        "E12000008.child_abso_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in absolute low-income in-work families (as % of all in absolute low-income families)": "160,946",
        "CSTM5160.child_abso_lif_0_19_lone*dr.2023-01-01.Children 0-19 in absolute low-income lone parent families (as % of all in absolute low-income families)": "2,983",
        "E92000001.child_abso_lif_0_19_lone*dr.2023-01-01.Children 0-19 in absolute low-income lone parent families (as % of all in absolute low-income families)": "997,216",
        "E12000008.child_abso_lif_0_19_lone*dr.2023-01-01.Children 0-19 in absolute low-income lone parent families (as % of all in absolute low-income families)": "118,117",
        "CSTM5160.child_abso_lif_0_19_couple*dr.2023-01-01.Children 0-19 in absolute low-income couple families (as % of all in absolute low-income families)": "2,800",
        "E92000001.child_abso_lif_0_19_couple*dr.2023-01-01.Children 0-19 in absolute low-income couple families (as % of all in absolute low-income families)": "1,059,269",
        "E12000008.child_abso_lif_0_19_couple*dr.2023-01-01.Children 0-19 in absolute low-income couple families (as % of all in absolute low-income families)": "113,671",
        "CSTM5160.child_r_lif_0_19*dr.2023-01-01.Children aged 0-19 in relative low-income families": "7,048",
        "E92000001.child_r_lif_0_19*dr.2023-01-01.Children aged 0-19 in relative low-income families": "2,599,372",
        "E12000008.child_r_lif_0_19*dr.2023-01-01.Children aged 0-19 in relative low-income families": "285,490",
        "CSTM5160.child_r_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in relative low-income out of work families (as % of all in relative low-income families)": "1,893",
        "E92000001.child_r_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in relative low-income out of work families (as % of all in relative low-income families)": "858,201",
        "E12000008.child_r_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in relative low-income out of work families (as % of all in relative low-income families)": "72,413",
        "CSTM5160.child_r_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in relative low-income in-work families (as % of all in relative low-income families)": "5,152",
        "E92000001.child_r_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in relative low-income in-work families (as % of all in relative low-income families)": "1,741,171",
        "E12000008.child_r_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in relative low-income in-work families (as % of all in relative low-income families)": "213,078",
        "CSTM5160.child_r_lif_0_19_lone*dr.2023-01-01.Children 0-19 in relative low-income lone parent families (as % of all in relative low-income families)": "3,597",
        "E92000001.child_r_lif_0_19_lone*dr.2023-01-01.Children 0-19 in relative low-income lone parent families (as % of all in relative low-income families)": "1,307,050",
        "E12000008.child_r_lif_0_19_lone*dr.2023-01-01.Children 0-19 in relative low-income lone parent families (as % of all in relative low-income families)": "145,380",
        "CSTM5160.child_r_lif_0_19_couple*dr.2023-01-01.Children 0-19 in relative low-income couple families (as % of all in relative low-income families)": "3,446",
        "E92000001.child_r_lif_0_19_couple*dr.2023-01-01.Children 0-19 in relative low-income couple families (as % of all in relative low-income families)": "1,292,319",
        "E12000008.child_r_lif_0_19_couple*dr.2023-01-01.Children 0-19 in relative low-income couple families (as % of all in relative low-income families)": "140,115",
        "CSTM5160.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021)": "357",
        "E92000001.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021)": "78,810",
        "E12000008.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021)": "14,514",
        "CSTM5160.low_birth_weight_n*dr.2013-01-01.Low birth weight": "1,153",
        "E92000001.low_birth_weight_n*dr.2013-01-01.Low birth weight": "249,929",
        "E12000008.low_birth_weight_n*dr.2013-01-01.Low birth weight": "34,837",
        "CSTM5160.mortality_under28_days*dr.2018-01-01.Deaths under 28 days (neonatal mortality), all causes": "31",
        "E92000001.mortality_under28_days*dr.2018-01-01.Deaths under 28 days (neonatal mortality), all causes": "8,561",
        "E12000008.mortality_under28_days*dr.2018-01-01.Deaths under 28 days (neonatal mortality), all causes": "1,164",
        "CSTM5160.mortality_under1_all*dr.2018-01-01.Deaths under 1 (infant mortality), all causes": "39",
        "E92000001.mortality_under1_all*dr.2018-01-01.Deaths under 1 (infant mortality), all causes": "11,780",
        "E12000008.mortality_under1_all*dr.2018-01-01.Deaths under 1 (infant mortality), all causes": "1,014",
        "CSTM5160.mortality_0_4_all_cause*dr.2018-01-01.Deaths under 5 (child mortality), all causes": "39",
        "E92000001.mortality_0_4_all_cause*dr.2018-01-01.Deaths under 5 (child mortality), all causes": "13,443",
        "E12000008.mortality_0_4_all_cause*dr.2018-01-01.Deaths under 5 (child mortality), all causes": "1,174",
        "CSTM5160.mortality_0_14_all_cause*dr.2018-01-01.Deaths under 15, all causes": "39",
        "E92000001.mortality_0_14_all_cause*dr.2018-01-01.Deaths under 15, all causes": "16,004",
        "E12000008.mortality_0_14_all_cause*dr.2018-01-01.Deaths under 15, all causes": "1,413",
        "CSTM5160.a_and_e_attend_rate_children_under_5.2017-04-01.A&E attendance for under 5s": "679.69",
        "E92000001.a_and_e_attend_rate_children_under_5.2017-04-01.A&E attendance for under 5s": "629.73",
        "E12000008.a_and_e_attend_rate_children_under_5.2017-04-01.A&E attendance for under 5s": "561.74",
        "CSTM5160.emergency_hosp_children_under_5.2018-01-01.Emergency Hospital Admissions: All Causes Under 5s": "118.89",
        "E92000001.emergency_hosp_children_under_5.2018-01-01.Emergency Hospital Admissions: All Causes Under 5s": "141.62",
        "E12000008.emergency_hosp_children_under_5.2018-01-01.Emergency Hospital Admissions: All Causes Under 5s": "129.78",
        "CSTM5160.obese_child_reception_yr_n.2020-01-01.Reception prevalence of obesity (including severe obesity), 3 years data combined": "7.49%",
        "E92000001.obese_child_reception_yr_n.2020-01-01.Reception prevalence of obesity (including severe obesity), 3 years data combined": "9.69%",
        "E12000008.obese_child_reception_yr_n.2020-01-01.Reception prevalence of obesity (including severe obesity), 3 years data combined": "8.48%",
        "CSTM5160.obese_child_yr_6_n.2020-01-01.Year 6 prevalence of obesity (including severe obesity), 3 years data combined": "16.79%",
        "E92000001.obese_child_yr_6_n.2020-01-01.Year 6 prevalence of obesity (including severe obesity), 3 years data combined": "22.47%",
        "E12000008.obese_child_yr_6_n.2020-01-01.Year 6 prevalence of obesity (including severe obesity), 3 years data combined": "19.08%",
        "CSTM5160.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP)": "15,894",
        "E92000001.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP)": "3,210,025",
        "E12000008.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP)": "394,965",
        "CSTM5160.dla_total*dr.2024-02-01.Disability benefit (DLA)": "6,103",
        "E92000001.dla_total*dr.2024-02-01.Disability benefit (DLA)": "1,173,036",
        "E12000008.dla_total*dr.2024-02-01.Disability benefit (DLA)": "187,109",
        "CSTM5160.aac_tot_count*dr.2024-02-01.Older people social care benefit (Attendance Allowance)": "4,526",
        "E92000001.aac_tot_count*dr.2024-02-01.Older people social care benefit (Attendance Allowance)": "1,352,651",
        "E12000008.aac_tot_count*dr.2024-02-01.Older people social care benefit (Attendance Allowance)": "210,313",
        "CSTM5160.pip_tot*dr.2013-04-01.Personal Independence Payment (PIP)": "0",
        "E92000001.pip_tot*dr.2013-04-01.Personal Independence Payment (PIP)": "0",
        "E12000008.pip_tot*dr.2013-04-01.Personal Independence Payment (PIP)": "0",
        "CSTM5160.pip_tot*dr.2013-05-01.Personal Independence Payment (PIP)": "0",
        "E92000001.pip_tot*dr.2013-05-01.Personal Independence Payment (PIP)": "253",
        "E12000008.pip_tot*dr.2013-05-01.Personal Independence Payment (PIP)": "0",
        "CSTM5160.pip_tot*dr.2013-06-01.Personal Independence Payment (PIP)": "0",
        "E92000001.pip_tot*dr.2013-06-01.Personal Independence Payment (PIP)": "525",
        "E12000008.pip_tot*dr.2013-06-01.Personal Independence Payment (PIP)": "0",
        "CSTM5160.pip_tot*dr.2013-07-01.Personal Independence Payment (PIP)": "0",
        "E92000001.pip_tot*dr.2013-07-01.Personal Independence Payment (PIP)": "1,634",
        "E12000008.pip_tot*dr.2013-07-01.Personal Independence Payment (PIP)": "109",
        "CSTM5160.pip_tot*dr.2013-08-01.Personal Independence Payment (PIP)": "12",
        "E92000001.pip_tot*dr.2013-08-01.Personal Independence Payment (PIP)": "3,503",
        "E12000008.pip_tot*dr.2013-08-01.Personal Independence Payment (PIP)": "400",
        "CSTM5160.pip_tot*dr.2013-09-01.Personal Independence Payment (PIP)": "14",
        "E92000001.pip_tot*dr.2013-09-01.Personal Independence Payment (PIP)": "5,419",
        "E12000008.pip_tot*dr.2013-09-01.Personal Independence Payment (PIP)": "608",
        "CSTM5160.pip_tot*dr.2013-10-01.Personal Independence Payment (PIP)": "19",
        "E92000001.pip_tot*dr.2013-10-01.Personal Independence Payment (PIP)": "7,949",
        "E12000008.pip_tot*dr.2013-10-01.Personal Independence Payment (PIP)": "862",
        "CSTM5160.pip_tot*dr.2013-11-01.Personal Independence Payment (PIP)": "33",
        "E92000001.pip_tot*dr.2013-11-01.Personal Independence Payment (PIP)": "11,942",
        "E12000008.pip_tot*dr.2013-11-01.Personal Independence Payment (PIP)": "1,269",
        "CSTM5160.pip_tot*dr.2013-12-01.Personal Independence Payment (PIP)": "45",
        "E92000001.pip_tot*dr.2013-12-01.Personal Independence Payment (PIP)": "16,119",
        "E12000008.pip_tot*dr.2013-12-01.Personal Independence Payment (PIP)": "1,671",
        "CSTM5160.pip_tot*dr.2014-01-01.Personal Independence Payment (PIP)": "52",
        "E92000001.pip_tot*dr.2014-01-01.Personal Independence Payment (PIP)": "22,591",
        "E12000008.pip_tot*dr.2014-01-01.Personal Independence Payment (PIP)": "2,297",
        "CSTM5160.pip_tot*dr.2014-02-01.Personal Independence Payment (PIP)": "71",
        "E92000001.pip_tot*dr.2014-02-01.Personal Independence Payment (PIP)": "28,958",
        "E12000008.pip_tot*dr.2014-02-01.Personal Independence Payment (PIP)": "2,941",
        "CSTM5160.pip_tot*dr.2014-03-01.Personal Independence Payment (PIP)": "96",
        "E92000001.pip_tot*dr.2014-03-01.Personal Independence Payment (PIP)": "37,434",
        "E12000008.pip_tot*dr.2014-03-01.Personal Independence Payment (PIP)": "3,782",
        "CSTM5160.pip_tot*dr.2014-04-01.Personal Independence Payment (PIP)": "132",
        "E92000001.pip_tot*dr.2014-04-01.Personal Independence Payment (PIP)": "46,647",
        "E12000008.pip_tot*dr.2014-04-01.Personal Independence Payment (PIP)": "4,497",
        "CSTM5160.pip_tot*dr.2014-05-01.Personal Independence Payment (PIP)": "198",
        "E92000001.pip_tot*dr.2014-05-01.Personal Independence Payment (PIP)": "56,661",
        "E12000008.pip_tot*dr.2014-05-01.Personal Independence Payment (PIP)": "5,886",
        "CSTM5160.pip_tot*dr.2014-06-01.Personal Independence Payment (PIP)": "245",
        "E92000001.pip_tot*dr.2014-06-01.Personal Independence Payment (PIP)": "68,264",
        "E12000008.pip_tot*dr.2014-06-01.Personal Independence Payment (PIP)": "7,116",
        "CSTM5160.pip_tot*dr.2014-07-01.Personal Independence Payment (PIP)": "317",
        "E92000001.pip_tot*dr.2014-07-01.Personal Independence Payment (PIP)": "85,660",
        "E12000008.pip_tot*dr.2014-07-01.Personal Independence Payment (PIP)": "8,665",
        "CSTM5160.pip_tot*dr.2014-08-01.Personal Independence Payment (PIP)": "419",
        "E92000001.pip_tot*dr.2014-08-01.Personal Independence Payment (PIP)": "111,423",
        "E12000008.pip_tot*dr.2014-08-01.Personal Independence Payment (PIP)": "10,866",
        "CSTM5160.pip_tot*dr.2014-09-01.Personal Independence Payment (PIP)": "459",
        "E92000001.pip_tot*dr.2014-09-01.Personal Independence Payment (PIP)": "133,488",
        "E12000008.pip_tot*dr.2014-09-01.Personal Independence Payment (PIP)": "12,444",
        "CSTM5160.pip_tot*dr.2014-10-01.Personal Independence Payment (PIP)": "554",
        "E92000001.pip_tot*dr.2014-10-01.Personal Independence Payment (PIP)": "164,643",
        "E12000008.pip_tot*dr.2014-10-01.Personal Independence Payment (PIP)": "14,922",
        "CSTM5160.pip_tot*dr.2014-11-01.Personal Independence Payment (PIP)": "659",
        "E92000001.pip_tot*dr.2014-11-01.Personal Independence Payment (PIP)": "199,163",
        "E12000008.pip_tot*dr.2014-11-01.Personal Independence Payment (PIP)": "17,862",
        "CSTM5160.pip_tot*dr.2014-12-01.Personal Independence Payment (PIP)": "781",
        "E92000001.pip_tot*dr.2014-12-01.Personal Independence Payment (PIP)": "232,767",
        "E12000008.pip_tot*dr.2014-12-01.Personal Independence Payment (PIP)": "21,080",
        "CSTM5160.pip_tot*dr.2015-01-01.Personal Independence Payment (PIP)": "968",
        "E92000001.pip_tot*dr.2015-01-01.Personal Independence Payment (PIP)": "272,906",
        "E12000008.pip_tot*dr.2015-01-01.Personal Independence Payment (PIP)": "25,522",
        "CSTM5160.pip_tot*dr.2015-02-01.Personal Independence Payment (PIP)": "1,135",
        "E92000001.pip_tot*dr.2015-02-01.Personal Independence Payment (PIP)": "308,316",
        "E12000008.pip_tot*dr.2015-02-01.Personal Independence Payment (PIP)": "29,146",
        "CSTM5160.pip_tot*dr.2015-03-01.Personal Independence Payment (PIP)": "1,319",
        "E92000001.pip_tot*dr.2015-03-01.Personal Independence Payment (PIP)": "338,975",
        "E12000008.pip_tot*dr.2015-03-01.Personal Independence Payment (PIP)": "32,245",
        "CSTM5160.pip_tot*dr.2015-04-01.Personal Independence Payment (PIP)": "1,468",
        "E92000001.pip_tot*dr.2015-04-01.Personal Independence Payment (PIP)": "367,589",
        "E12000008.pip_tot*dr.2015-04-01.Personal Independence Payment (PIP)": "35,642",
        "CSTM5160.pip_tot*dr.2015-05-01.Personal Independence Payment (PIP)": "1,549",
        "E92000001.pip_tot*dr.2015-05-01.Personal Independence Payment (PIP)": "371,677",
        "E12000008.pip_tot*dr.2015-05-01.Personal Independence Payment (PIP)": "36,305",
        "CSTM5160.pip_tot*dr.2015-06-01.Personal Independence Payment (PIP)": "1,704",
        "E92000001.pip_tot*dr.2015-06-01.Personal Independence Payment (PIP)": "398,792",
        "E12000008.pip_tot*dr.2015-06-01.Personal Independence Payment (PIP)": "39,712",
        "CSTM5160.pip_tot*dr.2015-07-01.Personal Independence Payment (PIP)": "1,860",
        "E92000001.pip_tot*dr.2015-07-01.Personal Independence Payment (PIP)": "423,779",
        "E12000008.pip_tot*dr.2015-07-01.Personal Independence Payment (PIP)": "43,058",
        "CSTM5160.pip_tot*dr.2015-08-01.Personal Independence Payment (PIP)": "1,949",
        "E92000001.pip_tot*dr.2015-08-01.Personal Independence Payment (PIP)": "442,336",
        "E12000008.pip_tot*dr.2015-08-01.Personal Independence Payment (PIP)": "45,125",
        "CSTM5160.pip_tot*dr.2015-09-01.Personal Independence Payment (PIP)": "2,082",
        "E92000001.pip_tot*dr.2015-09-01.Personal Independence Payment (PIP)": "466,124",
        "E12000008.pip_tot*dr.2015-09-01.Personal Independence Payment (PIP)": "47,946",
        "CSTM5160.pip_tot*dr.2015-10-01.Personal Independence Payment (PIP)": "2,206",
        "E92000001.pip_tot*dr.2015-10-01.Personal Independence Payment (PIP)": "490,820",
        "E12000008.pip_tot*dr.2015-10-01.Personal Independence Payment (PIP)": "50,838",
        "CSTM5160.pip_tot*dr.2015-11-01.Personal Independence Payment (PIP)": "2,329",
        "E92000001.pip_tot*dr.2015-11-01.Personal Independence Payment (PIP)": "512,516",
        "E12000008.pip_tot*dr.2015-11-01.Personal Independence Payment (PIP)": "53,762",
        "CSTM5160.pip_tot*dr.2015-12-01.Personal Independence Payment (PIP)": "2,443",
        "E92000001.pip_tot*dr.2015-12-01.Personal Independence Payment (PIP)": "534,610",
        "E12000008.pip_tot*dr.2015-12-01.Personal Independence Payment (PIP)": "56,493",
        "CSTM5160.pip_tot*dr.2016-01-01.Personal Independence Payment (PIP)": "2,568",
        "E92000001.pip_tot*dr.2016-01-01.Personal Independence Payment (PIP)": "557,290",
        "E12000008.pip_tot*dr.2016-01-01.Personal Independence Payment (PIP)": "59,445",
        "CSTM5160.pip_tot*dr.2016-02-01.Personal Independence Payment (PIP)": "2,723",
        "E92000001.pip_tot*dr.2016-02-01.Personal Independence Payment (PIP)": "584,535",
        "E12000008.pip_tot*dr.2016-02-01.Personal Independence Payment (PIP)": "63,207",
        "CSTM5160.pip_tot*dr.2016-03-01.Personal Independence Payment (PIP)": "2,929",
        "E92000001.pip_tot*dr.2016-03-01.Personal Independence Payment (PIP)": "615,771",
        "E12000008.pip_tot*dr.2016-03-01.Personal Independence Payment (PIP)": "67,270",
        "CSTM5160.pip_tot*dr.2016-04-01.Personal Independence Payment (PIP)": "3,161",
        "E92000001.pip_tot*dr.2016-04-01.Personal Independence Payment (PIP)": "649,611",
        "E12000008.pip_tot*dr.2016-04-01.Personal Independence Payment (PIP)": "72,130",
        "CSTM5160.pip_tot*dr.2016-07-01.Personal Independence Payment (PIP)": "3,728",
        "E92000001.pip_tot*dr.2016-07-01.Personal Independence Payment (PIP)": "725,463",
        "E12000008.pip_tot*dr.2016-07-01.Personal Independence Payment (PIP)": "85,751",
        "CSTM5160.pip_tot*dr.2016-08-01.Personal Independence Payment (PIP)": "3,986",
        "E92000001.pip_tot*dr.2016-08-01.Personal Independence Payment (PIP)": "790,970",
        "E12000008.pip_tot*dr.2016-08-01.Personal Independence Payment (PIP)": "90,864",
        "CSTM5160.pip_tot*dr.2016-09-01.Personal Independence Payment (PIP)": "4,198",
        "E92000001.pip_tot*dr.2016-09-01.Personal Independence Payment (PIP)": "834,447",
        "E12000008.pip_tot*dr.2016-09-01.Personal Independence Payment (PIP)": "96,058",
        "CSTM5160.pip_tot*dr.2016-10-01.Personal Independence Payment (PIP)": "4,462",
        "E92000001.pip_tot*dr.2016-10-01.Personal Independence Payment (PIP)": "878,818",
        "E12000008.pip_tot*dr.2016-10-01.Personal Independence Payment (PIP)": "102,039",
        "CSTM5160.pip_tot*dr.2016-11-01.Personal Independence Payment (PIP)": "4,721",
        "E92000001.pip_tot*dr.2016-11-01.Personal Independence Payment (PIP)": "921,219",
        "E12000008.pip_tot*dr.2016-11-01.Personal Independence Payment (PIP)": "107,650",
        "CSTM5160.pip_tot*dr.2016-12-01.Personal Independence Payment (PIP)": "4,911",
        "E92000001.pip_tot*dr.2016-12-01.Personal Independence Payment (PIP)": "957,150",
        "E12000008.pip_tot*dr.2016-12-01.Personal Independence Payment (PIP)": "112,156",
        "CSTM5160.pip_tot*dr.2017-01-01.Personal Independence Payment (PIP)": "5,160",
        "E92000001.pip_tot*dr.2017-01-01.Personal Independence Payment (PIP)": "996,588",
        "E12000008.pip_tot*dr.2017-01-01.Personal Independence Payment (PIP)": "117,475",
        "CSTM5160.pip_tot*dr.2017-02-01.Personal Independence Payment (PIP)": "5,428",
        "E92000001.pip_tot*dr.2017-02-01.Personal Independence Payment (PIP)": "1,032,978",
        "E12000008.pip_tot*dr.2017-02-01.Personal Independence Payment (PIP)": "122,544",
        "CSTM5160.pip_tot*dr.2017-03-01.Personal Independence Payment (PIP)": "5,672",
        "E92000001.pip_tot*dr.2017-03-01.Personal Independence Payment (PIP)": "1,075,019",
        "E12000008.pip_tot*dr.2017-03-01.Personal Independence Payment (PIP)": "128,226",
        "CSTM5160.pip_tot*dr.2017-04-01.Personal Independence Payment (PIP)": "5,832",
        "E92000001.pip_tot*dr.2017-04-01.Personal Independence Payment (PIP)": "1,105,614",
        "E12000008.pip_tot*dr.2017-04-01.Personal Independence Payment (PIP)": "132,585",
        "CSTM5160.pip_tot*dr.2017-05-01.Personal Independence Payment (PIP)": "6,023",
        "E92000001.pip_tot*dr.2017-05-01.Personal Independence Payment (PIP)": "1,142,360",
        "E12000008.pip_tot*dr.2017-05-01.Personal Independence Payment (PIP)": "137,386",
        "CSTM5160.pip_tot*dr.2017-06-01.Personal Independence Payment (PIP)": "6,236",
        "E92000001.pip_tot*dr.2017-06-01.Personal Independence Payment (PIP)": "1,177,392",
        "E12000008.pip_tot*dr.2017-06-01.Personal Independence Payment (PIP)": "142,207",
        "CSTM5160.pip_tot*dr.2017-07-01.Personal Independence Payment (PIP)": "6,442",
        "E92000001.pip_tot*dr.2017-07-01.Personal Independence Payment (PIP)": "1,209,478",
        "E12000008.pip_tot*dr.2017-07-01.Personal Independence Payment (PIP)": "146,359",
        "CSTM5160.pip_tot*dr.2017-08-01.Personal Independence Payment (PIP)": "6,570",
        "E92000001.pip_tot*dr.2017-08-01.Personal Independence Payment (PIP)": "1,239,793",
        "E12000008.pip_tot*dr.2017-08-01.Personal Independence Payment (PIP)": "149,378",
        "CSTM5160.pip_tot*dr.2017-09-01.Personal Independence Payment (PIP)": "6,691",
        "E92000001.pip_tot*dr.2017-09-01.Personal Independence Payment (PIP)": "1,268,267",
        "E12000008.pip_tot*dr.2017-09-01.Personal Independence Payment (PIP)": "152,167",
        "CSTM5160.pip_tot*dr.2017-10-01.Personal Independence Payment (PIP)": "6,819",
        "E92000001.pip_tot*dr.2017-10-01.Personal Independence Payment (PIP)": "1,304,196",
        "E12000008.pip_tot*dr.2017-10-01.Personal Independence Payment (PIP)": "155,359",
        "CSTM5160.pip_tot*dr.2018-10-01.Personal Independence Payment (PIP)": "8,391",
        "E92000001.pip_tot*dr.2018-10-01.Personal Independence Payment (PIP)": "1,631,883",
        "E12000008.pip_tot*dr.2018-10-01.Personal Independence Payment (PIP)": "192,571",
        "CSTM5160.pip_tot*dr.2018-11-01.Personal Independence Payment (PIP)": "8,501",
        "E92000001.pip_tot*dr.2018-11-01.Personal Independence Payment (PIP)": "1,652,958",
        "E12000008.pip_tot*dr.2018-11-01.Personal Independence Payment (PIP)": "195,208",
        "CSTM5160.pip_tot*dr.2018-12-01.Personal Independence Payment (PIP)": "8,580",
        "E92000001.pip_tot*dr.2018-12-01.Personal Independence Payment (PIP)": "1,664,752",
        "E12000008.pip_tot*dr.2018-12-01.Personal Independence Payment (PIP)": "196,709",
        "CSTM5160.pip_tot*dr.2019-01-01.Personal Independence Payment (PIP)": "8,677",
        "E92000001.pip_tot*dr.2019-01-01.Personal Independence Payment (PIP)": "1,682,427",
        "E12000008.pip_tot*dr.2019-01-01.Personal Independence Payment (PIP)": "199,148",
        "CSTM5160.pip_tot*dr.2019-02-01.Personal Independence Payment (PIP)": "8,765",
        "E92000001.pip_tot*dr.2019-02-01.Personal Independence Payment (PIP)": "1,706,103",
        "E12000008.pip_tot*dr.2019-02-01.Personal Independence Payment (PIP)": "201,752",
        "CSTM5160.pip_tot*dr.2019-03-01.Personal Independence Payment (PIP)": "8,883",
        "E92000001.pip_tot*dr.2019-03-01.Personal Independence Payment (PIP)": "1,721,109",
        "E12000008.pip_tot*dr.2019-03-01.Personal Independence Payment (PIP)": "203,594",
        "CSTM5160.pip_tot*dr.2019-04-01.Personal Independence Payment (PIP)": "8,943",
        "E92000001.pip_tot*dr.2019-04-01.Personal Independence Payment (PIP)": "1,734,242",
        "E12000008.pip_tot*dr.2019-04-01.Personal Independence Payment (PIP)": "205,264",
        "CSTM5160.pip_tot*dr.2019-05-01.Personal Independence Payment (PIP)": "9,027",
        "E92000001.pip_tot*dr.2019-05-01.Personal Independence Payment (PIP)": "1,751,422",
        "E12000008.pip_tot*dr.2019-05-01.Personal Independence Payment (PIP)": "206,813",
        "CSTM5160.pip_tot*dr.2019-06-01.Personal Independence Payment (PIP)": "9,111",
        "E92000001.pip_tot*dr.2019-06-01.Personal Independence Payment (PIP)": "1,774,583",
        "E12000008.pip_tot*dr.2019-06-01.Personal Independence Payment (PIP)": "209,234",
        "CSTM5160.pip_tot*dr.2019-07-01.Personal Independence Payment (PIP)": "9,231",
        "E92000001.pip_tot*dr.2019-07-01.Personal Independence Payment (PIP)": "1,808,105",
        "E12000008.pip_tot*dr.2019-07-01.Personal Independence Payment (PIP)": "212,468",
        "CSTM5160.pip_tot*dr.2019-08-01.Personal Independence Payment (PIP)": "9,352",
        "E92000001.pip_tot*dr.2019-08-01.Personal Independence Payment (PIP)": "1,839,507",
        "E12000008.pip_tot*dr.2019-08-01.Personal Independence Payment (PIP)": "215,864",
        "CSTM5160.pip_tot*dr.2019-09-01.Personal Independence Payment (PIP)": "9,468",
        "E92000001.pip_tot*dr.2019-09-01.Personal Independence Payment (PIP)": "1,872,281",
        "E12000008.pip_tot*dr.2019-09-01.Personal Independence Payment (PIP)": "219,503",
        "CSTM5160.pip_tot*dr.2019-10-01.Personal Independence Payment (PIP)": "9,646",
        "E92000001.pip_tot*dr.2019-10-01.Personal Independence Payment (PIP)": "1,907,982",
        "E12000008.pip_tot*dr.2019-10-01.Personal Independence Payment (PIP)": "223,946",
        "CSTM5160.pip_tot*dr.2019-11-01.Personal Independence Payment (PIP)": "9,746",
        "E92000001.pip_tot*dr.2019-11-01.Personal Independence Payment (PIP)": "1,932,834",
        "E12000008.pip_tot*dr.2019-11-01.Personal Independence Payment (PIP)": "227,125",
        "CSTM5160.pip_tot*dr.2019-12-01.Personal Independence Payment (PIP)": "9,852",
        "E92000001.pip_tot*dr.2019-12-01.Personal Independence Payment (PIP)": "1,952,973",
        "E12000008.pip_tot*dr.2019-12-01.Personal Independence Payment (PIP)": "230,041",
        "CSTM5160.pip_tot*dr.2020-01-01.Personal Independence Payment (PIP)": "9,954",
        "E92000001.pip_tot*dr.2020-01-01.Personal Independence Payment (PIP)": "1,970,278",
        "E12000008.pip_tot*dr.2020-01-01.Personal Independence Payment (PIP)": "232,281",
        "CSTM5160.pip_tot*dr.2020-02-01.Personal Independence Payment (PIP)": "10,040",
        "E92000001.pip_tot*dr.2020-02-01.Personal Independence Payment (PIP)": "1,984,628",
        "E12000008.pip_tot*dr.2020-02-01.Personal Independence Payment (PIP)": "233,616",
        "CSTM5160.pip_tot*dr.2020-03-01.Personal Independence Payment (PIP)": "10,224",
        "E92000001.pip_tot*dr.2020-03-01.Personal Independence Payment (PIP)": "2,011,915",
        "E12000008.pip_tot*dr.2020-03-01.Personal Independence Payment (PIP)": "237,324",
        "CSTM5160.pip_tot*dr.2020-04-01.Personal Independence Payment (PIP)": "10,422",
        "E92000001.pip_tot*dr.2020-04-01.Personal Independence Payment (PIP)": "2,037,028",
        "E12000008.pip_tot*dr.2020-04-01.Personal Independence Payment (PIP)": "240,989",
        "CSTM5160.pip_tot*dr.2020-05-01.Personal Independence Payment (PIP)": "10,509",
        "E92000001.pip_tot*dr.2020-05-01.Personal Independence Payment (PIP)": "2,058,071",
        "E12000008.pip_tot*dr.2020-05-01.Personal Independence Payment (PIP)": "243,843",
        "CSTM5160.pip_tot*dr.2020-06-01.Personal Independence Payment (PIP)": "10,618",
        "E92000001.pip_tot*dr.2020-06-01.Personal Independence Payment (PIP)": "2,076,216",
        "E12000008.pip_tot*dr.2020-06-01.Personal Independence Payment (PIP)": "246,320",
        "CSTM5160.pip_tot*dr.2020-07-01.Personal Independence Payment (PIP)": "10,711",
        "E92000001.pip_tot*dr.2020-07-01.Personal Independence Payment (PIP)": "2,095,512",
        "E12000008.pip_tot*dr.2020-07-01.Personal Independence Payment (PIP)": "248,926",
        "CSTM5160.pip_tot*dr.2020-08-01.Personal Independence Payment (PIP)": "10,748",
        "E92000001.pip_tot*dr.2020-08-01.Personal Independence Payment (PIP)": "2,108,141",
        "E12000008.pip_tot*dr.2020-08-01.Personal Independence Payment (PIP)": "250,619",
        "CSTM5160.pip_tot*dr.2020-09-01.Personal Independence Payment (PIP)": "10,829",
        "E92000001.pip_tot*dr.2020-09-01.Personal Independence Payment (PIP)": "2,124,603",
        "E12000008.pip_tot*dr.2020-09-01.Personal Independence Payment (PIP)": "252,925",
        "CSTM5160.pip_tot*dr.2020-10-01.Personal Independence Payment (PIP)": "10,890",
        "E92000001.pip_tot*dr.2020-10-01.Personal Independence Payment (PIP)": "2,138,106",
        "E12000008.pip_tot*dr.2020-10-01.Personal Independence Payment (PIP)": "254,590",
        "CSTM5160.pip_tot*dr.2020-11-01.Personal Independence Payment (PIP)": "10,992",
        "E92000001.pip_tot*dr.2020-11-01.Personal Independence Payment (PIP)": "2,151,231",
        "E12000008.pip_tot*dr.2020-11-01.Personal Independence Payment (PIP)": "256,501",
        "CSTM5160.pip_tot*dr.2020-12-01.Personal Independence Payment (PIP)": "11,036",
        "E92000001.pip_tot*dr.2020-12-01.Personal Independence Payment (PIP)": "2,160,113",
        "E12000008.pip_tot*dr.2020-12-01.Personal Independence Payment (PIP)": "257,646",
        "CSTM5160.pip_tot*dr.2021-01-01.Personal Independence Payment (PIP)": "11,061",
        "E92000001.pip_tot*dr.2021-01-01.Personal Independence Payment (PIP)": "2,168,958",
        "E12000008.pip_tot*dr.2021-01-01.Personal Independence Payment (PIP)": "258,499",
        "CSTM5160.pip_tot*dr.2021-02-01.Personal Independence Payment (PIP)": "11,119",
        "E92000001.pip_tot*dr.2021-02-01.Personal Independence Payment (PIP)": "2,184,309",
        "E12000008.pip_tot*dr.2021-02-01.Personal Independence Payment (PIP)": "260,519",
        "CSTM5160.pip_tot*dr.2021-03-01.Personal Independence Payment (PIP)": "11,184",
        "E92000001.pip_tot*dr.2021-03-01.Personal Independence Payment (PIP)": "2,203,549",
        "E12000008.pip_tot*dr.2021-03-01.Personal Independence Payment (PIP)": "262,881",
        "CSTM5160.pip_tot*dr.2021-04-01.Personal Independence Payment (PIP)": "11,312",
        "E92000001.pip_tot*dr.2021-04-01.Personal Independence Payment (PIP)": "2,222,208",
        "E12000008.pip_tot*dr.2021-04-01.Personal Independence Payment (PIP)": "265,187",
        "CSTM5160.pip_tot*dr.2021-05-01.Personal Independence Payment (PIP)": "11,338",
        "E92000001.pip_tot*dr.2021-05-01.Personal Independence Payment (PIP)": "2,230,463",
        "E12000008.pip_tot*dr.2021-05-01.Personal Independence Payment (PIP)": "266,001",
        "CSTM5160.pip_tot*dr.2021-06-01.Personal Independence Payment (PIP)": "11,412",
        "E92000001.pip_tot*dr.2021-06-01.Personal Independence Payment (PIP)": "2,243,590",
        "E12000008.pip_tot*dr.2021-06-01.Personal Independence Payment (PIP)": "267,908",
        "CSTM5160.pip_tot*dr.2021-07-01.Personal Independence Payment (PIP)": "11,500",
        "E92000001.pip_tot*dr.2021-07-01.Personal Independence Payment (PIP)": "2,263,420",
        "E12000008.pip_tot*dr.2021-07-01.Personal Independence Payment (PIP)": "270,433",
        "CSTM5160.pip_tot*dr.2021-08-01.Personal Independence Payment (PIP)": "11,538",
        "E92000001.pip_tot*dr.2021-08-01.Personal Independence Payment (PIP)": "2,277,215",
        "E12000008.pip_tot*dr.2021-08-01.Personal Independence Payment (PIP)": "272,318",
        "CSTM5160.pip_tot*dr.2021-09-01.Personal Independence Payment (PIP)": "11,573",
        "E92000001.pip_tot*dr.2021-09-01.Personal Independence Payment (PIP)": "2,289,975",
        "E12000008.pip_tot*dr.2021-09-01.Personal Independence Payment (PIP)": "273,663",
        "CSTM5160.pip_tot*dr.2021-10-01.Personal Independence Payment (PIP)": "11,608",
        "E92000001.pip_tot*dr.2021-10-01.Personal Independence Payment (PIP)": "2,304,370",
        "E12000008.pip_tot*dr.2021-10-01.Personal Independence Payment (PIP)": "275,614",
        "CSTM5160.pip_tot*dr.2021-11-01.Personal Independence Payment (PIP)": "11,757",
        "E92000001.pip_tot*dr.2021-11-01.Personal Independence Payment (PIP)": "2,324,656",
        "E12000008.pip_tot*dr.2021-11-01.Personal Independence Payment (PIP)": "278,546",
        "CSTM5160.pip_tot*dr.2021-12-01.Personal Independence Payment (PIP)": "11,887",
        "E92000001.pip_tot*dr.2021-12-01.Personal Independence Payment (PIP)": "2,345,686",
        "E12000008.pip_tot*dr.2021-12-01.Personal Independence Payment (PIP)": "281,469",
        "CSTM5160.pip_tot*dr.2022-01-01.Personal Independence Payment (PIP)": "11,993",
        "E92000001.pip_tot*dr.2022-01-01.Personal Independence Payment (PIP)": "2,368,560",
        "E12000008.pip_tot*dr.2022-01-01.Personal Independence Payment (PIP)": "284,260",
        "CSTM5160.pip_tot*dr.2022-02-01.Personal Independence Payment (PIP)": "12,055",
        "E92000001.pip_tot*dr.2022-02-01.Personal Independence Payment (PIP)": "2,388,081",
        "E12000008.pip_tot*dr.2022-02-01.Personal Independence Payment (PIP)": "286,749",
        "CSTM5160.pip_tot*dr.2022-03-01.Personal Independence Payment (PIP)": "12,184",
        "E92000001.pip_tot*dr.2022-03-01.Personal Independence Payment (PIP)": "2,418,543",
        "E12000008.pip_tot*dr.2022-03-01.Personal Independence Payment (PIP)": "290,722",
        "CSTM5160.pip_tot*dr.2022-04-01.Personal Independence Payment (PIP)": "12,282",
        "E92000001.pip_tot*dr.2022-04-01.Personal Independence Payment (PIP)": "2,443,254",
        "E12000008.pip_tot*dr.2022-04-01.Personal Independence Payment (PIP)": "293,689",
        "CSTM5160.pip_tot*dr.2022-05-01.Personal Independence Payment (PIP)": null,
        "E92000001.pip_tot*dr.2022-05-01.Personal Independence Payment (PIP)": null,
        "E12000008.pip_tot*dr.2022-05-01.Personal Independence Payment (PIP)": null,
        "CSTM5160.pip_tot*dr.2022-06-01.Personal Independence Payment (PIP)": null,
        "E92000001.pip_tot*dr.2022-06-01.Personal Independence Payment (PIP)": null,
        "E12000008.pip_tot*dr.2022-06-01.Personal Independence Payment (PIP)": null,
        "CSTM5160.pip_tot*dr.2022-07-01.Personal Independence Payment (PIP)": "12,600",
        "E92000001.pip_tot*dr.2022-07-01.Personal Independence Payment (PIP)": "2,523,631",
        "E12000008.pip_tot*dr.2022-07-01.Personal Independence Payment (PIP)": "304,064",
        "CSTM5160.pip_tot*dr.2022-08-01.Personal Independence Payment (PIP)": "12,723",
        "E92000001.pip_tot*dr.2022-08-01.Personal Independence Payment (PIP)": "2,551,058",
        "E12000008.pip_tot*dr.2022-08-01.Personal Independence Payment (PIP)": "307,523",
        "CSTM5160.pip_tot*dr.2022-09-01.Personal Independence Payment (PIP)": "12,864",
        "E92000001.pip_tot*dr.2022-09-01.Personal Independence Payment (PIP)": "2,579,096",
        "E12000008.pip_tot*dr.2022-09-01.Personal Independence Payment (PIP)": "311,031",
        "CSTM5160.pip_tot*dr.2022-10-01.Personal Independence Payment (PIP)": "13,008",
        "E92000001.pip_tot*dr.2022-10-01.Personal Independence Payment (PIP)": "2,609,559",
        "E12000008.pip_tot*dr.2022-10-01.Personal Independence Payment (PIP)": "314,640",
        "CSTM5160.pip_tot*dr.2022-11-01.Personal Independence Payment (PIP)": "13,165",
        "E92000001.pip_tot*dr.2022-11-01.Personal Independence Payment (PIP)": "2,641,505",
        "E12000008.pip_tot*dr.2022-11-01.Personal Independence Payment (PIP)": "318,887",
        "CSTM5160.pip_tot*dr.2022-12-01.Personal Independence Payment (PIP)": "13,271",
        "E92000001.pip_tot*dr.2022-12-01.Personal Independence Payment (PIP)": "2,666,352",
        "E12000008.pip_tot*dr.2022-12-01.Personal Independence Payment (PIP)": "322,147",
        "CSTM5160.pip_tot*dr.2023-01-01.Personal Independence Payment (PIP)": "13,431",
        "E92000001.pip_tot*dr.2023-01-01.Personal Independence Payment (PIP)": "2,700,992",
        "E12000008.pip_tot*dr.2023-01-01.Personal Independence Payment (PIP)": "326,911",
        "CSTM5160.pip_tot*dr.2023-02-01.Personal Independence Payment (PIP)": "13,548",
        "E92000001.pip_tot*dr.2023-02-01.Personal Independence Payment (PIP)": "2,723,593",
        "E12000008.pip_tot*dr.2023-02-01.Personal Independence Payment (PIP)": "329,634",
        "CSTM5160.pip_tot*dr.2023-03-01.Personal Independence Payment (PIP)": "13,653",
        "E92000001.pip_tot*dr.2023-03-01.Personal Independence Payment (PIP)": "2,741,638",
        "E12000008.pip_tot*dr.2023-03-01.Personal Independence Payment (PIP)": "332,106",
        "CSTM5160.pip_tot*dr.2023-04-01.Personal Independence Payment (PIP)": "13,650",
        "E92000001.pip_tot*dr.2023-04-01.Personal Independence Payment (PIP)": "2,752,645",
        "E12000008.pip_tot*dr.2023-04-01.Personal Independence Payment (PIP)": "333,606",
        "CSTM5160.pip_tot*dr.2023-07-01.Personal Independence Payment (PIP)": "14,115",
        "E92000001.pip_tot*dr.2023-07-01.Personal Independence Payment (PIP)": "2,837,368",
        "E12000008.pip_tot*dr.2023-07-01.Personal Independence Payment (PIP)": "344,757",
        "CSTM5160.pip_tot*dr.2023-10-01.Personal Independence Payment (PIP)": "14,562",
        "E92000001.pip_tot*dr.2023-10-01.Personal Independence Payment (PIP)": "2,932,738",
        "E12000008.pip_tot*dr.2023-10-01.Personal Independence Payment (PIP)": "357,067",
        "CSTM5160.pip_tot*dr.2024-01-01.Personal Independence Payment (PIP)": "15,077",
        "E92000001.pip_tot*dr.2024-01-01.Personal Independence Payment (PIP)": "3,029,282",
        "E12000008.pip_tot*dr.2024-01-01.Personal Independence Payment (PIP)": "371,146",
        "CSTM5160.pip_tot*dr.2024-04-01.Personal Independence Payment (PIP)": "15,406",
        "E92000001.pip_tot*dr.2024-04-01.Personal Independence Payment (PIP)": "3,109,637",
        "E12000008.pip_tot*dr.2024-04-01.Personal Independence Payment (PIP)": "381,518",
        "CSTM5160.pip_female*dr.2024-07-01.Personal Independence Payment (PIP), female": "8,411",
        "E92000001.pip_female*dr.2024-07-01.Personal Independence Payment (PIP), female": "1,763,938",
        "E12000008.pip_female*dr.2024-07-01.Personal Independence Payment (PIP), female": "218,782",
        "CSTM5160.pip_male*dr.2024-07-01.Personal Independence Payment (PIP), male": "7,487",
        "E92000001.pip_male*dr.2024-07-01.Personal Independence Payment (PIP), male": "1,446,083",
        "E12000008.pip_male*dr.2024-07-01.Personal Independence Payment (PIP), male": "176,188",
        "CSTM5160.pip_mental*dr.2024-07-01.Personal Independence Payment (PIP), mental health claimants": "7,298",
        "E92000001.pip_mental*dr.2024-07-01.Personal Independence Payment (PIP), mental health claimants": "1,226,631",
        "E12000008.pip_mental*dr.2024-07-01.Personal Independence Payment (PIP), mental health claimants": "158,481",
        "CSTM5160.pip_resp*dr.2024-07-01.Personal Independence Payment (PIP), respiratory disease claimants": "531",
        "E92000001.pip_resp*dr.2024-07-01.Personal Independence Payment (PIP), respiratory disease claimants": "123,161",
        "E12000008.pip_resp*dr.2024-07-01.Personal Independence Payment (PIP), respiratory disease claimants": "14,266",
        "CSTM5160.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021)": "20,351",
        "E92000001.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021)": "4,140,357",
        "E12000008.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021)": "581,048",
        "CSTM5160.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021)": "31,446",
        "E92000001.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021)": "5,634,153",
        "E12000008.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021)": "915,292",
        "CSTM5160.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021)": "20,804",
        "E92000001.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021)": "3,856,029",
        "E12000008.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021)": "698,690",
        "CSTM5160.hh_disabled_4a01*dr.2021-01-01.1 person disabled under the Equality Act in household (Census 2021)": "31,861",
        "E92000001.hh_disabled_4a01*dr.2021-01-01.1 person disabled under the Equality Act in household (Census 2021)": "5,950,081",
        "E12000008.hh_disabled_4a01*dr.2021-01-01.1 person disabled under the Equality Act in household (Census 2021)": "911,892",
        "CSTM5160.hh_disabled_4a02*dr.2021-01-01.2 or more people disabled under the Equality Act in household (Census 2021)": "7,760",
        "E92000001.hh_disabled_4a02*dr.2021-01-01.2 or more people disabled under the Equality Act in household (Census 2021)": "1,557,806",
        "E12000008.hh_disabled_4a02*dr.2021-01-01.2 or more people disabled under the Equality Act in household (Census 2021)": "232,191",
        "CSTM5160.id19_heapop_dec1*dr.2019-01-01.IoD 2019 Health Decile 1": "28,837",
        "E92000001.id19_heapop_dec1*dr.2019-01-01.IoD 2019 Health Decile 1": "5,402,462",
        "E12000008.id19_heapop_dec1*dr.2019-01-01.IoD 2019 Health Decile 1": "186,360",
        "CSTM5160.id19_heapop_dec2*dr.2019-01-01.IoD 2019 Health Decile 2": "31,853",
        "E92000001.id19_heapop_dec2*dr.2019-01-01.IoD 2019 Health Decile 2": "5,509,443",
        "E12000008.id19_heapop_dec2*dr.2019-01-01.IoD 2019 Health Decile 2": "394,802",
        "CSTM5160.id19_heapop_dec3*dr.2019-01-01.IoD 2019 Health Decile 3": "20,866",
        "E92000001.id19_heapop_dec3*dr.2019-01-01.IoD 2019 Health Decile 3": "5,517,936",
        "E12000008.id19_heapop_dec3*dr.2019-01-01.IoD 2019 Health Decile 3": "570,464",
        "CSTM5160.id19_heapop_dec4*dr.2019-01-01.IoD 2019 Health Decile 4": "36,095",
        "E92000001.id19_heapop_dec4*dr.2019-01-01.IoD 2019 Health Decile 4": "5,495,782",
        "E12000008.id19_heapop_dec4*dr.2019-01-01.IoD 2019 Health Decile 4": "718,620",
        "CSTM5160.id19_heapop_dec5*dr.2019-01-01.IoD 2019 Health Decile 5": "31,203",
        "E92000001.id19_heapop_dec5*dr.2019-01-01.IoD 2019 Health Decile 5": "5,498,186",
        "E12000008.id19_heapop_dec5*dr.2019-01-01.IoD 2019 Health Decile 5": "698,766",
        "CSTM5160.id19_heapop_dec6*dr.2019-01-01.IoD 2019 Health Decile 6": "25,837",
        "E92000001.id19_heapop_dec6*dr.2019-01-01.IoD 2019 Health Decile 6": "5,543,596",
        "E12000008.id19_heapop_dec6*dr.2019-01-01.IoD 2019 Health Decile 6": "875,382",
        "CSTM5160.id19_heapop_dec7*dr.2019-01-01.IoD 2019 Health Decile 7": "49,398",
        "E92000001.id19_heapop_dec7*dr.2019-01-01.IoD 2019 Health Decile 7": "5,555,094",
        "E12000008.id19_heapop_dec7*dr.2019-01-01.IoD 2019 Health Decile 7": "971,036",
        "CSTM5160.id19_heapop_dec8*dr.2019-01-01.IoD 2019 Health Decile 8": "31,383",
        "E92000001.id19_heapop_dec8*dr.2019-01-01.IoD 2019 Health Decile 8": "5,645,666",
        "E12000008.id19_heapop_dec8*dr.2019-01-01.IoD 2019 Health Decile 8": "1,092,501",
        "CSTM5160.id19_heapop_dec9*dr.2019-01-01.IoD 2019 Health Decile 9": "29,500",
        "E92000001.id19_heapop_dec9*dr.2019-01-01.IoD 2019 Health Decile 9": "5,631,319",
        "E12000008.id19_heapop_dec9*dr.2019-01-01.IoD 2019 Health Decile 9": "1,413,221",
        "CSTM5160.id19_heapop_dec10*dr.2019-01-01.IoD 2019 Health Decile 10": "3,183",
        "E92000001.id19_heapop_dec10*dr.2019-01-01.IoD 2019 Health Decile 10": "5,819,694",
        "E12000008.id19_heapop_dec10*dr.2019-01-01.IoD 2019 Health Decile 10": "2,159,609",
        "CSTM5160.ks301ew0008*dr.2021-01-01.Very good health (Census 2021)": "139,078",
        "E92000001.ks301ew0008*dr.2021-01-01.Very good health (Census 2021)": "27,390,829",
        "E12000008.ks301ew0008*dr.2021-01-01.Very good health (Census 2021)": "4,636,748",
        "CSTM5160.ks301ew0009*dr.2021-01-01.Good health (Census 2021)": "91,878",
        "E92000001.ks301ew0009*dr.2021-01-01.Good health (Census 2021)": "19,040,735",
        "E12000008.ks301ew0009*dr.2021-01-01.Good health (Census 2021)": "3,155,834",
        "CSTM5160.ks301ew0010*dr.2021-01-01.Fair health (Census 2021)": "32,118",
        "E92000001.ks301ew0010*dr.2021-01-01.Fair health (Census 2021)": "7,147,346",
        "E12000008.ks301ew0010*dr.2021-01-01.Fair health (Census 2021)": "1,092,213",
        "CSTM5160.ks301ew0011*dr.2021-01-01.Bad health (Census 2021)": "10,757",
        "E92000001.ks301ew0011*dr.2021-01-01.Bad health (Census 2021)": "2,248,255",
        "E12000008.ks301ew0011*dr.2021-01-01.Bad health (Census 2021)": "307,131",
        "CSTM5160.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021)": "3,272",
        "E92000001.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021)": "662,881",
        "E12000008.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021)": "86,139",
        "CSTM5160.ks301ew0012*dr.2001-01-01.Very bad health (Census 2021)": null,
        "E92000001.ks301ew0012*dr.2001-01-01.Very bad health (Census 2021)": null,
        "E12000008.ks301ew0012*dr.2001-01-01.Very bad health (Census 2021)": null,
        "CSTM5160.ks301ew0012*dr.2011-01-01.Very bad health  (E&W Census 2011)": "3,277",
        "E92000001.ks301ew0012*dr.2011-01-01.Very bad health  (E&W Census 2011)": "660,749",
        "E12000008.ks301ew0012*dr.2011-01-01.Very bad health  (E&W Census 2011)": "83,075",
        "CSTM5160.prev_asthma.2022-04-01.Asthma prevalence": "6.06%",
        "E92000001.prev_asthma.2022-04-01.Asthma prevalence": "6.60%",
        "E12000008.prev_asthma.2022-04-01.Asthma prevalence": "6.46%",
        "CSTM5160.prev_cancer.2022-04-01.Cancer prevalence (diagnosis since 2003)": "2.72%",
        "E92000001.prev_cancer.2022-04-01.Cancer prevalence (diagnosis since 2003)": "3.56%",
        "E12000008.prev_cancer.2022-04-01.Cancer prevalence (diagnosis since 2003)": "4.00%",
        "CSTM5160.prev_heart.2022-04-01.Coronary Heart Disease prevalence": "2.11%",
        "E92000001.prev_heart.2022-04-01.Coronary Heart Disease prevalence": "3.04%",
        "E12000008.prev_heart.2022-04-01.Coronary Heart Disease prevalence": "2.83%",
        "CSTM5160.prev_kidney.2022-04-01.Chronic Kidney Disease prevalence": "2.98%",
        "E92000001.prev_kidney.2022-04-01.Chronic Kidney Disease prevalence": "4.27%",
        "E12000008.prev_kidney.2022-04-01.Chronic Kidney Disease prevalence": "4.10%",
        "CSTM5160.prev_copd.2022-04-01.COPD prevalence": "1.48%",
        "E92000001.prev_copd.2022-04-01.COPD prevalence": "1.88%",
        "E12000008.prev_copd.2022-04-01.COPD prevalence": "1.66%",
        "CSTM5160.prev_dementia.2022-04-01.Dementia prevalence": "0.59%",
        "E92000001.prev_dementia.2022-04-01.Dementia prevalence": "0.76%",
        "E12000008.prev_dementia.2022-04-01.Dementia prevalence": "0.80%",
        "CSTM5160.prev_depress.2022-04-01.Depression prevalence": "13.43%",
        "E92000001.prev_depress.2022-04-01.Depression prevalence": "13.41%",
        "E12000008.prev_depress.2022-04-01.Depression prevalence": "13.83%",
        "CSTM5160.prev_diabetes.2022-04-01.Diabetes prevalence": "4.67%",
        "E92000001.prev_diabetes.2022-04-01.Diabetes prevalence": "7.54%",
        "E12000008.prev_diabetes.2022-04-01.Diabetes prevalence": "6.92%",
        "CSTM5160.prev_epilepsy.2022-04-01.Epilepsy prevalence": "0.72%",
        "E92000001.prev_epilepsy.2022-04-01.Epilepsy prevalence": "0.82%",
        "E12000008.prev_epilepsy.2022-04-01.Epilepsy prevalence": "0.78%",
        "CSTM5160.prev_heart_fail.2022-04-01.Heart Failure prevalence": "0.63%",
        "E92000001.prev_heart_fail.2022-04-01.Heart Failure prevalence": "1.01%",
        "E12000008.prev_heart_fail.2022-04-01.Heart Failure prevalence": "0.96%",
        "CSTM5160.prev_high_blood_pres.2022-04-01.High Blood Pressure prevalence": "10.00%",
        "E92000001.prev_high_blood_pres.2022-04-01.High Blood Pressure prevalence": "14.63%",
        "E12000008.prev_high_blood_pres.2022-04-01.High Blood Pressure prevalence": "14.69%",
        "CSTM5160.prev_learning_dis.2022-04-01.Learning Disabilities prevalence": "0.53%",
        "E92000001.prev_learning_dis.2022-04-01.Learning Disabilities prevalence": "0.56%",
        "E12000008.prev_learning_dis.2022-04-01.Learning Disabilities prevalence": "0.53%",
        "CSTM5160.prev_obesity.2022-04-01.Obesity prevalence": "7.00%",
        "E92000001.prev_obesity.2022-04-01.Obesity prevalence": "11.55%",
        "E12000008.prev_obesity.2022-04-01.Obesity prevalence": "10.31%",
        "CSTM5160.prev_osteoporisis.2022-04-01.Osteoporosis prevalence": "0.42%",
        "E92000001.prev_osteoporisis.2022-04-01.Osteoporosis prevalence": "0.94%",
        "E12000008.prev_osteoporisis.2022-04-01.Osteoporosis prevalence": "1.22%",
        "CSTM5160.prev_periph_arterial.2022-04-01.Peripheral Arterial Disease prevalence": "0.42%",
        "E92000001.prev_periph_arterial.2022-04-01.Peripheral Arterial Disease prevalence": "0.58%",
        "E12000008.prev_periph_arterial.2022-04-01.Peripheral Arterial Disease prevalence": "0.52%",
        "CSTM5160.prev_prediabetes.2022-04-01.Pre-Diabetes prevalence": "4.37%",
        "E92000001.prev_prediabetes.2022-04-01.Pre-Diabetes prevalence": "7.18%",
        "E12000008.prev_prediabetes.2022-04-01.Pre-Diabetes prevalence": "6.51%",
        "CSTM5160.prev_rheuma_arth.2022-04-01.Rheumatoid Arthritis prevalence": "0.52%",
        "E92000001.prev_rheuma_arth.2022-04-01.Rheumatoid Arthritis prevalence": "0.78%",
        "E12000008.prev_rheuma_arth.2022-04-01.Rheumatoid Arthritis prevalence": "0.77%",
        "CSTM5160.prev_stroke_trans_isch.2022-04-01.Stroke and Transient Ischaemic Attack prevalence": "1.38%",
        "E92000001.prev_stroke_trans_isch.2022-04-01.Stroke and Transient Ischaemic Attack prevalence": "1.88%",
        "E12000008.prev_stroke_trans_isch.2022-04-01.Stroke and Transient Ischaemic Attack prevalence": "1.86%",
        "CSTM5160.emerg_hosp_admiss_all_causes_static_rate.2016-01-01.Emergency Hospital Admissions: All Causes": "77.49",
        "E92000001.emerg_hosp_admiss_all_causes_static_rate.2016-01-01.Emergency Hospital Admissions: All Causes": "100.00",
        "E12000008.emerg_hosp_admiss_all_causes_static_rate.2016-01-01.Emergency Hospital Admissions: All Causes": "92.39",
        "CSTM5160.emerg_hosp_admiss_chd_static_rate.2016-01-01.Emergency Hospital Admissions: Coronary Heart Disease": "69.88",
        "E92000001.emerg_hosp_admiss_chd_static_rate.2016-01-01.Emergency Hospital Admissions: Coronary Heart Disease": "100.00",
        "E12000008.emerg_hosp_admiss_chd_static_rate.2016-01-01.Emergency Hospital Admissions: Coronary Heart Disease": "79.15",
        "CSTM5160.emerg_hosp_admiss_copd_static_rate.2016-01-01.Emergency Hospital Admissions: Chronic Obstructive Pulmonary Disease (COPD)": "69.88",
        "E92000001.emerg_hosp_admiss_copd_static_rate.2016-01-01.Emergency Hospital Admissions: Chronic Obstructive Pulmonary Disease (COPD)": "100.00",
        "E12000008.emerg_hosp_admiss_copd_static_rate.2016-01-01.Emergency Hospital Admissions: Chronic Obstructive Pulmonary Disease (COPD)": "76.08",
        "CSTM5160.emerg_hosp_admiss_hip_fracture_over65_static_rate.2016-01-01.Emergency Hospital Admissions: Hip Fracture (65+)": "101.24",
        "E92000001.emerg_hosp_admiss_hip_fracture_over65_static_rate.2016-01-01.Emergency Hospital Admissions: Hip Fracture (65+)": "100.00",
        "E12000008.emerg_hosp_admiss_hip_fracture_over65_static_rate.2016-01-01.Emergency Hospital Admissions: Hip Fracture (65+)": "96.25",
        "CSTM5160.emerg_hosp_admiss_myocardial_inf_static_rate.2016-01-01.Emergency Hospital Admissions: Myocardial Infarction": "69.70",
        "E92000001.emerg_hosp_admiss_myocardial_inf_static_rate.2016-01-01.Emergency Hospital Admissions: Myocardial Infarction": "100.00",
        "E12000008.emerg_hosp_admiss_myocardial_inf_static_rate.2016-01-01.Emergency Hospital Admissions: Myocardial Infarction": "86.15",
        "CSTM5160.emerg_hosp_admiss_stroke_static_rate.2016-01-01.Emergency Hospital Admissions: Stroke": "91.07",
        "E92000001.emerg_hosp_admiss_stroke_static_rate.2016-01-01.Emergency Hospital Admissions: Stroke": "100.00",
        "E12000008.emerg_hosp_admiss_stroke_static_rate.2016-01-01.Emergency Hospital Admissions: Stroke": "90.66",
        "CSTM5160.hosp_stays_alcohol_rel_harm_static_rate.2016-01-01.Alcohol attributable harm (SAR)": "79.63",
        "E92000001.hosp_stays_alcohol_rel_harm_static_rate.2016-01-01.Alcohol attributable harm (SAR)": "100.00",
        "E12000008.hosp_stays_alcohol_rel_harm_static_rate.2016-01-01.Alcohol attributable harm (SAR)": "85.43",
        "CSTM5160.hosp_stay_self_harm_static_rate.2016-01-01.Hospital stays for intentional self harm": "148.14",
        "E92000001.hosp_stay_self_harm_static_rate.2016-01-01.Hospital stays for intentional self harm": "100.00",
        "E12000008.hosp_stay_self_harm_static_rate.2016-01-01.Hospital stays for intentional self harm": "107.33",
        "CSTM5160.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021)": "244,548",
        "E92000001.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021)": "48,734,833",
        "E12000008.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021)": "8,049,399",
        "CSTM5160.is_carer_7a02*dr.2021-01-01.9 hours or less unpaid care a week (Census 2021)": "8,679",
        "E92000001.is_carer_7a02*dr.2021-01-01.9 hours or less unpaid care a week (Census 2021)": "1,668,989",
        "E12000008.is_carer_7a02*dr.2021-01-01.9 hours or less unpaid care a week (Census 2021)": "292,188",
        "CSTM5160.is_carer_7a03*dr.2021-01-01.10 to 19 hours unpaid care a week (Census 2021)": "2,655",
        "E92000001.is_carer_7a03*dr.2021-01-01.10 to 19 hours unpaid care a week (Census 2021)": "634,736",
        "E12000008.is_carer_7a03*dr.2021-01-01.10 to 19 hours unpaid care a week (Census 2021)": "96,434",
        "CSTM5160.is_carer_7a04*dr.2021-01-01.20 to 34 hours unpaid care a week (Census 2021)": "1,981",
        "E92000001.is_carer_7a04*dr.2021-01-01.20 to 34 hours unpaid care a week (Census 2021)": "452,264",
        "E12000008.is_carer_7a04*dr.2021-01-01.20 to 34 hours unpaid care a week (Census 2021)": "64,555",
        "CSTM5160.is_carer_7a05*dr.2021-01-01.35 to 49 hours unpaid care a week (Census 2021)": "2,059",
        "E92000001.is_carer_7a05*dr.2021-01-01.35 to 49 hours unpaid care a week (Census 2021)": "517,505",
        "E12000008.is_carer_7a05*dr.2021-01-01.35 to 49 hours unpaid care a week (Census 2021)": "70,324",
        "CSTM5160.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021)": "5,430",
        "E92000001.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021)": "1,404,771",
        "E12000008.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021)": "209,863",
        "CSTM5160.ks301ew0016*dr.2011-01-01.50+ hours unpaid care a week  (E&W Census 2011)": "4,716",
        "E92000001.ks301ew0016*dr.2011-01-01.50+ hours unpaid care a week  (E&W Census 2011)": "1,256,237",
        "E12000008.ks301ew0016*dr.2011-01-01.50+ hours unpaid care a week  (E&W Census 2011)": "173,356",
        "CSTM5160.spact_active_r.2021-01-01.Adults who are physically active": "73.19%",
        "E92000001.spact_active_r.2021-01-01.Adults who are physically active": "63.71%",
        "E12000008.spact_active_r.2021-01-01.Adults who are physically active": "66.15%",
        "CSTM5160.spact_inactive_r.2021-01-01.Adults who are physically inactive": "16.42%",
        "E92000001.spact_inactive_r.2021-01-01.Adults who are physically inactive": "24.08%",
        "E12000008.spact_inactive_r.2021-01-01.Adults who are physically inactive": "21.83%",
        "CSTM5160.spact_active2month_r.2021-01-01.Participation in physical activity at least twice in the last month": "0.86",
        "E92000001.spact_active2month_r.2021-01-01.Participation in physical activity at least twice in the last month": "0.78",
        "E12000008.spact_active2month_r.2021-01-01.Participation in physical activity at least twice in the last month": "0.81",
        "CSTM5160.male_life_expectancy.2016-01-01.Male life expectancy at birth": "79.19",
        "E92000001.male_life_expectancy.2016-01-01.Male life expectancy at birth": "79.49",
        "E12000008.male_life_expectancy.2016-01-01.Male life expectancy at birth": "80.53",
        "CSTM5160.female_life_expectancy.2016-01-01.Female life expectancy at birth": "83.08",
        "E92000001.female_life_expectancy.2016-01-01.Female life expectancy at birth": "83.17",
        "E12000008.female_life_expectancy.2016-01-01.Female life expectancy at birth": "84.05",
        "CSTM5160.id15_ypll.2008-01-01.IoD Years of Potential Life Lost": "67.280",
        "E92000001.id15_ypll.2008-01-01.IoD Years of Potential Life Lost": "61.014",
        "E12000008.id15_ypll.2008-01-01.IoD Years of Potential Life Lost": "53.966",
        "CSTM5160.id19_ypll.2012-01-01.IoD Years of Potential Life Lost": "61.65",
        "E92000001.id19_ypll.2012-01-01.IoD Years of Potential Life Lost": "57.06",
        "E12000008.id19_ypll.2012-01-01.IoD Years of Potential Life Lost": "50.61",
        "CSTM5160.deaths_all_ages_all_cancer_static_rate.2016-01-01.Deaths from cancer": "108.85",
        "E92000001.deaths_all_ages_all_cancer_static_rate.2016-01-01.Deaths from cancer": "100.00",
        "E12000008.deaths_all_ages_all_cancer_static_rate.2016-01-01.Deaths from cancer": "95.88",
        "CSTM5160.deaths_all_ages_all_causes_static_rate.2016-01-01.Deaths (all causes)": "101.43",
        "E92000001.deaths_all_ages_all_causes_static_rate.2016-01-01.Deaths (all causes)": "100.00",
        "E12000008.deaths_all_ages_all_causes_static_rate.2016-01-01.Deaths (all causes)": "92.90",
        "CSTM5160.deaths_all_ages_chd_static_rate.2016-01-01.Deaths all ages, coronary heart disease (CHD)": "86.34",
        "E92000001.deaths_all_ages_chd_static_rate.2016-01-01.Deaths all ages, coronary heart disease (CHD)": "100.00",
        "E12000008.deaths_all_ages_chd_static_rate.2016-01-01.Deaths all ages, coronary heart disease (CHD)": "84.36",
        "CSTM5160.deaths_all_ages_circ_dis_static_rate.2016-01-01.Deaths all ages, circulatory disease": "98.86",
        "E92000001.deaths_all_ages_circ_dis_static_rate.2016-01-01.Deaths all ages, circulatory disease": "100.00",
        "E12000008.deaths_all_ages_circ_dis_static_rate.2016-01-01.Deaths all ages, circulatory disease": "91.88",
        "CSTM5160.deaths_all_ages_resp_dis_static_rate.2016-01-01.Deaths all ages, respiratory disease": "93.28",
        "E92000001.deaths_all_ages_resp_dis_static_rate.2016-01-01.Deaths all ages, respiratory disease": "100.00",
        "E12000008.deaths_all_ages_resp_dis_static_rate.2016-01-01.Deaths all ages, respiratory disease": "91.50",
        "CSTM5160.deaths_all_ages_stroke_static_rate.2016-01-01.Deaths all ages, stroke": "102.70",
        "E92000001.deaths_all_ages_stroke_static_rate.2016-01-01.Deaths all ages, stroke": "100.00",
        "E12000008.deaths_all_ages_stroke_static_rate.2016-01-01.Deaths all ages, stroke": "93.42",
        "CSTM5160.deaths_und75_all_causes_static_rate.2016-01-01.Deaths under 75, all causes": "106.14",
        "E92000001.deaths_und75_all_causes_static_rate.2016-01-01.Deaths under 75, all causes": "100.00",
        "E12000008.deaths_und75_all_causes_static_rate.2016-01-01.Deaths under 75, all causes": "88.72",
        "CSTM5160.deaths_und75_circ_dis_static_rate.2016-01-01.Deaths under 75, circulatory disease": "96.89",
        "E92000001.deaths_und75_circ_dis_static_rate.2016-01-01.Deaths under 75, circulatory disease": "100.00",
        "E12000008.deaths_und75_circ_dis_static_rate.2016-01-01.Deaths under 75, circulatory disease": "83.62",
        "CSTM5160.deaths_und75_all_cancer_static_rate.2016-01-01.Deaths under 75, all cancers": "107.25",
        "E92000001.deaths_und75_all_cancer_static_rate.2016-01-01.Deaths under 75, all cancers": "100.00",
        "E12000008.deaths_und75_all_cancer_static_rate.2016-01-01.Deaths under 75, all cancers": "93.98",
        "CSTM5160.mortality_0_64_all_cause*dr.2018-01-01.Deaths under 65, all causes": "2,035",
        "E92000001.mortality_0_64_all_cause*dr.2018-01-01.Deaths under 65, all causes": "411,086",
        "E12000008.mortality_0_64_all_cause*dr.2018-01-01.Deaths under 65, all causes": "58,794",
        "CSTM5160.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021).rate": "15.71%",
        "E92000001.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021).rate": "26.06%",
        "E12000008.ks404ew0004*dr.2021-01-01.2 cars or vans in household (Census 2021).rate": "30.55%",
        "CSTM5160.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021).rate": "4.12%",
        "E92000001.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021).rate": "9.12%",
        "E12000008.ks404ew_new3*dr.2021-01-01.Households with 3+ cars (Census 2021).rate": "11.78%",
        "CSTM5160.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021).rate": "42.73%",
        "E92000001.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021).rate": "41.28%",
        "E12000008.ks404ew0003*dr.2021-01-01.1 car or van in household (Census 2021).rate": "40.81%",
        "CSTM5160.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021).rate": "37.45%",
        "E92000001.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021).rate": "23.54%",
        "E12000008.ks404ew0002*dr.2021-01-01.Households with no car (Census 2021).rate": "16.86%",
        "CSTM5160.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011).rate": "38.19%",
        "E92000001.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011).rate": "25.80%",
        "E12000008.ks404ew0002*dr.2011-01-01.Households with no car  (E&W Census 2011).rate": "18.58%",
        "CSTM5160.biglottery_award_04_21_sum_exmill*dr.2021-05-01.The National Lottery Community Fund grant funding per 1,000 population (£) 2004-2021.rate": "£168,244.84",
        "E92000001.biglottery_award_04_21_sum_exmill*dr.2021-05-01.The National Lottery Community Fund grant funding per 1,000 population (£) 2004-2021.rate": "£37,984.08",
        "E12000008.biglottery_award_04_21_sum_exmill*dr.2021-05-01.The National Lottery Community Fund grant funding per 1,000 population (£) 2004-2021.rate": "£53,048.96",
        "CSTM5160.grant_funding_count*dr.2024-10-01.Total count of grants from major grant funders.rate": "118.29",
        "E92000001.grant_funding_count*dr.2024-10-01.Total count of grants from major grant funders.rate": "80.78",
        "E12000008.grant_funding_count*dr.2024-10-01.Total count of grants from major grant funders.rate": "67.20",
        "CSTM5160.grant_funding_pounds*dr.2024-10-01.Total amount awarded from major grant funders per head.rate": "£2,806.36",
        "E92000001.grant_funding_pounds*dr.2024-10-01.Total amount awarded from major grant funders per head.rate": "£3,101.07",
        "E12000008.grant_funding_pounds*dr.2024-10-01.Total amount awarded from major grant funders per head.rate": "£2,428.72",
        "CSTM5160.charities*dr.2021-05-01.Total registered charities.rate": "2.85",
        "E92000001.charities*dr.2021-05-01.Total registered charities.rate": "2.79",
        "E12000008.charities*dr.2021-05-01.Total registered charities.rate": "2.92",
        "CSTM5160.ren_comm_assets*dr.2023-02-01.Density of Community owned assets.rate": "1,285.15",
        "E92000001.ren_comm_assets*dr.2023-02-01.Density of Community owned assets.rate": "755.87",
        "E12000008.ren_comm_assets*dr.2023-02-01.Density of Community owned assets.rate": "607.80",
        "CSTM5160.atgs_england*dr.2024-08-01.Percentage of households with access to Green Space.rate": "24.85%",
        "E92000001.atgs_england*dr.2024-08-01.Percentage of households with access to Green Space.rate": "23.04%",
        "E12000008.atgs_england*dr.2024-08-01.Percentage of households with access to Green Space.rate": "22.87%",
        "CSTM5160.private_outdoor_space*dr.2020-04-01.Addresses with private outdoor space.rate": "86.97%",
        "E92000001.private_outdoor_space*dr.2020-04-01.Addresses with private outdoor space.rate": "88.38%",
        "E12000008.private_outdoor_space*dr.2020-04-01.Addresses with private outdoor space.rate": "89.10%",
        "CSTM5160.os_greenspace_total*dr.2017-05-01.Greenspace coverage, total.rate": "9.93%",
        "E92000001.os_greenspace_total*dr.2017-05-01.Greenspace coverage, total.rate": "2.22%",
        "E12000008.os_greenspace_total*dr.2017-05-01.Greenspace coverage, total.rate": "3.19%",
        "CSTM5160.os_greenspace_parks_gardens*dr.2017-05-01.Greenspace coverage, public parks and gardens.rate": "1.84%",
        "E92000001.os_greenspace_parks_gardens*dr.2017-05-01.Greenspace coverage, public parks and gardens.rate": "0.79%",
        "E12000008.os_greenspace_parks_gardens*dr.2017-05-01.Greenspace coverage, public parks and gardens.rate": "1.15%",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2023-09-01.Anti-social behaviour (12 month total).rate": "20.7",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2023-09-01.Anti-social behaviour (12 month total).rate": "13.8",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2023-09-01.Anti-social behaviour (12 month total).rate": "10.9",
        "CSTM5160.bicycle_theft_police_uk_12_month*dr.2023-09-01.Bicycle theft offences (12 month total).rate": "2.8",
        "E92000001.bicycle_theft_police_uk_12_month*dr.2023-09-01.Bicycle theft offences (12 month total).rate": "0.8",
        "E12000008.bicycle_theft_police_uk_12_month*dr.2023-09-01.Bicycle theft offences (12 month total).rate": "1.0",
        "CSTM5160.burglary_police_uk_12_month*dr.2023-09-01.Burglary (12 month total).rate": "9.0",
        "E92000001.burglary_police_uk_12_month*dr.2023-09-01.Burglary (12 month total).rate": "9.2",
        "E12000008.burglary_police_uk_12_month*dr.2023-09-01.Burglary (12 month total).rate": "7.8",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2023-09-01.Criminal damage (12 month total).rate": "9.2",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2023-09-01.Criminal damage (12 month total).rate": "6.8",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2023-09-01.Criminal damage (12 month total).rate": "7.1",
        "CSTM5160.drugs_police_uk_12_month*dr.2023-09-01.Drug crime offences (12 month total).rate": "4.8",
        "E92000001.drugs_police_uk_12_month*dr.2023-09-01.Drug crime offences (12 month total).rate": "2.4",
        "E12000008.drugs_police_uk_12_month*dr.2023-09-01.Drug crime offences (12 month total).rate": "2.3",
        "CSTM5160.other_crime_police_uk_12_month*dr.2023-09-01.Other crime offences (12 month total).rate": "2.6",
        "E92000001.other_crime_police_uk_12_month*dr.2023-09-01.Other crime offences (12 month total).rate": "1.8",
        "E12000008.other_crime_police_uk_12_month*dr.2023-09-01.Other crime offences (12 month total).rate": "1.8",
        "CSTM5160.other_theft_police_uk_12_month*dr.2023-09-01.Other theft offences (12 month total).rate": "10.9",
        "E92000001.other_theft_police_uk_12_month*dr.2023-09-01.Other theft offences (12 month total).rate": "6.4",
        "E12000008.other_theft_police_uk_12_month*dr.2023-09-01.Other theft offences (12 month total).rate": "6.1",
        "CSTM5160.possession_of_weapons_police_uk_12_month*dr.2023-09-01.Possession of weapons offences (12 month total).rate": "1.8",
        "E92000001.possession_of_weapons_police_uk_12_month*dr.2023-09-01.Possession of weapons offences (12 month total).rate": "0.8",
        "E12000008.possession_of_weapons_police_uk_12_month*dr.2023-09-01.Possession of weapons offences (12 month total).rate": "0.9",
        "CSTM5160.public_order_police_uk_12_month*dr.2023-09-01.Public order offences (12 month total).rate": "12.7",
        "E92000001.public_order_police_uk_12_month*dr.2023-09-01.Public order offences (12 month total).rate": "6.4",
        "E12000008.public_order_police_uk_12_month*dr.2023-09-01.Public order offences (12 month total).rate": "6.6",
        "CSTM5160.robbery_police_uk_12_month*dr.2023-09-01.Robbery recorded offences (12 month total).rate": "1.4",
        "E92000001.robbery_police_uk_12_month*dr.2023-09-01.Robbery recorded offences (12 month total).rate": "1.1",
        "E12000008.robbery_police_uk_12_month*dr.2023-09-01.Robbery recorded offences (12 month total).rate": "0.6",
        "CSTM5160.shoplifting_police_uk_12_month*dr.2023-09-01.Shoplifting offences (12 month total).rate": "15.8",
        "E92000001.shoplifting_police_uk_12_month*dr.2023-09-01.Shoplifting offences (12 month total).rate": "6.6",
        "E12000008.shoplifting_police_uk_12_month*dr.2023-09-01.Shoplifting offences (12 month total).rate": "7.3",
        "CSTM5160.theft_from_the_person_police_uk_12_month*dr.2023-09-01.Theft from the person offences (12 month total).rate": "2.3",
        "E92000001.theft_from_the_person_police_uk_12_month*dr.2023-09-01.Theft from the person offences (12 month total).rate": "1.6",
        "E12000008.theft_from_the_person_police_uk_12_month*dr.2023-09-01.Theft from the person offences (12 month total).rate": "0.7",
        "CSTM5160.vehicle_crime_police_uk_12_month*dr.2023-09-01.Vehicle crime (12 month total).rate": "5.4",
        "E92000001.vehicle_crime_police_uk_12_month*dr.2023-09-01.Vehicle crime (12 month total).rate": "5.3",
        "E12000008.vehicle_crime_police_uk_12_month*dr.2023-09-01.Vehicle crime (12 month total).rate": "4.9",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2023-09-01.Violent crime and sexual offences (12 month total).rate": "41.4",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2023-09-01.Violent crime and sexual offences (12 month total).rate": "31.1",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2023-09-01.Violent crime and sexual offences (12 month total).rate": "31.6",
        "CSTM5160.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total).rate": "121.5",
        "E92000001.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total).rate": "107.7",
        "E12000008.total_crime_police_uk_12_month*dr.2018-09-01.Total crime offences (12 month total).rate": "88.9",
        "CSTM5160.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total).rate": "124.7",
        "E92000001.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total).rate": "105.4",
        "E12000008.total_crime_police_uk_12_month*dr.2018-12-01.Total crime offences (12 month total).rate": "90.2",
        "CSTM5160.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total).rate": "127.2",
        "E92000001.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total).rate": "104.7",
        "E12000008.total_crime_police_uk_12_month*dr.2019-03-01.Total crime offences (12 month total).rate": "98.1",
        "CSTM5160.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total).rate": "104.9",
        "E92000001.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total).rate": "85.6",
        "E12000008.total_crime_police_uk_12_month*dr.2019-06-01.Total crime offences (12 month total).rate": "80.0",
        "CSTM5160.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total).rate": "129.9",
        "E92000001.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total).rate": "102.2",
        "E12000008.total_crime_police_uk_12_month*dr.2019-09-01.Total crime offences (12 month total).rate": "97.7",
        "CSTM5160.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total).rate": "128.5",
        "E92000001.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total).rate": "102.6",
        "E12000008.total_crime_police_uk_12_month*dr.2019-12-01.Total crime offences (12 month total).rate": "97.4",
        "CSTM5160.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total).rate": "123.3",
        "E92000001.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total).rate": "100.2",
        "E12000008.total_crime_police_uk_12_month*dr.2020-03-01.Total crime offences (12 month total).rate": "95.2",
        "CSTM5160.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total).rate": "123.5",
        "E92000001.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total).rate": "99.0",
        "E12000008.total_crime_police_uk_12_month*dr.2020-06-01.Total crime offences (12 month total).rate": "97.2",
        "CSTM5160.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total).rate": "119.8",
        "E92000001.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total).rate": "98.7",
        "E12000008.total_crime_police_uk_12_month*dr.2020-09-01.Total crime offences (12 month total).rate": "96.8",
        "CSTM5160.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total).rate": "120.6",
        "E92000001.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total).rate": "93.4",
        "E12000008.total_crime_police_uk_12_month*dr.2020-12-01.Total crime offences (12 month total).rate": "97.8",
        "CSTM5160.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total).rate": "128.7",
        "E92000001.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total).rate": "93.0",
        "E12000008.total_crime_police_uk_12_month*dr.2021-03-01.Total crime offences (12 month total).rate": "92.3",
        "CSTM5160.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total).rate": "126.3",
        "E92000001.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total).rate": "94.0",
        "E12000008.total_crime_police_uk_12_month*dr.2021-06-01.Total crime offences (12 month total).rate": "91.8",
        "CSTM5160.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total).rate": "122.8",
        "E92000001.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total).rate": "95.7",
        "E12000008.total_crime_police_uk_12_month*dr.2021-09-01.Total crime offences (12 month total).rate": "91.5",
        "CSTM5160.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total).rate": "118.9",
        "E92000001.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total).rate": "97.5",
        "E12000008.total_crime_police_uk_12_month*dr.2021-12-01.Total crime offences (12 month total).rate": "90.7",
        "CSTM5160.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total).rate": "117.2",
        "E92000001.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total).rate": "97.7",
        "E12000008.total_crime_police_uk_12_month*dr.2022-03-01.Total crime offences (12 month total).rate": "89.3",
        "CSTM5160.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total).rate": "141.8",
        "E92000001.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total).rate": "118.9",
        "E12000008.total_crime_police_uk_12_month*dr.2022-06-01.Total crime offences (12 month total).rate": "108.6",
        "CSTM5160.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total).rate": "169.2",
        "E92000001.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total).rate": "130.1",
        "E12000008.total_crime_police_uk_12_month*dr.2022-09-01.Total crime offences (12 month total).rate": "118.6",
        "CSTM5160.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total).rate": "171.7",
        "E92000001.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total).rate": "134.7",
        "E12000008.total_crime_police_uk_12_month*dr.2022-12-01.Total crime offences (12 month total).rate": "123.2",
        "CSTM5160.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total).rate": "173.1",
        "E92000001.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total).rate": "131.3",
        "E12000008.total_crime_police_uk_12_month*dr.2023-03-01.Total crime offences (12 month total).rate": "121.1",
        "CSTM5160.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total).rate": "160.8",
        "E92000001.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total).rate": "118.1",
        "E12000008.total_crime_police_uk_12_month*dr.2023-06-01.Total crime offences (12 month total).rate": "111.9",
        "CSTM5160.total_crime_police_uk_12_month*dr.2023-09-01.Total crime offences (12 month total).rate": "135.7",
        "E92000001.total_crime_police_uk_12_month*dr.2023-09-01.Total crime offences (12 month total).rate": "88.7",
        "E12000008.total_crime_police_uk_12_month*dr.2023-09-01.Total crime offences (12 month total).rate": "84.9",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2018-09-01.Anti-social behaviour (12 month total).rate": "25.4",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2018-09-01.Anti-social behaviour (12 month total).rate": "22.2",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2018-09-01.Anti-social behaviour (12 month total).rate": "14.8",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2018-12-01.Anti-social behaviour (12 month total).rate": "25.2",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2018-12-01.Anti-social behaviour (12 month total).rate": "21.6",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2018-12-01.Anti-social behaviour (12 month total).rate": "15.0",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2019-03-01.Anti-social behaviour (12 month total).rate": "24.9",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2019-03-01.Anti-social behaviour (12 month total).rate": "21.2",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2019-03-01.Anti-social behaviour (12 month total).rate": "16.2",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2019-06-01.Anti-social behaviour (12 month total).rate": "25.4",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2019-06-01.Anti-social behaviour (12 month total).rate": "20.7",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2019-06-01.Anti-social behaviour (12 month total).rate": "15.1",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2019-09-01.Anti-social behaviour (12 month total).rate": "34.5",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2019-09-01.Anti-social behaviour (12 month total).rate": "26.1",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2019-09-01.Anti-social behaviour (12 month total).rate": "19.8",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2019-12-01.Anti-social behaviour (12 month total).rate": "36.8",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2019-12-01.Anti-social behaviour (12 month total).rate": "27.7",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2019-12-01.Anti-social behaviour (12 month total).rate": "21.3",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2020-03-01.Anti-social behaviour (12 month total).rate": "38.7",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2020-03-01.Anti-social behaviour (12 month total).rate": "29.5",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2020-03-01.Anti-social behaviour (12 month total).rate": "23.1",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2020-06-01.Anti-social behaviour (12 month total).rate": "38.3",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2020-06-01.Anti-social behaviour (12 month total).rate": "26.6",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2020-06-01.Anti-social behaviour (12 month total).rate": "22.1",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2020-09-01.Anti-social behaviour (12 month total).rate": "35.2",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2020-09-01.Anti-social behaviour (12 month total).rate": "24.9",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2020-09-01.Anti-social behaviour (12 month total).rate": "20.5",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2020-12-01.Anti-social behaviour (12 month total).rate": "33.0",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2020-12-01.Anti-social behaviour (12 month total).rate": "22.1",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2020-12-01.Anti-social behaviour (12 month total).rate": "19.0",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2021-03-01.Anti-social behaviour (12 month total).rate": "32.6",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2021-03-01.Anti-social behaviour (12 month total).rate": "19.3",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2021-03-01.Anti-social behaviour (12 month total).rate": "15.6",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2021-06-01.Anti-social behaviour (12 month total).rate": "25.9",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2021-06-01.Anti-social behaviour (12 month total).rate": "17.7",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2021-06-01.Anti-social behaviour (12 month total).rate": "13.6",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2021-09-01.Anti-social behaviour (12 month total).rate": "21.6",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2021-09-01.Anti-social behaviour (12 month total).rate": "16.7",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2021-09-01.Anti-social behaviour (12 month total).rate": "12.6",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2021-12-01.Anti-social behaviour (12 month total).rate": "18.5",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2021-12-01.Anti-social behaviour (12 month total).rate": "16.6",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2021-12-01.Anti-social behaviour (12 month total).rate": "11.9",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2022-03-01.Anti-social behaviour (12 month total).rate": "15.9",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2022-03-01.Anti-social behaviour (12 month total).rate": "15.8",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2022-03-01.Anti-social behaviour (12 month total).rate": "11.2",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2022-06-01.Anti-social behaviour (12 month total).rate": "17.5",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2022-06-01.Anti-social behaviour (12 month total).rate": "17.9",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2022-06-01.Anti-social behaviour (12 month total).rate": "12.8",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2022-09-01.Anti-social behaviour (12 month total).rate": "20.3",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2022-09-01.Anti-social behaviour (12 month total).rate": "20.1",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2022-09-01.Anti-social behaviour (12 month total).rate": "14.1",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2022-12-01.Anti-social behaviour (12 month total).rate": "21.3",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2022-12-01.Anti-social behaviour (12 month total).rate": "19.5",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2022-12-01.Anti-social behaviour (12 month total).rate": "14.0",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2023-03-01.Anti-social behaviour (12 month total).rate": "22.4",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2023-03-01.Anti-social behaviour (12 month total).rate": "19.0",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2023-03-01.Anti-social behaviour (12 month total).rate": "13.9",
        "CSTM5160.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total).rate": "22.7",
        "E92000001.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total).rate": "17.6",
        "E12000008.anti_social_behaviour_police_uk_12_month*dr.2023-06-01.Anti-social behaviour (12 month total).rate": "13.7",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2018-09-01.Violent crime and sexual offences (12 month total).rate": "33.0",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2018-09-01.Violent crime and sexual offences (12 month total).rate": "30.0",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2018-09-01.Violent crime and sexual offences (12 month total).rate": "27.9",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2018-12-01.Violent crime and sexual offences (12 month total).rate": "34.2",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2018-12-01.Violent crime and sexual offences (12 month total).rate": "29.9",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2018-12-01.Violent crime and sexual offences (12 month total).rate": "28.5",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2019-03-01.Violent crime and sexual offences (12 month total).rate": "35.4",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2019-03-01.Violent crime and sexual offences (12 month total).rate": "30.2",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2019-03-01.Violent crime and sexual offences (12 month total).rate": "31.4",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2019-06-01.Violent crime and sexual offences (12 month total).rate": "28.7",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2019-06-01.Violent crime and sexual offences (12 month total).rate": "24.6",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2019-06-01.Violent crime and sexual offences (12 month total).rate": "25.8",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2019-09-01.Violent crime and sexual offences (12 month total).rate": "35.2",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2019-09-01.Violent crime and sexual offences (12 month total).rate": "29.7",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2019-09-01.Violent crime and sexual offences (12 month total).rate": "32.0",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2019-12-01.Violent crime and sexual offences (12 month total).rate": "34.4",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2019-12-01.Violent crime and sexual offences (12 month total).rate": "29.9",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2019-12-01.Violent crime and sexual offences (12 month total).rate": "32.2",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2020-03-01.Violent crime and sexual offences (12 month total).rate": "32.2",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2020-03-01.Violent crime and sexual offences (12 month total).rate": "29.3",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2020-03-01.Violent crime and sexual offences (12 month total).rate": "31.4",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2020-06-01.Violent crime and sexual offences (12 month total).rate": "32.9",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2020-06-01.Violent crime and sexual offences (12 month total).rate": "30.4",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2020-06-01.Violent crime and sexual offences (12 month total).rate": "33.0",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2020-09-01.Violent crime and sexual offences (12 month total).rate": "32.9",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2020-09-01.Violent crime and sexual offences (12 month total).rate": "31.3",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2020-09-01.Violent crime and sexual offences (12 month total).rate": "33.8",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2020-12-01.Violent crime and sexual offences (12 month total).rate": "35.1",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2020-12-01.Violent crime and sexual offences (12 month total).rate": "30.4",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2020-12-01.Violent crime and sexual offences (12 month total).rate": "35.4",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2021-03-01.Violent crime and sexual offences (12 month total).rate": "38.9",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2021-03-01.Violent crime and sexual offences (12 month total).rate": "31.5",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2021-03-01.Violent crime and sexual offences (12 month total).rate": "34.6",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2021-06-01.Violent crime and sexual offences (12 month total).rate": "39.8",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2021-06-01.Violent crime and sexual offences (12 month total).rate": "32.3",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2021-06-01.Violent crime and sexual offences (12 month total).rate": "35.1",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2021-09-01.Violent crime and sexual offences (12 month total).rate": "39.8",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2021-09-01.Violent crime and sexual offences (12 month total).rate": "33.2",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2021-09-01.Violent crime and sexual offences (12 month total).rate": "35.1",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2021-12-01.Violent crime and sexual offences (12 month total).rate": "37.9",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2021-12-01.Violent crime and sexual offences (12 month total).rate": "33.7",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2021-12-01.Violent crime and sexual offences (12 month total).rate": "34.7",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2022-03-01.Violent crime and sexual offences (12 month total).rate": "37.3",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2022-03-01.Violent crime and sexual offences (12 month total).rate": "33.7",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2022-03-01.Violent crime and sexual offences (12 month total).rate": "33.9",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2022-06-01.Violent crime and sexual offences (12 month total).rate": "45.4",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2022-06-01.Violent crime and sexual offences (12 month total).rate": "41.2",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2022-06-01.Violent crime and sexual offences (12 month total).rate": "41.4",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2022-09-01.Violent crime and sexual offences (12 month total).rate": "53.5",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2022-09-01.Violent crime and sexual offences (12 month total).rate": "45.3",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2022-09-01.Violent crime and sexual offences (12 month total).rate": "45.3",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2022-12-01.Violent crime and sexual offences (12 month total).rate": "54.2",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2022-12-01.Violent crime and sexual offences (12 month total).rate": "46.7",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2022-12-01.Violent crime and sexual offences (12 month total).rate": "46.9",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2023-03-01.Violent crime and sexual offences (12 month total).rate": "55.1",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2023-03-01.Violent crime and sexual offences (12 month total).rate": "45.8",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2023-03-01.Violent crime and sexual offences (12 month total).rate": "46.1",
        "CSTM5160.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total).rate": "49.9",
        "E92000001.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total).rate": "41.1",
        "E12000008.violence_and_sexual_offences_police_uk_12_month*dr.2023-06-01.Violent crime and sexual offences (12 month total).rate": "41.8",
        "CSTM5160.burglary_police_uk_12_month*dr.2020-03-01.Burglary (12 month total).rate": "8.7",
        "E92000001.burglary_police_uk_12_month*dr.2020-03-01.Burglary (12 month total).rate": "10.1",
        "E12000008.burglary_police_uk_12_month*dr.2020-03-01.Burglary (12 month total).rate": "8.3",
        "CSTM5160.burglary_police_uk_12_month*dr.2020-06-01.Burglary (12 month total).rate": "7.5",
        "E92000001.burglary_police_uk_12_month*dr.2020-06-01.Burglary (12 month total).rate": "9.7",
        "E12000008.burglary_police_uk_12_month*dr.2020-06-01.Burglary (12 month total).rate": "8.0",
        "CSTM5160.burglary_police_uk_12_month*dr.2020-09-01.Burglary (12 month total).rate": "7.2",
        "E92000001.burglary_police_uk_12_month*dr.2020-09-01.Burglary (12 month total).rate": "9.5",
        "E12000008.burglary_police_uk_12_month*dr.2020-09-01.Burglary (12 month total).rate": "7.7",
        "CSTM5160.burglary_police_uk_12_month*dr.2020-12-01.Burglary (12 month total).rate": "7.0",
        "E92000001.burglary_police_uk_12_month*dr.2020-12-01.Burglary (12 month total).rate": "8.7",
        "E12000008.burglary_police_uk_12_month*dr.2020-12-01.Burglary (12 month total).rate": "7.5",
        "CSTM5160.burglary_police_uk_12_month*dr.2021-03-01.Burglary (12 month total).rate": "7.2",
        "E92000001.burglary_police_uk_12_month*dr.2021-03-01.Burglary (12 month total).rate": "8.8",
        "E12000008.burglary_police_uk_12_month*dr.2021-03-01.Burglary (12 month total).rate": "7.7",
        "CSTM5160.burglary_police_uk_12_month*dr.2021-06-01.Burglary (12 month total).rate": "7.5",
        "E92000001.burglary_police_uk_12_month*dr.2021-06-01.Burglary (12 month total).rate": "9.2",
        "E12000008.burglary_police_uk_12_month*dr.2021-06-01.Burglary (12 month total).rate": "7.9",
        "CSTM5160.burglary_police_uk_12_month*dr.2021-09-01.Burglary (12 month total).rate": "7.7",
        "E92000001.burglary_police_uk_12_month*dr.2021-09-01.Burglary (12 month total).rate": "9.5",
        "E12000008.burglary_police_uk_12_month*dr.2021-09-01.Burglary (12 month total).rate": "8.1",
        "CSTM5160.burglary_police_uk_12_month*dr.2021-12-01.Burglary (12 month total).rate": "8.1",
        "E92000001.burglary_police_uk_12_month*dr.2021-12-01.Burglary (12 month total).rate": "9.8",
        "E12000008.burglary_police_uk_12_month*dr.2021-12-01.Burglary (12 month total).rate": "8.3",
        "CSTM5160.burglary_police_uk_12_month*dr.2022-03-01.Burglary (12 month total).rate": "8.6",
        "E92000001.burglary_police_uk_12_month*dr.2022-03-01.Burglary (12 month total).rate": "10.0",
        "E12000008.burglary_police_uk_12_month*dr.2022-03-01.Burglary (12 month total).rate": "8.3",
        "CSTM5160.burglary_police_uk_12_month*dr.2022-06-01.Burglary (12 month total).rate": "11.1",
        "E92000001.burglary_police_uk_12_month*dr.2022-06-01.Burglary (12 month total).rate": "12.5",
        "E12000008.burglary_police_uk_12_month*dr.2022-06-01.Burglary (12 month total).rate": "10.1",
        "CSTM5160.burglary_police_uk_12_month*dr.2022-09-01.Burglary (12 month total).rate": "13.4",
        "E92000001.burglary_police_uk_12_month*dr.2022-09-01.Burglary (12 month total).rate": "15.0",
        "E12000008.burglary_police_uk_12_month*dr.2022-09-01.Burglary (12 month total).rate": "11.7",
        "CSTM5160.burglary_police_uk_12_month*dr.2022-12-01.Burglary (12 month total).rate": "13.4",
        "E92000001.burglary_police_uk_12_month*dr.2022-12-01.Burglary (12 month total).rate": "14.7",
        "E12000008.burglary_police_uk_12_month*dr.2022-12-01.Burglary (12 month total).rate": "11.4",
        "CSTM5160.burglary_police_uk_12_month*dr.2023-03-01.Burglary (12 month total).rate": "12.8",
        "E92000001.burglary_police_uk_12_month*dr.2023-03-01.Burglary (12 month total).rate": "14.3",
        "E12000008.burglary_police_uk_12_month*dr.2023-03-01.Burglary (12 month total).rate": "11.3",
        "CSTM5160.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total).rate": "11.3",
        "E92000001.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total).rate": "12.5",
        "E12000008.burglary_police_uk_12_month*dr.2023-06-01.Burglary (12 month total).rate": "10.3",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2018-09-01.Criminal damage (12 month total).rate": "9.5",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2018-09-01.Criminal damage (12 month total).rate": "9.0",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2018-09-01.Criminal damage (12 month total).rate": "8.7",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2018-12-01.Criminal damage (12 month total).rate": "9.7",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2018-12-01.Criminal damage (12 month total).rate": "8.8",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2018-12-01.Criminal damage (12 month total).rate": "8.7",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2019-03-01.Criminal damage (12 month total).rate": "9.9",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2019-03-01.Criminal damage (12 month total).rate": "8.7",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2019-03-01.Criminal damage (12 month total).rate": "9.4",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2019-06-01.Criminal damage (12 month total).rate": "8.0",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2019-06-01.Criminal damage (12 month total).rate": "6.8",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2019-06-01.Criminal damage (12 month total).rate": "7.4",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2019-09-01.Criminal damage (12 month total).rate": "10.2",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2019-09-01.Criminal damage (12 month total).rate": "7.9",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2019-09-01.Criminal damage (12 month total).rate": "8.9",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2019-12-01.Criminal damage (12 month total).rate": "10.3",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2019-12-01.Criminal damage (12 month total).rate": "7.8",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2019-12-01.Criminal damage (12 month total).rate": "8.6",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2020-03-01.Criminal damage (12 month total).rate": "10.0",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2020-03-01.Criminal damage (12 month total).rate": "7.3",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2020-03-01.Criminal damage (12 month total).rate": "8.1",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2020-06-01.Criminal damage (12 month total).rate": "10.0",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2020-06-01.Criminal damage (12 month total).rate": "7.5",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2020-06-01.Criminal damage (12 month total).rate": "8.3",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2020-09-01.Criminal damage (12 month total).rate": "9.2",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2020-09-01.Criminal damage (12 month total).rate": "7.6",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2020-09-01.Criminal damage (12 month total).rate": "8.3",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2020-12-01.Criminal damage (12 month total).rate": "8.6",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2020-12-01.Criminal damage (12 month total).rate": "7.3",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2020-12-01.Criminal damage (12 month total).rate": "8.3",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2021-03-01.Criminal damage (12 month total).rate": "8.9",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2021-03-01.Criminal damage (12 month total).rate": "7.5",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2021-03-01.Criminal damage (12 month total).rate": "8.0",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2021-06-01.Criminal damage (12 month total).rate": "9.6",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2021-06-01.Criminal damage (12 month total).rate": "7.6",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2021-06-01.Criminal damage (12 month total).rate": "8.1",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2021-09-01.Criminal damage (12 month total).rate": "9.4",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2021-09-01.Criminal damage (12 month total).rate": "7.8",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2021-09-01.Criminal damage (12 month total).rate": "8.2",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2021-12-01.Criminal damage (12 month total).rate": "9.6",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2021-12-01.Criminal damage (12 month total).rate": "7.8",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2021-12-01.Criminal damage (12 month total).rate": "8.1",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2022-03-01.Criminal damage (12 month total).rate": "9.6",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2022-03-01.Criminal damage (12 month total).rate": "7.8",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2022-03-01.Criminal damage (12 month total).rate": "7.9",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2022-06-01.Criminal damage (12 month total).rate": "11.3",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2022-06-01.Criminal damage (12 month total).rate": "9.4",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2022-06-01.Criminal damage (12 month total).rate": "9.6",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2022-09-01.Criminal damage (12 month total).rate": "13.5",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2022-09-01.Criminal damage (12 month total).rate": "10.8",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2022-09-01.Criminal damage (12 month total).rate": "11.1",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2022-12-01.Criminal damage (12 month total).rate": "13.2",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2022-12-01.Criminal damage (12 month total).rate": "10.6",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2022-12-01.Criminal damage (12 month total).rate": "10.8",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2023-03-01.Criminal damage (12 month total).rate": "13.1",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2023-03-01.Criminal damage (12 month total).rate": "10.3",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2023-03-01.Criminal damage (12 month total).rate": "10.6",
        "CSTM5160.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total).rate": "11.5",
        "E92000001.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total).rate": "9.0",
        "E12000008.criminal_damage_and_arson_police_uk_12_month*dr.2023-06-01.Criminal damage (12 month total).rate": "9.5",
        "CSTM5160.census11_p04_65_69*dr.2021-01-01.People aged 65-69 (Census 2021).rate": "3.94%",
        "E92000001.census11_p04_65_69*dr.2021-01-01.People aged 65-69 (Census 2021).rate": "4.90%",
        "E12000008.census11_p04_65_69*dr.2021-01-01.People aged 65-69 (Census 2021).rate": "4.98%",
        "CSTM5160.census11_p04_70_74*dr.2021-01-01.People aged 70-74 (Census 2021).rate": "3.71%",
        "E92000001.census11_p04_70_74*dr.2021-01-01.People aged 70-74 (Census 2021).rate": "4.95%",
        "E12000008.census11_p04_70_74*dr.2021-01-01.People aged 70-74 (Census 2021).rate": "5.19%",
        "CSTM5160.census11_p04_75_79*dr.2021-01-01.People aged 75-79 (Census 2021).rate": "2.57%",
        "E92000001.census11_p04_75_79*dr.2021-01-01.People aged 75-79 (Census 2021).rate": "3.61%",
        "E12000008.census11_p04_75_79*dr.2021-01-01.People aged 75-79 (Census 2021).rate": "3.86%",
        "CSTM5160.census11_p04_80_84*dr.2021-01-01.People aged 80-84 (Census 2021).rate": "1.85%",
        "E92000001.census11_p04_80_84*dr.2021-01-01.People aged 80-84 (Census 2021).rate": "2.52%",
        "E12000008.census11_p04_80_84*dr.2021-01-01.People aged 80-84 (Census 2021).rate": "2.69%",
        "CSTM5160.census11_p04_85_89*dr.2021-01-01.People aged 85-89 (Census 2021).rate": "1.23%",
        "E92000001.census11_p04_85_89*dr.2021-01-01.People aged 85-89 (Census 2021).rate": "1.54%",
        "E12000008.census11_p04_85_89*dr.2021-01-01.People aged 85-89 (Census 2021).rate": "1.70%",
        "CSTM5160.census11_p04_90pl*dr.2021-01-01.People aged 90 and over (Census 2021).rate": "0.79%",
        "E92000001.census11_p04_90pl*dr.2021-01-01.People aged 90 and over (Census 2021).rate": "0.88%",
        "E12000008.census11_p04_90pl*dr.2021-01-01.People aged 90 and over (Census 2021).rate": "1.03%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2001-01-01.Population aged 65+.rate": "16.1%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2001-01-01.Population aged 65+.rate": "15.8%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2001-01-01.Population aged 65+.rate": "16.3%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2002-01-01.Population aged 65+.rate": "13.56%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2002-01-01.Population aged 65+.rate": "14.07%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2002-01-01.Population aged 65+.rate": "15.20%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2003-01-01.Population aged 65+.rate": "13.28%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2003-01-01.Population aged 65+.rate": "14.16%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2003-01-01.Population aged 65+.rate": "15.30%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2004-01-01.Population aged 65+.rate": "13.02%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2004-01-01.Population aged 65+.rate": "14.24%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2004-01-01.Population aged 65+.rate": "15.41%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2005-01-01.Population aged 65+.rate": "14.8%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2005-01-01.Population aged 65+.rate": "16.0%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2005-01-01.Population aged 65+.rate": "17.5%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2006-01-01.Population aged 65+.rate": "12.59%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2006-01-01.Population aged 65+.rate": "14.38%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2006-01-01.Population aged 65+.rate": "15.62%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2007-01-01.Population aged 65+.rate": "12.34%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2007-01-01.Population aged 65+.rate": "14.51%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2007-01-01.Population aged 65+.rate": "15.83%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2008-01-01.Population aged 65+.rate": "12.23%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2008-01-01.Population aged 65+.rate": "14.73%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2008-01-01.Population aged 65+.rate": "16.13%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2009-01-01.Population aged 65+.rate": "12.18%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2009-01-01.Population aged 65+.rate": "15.00%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2009-01-01.Population aged 65+.rate": "16.48%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2010-01-01.Population aged 65+.rate": "12.21%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2010-01-01.Population aged 65+.rate": "15.29%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2010-01-01.Population aged 65+.rate": "16.86%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2011-01-01.Population aged 65+.rate": "13.13%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2011-01-01.Population aged 65+.rate": "16.60%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2011-01-01.Population aged 65+.rate": "18.36%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2012-01-01.Population aged 65+.rate": "13.28%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2012-01-01.Population aged 65+.rate": "16.93%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2012-01-01.Population aged 65+.rate": "17.85%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2013-01-01.Population aged 65+.rate": "13.38%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2013-01-01.Population aged 65+.rate": "17.27%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2013-01-01.Population aged 65+.rate": "18.26%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2014-01-01.Population aged 65+.rate": "13.45%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2014-01-01.Population aged 65+.rate": "17.56%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2014-01-01.Population aged 65+.rate": "18.58%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2015-01-01.Population aged 65+.rate": "13.40%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2015-01-01.Population aged 65+.rate": "17.73%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2015-01-01.Population aged 65+.rate": "18.77%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2016-01-01.Population aged 65+.rate": "13.36%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2016-01-01.Population aged 65+.rate": "17.88%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2016-01-01.Population aged 65+.rate": "18.94%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2017-01-01.Population aged 65+.rate": "13.30%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2017-01-01.Population aged 65+.rate": "18.03%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2017-01-01.Population aged 65+.rate": "19.11%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2018-01-01.Population aged 65+.rate": "13.26%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2018-01-01.Population aged 65+.rate": "18.18%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2018-01-01.Population aged 65+.rate": "19.29%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2019-01-01.Population aged 65+.rate": "13.35%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2019-01-01.Population aged 65+.rate": "18.39%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2019-01-01.Population aged 65+.rate": "19.55%",
        "CSTM5160.mye_census11revision_popest5yr_aged_65pl*dr.2020-01-01.Population aged 65+.rate": "13.37%",
        "E92000001.mye_census11revision_popest5yr_aged_65pl*dr.2020-01-01.Population aged 65+.rate": "18.50%",
        "E12000008.mye_census11revision_popest5yr_aged_65pl*dr.2020-01-01.Population aged 65+.rate": "19.70%",
        "CSTM5160.pc_total*dr.2024-02-01.Pensioners in poverty (Pension Credit).rate": "14.64%",
        "E92000001.pc_total*dr.2024-02-01.Pensioners in poverty (Pension Credit).rate": "10.85%",
        "E12000008.pc_total*dr.2024-02-01.Pensioners in poverty (Pension Credit).rate": "8.00%",
        "CSTM5160.pc_female*dr.2024-02-01.Pension Credit claimants, female.rate": "16.48%",
        "E92000001.pc_female*dr.2024-02-01.Pension Credit claimants, female.rate": "13.25%",
        "E12000008.pc_female*dr.2024-02-01.Pension Credit claimants, female.rate": "9.85%",
        "CSTM5160.pc_male*dr.2024-02-01.Pension Credit claimants, male.rate": "12.45%",
        "E92000001.pc_male*dr.2024-02-01.Pension Credit claimants, male.rate": "8.01%",
        "E12000008.pc_male*dr.2024-02-01.Pension Credit claimants, male.rate": "5.79%",
        "CSTM5160.pc_gc*dr.2024-02-01.Pension Credit claimants, Guarantee Element.rate": "9.18%",
        "E92000001.pc_gc*dr.2024-02-01.Pension Credit claimants, Guarantee Element.rate": "6.15%",
        "E12000008.pc_gc*dr.2024-02-01.Pension Credit claimants, Guarantee Element.rate": "4.55%",
        "CSTM5160.pc_gcandsc*dr.2024-02-01.Pension Credit claimants, Guarantee and Saving Element.rate": "3.93%",
        "E92000001.pc_gcandsc*dr.2024-02-01.Pension Credit claimants, Guarantee and Saving Element.rate": "3.31%",
        "E12000008.pc_gcandsc*dr.2024-02-01.Pension Credit claimants, Guarantee and Saving Element.rate": "2.38%",
        "CSTM5160.pc_sc*dr.2024-02-01.Pension Credit claimants, Saving Element.rate": "1.53%",
        "E92000001.pc_sc*dr.2024-02-01.Pension Credit claimants, Saving Element.rate": "1.39%",
        "E12000008.pc_sc*dr.2024-02-01.Pension Credit claimants, Saving Element.rate": "1.07%",
        "CSTM5160.pc_single*dr.2024-02-01.Pension Credit claimants who are single.rate": "13.33%",
        "E92000001.pc_single*dr.2024-02-01.Pension Credit claimants who are single.rate": "9.43%",
        "E12000008.pc_single*dr.2024-02-01.Pension Credit claimants who are single.rate": "7.00%",
        "CSTM5160.pc_withpartner*dr.2024-02-01.Pension Credit claimants who are with partner.rate": "1.33%",
        "E92000001.pc_withpartner*dr.2024-02-01.Pension Credit claimants who are with partner.rate": "1.42%",
        "E12000008.pc_withpartner*dr.2024-02-01.Pension Credit claimants who are with partner.rate": "1.00%",
        "CSTM5160.pc_total*dr.2010-11-01.Pensioners in poverty (Pension Credit).rate": "29.58%",
        "E92000001.pc_total*dr.2010-11-01.Pensioners in poverty (Pension Credit).rate": "22.01%",
        "E12000008.pc_total*dr.2010-11-01.Pensioners in poverty (Pension Credit).rate": "16.83%",
        "CSTM5160.pc_total*dr.2011-02-01.Pensioners in poverty (Pension Credit).rate": "31.67%",
        "E92000001.pc_total*dr.2011-02-01.Pensioners in poverty (Pension Credit).rate": "25.90%",
        "E12000008.pc_total*dr.2011-02-01.Pensioners in poverty (Pension Credit).rate": "19.01%",
        "CSTM5160.pc_total*dr.2011-05-01.Pensioners in poverty (Pension Credit).rate": "31.27%",
        "E92000001.pc_total*dr.2011-05-01.Pensioners in poverty (Pension Credit).rate": "25.73%",
        "E12000008.pc_total*dr.2011-05-01.Pensioners in poverty (Pension Credit).rate": "18.90%",
        "CSTM5160.pc_total*dr.2011-08-01.Pensioners in poverty (Pension Credit).rate": "31.17%",
        "E92000001.pc_total*dr.2011-08-01.Pensioners in poverty (Pension Credit).rate": "25.57%",
        "E12000008.pc_total*dr.2011-08-01.Pensioners in poverty (Pension Credit).rate": "18.83%",
        "CSTM5160.pc_total*dr.2011-11-01.Pensioners in poverty (Pension Credit).rate": "30.98%",
        "E92000001.pc_total*dr.2011-11-01.Pensioners in poverty (Pension Credit).rate": "25.44%",
        "E12000008.pc_total*dr.2011-11-01.Pensioners in poverty (Pension Credit).rate": "18.73%",
        "CSTM5160.pc_total*dr.2012-02-01.Pensioners in poverty (Pension Credit).rate": "29.98%",
        "E92000001.pc_total*dr.2012-02-01.Pensioners in poverty (Pension Credit).rate": "24.25%",
        "E12000008.pc_total*dr.2012-02-01.Pensioners in poverty (Pension Credit).rate": "17.80%",
        "CSTM5160.pc_total*dr.2012-05-01.Pensioners in poverty (Pension Credit).rate": "29.27%",
        "E92000001.pc_total*dr.2012-05-01.Pensioners in poverty (Pension Credit).rate": "23.58%",
        "E12000008.pc_total*dr.2012-05-01.Pensioners in poverty (Pension Credit).rate": "17.32%",
        "CSTM5160.pc_total*dr.2012-08-01.Pensioners in poverty (Pension Credit).rate": "28.90%",
        "E92000001.pc_total*dr.2012-08-01.Pensioners in poverty (Pension Credit).rate": "23.37%",
        "E12000008.pc_total*dr.2012-08-01.Pensioners in poverty (Pension Credit).rate": "17.17%",
        "CSTM5160.pc_total*dr.2012-11-01.Pensioners in poverty (Pension Credit).rate": "28.75%",
        "E92000001.pc_total*dr.2012-11-01.Pensioners in poverty (Pension Credit).rate": "23.18%",
        "E12000008.pc_total*dr.2012-11-01.Pensioners in poverty (Pension Credit).rate": "17.05%",
        "CSTM5160.pc_total*dr.2013-02-01.Pensioners in poverty (Pension Credit).rate": "27.96%",
        "E92000001.pc_total*dr.2013-02-01.Pensioners in poverty (Pension Credit).rate": "22.27%",
        "E12000008.pc_total*dr.2013-02-01.Pensioners in poverty (Pension Credit).rate": "16.32%",
        "CSTM5160.pc_total*dr.2013-05-01.Pensioners in poverty (Pension Credit).rate": "27.48%",
        "E92000001.pc_total*dr.2013-05-01.Pensioners in poverty (Pension Credit).rate": "21.81%",
        "E12000008.pc_total*dr.2013-05-01.Pensioners in poverty (Pension Credit).rate": "15.99%",
        "CSTM5160.pc_total*dr.2013-08-01.Pensioners in poverty (Pension Credit).rate": "27.17%",
        "E92000001.pc_total*dr.2013-08-01.Pensioners in poverty (Pension Credit).rate": "21.61%",
        "E12000008.pc_total*dr.2013-08-01.Pensioners in poverty (Pension Credit).rate": "15.85%",
        "CSTM5160.pc_total*dr.2013-11-01.Pensioners in poverty (Pension Credit).rate": "26.99%",
        "E92000001.pc_total*dr.2013-11-01.Pensioners in poverty (Pension Credit).rate": "21.44%",
        "E12000008.pc_total*dr.2013-11-01.Pensioners in poverty (Pension Credit).rate": "15.72%",
        "CSTM5160.pc_total*dr.2014-02-01.Pensioners in poverty (Pension Credit).rate": "26.22%",
        "E92000001.pc_total*dr.2014-02-01.Pensioners in poverty (Pension Credit).rate": "20.65%",
        "E12000008.pc_total*dr.2014-02-01.Pensioners in poverty (Pension Credit).rate": "15.11%",
        "CSTM5160.pc_total*dr.2014-05-01.Pensioners in poverty (Pension Credit).rate": "25.36%",
        "E92000001.pc_total*dr.2014-05-01.Pensioners in poverty (Pension Credit).rate": "20.04%",
        "E12000008.pc_total*dr.2014-05-01.Pensioners in poverty (Pension Credit).rate": "14.63%",
        "CSTM5160.pc_total*dr.2014-08-01.Pensioners in poverty (Pension Credit).rate": "25.28%",
        "E92000001.pc_total*dr.2014-08-01.Pensioners in poverty (Pension Credit).rate": "19.80%",
        "E12000008.pc_total*dr.2014-08-01.Pensioners in poverty (Pension Credit).rate": "14.47%",
        "CSTM5160.pc_total*dr.2014-11-01.Pensioners in poverty (Pension Credit).rate": "24.92%",
        "E92000001.pc_total*dr.2014-11-01.Pensioners in poverty (Pension Credit).rate": "19.59%",
        "E12000008.pc_total*dr.2014-11-01.Pensioners in poverty (Pension Credit).rate": "14.33%",
        "CSTM5160.pc_total*dr.2015-02-01.Pensioners in poverty (Pension Credit).rate": "24.24%",
        "E92000001.pc_total*dr.2015-02-01.Pensioners in poverty (Pension Credit).rate": "18.89%",
        "E12000008.pc_total*dr.2015-02-01.Pensioners in poverty (Pension Credit).rate": "13.80%",
        "CSTM5160.pc_total*dr.2015-05-01.Pensioners in poverty (Pension Credit).rate": "23.46%",
        "E92000001.pc_total*dr.2015-05-01.Pensioners in poverty (Pension Credit).rate": "18.19%",
        "E12000008.pc_total*dr.2015-05-01.Pensioners in poverty (Pension Credit).rate": "13.28%",
        "CSTM5160.pc_total*dr.2015-08-01.Pensioners in poverty (Pension Credit).rate": "23.23%",
        "E92000001.pc_total*dr.2015-08-01.Pensioners in poverty (Pension Credit).rate": "17.95%",
        "E12000008.pc_total*dr.2015-08-01.Pensioners in poverty (Pension Credit).rate": "13.09%",
        "CSTM5160.pc_total*dr.2015-11-01.Pensioners in poverty (Pension Credit).rate": "23.07%",
        "E92000001.pc_total*dr.2015-11-01.Pensioners in poverty (Pension Credit).rate": "17.74%",
        "E12000008.pc_total*dr.2015-11-01.Pensioners in poverty (Pension Credit).rate": "12.93%",
        "CSTM5160.pc_total*dr.2016-02-01.Pensioners in poverty (Pension Credit).rate": "22.55%",
        "E92000001.pc_total*dr.2016-02-01.Pensioners in poverty (Pension Credit).rate": "17.16%",
        "E12000008.pc_total*dr.2016-02-01.Pensioners in poverty (Pension Credit).rate": "12.49%",
        "CSTM5160.pc_total*dr.2016-05-01.Pensioners in poverty (Pension Credit).rate": "21.96%",
        "E92000001.pc_total*dr.2016-05-01.Pensioners in poverty (Pension Credit).rate": "16.63%",
        "E12000008.pc_total*dr.2016-05-01.Pensioners in poverty (Pension Credit).rate": "12.11%",
        "CSTM5160.pc_total*dr.2016-08-01.Pensioners in poverty (Pension Credit).rate": "21.43%",
        "E92000001.pc_total*dr.2016-08-01.Pensioners in poverty (Pension Credit).rate": "16.38%",
        "E12000008.pc_total*dr.2016-08-01.Pensioners in poverty (Pension Credit).rate": "11.95%",
        "CSTM5160.pc_total*dr.2016-11-01.Pensioners in poverty (Pension Credit).rate": "21.12%",
        "E92000001.pc_total*dr.2016-11-01.Pensioners in poverty (Pension Credit).rate": "16.15%",
        "E12000008.pc_total*dr.2016-11-01.Pensioners in poverty (Pension Credit).rate": "11.76%",
        "CSTM5160.pc_total*dr.2017-02-01.Pensioners in poverty (Pension Credit).rate": "20.62%",
        "E92000001.pc_total*dr.2017-02-01.Pensioners in poverty (Pension Credit).rate": "15.58%",
        "E12000008.pc_total*dr.2017-02-01.Pensioners in poverty (Pension Credit).rate": "11.34%",
        "CSTM5160.pc_total*dr.2017-05-01.Pensioners in poverty (Pension Credit).rate": "20.51%",
        "E92000001.pc_total*dr.2017-05-01.Pensioners in poverty (Pension Credit).rate": "15.33%",
        "E12000008.pc_total*dr.2017-05-01.Pensioners in poverty (Pension Credit).rate": "11.18%",
        "CSTM5160.pc_total*dr.2017-08-01.Pensioners in poverty (Pension Credit).rate": "20.17%",
        "E92000001.pc_total*dr.2017-08-01.Pensioners in poverty (Pension Credit).rate": "15.10%",
        "E12000008.pc_total*dr.2017-08-01.Pensioners in poverty (Pension Credit).rate": "11.03%",
        "CSTM5160.pc_total*dr.2017-11-01.Pensioners in poverty (Pension Credit).rate": "19.68%",
        "E92000001.pc_total*dr.2017-11-01.Pensioners in poverty (Pension Credit).rate": "14.86%",
        "E12000008.pc_total*dr.2017-11-01.Pensioners in poverty (Pension Credit).rate": "10.83%",
        "CSTM5160.pc_total*dr.2018-02-01.Pensioners in poverty (Pension Credit).rate": "19.14%",
        "E92000001.pc_total*dr.2018-02-01.Pensioners in poverty (Pension Credit).rate": "14.33%",
        "E12000008.pc_total*dr.2018-02-01.Pensioners in poverty (Pension Credit).rate": "10.43%",
        "CSTM5160.pc_total*dr.2018-05-01.Pensioners in poverty (Pension Credit).rate": "18.82%",
        "E92000001.pc_total*dr.2018-05-01.Pensioners in poverty (Pension Credit).rate": "14.03%",
        "E12000008.pc_total*dr.2018-05-01.Pensioners in poverty (Pension Credit).rate": "10.23%",
        "CSTM5160.pc_total*dr.2018-08-01.Pensioners in poverty (Pension Credit).rate": "18.52%",
        "E92000001.pc_total*dr.2018-08-01.Pensioners in poverty (Pension Credit).rate": "13.83%",
        "E12000008.pc_total*dr.2018-08-01.Pensioners in poverty (Pension Credit).rate": "10.07%",
        "CSTM5160.pc_total*dr.2018-11-01.Pensioners in poverty (Pension Credit).rate": "18.27%",
        "E92000001.pc_total*dr.2018-11-01.Pensioners in poverty (Pension Credit).rate": "13.64%",
        "E12000008.pc_total*dr.2018-11-01.Pensioners in poverty (Pension Credit).rate": "9.94%",
        "CSTM5160.pc_total*dr.2019-02-01.Pensioners in poverty (Pension Credit).rate": "17.75%",
        "E92000001.pc_total*dr.2019-02-01.Pensioners in poverty (Pension Credit).rate": "13.29%",
        "E12000008.pc_total*dr.2019-02-01.Pensioners in poverty (Pension Credit).rate": "10.16%",
        "CSTM5160.pc_total*dr.2019-05-01.Pensioners in poverty (Pension Credit).rate": "17.53%",
        "E92000001.pc_total*dr.2019-05-01.Pensioners in poverty (Pension Credit).rate": "13.11%",
        "E12000008.pc_total*dr.2019-05-01.Pensioners in poverty (Pension Credit).rate": "10.02%",
        "CSTM5160.pc_total*dr.2019-08-01.Pensioners in poverty (Pension Credit).rate": "17.31%",
        "E92000001.pc_total*dr.2019-08-01.Pensioners in poverty (Pension Credit).rate": "12.97%",
        "E12000008.pc_total*dr.2019-08-01.Pensioners in poverty (Pension Credit).rate": "9.92%",
        "CSTM5160.pc_total*dr.2019-11-01.Pensioners in poverty (Pension Credit).rate": "17.08%",
        "E92000001.pc_total*dr.2019-11-01.Pensioners in poverty (Pension Credit).rate": "12.83%",
        "E12000008.pc_total*dr.2019-11-01.Pensioners in poverty (Pension Credit).rate": "9.82%",
        "CSTM5160.pc_total*dr.2020-02-01.Pensioners in poverty (Pension Credit).rate": "16.73%",
        "E92000001.pc_total*dr.2020-02-01.Pensioners in poverty (Pension Credit).rate": "12.50%",
        "E12000008.pc_total*dr.2020-02-01.Pensioners in poverty (Pension Credit).rate": "9.56%",
        "CSTM5160.pc_total*dr.2020-05-01.Pensioners in poverty (Pension Credit).rate": "16.48%",
        "E92000001.pc_total*dr.2020-05-01.Pensioners in poverty (Pension Credit).rate": "12.27%",
        "E12000008.pc_total*dr.2020-05-01.Pensioners in poverty (Pension Credit).rate": "9.41%",
        "CSTM5160.pc_total*dr.2020-08-01.Pensioners in poverty (Pension Credit).rate": "16.30%",
        "E92000001.pc_total*dr.2020-08-01.Pensioners in poverty (Pension Credit).rate": "12.18%",
        "E12000008.pc_total*dr.2020-08-01.Pensioners in poverty (Pension Credit).rate": "9.34%",
        "CSTM5160.pc_total*dr.2020-11-01.Pensioners in poverty (Pension Credit).rate": "16.05%",
        "E92000001.pc_total*dr.2020-11-01.Pensioners in poverty (Pension Credit).rate": "12.06%",
        "E12000008.pc_total*dr.2020-11-01.Pensioners in poverty (Pension Credit).rate": "9.27%",
        "CSTM5160.pc_total*dr.2021-02-01.Pensioners in poverty (Pension Credit).rate": "15.64%",
        "E92000001.pc_total*dr.2021-02-01.Pensioners in poverty (Pension Credit).rate": "11.70%",
        "E12000008.pc_total*dr.2021-02-01.Pensioners in poverty (Pension Credit).rate": "8.52%",
        "CSTM5160.pc_total*dr.2021-05-01.Pensioners in poverty (Pension Credit).rate": "15.49%",
        "E92000001.pc_total*dr.2021-05-01.Pensioners in poverty (Pension Credit).rate": "11.59%",
        "E12000008.pc_total*dr.2021-05-01.Pensioners in poverty (Pension Credit).rate": "8.44%",
        "CSTM5160.pc_total*dr.2021-08-01.Pensioners in poverty (Pension Credit).rate": "15.34%",
        "E92000001.pc_total*dr.2021-08-01.Pensioners in poverty (Pension Credit).rate": "11.48%",
        "E12000008.pc_total*dr.2021-08-01.Pensioners in poverty (Pension Credit).rate": "8.37%",
        "CSTM5160.pc_total*dr.2021-11-01.Pensioners in poverty (Pension Credit).rate": "15.25%",
        "E92000001.pc_total*dr.2021-11-01.Pensioners in poverty (Pension Credit).rate": "11.35%",
        "E12000008.pc_total*dr.2021-11-01.Pensioners in poverty (Pension Credit).rate": "8.28%",
        "CSTM5160.pc_total*dr.2022-02-01.Pensioners in poverty (Pension Credit).rate": "14.87%",
        "E92000001.pc_total*dr.2022-02-01.Pensioners in poverty (Pension Credit).rate": "11.02%",
        "E12000008.pc_total*dr.2022-02-01.Pensioners in poverty (Pension Credit).rate": "8.03%",
        "CSTM5160.pc_total*dr.2022-05-01.Pensioners in poverty (Pension Credit).rate": "14.82%",
        "E92000001.pc_total*dr.2022-05-01.Pensioners in poverty (Pension Credit).rate": "10.92%",
        "E12000008.pc_total*dr.2022-05-01.Pensioners in poverty (Pension Credit).rate": "7.97%",
        "CSTM5160.pc_total*dr.2022-08-01.Pensioners in poverty (Pension Credit).rate": "15.04%",
        "E92000001.pc_total*dr.2022-08-01.Pensioners in poverty (Pension Credit).rate": "10.97%",
        "E12000008.pc_total*dr.2022-08-01.Pensioners in poverty (Pension Credit).rate": "8.04%",
        "CSTM5160.pc_total*dr.2022-11-01.Pensioners in poverty (Pension Credit).rate": "15.07%",
        "E92000001.pc_total*dr.2022-11-01.Pensioners in poverty (Pension Credit).rate": "10.98%",
        "E12000008.pc_total*dr.2022-11-01.Pensioners in poverty (Pension Credit).rate": "8.06%",
        "CSTM5160.pc_total*dr.2023-02-01.Pensioners in poverty (Pension Credit).rate": "14.93%",
        "E92000001.pc_total*dr.2023-02-01.Pensioners in poverty (Pension Credit).rate": "10.92%",
        "E12000008.pc_total*dr.2023-02-01.Pensioners in poverty (Pension Credit).rate": "8.03%",
        "CSTM5160.pc_total*dr.2023-05-01.Pensioners in poverty (Pension Credit).rate": "14.88%",
        "E92000001.pc_total*dr.2023-05-01.Pensioners in poverty (Pension Credit).rate": "10.98%",
        "E12000008.pc_total*dr.2023-05-01.Pensioners in poverty (Pension Credit).rate": "8.08%",
        "CSTM5160.pc_total*dr.2023-08-01.Pensioners in poverty (Pension Credit).rate": "14.86%",
        "E92000001.pc_total*dr.2023-08-01.Pensioners in poverty (Pension Credit).rate": "10.97%",
        "E12000008.pc_total*dr.2023-08-01.Pensioners in poverty (Pension Credit).rate": "8.08%",
        "CSTM5160.ks105ew0002*dr.2021-01-01.One person household: Aged 66 years and over (Census 2021).rate": "11.43%",
        "E92000001.ks105ew0002*dr.2021-01-01.One person household: Aged 66 years and over (Census 2021).rate": "12.81%",
        "E12000008.ks105ew0002*dr.2021-01-01.One person household: Aged 66 years and over (Census 2021).rate": "13.23%",
        "CSTM5160.lc3206ew_65pl_bad_vr_health*dr.2021-01-01.People over the age of 65 with bad or very bad health (Census 2021).rate": "41.34%",
        "E92000001.lc3206ew_65pl_bad_vr_health*dr.2021-01-01.People over the age of 65 with bad or very bad health (Census 2021).rate": "42.08%",
        "E12000008.lc3206ew_65pl_bad_vr_health*dr.2021-01-01.People over the age of 65 with bad or very bad health (Census 2021).rate": "37.62%",
        "CSTM5160.lc3205ew_ltti_65pl*dr.2021-01-01.People with a limiting long-term illness (aged 65+) (Census 2021).rate": "33.00%",
        "E92000001.lc3205ew_ltti_65pl*dr.2021-01-01.People with a limiting long-term illness (aged 65+) (Census 2021).rate": "32.83%",
        "E12000008.lc3205ew_ltti_65pl*dr.2021-01-01.People with a limiting long-term illness (aged 65+) (Census 2021).rate": "29.54%",
        "CSTM5160.cs062_no_car_pensioner_hh*dr.2021-01-01.Lone pensioner households with no car or van (Census 2021).rate": "61.48%",
        "E92000001.cs062_no_car_pensioner_hh*dr.2021-01-01.Lone pensioner households with no car or van (Census 2021).rate": "49.40%",
        "E12000008.cs062_no_car_pensioner_hh*dr.2021-01-01.Lone pensioner households with no car or van (Census 2021).rate": "43.34%",
        "CSTM5160.broadband_belowuso*dr.2023-05-01.Premises with broadband speeds below the Universal Service Obligation (USO).rate": "6.17%",
        "E92000001.broadband_belowuso*dr.2023-05-01.Premises with broadband speeds below the Universal Service Obligation (USO).rate": "25.18%",
        "E12000008.broadband_belowuso*dr.2023-05-01.Premises with broadband speeds below the Universal Service Obligation (USO).rate": "25.61%",
        "CSTM5160.iuc_e_cultural_creators*dr.2017-01-01.Internet User Classification: e-Cultural Creators.rate": "3.09%",
        "E92000001.iuc_e_cultural_creators*dr.2017-01-01.Internet User Classification: e-Cultural Creators.rate": "1.49%",
        "E12000008.iuc_e_cultural_creators*dr.2017-01-01.Internet User Classification: e-Cultural Creators.rate": "1.30%",
        "CSTM5160.iuc_e_rational_utilitarians*dr.2017-01-01.Internet User Classification: e-Rational Utilitarians.rate": "4.01%",
        "E92000001.iuc_e_rational_utilitarians*dr.2017-01-01.Internet User Classification: e-Rational Utilitarians.rate": "16.40%",
        "E12000008.iuc_e_rational_utilitarians*dr.2017-01-01.Internet User Classification: e-Rational Utilitarians.rate": "22.44%",
        "CSTM5160.iuc_passive_uncommitted*dr.2017-01-01.Internet User Classification: Passive and Uncommitted Users.rate": "11.40%",
        "E92000001.iuc_passive_uncommitted*dr.2017-01-01.Internet User Classification: Passive and Uncommitted Users.rate": "20.43%",
        "E12000008.iuc_passive_uncommitted*dr.2017-01-01.Internet User Classification: Passive and Uncommitted Users.rate": "12.56%",
        "CSTM5160.iuc_e_mainstream*dr.2017-01-01.Internet User Classification: e-Mainstream.rate": "14.78%",
        "E92000001.iuc_e_mainstream*dr.2017-01-01.Internet User Classification: e-Mainstream.rate": "14.44%",
        "E12000008.iuc_e_mainstream*dr.2017-01-01.Internet User Classification: e-Mainstream.rate": "17.70%",
        "CSTM5160.iuc_e_veterans*dr.2017-01-01.Internet User Classification: e-Veterans.rate": "18.30%",
        "E92000001.iuc_e_veterans*dr.2017-01-01.Internet User Classification: e-Veterans.rate": "13.59%",
        "E12000008.iuc_e_veterans*dr.2017-01-01.Internet User Classification: e-Veterans.rate": "23.37%",
        "CSTM5160.iuc_settled_offline*dr.2017-01-01.Internet User Classification: Settled Offline Communities.rate": "0.51%",
        "E92000001.iuc_settled_offline*dr.2017-01-01.Internet User Classification: Settled Offline Communities.rate": "4.48%",
        "E12000008.iuc_settled_offline*dr.2017-01-01.Internet User Classification: Settled Offline Communities.rate": "4.98%",
        "CSTM5160.iuc_digital_seniors*dr.2017-01-01.Internet User Classification: Digital Seniors.rate": "3.27%",
        "E92000001.iuc_digital_seniors*dr.2017-01-01.Internet User Classification: Digital Seniors.rate": "8.49%",
        "E12000008.iuc_digital_seniors*dr.2017-01-01.Internet User Classification: Digital Seniors.rate": "7.14%",
        "CSTM5160.iuc_e_professionals*dr.2017-01-01.Internet User Classification: e-Professionals.rate": "37.39%",
        "E92000001.iuc_e_professionals*dr.2017-01-01.Internet User Classification: e-Professionals.rate": "5.66%",
        "E12000008.iuc_e_professionals*dr.2017-01-01.Internet User Classification: e-Professionals.rate": "5.16%",
        "CSTM5160.iuc_e_withdrawn*dr.2017-01-01.Internet User Classification: e-Withdrawn.rate": "2.25%",
        "E92000001.iuc_e_withdrawn*dr.2017-01-01.Internet User Classification: e-Withdrawn.rate": "8.81%",
        "E12000008.iuc_e_withdrawn*dr.2017-01-01.Internet User Classification: e-Withdrawn.rate": "2.13%",
        "CSTM5160.iuc_youthful_urban_fringe*dr.2017-01-01.Internet User Classification: Youthful Urban Fringe.rate": "5.01%",
        "E92000001.iuc_youthful_urban_fringe*dr.2017-01-01.Internet User Classification: Youthful Urban Fringe.rate": "6.21%",
        "E12000008.iuc_youthful_urban_fringe*dr.2017-01-01.Internet User Classification: Youthful Urban Fringe.rate": "3.23%",
        "CSTM5160.id19_incpop_dec10*dr.2019-01-01.IoD 2019 Income Decile 10.rate": "1.58",
        "E92000001.id19_incpop_dec10*dr.2019-01-01.IoD 2019 Income Decile 10.rate": "9.76",
        "E12000008.id19_incpop_dec10*dr.2019-01-01.IoD 2019 Income Decile 10.rate": "18.88",
        "CSTM5160.id19_incpop_dec9*dr.2019-01-01.IoD 2019 Income Decile 9.rate": "8.54",
        "E92000001.id19_incpop_dec9*dr.2019-01-01.IoD 2019 Income Decile 9.rate": "9.70",
        "E12000008.id19_incpop_dec9*dr.2019-01-01.IoD 2019 Income Decile 9.rate": "14.28",
        "CSTM5160.id19_incpop_dec8*dr.2019-01-01.IoD 2019 Income Decile 8.rate": "11.68",
        "E92000001.id19_incpop_dec8*dr.2019-01-01.IoD 2019 Income Decile 8.rate": "9.82",
        "E12000008.id19_incpop_dec8*dr.2019-01-01.IoD 2019 Income Decile 8.rate": "11.87",
        "CSTM5160.id19_incpop_dec7*dr.2019-01-01.IoD 2019 Income Decile 7.rate": "13.88",
        "E92000001.id19_incpop_dec7*dr.2019-01-01.IoD 2019 Income Decile 7.rate": "9.87",
        "E12000008.id19_incpop_dec7*dr.2019-01-01.IoD 2019 Income Decile 7.rate": "12.98",
        "CSTM5160.id19_incpop_dec6*dr.2019-01-01.IoD 2019 Income Decile 6.rate": "14.36",
        "E92000001.id19_incpop_dec6*dr.2019-01-01.IoD 2019 Income Decile 6.rate": "9.99",
        "E12000008.id19_incpop_dec6*dr.2019-01-01.IoD 2019 Income Decile 6.rate": "11.21",
        "CSTM5160.id19_incpop_dec5*dr.2019-01-01.IoD 2019 Income Decile 5.rate": "13.70",
        "E92000001.id19_incpop_dec5*dr.2019-01-01.IoD 2019 Income Decile 5.rate": "10.23",
        "E12000008.id19_incpop_dec5*dr.2019-01-01.IoD 2019 Income Decile 5.rate": "11.34",
        "CSTM5160.id19_incpop_dec4*dr.2019-01-01.IoD 2019 Income Decile 4.rate": "11.91",
        "E92000001.id19_incpop_dec4*dr.2019-01-01.IoD 2019 Income Decile 4.rate": "10.21",
        "E12000008.id19_incpop_dec4*dr.2019-01-01.IoD 2019 Income Decile 4.rate": "9.16",
        "CSTM5160.id19_incpop_dec3*dr.2019-01-01.IoD 2019 Income Decile 3.rate": "8.39",
        "E92000001.id19_incpop_dec3*dr.2019-01-01.IoD 2019 Income Decile 3.rate": "10.16",
        "E12000008.id19_incpop_dec3*dr.2019-01-01.IoD 2019 Income Decile 3.rate": "7.47",
        "CSTM5160.id19_incpop_dec2*dr.2019-01-01.IoD 2019 Income Decile 2.rate": "6.81",
        "E92000001.id19_incpop_dec2*dr.2019-01-01.IoD 2019 Income Decile 2.rate": "10.08",
        "E12000008.id19_incpop_dec2*dr.2019-01-01.IoD 2019 Income Decile 2.rate": "5.13",
        "CSTM5160.id19_incpop_dec1*dr.2019-01-01.IoD 2019 Income Decile 1.rate": "8.20",
        "E92000001.id19_incpop_dec1*dr.2019-01-01.IoD 2019 Income Decile 1.rate": "9.96",
        "E12000008.id19_incpop_dec1*dr.2019-01-01.IoD 2019 Income Decile 1.rate": "2.83",
        "CSTM5160.ben_combs_wa_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 64).rate": "20.58%",
        "E92000001.ben_combs_wa_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 64).rate": "22.98%",
        "E12000008.ben_combs_wa_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 64).rate": "18.34%",
        "CSTM5160.ben_combs_16_24_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 24).rate": "10.22%",
        "E92000001.ben_combs_16_24_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 24).rate": "14.23%",
        "E12000008.ben_combs_16_24_tot_li*dr.2024-02-01.DWP Benefit claimants (aged 16 - 24).rate": "12.38%",
        "CSTM5160.ben_combs_spa_tot_li*dr.2024-02-01.People aged 65+ claiming DWP Benefits (Benefit Combinations).rate": "0.00%",
        "E92000001.ben_combs_spa_tot_li*dr.2024-02-01.People aged 65+ claiming DWP Benefits (Benefit Combinations).rate": "0.00%",
        "E12000008.ben_combs_spa_tot_li*dr.2024-02-01.People aged 65+ claiming DWP Benefits (Benefit Combinations).rate": "0.00%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2018-05-01.Working age Benefit claimants (Benefit combinations).rate": "14.94%",
        "E92000001.ben_combs_wa_tot_li*dr.2018-05-01.Working age Benefit claimants (Benefit combinations).rate": "16.38%",
        "E12000008.ben_combs_wa_tot_li*dr.2018-05-01.Working age Benefit claimants (Benefit combinations).rate": "12.80%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2018-08-01.Working age Benefit claimants (Benefit combinations).rate": "15.02%",
        "E92000001.ben_combs_wa_tot_li*dr.2018-08-01.Working age Benefit claimants (Benefit combinations).rate": "16.37%",
        "E12000008.ben_combs_wa_tot_li*dr.2018-08-01.Working age Benefit claimants (Benefit combinations).rate": "12.77%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2018-11-01.Working age Benefit claimants (Benefit combinations).rate": "15.10%",
        "E92000001.ben_combs_wa_tot_li*dr.2018-11-01.Working age Benefit claimants (Benefit combinations).rate": "16.43%",
        "E12000008.ben_combs_wa_tot_li*dr.2018-11-01.Working age Benefit claimants (Benefit combinations).rate": "12.82%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2019-02-01.Working age Benefit claimants (Benefit combinations).rate": "14.99%",
        "E92000001.ben_combs_wa_tot_li*dr.2019-02-01.Working age Benefit claimants (Benefit combinations).rate": "16.71%",
        "E12000008.ben_combs_wa_tot_li*dr.2019-02-01.Working age Benefit claimants (Benefit combinations).rate": "13.78%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2019-05-01.Working age Benefit claimants (Benefit combinations).rate": "15.02%",
        "E92000001.ben_combs_wa_tot_li*dr.2019-05-01.Working age Benefit claimants (Benefit combinations).rate": "16.88%",
        "E12000008.ben_combs_wa_tot_li*dr.2019-05-01.Working age Benefit claimants (Benefit combinations).rate": "13.91%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2019-08-01.Working age Benefit claimants (Benefit combinations).rate": "15.25%",
        "E92000001.ben_combs_wa_tot_li*dr.2019-08-01.Working age Benefit claimants (Benefit combinations).rate": "17.15%",
        "E12000008.ben_combs_wa_tot_li*dr.2019-08-01.Working age Benefit claimants (Benefit combinations).rate": "14.15%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2019-11-01.Working age Benefit claimants (Benefit combinations).rate": "15.33%",
        "E92000001.ben_combs_wa_tot_li*dr.2019-11-01.Working age Benefit claimants (Benefit combinations).rate": "17.39%",
        "E12000008.ben_combs_wa_tot_li*dr.2019-11-01.Working age Benefit claimants (Benefit combinations).rate": "14.37%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2020-02-01.Working age Benefit claimants (Benefit combinations).rate": "15.47%",
        "E92000001.ben_combs_wa_tot_li*dr.2020-02-01.Working age Benefit claimants (Benefit combinations).rate": "17.71%",
        "E12000008.ben_combs_wa_tot_li*dr.2020-02-01.Working age Benefit claimants (Benefit combinations).rate": "14.65%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2020-05-01.Working age Benefit claimants (Benefit combinations).rate": "21.43%",
        "E92000001.ben_combs_wa_tot_li*dr.2020-05-01.Working age Benefit claimants (Benefit combinations).rate": "23.25%",
        "E12000008.ben_combs_wa_tot_li*dr.2020-05-01.Working age Benefit claimants (Benefit combinations).rate": "20.21%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2020-08-01.Working age Benefit claimants (Benefit combinations).rate": "22.15%",
        "E92000001.ben_combs_wa_tot_li*dr.2020-08-01.Working age Benefit claimants (Benefit combinations).rate": "24.02%",
        "E12000008.ben_combs_wa_tot_li*dr.2020-08-01.Working age Benefit claimants (Benefit combinations).rate": "20.77%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2020-11-01.Working age Benefit claimants (Benefit combinations).rate": "22.26%",
        "E92000001.ben_combs_wa_tot_li*dr.2020-11-01.Working age Benefit claimants (Benefit combinations).rate": "24.26%",
        "E12000008.ben_combs_wa_tot_li*dr.2020-11-01.Working age Benefit claimants (Benefit combinations).rate": "20.89%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2021-02-01.Working age Benefit claimants (Benefit combinations).rate": "23.93%",
        "E92000001.ben_combs_wa_tot_li*dr.2021-02-01.Working age Benefit claimants (Benefit combinations).rate": "23.89%",
        "E12000008.ben_combs_wa_tot_li*dr.2021-02-01.Working age Benefit claimants (Benefit combinations).rate": "19.34%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2021-05-01.Working age Benefit claimants (Benefit combinations).rate": "23.10%",
        "E92000001.ben_combs_wa_tot_li*dr.2021-05-01.Working age Benefit claimants (Benefit combinations).rate": "23.49%",
        "E12000008.ben_combs_wa_tot_li*dr.2021-05-01.Working age Benefit claimants (Benefit combinations).rate": "18.89%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2021-08-01.Working age Benefit claimants (Benefit combinations).rate": "22.41%",
        "E92000001.ben_combs_wa_tot_li*dr.2021-08-01.Working age Benefit claimants (Benefit combinations).rate": "22.96%",
        "E12000008.ben_combs_wa_tot_li*dr.2021-08-01.Working age Benefit claimants (Benefit combinations).rate": "18.37%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2021-11-01.Working age Benefit claimants (Benefit combinations).rate": "21.42%",
        "E92000001.ben_combs_wa_tot_li*dr.2021-11-01.Working age Benefit claimants (Benefit combinations).rate": "22.35%",
        "E12000008.ben_combs_wa_tot_li*dr.2021-11-01.Working age Benefit claimants (Benefit combinations).rate": "17.81%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2022-02-01.Working age Benefit claimants (Benefit combinations).rate": "20.67%",
        "E92000001.ben_combs_wa_tot_li*dr.2022-02-01.Working age Benefit claimants (Benefit combinations).rate": "21.77%",
        "E12000008.ben_combs_wa_tot_li*dr.2022-02-01.Working age Benefit claimants (Benefit combinations).rate": "17.36%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2022-05-01.Working age Benefit claimants (Benefit combinations).rate": "20.36%",
        "E92000001.ben_combs_wa_tot_li*dr.2022-05-01.Working age Benefit claimants (Benefit combinations).rate": "21.57%",
        "E12000008.ben_combs_wa_tot_li*dr.2022-05-01.Working age Benefit claimants (Benefit combinations).rate": "17.18%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2022-08-01.Working age Benefit claimants (Benefit combinations).rate": "20.39%",
        "E92000001.ben_combs_wa_tot_li*dr.2022-08-01.Working age Benefit claimants (Benefit combinations).rate": "21.70%",
        "E12000008.ben_combs_wa_tot_li*dr.2022-08-01.Working age Benefit claimants (Benefit combinations).rate": "17.33%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2022-11-01.Working age Benefit claimants (Benefit combinations).rate": "20.34%",
        "E92000001.ben_combs_wa_tot_li*dr.2022-11-01.Working age Benefit claimants (Benefit combinations).rate": "21.80%",
        "E12000008.ben_combs_wa_tot_li*dr.2022-11-01.Working age Benefit claimants (Benefit combinations).rate": "17.44%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2023-02-01.Working age Benefit claimants (Benefit combinations).rate": "20.20%",
        "E92000001.ben_combs_wa_tot_li*dr.2023-02-01.Working age Benefit claimants (Benefit combinations).rate": "21.89%",
        "E12000008.ben_combs_wa_tot_li*dr.2023-02-01.Working age Benefit claimants (Benefit combinations).rate": "17.51%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2023-05-01.Working age Benefit claimants (Benefit combinations).rate": "20.24%",
        "E92000001.ben_combs_wa_tot_li*dr.2023-05-01.Working age Benefit claimants (Benefit combinations).rate": "21.99%",
        "E12000008.ben_combs_wa_tot_li*dr.2023-05-01.Working age Benefit claimants (Benefit combinations).rate": "17.59%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2023-08-01.DWP Benefit claimants (aged 16 - 64).rate": "20.32%",
        "E92000001.ben_combs_wa_tot_li*dr.2023-08-01.DWP Benefit claimants (aged 16 - 64).rate": "22.19%",
        "E12000008.ben_combs_wa_tot_li*dr.2023-08-01.DWP Benefit claimants (aged 16 - 64).rate": "17.75%",
        "CSTM5160.ben_combs_wa_tot_li*dr.2023-11-01.DWP Benefit claimants (aged 16 - 64).rate": "20.36%",
        "E92000001.ben_combs_wa_tot_li*dr.2023-11-01.DWP Benefit claimants (aged 16 - 64).rate": "22.51%",
        "E12000008.ben_combs_wa_tot_li*dr.2023-11-01.DWP Benefit claimants (aged 16 - 64).rate": "17.98%",
        "CSTM5160.uc_tot_small*dr.2024-09-01.Universal Credit.rate": "14.87%",
        "E92000001.uc_tot_small*dr.2024-09-01.Universal Credit.rate": "17.29%",
        "E12000008.uc_tot_small*dr.2024-09-01.Universal Credit.rate": "13.60%",
        "CSTM5160.uc_no_work_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: No work requirements.rate": "5.44%",
        "E92000001.uc_no_work_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: No work requirements.rate": "6.67%",
        "E12000008.uc_no_work_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: No work requirements.rate": "5.04%",
        "CSTM5160.uc_plan_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Planning for work.rate": "0.14%",
        "E92000001.uc_plan_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Planning for work.rate": "0.27%",
        "E12000008.uc_plan_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Planning for work.rate": "0.23%",
        "CSTM5160.uc_prep_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Preparing for work.rate": "0.91%",
        "E92000001.uc_prep_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Preparing for work.rate": "0.88%",
        "E12000008.uc_prep_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Preparing for work.rate": "0.72%",
        "CSTM5160.uc_search_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Searching for work.rate": "4.09%",
        "E92000001.uc_search_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Searching for work.rate": "4.15%",
        "E12000008.uc_search_for_work*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Searching for work.rate": "3.04%",
        "CSTM5160.uc_work_with_no_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working No requirements.rate": "2.55%",
        "E92000001.uc_work_with_no_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working No requirements.rate": "3.10%",
        "E12000008.uc_work_with_no_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working No requirements.rate": "2.87%",
        "CSTM5160.uc_work_with_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working with requirements.rate": "1.74%",
        "E92000001.uc_work_with_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working with requirements.rate": "2.22%",
        "E12000008.uc_work_with_req*dr.2024-09-01.Universal Credit claimants - Conditionality Regime: Working with requirements.rate": "1.70%",
        "CSTM5160.uc_total_households*dr.2024-05-01.Total households on Universal Credit.rate": "19.72%",
        "E92000001.uc_total_households*dr.2024-05-01.Total households on Universal Credit.rate": "20.83%",
        "E12000008.uc_total_households*dr.2024-05-01.Total households on Universal Credit.rate": "16.25%",
        "CSTM5160.uc_single_dep_child*dr.2024-05-01.Households on Universal Credit, Family type, single with dependent children.rate": "4.84%",
        "E92000001.uc_single_dep_child*dr.2024-05-01.Households on Universal Credit, Family type, single with dependent children.rate": "7.27%",
        "E12000008.uc_single_dep_child*dr.2024-05-01.Households on Universal Credit, Family type, single with dependent children.rate": "5.81%",
        "CSTM5160.uc_carer_ent*dr.2024-05-01.Households on Universal Credit, Carer Entitlement.rate": "1.98%",
        "E92000001.uc_carer_ent*dr.2024-05-01.Households on Universal Credit, Carer Entitlement.rate": "2.70%",
        "E12000008.uc_carer_ent*dr.2024-05-01.Households on Universal Credit, Carer Entitlement.rate": "2.16%",
        "CSTM5160.uc_child_ent*dr.2024-05-01.Households on Universal Credit, Child Entitlement.rate": "6.31%",
        "E92000001.uc_child_ent*dr.2024-05-01.Households on Universal Credit, Child Entitlement.rate": "9.83%",
        "E12000008.uc_child_ent*dr.2024-05-01.Households on Universal Credit, Child Entitlement.rate": "7.98%",
        "CSTM5160.uc_disab_child_ent*dr.2024-02-01.Households on Universal Credit, Disabled Child Entitlement.rate": "0.83%",
        "E92000001.uc_disab_child_ent*dr.2024-02-01.Households on Universal Credit, Disabled Child Entitlement.rate": "1.24%",
        "E12000008.uc_disab_child_ent*dr.2024-02-01.Households on Universal Credit, Disabled Child Entitlement.rate": "1.16%",
        "CSTM5160.uc_lcw*dr.2024-02-01.Households on Universal Credit - Limited Capability for Work Entitlement.rate": "4.90%",
        "E92000001.uc_lcw*dr.2024-02-01.Households on Universal Credit - Limited Capability for Work Entitlement.rate": "5.05%",
        "E12000008.uc_lcw*dr.2024-02-01.Households on Universal Credit - Limited Capability for Work Entitlement.rate": "3.64%",
        "CSTM5160.uc_housing_ent_soc*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Social rented.rate": "4.56%",
        "E92000001.uc_housing_ent_soc*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Social rented.rate": "6.62%",
        "E12000008.uc_housing_ent_soc*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Social rented.rate": "5.26%",
        "CSTM5160.uc_housing_ent_priv*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Private rented.rate": "8.16%",
        "E92000001.uc_housing_ent_priv*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Private rented.rate": "5.93%",
        "E12000008.uc_housing_ent_priv*dr.2024-05-01.Households on Universal Credit, Housing Entitlement, Private rented.rate": "4.91%",
        "CSTM5160.uc_housing_ent_tot*dr.2024-05-01.Households on Universal Credit with Housing Entitlement.rate": "13.13%",
        "E92000001.uc_housing_ent_tot*dr.2024-05-01.Households on Universal Credit with Housing Entitlement.rate": "12.91%",
        "E12000008.uc_housing_ent_tot*dr.2024-05-01.Households on Universal Credit with Housing Entitlement.rate": "10.53%",
        "CSTM5160.hb_tot*dr.2020-01-01.Housing Benefit.rate": "14.42%",
        "E92000001.hb_tot*dr.2020-01-01.Housing Benefit.rate": "11.80%",
        "E12000008.hb_tot*dr.2020-01-01.Housing Benefit.rate": "9.42%",
        "CSTM5160.hb_tot*dr.2020-02-01.Housing Benefit.rate": "14.27%",
        "E92000001.hb_tot*dr.2020-02-01.Housing Benefit.rate": "11.66%",
        "E12000008.hb_tot*dr.2020-02-01.Housing Benefit.rate": "9.29%",
        "CSTM5160.hb_tot*dr.2020-03-01.Housing Benefit.rate": "14.20%",
        "E92000001.hb_tot*dr.2020-03-01.Housing Benefit.rate": "11.53%",
        "E12000008.hb_tot*dr.2020-03-01.Housing Benefit.rate": "9.19%",
        "CSTM5160.hb_tot*dr.2020-04-01.Housing Benefit.rate": "14.09%",
        "E92000001.hb_tot*dr.2020-04-01.Housing Benefit.rate": "11.43%",
        "E12000008.hb_tot*dr.2020-04-01.Housing Benefit.rate": "9.10%",
        "CSTM5160.hb_tot*dr.2020-05-01.Housing Benefit.rate": "13.95%",
        "E92000001.hb_tot*dr.2020-05-01.Housing Benefit.rate": "11.27%",
        "E12000008.hb_tot*dr.2020-05-01.Housing Benefit.rate": "8.99%",
        "CSTM5160.hb_tot*dr.2020-06-01.Housing Benefit.rate": "13.93%",
        "E92000001.hb_tot*dr.2020-06-01.Housing Benefit.rate": "11.19%",
        "E12000008.hb_tot*dr.2020-06-01.Housing Benefit.rate": "8.92%",
        "CSTM5160.hb_tot*dr.2020-07-01.Housing Benefit.rate": "13.86%",
        "E92000001.hb_tot*dr.2020-07-01.Housing Benefit.rate": "11.12%",
        "E12000008.hb_tot*dr.2020-07-01.Housing Benefit.rate": "8.86%",
        "CSTM5160.hb_tot*dr.2020-08-01.Housing Benefit.rate": "13.77%",
        "E92000001.hb_tot*dr.2020-08-01.Housing Benefit.rate": "11.02%",
        "E12000008.hb_tot*dr.2020-08-01.Housing Benefit.rate": "8.77%",
        "CSTM5160.hb_tot*dr.2020-09-01.Housing Benefit.rate": "13.70%",
        "E92000001.hb_tot*dr.2020-09-01.Housing Benefit.rate": "10.94%",
        "E12000008.hb_tot*dr.2020-09-01.Housing Benefit.rate": "8.70%",
        "CSTM5160.hb_tot*dr.2020-10-01.Housing Benefit.rate": "13.60%",
        "E92000001.hb_tot*dr.2020-10-01.Housing Benefit.rate": "10.85%",
        "E12000008.hb_tot*dr.2020-10-01.Housing Benefit.rate": "8.63%",
        "CSTM5160.hb_tot*dr.2020-11-01.Housing Benefit.rate": "13.47%",
        "E92000001.hb_tot*dr.2020-11-01.Housing Benefit.rate": "10.73%",
        "E12000008.hb_tot*dr.2020-11-01.Housing Benefit.rate": "8.54%",
        "CSTM5160.hb_tot*dr.2020-12-01.Housing Benefit.rate": "13.42%",
        "E92000001.hb_tot*dr.2020-12-01.Housing Benefit.rate": "10.65%",
        "E12000008.hb_tot*dr.2020-12-01.Housing Benefit.rate": "8.47%",
        "CSTM5160.hb_tot*dr.2021-01-01.Housing Benefit.rate": "13.39%",
        "E92000001.hb_tot*dr.2021-01-01.Housing Benefit.rate": "10.58%",
        "E12000008.hb_tot*dr.2021-01-01.Housing Benefit.rate": "8.42%",
        "CSTM5160.hb_tot*dr.2021-02-01.Housing Benefit.rate": "13.29%",
        "E92000001.hb_tot*dr.2021-02-01.Housing Benefit.rate": "10.51%",
        "E12000008.hb_tot*dr.2021-02-01.Housing Benefit.rate": "8.35%",
        "CSTM5160.hb_tot*dr.2021-03-01.Housing Benefit.rate": "13.27%",
        "E92000001.hb_tot*dr.2021-03-01.Housing Benefit.rate": "10.41%",
        "E12000008.hb_tot*dr.2021-03-01.Housing Benefit.rate": "8.27%",
        "CSTM5160.hb_tot*dr.2021-04-01.Housing Benefit.rate": "13.18%",
        "E92000001.hb_tot*dr.2021-04-01.Housing Benefit.rate": "10.34%",
        "E12000008.hb_tot*dr.2021-04-01.Housing Benefit.rate": "8.22%",
        "CSTM5160.hb_tot*dr.2021-05-01.Housing Benefit.rate": "13.04%",
        "E92000001.hb_tot*dr.2021-05-01.Housing Benefit.rate": "10.24%",
        "E12000008.hb_tot*dr.2021-05-01.Housing Benefit.rate": "8.12%",
        "CSTM5160.hb_tot*dr.2021-06-01.Housing Benefit.rate": null,
        "E92000001.hb_tot*dr.2021-06-01.Housing Benefit.rate": null,
        "E12000008.hb_tot*dr.2021-06-01.Housing Benefit.rate": null,
        "CSTM5160.hb_tot*dr.2021-07-01.Housing Benefit.rate": null,
        "E92000001.hb_tot*dr.2021-07-01.Housing Benefit.rate": null,
        "E12000008.hb_tot*dr.2021-07-01.Housing Benefit.rate": null,
        "CSTM5160.hb_tot*dr.2021-08-01.Housing Benefit.rate": "12.83%",
        "E92000001.hb_tot*dr.2021-08-01.Housing Benefit.rate": "10.00%",
        "E12000008.hb_tot*dr.2021-08-01.Housing Benefit.rate": "7.93%",
        "CSTM5160.hb_tot*dr.2021-09-01.Housing Benefit.rate": "12.73%",
        "E92000001.hb_tot*dr.2021-09-01.Housing Benefit.rate": "9.93%",
        "E12000008.hb_tot*dr.2021-09-01.Housing Benefit.rate": "7.87%",
        "CSTM5160.hb_tot*dr.2021-10-01.Housing Benefit.rate": "12.60%",
        "E92000001.hb_tot*dr.2021-10-01.Housing Benefit.rate": "9.81%",
        "E12000008.hb_tot*dr.2021-10-01.Housing Benefit.rate": "7.77%",
        "CSTM5160.hb_tot*dr.2021-11-01.Housing Benefit.rate": "12.53%",
        "E92000001.hb_tot*dr.2021-11-01.Housing Benefit.rate": "9.73%",
        "E12000008.hb_tot*dr.2021-11-01.Housing Benefit.rate": "7.70%",
        "CSTM5160.hb_tot*dr.2021-12-01.Housing Benefit.rate": "12.49%",
        "E92000001.hb_tot*dr.2021-12-01.Housing Benefit.rate": "9.66%",
        "E12000008.hb_tot*dr.2021-12-01.Housing Benefit.rate": "7.65%",
        "CSTM5160.hb_tot*dr.2022-01-01.Housing Benefit.rate": "12.44%",
        "E92000001.hb_tot*dr.2022-01-01.Housing Benefit.rate": "9.60%",
        "E12000008.hb_tot*dr.2022-01-01.Housing Benefit.rate": "7.60%",
        "CSTM5160.hb_tot*dr.2022-02-01.Housing Benefit.rate": "12.39%",
        "E92000001.hb_tot*dr.2022-02-01.Housing Benefit.rate": "9.53%",
        "E12000008.hb_tot*dr.2022-02-01.Housing Benefit.rate": "7.56%",
        "CSTM5160.hb_tot*dr.2022-03-01.Housing Benefit.rate": "12.35%",
        "E92000001.hb_tot*dr.2022-03-01.Housing Benefit.rate": "9.46%",
        "E12000008.hb_tot*dr.2022-03-01.Housing Benefit.rate": "7.49%",
        "CSTM5160.hb_tot*dr.2022-04-01.Housing Benefit.rate": "12.20%",
        "E92000001.hb_tot*dr.2022-04-01.Housing Benefit.rate": "9.36%",
        "E12000008.hb_tot*dr.2022-04-01.Housing Benefit.rate": "7.42%",
        "CSTM5160.hb_tot*dr.2022-05-01.Housing Benefit.rate": "12.16%",
        "E92000001.hb_tot*dr.2022-05-01.Housing Benefit.rate": "9.33%",
        "E12000008.hb_tot*dr.2022-05-01.Housing Benefit.rate": "7.39%",
        "CSTM5160.hb_tot*dr.2022-06-01.Housing Benefit.rate": "12.11%",
        "E92000001.hb_tot*dr.2022-06-01.Housing Benefit.rate": "9.30%",
        "E12000008.hb_tot*dr.2022-06-01.Housing Benefit.rate": "7.37%",
        "CSTM5160.hb_tot*dr.2022-07-01.Housing Benefit.rate": "12.02%",
        "E92000001.hb_tot*dr.2022-07-01.Housing Benefit.rate": "9.21%",
        "E12000008.hb_tot*dr.2022-07-01.Housing Benefit.rate": "7.29%",
        "CSTM5160.hb_tot*dr.2022-08-01.Housing Benefit.rate": "11.93%",
        "E92000001.hb_tot*dr.2022-08-01.Housing Benefit.rate": "9.15%",
        "E12000008.hb_tot*dr.2022-08-01.Housing Benefit.rate": "7.24%",
        "CSTM5160.hb_tot*dr.2022-09-01.Housing Benefit.rate": "11.87%",
        "E92000001.hb_tot*dr.2022-09-01.Housing Benefit.rate": "9.10%",
        "E12000008.hb_tot*dr.2022-09-01.Housing Benefit.rate": "7.20%",
        "CSTM5160.hb_tot*dr.2022-10-01.Housing Benefit.rate": "11.70%",
        "E92000001.hb_tot*dr.2022-10-01.Housing Benefit.rate": "9.01%",
        "E12000008.hb_tot*dr.2022-10-01.Housing Benefit.rate": "7.12%",
        "CSTM5160.hb_tot*dr.2022-11-01.Housing Benefit.rate": "11.66%",
        "E92000001.hb_tot*dr.2022-11-01.Housing Benefit.rate": "8.96%",
        "E12000008.hb_tot*dr.2022-11-01.Housing Benefit.rate": "7.07%",
        "CSTM5160.hb_tot*dr.2022-12-01.Housing Benefit.rate": "11.61%",
        "E92000001.hb_tot*dr.2022-12-01.Housing Benefit.rate": "8.91%",
        "E12000008.hb_tot*dr.2022-12-01.Housing Benefit.rate": "7.04%",
        "CSTM5160.hb_tot*dr.2023-01-01.Housing Benefit.rate": "11.56%",
        "E92000001.hb_tot*dr.2023-01-01.Housing Benefit.rate": "8.86%",
        "E12000008.hb_tot*dr.2023-01-01.Housing Benefit.rate": "7.00%",
        "CSTM5160.hb_tot*dr.2023-02-01.Housing Benefit.rate": "11.48%",
        "E92000001.hb_tot*dr.2023-02-01.Housing Benefit.rate": "8.81%",
        "E12000008.hb_tot*dr.2023-02-01.Housing Benefit.rate": "6.95%",
        "CSTM5160.hb_tot*dr.2023-03-01.Housing Benefit.rate": null,
        "E92000001.hb_tot*dr.2023-03-01.Housing Benefit.rate": null,
        "E12000008.hb_tot*dr.2023-03-01.Housing Benefit.rate": null,
        "CSTM5160.hb_tot*dr.2023-04-01.Housing Benefit.rate": null,
        "E92000001.hb_tot*dr.2023-04-01.Housing Benefit.rate": null,
        "E12000008.hb_tot*dr.2023-04-01.Housing Benefit.rate": null,
        "CSTM5160.hb_tot*dr.2023-05-01.Housing Benefit.rate": "11.35%",
        "E92000001.hb_tot*dr.2023-05-01.Housing Benefit.rate": "8.67%",
        "E12000008.hb_tot*dr.2023-05-01.Housing Benefit.rate": "6.84%",
        "CSTM5160.hb_tot*dr.2023-06-01.Housing Benefit.rate": "11.33%",
        "E92000001.hb_tot*dr.2023-06-01.Housing Benefit.rate": "8.65%",
        "E12000008.hb_tot*dr.2023-06-01.Housing Benefit.rate": "6.84%",
        "CSTM5160.hb_tot*dr.2023-07-01.Housing Benefit.rate": "11.25%",
        "E92000001.hb_tot*dr.2023-07-01.Housing Benefit.rate": "8.59%",
        "E12000008.hb_tot*dr.2023-07-01.Housing Benefit.rate": "6.78%",
        "CSTM5160.hb_tot*dr.2023-08-01.Housing Benefit.rate": "11.18%",
        "E92000001.hb_tot*dr.2023-08-01.Housing Benefit.rate": "8.56%",
        "E12000008.hb_tot*dr.2023-08-01.Housing Benefit.rate": "6.75%",
        "CSTM5160.hb_tot*dr.2023-09-01.Housing Benefit.rate": "11.13%",
        "E92000001.hb_tot*dr.2023-09-01.Housing Benefit.rate": "8.49%",
        "E12000008.hb_tot*dr.2023-09-01.Housing Benefit.rate": "6.70%",
        "CSTM5160.hb_tot*dr.2023-10-01.Housing Benefit.rate": "11.10%",
        "E92000001.hb_tot*dr.2023-10-01.Housing Benefit.rate": "8.45%",
        "E12000008.hb_tot*dr.2023-10-01.Housing Benefit.rate": "6.67%",
        "CSTM5160.hb_tot*dr.2023-11-01.Housing Benefit.rate": "11.08%",
        "E92000001.hb_tot*dr.2023-11-01.Housing Benefit.rate": "8.42%",
        "E12000008.hb_tot*dr.2023-11-01.Housing Benefit.rate": "6.64%",
        "CSTM5160.hb_tot*dr.2023-12-01.Housing Benefit.rate": "11.01%",
        "E92000001.hb_tot*dr.2023-12-01.Housing Benefit.rate": "8.26%",
        "E12000008.hb_tot*dr.2023-12-01.Housing Benefit.rate": "6.48%",
        "CSTM5160.hb_tot*dr.2024-01-01.Housing Benefit.rate": "10.97%",
        "E92000001.hb_tot*dr.2024-01-01.Housing Benefit.rate": "8.25%",
        "E12000008.hb_tot*dr.2024-01-01.Housing Benefit.rate": "6.47%",
        "CSTM5160.hb_tot*dr.2024-02-01.Housing Benefit.rate": "11.04%",
        "E92000001.hb_tot*dr.2024-02-01.Housing Benefit.rate": "8.22%",
        "E12000008.hb_tot*dr.2024-02-01.Housing Benefit.rate": "6.46%",
        "CSTM5160.hb_tot*dr.2024-03-01.Housing Benefit.rate": "10.91%",
        "E92000001.hb_tot*dr.2024-03-01.Housing Benefit.rate": "8.23%",
        "E12000008.hb_tot*dr.2024-03-01.Housing Benefit.rate": "6.51%",
        "CSTM5160.hb_tot*dr.2024-04-01.Housing Benefit.rate": "10.83%",
        "E92000001.hb_tot*dr.2024-04-01.Housing Benefit.rate": "8.21%",
        "E12000008.hb_tot*dr.2024-04-01.Housing Benefit.rate": "6.49%",
        "CSTM5160.hb_tot*dr.2024-05-01.Housing Benefit.rate": "10.78%",
        "E92000001.hb_tot*dr.2024-05-01.Housing Benefit.rate": "8.19%",
        "E12000008.hb_tot*dr.2024-05-01.Housing Benefit.rate": "6.47%",
        "CSTM5160.ks601ew0002*dr.2021-01-01.Part-time employees (Census 2021).rate": "11.57%",
        "E92000001.ks601ew0002*dr.2021-01-01.Part-time employees (Census 2021).rate": "11.90%",
        "E12000008.ks601ew0002*dr.2021-01-01.Part-time employees (Census 2021).rate": "11.80%",
        "CSTM5160.ks601ew0003*dr.2021-01-01.Full-time employees (Census 2021).rate": "31.63%",
        "E92000001.ks601ew0003*dr.2021-01-01.Full-time employees (Census 2021).rate": "34.27%",
        "E12000008.ks601ew0003*dr.2021-01-01.Full-time employees (Census 2021).rate": "35.21%",
        "CSTM5160.ks601ew0004*dr.2021-01-01.Self-employed people (Census 2021).rate": "13.04%",
        "E92000001.ks601ew0004*dr.2021-01-01.Self-employed people (Census 2021).rate": "9.54%",
        "E12000008.ks601ew0004*dr.2021-01-01.Self-employed people (Census 2021).rate": "10.62%",
        "CSTM5160.ks601ew0005*dr.2021-01-01.Economically active: Unemployed (Census 2021).rate": "3.12%",
        "E92000001.ks601ew0005*dr.2021-01-01.Economically active: Unemployed (Census 2021).rate": "2.85%",
        "E12000008.ks601ew0005*dr.2021-01-01.Economically active: Unemployed (Census 2021).rate": "2.50%",
        "CSTM5160.ks601ew0006*dr.2021-01-01.Economically active: Full-time student (Census 2021).rate": "4.42%",
        "E92000001.ks601ew0006*dr.2021-01-01.Economically active: Full-time student (Census 2021).rate": "2.30%",
        "E12000008.ks601ew0006*dr.2021-01-01.Economically active: Full-time student (Census 2021).rate": "2.10%",
        "CSTM5160.ks601ew0007*dr.2021-01-01.Economically inactive: Retired (Census 2021).rate": "15.29%",
        "E92000001.ks601ew0007*dr.2021-01-01.Economically inactive: Retired (Census 2021).rate": "21.48%",
        "E12000008.ks601ew0007*dr.2021-01-01.Economically inactive: Retired (Census 2021).rate": "22.48%",
        "CSTM5160.ks601ew0008*dr.2021-01-01.Economically inactive: Student (including full-time students) (Census 2021).rate": "9.86%",
        "E92000001.ks601ew0008*dr.2021-01-01.Economically inactive: Student (including full-time students) (Census 2021).rate": "5.64%",
        "E12000008.ks601ew0008*dr.2021-01-01.Economically inactive: Student (including full-time students) (Census 2021).rate": "5.10%",
        "CSTM5160.ks601ew0009*dr.2021-01-01.Economically inactive: Looking after home or family (Census 2021).rate": "3.60%",
        "E92000001.ks601ew0009*dr.2021-01-01.Economically inactive: Looking after home or family (Census 2021).rate": "4.80%",
        "E12000008.ks601ew0009*dr.2021-01-01.Economically inactive: Looking after home or family (Census 2021).rate": "4.42%",
        "CSTM5160.ks601ew0010*dr.2021-01-01.Economically inactive: Long-term sick or disabled (Census 2021).rate": "4.42%",
        "E92000001.ks601ew0010*dr.2021-01-01.Economically inactive: Long-term sick or disabled (Census 2021).rate": "4.07%",
        "E12000008.ks601ew0010*dr.2021-01-01.Economically inactive: Long-term sick or disabled (Census 2021).rate": "3.11%",
        "CSTM5160.ks601ew0011*dr.2021-01-01.Economically inactive: Other (Census 2021).rate": "3.05%",
        "E92000001.ks601ew0011*dr.2021-01-01.Economically inactive: Other (Census 2021).rate": "3.14%",
        "E12000008.ks601ew0011*dr.2021-01-01.Economically inactive: Other (Census 2021).rate": "2.67%",
        "CSTM5160.ks604ew0002*dr.2021-01-01.Part-time: 15 hours or less worked (Census 2021).rate": "13.23%",
        "E92000001.ks604ew0002*dr.2021-01-01.Part-time: 15 hours or less worked (Census 2021).rate": "10.34%",
        "E12000008.ks604ew0002*dr.2021-01-01.Part-time: 15 hours or less worked (Census 2021).rate": "10.98%",
        "CSTM5160.ks604ew0003*dr.2021-01-01.Part-time: 16 to 30 hours worked (Census 2021).rate": "20.70%",
        "E92000001.ks604ew0003*dr.2021-01-01.Part-time: 16 to 30 hours worked (Census 2021).rate": "19.47%",
        "E12000008.ks604ew0003*dr.2021-01-01.Part-time: 16 to 30 hours worked (Census 2021).rate": "18.56%",
        "CSTM5160.ks604ew0004*dr.2021-01-01.Full-time: 31 to 48 hours worked (Census 2021).rate": "56.77%",
        "E92000001.ks604ew0004*dr.2021-01-01.Full-time: 31 to 48 hours worked (Census 2021).rate": "59.11%",
        "E12000008.ks604ew0004*dr.2021-01-01.Full-time: 31 to 48 hours worked (Census 2021).rate": "58.56%",
        "CSTM5160.ks604ew0005*dr.2021-01-01.Full-time: 49 or more hours worked (Census 2021).rate": "9.30%",
        "E92000001.ks604ew0005*dr.2021-01-01.Full-time: 49 or more hours worked (Census 2021).rate": "11.08%",
        "E12000008.ks604ew0005*dr.2021-01-01.Full-time: 49 or more hours worked (Census 2021).rate": "11.91%",
        "CSTM5160.ks608ew0002*dr.2021-01-01.Occupation group: Managers, directors and senior officials (Census 2021).rate": "14.14%",
        "E92000001.ks608ew0002*dr.2021-01-01.Occupation group: Managers, directors and senior officials (Census 2021).rate": "12.89%",
        "E12000008.ks608ew0002*dr.2021-01-01.Occupation group: Managers, directors and senior officials (Census 2021).rate": "14.88%",
        "CSTM5160.ks608ew0003*dr.2021-01-01.Occupation group: Professional occupations (Census 2021).rate": "26.19%",
        "E92000001.ks608ew0003*dr.2021-01-01.Occupation group: Professional occupations (Census 2021).rate": "20.29%",
        "E12000008.ks608ew0003*dr.2021-01-01.Occupation group: Professional occupations (Census 2021).rate": "21.22%",
        "CSTM5160.ks608ew0004*dr.2021-01-01.Occupation group: Associate professional and technical occupations (Census 2021).rate": "16.96%",
        "E92000001.ks608ew0004*dr.2021-01-01.Occupation group: Associate professional and technical occupations (Census 2021).rate": "13.25%",
        "E12000008.ks608ew0004*dr.2021-01-01.Occupation group: Associate professional and technical occupations (Census 2021).rate": "14.26%",
        "CSTM5160.ks608ew0005*dr.2021-01-01.Occupation group: Administrative and secretarial occupations (Census 2021).rate": "7.43%",
        "E92000001.ks608ew0005*dr.2021-01-01.Occupation group: Administrative and secretarial occupations (Census 2021).rate": "9.27%",
        "E12000008.ks608ew0005*dr.2021-01-01.Occupation group: Administrative and secretarial occupations (Census 2021).rate": "9.35%",
        "CSTM5160.ks608ew0006*dr.2021-01-01.Occupation group: Skilled trades occupations (Census 2021).rate": "7.72%",
        "E92000001.ks608ew0006*dr.2021-01-01.Occupation group: Skilled trades occupations (Census 2021).rate": "10.16%",
        "E12000008.ks608ew0006*dr.2021-01-01.Occupation group: Skilled trades occupations (Census 2021).rate": "10.00%",
        "CSTM5160.ks608ew0007*dr.2021-01-01.Occupation group: Caring, leisure and other service occupations (Census 2021).rate": "8.67%",
        "E92000001.ks608ew0007*dr.2021-01-01.Occupation group: Caring, leisure and other service occupations (Census 2021).rate": "9.27%",
        "E12000008.ks608ew0007*dr.2021-01-01.Occupation group: Caring, leisure and other service occupations (Census 2021).rate": "9.10%",
        "CSTM5160.ks608ew0008*dr.2021-01-01.Occupation group: Sales and customer service occupations (Census 2021).rate": "7.28%",
        "E92000001.ks608ew0008*dr.2021-01-01.Occupation group: Sales and customer service occupations (Census 2021).rate": "7.47%",
        "E12000008.ks608ew0008*dr.2021-01-01.Occupation group: Sales and customer service occupations (Census 2021).rate": "6.82%",
        "CSTM5160.ks608ew0009*dr.2021-01-01.Occupation group: Process, plant and machine operatives (Census 2021).rate": "3.69%",
        "E92000001.ks608ew0009*dr.2021-01-01.Occupation group: Process, plant and machine operatives (Census 2021).rate": "6.94%",
        "E12000008.ks608ew0009*dr.2021-01-01.Occupation group: Process, plant and machine operatives (Census 2021).rate": "5.55%",
        "CSTM5160.ks608ew0010*dr.2021-01-01.Occupation group: Elementary occupations (Census 2021).rate": "7.93%",
        "E92000001.ks608ew0010*dr.2021-01-01.Occupation group: Elementary occupations (Census 2021).rate": "10.46%",
        "E12000008.ks608ew0010*dr.2021-01-01.Occupation group: Elementary occupations (Census 2021).rate": "8.81%",
        "CSTM5160.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021).rate": "12.41%",
        "E92000001.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021).rate": "18.08%",
        "E12000008.ks501ew0002*dr.2021-01-01.People with no qualifications (Census 2021).rate": "15.38%",
        "CSTM5160.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021).rate": "7.03%",
        "E92000001.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021).rate": "9.69%",
        "E12000008.ks501ew0003*dr.2021-01-01.Highest level of qualification: Level 1 qualifications (Census 2021).rate": "9.78%",
        "CSTM5160.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021).rate": "10.65%",
        "E92000001.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021).rate": "13.32%",
        "E12000008.ks501ew0004*dr.2021-01-01.Highest level of qualification: Level 2 qualifications (Census 2021).rate": "13.86%",
        "CSTM5160.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021).rate": "3.42%",
        "E92000001.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021).rate": "5.32%",
        "E12000008.ks501ew0005*dr.2021-01-01.Highest level of qualification: Apprenticeship (Census 2021).rate": "5.12%",
        "CSTM5160.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021).rate": "19.70%",
        "E92000001.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021).rate": "16.92%",
        "E12000008.ks501ew0006*dr.2021-01-01.Highest level of qualification: Level 3 qualifications (Census 2021).rate": "17.39%",
        "CSTM5160.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021).rate": "44.52%",
        "E92000001.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021).rate": "33.92%",
        "E12000008.ks501ew0007*dr.2021-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021).rate": "35.77%",
        "CSTM5160.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021).rate": "2.27%",
        "E92000001.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021).rate": "2.76%",
        "E12000008.ks501ew0008*dr.2021-01-01.Highest level of qualification: Other qualifications (Census 2021).rate": "2.70%",
        "CSTM5160.ks501ew0002*dr.2011-01-01.People with no qualifications (E&W Census 2011).rate": "16.06%",
        "E92000001.ks501ew0002*dr.2011-01-01.People with no qualifications (E&W Census 2011).rate": "22.46%",
        "E12000008.ks501ew0002*dr.2011-01-01.People with no qualifications (E&W Census 2011).rate": "19.08%",
        "CSTM5160.ks501ew0007*dr.2011-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications  (E&W Census 2011).rate": "36.86%",
        "E92000001.ks501ew0007*dr.2011-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications  (E&W Census 2011).rate": "27.38%",
        "E12000008.ks501ew0007*dr.2011-01-01.Highest level of qualification: Level 4/5 (degree or higher) qualifications  (E&W Census 2011).rate": "29.94%",
        "CSTM5160.polar_tundra_participation_num*dr.2019-09-01.Participation of state school pupils in higher education.rate": "35.95%",
        "E92000001.polar_tundra_participation_num*dr.2019-09-01.Participation of state school pupils in higher education.rate": "40.32%",
        "E12000008.polar_tundra_participation_num*dr.2019-09-01.Participation of state school pupils in higher education.rate": "39.53%",
        "CSTM5160.id19_edupop_dec1*dr.2019-01-01.IoD 2019 Education Decile 1.rate": "8.80%",
        "E92000001.id19_edupop_dec1*dr.2019-01-01.IoD 2019 Education Decile 1.rate": "9.86%",
        "E12000008.id19_edupop_dec1*dr.2019-01-01.IoD 2019 Education Decile 1.rate": "6.18%",
        "CSTM5160.id19_edupop_dec2*dr.2019-01-01.IoD 2019 Education Decile 2.rate": "7.86%",
        "E92000001.id19_edupop_dec2*dr.2019-01-01.IoD 2019 Education Decile 2.rate": "9.98%",
        "E12000008.id19_edupop_dec2*dr.2019-01-01.IoD 2019 Education Decile 2.rate": "7.74%",
        "CSTM5160.id19_edupop_dec3*dr.2019-01-01.IoD 2019 Education Decile 3.rate": "4.35%",
        "E92000001.id19_edupop_dec3*dr.2019-01-01.IoD 2019 Education Decile 3.rate": "10.04%",
        "E12000008.id19_edupop_dec3*dr.2019-01-01.IoD 2019 Education Decile 3.rate": "9.50%",
        "CSTM5160.id19_edupop_dec4*dr.2019-01-01.IoD 2019 Education Decile 4.rate": "9.94%",
        "E92000001.id19_edupop_dec4*dr.2019-01-01.IoD 2019 Education Decile 4.rate": "10.04%",
        "E12000008.id19_edupop_dec4*dr.2019-01-01.IoD 2019 Education Decile 4.rate": "9.79%",
        "CSTM5160.id19_edupop_dec5*dr.2019-01-01.IoD 2019 Education Decile 5.rate": "7.93%",
        "E92000001.id19_edupop_dec5*dr.2019-01-01.IoD 2019 Education Decile 5.rate": "10.08%",
        "E12000008.id19_edupop_dec5*dr.2019-01-01.IoD 2019 Education Decile 5.rate": "10.16%",
        "CSTM5160.id19_edupop_dec6*dr.2019-01-01.IoD 2019 Education Decile 6.rate": "6.86%",
        "E92000001.id19_edupop_dec6*dr.2019-01-01.IoD 2019 Education Decile 6.rate": "10.09%",
        "E12000008.id19_edupop_dec6*dr.2019-01-01.IoD 2019 Education Decile 6.rate": "10.84%",
        "CSTM5160.id19_edupop_dec7*dr.2019-01-01.IoD 2019 Education Decile 7.rate": "11.22%",
        "E92000001.id19_edupop_dec7*dr.2019-01-01.IoD 2019 Education Decile 7.rate": "10.08%",
        "E12000008.id19_edupop_dec7*dr.2019-01-01.IoD 2019 Education Decile 7.rate": "11.49%",
        "CSTM5160.id19_edupop_dec8*dr.2019-01-01.IoD 2019 Education Decile 8.rate": "13.37%",
        "E92000001.id19_edupop_dec8*dr.2019-01-01.IoD 2019 Education Decile 8.rate": "9.97%",
        "E12000008.id19_edupop_dec8*dr.2019-01-01.IoD 2019 Education Decile 8.rate": "11.96%",
        "CSTM5160.id19_edupop_dec9*dr.2019-01-01.IoD 2019 Education Decile 9.rate": "15.87%",
        "E92000001.id19_edupop_dec9*dr.2019-01-01.IoD 2019 Education Decile 9.rate": "9.93%",
        "E12000008.id19_edupop_dec9*dr.2019-01-01.IoD 2019 Education Decile 9.rate": "13.41%",
        "CSTM5160.id19_edupop_dec10*dr.2019-01-01.IoD 2019 Education Decile 10.rate": "12.86%",
        "E92000001.id19_edupop_dec10*dr.2019-01-01.IoD 2019 Education Decile 10.rate": "9.71%",
        "E12000008.id19_edupop_dec10*dr.2019-01-01.IoD 2019 Education Decile 10.rate": "14.09%",
        "CSTM5160.idaci19_num*dr.2015-08-01.IoD 2019 Income Deprivation Affecting Children Index (IDACI) Score (rate).rate": "15.29%",
        "E92000001.idaci19_num*dr.2015-08-01.IoD 2019 Income Deprivation Affecting Children Index (IDACI) Score (rate).rate": "17.08%",
        "E12000008.idaci19_num*dr.2015-08-01.IoD 2019 Income Deprivation Affecting Children Index (IDACI) Score (rate).rate": "12.38%",
        "CSTM5160.child_abso_lif_0_19*dr.2023-01-01.Children aged 0-19 in absolute low-income families.rate": "10.19%",
        "E92000001.child_abso_lif_0_19*dr.2023-01-01.Children aged 0-19 in absolute low-income families.rate": "15.58%",
        "E12000008.child_abso_lif_0_19*dr.2023-01-01.Children aged 0-19 in absolute low-income families.rate": "10.69%",
        "CSTM5160.child_abso_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in absolute low-income out of work families (as % of all in absolute low-income families).rate": "32.27%",
        "E92000001.child_abso_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in absolute low-income out of work families (as % of all in absolute low-income families).rate": "33.64%",
        "E12000008.child_abso_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in absolute low-income out of work families (as % of all in absolute low-income families).rate": "30.56%",
        "CSTM5160.child_abso_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in absolute low-income in-work families (as % of all in absolute low-income families).rate": "67.69%",
        "E92000001.child_abso_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in absolute low-income in-work families (as % of all in absolute low-income families).rate": "66.36%",
        "E12000008.child_abso_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in absolute low-income in-work families (as % of all in absolute low-income families).rate": "69.44%",
        "CSTM5160.child_abso_lif_0_19_lone*dr.2023-01-01.Children 0-19 in absolute low-income lone parent families (as % of all in absolute low-income families).rate": "51.54%",
        "E92000001.child_abso_lif_0_19_lone*dr.2023-01-01.Children 0-19 in absolute low-income lone parent families (as % of all in absolute low-income families).rate": "48.49%",
        "E12000008.child_abso_lif_0_19_lone*dr.2023-01-01.Children 0-19 in absolute low-income lone parent families (as % of all in absolute low-income families).rate": "50.96%",
        "CSTM5160.child_abso_lif_0_19_couple*dr.2023-01-01.Children 0-19 in absolute low-income couple families (as % of all in absolute low-income families).rate": "48.38%",
        "E92000001.child_abso_lif_0_19_couple*dr.2023-01-01.Children 0-19 in absolute low-income couple families (as % of all in absolute low-income families).rate": "51.51%",
        "E12000008.child_abso_lif_0_19_couple*dr.2023-01-01.Children 0-19 in absolute low-income couple families (as % of all in absolute low-income families).rate": "49.04%",
        "CSTM5160.child_r_lif_0_19*dr.2023-01-01.Children aged 0-19 in relative low-income families.rate": "12.41%",
        "E92000001.child_r_lif_0_19*dr.2023-01-01.Children aged 0-19 in relative low-income families.rate": "19.69%",
        "E12000008.child_r_lif_0_19*dr.2023-01-01.Children aged 0-19 in relative low-income families.rate": "13.16%",
        "CSTM5160.child_r_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in relative low-income out of work families (as % of all in relative low-income families).rate": "26.86%",
        "E92000001.child_r_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in relative low-income out of work families (as % of all in relative low-income families).rate": "33.02%",
        "E12000008.child_r_lif_0_19_outwork*dr.2023-01-01.Children 0-19 in relative low-income out of work families (as % of all in relative low-income families).rate": "25.36%",
        "CSTM5160.child_r_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in relative low-income in-work families (as % of all in relative low-income families).rate": "73.10%",
        "E92000001.child_r_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in relative low-income in-work families (as % of all in relative low-income families).rate": "66.98%",
        "E12000008.child_r_lif_0_19_inwork*dr.2023-01-01.Children 0-19 in relative low-income in-work families (as % of all in relative low-income families).rate": "74.64%",
        "CSTM5160.child_r_lif_0_19_lone*dr.2023-01-01.Children 0-19 in relative low-income lone parent families (as % of all in relative low-income families).rate": "51.04%",
        "E92000001.child_r_lif_0_19_lone*dr.2023-01-01.Children 0-19 in relative low-income lone parent families (as % of all in relative low-income families).rate": "50.28%",
        "E12000008.child_r_lif_0_19_lone*dr.2023-01-01.Children 0-19 in relative low-income lone parent families (as % of all in relative low-income families).rate": "50.92%",
        "CSTM5160.child_r_lif_0_19_couple*dr.2023-01-01.Children 0-19 in relative low-income couple families (as % of all in relative low-income families).rate": "48.89%",
        "E92000001.child_r_lif_0_19_couple*dr.2023-01-01.Children 0-19 in relative low-income couple families (as % of all in relative low-income families).rate": "49.72%",
        "E12000008.child_r_lif_0_19_couple*dr.2023-01-01.Children 0-19 in relative low-income couple families (as % of all in relative low-income families).rate": "49.08%",
        "CSTM5160.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021).rate": "1.19%",
        "E92000001.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021).rate": "1.06%",
        "E12000008.lc3304ew_unpaid_care_015*dr.2021-01-01.Children providing unpaid care (Census 2021).rate": "1.18%",
        "CSTM5160.low_birth_weight_n*dr.2013-01-01.Low birth weight.rate": "7.03%",
        "E92000001.low_birth_weight_n*dr.2013-01-01.Low birth weight.rate": "7.44%",
        "E12000008.low_birth_weight_n*dr.2013-01-01.Low birth weight.rate": "6.68%",
        "CSTM5160.mortality_under28_days*dr.2018-01-01.Deaths under 28 days (neonatal mortality), all causes.rate": "1.30%",
        "E92000001.mortality_under28_days*dr.2018-01-01.Deaths under 28 days (neonatal mortality), all causes.rate": "1.42%",
        "E12000008.mortality_under28_days*dr.2018-01-01.Deaths under 28 days (neonatal mortality), all causes.rate": "1.23%",
        "CSTM5160.mortality_under1_all*dr.2018-01-01.Deaths under 1 (infant mortality), all causes.rate": "1.64%",
        "E92000001.mortality_under1_all*dr.2018-01-01.Deaths under 1 (infant mortality), all causes.rate": "1.95%",
        "E12000008.mortality_under1_all*dr.2018-01-01.Deaths under 1 (infant mortality), all causes.rate": "1.07%",
        "CSTM5160.mortality_0_4_all_cause*dr.2018-01-01.Deaths under 5 (child mortality), all causes.rate": "0.31%",
        "E92000001.mortality_0_4_all_cause*dr.2018-01-01.Deaths under 5 (child mortality), all causes.rate": "0.42%",
        "E12000008.mortality_0_4_all_cause*dr.2018-01-01.Deaths under 5 (child mortality), all causes.rate": "0.23%",
        "CSTM5160.mortality_0_14_all_cause*dr.2018-01-01.Deaths under 15, all causes.rate": "0.10%",
        "E92000001.mortality_0_14_all_cause*dr.2018-01-01.Deaths under 15, all causes.rate": "0.16%",
        "E12000008.mortality_0_14_all_cause*dr.2018-01-01.Deaths under 15, all causes.rate": "0.09%",
        "CSTM5160.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP).rate": "8.06%",
        "E92000001.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP).rate": "8.94%",
        "E12000008.pip_tot*dr.2024-07-01.Personal Independence Payment (PIP).rate": "6.82%",
        "CSTM5160.dla_total*dr.2024-02-01.Disability benefit (DLA).rate": "2.20%",
        "E92000001.dla_total*dr.2024-02-01.Disability benefit (DLA).rate": "2.05%",
        "E12000008.dla_total*dr.2024-02-01.Disability benefit (DLA).rate": "1.99%",
        "CSTM5160.aac_tot_count*dr.2024-02-01.Older people social care benefit (Attendance Allowance).rate": "11.45%",
        "E92000001.aac_tot_count*dr.2024-02-01.Older people social care benefit (Attendance Allowance).rate": "12.73%",
        "E12000008.aac_tot_count*dr.2024-02-01.Older people social care benefit (Attendance Allowance).rate": "11.39%",
        "CSTM5160.pip_tot*dr.2013-04-01.Personal Independence Payment (PIP).rate": "0.00%",
        "E92000001.pip_tot*dr.2013-04-01.Personal Independence Payment (PIP).rate": "0.00%",
        "E12000008.pip_tot*dr.2013-04-01.Personal Independence Payment (PIP).rate": "0.00%",
        "CSTM5160.pip_tot*dr.2013-05-01.Personal Independence Payment (PIP).rate": "0.00%",
        "E92000001.pip_tot*dr.2013-05-01.Personal Independence Payment (PIP).rate": "0.00%",
        "E12000008.pip_tot*dr.2013-05-01.Personal Independence Payment (PIP).rate": "0.00%",
        "CSTM5160.pip_tot*dr.2013-06-01.Personal Independence Payment (PIP).rate": "0.00%",
        "E92000001.pip_tot*dr.2013-06-01.Personal Independence Payment (PIP).rate": "0.00%",
        "E12000008.pip_tot*dr.2013-06-01.Personal Independence Payment (PIP).rate": "0.00%",
        "CSTM5160.pip_tot*dr.2013-07-01.Personal Independence Payment (PIP).rate": "0.00%",
        "E92000001.pip_tot*dr.2013-07-01.Personal Independence Payment (PIP).rate": "0.00%",
        "E12000008.pip_tot*dr.2013-07-01.Personal Independence Payment (PIP).rate": "0.00%",
        "CSTM5160.pip_tot*dr.2013-08-01.Personal Independence Payment (PIP).rate": "0.01%",
        "E92000001.pip_tot*dr.2013-08-01.Personal Independence Payment (PIP).rate": "0.01%",
        "E12000008.pip_tot*dr.2013-08-01.Personal Independence Payment (PIP).rate": "0.01%",
        "CSTM5160.pip_tot*dr.2013-09-01.Personal Independence Payment (PIP).rate": "0.01%",
        "E92000001.pip_tot*dr.2013-09-01.Personal Independence Payment (PIP).rate": "0.02%",
        "E12000008.pip_tot*dr.2013-09-01.Personal Independence Payment (PIP).rate": "0.01%",
        "CSTM5160.pip_tot*dr.2013-10-01.Personal Independence Payment (PIP).rate": "0.01%",
        "E92000001.pip_tot*dr.2013-10-01.Personal Independence Payment (PIP).rate": "0.02%",
        "E12000008.pip_tot*dr.2013-10-01.Personal Independence Payment (PIP).rate": "0.02%",
        "CSTM5160.pip_tot*dr.2013-11-01.Personal Independence Payment (PIP).rate": "0.02%",
        "E92000001.pip_tot*dr.2013-11-01.Personal Independence Payment (PIP).rate": "0.03%",
        "E12000008.pip_tot*dr.2013-11-01.Personal Independence Payment (PIP).rate": "0.02%",
        "CSTM5160.pip_tot*dr.2013-12-01.Personal Independence Payment (PIP).rate": "0.02%",
        "E92000001.pip_tot*dr.2013-12-01.Personal Independence Payment (PIP).rate": "0.05%",
        "E12000008.pip_tot*dr.2013-12-01.Personal Independence Payment (PIP).rate": "0.03%",
        "CSTM5160.pip_tot*dr.2014-01-01.Personal Independence Payment (PIP).rate": "0.03%",
        "E92000001.pip_tot*dr.2014-01-01.Personal Independence Payment (PIP).rate": "0.07%",
        "E12000008.pip_tot*dr.2014-01-01.Personal Independence Payment (PIP).rate": "0.04%",
        "CSTM5160.pip_tot*dr.2014-02-01.Personal Independence Payment (PIP).rate": "0.04%",
        "E92000001.pip_tot*dr.2014-02-01.Personal Independence Payment (PIP).rate": "0.08%",
        "E12000008.pip_tot*dr.2014-02-01.Personal Independence Payment (PIP).rate": "0.05%",
        "CSTM5160.pip_tot*dr.2014-03-01.Personal Independence Payment (PIP).rate": "0.05%",
        "E92000001.pip_tot*dr.2014-03-01.Personal Independence Payment (PIP).rate": "0.11%",
        "E12000008.pip_tot*dr.2014-03-01.Personal Independence Payment (PIP).rate": "0.07%",
        "CSTM5160.pip_tot*dr.2014-04-01.Personal Independence Payment (PIP).rate": "0.07%",
        "E92000001.pip_tot*dr.2014-04-01.Personal Independence Payment (PIP).rate": "0.14%",
        "E12000008.pip_tot*dr.2014-04-01.Personal Independence Payment (PIP).rate": "0.08%",
        "CSTM5160.pip_tot*dr.2014-05-01.Personal Independence Payment (PIP).rate": "0.10%",
        "E92000001.pip_tot*dr.2014-05-01.Personal Independence Payment (PIP).rate": "0.16%",
        "E12000008.pip_tot*dr.2014-05-01.Personal Independence Payment (PIP).rate": "0.11%",
        "CSTM5160.pip_tot*dr.2014-06-01.Personal Independence Payment (PIP).rate": "0.12%",
        "E92000001.pip_tot*dr.2014-06-01.Personal Independence Payment (PIP).rate": "0.20%",
        "E12000008.pip_tot*dr.2014-06-01.Personal Independence Payment (PIP).rate": "0.13%",
        "CSTM5160.pip_tot*dr.2014-07-01.Personal Independence Payment (PIP).rate": "0.16%",
        "E92000001.pip_tot*dr.2014-07-01.Personal Independence Payment (PIP).rate": "0.25%",
        "E12000008.pip_tot*dr.2014-07-01.Personal Independence Payment (PIP).rate": "0.16%",
        "CSTM5160.pip_tot*dr.2014-08-01.Personal Independence Payment (PIP).rate": "0.21%",
        "E92000001.pip_tot*dr.2014-08-01.Personal Independence Payment (PIP).rate": "0.32%",
        "E12000008.pip_tot*dr.2014-08-01.Personal Independence Payment (PIP).rate": "0.20%",
        "CSTM5160.pip_tot*dr.2014-09-01.Personal Independence Payment (PIP).rate": "0.23%",
        "E92000001.pip_tot*dr.2014-09-01.Personal Independence Payment (PIP).rate": "0.39%",
        "E12000008.pip_tot*dr.2014-09-01.Personal Independence Payment (PIP).rate": "0.22%",
        "CSTM5160.pip_tot*dr.2014-10-01.Personal Independence Payment (PIP).rate": "0.28%",
        "E92000001.pip_tot*dr.2014-10-01.Personal Independence Payment (PIP).rate": "0.48%",
        "E12000008.pip_tot*dr.2014-10-01.Personal Independence Payment (PIP).rate": "0.27%",
        "CSTM5160.pip_tot*dr.2014-11-01.Personal Independence Payment (PIP).rate": "0.33%",
        "E92000001.pip_tot*dr.2014-11-01.Personal Independence Payment (PIP).rate": "0.58%",
        "E12000008.pip_tot*dr.2014-11-01.Personal Independence Payment (PIP).rate": "0.32%",
        "CSTM5160.pip_tot*dr.2014-12-01.Personal Independence Payment (PIP).rate": "0.40%",
        "E92000001.pip_tot*dr.2014-12-01.Personal Independence Payment (PIP).rate": "0.68%",
        "E12000008.pip_tot*dr.2014-12-01.Personal Independence Payment (PIP).rate": "0.38%",
        "CSTM5160.pip_tot*dr.2015-01-01.Personal Independence Payment (PIP).rate": "0.48%",
        "E92000001.pip_tot*dr.2015-01-01.Personal Independence Payment (PIP).rate": "0.79%",
        "E12000008.pip_tot*dr.2015-01-01.Personal Independence Payment (PIP).rate": "0.46%",
        "CSTM5160.pip_tot*dr.2015-02-01.Personal Independence Payment (PIP).rate": "0.57%",
        "E92000001.pip_tot*dr.2015-02-01.Personal Independence Payment (PIP).rate": "0.89%",
        "E12000008.pip_tot*dr.2015-02-01.Personal Independence Payment (PIP).rate": "0.52%",
        "CSTM5160.pip_tot*dr.2015-03-01.Personal Independence Payment (PIP).rate": "0.66%",
        "E92000001.pip_tot*dr.2015-03-01.Personal Independence Payment (PIP).rate": "0.98%",
        "E12000008.pip_tot*dr.2015-03-01.Personal Independence Payment (PIP).rate": "0.58%",
        "CSTM5160.pip_tot*dr.2015-04-01.Personal Independence Payment (PIP).rate": "0.73%",
        "E92000001.pip_tot*dr.2015-04-01.Personal Independence Payment (PIP).rate": "1.06%",
        "E12000008.pip_tot*dr.2015-04-01.Personal Independence Payment (PIP).rate": "0.64%",
        "CSTM5160.pip_tot*dr.2015-05-01.Personal Independence Payment (PIP).rate": "0.77%",
        "E92000001.pip_tot*dr.2015-05-01.Personal Independence Payment (PIP).rate": "1.07%",
        "E12000008.pip_tot*dr.2015-05-01.Personal Independence Payment (PIP).rate": "0.65%",
        "CSTM5160.pip_tot*dr.2015-06-01.Personal Independence Payment (PIP).rate": "0.85%",
        "E92000001.pip_tot*dr.2015-06-01.Personal Independence Payment (PIP).rate": "1.15%",
        "E12000008.pip_tot*dr.2015-06-01.Personal Independence Payment (PIP).rate": "0.71%",
        "CSTM5160.pip_tot*dr.2015-07-01.Personal Independence Payment (PIP).rate": "0.93%",
        "E92000001.pip_tot*dr.2015-07-01.Personal Independence Payment (PIP).rate": "1.22%",
        "E12000008.pip_tot*dr.2015-07-01.Personal Independence Payment (PIP).rate": "0.77%",
        "CSTM5160.pip_tot*dr.2015-08-01.Personal Independence Payment (PIP).rate": "0.97%",
        "E92000001.pip_tot*dr.2015-08-01.Personal Independence Payment (PIP).rate": "1.28%",
        "E12000008.pip_tot*dr.2015-08-01.Personal Independence Payment (PIP).rate": "0.81%",
        "CSTM5160.pip_tot*dr.2015-09-01.Personal Independence Payment (PIP).rate": "1.04%",
        "E92000001.pip_tot*dr.2015-09-01.Personal Independence Payment (PIP).rate": "1.34%",
        "E12000008.pip_tot*dr.2015-09-01.Personal Independence Payment (PIP).rate": "0.86%",
        "CSTM5160.pip_tot*dr.2015-10-01.Personal Independence Payment (PIP).rate": "1.10%",
        "E92000001.pip_tot*dr.2015-10-01.Personal Independence Payment (PIP).rate": "1.42%",
        "E12000008.pip_tot*dr.2015-10-01.Personal Independence Payment (PIP).rate": "0.91%",
        "CSTM5160.pip_tot*dr.2015-11-01.Personal Independence Payment (PIP).rate": "1.16%",
        "E92000001.pip_tot*dr.2015-11-01.Personal Independence Payment (PIP).rate": "1.48%",
        "E12000008.pip_tot*dr.2015-11-01.Personal Independence Payment (PIP).rate": "0.97%",
        "CSTM5160.pip_tot*dr.2015-12-01.Personal Independence Payment (PIP).rate": "1.22%",
        "E92000001.pip_tot*dr.2015-12-01.Personal Independence Payment (PIP).rate": "1.54%",
        "E12000008.pip_tot*dr.2015-12-01.Personal Independence Payment (PIP).rate": "1.02%",
        "CSTM5160.pip_tot*dr.2016-01-01.Personal Independence Payment (PIP).rate": "1.26%",
        "E92000001.pip_tot*dr.2016-01-01.Personal Independence Payment (PIP).rate": "1.60%",
        "E12000008.pip_tot*dr.2016-01-01.Personal Independence Payment (PIP).rate": "1.06%",
        "CSTM5160.pip_tot*dr.2016-02-01.Personal Independence Payment (PIP).rate": "1.34%",
        "E92000001.pip_tot*dr.2016-02-01.Personal Independence Payment (PIP).rate": "1.68%",
        "E12000008.pip_tot*dr.2016-02-01.Personal Independence Payment (PIP).rate": "1.13%",
        "CSTM5160.pip_tot*dr.2016-03-01.Personal Independence Payment (PIP).rate": "1.44%",
        "E92000001.pip_tot*dr.2016-03-01.Personal Independence Payment (PIP).rate": "1.77%",
        "E12000008.pip_tot*dr.2016-03-01.Personal Independence Payment (PIP).rate": "1.20%",
        "CSTM5160.pip_tot*dr.2016-04-01.Personal Independence Payment (PIP).rate": "1.56%",
        "E92000001.pip_tot*dr.2016-04-01.Personal Independence Payment (PIP).rate": "1.86%",
        "E12000008.pip_tot*dr.2016-04-01.Personal Independence Payment (PIP).rate": "1.29%",
        "CSTM5160.pip_tot*dr.2016-07-01.Personal Independence Payment (PIP).rate": "1.84%",
        "E92000001.pip_tot*dr.2016-07-01.Personal Independence Payment (PIP).rate": "2.08%",
        "E12000008.pip_tot*dr.2016-07-01.Personal Independence Payment (PIP).rate": "1.53%",
        "CSTM5160.pip_tot*dr.2016-08-01.Personal Independence Payment (PIP).rate": "1.96%",
        "E92000001.pip_tot*dr.2016-08-01.Personal Independence Payment (PIP).rate": "2.27%",
        "E12000008.pip_tot*dr.2016-08-01.Personal Independence Payment (PIP).rate": "1.62%",
        "CSTM5160.pip_tot*dr.2016-09-01.Personal Independence Payment (PIP).rate": "2.07%",
        "E92000001.pip_tot*dr.2016-09-01.Personal Independence Payment (PIP).rate": "2.39%",
        "E12000008.pip_tot*dr.2016-09-01.Personal Independence Payment (PIP).rate": "1.72%",
        "CSTM5160.pip_tot*dr.2016-10-01.Personal Independence Payment (PIP).rate": "2.20%",
        "E92000001.pip_tot*dr.2016-10-01.Personal Independence Payment (PIP).rate": "2.52%",
        "E12000008.pip_tot*dr.2016-10-01.Personal Independence Payment (PIP).rate": "1.82%",
        "CSTM5160.pip_tot*dr.2016-11-01.Personal Independence Payment (PIP).rate": "2.32%",
        "E92000001.pip_tot*dr.2016-11-01.Personal Independence Payment (PIP).rate": "2.64%",
        "E12000008.pip_tot*dr.2016-11-01.Personal Independence Payment (PIP).rate": "1.92%",
        "CSTM5160.pip_tot*dr.2016-12-01.Personal Independence Payment (PIP).rate": "2.42%",
        "E92000001.pip_tot*dr.2016-12-01.Personal Independence Payment (PIP).rate": "2.75%",
        "E12000008.pip_tot*dr.2016-12-01.Personal Independence Payment (PIP).rate": "2.00%",
        "CSTM5160.pip_tot*dr.2017-01-01.Personal Independence Payment (PIP).rate": "2.52%",
        "E92000001.pip_tot*dr.2017-01-01.Personal Independence Payment (PIP).rate": "2.85%",
        "E12000008.pip_tot*dr.2017-01-01.Personal Independence Payment (PIP).rate": "2.09%",
        "CSTM5160.pip_tot*dr.2017-02-01.Personal Independence Payment (PIP).rate": "2.66%",
        "E92000001.pip_tot*dr.2017-02-01.Personal Independence Payment (PIP).rate": "2.96%",
        "E12000008.pip_tot*dr.2017-02-01.Personal Independence Payment (PIP).rate": "2.19%",
        "CSTM5160.pip_tot*dr.2017-03-01.Personal Independence Payment (PIP).rate": "2.77%",
        "E92000001.pip_tot*dr.2017-03-01.Personal Independence Payment (PIP).rate": "3.08%",
        "E12000008.pip_tot*dr.2017-03-01.Personal Independence Payment (PIP).rate": "2.29%",
        "CSTM5160.pip_tot*dr.2017-04-01.Personal Independence Payment (PIP).rate": "2.85%",
        "E92000001.pip_tot*dr.2017-04-01.Personal Independence Payment (PIP).rate": "3.16%",
        "E12000008.pip_tot*dr.2017-04-01.Personal Independence Payment (PIP).rate": "2.36%",
        "CSTM5160.pip_tot*dr.2017-05-01.Personal Independence Payment (PIP).rate": "2.95%",
        "E92000001.pip_tot*dr.2017-05-01.Personal Independence Payment (PIP).rate": "3.27%",
        "E12000008.pip_tot*dr.2017-05-01.Personal Independence Payment (PIP).rate": "2.45%",
        "CSTM5160.pip_tot*dr.2017-06-01.Personal Independence Payment (PIP).rate": "3.05%",
        "E92000001.pip_tot*dr.2017-06-01.Personal Independence Payment (PIP).rate": "3.37%",
        "E12000008.pip_tot*dr.2017-06-01.Personal Independence Payment (PIP).rate": "2.54%",
        "CSTM5160.pip_tot*dr.2017-07-01.Personal Independence Payment (PIP).rate": "3.15%",
        "E92000001.pip_tot*dr.2017-07-01.Personal Independence Payment (PIP).rate": "3.46%",
        "E12000008.pip_tot*dr.2017-07-01.Personal Independence Payment (PIP).rate": "2.61%",
        "CSTM5160.pip_tot*dr.2017-08-01.Personal Independence Payment (PIP).rate": "3.21%",
        "E92000001.pip_tot*dr.2017-08-01.Personal Independence Payment (PIP).rate": "3.55%",
        "E12000008.pip_tot*dr.2017-08-01.Personal Independence Payment (PIP).rate": "2.66%",
        "CSTM5160.pip_tot*dr.2017-09-01.Personal Independence Payment (PIP).rate": "3.27%",
        "E92000001.pip_tot*dr.2017-09-01.Personal Independence Payment (PIP).rate": "3.63%",
        "E12000008.pip_tot*dr.2017-09-01.Personal Independence Payment (PIP).rate": "2.71%",
        "CSTM5160.pip_tot*dr.2017-10-01.Personal Independence Payment (PIP).rate": "3.34%",
        "E92000001.pip_tot*dr.2017-10-01.Personal Independence Payment (PIP).rate": "3.73%",
        "E12000008.pip_tot*dr.2017-10-01.Personal Independence Payment (PIP).rate": "2.77%",
        "CSTM5160.pip_tot*dr.2018-10-01.Personal Independence Payment (PIP).rate": "4.06%",
        "E92000001.pip_tot*dr.2018-10-01.Personal Independence Payment (PIP).rate": "4.66%",
        "E12000008.pip_tot*dr.2018-10-01.Personal Independence Payment (PIP).rate": "3.43%",
        "CSTM5160.pip_tot*dr.2018-11-01.Personal Independence Payment (PIP).rate": "4.12%",
        "E92000001.pip_tot*dr.2018-11-01.Personal Independence Payment (PIP).rate": "4.72%",
        "E12000008.pip_tot*dr.2018-11-01.Personal Independence Payment (PIP).rate": "3.48%",
        "CSTM5160.pip_tot*dr.2018-12-01.Personal Independence Payment (PIP).rate": "4.15%",
        "E92000001.pip_tot*dr.2018-12-01.Personal Independence Payment (PIP).rate": "4.75%",
        "E12000008.pip_tot*dr.2018-12-01.Personal Independence Payment (PIP).rate": "3.50%",
        "CSTM5160.pip_tot*dr.2019-01-01.Personal Independence Payment (PIP).rate": "4.19%",
        "E92000001.pip_tot*dr.2019-01-01.Personal Independence Payment (PIP).rate": "4.84%",
        "E12000008.pip_tot*dr.2019-01-01.Personal Independence Payment (PIP).rate": "3.76%",
        "CSTM5160.pip_tot*dr.2019-02-01.Personal Independence Payment (PIP).rate": "4.23%",
        "E92000001.pip_tot*dr.2019-02-01.Personal Independence Payment (PIP).rate": "4.90%",
        "E12000008.pip_tot*dr.2019-02-01.Personal Independence Payment (PIP).rate": "3.81%",
        "CSTM5160.pip_tot*dr.2019-03-01.Personal Independence Payment (PIP).rate": "4.29%",
        "E92000001.pip_tot*dr.2019-03-01.Personal Independence Payment (PIP).rate": "4.95%",
        "E12000008.pip_tot*dr.2019-03-01.Personal Independence Payment (PIP).rate": "3.85%",
        "CSTM5160.pip_tot*dr.2019-04-01.Personal Independence Payment (PIP).rate": "4.32%",
        "E92000001.pip_tot*dr.2019-04-01.Personal Independence Payment (PIP).rate": "4.99%",
        "E12000008.pip_tot*dr.2019-04-01.Personal Independence Payment (PIP).rate": "3.88%",
        "CSTM5160.pip_tot*dr.2019-05-01.Personal Independence Payment (PIP).rate": "4.36%",
        "E92000001.pip_tot*dr.2019-05-01.Personal Independence Payment (PIP).rate": "5.03%",
        "E12000008.pip_tot*dr.2019-05-01.Personal Independence Payment (PIP).rate": "3.91%",
        "CSTM5160.pip_tot*dr.2019-06-01.Personal Independence Payment (PIP).rate": "4.40%",
        "E92000001.pip_tot*dr.2019-06-01.Personal Independence Payment (PIP).rate": "5.10%",
        "E12000008.pip_tot*dr.2019-06-01.Personal Independence Payment (PIP).rate": "3.95%",
        "CSTM5160.pip_tot*dr.2019-07-01.Personal Independence Payment (PIP).rate": "4.45%",
        "E92000001.pip_tot*dr.2019-07-01.Personal Independence Payment (PIP).rate": "5.20%",
        "E12000008.pip_tot*dr.2019-07-01.Personal Independence Payment (PIP).rate": "4.01%",
        "CSTM5160.pip_tot*dr.2019-08-01.Personal Independence Payment (PIP).rate": "4.51%",
        "E92000001.pip_tot*dr.2019-08-01.Personal Independence Payment (PIP).rate": "5.29%",
        "E12000008.pip_tot*dr.2019-08-01.Personal Independence Payment (PIP).rate": "4.08%",
        "CSTM5160.pip_tot*dr.2019-09-01.Personal Independence Payment (PIP).rate": "4.57%",
        "E92000001.pip_tot*dr.2019-09-01.Personal Independence Payment (PIP).rate": "5.38%",
        "E12000008.pip_tot*dr.2019-09-01.Personal Independence Payment (PIP).rate": "4.15%",
        "CSTM5160.pip_tot*dr.2019-10-01.Personal Independence Payment (PIP).rate": "4.65%",
        "E92000001.pip_tot*dr.2019-10-01.Personal Independence Payment (PIP).rate": "5.48%",
        "E12000008.pip_tot*dr.2019-10-01.Personal Independence Payment (PIP).rate": "4.23%",
        "CSTM5160.pip_tot*dr.2019-11-01.Personal Independence Payment (PIP).rate": "4.70%",
        "E92000001.pip_tot*dr.2019-11-01.Personal Independence Payment (PIP).rate": "5.56%",
        "E12000008.pip_tot*dr.2019-11-01.Personal Independence Payment (PIP).rate": "4.29%",
        "CSTM5160.pip_tot*dr.2019-12-01.Personal Independence Payment (PIP).rate": "4.75%",
        "E92000001.pip_tot*dr.2019-12-01.Personal Independence Payment (PIP).rate": "5.61%",
        "E12000008.pip_tot*dr.2019-12-01.Personal Independence Payment (PIP).rate": "4.35%",
        "CSTM5160.pip_tot*dr.2020-01-01.Personal Independence Payment (PIP).rate": "4.79%",
        "E92000001.pip_tot*dr.2020-01-01.Personal Independence Payment (PIP).rate": "5.64%",
        "E12000008.pip_tot*dr.2020-01-01.Personal Independence Payment (PIP).rate": "4.38%",
        "CSTM5160.pip_tot*dr.2020-02-01.Personal Independence Payment (PIP).rate": "4.83%",
        "E92000001.pip_tot*dr.2020-02-01.Personal Independence Payment (PIP).rate": "5.69%",
        "E12000008.pip_tot*dr.2020-02-01.Personal Independence Payment (PIP).rate": "4.41%",
        "CSTM5160.pip_tot*dr.2020-03-01.Personal Independence Payment (PIP).rate": "4.92%",
        "E92000001.pip_tot*dr.2020-03-01.Personal Independence Payment (PIP).rate": "5.76%",
        "E12000008.pip_tot*dr.2020-03-01.Personal Independence Payment (PIP).rate": "4.48%",
        "CSTM5160.pip_tot*dr.2020-04-01.Personal Independence Payment (PIP).rate": "5.01%",
        "E92000001.pip_tot*dr.2020-04-01.Personal Independence Payment (PIP).rate": "5.84%",
        "E12000008.pip_tot*dr.2020-04-01.Personal Independence Payment (PIP).rate": "4.55%",
        "CSTM5160.pip_tot*dr.2020-05-01.Personal Independence Payment (PIP).rate": "5.05%",
        "E92000001.pip_tot*dr.2020-05-01.Personal Independence Payment (PIP).rate": "5.90%",
        "E12000008.pip_tot*dr.2020-05-01.Personal Independence Payment (PIP).rate": "4.60%",
        "CSTM5160.pip_tot*dr.2020-06-01.Personal Independence Payment (PIP).rate": "5.11%",
        "E92000001.pip_tot*dr.2020-06-01.Personal Independence Payment (PIP).rate": "5.95%",
        "E12000008.pip_tot*dr.2020-06-01.Personal Independence Payment (PIP).rate": "4.65%",
        "CSTM5160.pip_tot*dr.2020-07-01.Personal Independence Payment (PIP).rate": "5.15%",
        "E92000001.pip_tot*dr.2020-07-01.Personal Independence Payment (PIP).rate": "6.00%",
        "E12000008.pip_tot*dr.2020-07-01.Personal Independence Payment (PIP).rate": "4.70%",
        "CSTM5160.pip_tot*dr.2020-08-01.Personal Independence Payment (PIP).rate": "5.17%",
        "E92000001.pip_tot*dr.2020-08-01.Personal Independence Payment (PIP).rate": "6.04%",
        "E12000008.pip_tot*dr.2020-08-01.Personal Independence Payment (PIP).rate": "4.73%",
        "CSTM5160.pip_tot*dr.2020-09-01.Personal Independence Payment (PIP).rate": "5.21%",
        "E92000001.pip_tot*dr.2020-09-01.Personal Independence Payment (PIP).rate": "6.09%",
        "E12000008.pip_tot*dr.2020-09-01.Personal Independence Payment (PIP).rate": "4.77%",
        "CSTM5160.pip_tot*dr.2020-10-01.Personal Independence Payment (PIP).rate": "5.24%",
        "E92000001.pip_tot*dr.2020-10-01.Personal Independence Payment (PIP).rate": "6.13%",
        "E12000008.pip_tot*dr.2020-10-01.Personal Independence Payment (PIP).rate": "4.80%",
        "CSTM5160.pip_tot*dr.2020-11-01.Personal Independence Payment (PIP).rate": "5.29%",
        "E92000001.pip_tot*dr.2020-11-01.Personal Independence Payment (PIP).rate": "6.16%",
        "E12000008.pip_tot*dr.2020-11-01.Personal Independence Payment (PIP).rate": "4.84%",
        "CSTM5160.pip_tot*dr.2020-12-01.Personal Independence Payment (PIP).rate": "5.31%",
        "E92000001.pip_tot*dr.2020-12-01.Personal Independence Payment (PIP).rate": "6.19%",
        "E12000008.pip_tot*dr.2020-12-01.Personal Independence Payment (PIP).rate": "4.86%",
        "CSTM5160.pip_tot*dr.2021-01-01.Personal Independence Payment (PIP).rate": "5.66%",
        "E92000001.pip_tot*dr.2021-01-01.Personal Independence Payment (PIP).rate": "6.09%",
        "E12000008.pip_tot*dr.2021-01-01.Personal Independence Payment (PIP).rate": "4.49%",
        "CSTM5160.pip_tot*dr.2021-02-01.Personal Independence Payment (PIP).rate": "5.69%",
        "E92000001.pip_tot*dr.2021-02-01.Personal Independence Payment (PIP).rate": "6.14%",
        "E12000008.pip_tot*dr.2021-02-01.Personal Independence Payment (PIP).rate": "4.53%",
        "CSTM5160.pip_tot*dr.2021-03-01.Personal Independence Payment (PIP).rate": "5.72%",
        "E92000001.pip_tot*dr.2021-03-01.Personal Independence Payment (PIP).rate": "6.19%",
        "E12000008.pip_tot*dr.2021-03-01.Personal Independence Payment (PIP).rate": "4.57%",
        "CSTM5160.pip_tot*dr.2021-04-01.Personal Independence Payment (PIP).rate": "5.78%",
        "E92000001.pip_tot*dr.2021-04-01.Personal Independence Payment (PIP).rate": "6.24%",
        "E12000008.pip_tot*dr.2021-04-01.Personal Independence Payment (PIP).rate": "4.61%",
        "CSTM5160.pip_tot*dr.2021-05-01.Personal Independence Payment (PIP).rate": "5.80%",
        "E92000001.pip_tot*dr.2021-05-01.Personal Independence Payment (PIP).rate": "6.27%",
        "E12000008.pip_tot*dr.2021-05-01.Personal Independence Payment (PIP).rate": "4.62%",
        "CSTM5160.pip_tot*dr.2021-06-01.Personal Independence Payment (PIP).rate": "5.84%",
        "E92000001.pip_tot*dr.2021-06-01.Personal Independence Payment (PIP).rate": "6.30%",
        "E12000008.pip_tot*dr.2021-06-01.Personal Independence Payment (PIP).rate": "4.65%",
        "CSTM5160.pip_tot*dr.2021-07-01.Personal Independence Payment (PIP).rate": "5.88%",
        "E92000001.pip_tot*dr.2021-07-01.Personal Independence Payment (PIP).rate": "6.36%",
        "E12000008.pip_tot*dr.2021-07-01.Personal Independence Payment (PIP).rate": "4.70%",
        "CSTM5160.pip_tot*dr.2021-08-01.Personal Independence Payment (PIP).rate": "5.90%",
        "E92000001.pip_tot*dr.2021-08-01.Personal Independence Payment (PIP).rate": "6.40%",
        "E12000008.pip_tot*dr.2021-08-01.Personal Independence Payment (PIP).rate": "4.73%",
        "CSTM5160.pip_tot*dr.2021-09-01.Personal Independence Payment (PIP).rate": "5.92%",
        "E92000001.pip_tot*dr.2021-09-01.Personal Independence Payment (PIP).rate": "6.43%",
        "E12000008.pip_tot*dr.2021-09-01.Personal Independence Payment (PIP).rate": "4.75%",
        "CSTM5160.pip_tot*dr.2021-10-01.Personal Independence Payment (PIP).rate": "5.94%",
        "E92000001.pip_tot*dr.2021-10-01.Personal Independence Payment (PIP).rate": "6.47%",
        "E12000008.pip_tot*dr.2021-10-01.Personal Independence Payment (PIP).rate": "4.79%",
        "CSTM5160.pip_tot*dr.2021-11-01.Personal Independence Payment (PIP).rate": "6.01%",
        "E92000001.pip_tot*dr.2021-11-01.Personal Independence Payment (PIP).rate": "6.53%",
        "E12000008.pip_tot*dr.2021-11-01.Personal Independence Payment (PIP).rate": "4.84%",
        "CSTM5160.pip_tot*dr.2021-12-01.Personal Independence Payment (PIP).rate": "6.08%",
        "E92000001.pip_tot*dr.2021-12-01.Personal Independence Payment (PIP).rate": "6.59%",
        "E12000008.pip_tot*dr.2021-12-01.Personal Independence Payment (PIP).rate": "4.89%",
        "CSTM5160.pip_tot*dr.2022-01-01.Personal Independence Payment (PIP).rate": "6.08%",
        "E92000001.pip_tot*dr.2022-01-01.Personal Independence Payment (PIP).rate": "6.60%",
        "E12000008.pip_tot*dr.2022-01-01.Personal Independence Payment (PIP).rate": "4.91%",
        "CSTM5160.pip_tot*dr.2022-02-01.Personal Independence Payment (PIP).rate": "6.12%",
        "E92000001.pip_tot*dr.2022-02-01.Personal Independence Payment (PIP).rate": "6.65%",
        "E12000008.pip_tot*dr.2022-02-01.Personal Independence Payment (PIP).rate": "4.95%",
        "CSTM5160.pip_tot*dr.2022-03-01.Personal Independence Payment (PIP).rate": "6.18%",
        "E92000001.pip_tot*dr.2022-03-01.Personal Independence Payment (PIP).rate": "6.74%",
        "E12000008.pip_tot*dr.2022-03-01.Personal Independence Payment (PIP).rate": "5.02%",
        "CSTM5160.pip_tot*dr.2022-04-01.Personal Independence Payment (PIP).rate": "6.23%",
        "E92000001.pip_tot*dr.2022-04-01.Personal Independence Payment (PIP).rate": "6.81%",
        "E12000008.pip_tot*dr.2022-04-01.Personal Independence Payment (PIP).rate": "5.07%",
        "CSTM5160.pip_tot*dr.2022-05-01.Personal Independence Payment (PIP).rate": null,
        "E92000001.pip_tot*dr.2022-05-01.Personal Independence Payment (PIP).rate": null,
        "E12000008.pip_tot*dr.2022-05-01.Personal Independence Payment (PIP).rate": null,
        "CSTM5160.pip_tot*dr.2022-06-01.Personal Independence Payment (PIP).rate": null,
        "E92000001.pip_tot*dr.2022-06-01.Personal Independence Payment (PIP).rate": null,
        "E12000008.pip_tot*dr.2022-06-01.Personal Independence Payment (PIP).rate": null,
        "CSTM5160.pip_tot*dr.2022-07-01.Personal Independence Payment (PIP).rate": "6.39%",
        "E92000001.pip_tot*dr.2022-07-01.Personal Independence Payment (PIP).rate": "7.03%",
        "E12000008.pip_tot*dr.2022-07-01.Personal Independence Payment (PIP).rate": "5.25%",
        "CSTM5160.pip_tot*dr.2022-08-01.Personal Independence Payment (PIP).rate": "6.45%",
        "E92000001.pip_tot*dr.2022-08-01.Personal Independence Payment (PIP).rate": "7.11%",
        "E12000008.pip_tot*dr.2022-08-01.Personal Independence Payment (PIP).rate": "5.31%",
        "CSTM5160.pip_tot*dr.2022-09-01.Personal Independence Payment (PIP).rate": "6.53%",
        "E92000001.pip_tot*dr.2022-09-01.Personal Independence Payment (PIP).rate": "7.18%",
        "E12000008.pip_tot*dr.2022-09-01.Personal Independence Payment (PIP).rate": "5.37%",
        "CSTM5160.pip_tot*dr.2022-10-01.Personal Independence Payment (PIP).rate": "6.60%",
        "E92000001.pip_tot*dr.2022-10-01.Personal Independence Payment (PIP).rate": "7.27%",
        "E12000008.pip_tot*dr.2022-10-01.Personal Independence Payment (PIP).rate": "5.44%",
        "CSTM5160.pip_tot*dr.2022-11-01.Personal Independence Payment (PIP).rate": "6.68%",
        "E92000001.pip_tot*dr.2022-11-01.Personal Independence Payment (PIP).rate": "7.36%",
        "E12000008.pip_tot*dr.2022-11-01.Personal Independence Payment (PIP).rate": "5.51%",
        "CSTM5160.pip_tot*dr.2022-12-01.Personal Independence Payment (PIP).rate": "6.73%",
        "E92000001.pip_tot*dr.2022-12-01.Personal Independence Payment (PIP).rate": "7.43%",
        "E12000008.pip_tot*dr.2022-12-01.Personal Independence Payment (PIP).rate": "5.57%",
        "CSTM5160.pip_tot*dr.2023-01-01.Personal Independence Payment (PIP).rate": "6.81%",
        "E92000001.pip_tot*dr.2023-01-01.Personal Independence Payment (PIP).rate": "7.52%",
        "E12000008.pip_tot*dr.2023-01-01.Personal Independence Payment (PIP).rate": "5.65%",
        "CSTM5160.pip_tot*dr.2023-02-01.Personal Independence Payment (PIP).rate": "6.87%",
        "E92000001.pip_tot*dr.2023-02-01.Personal Independence Payment (PIP).rate": "7.59%",
        "E12000008.pip_tot*dr.2023-02-01.Personal Independence Payment (PIP).rate": "5.70%",
        "CSTM5160.pip_tot*dr.2023-03-01.Personal Independence Payment (PIP).rate": "6.93%",
        "E92000001.pip_tot*dr.2023-03-01.Personal Independence Payment (PIP).rate": "7.64%",
        "E12000008.pip_tot*dr.2023-03-01.Personal Independence Payment (PIP).rate": "5.74%",
        "CSTM5160.pip_tot*dr.2023-04-01.Personal Independence Payment (PIP).rate": "6.93%",
        "E92000001.pip_tot*dr.2023-04-01.Personal Independence Payment (PIP).rate": "7.67%",
        "E12000008.pip_tot*dr.2023-04-01.Personal Independence Payment (PIP).rate": "5.76%",
        "CSTM5160.pip_tot*dr.2023-07-01.Personal Independence Payment (PIP).rate": "7.16%",
        "E92000001.pip_tot*dr.2023-07-01.Personal Independence Payment (PIP).rate": "7.90%",
        "E12000008.pip_tot*dr.2023-07-01.Personal Independence Payment (PIP).rate": "5.96%",
        "CSTM5160.pip_tot*dr.2023-10-01.Personal Independence Payment (PIP).rate": "7.39%",
        "E92000001.pip_tot*dr.2023-10-01.Personal Independence Payment (PIP).rate": "8.17%",
        "E12000008.pip_tot*dr.2023-10-01.Personal Independence Payment (PIP).rate": "6.17%",
        "CSTM5160.pip_tot*dr.2024-01-01.Personal Independence Payment (PIP).rate": "7.65%",
        "E92000001.pip_tot*dr.2024-01-01.Personal Independence Payment (PIP).rate": "8.44%",
        "E12000008.pip_tot*dr.2024-01-01.Personal Independence Payment (PIP).rate": "6.41%",
        "CSTM5160.pip_tot*dr.2024-04-01.Personal Independence Payment (PIP).rate": "7.82%",
        "E92000001.pip_tot*dr.2024-04-01.Personal Independence Payment (PIP).rate": "8.66%",
        "E12000008.pip_tot*dr.2024-04-01.Personal Independence Payment (PIP).rate": "6.59%",
        "CSTM5160.pip_female*dr.2024-07-01.Personal Independence Payment (PIP), female.rate": "8.39%",
        "E92000001.pip_female*dr.2024-07-01.Personal Independence Payment (PIP), female.rate": "9.69%",
        "E12000008.pip_female*dr.2024-07-01.Personal Independence Payment (PIP), female.rate": "7.46%",
        "CSTM5160.pip_male*dr.2024-07-01.Personal Independence Payment (PIP), male.rate": "7.73%",
        "E92000001.pip_male*dr.2024-07-01.Personal Independence Payment (PIP), male.rate": "8.17%",
        "E12000008.pip_male*dr.2024-07-01.Personal Independence Payment (PIP), male.rate": "6.17%",
        "CSTM5160.pip_mental*dr.2024-07-01.Personal Independence Payment (PIP), mental health claimants.rate": "3.70%",
        "E92000001.pip_mental*dr.2024-07-01.Personal Independence Payment (PIP), mental health claimants.rate": "3.42%",
        "E12000008.pip_mental*dr.2024-07-01.Personal Independence Payment (PIP), mental health claimants.rate": "2.74%",
        "CSTM5160.pip_resp*dr.2024-07-01.Personal Independence Payment (PIP), respiratory disease claimants.rate": "0.27%",
        "E92000001.pip_resp*dr.2024-07-01.Personal Independence Payment (PIP), respiratory disease claimants.rate": "0.34%",
        "E12000008.pip_resp*dr.2024-07-01.Personal Independence Payment (PIP), respiratory disease claimants.rate": "0.25%",
        "CSTM5160.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021).rate": "7.34%",
        "E92000001.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021).rate": "7.33%",
        "E12000008.disability_5a_1*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021).rate": "6.26%",
        "CSTM5160.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021).rate": "11.35%",
        "E92000001.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021).rate": "9.97%",
        "E12000008.disability_5a_2*dr.2021-01-01.Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021).rate": "9.87%",
        "CSTM5160.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021).rate": "7.51%",
        "E92000001.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021).rate": "6.83%",
        "E12000008.disability_5a_3*dr.2021-01-01.Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021).rate": "7.53%",
        "CSTM5160.hh_disabled_4a01*dr.2021-01-01.1 person disabled under the Equality Act in household (Census 2021).rate": "26.24%",
        "E92000001.hh_disabled_4a01*dr.2021-01-01.1 person disabled under the Equality Act in household (Census 2021).rate": "25.39%",
        "E12000008.hh_disabled_4a01*dr.2021-01-01.1 person disabled under the Equality Act in household (Census 2021).rate": "23.95%",
        "CSTM5160.hh_disabled_4a02*dr.2021-01-01.2 or more people disabled under the Equality Act in household (Census 2021).rate": "6.39%",
        "E92000001.hh_disabled_4a02*dr.2021-01-01.2 or more people disabled under the Equality Act in household (Census 2021).rate": "6.65%",
        "E12000008.hh_disabled_4a02*dr.2021-01-01.2 or more people disabled under the Equality Act in household (Census 2021).rate": "6.10%",
        "CSTM5160.id19_heapop_dec1*dr.2019-01-01.IoD 2019 Health Decile 1.rate": "9.91",
        "E92000001.id19_heapop_dec1*dr.2019-01-01.IoD 2019 Health Decile 1.rate": "9.69",
        "E12000008.id19_heapop_dec1*dr.2019-01-01.IoD 2019 Health Decile 1.rate": "2.16",
        "CSTM5160.id19_heapop_dec2*dr.2019-01-01.IoD 2019 Health Decile 2.rate": "10.95",
        "E92000001.id19_heapop_dec2*dr.2019-01-01.IoD 2019 Health Decile 2.rate": "9.88",
        "E12000008.id19_heapop_dec2*dr.2019-01-01.IoD 2019 Health Decile 2.rate": "4.57",
        "CSTM5160.id19_heapop_dec3*dr.2019-01-01.IoD 2019 Health Decile 3.rate": "7.17",
        "E92000001.id19_heapop_dec3*dr.2019-01-01.IoD 2019 Health Decile 3.rate": "9.90",
        "E12000008.id19_heapop_dec3*dr.2019-01-01.IoD 2019 Health Decile 3.rate": "6.61",
        "CSTM5160.id19_heapop_dec4*dr.2019-01-01.IoD 2019 Health Decile 4.rate": "12.41",
        "E92000001.id19_heapop_dec4*dr.2019-01-01.IoD 2019 Health Decile 4.rate": "9.86",
        "E12000008.id19_heapop_dec4*dr.2019-01-01.IoD 2019 Health Decile 4.rate": "8.32",
        "CSTM5160.id19_heapop_dec5*dr.2019-01-01.IoD 2019 Health Decile 5.rate": "10.73",
        "E92000001.id19_heapop_dec5*dr.2019-01-01.IoD 2019 Health Decile 5.rate": "9.86",
        "E12000008.id19_heapop_dec5*dr.2019-01-01.IoD 2019 Health Decile 5.rate": "8.09",
        "CSTM5160.id19_heapop_dec6*dr.2019-01-01.IoD 2019 Health Decile 6.rate": "8.88",
        "E92000001.id19_heapop_dec6*dr.2019-01-01.IoD 2019 Health Decile 6.rate": "9.94",
        "E12000008.id19_heapop_dec6*dr.2019-01-01.IoD 2019 Health Decile 6.rate": "10.14",
        "CSTM5160.id19_heapop_dec7*dr.2019-01-01.IoD 2019 Health Decile 7.rate": "16.98",
        "E92000001.id19_heapop_dec7*dr.2019-01-01.IoD 2019 Health Decile 7.rate": "9.97",
        "E12000008.id19_heapop_dec7*dr.2019-01-01.IoD 2019 Health Decile 7.rate": "11.24",
        "CSTM5160.id19_heapop_dec8*dr.2019-01-01.IoD 2019 Health Decile 8.rate": "10.79",
        "E92000001.id19_heapop_dec8*dr.2019-01-01.IoD 2019 Health Decile 8.rate": "10.13",
        "E12000008.id19_heapop_dec8*dr.2019-01-01.IoD 2019 Health Decile 8.rate": "12.65",
        "CSTM5160.id19_heapop_dec9*dr.2019-01-01.IoD 2019 Health Decile 9.rate": "10.14",
        "E92000001.id19_heapop_dec9*dr.2019-01-01.IoD 2019 Health Decile 9.rate": "10.10",
        "E12000008.id19_heapop_dec9*dr.2019-01-01.IoD 2019 Health Decile 9.rate": "16.36",
        "CSTM5160.id19_heapop_dec10*dr.2019-01-01.IoD 2019 Health Decile 10.rate": "1.09",
        "E92000001.id19_heapop_dec10*dr.2019-01-01.IoD 2019 Health Decile 10.rate": "10.44",
        "E12000008.id19_heapop_dec10*dr.2019-01-01.IoD 2019 Health Decile 10.rate": "25.01",
        "CSTM5160.ks301ew0008*dr.2021-01-01.Very good health (Census 2021).rate": "50.19%",
        "E92000001.ks301ew0008*dr.2021-01-01.Very good health (Census 2021).rate": "48.49%",
        "E12000008.ks301ew0008*dr.2021-01-01.Very good health (Census 2021).rate": "49.98%",
        "CSTM5160.ks301ew0009*dr.2021-01-01.Good health (Census 2021).rate": "33.16%",
        "E92000001.ks301ew0009*dr.2021-01-01.Good health (Census 2021).rate": "33.71%",
        "E12000008.ks301ew0009*dr.2021-01-01.Good health (Census 2021).rate": "34.01%",
        "CSTM5160.ks301ew0010*dr.2021-01-01.Fair health (Census 2021).rate": "11.59%",
        "E92000001.ks301ew0010*dr.2021-01-01.Fair health (Census 2021).rate": "12.65%",
        "E12000008.ks301ew0010*dr.2021-01-01.Fair health (Census 2021).rate": "11.77%",
        "CSTM5160.ks301ew0011*dr.2021-01-01.Bad health (Census 2021).rate": "3.88%",
        "E92000001.ks301ew0011*dr.2021-01-01.Bad health (Census 2021).rate": "3.98%",
        "E12000008.ks301ew0011*dr.2021-01-01.Bad health (Census 2021).rate": "3.31%",
        "CSTM5160.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021).rate": "1.18%",
        "E92000001.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021).rate": "1.17%",
        "E12000008.ks301ew0012*dr.2021-01-01.Very bad health (Census 2021).rate": "0.93%",
        "CSTM5160.ks301ew0012*dr.2001-01-01.Very bad health (Census 2021).rate": null,
        "E92000001.ks301ew0012*dr.2001-01-01.Very bad health (Census 2021).rate": null,
        "E12000008.ks301ew0012*dr.2001-01-01.Very bad health (Census 2021).rate": null,
        "CSTM5160.ks301ew0012*dr.2011-01-01.Very bad health  (E&W Census 2011).rate": "1.20%",
        "E92000001.ks301ew0012*dr.2011-01-01.Very bad health  (E&W Census 2011).rate": "1.25%",
        "E12000008.ks301ew0012*dr.2011-01-01.Very bad health  (E&W Census 2011).rate": "0.96%",
        "CSTM5160.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021).rate": "92.16%",
        "E92000001.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021).rate": "91.24%",
        "E12000008.ks301ew0013*dr.2021-01-01.No unpaid care (Census 2021).rate": "91.65%",
        "CSTM5160.is_carer_7a02*dr.2021-01-01.9 hours or less unpaid care a week (Census 2021).rate": "3.27%",
        "E92000001.is_carer_7a02*dr.2021-01-01.9 hours or less unpaid care a week (Census 2021).rate": "3.12%",
        "E12000008.is_carer_7a02*dr.2021-01-01.9 hours or less unpaid care a week (Census 2021).rate": "3.33%",
        "CSTM5160.is_carer_7a03*dr.2021-01-01.10 to 19 hours unpaid care a week (Census 2021).rate": "1.00%",
        "E92000001.is_carer_7a03*dr.2021-01-01.10 to 19 hours unpaid care a week (Census 2021).rate": "1.19%",
        "E12000008.is_carer_7a03*dr.2021-01-01.10 to 19 hours unpaid care a week (Census 2021).rate": "1.10%",
        "CSTM5160.is_carer_7a04*dr.2021-01-01.20 to 34 hours unpaid care a week (Census 2021).rate": "0.75%",
        "E92000001.is_carer_7a04*dr.2021-01-01.20 to 34 hours unpaid care a week (Census 2021).rate": "0.85%",
        "E12000008.is_carer_7a04*dr.2021-01-01.20 to 34 hours unpaid care a week (Census 2021).rate": "0.74%",
        "CSTM5160.is_carer_7a05*dr.2021-01-01.35 to 49 hours unpaid care a week (Census 2021).rate": "0.78%",
        "E92000001.is_carer_7a05*dr.2021-01-01.35 to 49 hours unpaid care a week (Census 2021).rate": "0.97%",
        "E12000008.is_carer_7a05*dr.2021-01-01.35 to 49 hours unpaid care a week (Census 2021).rate": "0.80%",
        "CSTM5160.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021).rate": "2.05%",
        "E92000001.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021).rate": "2.63%",
        "E12000008.ks301ew0016*dr.2021-01-01.50+ hours unpaid care a week (Census 2021).rate": "2.39%",
        "CSTM5160.ks301ew0016*dr.2011-01-01.50+ hours unpaid care a week  (E&W Census 2011).rate": "1.73%",
        "E92000001.ks301ew0016*dr.2011-01-01.50+ hours unpaid care a week  (E&W Census 2011).rate": "2.37%",
        "E12000008.ks301ew0016*dr.2011-01-01.50+ hours unpaid care a week  (E&W Census 2011).rate": "2.01%",
        "CSTM5160.mortality_0_64_all_cause*dr.2018-01-01.Deaths under 65, all causes.rate": "0.83%",
        "E92000001.mortality_0_64_all_cause*dr.2018-01-01.Deaths under 65, all causes.rate": "0.89%",
        "E12000008.mortality_0_64_all_cause*dr.2018-01-01.Deaths under 65, all causes.rate": "0.79%"
    },
    reportThemes:  [
    {
        "id": 10,
        "name": "Access and Transport",
        "slug": "access-and-transport",
        "title": "Access and Transport",
        "description": "<p>The following visualisations provide data on car ownership, travel time to essential services, job access scores, food insecurity, access to health assets and on the local retail environment.</p><p>As a collection, these indicators offer insight into the disparities, challenges, and opportunities that individuals have as their accessible environment.</p></p>",
        "clientID": 1,
        "created_at": "2022-11-11T08:41:44.000000Z",
        "updated_at": "2023-09-18T13:30:15.000000Z",
        "use_for_summary": false,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 505,
                "reportThemeID": 10,
                "text": "<h3>Car or van ownership</h3><p></p><p>The table below shows the proportion of households according to their ownership of a car or van. This is based on responses to the car ownership question in the 2021 Census which asks information on the number of cars or vans owned, or are available for use, by one or more members of a household. This includes company cars and vans available for private use and is based on details for private households only. Cars or vans used by residents of communal establishments are not counted.</p><p></p><p>In Brighton and Hove, 37.45% of households have no access to a car or van. This is greater than the proportion without access to a car or van in England (23.54%) and greater than the proportion without access to a car or van in South East (16.86%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 506,
                "reportThemeID": 10,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16881281919314",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "Car or van ownership",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2508,
                        "indicatorHTMLTableRef": "16881281919314",
                        "chartRef": null,
                        "indicatorCode": "ks404ew0004*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "2 cars or vans in household (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2509,
                        "indicatorHTMLTableRef": "16881281919314",
                        "chartRef": null,
                        "indicatorCode": "ks404ew_new3*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Households with 3+ cars (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2507,
                        "indicatorHTMLTableRef": "16881281919314",
                        "chartRef": null,
                        "indicatorCode": "ks404ew0003*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "1 car or van in household (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2506,
                        "indicatorHTMLTableRef": "16881281919314",
                        "chartRef": null,
                        "indicatorCode": "ks404ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Households with no car (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 507,
                "reportThemeID": 10,
                "text": "<p>The bar chart below shows the numbers of households with no car or van in the 2021 Census alongside the proportions from previous Censuses to identify changes over time.</p><p>In 2011, 38.19% of households in Brighton and Hove had no access to a car or van. This is similar to the proportion without access to a car or van in 2021 (37.45%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 73,
                "reportThemeID": 10,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16698134215515",
                "chartDescription": "Households with no car or van",
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": false,
                "itemName": "Households with no car or van",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2711,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16698134215515",
                        "indicatorCode": "ks404ew0002*dr",
                        "indicatorDate": "2011-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 905,
                        "y_label": null,
                        "indicatorName": "Households with no car  (E&W Census 2011)",
                        "source": "Census 2011",
                        "date": "2011-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2011",
                        "shortName": null
                    },
                    {
                        "id": 2712,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16698134215515",
                        "indicatorCode": "ks404ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1140,
                        "y_label": null,
                        "indicatorName": "Households with no car (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 141,
                "reportThemeID": 10,
                "text": "<h3>Travel times to key services</h3><p></p><p>The table below provides data for the travel time to key services (by walking and public transport). These figures are derived from the analysis of spatial data on public transport timetables; road, cycle and footpath networks; population and key local services. The data shows the average minimum travel time - the shortest travel time by walking and public transport, averaged over the LSOA geographical area. Where the shortest journey is by public transport, an average of five minutes is added to allow for a margin for catching the service, but if a quicker walking journey is available, this will be used with nothing added.</p><p></p><p>In Brighton and Hove, the average travel time to the nearest GP is 9.0 minutes. This is shorter than the travel time in England (13.0 minutes) and shorter than South East (14.0 minutes).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 509,
                "reportThemeID": 10,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16881288353859",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Travel times to key services (by walking and public transport)",
                "itemSource": "Department for Transport (DfT)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2693,
                        "indicatorHTMLTableRef": "16881288353859",
                        "chartRef": null,
                        "indicatorCode": "core_access_employment_trav_time_ptwalk",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Travel time to nearest employment centre by public transport/walk (with more than 500 jobs)",
                        "source": "Department for Transport (DfT)",
                        "date": "2019-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2517,
                        "indicatorHTMLTableRef": "16881288353859",
                        "chartRef": null,
                        "indicatorCode": "core_access_employment_trav_time_ptwalk_5000",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Travel time to nearest employment centre by public transport/walk (with more than 5000 jobs)",
                        "source": "Department for Transport (DfT)",
                        "date": "2019-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2518,
                        "indicatorHTMLTableRef": "16881288353859",
                        "chartRef": null,
                        "indicatorCode": "core_access_fe_trav_time_ptwalk",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Travel time to nearest Further Education Institution by public transport/walk",
                        "source": "Department for Transport (DfT)",
                        "date": "2019-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2519,
                        "indicatorHTMLTableRef": "16881288353859",
                        "chartRef": null,
                        "indicatorCode": "core_access_gp_trav_time_ptwalk",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Travel time to nearest GP by public transport/walk",
                        "source": "Department for Transport (DfT)",
                        "date": "2019-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2520,
                        "indicatorHTMLTableRef": "16881288353859",
                        "chartRef": null,
                        "indicatorCode": "core_access_hospital_trav_time_ptwalk",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Travel time to nearest Hospital by public transport/walk",
                        "source": "Department for Transport (DfT)",
                        "date": "2019-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2521,
                        "indicatorHTMLTableRef": "16881288353859",
                        "chartRef": null,
                        "indicatorCode": "core_access_primary_school_trav_time_ptwalk",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 6,
                        "y_label": null,
                        "indicatorName": "Travel time to nearest Primary School by public transport/walk",
                        "source": "Department for Transport (DfT)",
                        "date": "2019-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2522,
                        "indicatorHTMLTableRef": "16881288353859",
                        "chartRef": null,
                        "indicatorCode": "core_access_secondary_school_trav_time_ptwalk",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 7,
                        "y_label": null,
                        "indicatorName": "Travel time to nearest Secondary School by public transport/walk",
                        "source": "Department for Transport (DfT)",
                        "date": "2019-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2523,
                        "indicatorHTMLTableRef": "16881288353859",
                        "chartRef": null,
                        "indicatorCode": "core_access_supermarket_trav_time_ptwalk",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 8,
                        "y_label": null,
                        "indicatorName": "Travel time to nearest supermarket by public transport/walk",
                        "source": "Department for Transport (DfT)",
                        "date": "2019-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2524,
                        "indicatorHTMLTableRef": "16881288353859",
                        "chartRef": null,
                        "indicatorCode": "core_access_town_trav_time_ptwalk",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 9,
                        "y_label": null,
                        "indicatorName": "Travel time to nearest town centre by public transport/walk",
                        "source": "Department for Transport (DfT)",
                        "date": "2019-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2019",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 510,
                "reportThemeID": 10,
                "text": "<blockquote><h3>IoD Geographical Barriers</h3></blockquote><p></p><p>The bar chart below shows the geographical barriers sub-domain rank from the Indices of Deprivation, where larger scores and higher ranks correspond to more deprived areas. This sub-domain relates to the physical proximity of local services where the score is derived from the road distances to a post office, primary school, general store or supermarket, and to a GP surgery.</p><p>In 2019, Brighton and Hove was ranked 23,485. This is similar to the rank it received in 2015 (24,328). For comparison, England in 2019 was ranked 16,490 which is similar to the rank it received in 2015 (16,491).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 511,
                "reportThemeID": 10,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881289721165",
                "chartDescription": "<p>Indices of Deprivation (geographical barriers) Rank</p>",
                "chartXLabel": null,
                "chartYLabel": "Rank",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": false,
                "itemName": "IoD Geographical Barriers",
                "itemSource": "Ministry of Housing Communities and Local Government (MHCLG)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2694,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881289721165",
                        "indicatorCode": "bargeo19_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 642,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Geographical Barriers Sub-domain Rank",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2525,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881289721165",
                        "indicatorCode": "bargeo15_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 795,
                        "y_label": null,
                        "indicatorName": "IoD 2015 Geographical Barriers Sub-domain Rank",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2015-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2015",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 512,
                "reportThemeID": 10,
                "text": "<blockquote><h3>IoD road distance to key services</h3></blockquote><p></p><p>The bar chart below provides a further breakdown of the Indices of Deprivation geographical sub domain. It displays road distances to key services where each indicator is defined as an average road distance to the relevant key service, measured in kilometres and calculated initially at Output Area level. Greater distances can often indicate that an area is experiencing higher levels of deprivation through greater challenges in accessing key services.</p><p>The average road distance to a GP surgery in&nbsp;Brighton and Hove is 0.800 km. This is shorter than the average distance in England (1.304 km) and shorter than the average distance in South East (1.434 km).</p><p>The average road distance to a general store or supermarket in Brighton and Hove is 0.377 km. This is shorter than the average distance in England (0.711 km) and shorter than the average distance in South East (0.774 km).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 513,
                "reportThemeID": 10,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881290521891",
                "chartDescription": "<p>Road distance to key services</p>",
                "chartXLabel": null,
                "chartYLabel": "Distance (km)",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": "Road distance to key services",
                "itemSource": "Ministry of Housing Communities and Local Government (MHCLG)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2527,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881290521891",
                        "indicatorCode": "id19_road_food",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 787,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Road distance to general store or supermarket indicator (km)",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2018-01-01",
                        "decimalPlaces": 3,
                        "dateCoverageText": "2018",
                        "shortName": null
                    },
                    {
                        "id": 2528,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881290521891",
                        "indicatorCode": "id19_road_gp",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 792,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Road distance to a GP surgery indicator (km)",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 3,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2529,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881290521891",
                        "indicatorCode": "id19_road_po",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 793,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Road distance to a post office indicator (km)",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2018-01-01",
                        "decimalPlaces": 3,
                        "dateCoverageText": "2018",
                        "shortName": null
                    },
                    {
                        "id": 2530,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881290521891",
                        "indicatorCode": "id19_road_primary",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 794,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Road distance to a primary school indicator (km)",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 3,
                        "dateCoverageText": "2019",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 514,
                "reportThemeID": 10,
                "text": "<h3>Jobs Access score</h3><p></p><p>The bar chart below shows the overall Jobs Access score. This is a measure of connectivity developed by <a target=\"_blank\" rel=\"noopener noreferrer nofollow\" href=\"https://www.ukonward.com\">UK Onward</a> and includes the number of jobs accessible by car and public transport across 15 minutes, 30 minutes, 60 minutes and 90 minutes timespans. It also incorporates a “door-to-workplace” measure, including every journey stage from time spent walking to the car, driving, to parking and walking to an office - as well as average delays, timetabling and actual journey time on public transport. Together these measures form an overall Jobs Access score, the weighted average job count, combining driving and public transport. A higher score indicates greater levels of job accessibility.</p><p></p><p>In Brighton and Hove, the Jobs Access score is 281,448.78. This is lower than England (698,519.47) and lower than South East (620,634.19).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 10,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 515,
                "reportThemeID": 10,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881291367581",
                "chartDescription": "<p>UK Onward - Jobs access score</p>",
                "chartXLabel": null,
                "chartYLabel": "Score",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 11,
                "timeSeries": false,
                "itemName": "Jobs access score",
                "itemSource": "UK Onward",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2531,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881291367581",
                        "indicatorCode": "jobs_access_score",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 800,
                        "y_label": null,
                        "indicatorName": "Job access score",
                        "source": "UK Onward",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 516,
                "reportThemeID": 10,
                "text": "<blockquote><h3>Priority Places for Food Index</h3></blockquote><p></p><p>The bar chart below shows the ranks given in the Priority Places for Food Index, where a lower rank indicates higher food insecurity. This is a composite index formed of data compiled across seven different dimensions relating to food insecurity. It is constructed using open data to capture complex and multidimensional aspects of food insecurity and was developed in response to the 2022 cost of living crisis which has seen many communities under severe financial pressure and at an increased risk of food insecurity.</p><p>Brighton and Hove has a Priority Places for Food Index rank of 22,896. This means that Brighton and Hove has lower levels of food insecurity than England (16,898) and lower levels of food insecurity than South East (20,764).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 12,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 517,
                "reportThemeID": 10,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881297739492",
                "chartDescription": "<p>Priority Places for Food Index (Rank)</p>",
                "chartXLabel": null,
                "chartYLabel": "Rank",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 13,
                "timeSeries": false,
                "itemName": "Priority Places for Food Index rank",
                "itemSource": "Consumer Data Research Centre (CDRC)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2532,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881297739492",
                        "indicatorCode": "p_p_food_index_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 790,
                        "y_label": null,
                        "indicatorName": "Priority Places for Food Index (Rank)",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-07-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 2351,
                "reportThemeID": 10,
                "text": "<p>The bar chart below gives further insight into the Priority Places for Food Index, showing how Brighton and Hove, England and South East rank on each of the seven sub-domains. Again, a lower rank indicates higher insecurity:</p><ul><li><p>Ecommerce Access - includes online groceries availability and propensity to shop online.</p></li><li><p>Fuel Poverty - includes the proportion of households in fuel poverty and prepayment meter prevalence.</p></li><li><p>Food for Families - includes children in relative low-income families, healthy start voucher uptake and distance to the nearest food bank.</p></li><li><p>Non-Supermarket Proximity - includes distance to nearest non-supermarket retail food store, count of non-supermarket retail food stores within 1km, average distance to nearest market and average count of markets within 1km.</p></li><li><p>Supermarket Proximity - includes the average distance to nearest large grocery store and the average count of stores within 1km.</p></li><li><p>Supermarket Accessibility - includes the average travel distance to nearest large grocery store and accessibility via public transport.</p></li><li><p>Socio-Demographic Barriers - includes the proportion of the population experiencing income deprivation and the proportion of the population with no car access.</p></li></ul><p>Brighton and Hove has a Food for Families sub-domain rank of 18,428. This means that Brighton and Hove has lower need for family food support than England (16,853) and higher need than South East (21,448).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 14,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 2352,
                "reportThemeID": 10,
                "text": null,
                "chartType": "Bar",
                "chartRef": "17078201401553",
                "chartDescription": "<p>Priority Places for Food Index Sub-Domains</p>",
                "chartXLabel": null,
                "chartYLabel": "Rank",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 15,
                "timeSeries": false,
                "itemName": "Priority Places for Food Index Sub-Domains",
                "itemSource": "Consumer Data Research Centre (CDRC)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 6783,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "17078201401553",
                        "indicatorCode": "ppfood_domain_ecommerce_access_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 137,
                        "y_label": null,
                        "indicatorName": "PPFI Ecommerce Access Domain (Rank)",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-07-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 6780,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "17078201401553",
                        "indicatorCode": "ppfood_domain_fuel_poverty_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 139,
                        "y_label": null,
                        "indicatorName": "PPFI Fuel Poverty Domain (Rank)",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-07-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 6781,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "17078201401553",
                        "indicatorCode": "ppfood_domain_food_for_families_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 140,
                        "y_label": null,
                        "indicatorName": "PPFI Food for Families Domain (Rank)",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-07-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 6786,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "17078201401553",
                        "indicatorCode": "ppfood_domain_nonsupermarket_proximity_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 141,
                        "y_label": null,
                        "indicatorName": "PPFI Non-Supermarket Proximity Domain (Rank)",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-07-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 6785,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "17078201401553",
                        "indicatorCode": "ppfood_domain_supermarket_proximity_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 142,
                        "y_label": null,
                        "indicatorName": "PPFI Supermarket Proximity Domain (Rank)",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-07-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 6784,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "17078201401553",
                        "indicatorCode": "ppfood_domain_supermarket_accessibility_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 144,
                        "y_label": null,
                        "indicatorName": "PPFI Supermarket Accessibility Domain (Rank)",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-07-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 6782,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "17078201401553",
                        "indicatorCode": "ppfood_domain_socio_demographic_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 145,
                        "y_label": null,
                        "indicatorName": "PPFI Socio-Demographic Barriers Domain (Rank)",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-07-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 518,
                "reportThemeID": 10,
                "text": "<blockquote><h3>Access to Health Assets and Hazards</h3></blockquote><p></p><p>AHAH (the index of ‘Access to Health Assets and Hazards’) is a multi-dimensional index developed by the CDRC for Great Britain measuring how ‘healthy’ neighbourhoods are. This open data resource provides a valuable set of measures for researchers and policy makers with an interest in measuring the role of spatial features on health.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 16,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 519,
                "reportThemeID": 10,
                "text": "<p>The bar chart below shows the AHAH access to health assets, displaying the mean distance (in minutes) to five key health assets.</p><p>In Brighton and Hove the average distance to an A&E hospital is 1.40 minutes. This is shorter than the average distance in England (2.79 minutes) and shorter than the average distance in South East (2.99 minutes).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 17,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 520,
                "reportThemeID": 10,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881298541382",
                "chartDescription": "<p>Access to health assets (AHAH)</p>",
                "chartXLabel": null,
                "chartYLabel": "Minutes",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 18,
                "timeSeries": false,
                "itemName": "Access to health assets",
                "itemSource": "Consumer Data Research Centre (CDRC)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2533,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881298541382",
                        "indicatorCode": "ahah_leisure",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 797,
                        "y_label": null,
                        "indicatorName": "AHAH Leisure Centres",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 2534,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881298541382",
                        "indicatorCode": "ahah_dentists",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 798,
                        "y_label": null,
                        "indicatorName": "AHAH Dentists",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 2535,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881298541382",
                        "indicatorCode": "ahah_gp_surgeries",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 802,
                        "y_label": null,
                        "indicatorName": "AHAH GP surgeries",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 2536,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881298541382",
                        "indicatorCode": "ahah_hospitals",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 803,
                        "y_label": null,
                        "indicatorName": "AHAH A&E Hospitals",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 2537,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881298541382",
                        "indicatorCode": "ahah_pharmacies",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 806,
                        "y_label": null,
                        "indicatorName": "AHAH Pharmacies",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 521,
                "reportThemeID": 10,
                "text": "<p>The bar chart below shows the AHAH access to health hazards, displaying the distance (in minutes) to four key unhealthy retail offerings.</p><p>In Brighton and Hove the average distance to a tobacconist or vape store is 2.81 minutes. This is shorter than the average distance in England (5.21 minutes) and shorter than the average distance in South East (6.06 minutes).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 19,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 522,
                "reportThemeID": 10,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881299629392",
                "chartDescription": "<p>Access to health hazards (AHAH)</p>",
                "chartXLabel": null,
                "chartYLabel": "Minutes",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 20,
                "timeSeries": false,
                "itemName": "Access to health hazards",
                "itemSource": "Consumer Data Research Centre (CDRC)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": "C1",
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2538,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881299629392",
                        "indicatorCode": "ahah_fast_food",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 796,
                        "y_label": null,
                        "indicatorName": "AHAH Fast food outlets",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 2539,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881299629392",
                        "indicatorCode": "ahah_gambling",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 799,
                        "y_label": null,
                        "indicatorName": "AHAH Gambling outlets",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 2541,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881299629392",
                        "indicatorCode": "ahah_pubs_night_clubs",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 801,
                        "y_label": null,
                        "indicatorName": "AHAH Pubs/bars/nightclubs",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 2542,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881299629392",
                        "indicatorCode": "ahah_tobacconist",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 805,
                        "y_label": null,
                        "indicatorName": "AHAH Tobacconists and Vape Stores",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 92,
        "name": "Community",
        "slug": "community",
        "title": "Community",
        "description": "<p>The following visualisations offer insight into various community measures. It includes data from the Community Needs Index, as well as measures of funding, voter turnout, charities, community & civic participation, neighbourhood cohesion and community-owned assets. This selection of data together provides a comprehensive overview of the community landscape, emphasising the factors that are contributing to the community's need. </p></p>",
        "clientID": 1,
        "created_at": "2023-06-30T13:05:23.000000Z",
        "updated_at": "2023-12-06T09:34:32.000000Z",
        "use_for_summary": false,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 523,
                "reportThemeID": 92,
                "text": "<h3>Community Needs Index (CNI) rank</h3><p></p><p>The chart below shows the overall rank on the 2023 Community Needs Index (CNI), as well as a rank on each of the three CNI domains for Brighton and Hove, England and South East. The CNI was developed to identify areas experiencing poor community and civic infrastructure, relative isolation and low levels of participation in community life. The index was created by combining a series of 28 indicators, conceptualised under three domains: Civic Assets, Connectedness and Active and Engaged Community. A lower rank indicates that an area has relatively higher levels of need.</p><ul><li><p>Civic Assets: measures the presence of key community, civic, educational and cultural assets in a close proximity of the area. These include pubs, libraries, green space, community centres, swimming pools – facilities that provide things to do often, at no or little cost, which are important to how positive a community feels about its area.</p></li><li><p>Connectedness: measures the connectivity to key services, digital infrastructure, isolation and strength of the local jobs market. It looks at whether residents have access to key services, such as health services, within a reasonable travel distance. It considers how good public transport and digital infrastructure are and how strong the local job market is.</p></li><li><p>Active and Engaged Community: measures the levels of third sector civic and community activity and barriers to participation and engagement. It shows whether charities are active in the area, and whether people appear to be engaged in the broader civic life of their community.</p></li></ul><p>Brighton and Hove has an overall CNI rank of 22,368. This means that Brighton and Hove has lower levels of community need than England (17,040) and lower levels of community need than South East (17,783).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 524,
                "reportThemeID": 92,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881303763750",
                "chartDescription": "<p>CNI rank</p>",
                "chartXLabel": null,
                "chartYLabel": "Rank",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "CNI rank",
                "itemSource": "Oxford Consultants for Social Inclusion (OCSI) and Local Trust",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2543,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881303763750",
                        "indicatorCode": "community_needs_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 812,
                        "y_label": null,
                        "indicatorName": "Community Needs Index 2023: Community Needs rank",
                        "source": "Oxford Consultants for Social Inclusion (OCSI)",
                        "date": "2023-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 2546,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881303763750",
                        "indicatorCode": "active_community_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 819,
                        "y_label": null,
                        "indicatorName": "Community Needs Index 2023: Active and Engaged Community rank",
                        "source": "Oxford Consultants for Social Inclusion (OCSI) and Local Trust",
                        "date": "2023-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 2544,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881303763750",
                        "indicatorCode": "civic_assets_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 842,
                        "y_label": null,
                        "indicatorName": "Community Needs Index 2023: Civic Assets rank",
                        "source": "Oxford Consultants for Social Inclusion (OCSI)",
                        "date": "2023-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 2545,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881303763750",
                        "indicatorCode": "connectedness_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 880,
                        "y_label": null,
                        "indicatorName": "Community Needs Index 2023: Connectedness rank",
                        "source": "Oxford Consultants for Social Inclusion (OCSI)",
                        "date": "2023-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2023",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 525,
                "reportThemeID": 92,
                "text": "<blockquote><h3>Big Lottery funding</h3></blockquote><p></p><p>The table below looks at data on community funding, showing the combined total of grants made to local projects and organisations by the Big Lottery Fund between 2004 and 2021. Figures are presented as funding per 1,000 population.</p><p>Brighton and Hove has received £168,244.84 of funding per 1,000 population between 2004 and 2021. This is higher than the amount of funding per 1,000 across England (£37,984.08) and higher than the amount of funding per 1,000 across South East (£53,048.96).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 615,
                "reportThemeID": 92,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16892485083933",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": false,
                "itemName": "Big Lottery funding",
                "itemSource": "The National Lottery Community Fund",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2713,
                        "indicatorHTMLTableRef": "16892485083933",
                        "chartRef": null,
                        "indicatorCode": "biglottery_award_04_21_sum_exmill*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "The National Lottery Community Fund grant funding per 1,000 population (£) 2004-2021",
                        "source": "The National Lottery Community Fund",
                        "date": "2021-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 527,
                "reportThemeID": 92,
                "text": "<h3>Total grants from major grant funders</h3><p></p><p>The table below shows the total count of grants and the combined amount of grant funding per head from the largest national grant giving organisations whose data has been subject to the 360giving standard. The data is based on the location of grant recipients rather than the location of beneficiaries. The date of the grants awarded fall between 1991 and Oct-2024.</p><p>The total amount of grants issued from major grant funders per head in Brighton and Hove is £2,806.36. This is lower than the amount of funding per head across England (£3,101.07) and higher than the amount of funding per head across South East (£2,428.72).</p><p></p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 528,
                "reportThemeID": 92,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16881305226591",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Total grants from major grant funders",
                "itemSource": "360 Giving Grant",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2548,
                        "indicatorHTMLTableRef": "16881305226591",
                        "chartRef": null,
                        "indicatorCode": "grant_funding_count*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Total count of grants from major grant funders",
                        "source": "360 Giving",
                        "date": "2024-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2024",
                        "shortName": null
                    },
                    {
                        "id": 2549,
                        "indicatorHTMLTableRef": "16881305226591",
                        "chartRef": null,
                        "indicatorCode": "grant_funding_pounds*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Total amount awarded from major grant funders per head",
                        "source": "360 Giving",
                        "date": "2024-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 529,
                "reportThemeID": 92,
                "text": "<blockquote><h3>Voter turnout</h3></blockquote><p></p><p>The chart below shows the valid voter turnout (%) at the most recent Local Council Election. There is some local variation in the frequency and date of Local Elections, with different parts of the country going to the polls at different times and with different levels of regularity so caution should therefore be advised when drawing direct comparisons between local areas, as the socio-political context varies from year to year with associated impacts on turnout rates.</p><p>The average proportion of people who turned up to vote at the most recent Local Council Election was 57.56% across Brighton and Hove. This is higher than the average voter turnout across England (46.71%) and higher than the average voter turnout across South East (48.09%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 530,
                "reportThemeID": 92,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881306145114",
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": false,
                "itemName": "Voter turnout",
                "itemSource": "Electoral Commission",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2550,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881306145114",
                        "indicatorCode": "voter_turnout",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 835,
                        "y_label": null,
                        "indicatorName": "Voter Turnout at Local Elections",
                        "source": "Electoral Commission",
                        "date": "2022-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 531,
                "reportThemeID": 92,
                "text": "<blockquote><h3>Total registered charities</h3></blockquote><p></p><p>The table below shows the number of registered charities per 1,000 population. This is based on location of charities rather than areas where they operate, some of which will have a global focus.</p><p>Brighton and Hove has 2.85 charities per 1,000 population. This is similar to the number of charities per 1,000 across England (2.79) and similar to the number of charities per 1,000 across South East (2.92).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 532,
                "reportThemeID": 92,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16881306671569",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": "Total registered charities",
                "itemSource": "Charity Commission",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2692,
                        "indicatorHTMLTableRef": "16881306671569",
                        "chartRef": null,
                        "indicatorCode": "charities*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Total registered charities",
                        "source": "Charity Commission",
                        "date": "2021-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 533,
                "reportThemeID": 92,
                "text": "<blockquote><h3>Civic engagement</h3></blockquote><p></p><p>The chart below shows average self-reported levels of community and civic engagement. These measures are taken from the Community Life Survey and reflect whether individuals are working together in both political and non-political actions to protect public values or make a change in a community.</p><p>The proportion of people taking part in any civic engagement in Brighton and Hove is 49.79%. This is higher than the proportion in England (45.19%) and higher than the proportion in South East (45.94%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 10,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 535,
                "reportThemeID": 92,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881307353941",
                "chartDescription": "<p>Civic engagement</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 11,
                "timeSeries": false,
                "itemName": "Civic engagement",
                "itemSource": "Community Life Survey",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2555,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881307353941",
                        "indicatorCode": "civic_eng_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 634,
                        "y_label": null,
                        "indicatorName": "Percentage of people taking part in any civic engagement",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2556,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881307353941",
                        "indicatorCode": "cls_consult_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 817,
                        "y_label": null,
                        "indicatorName": "Not taken part in a consultation about local services or issues in your local area",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2557,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881307353941",
                        "indicatorCode": "cls_club_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 836,
                        "y_label": null,
                        "indicatorName": "Not taken part in community groups clubs or organisations",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2553,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881307353941",
                        "indicatorCode": "infl_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 843,
                        "y_label": null,
                        "indicatorName": "People who feel they can influence decision in their local area",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2554,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881307353941",
                        "indicatorCode": "volunteer_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 876,
                        "y_label": null,
                        "indicatorName": "Percentage of people engaged in formal or informal volunteering in the last month",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 536,
                "reportThemeID": 92,
                "text": "<blockquote><h3>Neighbourhood strength and cohesion</h3></blockquote><p></p><p>The chart below shows average levels of neighbourhood strength and cohesion across Brighton and Hove, England and South East. This reflects the residents' sense of community, engagement in acts of neighbouring, and attractiveness of living in the neighbourhood.</p><p>On average, 56.66% of residents in Brighton and Hove feel they do not belong very strongly to their neighbourhood. This is lower than the proportion in England (62.92%) and lower than the proportion in South East (63.54%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 12,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 537,
                "reportThemeID": 92,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881308455649",
                "chartDescription": "<p>Neighbourhood strength and cohesion</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 13,
                "timeSeries": false,
                "itemName": "Neighbourhood strength and cohesion",
                "itemSource": "Community Life Survey",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2559,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881308455649",
                        "indicatorCode": "cls_pull_neigh_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 492,
                        "y_label": null,
                        "indicatorName": "Disagree that: People in this neighbourhood pull together to improve the neighbourhood",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021 to 2022",
                        "shortName": null
                    },
                    {
                        "id": 2560,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881308455649",
                        "indicatorCode": "cls_keys_neigh_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 821,
                        "y_label": null,
                        "indicatorName": "Fairly or very uncomfortable with asking a neighbour to keep a set of keys to your home for emergencies",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021 to 2022",
                        "shortName": null
                    },
                    {
                        "id": 2563,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881308455649",
                        "indicatorCode": "cls_belong_neigh_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 822,
                        "y_label": null,
                        "indicatorName": "Do not feel belong very strongly to neighbourhood",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021 to 2022",
                        "shortName": null
                    },
                    {
                        "id": 2561,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881308455649",
                        "indicatorCode": "cls_chat_neigh_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 846,
                        "y_label": null,
                        "indicatorName": "Never chat to neighbours",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021 to 2022",
                        "shortName": null
                    },
                    {
                        "id": 2558,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881308455649",
                        "indicatorCode": "cls_shop_neigh_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 882,
                        "y_label": null,
                        "indicatorName": "Fairly or very uncomfortable with asking a neighbour to collect a few shopping essentials if you were ill and at home on your own",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021 to 2022",
                        "shortName": null
                    },
                    {
                        "id": 2562,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881308455649",
                        "indicatorCode": "cls_borrow_neigh_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 883,
                        "y_label": null,
                        "indicatorName": "Disagree that borrow things or exchange favours with neighbours",
                        "source": "Department for Culture Media and Sport (DCMS)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021 to 2022",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 538,
                "reportThemeID": 92,
                "text": "<blockquote><h3>Community owned assets</h3></blockquote><p></p><p>The chart below shows the number of community-owned assets per 100,000 population. This picks up assets inside or within 1km of the local area boundary. Figures are compiled using data from Power to Change, the Community Land Trust Network, Co-operatives UK, Plunkett Foundation, Locality, and Keep it in the Community.</p><p>Brighton and Hove has 1,285.15 community-owned assets per 100,000 population. This is more than the amount per 100,000 in England (755.87) and more than the amount per 100,000 in South East (607.80).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 14,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 539,
                "reportThemeID": 92,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881309576407",
                "chartDescription": "<p>Community owned assets</p>",
                "chartXLabel": null,
                "chartYLabel": "Assets per 100,000",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 15,
                "timeSeries": false,
                "itemName": "Community owned assets",
                "itemSource": "Power to Change, Community Land Trust Network, Co-operatives UK, Plunkett Foundation, Locality, Keep it in the Community.",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2564,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881309576407",
                        "indicatorCode": "ren_comm_assets*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 847,
                        "y_label": null,
                        "indicatorName": "Density of Community owned assets",
                        "source": "Power to Change, the Community Land Trust Network, Co-operatives UK, Plunkett Foundation, Locality and Keep it in the Community",
                        "date": "2023-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 94,
        "name": "Community (living environment)",
        "slug": "community-living-environment",
        "title": "Living Environment",
        "description": "<p>The following series of text and charts delves into the living environment of Brighton and Hove, giving insight into crucial factors such as the presence of green and blue spaces, air pollution levels, and flood risk. Together these indicators provide a deeper understanding of the area's environmental health, resilience, and potential for sustainable development.</p></p>",
        "clientID": 1,
        "created_at": "2023-06-30T13:34:23.000000Z",
        "updated_at": "2023-11-30T08:45:58.000000Z",
        "use_for_summary": true,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 556,
                "reportThemeID": 94,
                "text": "<blockquote><h3>Indices of Deprivation (IoD) Living Environment</h3></blockquote><p></p><p>The chart below shows the Indices of Deprivation (IoD) 2015 and 2019 Living Environment Deprivation domain ranks. These measure the quality of the local environment. The indicators fall into two sub-domains:</p><ul><li><p>The 'indoors' living environment measures the quality of housing and contains the following indicators: Houses without central heating: The proportion of houses that do not have central heating; Housing in poor condition: The proportion of social and private homes that fail to meet the Decent Homes standard.</p></li><li><p>The 'outdoors' living environment contains measures of air quality and road traffic accidents and contains the following indicators: Air quality: A measure of air quality based on emissions rates for four pollutants; Road traffic accidents involving injury to pedestrians and cyclists: A measure of road traffic accidents involving injury to pedestrians and cyclists among the resident and workplace population.</p></li></ul><p>Data shows the average LSOA rank where a lower rank indicates that an area is experiencing higher levels of deprivation.</p><p>Brighton and Hove’s average rank on the Living Environment domain in 2019 is 13,933. This is lower than England's rank (16,158) and lower than South East's rank (18,876).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 557,
                "reportThemeID": 94,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881321606995",
                "chartDescription": "<p>Living Environment rank (IoD)</p>",
                "chartXLabel": null,
                "chartYLabel": "Average rank",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "Living Environment rank (IoD)",
                "itemSource": "Ministry of Housing Communities and Local Government (MHCLG)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2600,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881321606995",
                        "indicatorCode": "liv15_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 644,
                        "y_label": null,
                        "indicatorName": "IoD 2015 Living Environment Rank",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2015-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2015",
                        "shortName": null
                    },
                    {
                        "id": 2601,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881321606995",
                        "indicatorCode": "liv19_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1124,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Living Environment Rank",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2602,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881321606995",
                        "indicatorCode": "indoor15_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1192,
                        "y_label": null,
                        "indicatorName": "IoD 2015 Indoors Sub-domain Rank",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2015-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2015",
                        "shortName": null
                    },
                    {
                        "id": 2603,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881321606995",
                        "indicatorCode": "indoor19_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1200,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Indoors Sub-domain Rank",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2604,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881321606995",
                        "indicatorCode": "outdoor15_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1211,
                        "y_label": null,
                        "indicatorName": "IoD 2015 Outdoors Sub-domain Rank",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2015-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2015",
                        "shortName": null
                    },
                    {
                        "id": 2605,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881321606995",
                        "indicatorCode": "outdoor19_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1220,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Outdoors Sub-domain Rank",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2019",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 558,
                "reportThemeID": 94,
                "text": "<h3>Green spaces</h3><p></p><p>The table below looks at various measures of green space, including households with access to green space, total green space coverage (including allotments or community growing spaces, bowling greens, cemeteries, religious grounds, golf courses, other sports facilities, play spaces, playing fields, public parks or gardens and tennis courts), the average distance & number of parks, public gardens & playing fields, addresses with private outdoor space, as well as tree cover (the percentage of tree canopy cover).</p><p>On average, 24.85% of households in Brighton and Hove have access to Green Space This is higher than England where (23.04%) of households have Green Space access, and higher than South East where (22.87%) of households have Green Space access.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 559,
                "reportThemeID": 94,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16881330146845",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": false,
                "itemName": "Green spaces",
                "itemSource": "Ordnance Survey/Friends of the Earth/DEFRA",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 7146,
                        "indicatorHTMLTableRef": "16881330146845",
                        "chartRef": null,
                        "indicatorCode": "atgs_england*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Percentage of households with access to Green Space",
                        "source": "Department for Environment Food & Rural Affairs (DEFRA)",
                        "date": "2024-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    },
                    {
                        "id": 2611,
                        "indicatorHTMLTableRef": "16881330146845",
                        "chartRef": null,
                        "indicatorCode": "tree_cover",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Tree cover",
                        "source": "Friends of the Earth",
                        "date": "2022-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2022",
                        "shortName": null
                    },
                    {
                        "id": 2608,
                        "indicatorHTMLTableRef": "16881330146845",
                        "chartRef": null,
                        "indicatorCode": "private_outdoor_space*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Addresses with private outdoor space",
                        "source": "Ordnance Survey",
                        "date": "2020-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2020",
                        "shortName": null
                    },
                    {
                        "id": 2606,
                        "indicatorHTMLTableRef": "16881330146845",
                        "chartRef": null,
                        "indicatorCode": "access_park_playingfield",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Average distance to nearest Park, Public Garden, or Playing Field (m)",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2020-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2020",
                        "shortName": null
                    },
                    {
                        "id": 2607,
                        "indicatorHTMLTableRef": "16881330146845",
                        "chartRef": null,
                        "indicatorCode": "num_park_av",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Average number of Parks, Public Gardens, or Playing Fields within 1,000 m radius",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2020-04-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "Apr-2020",
                        "shortName": null
                    },
                    {
                        "id": 2609,
                        "indicatorHTMLTableRef": "16881330146845",
                        "chartRef": null,
                        "indicatorCode": "os_greenspace_total*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 6,
                        "y_label": null,
                        "indicatorName": "Greenspace coverage, total",
                        "source": "Ordnance Survey (OS)",
                        "date": "2017-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2610,
                        "indicatorHTMLTableRef": "16881330146845",
                        "chartRef": null,
                        "indicatorCode": "os_greenspace_parks_gardens*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 7,
                        "y_label": null,
                        "indicatorName": "Greenspace coverage, public parks and gardens",
                        "source": "Ordnance Survey (OS)",
                        "date": "2017-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 560,
                "reportThemeID": 94,
                "text": "<h3>Passive Green & Blue spaces (AHAH index)</h3><p></p><p>The charts below show first the drive-time accessibility to Blue spaces followed by the NDVI value indicating Passive Green Space. These indicators are an input measure for the Access to Healthy Assets and Hazards (AHAH) index as part of the Physical Environment domain. The AHAH index is a multi-dimensional index developed by the CDRC for Great Britain measuring how 'healthy' neighbourhoods are. Blue spaces are water features that can be positive amenities. This indicator is based on the distance people need to travel to access their nearest water body such as a beach, a lake and a river.</p><p></p><p>The average drive-time to a blue space in Brighton and Hove is 3.69 minutes. This is similar to the average distance in England (4.02 minutes) and similar to the average distance in South East (4.37 minutes).</p><p></p><p>The NDVI value for Passive Green space in Brighton and Hove is 0.36. This is similar to the Passive Green space value for England (0.42) and similar to the Passive Green space value for South East (0.47)</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 561,
                "reportThemeID": 94,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881331511060",
                "chartDescription": "Blue spaces (AHAH index)",
                "chartXLabel": null,
                "chartYLabel": "Distance (minutes)",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Blue spaces (AHAH index)",
                "itemSource": "Open Street Map",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2614,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881331511060",
                        "indicatorCode": "ahah_blue_space",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 31,
                        "y_label": null,
                        "indicatorName": "AHAH Blue Space",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 2483,
                "reportThemeID": 94,
                "text": null,
                "chartType": "Bar",
                "chartRef": "17247544284919",
                "chartDescription": "Green Spaces (AHAH index)",
                "chartXLabel": null,
                "chartYLabel": "NDVI Value",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": "Green Spaces (AHAH index)",
                "itemSource": "Sentinel Satellite",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 7147,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "17247544284919",
                        "indicatorCode": "ahah_green_space_passive",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 30,
                        "y_label": null,
                        "indicatorName": "AHAH Green Space (passive)",
                        "source": "Consumer Data Research Centre (CDRC)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 562,
                "reportThemeID": 94,
                "text": "<blockquote><h3>Indices of Deprivation (IoD) Air quality</h3></blockquote><p></p><p>The chart below shows the four components of the Indices of Deprivation (IoD) 2019 Air quality indicator in Brighton and Hove, England and South East, estimating the concentration of the four pollutants: nitrogen dioxide, benzene, sulphur dioxide and particulates. A higher score indicates that an area is experiencing high levels of deprivation.</p><p>Brighton and Hove's estimated concentration of nitrogen dioxide is 0.417. This is similar to the concentration in England (0.430) and similar to the concentration in South East (0.397).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 563,
                "reportThemeID": 94,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881332196445",
                "chartDescription": "<p>Air quality (IoD)</p>",
                "chartXLabel": null,
                "chartYLabel": "Score",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": "Indices of Deprivation (IoD) Air quality",
                "itemSource": "Ministry of Housing Communities and Local Government (MHCLG)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2617,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881332196445",
                        "indicatorCode": "id19_airqualitynitrogennioxide",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 825,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Nitrogen dioxide (component of air quality indicator)",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2016-01-01",
                        "decimalPlaces": 3,
                        "dateCoverageText": "2016",
                        "shortName": null
                    },
                    {
                        "id": 2615,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881332196445",
                        "indicatorCode": "id19_airquality_benzene",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1145,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Benzene (component of air quality indicator)",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2016-01-01",
                        "decimalPlaces": 3,
                        "dateCoverageText": "2016",
                        "shortName": null
                    },
                    {
                        "id": 2616,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881332196445",
                        "indicatorCode": "id19_airquality_sulphurdioxide",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1166,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Sulphur dioxide (component of air quality indicator)",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2016-01-01",
                        "decimalPlaces": 3,
                        "dateCoverageText": "2016",
                        "shortName": null
                    },
                    {
                        "id": 2618,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881332196445",
                        "indicatorCode": "id19_airqualityparticulates",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1185,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Particulates (component of air quality indicator)",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2016-01-01",
                        "decimalPlaces": 3,
                        "dateCoverageText": "2016",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 564,
                "reportThemeID": 94,
                "text": "<blockquote><h3>Neighbourhood Flood Vulnerability Index (NFVI)</h3></blockquote><p></p><p>The table below shows the Neighbourhood Flood Vulnerability Index (NFVI) scores across Brighton and Hove, England and South East. The NFVI measures social vulnerability and flood risk by combining five characteristics of vulnerability: susceptibility, ability to prepare, ability to respond, ability to recover and community support. A higher NFVI score indicates higher social vulnerability.</p><p>Brighton and Hove has a NFVI score of -0.34. This indicates lower flood vulnerability to England (-0.06) and similar flood vulnerability to South East (-0.38).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 718,
                "reportThemeID": 94,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16933868844230",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 10,
                "timeSeries": false,
                "itemName": "Neighbourhood Flood Vulnerability Index (NFVI)",
                "itemSource": "Climate UK",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2918,
                        "indicatorHTMLTableRef": "16933868844230",
                        "chartRef": null,
                        "indicatorCode": "nfvi",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Neighbourhood Flood Vulnerability Index (NFVI)",
                        "source": "Sayers, P.B., Horritt, M., Penning Rowsell, E., and Fieth, J. (2017). Present and future flood vulnerability, risk and disadvantage: A UK scale assessment.",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 129,
        "name": "Crime",
        "slug": "crime",
        "title": "Crime",
        "description": "<p>The following data provides insight into the recorded crime rates in an area. The data is created using the rolling 12 month total of neighbourhood-level incidents for various types of offence as a rate per 1,000 residents. The incidents were located to the point at which they occurred and allocated to the appropriate lower super output area (LSOA). This is based on data for individual crime incidents published via the <a href=\"https://www.police.uk\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">Police UK open data portal</a>, which has been linked by Local Insight to selected neighbourhoods.</p><p>Please note there can be missing data in Police.uk crime counts for certain areas. This is often due to issues with IT system changes at various police forces such as Devon and Cornwall, and Greater Manchester currently, which affects their reporting of figures to Police UK. Please see <a href=\"https://data.police.uk/changelog/\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">Police UK's change log</a> for details of current known issues.</p></p>",
        "clientID": 1,
        "created_at": "2023-08-31T09:29:05.000000Z",
        "updated_at": "2024-01-29T17:45:31.000000Z",
        "use_for_summary": false,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 722,
                "reportThemeID": 129,
                "text": "<h3>Crime and anti-social behaviour incident summary</h3><p></p><p>The table below shows counts and rates for the main crime types and anti-social behaviour incidents between Sep-2023 to Aug-2024.</p><p>There were 37,727 total crime offences in Brighton and Hove between Sep-2023 to Aug-2024, with the overall crime rate being 135.7 per 1,000 population. This is higher than the average across England (88.7) and higher than the average across South East (84.9).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 1010,
                "reportThemeID": 129,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16959732289390",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "Crime and anti-social behaviour incident summary",
                "itemSource": "Police UK",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 3683,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3684,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "bicycle_theft_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Bicycle theft offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3685,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3686,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3687,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "drugs_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Drug crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3688,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "other_crime_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 6,
                        "y_label": null,
                        "indicatorName": "Other crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3689,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "other_theft_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 7,
                        "y_label": null,
                        "indicatorName": "Other theft offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3690,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "possession_of_weapons_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 8,
                        "y_label": null,
                        "indicatorName": "Possession of weapons offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3691,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "public_order_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 9,
                        "y_label": null,
                        "indicatorName": "Public order offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3692,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "robbery_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 10,
                        "y_label": null,
                        "indicatorName": "Robbery recorded offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3693,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "shoplifting_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 11,
                        "y_label": null,
                        "indicatorName": "Shoplifting offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3694,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "theft_from_the_person_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 12,
                        "y_label": null,
                        "indicatorName": "Theft from the person offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3695,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "vehicle_crime_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 13,
                        "y_label": null,
                        "indicatorName": "Vehicle crime (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    },
                    {
                        "id": 3696,
                        "indicatorHTMLTableRef": "16959732289390",
                        "chartRef": null,
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 14,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 721,
                "reportThemeID": 129,
                "text": "<h3>Total crime rate (time series)</h3><p></p><p>The line chart below shows a time series made up of 12 month totals of neighbourhood-level incidents of criminal offences. The figure is a rate per 1,000 residents. The first data point covers Sep-2018 to Aug-2019 and the final data point covers Sep-2023 to Aug-2024.</p><p>The overall crime rate in Brighton and Hove has increased from 121.5 per 1,000 people between Sep-2018 to Aug-2019 to 135.7 per 1,000 people between Sep-2023 to Aug-2024.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 720,
                "reportThemeID": 129,
                "text": null,
                "chartType": "Line",
                "chartRef": "16819170424177129",
                "chartDescription": "<p>12 month total crime rate (time series)</p>",
                "chartXLabel": null,
                "chartYLabel": "Crimes per 1,000 population",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": true,
                "itemName": "Total crime rate (time series)",
                "itemSource": "Police UK",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2018-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2018 to Aug-2019",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2018-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2018 to Nov-2019",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2019-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2019 to Feb-2020",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2019-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2019 to May-2020",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2019-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2019 to Aug-2020",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2019-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2019 to Nov-2020",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2020-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2020 to Feb-2021",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2020-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2020 to May-2021",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2020-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2020 to Aug-2021",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2020-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2020 to Nov-2021",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2021-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2021 to Feb-2022",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2021-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2021 to May-2022",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2021-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2021 to Aug-2022",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2021-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2021 to Nov-2022",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2022-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2022 to Feb-2023",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2022-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2022 to May-2023",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2022-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2022 to Aug-2023",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2022-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2022 to Nov-2023",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2023 to Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2023 to May-2024",
                        "shortName": null
                    },
                    {
                        "id": 2942,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16819170424177129",
                        "indicatorCode": "total_crime_police_uk_12_month*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 700,
                        "y_label": null,
                        "indicatorName": "Total crime offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 724,
                "reportThemeID": 129,
                "text": "<h3>Anti-social behaviour (time series)</h3><p></p><p>The line chart below shows a time series made up of 12 month totals of neighbourhood-level incidents of anti-social behaviour offences. The figure is a rate per 1,000 residents. The first data point covers Sep-2018 to Aug-2019 and the final data point covers Sep-2023 to Aug-2024.</p><p>The rate in Brighton and Hove has decreased from 25.4 per 1,000 people between Sep-2018 to Aug-2019 to 20.7 per 1,000 people between Sep-2023 to Aug-2024.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 723,
                "reportThemeID": 129,
                "text": null,
                "chartType": "Line",
                "chartRef": "16934747361403",
                "chartDescription": "<p>Anti-social behaviour</p>",
                "chartXLabel": null,
                "chartYLabel": "Rate",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": true,
                "itemName": "Anti-social behaviour (time series)",
                "itemSource": "Police UK",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2018-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2018 to Aug-2019",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2018-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2018 to Nov-2019",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2019-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2019 to Feb-2020",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2019-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2019 to May-2020",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2019-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2019 to Aug-2020",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2019-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2019 to Nov-2020",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2020-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2020 to Feb-2021",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2020-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2020 to May-2021",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2020-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2020 to Aug-2021",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2020-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2020 to Nov-2021",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2021-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2021 to Feb-2022",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2021-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2021 to May-2022",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2021-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2021 to Aug-2022",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2021-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2021 to Nov-2022",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2022-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2022 to Feb-2023",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2022-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2022 to May-2023",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2022-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2022 to Aug-2023",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2022-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2022 to Nov-2023",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2023-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2023 to Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2023-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2023 to May-2024",
                        "shortName": null
                    },
                    {
                        "id": 2945,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934747361403",
                        "indicatorCode": "anti_social_behaviour_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-01-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 778,
                        "y_label": null,
                        "indicatorName": "Anti-social behaviour (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 725,
                "reportThemeID": 129,
                "text": "<h3>Violence and sexual offences (time series)</h3><p></p><p>The line chart below shows a time series made up of 12 month totals of neighbourhood-level incidents of violence and sexual offences. The figure is a rate per 1,000 residents. The first data point covers Sep-2018 to Aug-2019 and the final data point covers Sep-2023 to Aug-2024.</p><p>The rate in Brighton and Hove has increased from 33.0 per 1,000 people between Sep-2018 to Aug-2019 to 41.4 per 1,000 people between Sep-2023 to Aug-2024.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 726,
                "reportThemeID": 129,
                "text": null,
                "chartType": "Line",
                "chartRef": "16934761314374",
                "chartDescription": "<p>Violence and sexual offences</p>",
                "chartXLabel": null,
                "chartYLabel": "Rate",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": true,
                "itemName": "Violence and sexual offences",
                "itemSource": "Police UK",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2018-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2018 to Aug-2019",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2018-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2018 to Nov-2019",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2019-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2019 to Feb-2020",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2019-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2019 to May-2020",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2019-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2019 to Aug-2020",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2019-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2019 to Nov-2020",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2020-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2020 to Feb-2021",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2020-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2020 to May-2021",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2020-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2020 to Aug-2021",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2020-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2020 to Nov-2021",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2021-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2021 to Feb-2022",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2021-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2021 to May-2022",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2021-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2021 to Aug-2022",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2021-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2021 to Nov-2022",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2022-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2022 to Feb-2023",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2022-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2022 to May-2023",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2022-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2022 to Aug-2023",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2022-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2022 to Nov-2023",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2023 to Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2023 to May-2024",
                        "shortName": null
                    },
                    {
                        "id": 2946,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934761314374",
                        "indicatorCode": "violence_and_sexual_offences_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 720,
                        "y_label": null,
                        "indicatorName": "Violent crime and sexual offences (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 727,
                "reportThemeID": 129,
                "text": "<h3>Burglary (time series)</h3><p></p><p>The line chart below shows a time series made up of 12 month totals of neighbourhood-level incidents of burglary offences. The figure is a rate per 1,000 residents. The first data point covers Mar-2020 to Feb-2021 and the final data point covers Sep-2023 to Aug-2024.</p><p>The rate in Brighton and Hove has increased from 8.7 per 1,000 people between Mar-2020 to Feb-2021 to 9.0 per 1,000 people between Sep-2023 to Aug-2024.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 728,
                "reportThemeID": 129,
                "text": null,
                "chartType": "Line",
                "chartRef": "16934766247871",
                "chartDescription": "<p>Burglary</p>",
                "chartXLabel": null,
                "chartYLabel": "Rate",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": true,
                "itemName": "Burglary",
                "itemSource": "Police UK",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2018-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2018 to Aug-2019",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2018-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2018 to Nov-2019",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2019-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2019 to Feb-2020",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2019-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2019 to May-2020",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2019-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2019 to Aug-2020",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2019-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2019 to Nov-2020",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2020-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2020 to Feb-2021",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2020-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2020 to May-2021",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2020-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2020 to Aug-2021",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2020-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2020 to Nov-2021",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2021-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2021 to Feb-2022",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2021-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2021 to May-2022",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2021-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2021 to Aug-2022",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2021-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2021 to Nov-2022",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2022-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2022 to Feb-2023",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2022-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2022 to May-2023",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2022-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2022 to Aug-2023",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2022-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2022 to Nov-2023",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2023-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2023 to Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2023-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2023 to May-2024",
                        "shortName": null
                    },
                    {
                        "id": 2947,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934766247871",
                        "indicatorCode": "burglary_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 722,
                        "y_label": null,
                        "indicatorName": "Burglary (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 729,
                "reportThemeID": 129,
                "text": "<h3>Criminal damage and arson (time series)</h3><p></p><p>The line chart below shows a time series made up of 12 month totals of neighbourhood-level incidents of criminal damage and arson offences. The figure is a rate per 1,000 residents. The first data point covers Sep-2018 to Aug-2019 and the final data point covers Sep-2023 to Aug-2024.</p><p>The rate in Brighton and Hove has decreased from 9.5 per 1,000 people between Sep-2018 to Aug-2019 to 9.2 per 1,000 people between Sep-2023 to Aug-2024.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 10,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 730,
                "reportThemeID": 129,
                "text": null,
                "chartType": "Line",
                "chartRef": "16934767684079",
                "chartDescription": "<p>Criminal damage and arson</p>",
                "chartXLabel": null,
                "chartYLabel": "Rate",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 11,
                "timeSeries": true,
                "itemName": "Criminal damage and arson",
                "itemSource": "Police UK",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2018-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2018 to Aug-2019",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2018-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2018 to Nov-2019",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2019-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2019 to Feb-2020",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2019-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2019 to May-2020",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2019-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2019 to Aug-2020",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2019-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2019 to Nov-2020",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2020-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2020 to Feb-2021",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2020-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2020 to May-2021",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2020-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2020 to Aug-2021",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2020-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2020 to Nov-2021",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2021-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2021 to Feb-2022",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2021-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2021 to May-2022",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2021-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2021 to Aug-2022",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2021-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2021 to Nov-2022",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2022-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2022 to Feb-2023",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2022-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2022 to May-2023",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2022-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2022 to Aug-2023",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2022-12-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Dec-2022 to Nov-2023",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2023-03-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Mar-2023 to Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2023-06-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Jun-2023 to May-2024",
                        "shortName": null
                    },
                    {
                        "id": 2949,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16934767684079",
                        "indicatorCode": "criminal_damage_and_arson_police_uk_12_month*dr",
                        "indicatorDate": "2018-08-01,2040-08-01",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 749,
                        "y_label": null,
                        "indicatorName": "Criminal damage (12 month total)",
                        "source": "Police UK",
                        "date": "2023-09-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "Sep-2023 to Aug-2024",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 91,
        "name": "Demographics (older people)",
        "slug": "demographics-older-people",
        "title": "Demographics (Older People)",
        "description": "<p>The following data visualisations focus on providing insight into the characteristics of the older population within Brighton and Hove. By analysing demographic data relating to individuals aged 65 and above, as well as insights into pension credit claimants and vulnerable pensioner households, this selection of indicators shed light on the challenges and opportunities associated with the older population.</p></p>",
        "clientID": 1,
        "created_at": "2023-06-30T11:56:56.000000Z",
        "updated_at": "2024-04-09T10:19:34.000000Z",
        "use_for_summary": true,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 495,
                "reportThemeID": 91,
                "text": "<blockquote><h3>Population aged 65+</h3></blockquote><p></p><p>The chart below shows the proportion of the total population in Brighton and Hove, England and South East who are aged 65+, broken down by 5 year age bands and self-reported from the 2021 Census.</p><p>The overall proportion of people aged 65+ in Brighton and Hove is 14.07%. This is lower than the proportion of people aged 65+ in England (18.41%) and lower than the proportion of those aged 65+ in South East (19.45%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 496,
                "reportThemeID": 91,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881262968753",
                "chartDescription": "<p>Population aged 65+</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "Population aged 65+",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2485,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881262968753",
                        "indicatorCode": "census11_p04_65_69*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 889,
                        "y_label": null,
                        "indicatorName": "People aged 65-69 (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2486,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881262968753",
                        "indicatorCode": "census11_p04_70_74*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 890,
                        "y_label": null,
                        "indicatorName": "People aged 70-74 (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2487,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881262968753",
                        "indicatorCode": "census11_p04_75_79*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 891,
                        "y_label": null,
                        "indicatorName": "People aged 75-79 (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2488,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881262968753",
                        "indicatorCode": "census11_p04_80_84*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 892,
                        "y_label": null,
                        "indicatorName": "People aged 80-84 (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2489,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881262968753",
                        "indicatorCode": "census11_p04_85_89*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 893,
                        "y_label": null,
                        "indicatorName": "People aged 85-89 (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2490,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881262968753",
                        "indicatorCode": "census11_p04_90pl*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 894,
                        "y_label": null,
                        "indicatorName": "People aged 90 and over (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 497,
                "reportThemeID": 91,
                "text": "<blockquote><h3>Change in population aged 65+</h3></blockquote><p></p><p>The chart below shows the change in the percentage of people aged 65+ between 2001 and 2020 in Brighton and Hove, England and South East. These population figures are taken from the Office for National Statistics (ONS) Mid Year Estimates and are presented as a proportion of the total population.</p><p>In Brighton and Hove the proportion of the population who are aged 65+ has decreased since 2001, changing from 16.1% to 13.37%. This is lower than the proportion in England (18.50%) and lower than the proportion in South East (19.70%) in 2020.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 498,
                "reportThemeID": 91,
                "text": null,
                "chartType": "Line",
                "chartRef": "16881265508246",
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": true,
                "itemName": "Change in population aged 65+",
                "itemSource": "Office for National Statistics (ONS)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2001-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2001",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2002-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2002",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2003-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2003",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2004-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2004",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2005-01-01",
                        "decimalPlaces": 1,
                        "dateCoverageText": "2005",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2006-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2006",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2007-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2007",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2008-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2008",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2009-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2009",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2010-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2010",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2011-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2011",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2012-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2012",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2013-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2013",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2014-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2014",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2015-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2015",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2018-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2018",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 2491,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881265508246",
                        "indicatorCode": "mye_census11revision_popest5yr_aged_65pl*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 772,
                        "y_label": null,
                        "indicatorName": "Population aged 65+",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2020-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2020",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 499,
                "reportThemeID": 91,
                "text": "<blockquote><h3>Pension Credit claimants</h3></blockquote><p></p><p>The table below looks at the number and proportion of pensioners in poverty in Brighton and Hove, England, and South East. Pensioners in poverty are those in receipt of Pension Credit. Pension Credit provides financial help for people aged 60 or over whose income is below a certain level set by the law. The table breaks down Pension Credit claimants by sex, credit element and relationship status. There are two parts to Pension Credit: Guarantee element (which tops up a weekly income to a minimum amount) and Savings element (a small top-up for people who have a modest amount of income or savings).</p><p>5,786 people in Brighton and Hove are in receipt of Pension Credit. Of these, 3,537 are female and 2,248 are male. The proportion of those aged 65+ receiving Pension Credit in Brighton and Hove (14.64%) is higher than the proportion of claimants in England (10.85%) and higher than the proportion of claimants in South East (8.00%).</p><p>In Brighton and Hove, a higher proportion of Pension Credit claimants are single (13.33%) compared to those who have a partner (1.33%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 500,
                "reportThemeID": 91,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16881269343462",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Pension Credit claimants",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2492,
                        "indicatorHTMLTableRef": "16881269343462",
                        "chartRef": null,
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2493,
                        "indicatorHTMLTableRef": "16881269343462",
                        "chartRef": null,
                        "indicatorCode": "pc_female*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Pension Credit claimants, female",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2494,
                        "indicatorHTMLTableRef": "16881269343462",
                        "chartRef": null,
                        "indicatorCode": "pc_male*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Pension Credit claimants, male",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2495,
                        "indicatorHTMLTableRef": "16881269343462",
                        "chartRef": null,
                        "indicatorCode": "pc_gc*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Pension Credit claimants, Guarantee Element",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2496,
                        "indicatorHTMLTableRef": "16881269343462",
                        "chartRef": null,
                        "indicatorCode": "pc_gcandsc*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Pension Credit claimants, Guarantee and Saving Element",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2497,
                        "indicatorHTMLTableRef": "16881269343462",
                        "chartRef": null,
                        "indicatorCode": "pc_sc*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 6,
                        "y_label": null,
                        "indicatorName": "Pension Credit claimants, Saving Element",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2498,
                        "indicatorHTMLTableRef": "16881269343462",
                        "chartRef": null,
                        "indicatorCode": "pc_single*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 7,
                        "y_label": null,
                        "indicatorName": "Pension Credit claimants who are single",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 2499,
                        "indicatorHTMLTableRef": "16881269343462",
                        "chartRef": null,
                        "indicatorCode": "pc_withpartner*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 8,
                        "y_label": null,
                        "indicatorName": "Pension Credit claimants who are with partner",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 501,
                "reportThemeID": 91,
                "text": "<blockquote><h3>Change in Pension Credit claimants</h3></blockquote><p></p><p>The chart below shows a time series of the total percentage of Pension Credit claimants (as a proportion of all those aged 65+), giving insight into how this has changed in Brighton and Hove, England and South East between Nov-2010 and Feb-2024.</p><p>In Brighton and Hove the proportion of the population aged 65+ who are claiming Pension Credit has decreased, changing from 29.58% in Nov-2010 to 14.64% in Feb-2024. This is higher than the proportion in England (10.85%) and higher than the proportion in South East (8.00%) in Feb-2024.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 502,
                "reportThemeID": 91,
                "text": null,
                "chartType": "Line",
                "chartRef": "16881271011413",
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": true,
                "itemName": "Change in Pension Credit claimants",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2010-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2010",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2011",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2011",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2011",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2011",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2012",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2012",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2012",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2012",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2013",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2013",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2013",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2013",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2014",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2014",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2014",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2014",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2015",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2015",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2015",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2015",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2016",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2016",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2016",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2016",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2017",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2017",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2017",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2017",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2018",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2018",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2018",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2018",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2019",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2019",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2019",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2019",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2020",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2020",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2020",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2020",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2021",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2021",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2021",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2021",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2022",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2022",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2022",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2022",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2023",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2023",
                        "shortName": null
                    },
                    {
                        "id": 3572,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881271011413",
                        "indicatorCode": "pc_total*dr",
                        "indicatorDate": "2010-11-01,2023-09-05",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 980,
                        "y_label": null,
                        "indicatorName": "Pensioners in poverty (Pension Credit)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2023",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 503,
                "reportThemeID": 91,
                "text": "<blockquote><h3>Vulnerable pensioner households</h3></blockquote><p></p><p>The table below looks at vulnerable pensioner households, including those that may face greater risks or who may have different types of need. Here pensioner loneliness is measured by the proportion of pensioners living alone (defined as households of one pensioner and no other household members). Pensioner vulnerability is also reflected in those with bad/very bad health, a long-term illness and no access to a car/van.</p><p>There are 13,875 pensioners living alone in Brighton and Hove, 11.43% of the population aged 65+ here. This is lower than the proportion in England (12.81%) and lower than the proportion in South East (13.23%).</p><p>Additionally, 16,114 pensioners in Brighton and Hove have bad or very bad health (41.34%). This proportion is similar to the proportion in England (42.08%) and higher than the proportion in South East (37.62%).<br></p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 504,
                "reportThemeID": 91,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16881272639157",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": "Vulnerable pensioner households",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2501,
                        "indicatorHTMLTableRef": "16881272639157",
                        "chartRef": null,
                        "indicatorCode": "ks105ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "One person household: Aged 66 years and over (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2502,
                        "indicatorHTMLTableRef": "16881272639157",
                        "chartRef": null,
                        "indicatorCode": "lc3206ew_65pl_bad_vr_health*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "People over the age of 65 with bad or very bad health (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2926,
                        "indicatorHTMLTableRef": "16881272639157",
                        "chartRef": null,
                        "indicatorCode": "lc3205ew_ltti_65pl*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "People with a limiting long-term illness (aged 65+) (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2505,
                        "indicatorHTMLTableRef": "16881272639157",
                        "chartRef": null,
                        "indicatorCode": "cs062_no_car_pensioner_hh*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Lone pensioner households with no car or van (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 93,
        "name": "Digital",
        "slug": "digital",
        "title": "Digital",
        "description": "<p>This selection of data visualisations present key indicators related to digital inclusion and internet usage. This includes the Digital Exclusion Risk Index (DERI) score, average broadband speeds, median data usage, rate of premises falling below the universal service obligation (USO), and the proportions within the different internet user classifications. Together these metrics offer valuable insights into the level of digital infrastructure, inclusion and skills.</p></p>",
        "clientID": 1,
        "created_at": "2023-06-30T13:28:11.000000Z",
        "updated_at": "2023-07-13T13:40:51.000000Z",
        "use_for_summary": true,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 550,
                "reportThemeID": 93,
                "text": "<blockquote><h3>Digital Exclusion Risk Index (DERI)</h3></blockquote><p></p><p>The bar chart below shows the Digital Exclusion Risk Index (DERI) score where a higher score indicates a higher level of digital exclusion risk. This indicator explores the risk of digital exclusion at LSOA level derived from three components; age, broadband access, and deprivation.</p><p>In Brighton and Hove, the Digital Exclusion Risk Index (DERI) score is 2.57. This means that Brighton and Hove has a lower level of digital exclusion risk than England (3.00) and has a similar level of digital exclusion risk to South East (2.77).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 551,
                "reportThemeID": 93,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881317343475",
                "chartDescription": "<p>Digital Exclusion Risk Index (DERI) score</p>",
                "chartXLabel": null,
                "chartYLabel": "Score",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "Digital Exclusion Risk Index (DERI) score",
                "itemSource": "Greater Manchester Office of Data Analytics",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2585,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881317343475",
                        "indicatorCode": "digital_exclusion_index",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 838,
                        "y_label": null,
                        "indicatorName": "Digital Exclusion Risk Index (DERI) score",
                        "source": "Greater Manchester Office of Data Analytics",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 552,
                "reportThemeID": 93,
                "text": "<blockquote><h3>Broadband speed and data usage</h3></blockquote><p></p><p>The table below shows indicators covering broadband speed and data usage. This includes; median data usage (GBs), download speeds (Mbit/s), upload speeds (Mbit/s), and the percentage of premises that fall below the Universal Service Obligation (USO).</p><p>The USO is set to improve broadband availability by giving homes and businesses the legal right to request a decent and affordable broadband connection defined as a download speed of at least 10Mbit/s and an upload speed of at least 1Mbit/s. People who do not have access to a decent service are able to request one under the USO as set out by the Government in legislation.</p><p>In Brighton and Hove, the average broadband download speed of 176.60Mb/sMbit/s is faster than England (152.10Mb/sMbit/s) and faster than South East (154.99Mb/sMbit/s).</p><p><i>Note: 8 Megabits(Mb) is roughly equivalent to 1 Megabyte(MB)</i></p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 553,
                "reportThemeID": 93,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16881317857277",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": false,
                "itemName": "Broadband speed and data usage",
                "itemSource": "Ofcom",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2586,
                        "indicatorHTMLTableRef": "16881317857277",
                        "chartRef": null,
                        "indicatorCode": "data_useage",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Median Broadband Data Use (GB)",
                        "source": "Ofcom",
                        "date": "2023-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 2587,
                        "indicatorHTMLTableRef": "16881317857277",
                        "chartRef": null,
                        "indicatorCode": "averagespeedmbps",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Broadband Download Speed (Mb/s)",
                        "source": "Ofcom",
                        "date": "2023-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 2588,
                        "indicatorHTMLTableRef": "16881317857277",
                        "chartRef": null,
                        "indicatorCode": "averagespeedmbps_up",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Broadband Upload Speed (Mb/s)",
                        "source": "Ofcom",
                        "date": "2023-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 2589,
                        "indicatorHTMLTableRef": "16881317857277",
                        "chartRef": null,
                        "indicatorCode": "broadband_belowuso*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Premises with broadband speeds below the Universal Service Obligation (USO)",
                        "source": "Ofcom",
                        "date": "2023-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 554,
                "reportThemeID": 93,
                "text": "<blockquote><h3>Internet User Classifications (IUC)</h3></blockquote><p></p><p>The bar chart below shows the classification of neighbourhoods in terms of how they interact with the internet. The Internet User Classification (IUC) is a bespoke classification that identifies population profiles of internet use and engagement at LSOA level, providing insights into how people interact with the internet in a variety of ways.</p><p>In Brighton and Hove, the proportion of people living in areas falling within the category “e-Withdrawn” is 2.25%, which is lower than England (8.81%) and similar to South East (2.13%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 555,
                "reportThemeID": 93,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881318608290",
                "chartDescription": "<p>Internet User Classifications (IUC)</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Internet User Classifications (IUC)",
                "itemSource": "Consumer Data Research Centre (CDRC)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2591,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881318608290",
                        "indicatorCode": "iuc_e_cultural_creators*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 808,
                        "y_label": null,
                        "indicatorName": "Internet User Classification: e-Cultural Creators",
                        "source": "ESRC Consumer Data Research Centre",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2594,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881318608290",
                        "indicatorCode": "iuc_e_rational_utilitarians*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 810,
                        "y_label": null,
                        "indicatorName": "Internet User Classification: e-Rational Utilitarians",
                        "source": "ESRC Consumer Data Research Centre",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2597,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881318608290",
                        "indicatorCode": "iuc_passive_uncommitted*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 813,
                        "y_label": null,
                        "indicatorName": "Internet User Classification: Passive and Uncommitted Users",
                        "source": "ESRC Consumer Data Research Centre",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2592,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881318608290",
                        "indicatorCode": "iuc_e_mainstream*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 840,
                        "y_label": null,
                        "indicatorName": "Internet User Classification: e-Mainstream",
                        "source": "ESRC Consumer Data Research Centre",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2595,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881318608290",
                        "indicatorCode": "iuc_e_veterans*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 844,
                        "y_label": null,
                        "indicatorName": "Internet User Classification: e-Veterans",
                        "source": "ESRC Consumer Data Research Centre",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2598,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881318608290",
                        "indicatorCode": "iuc_settled_offline*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 845,
                        "y_label": null,
                        "indicatorName": "Internet User Classification: Settled Offline Communities",
                        "source": "ESRC Consumer Data Research Centre",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2590,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881318608290",
                        "indicatorCode": "iuc_digital_seniors*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 870,
                        "y_label": null,
                        "indicatorName": "Internet User Classification: Digital Seniors",
                        "source": "ESRC Consumer Data Research Centre",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2593,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881318608290",
                        "indicatorCode": "iuc_e_professionals*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 872,
                        "y_label": null,
                        "indicatorName": "Internet User Classification: e-Professionals",
                        "source": "ESRC Consumer Data Research Centre",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2596,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881318608290",
                        "indicatorCode": "iuc_e_withdrawn*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 875,
                        "y_label": null,
                        "indicatorName": "Internet User Classification: e-Withdrawn",
                        "source": "ESRC Consumer Data Research Centre",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    },
                    {
                        "id": 2599,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881318608290",
                        "indicatorCode": "iuc_youthful_urban_fringe*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 878,
                        "y_label": null,
                        "indicatorName": "Internet User Classification: Youthful Urban Fringe",
                        "source": "ESRC Consumer Data Research Centre",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 23,
        "name": "Economy (deprivation and low income)",
        "slug": "economy-deprivation-low-income",
        "title": "Economy (Deprivation and Low Income)",
        "description": "<p>The following data focuses on deprivation and low incomes within Brighton and Hove, England and South East, exploring a range of different indicators from the Indices of Deprivation (IoD) income deprivation domain and from DWP benefit claimant data. These visualisations provide insight into the nature and extent of poverty in these areas and to identify where targeted intervention and support may be required.</p></p>",
        "clientID": 1,
        "created_at": "2023-05-04T15:12:43.000000Z",
        "updated_at": "2024-04-09T10:20:22.000000Z",
        "use_for_summary": true,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 616,
                "reportThemeID": 23,
                "text": "<blockquote><h3>IoD income domain</h3></blockquote><p></p><p>The bar chart below shows the proportion of people living in neighbourhoods grouped into deciles according to level of deprivation derived from the IoD (income domain). A higher score on the IoD (income domain) indicates that an area is experiencing greater levels of deprivation relating to low income. To clarify, all neighbourhoods in England are grouped into ten equal sized groups “deciles”; neighbourhoods in decile 1 fall within the most deprived 10% of neighbourhoods nationally, whilst neighbourhoods in decile 10 fall within the least deprived 10% of neighbourhoods nationally. </p><p>The definition of low income used by the IoD includes both those people that are out-of-work and those that are in work but who have low earnings (and who satisfy the respective means tests). The following indicators are included: Adults and children in Income Support families; Adults and children in income-based Jobseeker's Allowance families; Adults and children in income-based Employment and Support Allowance families Adults and children in Pension Credit (Guarantee) families; Adults and children in Working Tax Credit and Child Tax Credit families not already counted that is those who are not in receipt of Income Support income-based Jobseeker's Allowance income-based Employment and Support Allowance or Pension Credit (Guarantee) and whose equivalised income (excluding housing benefit) is below 60% of the median before housing costs; Adults and children in Universal Credit families where no adult is in 'Working - no requirements' conditionality regime; Asylum seekers in England in receipt of subsistence support accommodation support or both.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 282,
                "reportThemeID": 23,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16832137317905",
                "chartDescription": "<p>IoD (income domain) deprivation deciles</p>",
                "chartXLabel": null,
                "chartYLabel": "Percentage %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "IoD (income domain) deprivation deciles",
                "itemSource": "Ministry of Housing Communities and Local Government (MHCLG)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 640,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832137317905",
                        "indicatorCode": "id19_incpop_dec10*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 723,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Decile 10",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 641,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832137317905",
                        "indicatorCode": "id19_incpop_dec9*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 724,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Decile 9",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 642,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832137317905",
                        "indicatorCode": "id19_incpop_dec8*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 725,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Decile 8",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 643,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832137317905",
                        "indicatorCode": "id19_incpop_dec7*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 727,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Decile 7",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 644,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832137317905",
                        "indicatorCode": "id19_incpop_dec6*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 728,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Decile 6",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 645,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832137317905",
                        "indicatorCode": "id19_incpop_dec5*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 731,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Decile 5",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 646,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832137317905",
                        "indicatorCode": "id19_incpop_dec4*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 732,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Decile 4",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 647,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832137317905",
                        "indicatorCode": "id19_incpop_dec3*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 734,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Decile 3",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 648,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832137317905",
                        "indicatorCode": "id19_incpop_dec2*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 738,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Decile 2",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 649,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832137317905",
                        "indicatorCode": "id19_incpop_dec1*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 745,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Decile 1",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 283,
                "reportThemeID": 23,
                "text": "<blockquote><h3>DWP benefit claimants</h3></blockquote><p></p><p>The table below shows the count and proportion within the area of people receiving DWP benefits. The data is provided for three age groups: 16-64, 16-24 and 65+.</p><p>DWP Benefits are payable to eligible people who need additional financial support due to low income, worklessness, poor health, caring responsibilities, bereavement or disability. The following benefits are included: Universal Credit. Bereavement Benefit, Carers Allowance, Incapacity Benefit/Severe Disablement Allowance, Income Support, Jobseekers Allowance, Pension Credit and Widows Benefit. The aim of these is to provide statistics on the number of individuals claiming a DWP benefit in total (i.e. only counting each individual claimant once).</p><p>In Brighton and Hove 20.58% of people aged 16-64 are claiming DWP benefits. This is lower than the proportion in England (22.98%) and higher than the proportion in South East (18.34%).</p><p>0.00% of people aged 65+ are also claiming DWP benefits in Brighton and Hove. This is similar to the proportion in England (0.00%) and similar to the proportion in South East (0.00%). To clarify, this does not include those that claim state pension only.</p><p><i>Note: The benefit combinations are recorded as quarterly snapshots. They do not cover every possible combination.</i></p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 284,
                "reportThemeID": 23,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16832148613957",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": false,
                "itemName": "People receiving DWP benefits",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 650,
                        "indicatorHTMLTableRef": "16832148613957",
                        "chartRef": null,
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "DWP Benefit claimants (aged 16 - 64)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 651,
                        "indicatorHTMLTableRef": "16832148613957",
                        "chartRef": null,
                        "indicatorCode": "ben_combs_16_24_tot_li*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "DWP Benefit claimants (aged 16 - 24)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 7143,
                        "indicatorHTMLTableRef": "16832148613957",
                        "chartRef": null,
                        "indicatorCode": "ben_combs_spa_tot_li*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "People aged 65+ claiming DWP Benefits (Benefit Combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 285,
                "reportThemeID": 23,
                "text": "<p>The line graph below shows a time series for the proportion of working age people receiving DWP benefits in Brighton and Hove, England and South East. Working age DWP Benefits are benefits payable to all people aged 16-64 who need additional financial support due to low income, worklessness, poor health, caring responsibilities, bereavement or disability.</p><p>In Brighton and Hove the proportion of people aged 16-64 claiming DWP benefits has increased since May-2018, changing from 14.94% to 20.58%. This latest figure is lower than the proportion in England (22.98%) and higher than the proportion in South East (18.34%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 286,
                "reportThemeID": 23,
                "text": null,
                "chartType": "Line",
                "chartRef": "16832152042257",
                "chartDescription": "<p>People aged 16-64 receiving DWP benefits</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": true,
                "itemName": "People aged 16-64 receiving DWP benefits",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2018",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2018",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2018",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2019",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2019",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2019",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2019",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2020",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2020",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2020",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2020",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2021",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2021",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2021",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2021",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2022",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2022",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2022",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2022",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2023",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "Working age Benefit claimants (Benefit combinations)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2023",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "DWP Benefit claimants (aged 16 - 64)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2023",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "DWP Benefit claimants (aged 16 - 64)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2023",
                        "shortName": null
                    },
                    {
                        "id": 3551,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832152042257",
                        "indicatorCode": "ben_combs_wa_tot_li*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 921,
                        "y_label": null,
                        "indicatorName": "DWP Benefit claimants (aged 16 - 64)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 287,
                "reportThemeID": 23,
                "text": "<blockquote><h3>Universal Credit claimants</h3></blockquote><p></p><p>The table below provides insight into Universal Credit (UC) claimants. It shows the count and proportion of claimants within the area for each UC conditionality regime.</p><p>For context, UC provides a single payment based upon the circumstances of the household to provide support for housing costs, children and childcare costs with additions for disabled people and carers. The following benefits will be abolished as UC rolls out: Income-based Jobseekers Allowance, Income-related Employment and Support Allowance, Income Support, Working Tax Credit, Child Tax Credit and Housing Benefit. The main differences between UC and the previous welfare system are: UC is available to people who are in work and on a low income, as well as to those who are out-of-work, Most people will now apply online and manage their claim through an online account, UC will be responsive as people on low incomes move in and out of work, they will get ongoing support. Most claimants on low incomes will still be paid UC when they first start a new job or increase their part-time hours. Claimants will receive a single monthly household payment, paid into a bank account in the same way as a monthly salary, support with housing costs will usually go direct to the claimant as part of their monthly payment.</p><p>In Brighton and Hove 14.87% of people are claiming UC. This is lower than the proportion in England (17.29%) and higher than the proportion in South East (13.60%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 288,
                "reportThemeID": 23,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "1683215672722",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": false,
                "itemName": "Universal Credit claimants",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 654,
                        "indicatorHTMLTableRef": "1683215672722",
                        "chartRef": null,
                        "indicatorCode": "uc_tot_small*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Universal Credit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2024",
                        "shortName": null
                    },
                    {
                        "id": 655,
                        "indicatorHTMLTableRef": "1683215672722",
                        "chartRef": null,
                        "indicatorCode": "uc_no_work_req*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Universal Credit claimants - Conditionality Regime: No work requirements",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2024",
                        "shortName": null
                    },
                    {
                        "id": 656,
                        "indicatorHTMLTableRef": "1683215672722",
                        "chartRef": null,
                        "indicatorCode": "uc_plan_for_work*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Universal Credit claimants - Conditionality Regime: Planning for work",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2024",
                        "shortName": null
                    },
                    {
                        "id": 657,
                        "indicatorHTMLTableRef": "1683215672722",
                        "chartRef": null,
                        "indicatorCode": "uc_prep_for_work*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Universal Credit claimants - Conditionality Regime: Preparing for work",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2024",
                        "shortName": null
                    },
                    {
                        "id": 658,
                        "indicatorHTMLTableRef": "1683215672722",
                        "chartRef": null,
                        "indicatorCode": "uc_search_for_work*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Universal Credit claimants - Conditionality Regime: Searching for work",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2024",
                        "shortName": null
                    },
                    {
                        "id": 659,
                        "indicatorHTMLTableRef": "1683215672722",
                        "chartRef": null,
                        "indicatorCode": "uc_work_with_no_req*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 6,
                        "y_label": null,
                        "indicatorName": "Universal Credit claimants - Conditionality Regime: Working No requirements",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2024",
                        "shortName": null
                    },
                    {
                        "id": 660,
                        "indicatorHTMLTableRef": "1683215672722",
                        "chartRef": null,
                        "indicatorCode": "uc_work_with_req*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 7,
                        "y_label": null,
                        "indicatorName": "Universal Credit claimants - Conditionality Regime: Working with requirements",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 289,
                "reportThemeID": 23,
                "text": "<blockquote><h3>Households on Universal Credit</h3></blockquote><p></p><p>The table below offers further insight into Universal Credit claims by showing the total number and proportion of households on UC. This is then further broken down into relevant subsections.</p><p>It shows us that, in Brighton and Hove, 0.83% of households are on UC and receiving the disabled child entitlement. This is lower than the proportion in England (1.24%) and lower than the proportion in South East (1.16%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 290,
                "reportThemeID": 23,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16832740918786",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": "Households on Universal Credit",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 661,
                        "indicatorHTMLTableRef": "16832740918786",
                        "chartRef": null,
                        "indicatorCode": "uc_total_households*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Total households on Universal Credit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2024",
                        "shortName": null
                    },
                    {
                        "id": 662,
                        "indicatorHTMLTableRef": "16832740918786",
                        "chartRef": null,
                        "indicatorCode": "uc_single_dep_child*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Households on Universal Credit, Family type, single with dependent children",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2024",
                        "shortName": null
                    },
                    {
                        "id": 663,
                        "indicatorHTMLTableRef": "16832740918786",
                        "chartRef": null,
                        "indicatorCode": "uc_carer_ent*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Households on Universal Credit, Carer Entitlement",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2024",
                        "shortName": null
                    },
                    {
                        "id": 664,
                        "indicatorHTMLTableRef": "16832740918786",
                        "chartRef": null,
                        "indicatorCode": "uc_child_ent*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Households on Universal Credit, Child Entitlement",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2024",
                        "shortName": null
                    },
                    {
                        "id": 665,
                        "indicatorHTMLTableRef": "16832740918786",
                        "chartRef": null,
                        "indicatorCode": "uc_disab_child_ent*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Households on Universal Credit, Disabled Child Entitlement",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 668,
                        "indicatorHTMLTableRef": "16832740918786",
                        "chartRef": null,
                        "indicatorCode": "uc_lcw*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 6,
                        "y_label": null,
                        "indicatorName": "Households on Universal Credit - Limited Capability for Work Entitlement",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 667,
                        "indicatorHTMLTableRef": "16832740918786",
                        "chartRef": null,
                        "indicatorCode": "uc_housing_ent_soc*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 7,
                        "y_label": null,
                        "indicatorName": "Households on Universal Credit, Housing Entitlement, Social rented",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2024",
                        "shortName": null
                    },
                    {
                        "id": 666,
                        "indicatorHTMLTableRef": "16832740918786",
                        "chartRef": null,
                        "indicatorCode": "uc_housing_ent_priv*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 8,
                        "y_label": null,
                        "indicatorName": "Households on Universal Credit, Housing Entitlement, Private rented",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2024",
                        "shortName": null
                    },
                    {
                        "id": 6950,
                        "indicatorHTMLTableRef": "16832740918786",
                        "chartRef": null,
                        "indicatorCode": "uc_housing_ent_tot*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 9,
                        "y_label": null,
                        "indicatorName": "Households on Universal Credit with Housing Entitlement",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 291,
                "reportThemeID": 23,
                "text": "<blockquote><h3>Housing Benefit claimants</h3></blockquote><p></p><p>The line chart below shows a time series of housing benefit (HB) claimants. This is the proportion of households receiving HB which can be claimed by a person if they are living on a low income and are liable to pay rent.</p><p>In Brighton and Hove the proportion of households claiming Housing Benefit has decreased since Jan-2020, from 14.42% to 10.78%. This latest figure is higher than the proportion in England (8.19%) and higher than the proportion in South East (6.47%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 10,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 292,
                "reportThemeID": 23,
                "text": null,
                "chartType": "Line",
                "chartRef": "16832745021218",
                "chartDescription": "<p>Housing Benefit claimants</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 11,
                "timeSeries": true,
                "itemName": "Housing Benefit claimants",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2011-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2011",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2012-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2012",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2013",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2014",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2015",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2015",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2015",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2015",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2015",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2015",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2015",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2015",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2015",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2015",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2016",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2017",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2018",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2019",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2020",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2021",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2022",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2023",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2024",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2024",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2024",
                        "shortName": null
                    },
                    {
                        "id": 3552,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832745021218",
                        "indicatorCode": "hb_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 928,
                        "y_label": null,
                        "indicatorName": "Housing Benefit",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2024",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 29,
        "name": "Economy (economic activity)",
        "slug": "economy-economic-activity",
        "title": "Economy (Economic Activity)",
        "description": "<p>The following selection of data visualisations present key indicators related to economic activity from the most recent UK Census. These indicators encompass various aspects of employment status, including part-time, full-time, and self-employed, as well as different classifications of economically active or inactive individuals. Additionally, there is data on working hours distribution among adults and the proportions of adults in different occupational groups. The combination of these indicators provide a valuable insight into notable trends and patterns for informing policy decisions related to workforce development and planning.</p></p>",
        "clientID": 1,
        "created_at": "2023-06-12T15:19:07.000000Z",
        "updated_at": "2024-04-09T10:20:46.000000Z",
        "use_for_summary": true,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 382,
                "reportThemeID": 29,
                "text": "<blockquote><h3>Work status</h3></blockquote><p></p><p>The table below provides data from the responses to the 2021 Census economic activity questions. Figures show the proportion of adults aged 16+ within the different classifications.</p><p>In Brighton and Hove, the proportion of those retired and economically inactive is 15.29%, which is lower than in England (21.48%) and lower than South East (22.48%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 383,
                "reportThemeID": 29,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16865832336869",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "Economic activity: work status",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 954,
                        "indicatorHTMLTableRef": "16865832336869",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Part-time employees (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 955,
                        "indicatorHTMLTableRef": "16865832336869",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0003*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Full-time employees (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 956,
                        "indicatorHTMLTableRef": "16865832336869",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0004*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Self-employed people (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 957,
                        "indicatorHTMLTableRef": "16865832336869",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0005*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Economically active: Unemployed (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 958,
                        "indicatorHTMLTableRef": "16865832336869",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0006*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Economically active: Full-time student (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 959,
                        "indicatorHTMLTableRef": "16865832336869",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0007*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 6,
                        "y_label": null,
                        "indicatorName": "Economically inactive: Retired (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 960,
                        "indicatorHTMLTableRef": "16865832336869",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0008*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 7,
                        "y_label": null,
                        "indicatorName": "Economically inactive: Student (including full-time students) (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 961,
                        "indicatorHTMLTableRef": "16865832336869",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0009*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 8,
                        "y_label": null,
                        "indicatorName": "Economically inactive: Looking after home or family (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 962,
                        "indicatorHTMLTableRef": "16865832336869",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0010*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 9,
                        "y_label": null,
                        "indicatorName": "Economically inactive: Long-term sick or disabled (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 963,
                        "indicatorHTMLTableRef": "16865832336869",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0011*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 10,
                        "y_label": null,
                        "indicatorName": "Economically inactive: Other (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 588,
                "reportThemeID": 29,
                "text": "<blockquote><h3>Employment type</h3></blockquote><p></p><p>The stacked bar chart below provides a comparison for the rates of different types of employment from the 2021 Census economic activity questions. Working part-time is defined as working 30 hours or less a week, full-time as working 31 hours or more a week, and being self-employed relates to the person's main job in the week before Census or, if not working in the week before Census, their last main job.</p><p>In Brighton and Hove, the proportion of those in full-time roles is 31.63%, which is lower than England (34.27%) and lower than South East (35.21%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 384,
                "reportThemeID": 29,
                "text": null,
                "chartType": "Stacked Bar",
                "chartRef": "16865833876912",
                "chartDescription": "<p>Employment type</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": false,
                "itemName": "Employment type",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 964,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16865833876912",
                        "indicatorCode": "ks601ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 786,
                        "y_label": null,
                        "indicatorName": "Part-time employees (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 965,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16865833876912",
                        "indicatorCode": "ks601ew0003*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 788,
                        "y_label": null,
                        "indicatorName": "Full-time employees (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 966,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16865833876912",
                        "indicatorCode": "ks601ew0004*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 789,
                        "y_label": null,
                        "indicatorName": "Self-employed people (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 385,
                "reportThemeID": 29,
                "text": "<blockquote><h3>Working hours</h3></blockquote><p></p><p>The table below shows data on the number of hours adults aged 16+ work per week. Figures are based on self-reported responses from the 2021 Census.</p><p>In Brighton and Hove, the proportion of those working 15 hours or less is 13.23%, which is higher than England (10.34%) and higher than South East (10.98%).</p><p>In contrast, the proportion of those working 49 hours or more in Brighton and Hove is 9.30%, which is lower than England (11.08%) and lower than South East (11.91%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 386,
                "reportThemeID": 29,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16865834847752",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Working hours",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 967,
                        "indicatorHTMLTableRef": "16865834847752",
                        "chartRef": null,
                        "indicatorCode": "ks604ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Part-time: 15 hours or less worked (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 968,
                        "indicatorHTMLTableRef": "16865834847752",
                        "chartRef": null,
                        "indicatorCode": "ks604ew0003*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Part-time: 16 to 30 hours worked (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 969,
                        "indicatorHTMLTableRef": "16865834847752",
                        "chartRef": null,
                        "indicatorCode": "ks604ew0004*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Full-time: 31 to 48 hours worked (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 970,
                        "indicatorHTMLTableRef": "16865834847752",
                        "chartRef": null,
                        "indicatorCode": "ks604ew0005*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Full-time: 49 or more hours worked (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 540,
                "reportThemeID": 29,
                "text": "<p>The stacked bar chart below shows a more granular breakdown of those working part-time hours.</p><p>In Brighton and Hove, the proportion of those working 16 to 30 hours per week is 20.70%, which is higher than those working 15 hours or less per week (13.23%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 541,
                "reportThemeID": 29,
                "text": null,
                "chartType": "Stacked Bar",
                "chartRef": "16881310527061",
                "chartDescription": "<p>Part-time hours</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": false,
                "itemName": "Part-time hours",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2565,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881310527061",
                        "indicatorCode": "ks604ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 848,
                        "y_label": null,
                        "indicatorName": "Part-time: 15 hours or less worked (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2566,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881310527061",
                        "indicatorCode": "ks604ew0003*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 884,
                        "y_label": null,
                        "indicatorName": "Part-time: 16 to 30 hours worked (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 542,
                "reportThemeID": 29,
                "text": "<p>The stacked bar chart below shows a more granular breakdown of those working full-time hours.</p><p>In Brighton and Hove, the proportion of those working 31 to 48 hours per week is 56.77%, which is higher than those working 49 hours or more per week (9.30%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 543,
                "reportThemeID": 29,
                "text": null,
                "chartType": "Stacked Bar",
                "chartRef": "16881311134971",
                "chartDescription": "<p>Full-time hours</p>",
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": "Full-time hours",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2570,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881311134971",
                        "indicatorCode": "ks604ew0004*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 849,
                        "y_label": null,
                        "indicatorName": "Full-time: 31 to 48 hours worked (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2571,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881311134971",
                        "indicatorCode": "ks604ew0005*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 885,
                        "y_label": null,
                        "indicatorName": "Full-time: 49 or more hours worked (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 544,
                "reportThemeID": 29,
                "text": "<blockquote><h3>Occupational groups</h3></blockquote><p></p><p>The table below provides figures for the number of people in employment (aged 16+) within various types of occupational groups. An individual's occupation group is determined by their response to the occupation questions in the 2021 Census.</p><p>In Brighton and Hove, the proportion of those in sales and customer service occupations is 7.28%, which is similar to England (7.47%) and similar to South East (6.82%).</p><p>7.72% of people aged 16+ are also employed in skilled trades in Brighton and Hove, which is lower than in England (10.16%) and lower than South East (10.00%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 10,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 545,
                "reportThemeID": 29,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16881313664491",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 11,
                "timeSeries": false,
                "itemName": "Occupational groups",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2572,
                        "indicatorHTMLTableRef": "16881313664491",
                        "chartRef": null,
                        "indicatorCode": "ks608ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Occupation group: Managers, directors and senior officials (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2573,
                        "indicatorHTMLTableRef": "16881313664491",
                        "chartRef": null,
                        "indicatorCode": "ks608ew0003*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Occupation group: Professional occupations (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2574,
                        "indicatorHTMLTableRef": "16881313664491",
                        "chartRef": null,
                        "indicatorCode": "ks608ew0004*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Occupation group: Associate professional and technical occupations (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2575,
                        "indicatorHTMLTableRef": "16881313664491",
                        "chartRef": null,
                        "indicatorCode": "ks608ew0005*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Occupation group: Administrative and secretarial occupations (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2576,
                        "indicatorHTMLTableRef": "16881313664491",
                        "chartRef": null,
                        "indicatorCode": "ks608ew0006*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Occupation group: Skilled trades occupations (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2577,
                        "indicatorHTMLTableRef": "16881313664491",
                        "chartRef": null,
                        "indicatorCode": "ks608ew0007*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 6,
                        "y_label": null,
                        "indicatorName": "Occupation group: Caring, leisure and other service occupations (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2578,
                        "indicatorHTMLTableRef": "16881313664491",
                        "chartRef": null,
                        "indicatorCode": "ks608ew0008*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 7,
                        "y_label": null,
                        "indicatorName": "Occupation group: Sales and customer service occupations (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2579,
                        "indicatorHTMLTableRef": "16881313664491",
                        "chartRef": null,
                        "indicatorCode": "ks608ew0009*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 8,
                        "y_label": null,
                        "indicatorName": "Occupation group: Process, plant and machine operatives (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2580,
                        "indicatorHTMLTableRef": "16881313664491",
                        "chartRef": null,
                        "indicatorCode": "ks608ew0010*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 9,
                        "y_label": null,
                        "indicatorName": "Occupation group: Elementary occupations (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 546,
                "reportThemeID": 29,
                "text": "<p>The stacked bar chart below focuses on those people within the managers, professionals and associate professional occupation groups which when combined reflect a hierarchy of roles in terms of responsibility, skill level, and decision-making authority. Managers and directors oversee the operations, professionals contribute expertise, and associate professionals provide support and technical assistance within their respective fields.</p><p>In Brighton and Hove, the proportion of those in the managers, directors and senior officials occupational group is 14.14%, which is higher than England (12.89%) and similar to South East (14.88%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 12,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 547,
                "reportThemeID": 29,
                "text": null,
                "chartType": "Stacked Bar",
                "chartRef": "16881315111418",
                "chartDescription": "<p>Occupational groups (managers, professionals and associate professionals)</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 13,
                "timeSeries": false,
                "itemName": "Occupational groups (managers, professionals and associate professionals)",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2581,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881315111418",
                        "indicatorCode": "ks608ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 850,
                        "y_label": null,
                        "indicatorName": "Occupation group: Managers, directors and senior officials (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2582,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881315111418",
                        "indicatorCode": "ks608ew0003*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 852,
                        "y_label": null,
                        "indicatorName": "Occupation group: Professional occupations (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2583,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881315111418",
                        "indicatorCode": "ks608ew0004*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 853,
                        "y_label": null,
                        "indicatorName": "Occupation group: Associate professional and technical occupations (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 548,
                "reportThemeID": 29,
                "text": "<p>The bar chart below focuses on those people within the elementary occupation groups. Elementary occupations refer to jobs that involve simple and routine tasks that usually require limited specific skills and qualifications. This may include include jobs such as cleaners, labourers, agricultural workers, kitchen assistants, refuse collectors, or construction workers.</p><p>In Brighton and Hove, the proportion of those in elementary occupations is 7.93%, which is lower than England (10.46%) and similar to South East (8.81%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 14,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 549,
                "reportThemeID": 29,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16881315788367",
                "chartDescription": "<p>Occupational groups (elementary occupations)</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 15,
                "timeSeries": false,
                "itemName": "Occupational groups (elementary occupations)",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2584,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16881315788367",
                        "indicatorCode": "ks608ew0010*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 855,
                        "y_label": null,
                        "indicatorName": "Occupation group: Elementary occupations (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 16,
        "name": "Education",
        "slug": "education",
        "title": "Education",
        "description": "<p>The following data visualisations are focused on the subject of education. It brings together indicators on qualification levels, participation in higher education, numbers of full-time students, and data from the Indices of Deprivation education domain. Together this collection provides insight into educational disparities and supports the identification of communities that may be falling behind and in need of support.</p></p>",
        "clientID": 1,
        "created_at": "2023-04-24T08:44:24.000000Z",
        "updated_at": "2023-11-10T09:44:42.000000Z",
        "use_for_summary": true,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 617,
                "reportThemeID": 16,
                "text": "<blockquote><h3>Level of qualification (aged 16+)</h3></blockquote><p></p><p>The table below shows the composition of people aged 16+ by level of qualification.</p><p>There are 8,052 people in Brighton and Hove with an apprenticeship as their highest qualification, 3.42% of all people aged over 16. This is lower than the average for England (5.32%) and lower than the average for South East (5.12%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 143,
                "reportThemeID": 16,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16823260049187",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "Level of qualification (aged 16+)",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 325,
                        "indicatorHTMLTableRef": "16823260049187",
                        "chartRef": null,
                        "indicatorCode": "ks501ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "People with no qualifications (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 326,
                        "indicatorHTMLTableRef": "16823260049187",
                        "chartRef": null,
                        "indicatorCode": "ks501ew0003*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Highest level of qualification: Level 1 qualifications (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 329,
                        "indicatorHTMLTableRef": "16823260049187",
                        "chartRef": null,
                        "indicatorCode": "ks501ew0004*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Highest level of qualification: Level 2 qualifications (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 330,
                        "indicatorHTMLTableRef": "16823260049187",
                        "chartRef": null,
                        "indicatorCode": "ks501ew0005*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Highest level of qualification: Apprenticeship (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 331,
                        "indicatorHTMLTableRef": "16823260049187",
                        "chartRef": null,
                        "indicatorCode": "ks501ew0006*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Highest level of qualification: Level 3 qualifications (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 332,
                        "indicatorHTMLTableRef": "16823260049187",
                        "chartRef": null,
                        "indicatorCode": "ks501ew0007*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 6,
                        "y_label": null,
                        "indicatorName": "Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 333,
                        "indicatorHTMLTableRef": "16823260049187",
                        "chartRef": null,
                        "indicatorCode": "ks501ew0008*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 7,
                        "y_label": null,
                        "indicatorName": "Highest level of qualification: Other qualifications (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 145,
                "reportThemeID": 16,
                "text": "<blockquote><h3>No qualifications vs degree level or above</h3></blockquote><p></p><p>The bar chart below shows the proportion of people aged 16+ with no qualifications, alongside the proportion of people with qualifications to degree level and above.</p><p>Within Brighton and Hove 12.41% of people aged 16+ have no qualifications. This is lower than the proportion in England (18.08%) and lower than the proportion in South East (15.38%).</p><p>In contrast, within Brighton and Hove 44.52% of people aged 16+ are educated to degree level or higher. This is higher than the proportion in England (33.92%) and higher than the proportion in South East (35.77%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 144,
                "reportThemeID": 16,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16823260972169",
                "chartDescription": "<p>No qualifications vs degree level or above</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": false,
                "itemName": "No qualifications vs degree level or above",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 327,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823260972169",
                        "indicatorCode": "ks501ew0002*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 653,
                        "y_label": null,
                        "indicatorName": "People with no qualifications (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 328,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823260972169",
                        "indicatorCode": "ks501ew0007*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 658,
                        "y_label": null,
                        "indicatorName": "Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 148,
                "reportThemeID": 16,
                "text": "<blockquote><h3>Change in the proportion of people with no qualifications/degree level qualifications</h3></blockquote><p></p><p>The bar chart below is a visualisation of the change in the proportion of people with no qualifications vs degree level qualifications over a 10 year period by comparing data from Census 2011 to Census 2021.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 147,
                "reportThemeID": 16,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16823284502756",
                "chartDescription": "<p>Change in the proportion of people with no qualifications/degree level qualifications</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Change in the proportion of people with no qualifications/degree level qualifications",
                "itemSource": "UK Census",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 482,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823284502756",
                        "indicatorCode": "ks501ew0002*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 675,
                        "y_label": null,
                        "indicatorName": "People with no qualifications (E&W Census 2011)",
                        "source": "Census 2021",
                        "date": "2011-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2011",
                        "shortName": null
                    },
                    {
                        "id": 482,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823284502756",
                        "indicatorCode": "ks501ew0002*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 675,
                        "y_label": null,
                        "indicatorName": "People with no qualifications (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 483,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823284502756",
                        "indicatorCode": "ks501ew0007*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 685,
                        "y_label": null,
                        "indicatorName": "Highest level of qualification: Level 4/5 (degree or higher) qualifications  (E&W Census 2011)",
                        "source": "Census 2011",
                        "date": "2011-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2011",
                        "shortName": null
                    },
                    {
                        "id": 483,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823284502756",
                        "indicatorCode": "ks501ew0007*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 685,
                        "y_label": null,
                        "indicatorName": "Highest level of qualification: Level 4/5 (degree or higher) qualifications (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 146,
                "reportThemeID": 16,
                "text": "<blockquote><h3>Participation in higher education</h3></blockquote><p></p><p>The chart below shows the percentage of state school pupils going into higher education. The data is recorded as the proportion of the 16 year old state-funded mainstream school pupils in the area who sat their GCSEs in the summers between 2019 to 2020 and that were then in higher education at the age of 18 or 19.</p><p>The rate of state school pupils in Brighton and Hove participating in higher education is 35.95%. This is lower than England (40.32%) and lower than South East (39.53%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 150,
                "reportThemeID": 16,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16823287802178",
                "chartDescription": "<p>Participation in higher education</p>",
                "chartXLabel": null,
                "chartYLabel": "Percentage %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": false,
                "itemName": "Participation in higher education",
                "itemSource": "Office for Students (OFS)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 336,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823287802178",
                        "indicatorCode": "polar_tundra_participation_num*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 659,
                        "y_label": null,
                        "indicatorName": "Participation of state school pupils in higher education",
                        "source": "Office for Students, ONS",
                        "date": "2019-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019 to 2020",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 151,
                "reportThemeID": 16,
                "text": "<blockquote><h3>Indices of Deprivation (IoD): Education, skills and training domain</h3></blockquote><p></p><p>The table below shows data from the Indices of Deprivation, education skills and training domain. It measures the lack of attainment and skills in the local population and is derived from two sub-domains: one relating to children and young people and one relating to adult skills. Within the table you are shown the average LSOA ranks for Brighton and Hove, England and South East, where a lower rank indicates higher levels of deprivation.</p><p>The average LSOA rank in Brighton and Hove is 18,757. This is higher than England (16,446) and higher than South East (18,524). Therefore, Brighton and Hove has lower levels of deprivation on this domain compared to England and lower levels of deprivation on this domain compared to South East.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 152,
                "reportThemeID": 16,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16823290521952",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": "Indices of Deprivation (IoD): Education, skills and training domain",
                "itemSource": "Ministry of Housing Communities and Local Government (MHCLG)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 338,
                        "indicatorHTMLTableRef": "16823290521952",
                        "chartRef": null,
                        "indicatorCode": "edu19_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education, Skills and Training Rank",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 340,
                        "indicatorHTMLTableRef": "16823290521952",
                        "chartRef": null,
                        "indicatorCode": "educhi19_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Children and Young People Sub-domain Rank",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 342,
                        "indicatorHTMLTableRef": "16823290521952",
                        "chartRef": null,
                        "indicatorCode": "eduski19_rank",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Adult Skills Sub-domain Rank",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 0,
                        "dateCoverageText": "2019",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 153,
                "reportThemeID": 16,
                "text": "<blockquote><h3>Indices of Deprivation: education domain (deprivation deciles)</h3></blockquote><p></p><p>For further insight, the bar chart below shows the proportion of people living in neighbourhoods grouped into deciles according to level of deprivation derived from the Indices of Deprivation (IoD) education domain. To clarify, all neighbourhoods in England are grouped into ten equal sized groups “deciles”; neighbourhoods in decile 1 fall within the most deprived 10% of neighbourhoods nationally and LSOAs in decile 10 fall within the least deprived 10% of neighbourhoods nationally.</p><p>In Brighton and Hove 8.80% of people are in the first decile, 7.86% in the second decile, 4.35% in the third decile, 9.94% in the fourth decile, 7.93% in the fifth decile, 6.86% in the sixth decile, 11.22% in the seventh decile, 13.37% in the eighth decile, 15.87% in the ninth decile and 12.86% in the tenth decile.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 10,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 154,
                "reportThemeID": 16,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16823293308286",
                "chartDescription": "<p>Indices of Deprivation: education domain (deprivation deciles)</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 11,
                "timeSeries": false,
                "itemName": "Indices of Deprivation: education domain (deprivation deciles)",
                "itemSource": "Ministry of Housing Communities and Local Government (MHCLG)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 354,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823293308286",
                        "indicatorCode": "id19_edupop_dec1*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1121,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education Decile 1",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 353,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823293308286",
                        "indicatorCode": "id19_edupop_dec2*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1156,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education Decile 2",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 352,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823293308286",
                        "indicatorCode": "id19_edupop_dec3*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1186,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education Decile 3",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 351,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823293308286",
                        "indicatorCode": "id19_edupop_dec4*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1195,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education Decile 4",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 350,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823293308286",
                        "indicatorCode": "id19_edupop_dec5*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1198,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education Decile 5",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 349,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823293308286",
                        "indicatorCode": "id19_edupop_dec6*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1210,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education Decile 6",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 348,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823293308286",
                        "indicatorCode": "id19_edupop_dec7*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1215,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education Decile 7",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 347,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823293308286",
                        "indicatorCode": "id19_edupop_dec8*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1221,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education Decile 8",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 346,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823293308286",
                        "indicatorCode": "id19_edupop_dec9*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1225,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education Decile 9",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 345,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16823293308286",
                        "indicatorCode": "id19_edupop_dec10*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1228,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Education Decile 10",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 155,
                "reportThemeID": 16,
                "text": "<blockquote><h3>Full-time students</h3></blockquote><p></p><p>The table below shows the total number and proportion of adults aged 16+ who are economically active/inactive and a full-time student. Economically active students refers to those aged 16+ who are in full-time education and who are either in employment or unemployed yet seeking out work. Economically inactive students refer to those aged 16+ who are in full-time education, are unemployed and have not sought work in the last four weeks and/or are not available to start work in the next two weeks.</p><p>The proportion of economically active students in Brighton and Hove is 4.42%, which is higher than England (2.30%) and higher than South East (2.10%).</p><p>The proportion of economically inactive students in Brighton and Hove is 9.86%, which is higher than England (5.64%) and higher than South East (5.10%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 12,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 156,
                "reportThemeID": 16,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16823294511995",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 13,
                "timeSeries": false,
                "itemName": "Full-time students",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 343,
                        "indicatorHTMLTableRef": "16823294511995",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0006*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Economically active: Full-time student (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 344,
                        "indicatorHTMLTableRef": "16823294511995",
                        "chartRef": null,
                        "indicatorCode": "ks601ew0008*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Economically inactive: Student (including full-time students) (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 27,
        "name": "Health (children)",
        "slug": "health-children",
        "title": "Children's health",
        "description": "<p>The following data is a selection of indicators relating to children's health. Understanding and monitoring children's health is crucial for policymakers and healthcare professionals. These children's health indicators provide valuable insights into well-being, areas of concern, and helps formulate evidence-based interventions and policies. By examining indicators such as birth outcomes, poverty rates, mortality rates, and healthcare utilisation, we gain a holistic understanding of the factors influencing children's health. This data aids in identifying vulnerable populations, tracking progress, addressing disparities, and ultimately improving the overall health outcomes and quality of life for children.</p></p>",
        "clientID": 1,
        "created_at": "2023-05-12T14:19:35.000000Z",
        "updated_at": "2023-11-10T09:44:25.000000Z",
        "use_for_summary": true,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 338,
                "reportThemeID": 27,
                "text": "<blockquote><h3>Income Deprivation Affecting Children Index (IDACI)</h3></blockquote><p></p><p>The bar chart below shows the Income Deprivation Affecting Children Index (IDACI), where a higher score indicates greater levels of deprivation. This specific measure comprises children aged 0 to 15 living in income deprived families, defined as families that either receive Income Support, Jobseekers Allowance, income-based Employment and Support Allowance, Pension Credit (Guarantee), Universal Credit (in the 'Searching for work', 'No work requirements', 'Planning for work', 'Working with requirements' and 'Preparing for work' conditionality groups) or families not in receipt of these benefits but in receipt of Working Tax Credit or Child Tax Credit with an equivalised income (excluding housing benefit) below 60 per cent of the national median before housing costs.</p><p>According to the 2019 version of the Index, 15.29% of children in Brighton and Hove are income deprived. This is lower than the proportion in England (17.08%) and higher than the proportion in South East (12.38%).</p><p><i>Note: Child asylum seekers are not included in the Income Deprivation Affecting Children Index.</i></p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 339,
                "reportThemeID": 27,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16839021727867",
                "chartDescription": "<p>Income Deprivation Affecting Children Index (IDACI)</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "Income Deprivation Affecting Children Index (IDACI)",
                "itemSource": "Ministry of Housing Communities and Local Government (MHCLG)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 771,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16839021727867",
                        "indicatorCode": "idaci15_num*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 769,
                        "y_label": null,
                        "indicatorName": "IoD 2015 Income Deprivation Affecting Children Index (IDACI) Score (rate)",
                        "source": "Communities and Local Government (CLG) (https://www.gov.uk/government/statistics/english-indices-of-deprivation-2015)",
                        "date": "2012-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2012-2013",
                        "shortName": null
                    },
                    {
                        "id": 772,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16839021727867",
                        "indicatorCode": "idaci19_num*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 776,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Income Deprivation Affecting Children Index (IDACI) Score (rate)",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2015-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2015-2016",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 340,
                "reportThemeID": 27,
                "text": "<blockquote><h3>Children living in absolute low-income families</h3></blockquote><p></p><p>The table below shows indicators related to children aged 0-19 in absolute low-income families (defined as a family with an income below 60% of median income). Here income is measured before housing costs are deducted and a family must have claimed one or more of; universal credit, tax credits or housing benefit at any point in the year to be classed as low income in these statistics.</p><p>5,788 children aged 0-19 in Brighton and Hove are in absolute low-income families (10.19%). Of these, 3,918 are from families who are in-work (67.69%), whereas 1,868 are from out-of-work families (32.27%). Additionally, 2,983 of these children are from lone-parent families (51.54%), whereas 2,800 are from couple families (48.38%).</p><p>The overall proportion of children aged 0-19 in absolute low-income families is lower in Brighton and Hove compared to England (15.58%) and similar in Brighton and Hove compared to South East (10.69%).</p><p><i> Note: Statistical disclosure control has been applied to these count figures to avoid the release of confidential data. Breakdowns may therefore not sum to the total amount of children in absolute low-income families due to the disclosure control applied.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 343,
                "reportThemeID": 27,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16839022536609",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": false,
                "itemName": "Children living in absolute low-income families",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 765,
                        "indicatorHTMLTableRef": "16839022536609",
                        "chartRef": null,
                        "indicatorCode": "child_abso_lif_0_19*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Children aged 0-19 in absolute low-income families",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 773,
                        "indicatorHTMLTableRef": "16839022536609",
                        "chartRef": null,
                        "indicatorCode": "child_abso_lif_0_19_outwork*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Children 0-19 in absolute low-income out of work families (as % of all in absolute low-income families)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 775,
                        "indicatorHTMLTableRef": "16839022536609",
                        "chartRef": null,
                        "indicatorCode": "child_abso_lif_0_19_inwork*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Children 0-19 in absolute low-income in-work families (as % of all in absolute low-income families)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 774,
                        "indicatorHTMLTableRef": "16839022536609",
                        "chartRef": null,
                        "indicatorCode": "child_abso_lif_0_19_lone*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Children 0-19 in absolute low-income lone parent families (as % of all in absolute low-income families)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 776,
                        "indicatorHTMLTableRef": "16839022536609",
                        "chartRef": null,
                        "indicatorCode": "child_abso_lif_0_19_couple*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Children 0-19 in absolute low-income couple families (as % of all in absolute low-income families)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 342,
                "reportThemeID": 27,
                "text": "<blockquote><h3>Children living in relative low-income families</h3></blockquote><p></p><p>The table below shows indicators related to children living in relative low-income families. This differs from absolute low-income families in that it is defined as families with an income below 60% of the median in 2023, rather than in 2010/11. Here income is measured before housing costs are deducted. A family must have claimed one or more of; universal credit, tax credits or housing benefit at any point in the year to be classed as low income in these statistics. Children are dependent individuals aged under 16; or aged 16 to 19 in full-time non-advanced education. Note: a family is defined as in-work if they have an accumulated a period of at least 26 weeks paid employment or self-employment within the 52-week tax year. Family type accounts for changes in family composition throughout the year (for example, re-partnering, multiple partners). A lone parent family type means the parent/guardian has had no partnerships at any point in the year.</p><p>7,048 children aged 0-19 in Brighton and Hove are in relative low-income families (12.41%). Of these, 5,152 are from families who are in-work (73.10%), whereas 1,893 are from out-of-work families (26.86%). Additionally, 3,597 of these children are from lone-parent families (51.04%), whereas 3,446 are from couple families (48.89%).</p><p>The overall proportion of children aged 0-19 in relative low-income families is lower in Brighton and Hove compared to England (19.69%) and similar in Brighton and Hove compared to South East (13.16%).</p><p><i> Note: Statistical disclosure control has been applied to these count figures to avoid the release of confidential data. Breakdowns may therefore not sum to the total amount of children in relative low-income families due to the disclosure control applied.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 344,
                "reportThemeID": 27,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16839023159161",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Children living in relative low-income families",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 766,
                        "indicatorHTMLTableRef": "16839023159161",
                        "chartRef": null,
                        "indicatorCode": "child_r_lif_0_19*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Children aged 0-19 in relative low-income families",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 777,
                        "indicatorHTMLTableRef": "16839023159161",
                        "chartRef": null,
                        "indicatorCode": "child_r_lif_0_19_outwork*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Children 0-19 in relative low-income out of work families (as % of all in relative low-income families)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 779,
                        "indicatorHTMLTableRef": "16839023159161",
                        "chartRef": null,
                        "indicatorCode": "child_r_lif_0_19_inwork*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Children 0-19 in relative low-income in-work families (as % of all in relative low-income families)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 778,
                        "indicatorHTMLTableRef": "16839023159161",
                        "chartRef": null,
                        "indicatorCode": "child_r_lif_0_19_lone*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Children 0-19 in relative low-income lone parent families (as % of all in relative low-income families)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    },
                    {
                        "id": 780,
                        "indicatorHTMLTableRef": "16839023159161",
                        "chartRef": null,
                        "indicatorCode": "child_r_lif_0_19_couple*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Children 0-19 in relative low-income couple families (as % of all in relative low-income families)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2023",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 345,
                "reportThemeID": 27,
                "text": "<blockquote><h3>Children providing unpaid care</h3></blockquote><p></p><p>The bar chart below shows the proportion of children (aged 0-15) that are providing informal unpaid care. The figures are based on self reported responses to the 2021 Census. A person is a provider of unpaid care if they give any help or support to family members, friends, neighbours or others because of long-term physical or mental health disability, or problems related to old age.</p><p>The proportion of children providing unpaid care in Brighton and Hove is 1.19%. This is similar to the proportion in England (1.06%) and similar to the proportion in South East (1.18%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 341,
                "reportThemeID": 27,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16839021869546",
                "chartDescription": "<p>Children providing unpaid care</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": false,
                "itemName": "Children providing unpaid care",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 811,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16839021869546",
                        "indicatorCode": "lc3304ew_unpaid_care_015*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 780,
                        "y_label": null,
                        "indicatorName": "Children providing unpaid care (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 346,
                "reportThemeID": 27,
                "text": "<blockquote><h3>Low birth weight</h3></blockquote><p></p><p>The table below shows data on the proportion of babies born with a low birth weight. This is the number of live and still births occurring in the year with a stated birth weight greater than 0 and less than 2,500 grams for all maternal ages. The denominator is all live and still births occurring in a year with a valid stated birth weight for all maternal ages.</p><p>The proportion of babies born with a low birth weight in Brighton and Hove between 2010 to 2013 was 7.03%. This is similar to the proportion in England (7.44%) and similar to the proportion in South East (6.68%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 931,
                "reportThemeID": 27,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16939150665578",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": "Low birth weight",
                "itemSource": "Office for National Statistics (ONS)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 6788,
                        "indicatorHTMLTableRef": "16939150665578",
                        "chartRef": null,
                        "indicatorCode": "low_birth_weight_n*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Low birth weight",
                        "source": "Office for National Statistics (ONS)",
                        "date": "2013-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2010 to 2013",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 350,
                "reportThemeID": 27,
                "text": "<h3>Infant mortality</h3><p></p><p>The table below shows the mortality rate for all causes of death for babies aged either under 28 days (neonatal) or under 1 (infant). The mortality rate is calculated from the sum total of deaths over the 5 year period, divided by the average population over those years and multiplied by 100 to provide a percentage.</p><p>The neonatal mortality rate in Brighton and Hove between 2018 to 2022 was 1.30%. This is similar to the rate in England (1.42%) and similar to the rate in South East (1.23%).</p><p>The infant mortality rate in Brighton and Hove between 2018 to 2022 was 1.64%. This is similar to the rate in England (1.95%) and similar to the rate in South East (1.07%).</p><p><i>Note: Data is suppressed when the number of deaths is less than 5, which is often the case when looking at MSOA-level data and below.</i></p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 10,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 353,
                "reportThemeID": 27,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16839026589738",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 11,
                "timeSeries": false,
                "itemName": "Infant mortality",
                "itemSource": "Nomis",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 769,
                        "indicatorHTMLTableRef": "16839026589738",
                        "chartRef": null,
                        "indicatorCode": "mortality_under28_days*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Deaths under 28 days (neonatal mortality), all causes",
                        "source": "Nomis",
                        "date": "2018-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2018 to 2022",
                        "shortName": null
                    },
                    {
                        "id": 784,
                        "indicatorHTMLTableRef": "16839026589738",
                        "chartRef": null,
                        "indicatorCode": "mortality_under1_all*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Deaths under 1 (infant mortality), all causes",
                        "source": "Nomis",
                        "date": "2018-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2018 to 2022",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 352,
                "reportThemeID": 27,
                "text": "<h3>Child mortality</h3><p></p><p>The table below shows the crude rate of mortality for all causes of death in persons aged either 0-4 or 0-14. The mortality rate is calculated from the sum total of deaths over the 5 year period, divided by the average population over those years and multiplied by 100 to provide a percentage.</p><p>The child mortality rate in Brighton and Hove between 2018 to 2022 was 0.31%. This is similar to the rate in England (0.42%) and similar to the rate in South East (0.23%).</p><p>The mortality rate of those aged under 15 in Brighton and Hove between 2018 to 2022 was 0.10%. This is similar to the rate in England (0.16%) and similar to the rate in South East (0.09%).</p><p><i>Note: Data is suppressed when the number of deaths is less than 5, which is often the case when looking at MSOA-level data and below.</i></p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 12,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 351,
                "reportThemeID": 27,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16839025541509",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 13,
                "timeSeries": false,
                "itemName": "Child mortality",
                "itemSource": "Nomis",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 768,
                        "indicatorHTMLTableRef": "16839025541509",
                        "chartRef": null,
                        "indicatorCode": "mortality_0_4_all_cause*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Deaths under 5 (child mortality), all causes",
                        "source": "Nomis",
                        "date": "2018-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2018 to 2022",
                        "shortName": null
                    },
                    {
                        "id": 785,
                        "indicatorHTMLTableRef": "16839025541509",
                        "chartRef": null,
                        "indicatorCode": "mortality_0_14_all_cause*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Deaths under 15, all causes",
                        "source": "Nomis",
                        "date": "2018-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2018 to 2022",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 354,
                "reportThemeID": 27,
                "text": "<blockquote><h3>Children and young people admitted to hospital</h3></blockquote><p></p><p>The bar chart below shows indicators for children and young people being admitted to hospital. It gives the crude rate of A&E attendances for children (aged under 5 years), per 1,000 resident population. This indicator is important because A&E attendances in children aged under five years are often preventable, and commonly caused by accidental injury or by minor illnesses which could have been treated in primary care. It also includes the crude rate of emergency hospital admissions for children (aged under 5 years), per 1,000 resident population. From a public health point of view, this emergency admissions data gives an indication of wider determinants of children's poor health, linked to areas such as housing and transport. Over one quarter of emergency hospital admissions in children aged under 5 years in 2014/15 was for respiratory infections. Factors such as smoking in the home and damp housing are known to increase the risk and severity of respiratory infections in young children.</p><p>There were 679.69 A&E attendances for every 1,000 children in Brighton and Hove between 2017 to 2020. This is similar to the rate in England (629.73) and higher than the rate in South East (561.74).</p><p>There were 118.89 emergency hospital admissions for every 1,000 children in Brighton and Hove between 2018 to 2021. This is lower than the rate in England (141.62) and similar to the rate in South East (129.78).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 14,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 355,
                "reportThemeID": 27,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16839027174271",
                "chartDescription": "<p>Children and young people admitted to hospital</p>",
                "chartXLabel": null,
                "chartYLabel": "Admissions per 1,000",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 15,
                "timeSeries": false,
                "itemName": "Children and young people admitted to hospital",
                "itemSource": "Hospital Episode Statistics (HES) NHS Digital",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 786,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16839027174271",
                        "indicatorCode": "a_and_e_attend_rate_children_under_5",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 775,
                        "y_label": null,
                        "indicatorName": "A&E attendance for under 5s",
                        "source": "Hospital Episode Statistics (HES)",
                        "date": "2017-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2017 to 2020",
                        "shortName": null
                    },
                    {
                        "id": 787,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16839027174271",
                        "indicatorCode": "emergency_hosp_children_under_5",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 777,
                        "y_label": null,
                        "indicatorName": "Emergency Hospital Admissions: All Causes Under 5s",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2018-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2018 to 2021",
                        "shortName": "All Causes Under 5s"
                    }
                ]
            },
            {
                "id": 356,
                "reportThemeID": 27,
                "text": "<h3>Obesity in Children</h3><p></p><p>The table below shows the proportion of children in either reception year (aged 4-5) or year 6 (aged 10-11) classified as obese (including severe obesity). </p><p>For population monitoring purposes, a child’s body mass index (BMI) is classed as obese where it is on or above the 95th centile based on the British 1990 (UK90) growth reference data. The population monitoring cut offs for overweight and obesity are lower than the clinical cut offs.This is to capture children in the population in the clinical obesity BMI categories and those who are at high risk of moving into the clinical obesity category.</p><p>From 2020 to 2023, 7.49% of children aged 4-5 were categorised as obese or severely obese in Brighton and Hove. This is lower than the rate in England (9.69%) and similar to the rate in South East (8.48%).</p><p>From 2020 to 2023, 16.79% of children aged 10-11 were categorised as obese or severely obese in Brighton and Hove. This is lower than the rate in England (22.47%) and lower than the rate in South East (19.08%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 16,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 357,
                "reportThemeID": 27,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16839027581368",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 17,
                "timeSeries": false,
                "itemName": "Obesity in Children",
                "itemSource": "National Child Measurement Programme, NHS Digital",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 7152,
                        "indicatorHTMLTableRef": "16839027581368",
                        "chartRef": null,
                        "indicatorCode": "obese_child_reception_yr_n",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Reception prevalence of obesity (including severe obesity), 3 years data combined",
                        "source": "Office for Health Improvement and Disparities (OHID)",
                        "date": "2020-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2020 to 2023",
                        "shortName": null
                    },
                    {
                        "id": 7151,
                        "indicatorHTMLTableRef": "16839027581368",
                        "chartRef": null,
                        "indicatorCode": "obese_child_yr_6_n",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Year 6 prevalence of obesity (including severe obesity), 3 years data combined",
                        "source": "Office for Health Improvement and Disparities (OHID)",
                        "date": "2020-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2020 to 2023",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 24,
        "name": "Health (disability and longterm illness)",
        "slug": "health-disability-and-longterm-illness",
        "title": "Health (Disability and Long-term Illness)",
        "description": "<p>The following data visualisations provide insights into the prevalence and distribution of disability and long-term illness. This data can help us understand and measure the impact of government policies and programs on those affected and provide an indication of the level of need and support required for people with disabilities in a local area.</p></p>",
        "clientID": 1,
        "created_at": "2023-05-05T14:35:55.000000Z",
        "updated_at": "2024-04-09T10:23:30.000000Z",
        "use_for_summary": true,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 302,
                "reportThemeID": 24,
                "text": "<blockquote><h3>Disability benefits</h3></blockquote><p></p><p>The table below shows a series of indicators related to disability benefits providing the total numbers of claims and proportions within each area that are accessing the support systems available. It includes the following benefits;</p><ul><li><p>Personal Independence Payment (PIP) can help with extra living costs if you have both: a long-term physical or mental health condition or disability and difficulty doing certain everyday tasks or getting around because of your condition.</p></li><li><p>Disability Living Allowance (DLA) is payable to children and adults who become disabled before the age of 65. It is being replaced by other benefits (mainly PIP).</p></li><li><p>Attendance Allowance helps with extra costs if you are over 65 and have a disability severe enough that you need someone to help look after you.</p></li><li><p>Universal Credit (UC) disabled child entitlement is an increased award for the child element of UC where a child is entitled to a rate of DLA or PIP.</p></li><li><p>Universal Credit (UC) limited capability to work entitlement is for when a household member has limited capacity to work due to poor mental or physical health conditions.</p></li></ul><p>8.06% of the working-age population in Brighton and Hove are receiving Personal Independence Payments (PIP). This is similar to the proportion in England (8.94%) and higher than the proportion in South East (6.82%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 293,
                "reportThemeID": 24,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16832990204742",
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "Disability benefits",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 670,
                        "indicatorHTMLTableRef": "16832990204742",
                        "chartRef": null,
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2024",
                        "shortName": null
                    },
                    {
                        "id": 671,
                        "indicatorHTMLTableRef": "16832990204742",
                        "chartRef": null,
                        "indicatorCode": "dla_total*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Disability benefit (DLA)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 672,
                        "indicatorHTMLTableRef": "16832990204742",
                        "chartRef": null,
                        "indicatorCode": "aac_tot_count*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Older people social care benefit (Attendance Allowance)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 673,
                        "indicatorHTMLTableRef": "16832990204742",
                        "chartRef": null,
                        "indicatorCode": "uc_disab_child_ent*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Households on Universal Credit, Disabled Child Entitlement",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    },
                    {
                        "id": 674,
                        "indicatorHTMLTableRef": "16832990204742",
                        "chartRef": null,
                        "indicatorCode": "uc_lcw*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Households on Universal Credit - Limited Capability for Work Entitlement",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 294,
                "reportThemeID": 24,
                "text": "<blockquote><h3>Personal Independence Payment (PIP)</h3></blockquote><p><br>The line chart below shows a time series of the proportion of the people (aged 16-64) currently receiving Personal Independence Payment (PIP). PIP helps with some of the extra costs caused by long-term disability, ill-health or terminal ill-health. From 8th April 2013 DWP started to replace Disability Living Allowance (DLA) for working age people with PIP so there has been a consistent increase in PIP since then as the process of replacing DLA continues.</p><p>In Jul-2024, 8.06% of those aged 16-64 in Brighton and Hove were receiving PIP. This rate has increased since Apr-2022 (6.23%). The latest rate is lower than that in England (8.94%) and higher than the proportion in South East (6.82%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 295,
                "reportThemeID": 24,
                "text": null,
                "chartType": "Line",
                "chartRef": "16832992598856",
                "chartDescription": "<p>Personal Independence Payment (PIP)</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": true,
                "itemName": "Personal Independence Payments (PIP)",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2013",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2013",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2013",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2013",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2013",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2013",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2013",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2013",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2013-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2013",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2014-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2014",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2015-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2015",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2016",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2016",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2016",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2016",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2016",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2016",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2016",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2016",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2016",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2016-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2016",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2017",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2017",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2017",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2017",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2017",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2017",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2017",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2017",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2017",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2017-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2017",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2018",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2018",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2018-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2018",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2019-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2019",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2020-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2020",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2021-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2021",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-05-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "May-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-06-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jun-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-08-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Aug-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-09-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Sep-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-11-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Nov-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2022-12-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Dec-2022",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2023",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-02-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Feb-2023",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-03-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Mar-2023",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2023",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2023",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2023-10-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Oct-2023",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jan-2024",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Apr-2024",
                        "shortName": null
                    },
                    {
                        "id": 3579,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16832992598856",
                        "indicatorCode": "pip_tot*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 946,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP)",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 296,
                "reportThemeID": 24,
                "text": "<p>The table below shows further insight into Personal Independence Payment (PIP) claimants. The breakdowns include numbers and proportions of male and female recipients as well as for those claiming for respiratory illness or mental health.</p><p>3.70% of residents in Brighton and Hove are receiving PIP due to mental health conditions. This is similar to the proportion in England (3.42%) and higher than the proportion in South East (2.74%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 297,
                "reportThemeID": 24,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16833001021703",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Personal Independence Payment claimants",
                "itemSource": "Department for Work and Pensions (DWP)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 676,
                        "indicatorHTMLTableRef": "16833001021703",
                        "chartRef": null,
                        "indicatorCode": "pip_female*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP), female",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2024",
                        "shortName": null
                    },
                    {
                        "id": 677,
                        "indicatorHTMLTableRef": "16833001021703",
                        "chartRef": null,
                        "indicatorCode": "pip_male*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP), male",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2024",
                        "shortName": null
                    },
                    {
                        "id": 678,
                        "indicatorHTMLTableRef": "16833001021703",
                        "chartRef": null,
                        "indicatorCode": "pip_mental*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP), mental health claimants",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2024",
                        "shortName": null
                    },
                    {
                        "id": 679,
                        "indicatorHTMLTableRef": "16833001021703",
                        "chartRef": null,
                        "indicatorCode": "pip_resp*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Personal Independence Payment (PIP), respiratory disease claimants",
                        "source": "Department for Work and Pensions (DWP)",
                        "date": "2024-07-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "Jul-2024",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 298,
                "reportThemeID": 24,
                "text": "<blockquote><h3>People who are disabled under the Equality Act</h3></blockquote><p></p><p>The table below shows figures for people disabled under the Equality Act with different limitations on day-to-day activity and also for those who have a long term physical or mental health condition but are not limited day to day. Figures are taken from responses to the 2021 Census and are based on a self assessment of whether or not a person has a limiting long-term illness, health problem or disability which limits their daily activities or the work they can do, including problems that are due to old age.</p><p>In Brighton and Hove, the proportion of people disabled under the Equality Act with their day-to-day activities limited a lot is 7.34%. This is similar to the proportion in England (7.33%) and higher than the proportion in South East (6.26%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 299,
                "reportThemeID": 24,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16833003141537",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": false,
                "itemName": "Disabled under the Equality Act",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2667,
                        "indicatorHTMLTableRef": "16833003141537",
                        "chartRef": null,
                        "indicatorCode": "disability_5a_1*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Disabled under the Equality Act: Day-to-day activities limited a lot (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2668,
                        "indicatorHTMLTableRef": "16833003141537",
                        "chartRef": null,
                        "indicatorCode": "disability_5a_2*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Disabled under the Equality Act: Day-to-day activities limited a little (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2669,
                        "indicatorHTMLTableRef": "16833003141537",
                        "chartRef": null,
                        "indicatorCode": "disability_5a_3*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Not disabled under the Equality Act: Has long term physical or mental health condition but day-to-day activities are not limited (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 300,
                "reportThemeID": 24,
                "text": "<blockquote><h3>Number of people in households who are disabled under the Equality Act</h3></blockquote><p></p><p>The table below shows data on the number of households with at least one person who is disabled under the Equality Act.</p><p>In Brighton and Hove, the proportion of households with two or more disabled people is 6.39%. This is similar to the proportion in England (6.65%) and similar to the proportion in South East (6.10%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 301,
                "reportThemeID": 24,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16833005025274",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": "People with a limiting long-term illness",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 2670,
                        "indicatorHTMLTableRef": "16833005025274",
                        "chartRef": null,
                        "indicatorCode": "hh_disabled_4a01*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "1 person disabled under the Equality Act in household (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 2671,
                        "indicatorHTMLTableRef": "16833005025274",
                        "chartRef": null,
                        "indicatorCode": "hh_disabled_4a02*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "2 or more people disabled under the Equality Act in household (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 25,
        "name": "Health (general health and unpaid care)",
        "slug": "health-general-and-unpaid-care",
        "title": "Health (General Health and Unpaid Care)",
        "description": "<p>The following visualisations offer insight into various health related data for Brighton and Hove, England and South East. It includes prevalence of specific chronic conditions, emergency hospital admissions, self-harm or alcohol related hospital admissions, self-reported health quality, and estimated population activity levels. This selection of data together provides a comprehensive overview of an area's health landscape, shedding light on critical indicators with significant impact on individual and public well-being.</p></p>",
        "clientID": 1,
        "created_at": "2023-05-09T11:20:11.000000Z",
        "updated_at": "2024-08-29T12:21:50.000000Z",
        "use_for_summary": true,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 303,
                "reportThemeID": 25,
                "text": "<blockquote><h3>Indices of Deprivation (IoD) health deprivation and disability domain</h3></blockquote><p></p><p>The bar chart below shows the proportion of people living in neighbourhoods, grouped into deciles, according to the level of deprivation derived from The Indices of Deprivation (IoD) 2019 health deprivation and disability domain.</p><p>The IoD health deprivation and disability domain measures the risk of premature death and the impairment of quality of life through poor physical or mental health. The domain measures morbidity, disability and premature mortality but not aspects of behaviour or environment that may be predictive of future health deprivation. The following indicators are included: Years of potential life lost: An age and sex standardised measure of premature death; Comparative illness and disability ratio: An age and sex standardised morbidity/disability ratio; Acute morbidity: An age and sex standardised rate of emergency admission to hospital; Mood and anxiety disorders: A composite based on the rate of adults suffering from mood and anxiety disorders hospital episodes data, and suicide mortality data.</p><p>A higher score in this IoD domain indicates that an area is experiencing greater levels of deprivation relating to health and disability measures. To clarify, all neighbourhoods in England are grouped into ten equal sized groups “deciles”; neighbourhoods in decile 1 fall within the most deprived 10% of neighbourhoods nationally and neighbourhoods in decile 10 fall within the least deprived 10% of neighbourhoods nationally.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 304,
                "reportThemeID": 25,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16836340554220",
                "chartDescription": "<p>IoD (health deprivation and disability domain)</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "IoD (health deprivation and disability domain)",
                "itemSource": "Communities and Local Government (CLG)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 7102,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836340554220",
                        "indicatorCode": "id19_heapop_dec1*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 48,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Health Decile 1",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 7103,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836340554220",
                        "indicatorCode": "id19_heapop_dec2*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 50,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Health Decile 2",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 7104,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836340554220",
                        "indicatorCode": "id19_heapop_dec3*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 52,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Health Decile 3",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 7105,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836340554220",
                        "indicatorCode": "id19_heapop_dec4*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 54,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Health Decile 4",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 7106,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836340554220",
                        "indicatorCode": "id19_heapop_dec5*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 55,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Health Decile 5",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 7107,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836340554220",
                        "indicatorCode": "id19_heapop_dec6*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 58,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Health Decile 6",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 7108,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836340554220",
                        "indicatorCode": "id19_heapop_dec7*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 59,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Health Decile 7",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 7109,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836340554220",
                        "indicatorCode": "id19_heapop_dec8*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 60,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Health Decile 8",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 7110,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836340554220",
                        "indicatorCode": "id19_heapop_dec9*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 61,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Health Decile 9",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    },
                    {
                        "id": 7111,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836340554220",
                        "indicatorCode": "id19_heapop_dec10*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 63,
                        "y_label": null,
                        "indicatorName": "IoD 2019 Health Decile 10",
                        "source": "Communities and Local Government (CLG)",
                        "date": "2019-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2019",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 305,
                "reportThemeID": 25,
                "text": "<blockquote><h3>Self-reported general health</h3></blockquote><p></p><p>The table below shows data from Census 2021 on the total number and proportion of residents within each response category from a self-assessment of general health.</p><p>50.19% of people in Brighton and Hove have very good health. This is higher than the proportion in England (48.49%) and similar to the proportion in South East (49.98%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 306,
                "reportThemeID": 25,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16836375462055",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": false,
                "itemName": "Self-reported general health",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 691,
                        "indicatorHTMLTableRef": "16836375462055",
                        "chartRef": null,
                        "indicatorCode": "ks301ew0008*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Very good health (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 692,
                        "indicatorHTMLTableRef": "16836375462055",
                        "chartRef": null,
                        "indicatorCode": "ks301ew0009*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "Good health (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 693,
                        "indicatorHTMLTableRef": "16836375462055",
                        "chartRef": null,
                        "indicatorCode": "ks301ew0010*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "Fair health (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 694,
                        "indicatorHTMLTableRef": "16836375462055",
                        "chartRef": null,
                        "indicatorCode": "ks301ew0011*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "Bad health (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 695,
                        "indicatorHTMLTableRef": "16836375462055",
                        "chartRef": null,
                        "indicatorCode": "ks301ew0012*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "Very bad health (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 307,
                "reportThemeID": 25,
                "text": "<blockquote><h3>Self-reported general health</h3></blockquote><p></p><p>The bar chart below further shows the people in the 'very bad health' category and the changes between the most recent and previous comparable censuses. Figures are taken from responses to the UK Census based on a self assessment of general health.</p><p>In Brighton and Hove the proportion of people suffering with very bad health has remained stable since 2011, changing from 1.20% to 1.18%. This latest figure is similar to the proportion in England (1.17%) and similar to the proportion in South East (0.93%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 308,
                "reportThemeID": 25,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16836379893491",
                "chartDescription": "<p>Self reported - very bad health</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Self reported - very bad health",
                "itemSource": "UK Census",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 696,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836379893491",
                        "indicatorCode": "ks301ew0012*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 729,
                        "y_label": null,
                        "indicatorName": "Very bad health (Census 2021)",
                        "source": "Census 2021",
                        "date": "2001-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2001",
                        "shortName": null
                    },
                    {
                        "id": 696,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836379893491",
                        "indicatorCode": "ks301ew0012*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 729,
                        "y_label": null,
                        "indicatorName": "Very bad health  (E&W Census 2011)",
                        "source": "Census 2011",
                        "date": "2011-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2011",
                        "shortName": null
                    },
                    {
                        "id": 696,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836379893491",
                        "indicatorCode": "ks301ew0012*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 729,
                        "y_label": null,
                        "indicatorName": "Very bad health (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 309,
                "reportThemeID": 25,
                "text": "<blockquote><h3>Disease prevalence</h3></blockquote><p></p><p>The bar chart below shows the estimated percentage of various disease prevalence. These estimates are calculated based on the number of people listed on GP registers in 2022/23, and the number of people recorded as having the relevant health conditions. The data from England's GP practices was published by NHS digital at small area level, therefore the data showing at local authority or a higher level has been aggregated from smaller geographies.</p><p>High blood pressure, obesity and depression are often the most prevalent three conditions in population samples from the categories. 13.43% of people in Brighton and Hove are recorded as having depression. This is similar to the proportion in England (13.41%) and similar to the proportion in South East (13.83%).</p><p>10.00% of people in Brighton and Hove are recorded as having high blood pressure. This is lower than the proportion in England (14.63%) and lower than the proportion in South East (14.69%).</p><p>7.00% of people are recorded as obese in Brighton and Hove. This is lower than the proportion in England (11.55%) and lower than the proportion in South East (10.31%).</p><p><i>Note: These are only estimates and they are sensitive to the accuracy of GP data reporting. Some differences between areas may reflect differences in the way that GP practices operate, measure. and record, rather than genuine differences in prevalence. </i></p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 310,
                "reportThemeID": 25,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16836383911192",
                "chartDescription": "<p>Disease prevalence</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": false,
                "itemName": "Disease prevalence",
                "itemSource": "House of Commons Library",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 697,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_asthma",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 726,
                        "y_label": null,
                        "indicatorName": "Asthma prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 698,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_cancer",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 730,
                        "y_label": null,
                        "indicatorName": "Cancer prevalence (diagnosis since 2003)",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 699,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_heart",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 733,
                        "y_label": null,
                        "indicatorName": "Coronary Heart Disease prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 700,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_kidney",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 735,
                        "y_label": null,
                        "indicatorName": "Chronic Kidney Disease prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 701,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_copd",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 736,
                        "y_label": null,
                        "indicatorName": "COPD prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 703,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_dementia",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 737,
                        "y_label": null,
                        "indicatorName": "Dementia prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 704,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_depress",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 739,
                        "y_label": null,
                        "indicatorName": "Depression prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 705,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_diabetes",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 740,
                        "y_label": null,
                        "indicatorName": "Diabetes prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 706,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_epilepsy",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 741,
                        "y_label": null,
                        "indicatorName": "Epilepsy prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 707,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_heart_fail",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 743,
                        "y_label": null,
                        "indicatorName": "Heart Failure prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 708,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_high_blood_pres",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 746,
                        "y_label": null,
                        "indicatorName": "High Blood Pressure prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 709,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_learning_dis",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 747,
                        "y_label": null,
                        "indicatorName": "Learning Disabilities prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 711,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_obesity",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 750,
                        "y_label": null,
                        "indicatorName": "Obesity prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 712,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_osteoporisis",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 751,
                        "y_label": null,
                        "indicatorName": "Osteoporosis prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 713,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_periph_arterial",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 752,
                        "y_label": null,
                        "indicatorName": "Peripheral Arterial Disease prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 6548,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_prediabetes",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 754,
                        "y_label": null,
                        "indicatorName": "Pre-Diabetes prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 715,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_rheuma_arth",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 756,
                        "y_label": null,
                        "indicatorName": "Rheumatoid Arthritis prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    },
                    {
                        "id": 716,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836383911192",
                        "indicatorCode": "prev_stroke_trans_isch",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 757,
                        "y_label": null,
                        "indicatorName": "Stroke and Transient Ischaemic Attack prevalence",
                        "source": "NHS Digital via House of Commons Library",
                        "date": "2022-04-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2022/23",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 311,
                "reportThemeID": 25,
                "text": "<blockquote><h3>Emergency hospital admissions</h3></blockquote><p></p><p>The bar chart below shows emergency admissions to hospital for various conditions. The NHS Data Model and Dictionary defines emergency admissions as those which are 'unpredictable and at short notice because of clinical need'. This indicator allows the level of such admissions at a local level to be compared to those expected given the age structure of local populations. High levels of emergency admissions may be due to a variety of causes such as high levels of injury within a population or poor management of chronic conditions within primary care. It should be viewed as an indication of the levels of unplanned secondary care use within a population and care should be taken when interpreting these results. To clarify, figures of over 100% indicate actual admissions were above the expected.</p><p>In Brighton and Hove, the proportion of emergency admissions for strokes was 91.07% of the predicted. This is lower than the proportion in England (100.00%) and similar to the proportion in South East (90.66%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 312,
                "reportThemeID": 25,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16836392536617",
                "chartDescription": "<p>Emergency hospital admissions</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": "Emergency hospital admissions",
                "itemSource": "Office for Health Improvement and Disparities (OHID)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 717,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836392536617",
                        "indicatorCode": "emerg_hosp_admiss_all_causes_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 744,
                        "y_label": null,
                        "indicatorName": "Emergency Hospital Admissions: All Causes",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2021",
                        "shortName": null
                    },
                    {
                        "id": 718,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836392536617",
                        "indicatorCode": "emerg_hosp_admiss_chd_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 748,
                        "y_label": null,
                        "indicatorName": "Emergency Hospital Admissions: Coronary Heart Disease",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2021",
                        "shortName": null
                    },
                    {
                        "id": 719,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836392536617",
                        "indicatorCode": "emerg_hosp_admiss_copd_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 753,
                        "y_label": null,
                        "indicatorName": "Emergency Hospital Admissions: Chronic Obstructive Pulmonary Disease (COPD)",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2021",
                        "shortName": null
                    },
                    {
                        "id": 720,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836392536617",
                        "indicatorCode": "emerg_hosp_admiss_hip_fracture_over65_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 755,
                        "y_label": null,
                        "indicatorName": "Emergency Hospital Admissions: Hip Fracture (65+)",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2021",
                        "shortName": null
                    },
                    {
                        "id": 721,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836392536617",
                        "indicatorCode": "emerg_hosp_admiss_myocardial_inf_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 759,
                        "y_label": null,
                        "indicatorName": "Emergency Hospital Admissions: Myocardial Infarction",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2021",
                        "shortName": null
                    },
                    {
                        "id": 722,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836392536617",
                        "indicatorCode": "emerg_hosp_admiss_stroke_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 763,
                        "y_label": null,
                        "indicatorName": "Emergency Hospital Admissions: Stroke",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 313,
                "reportThemeID": 25,
                "text": "<h3>Hospital admissions for alcohol attributable conditions</h3><p></p><p>The bar chart below shows the standardised emergency admission ratio (SAR) for hospital stays for alcohol attributable conditions (broad definition). This is defined as whenever the primary diagnosis or any of the secondary diagnoses contain an alcohol-attributable condition. Children under 16 were only included if they had an alcohol-specific diagnosis i.e. where the alcohol-attributable fraction (AAF) equalled one, meaning that alcohol consumption was a contributory factor in all cases. For other conditions, the AAF estimates were not available for children. A SAR is a measure of how more or less likely a person living in that area is to have an emergency admission to hospital compared to the standard population, in this case England, where more than 100 would show more likely and vice versa.</p><p>In Brighton and Hove the SAR was 79.63 being admitted for alcohol attributable hospital stays. This is lower than in England (100.00) and lower than in South East (85.43).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 10,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 314,
                "reportThemeID": 25,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16836396542531",
                "chartDescription": "<p>Hospital admissions for alcohol attributable harm</p>",
                "chartXLabel": null,
                "chartYLabel": "SAR",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 11,
                "timeSeries": false,
                "itemName": "Hospital admissions for alcohol attributable conditions",
                "itemSource": "Office for Health Improvement and Disparities (OHID)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 7148,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836396542531",
                        "indicatorCode": "hosp_stays_alcohol_rel_harm_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 29,
                        "y_label": null,
                        "indicatorName": "Alcohol attributable harm (SAR)",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 315,
                "reportThemeID": 25,
                "text": "<h3>Hospital stays for intentional self harm</h3><p></p><p>The bar chart below shows the standardised emergency admission ratio (SAR) for hospital stays for intentional self harm (ICD10 code X60 to X84). A SAR is a measure of how more or less likely a person living in that area is to have an emergency admission to hospital compared to the standard population, in this case England, where more than 100 would show more likely and vice versa.</p><p>In Brighton and Hove the SAR was 148.14 being admitted for intentional self-harm hospital stays. This is higher than in England (100.00) and higher than in South East (107.33).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 12,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 316,
                "reportThemeID": 25,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16836397547393",
                "chartDescription": "<p>Hospital stays for intentional self harm</p>",
                "chartXLabel": null,
                "chartYLabel": "SAR",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 13,
                "timeSeries": false,
                "itemName": "Hospital stays for intentional self harm",
                "itemSource": "Office for Health Improvement and Disparities (OHID)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 7149,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836397547393",
                        "indicatorCode": "hosp_stay_self_harm_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 28,
                        "y_label": null,
                        "indicatorName": "Hospital stays for intentional self harm",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 319,
                "reportThemeID": 25,
                "text": "<blockquote><h3>Provision of unpaid care</h3></blockquote><p></p><p>The table below shows the proportion of people providing unpaid care for different amounts of time each week. Figures are based on the self reported responses to the UK Census. A person is a provider of unpaid care if they give any help or support to family members, friends, neighbours or others because of long-term physical or mental health or disability, or problems related to old age. The figures include all people of all ages providing unpaid care.</p><p>92.16% of people in Brighton and Hove provide no unpaid care. This is similar to the proportion in England (91.24%) and similar to the proportion in South East (91.65%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 16,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 320,
                "reportThemeID": 25,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16836420086887",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 17,
                "timeSeries": false,
                "itemName": "Provision of unpaid care",
                "itemSource": "Census 2021",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 730,
                        "indicatorHTMLTableRef": "16836420086887",
                        "chartRef": null,
                        "indicatorCode": "ks301ew0013*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "No unpaid care (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 3580,
                        "indicatorHTMLTableRef": "16836420086887",
                        "chartRef": null,
                        "indicatorCode": "is_carer_7a02*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 2,
                        "y_label": null,
                        "indicatorName": "9 hours or less unpaid care a week (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 3581,
                        "indicatorHTMLTableRef": "16836420086887",
                        "chartRef": null,
                        "indicatorCode": "is_carer_7a03*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 3,
                        "y_label": null,
                        "indicatorName": "10 to 19 hours unpaid care a week (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 3582,
                        "indicatorHTMLTableRef": "16836420086887",
                        "chartRef": null,
                        "indicatorCode": "is_carer_7a04*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 4,
                        "y_label": null,
                        "indicatorName": "20 to 34 hours unpaid care a week (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 3583,
                        "indicatorHTMLTableRef": "16836420086887",
                        "chartRef": null,
                        "indicatorCode": "is_carer_7a05*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 5,
                        "y_label": null,
                        "indicatorName": "35 to 49 hours unpaid care a week (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 733,
                        "indicatorHTMLTableRef": "16836420086887",
                        "chartRef": null,
                        "indicatorCode": "ks301ew0016*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 6,
                        "y_label": null,
                        "indicatorName": "50+ hours unpaid care a week (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 321,
                "reportThemeID": 25,
                "text": "<blockquote><h3>Provision of intensive unpaid care (50+ hours per week)</h3></blockquote><p></p><p>The bar chart below shows the proportion of people providing unpaid care for 50 or more hours per week. Figures are based on self reported responses to the most recent and earlier comparable UK Censuses. A person is a provider of unpaid care if they give any help or support to family members, friends, neighbours or others because of long-term physical or mental health or disability, or problems related to old age. The figures include all people of all ages providing unpaid care.</p><p>2.05% of people in Brighton and Hove provide 50+ hours of unpaid care a week. This is lower than the proportion in England (2.63%) and similar to the proportion in South East (2.39%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 18,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 322,
                "reportThemeID": 25,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16836428086453",
                "chartDescription": "<p>Provision of intensive unpaid care (50+ hours per week)</p>",
                "chartXLabel": null,
                "chartYLabel": "Percent %",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 19,
                "timeSeries": false,
                "itemName": "Provision of intensive unpaid care (50+ hours per week)",
                "itemSource": "UK Census",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 734,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836428086453",
                        "indicatorCode": "ks301ew0016*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 466,
                        "y_label": null,
                        "indicatorName": "50+ hours unpaid care a week  (E&W Census 2011)",
                        "source": "Census 2011",
                        "date": "2011-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2011",
                        "shortName": null
                    },
                    {
                        "id": 734,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836428086453",
                        "indicatorCode": "ks301ew0016*dr",
                        "indicatorDate": "asc",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 466,
                        "y_label": null,
                        "indicatorName": "50+ hours unpaid care a week (Census 2021)",
                        "source": "Census 2021",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 323,
                "reportThemeID": 25,
                "text": "<blockquote><h3>Physical activity among adults</h3></blockquote><p></p><p>This chart below shows the modelled estimated percentage of adults (aged 16+) who are classed as either; active, inactive, or to have participated in sport and physical activity (excluding gardening) at least twice in the last 28 days' to provide insight into the level of physical activity in an area.</p><p>In Brighton and Hove the proportion as a decimal is 73.19% of all adults are classed as active. This is higher than the proportion in England (63.71%) and higher than the proportion in South East (66.15%)</p><p><i> People are described as being inactive if they have done fewer than 30 minutes of moderate intensity equivalent (MIE) physical activity (excluding gardening) in the past week. Activity is counted in MIE minutes whereby each 'moderate' minute counts as one minute and each 'vigorous' minute counts as two moderate minutes. Moderate activity is defined as activity where you raise your breathing rate, whereas vigorous activity is defined as doing activity where you are out of breath or are sweating (you may not be able to say more than a few words without pausing for breath). For the category of people who have participated in sport and physical activity at least twice in the last 28 days it is defined as each session being at least 10 minutes and of at least moderate intensity. An individual can reach the minimum threshold by a combination of two 30 minute sessions across the last 28 days or by six 10 minute sessions, for example.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 20,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 324,
                "reportThemeID": 25,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16836433822600",
                "chartDescription": "<p>Physical activity among adults</p>",
                "chartXLabel": null,
                "chartYLabel": "Proportion",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 21,
                "timeSeries": false,
                "itemName": "Physical activity among adults",
                "itemSource": "Sport England",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 735,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836433822600",
                        "indicatorCode": "spact_active_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 761,
                        "y_label": null,
                        "indicatorName": "Adults who are physically active",
                        "source": "Sport England: Active Lives Survey",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 736,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836433822600",
                        "indicatorCode": "spact_inactive_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 767,
                        "y_label": null,
                        "indicatorName": "Adults who are physically inactive",
                        "source": "Sport England: Active Lives Survey",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    },
                    {
                        "id": 737,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16836433822600",
                        "indicatorCode": "spact_active2month_r",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 768,
                        "y_label": null,
                        "indicatorName": "Participation in physical activity at least twice in the last month",
                        "source": "Sport England: Active Lives Survey",
                        "date": "2021-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2021",
                        "shortName": null
                    }
                ]
            }
        ]
    },
    {
        "id": 26,
        "name": "Health (mortality)",
        "slug": "health-mortality",
        "title": "Health (Mortality)",
        "description": "<p>The following data visualisations provide a variety of mortality indicators including; life expectancy, causes of death, and years of potential life lost as a result of deprivation. Mortality data is a valuable resource for decision making in public policy, resource allocation and addressing health inequalities to improve population health and well-being.</p></p>",
        "clientID": 1,
        "created_at": "2023-05-10T09:51:21.000000Z",
        "updated_at": "2024-04-09T10:25:39.000000Z",
        "use_for_summary": false,
        "allow_for_custom": true,
        "country": "England",
        "config": [
            {
                "id": 325,
                "reportThemeID": 26,
                "text": "<h3>Life expectancy</h3><p></p><p>The bar chart below shows male and female life expectancy at birth. Life expectancy at birth is a useful summary measure of all cause mortality as it quantifies the differences between areas in units (years of life) that are more readily understood and meaningful to the audience than those of other measures. All cause mortality is a fundamental and probably the oldest measure of the health status of a population. It represents the cumulative effect of the prevalence of risk factors, the prevalence and severity of disease, and the effectiveness of interventions and treatment. Differences in levels of all cause mortality reflect health inequalities between different population groups, e.g. between genders, social classes, and ethnic groups.</p><p>In Brighton and Hove the male life expectancy from birth is 79.19 years old. This is similar to the life expectancy in England (79.49 years old) and lower than the life expectancy in South East (80.53 years old).</p><p>The female life expectancy from birth in Brighton and Hove is 83.08 years old. This is similar to the life expectancy in England (83.17 years old) and similar to the life expectancy in South East (84.05 years old).</p><p><i>Note: Some areas may be missing data due to data suppression for data quality purposes.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 0,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 326,
                "reportThemeID": 26,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16837183573636",
                "chartDescription": "<p>Life expectancy at birth</p>",
                "chartXLabel": null,
                "chartYLabel": "Age",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 1,
                "timeSeries": false,
                "itemName": "Life expectancy",
                "itemSource": "Office for National Statistics (ONS)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 7099,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837183573636",
                        "indicatorCode": "male_life_expectancy",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 62,
                        "y_label": null,
                        "indicatorName": "Male life expectancy at birth",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2021",
                        "shortName": null
                    },
                    {
                        "id": 7100,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837183573636",
                        "indicatorCode": "female_life_expectancy",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 64,
                        "y_label": null,
                        "indicatorName": "Female life expectancy at birth",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2021",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 327,
                "reportThemeID": 26,
                "text": "<blockquote><h3>Years of potential life lost</h3></blockquote><p></p><p>The bar chart below shows the Indices of Deprivation (IoD) Years of potential life lost (YPLL) score covering the years 2012 to 2015. The score is a measure that helps us understand the impact of deprivation on people's health and well-being. It focuses specifically on the number of years of life that are lost prematurely due to various factors related to deprivation. It calculates the number of years that individuals die before they would be expected to, based on average life expectancy. For example, if someone dies at the age of 60, but the average life expectancy in their area is 75, they would contribute 15 years to the YPLL score. By examining this score, we can identify areas where deprivation has a significant impact on people's health and longevity.</p><p>In Brighton and Hove the YPLL score is 61.65 years. This is higher than the score for England (57.06) and higher than the score for South East (50.61).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 2,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 328,
                "reportThemeID": 26,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16837255774723",
                "chartDescription": "<p>IoD Years of potential life lost</p>",
                "chartXLabel": null,
                "chartYLabel": "Score (years)",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 3,
                "timeSeries": false,
                "itemName": "IoD Years of potential life lost",
                "itemSource": "Ministry of Housing Communities and Local Government (MHCLG)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 740,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837255774723",
                        "indicatorCode": "id15_ypll",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 758,
                        "y_label": null,
                        "indicatorName": "IoD Years of Potential Life Lost",
                        "source": "Communities and Local Government (CLG) (https://www.gov.uk/government/statistics/english-indices-of-deprivation-2015)",
                        "date": "2008-01-01",
                        "decimalPlaces": 3,
                        "dateCoverageText": "2008 to 2011",
                        "shortName": null
                    },
                    {
                        "id": 741,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837255774723",
                        "indicatorCode": "id19_ypll",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 760,
                        "y_label": null,
                        "indicatorName": "IoD Years of Potential Life Lost",
                        "source": "Ministry of Housing Communities and Local Government (MHCLG)",
                        "date": "2012-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2012 to 2015",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 329,
                "reportThemeID": 26,
                "text": "<h3>Mortality from key causes</h3><p></p><p>The bar chart below shows age standardised estimates of deaths from various key causes. The data is presented as a standardised mortality ratio calculated by dividing the observed total deaths in the area (by five year age and gender band) by the expected deaths (applying age-specific death rates for England) and multiplying by 100.</p><p>In Brighton and Hove the actual number of deaths from cancer was 108.85% of the predicted number. This is higher than the percentage in England (100.00%) and higher than the percentage in South East (95.88%).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 4,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 330,
                "reportThemeID": 26,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16837260197747",
                "chartDescription": "<p>Mortality from key causes</p>",
                "chartXLabel": null,
                "chartYLabel": "% of predicted",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": true,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 5,
                "timeSeries": false,
                "itemName": "Mortality from key causes",
                "itemSource": "Office for National Statistics (ONS)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 742,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837260197747",
                        "indicatorCode": "deaths_all_ages_all_cancer_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 762,
                        "y_label": null,
                        "indicatorName": "Deaths from cancer",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2020",
                        "shortName": null
                    },
                    {
                        "id": 743,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837260197747",
                        "indicatorCode": "deaths_all_ages_all_causes_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 764,
                        "y_label": null,
                        "indicatorName": "Deaths (all causes)",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2020",
                        "shortName": null
                    },
                    {
                        "id": 744,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837260197747",
                        "indicatorCode": "deaths_all_ages_chd_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 765,
                        "y_label": null,
                        "indicatorName": "Deaths all ages, coronary heart disease (CHD)",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2020",
                        "shortName": null
                    },
                    {
                        "id": 745,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837260197747",
                        "indicatorCode": "deaths_all_ages_circ_dis_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 766,
                        "y_label": null,
                        "indicatorName": "Deaths all ages, circulatory disease",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2020",
                        "shortName": null
                    },
                    {
                        "id": 746,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837260197747",
                        "indicatorCode": "deaths_all_ages_resp_dis_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 770,
                        "y_label": null,
                        "indicatorName": "Deaths all ages, respiratory disease",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2020",
                        "shortName": null
                    },
                    {
                        "id": 747,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837260197747",
                        "indicatorCode": "deaths_all_ages_stroke_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 771,
                        "y_label": null,
                        "indicatorName": "Deaths all ages, stroke",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2020",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 331,
                "reportThemeID": 26,
                "text": "<h3>Mortality from key causes (aged under 75)</h3><p></p><p>The bar chart below further details mortality from key causes. It shows age standardised estimates of deaths for people aged under 75. The data is again presented as a standardised mortality ratio calculated by dividing the observed total deaths in the area by the expected deaths and multiplying by 100.</p><p>In Brighton and Hove the actual number of deaths in under 75's was 96.89% of the predicted number. This is lower than the percentage in England (100.00% of predicted) and higher than the percentage in South East (83.62% of predicted).</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 6,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 332,
                "reportThemeID": 26,
                "text": null,
                "chartType": "Bar",
                "chartRef": "16837926092699",
                "chartDescription": "<p>Mortality from key causes (aged under 75)</p>",
                "chartXLabel": null,
                "chartYLabel": "% of predicted",
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 7,
                "timeSeries": false,
                "itemName": "Mortality from key causes (aged under 75)",
                "itemSource": "Office for National Statistics (ONS)",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 748,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837926092699",
                        "indicatorCode": "deaths_und75_all_causes_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 774,
                        "y_label": null,
                        "indicatorName": "Deaths under 75, all causes",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2020",
                        "shortName": null
                    },
                    {
                        "id": 749,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837926092699",
                        "indicatorCode": "deaths_und75_circ_dis_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1159,
                        "y_label": null,
                        "indicatorName": "Deaths under 75, circulatory disease",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2020",
                        "shortName": null
                    },
                    {
                        "id": 750,
                        "indicatorHTMLTableRef": null,
                        "chartRef": "16837926092699",
                        "indicatorCode": "deaths_und75_all_cancer_static_rate",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1167,
                        "y_label": null,
                        "indicatorName": "Deaths under 75, all cancers",
                        "source": "Office for Health Improvements and Disparities (OHID)",
                        "date": "2016-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2016 to 2020",
                        "shortName": null
                    }
                ]
            },
            {
                "id": 333,
                "reportThemeID": 26,
                "text": "<h3>Mortality for people aged 0-64</h3><p></p><p>In Brighton and Hove the rate of deaths for the under 65 age group was 0.83%. This is similar to the rate in England (0.89%) and similar to the rate in South East (0.79% of the expected).</p><p><i>Note: Rates are suppressed in the raw data when the number of deaths is less than 5 which is often the case when looking at MSOA data.</p></p>",
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": null,
                "HTMLTableType": 1,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 8,
                "timeSeries": false,
                "itemName": null,
                "itemSource": null,
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": []
            },
            {
                "id": 334,
                "reportThemeID": 26,
                "text": null,
                "chartType": null,
                "chartRef": null,
                "chartDescription": null,
                "chartXLabel": null,
                "chartYLabel": null,
                "syncChartWithHTMLTableRef": null,
                "inverseChartData": false,
                "chartXCommaSeperatedValues": null,
                "chartYCommaSeperatedValues": null,
                "indicatorHTMLTableRef": "16837956529704",
                "HTMLTableType": 2,
                "indicatorHTMLTableDescription": null,
                "domainHTMLTableColOneText": null,
                "domainHTMLTableColTwoText": null,
                "order": 9,
                "timeSeries": false,
                "itemName": "Mortality",
                "itemSource": "Nomis",
                "showComparators": true,
                "kpi_config": null,
                "display_data_point_markers": false,
                "left_label": null,
                "right_label": null,
                "comparator": null,
                "concatenate_dates": false,
                "use_short_indicator_name": false,
                "indicators": [
                    {
                        "id": 751,
                        "indicatorHTMLTableRef": "16837956529704",
                        "chartRef": null,
                        "indicatorCode": "mortality_0_64_all_cause*dr",
                        "indicatorDate": "0",
                        "chartColour": null,
                        "domainHTMLTableColTwo": false,
                        "order": 1,
                        "y_label": null,
                        "indicatorName": "Deaths under 65, all causes",
                        "source": "Nomis",
                        "date": "2018-01-01",
                        "decimalPlaces": 2,
                        "dateCoverageText": "2018 to 2022",
                        "shortName": null
                    }
                ]
            }
        ]
    }
    ]
  }),
  components: { ReportViewer },
  async mounted() {},
  watch: {},
  computed: {
    // viewReportDialog: {
    //   get() {
    //     return this.$store.state.viewReportDialog;
    //   },
    //   set(value) {
    //     this.$store.commit("setViewReportDialog", value);
    //   },
    // },
  },
  methods: {
    // showDialog() {
    //     this.viewReportDialog = true;
    // }
  },
};
</script>

<style scoped></style>
