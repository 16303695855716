<template>
  <v-chip density="compact" class="py-1 px-2 mx-2 beta-chip"> BETA </v-chip>
</template>

<script>
export default {
  name: "BETACHIP",
  data: () => ({}),
};
</script>

<style scoped>
.beta-chip {
  background-color: #ffeaa5;
  color: #af6200;
}
</style>
