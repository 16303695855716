<template>
  <v-dialog
    v-model="openSelectAreasDialog"
    max-width="1400"
    height="85vh"
    transition="dialog-bottom-transition"
    :persistent="true"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        tile
        color="primary"
        variant="elevated"
        id="importGeoJSONButton"
        v-bind="props"
        @click="openSelectAreasDialog = true"
        aria-label="Import GeoJSON"
        class="mt-2 mr-3 mb-8 px-1"
        width="100%"
      >
        Select Boundary</v-btn
      >
    </template>
    <v-card>
      <v-toolbar
        :style="
          'background-color: ' +
          this.$store.state.config.siteConfig.toolbar_colour
        "
        class="text-h6"
      >
        <v-btn tile icon dark @click="closeDialog()" aria-label="Close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Select Default Boundary </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <div v-if="loadedInitialData" class="loading">
        <v-progress-circular
          indeterminate
          color="rgb(81, 98, 124)"
          size="128"
          width="3"
          >Loading...</v-progress-circular
        >
      </div>
      <div v-else>
        <v-card-actions>
          <v-row class="ma-2">
            <v-col>
              <div class="column-header">
                <h4 class="pb-4">Select Areas</h4>
                <v-row class="pa-0">
                  <v-col class="py-0" cols="8">
                    <v-select
                      v-model="selectedAreaLevel"
                      :items="areaLevels"
                      item-title="area_level_name"
                      item-value="area_level"
                      :label="areaLevelLabel"
                      variant="outlined"
                      @update:modelValue="handleSelectAreaLevel(true)"
                      rounded="0"
                      class="mt-2"
                      density="compact"
                    ></v-select>
                  </v-col>
                  <v-col class="py-0" cols="4">
                    <v-select
                      v-model="timepoint"
                      :items="yearOptions"
                      label="Year"
                      variant="outlined"
                      @update:modelValue="handleSelectAreaLevel(false)"
                      rounded="0"
                      class="mt-2"
                      density="compact"
                      :disabled="!selectedAreaLevel || loadingFilterAreas"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="needFilteringByLA" cols="6" class="py-0">
                    <v-select
                      v-model="selectedParentArea"
                      :items="parentAreas"
                      item-title="area_display_name"
                      item-value="id"
                      :label="level6Name"
                      :disabled="disableFilter"
                      variant="outlined"
                      rounded="0"
                      class="mt-2"
                      density="compact"
                    >
                      <template #append>
                        <v-progress-circular
                          v-if="loadingFilterAreas"
                          indeterminate
                          color="rgb(81, 98, 124)"
                          size="20"
                          width="3"
                        ></v-progress-circular> </template
                    ></v-select>
                  </v-col>
                  <v-col v-if="showSearchField" cols="6" class="py-0">
                    <v-text-field
                      v-model="search"
                      prepend-inner-icon="mdi-magnify"
                      class="mt-2"
                      label="Search"
                      variant="outlined"
                      density="compact"
                      rounded="0"
                      clearable
                      :disabled="!timepoint"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-if="selectInitialChoices">
                <v-card-text class="text-center"
                  >Select Area Level & Timepoint</v-card-text
                >
              </div>
              <div v-else-if="selectLocalAuthority">
                <v-card-text class="text-center"
                  >Select a Local Authority to filter by</v-card-text
                >
              </div>
              <div v-else-if="loadingFilterAreas" class="loading-areas">
                <v-progress-circular
                  indeterminate
                  color="rgb(81, 98, 124)"
                  size="128"
                  width="3"
                  >Loading...</v-progress-circular
                >
              </div>
              <div v-else>
                <v-list class="area-list">
                  <v-list-item
                    v-for="area in searchedAreas"
                    :key="area.id"
                    @click="addArea(area)"
                    class="area-list-item"
                  >
                    <v-list-item-title>{{
                      area.area_display_name
                    }}</v-list-item-title>
                    <template v-slot:append>
                      <v-btn
                        color="grey-lighten-1"
                        icon="mdi-plus"
                        variant="text"
                      ></v-btn>
                    </template>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <div class="column-header">
                <h4 class="pb-4">Selected Areas</h4>
                <v-row class="ml-4">
                  <v-card-text class="pb-0">
                    Selected areas will display as a single
                    boundary</v-card-text
                  ></v-row
                >
                <v-row class="ml-6">
                  <v-icon
                    :style="'font-size: 45px; color: ' + boundaryColour"
                    >{{ "mdi-vector-polygon" }}</v-icon
                  >
                  <v-btn
                    class="ma-2"
                    style="width: 180px"
                    color="primary"
                    variant="elevated"
                    aria-label="edit colour"
                    rounded="0"
                    @click="toggleEditColour"
                  >
                    Edit Colour
                  </v-btn>
                  <v-expand-x-transition>
                    <v-card
                      v-show="editColour"
                      ref="colourCard"
                      :style="{
                        position: 'absolute',
                        zIndex: 1000,
                        top: '50px',
                        left: '0',
                        right: '0',
                        margin: '0 auto',
                      }"
                      max-width="300"
                      @focusin="addEscapeListener"
                      @focusout="removeEscapeListener"
                      tabindex="0"
                    >
                      <v-btn
                        tile
                        prepend-icon="mdi-close"
                        variant="text"
                        @click="editColour = false"
                      >
                        Close
                      </v-btn>

                      <v-color-picker
                        v-model="boundaryColour"
                        show-swatches
                      ></v-color-picker>
                    </v-card>
                  </v-expand-x-transition>

                  <v-btn
                    class="ma-2"
                    style="width: 100px"
                    color="success"
                    variant="elevated"
                    aria-label="edit colour"
                    rounded="0"
                    :disabled="selectedAreas.length === 0 || saving"
                    @click="saveBoundary"
                  >
                    <span v-if="!saving">Save</span>
                    <v-progress-circular
                      v-if="saving"
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    style="width: 100px"
                    color="error"
                    variant="elevated"
                    aria-label="edit colour"
                    rounded="0"
                    :disabled="
                      !this.$store.getters.customClientConfig.has_boundary
                    "
                    @click="removeBoundary"
                  >
                    <span v-if="!deleting">Remove</span>
                    <v-progress-circular
                      v-if="deleting"
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                  </v-btn>
                </v-row>
              </div>
              <v-list class="area-list">
                <v-list-item
                  v-for="selected in selectedAreas"
                  :key="selected.id"
                  class="area-list-item"
                >
                  <v-list-item-title>{{
                    selected.area_display_name
                  }}</v-list-item-title>
                  <template v-slot:append>
                    <v-btn
                      color="grey-lighten-1"
                      icon="mdi-delete"
                      variant="text"
                      @click="removeArea(selected)"
                    ></v-btn>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-subtitle
          v-if="this.$store.state.displayOnsSource"
          class="source"
        >
          <p>
            Source: Office for National Statistics licensed under the Open
            Government Licence v.3.0
          </p>
          <p>
            Contains OS data © Crown copyright and database right
            {{ OSYear }}
          </p>
        </v-card-subtitle>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AreaSelector",
  data: () => ({
    openSelectAreasDialog: false,
    areaLevels: [],
    parentAreas: [],
    selectedParentArea: null,
    loading: false,
    areas: [],
    area: [],
    selectedAreaLevel: null,
    search: "",
    timepoints: [],
    timepoint: null,
    selectedAreas: [],
    boundaryColour: "#000000",
    editColour: false,
    loadExisting: false,
    loadAreas: false,
    loadTimepoints: false,
    loadingFilterAreas: false,
    saving: false,
    deleting: false,
  }),
  props: {},
  mounted() {},
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside, true);
  },
  computed: {
    selectInitialChoices() {
      return (
        this.selectedAreaLevel === null &&
        this.timepoint === null &&
        this.selectedParentArea === null
      );
    },
    selectLocalAuthority() {
      return this.needFilteringByLA && this.selectedParentArea === null;
    },
    loadedInitialData() {
      return this.loadAreas || this.loadTimepoints || this.loadExisting;
    },
    searchedAreas() {
      // Check if search is empty
      if (!this.search || this.search === "") {
        return this.areas; // Return all options if search is empty
      } else {
        // Return filtered list based on search
        return this.areas.filter((area) => {
          return area.area_display_name
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      }
    },
    OSYear() {
      return new Date().getFullYear();
    },
    disableFilter() {
      if (!this.selectedAreaLevel || this.selectedAreaLevel === 6) {
        return true;
      } else {
        return false;
      }
    },
    yearOptions() {
      // return the years available for the selected area level
      let availableYears = this.timepoints.filter((timepoint) => {
        return timepoint.area_level === this.selectedAreaLevel;
      });

      // we just want the years array
      availableYears = availableYears.flatMap((obj) => obj.years);

      return availableYears;
    },
    level6Name() {
      return this.$store.getters.siteConfig.site_country === "aus"
        ? "Filter"
        : "Filter by Local Authority";
    },
    needFilteringByLA() {
      if (this.$store.state.config.siteConfig.site_country === "aus") {
        return false;
      } else {
        // area level ids that we need to lister by parent: LSOA, MSOA, WD
        const needFiltering = [1, 2, 5];

        return needFiltering.includes(this.selectedAreaLevel);
      }
    },
    showSearchField: {
      get() {
        return (
          this.$store.getters.siteConfig.site_country === "aus" ||
          this.selectedAreaLevel === 6 ||
          this.selectedAreaLevel === 14 ||
          this.selectedAreaLevel === 6
        );
      },
    },
    areaLevelLabel() {
      return this.$store.getters.siteConfig.site_country === "aus"
        ? "Filter by area"
        : "Filter by area level";
    },
  },
  methods: {
    getExistingBoundaryAreas() {
      if (this.$store.getters.customClientConfig.has_boundary) {
        this.loading = true;
        this.$axios
          .get("/get-client-boundary-details")
          .then(
            function (response) {
              // handle success
              this.selectedAreas = response.data.areas;
              this.boundaryColour = response.data.colour;
              this.loadExisting = false;
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.loadExisting = false;
              this.emit.emit("systemMessage", {
                title: "Error! Failed to get client settings",
                message: error.response.data.message,
                timeout: 0,
                colour: "error",
              });
            }.bind(this),
          );
      }
    },
    async handleSelectAreaLevel(setToLatestTimepoint = true) {
      // clear these
      this.selectedParentArea = null;
      this.parentAreas = [];
      this.areas = [];

      // Set the latest timepoint if required
      if (setToLatestTimepoint) {
        this.timepoint = this.yearOptions[this.yearOptions.length - 1];
      }

      // if we need to filter by LA, get the parent areas otherwise go straight to the areas
      if (this.needFilteringByLA) {
        this.parentAreas = this.parentAreas.length
          ? this.parentAreas
          : await this.getFilterLA();
      } else {
        this.areas = await this.getAreasByLevel(this.selectedAreaLevel);
      }
    },
    saveBoundary() {
      this.saving = true;
      // get the list of area ids out
      let selectedAreasIds = this.selectedAreas.map((area) => {
        return area.id;
      });

      // gather the data
      let boundary = {
        areas: selectedAreasIds,
        colour: this.boundaryColour,
      };

      // save it and set show_boundary to true in db
      this.loading = true;
      this.$axios
        .post("/save-client-boundary", boundary)
        .then(
          function (response) {
            // handle success
            this.saving = false;
            this.emit.emit("systemMessage", {
              title: "Success! Boundary settings saved",
              message: response.data.message,
              timeout: 3000,
              colour: "success",
            });
            // update the customClientConfig in store to say they have a boundary now and automatically set it to show
            let config = this.$store.getters.customClientConfig;
            config.show_boundary = true;
            config.has_boundary = true;
            this.$store.commit("setCustomClientConfig", config);
            this.$emit("updateBoundary");
            this.closeDialog();
          }.bind(this),
        )
        .catch(
          function (error) {
            this.saving = false;
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to saving boundary settings",
              message: error.response.data.message,
              timeout: 0,
              colour: "error",
            });
            this.closeDialog();
          }.bind(this),
        );
    },
    // remove the boundary and set show_boundary to false in db to stop it showing with no boundary
    removeBoundary() {
      this.deleting = true;
      this.$axios
        .delete("/delete-client-boundary")
        .then(
          function (response) {
            // handle success
            this.emit.emit("systemMessage", {
              title: "Success! Boundary settings removed",
              message: response.data.message,
              timeout: 3000,
              colour: "success",
            });

            // tell the map to remove the boundary
            let config = this.$store.getters.customClientConfig;
            config.show_boundary = false;
            config.has_boundary = false;
            this.$store.commit("setCustomClientConfig", config);
            this.deleting = false;
            this.$emit("updateBoundary");
            this.closeDialog();
          }.bind(this),
        )
        .catch(
          function (error) {
            this.deleting = false;
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to remove boundary settings",
              message: error.response.data.message,
              timeout: 0,
              colour: "error",
            });
          }.bind(this),
        );
    },
    toggleEditColour() {
      this.editColour = !this.editColour;
      if (this.editColour) {
        this.$nextTick(() => {
          this.addEscapeListener();
        });
      } else {
        this.removeEscapeListener();
      }
    },
    handleEscapeKey(event) {
      if (event.key === "Escape") {
        this.editColour = false;
        this.removeEscapeListener();
      }
    },
    addEscapeListener() {
      window.addEventListener("keyup", this.handleEscapeKey);
    },
    removeEscapeListener() {
      window.removeEventListener("keyup", this.handleEscapeKey);
    },
    getAreaLevels() {
      this.loadAreas = true;
      this.$axios
        .get("/list-area-data-levels")
        .then(
          function (response) {
            // handle success
            this.areaLevels = response.data;
            this.loadAreas = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.loadAreas = false;
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get client settings",
              message: error.response.data.message,
              timeout: 0,
              colour: "error",
            });
          }.bind(this),
        );
    },
    getTimepoints() {
      this.loadTimepoints = true;
      this.$axios
        .get("/get-timepoints-standard-area")
        .then(
          function (response) {
            // handle success
            this.timepoints = response.data;
            this.loadTimepoints = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.loadTimepoints = false;
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get timepoints",
              message: error.response.data.message,
              timeout: 0,
              colour: "error",
            });
          }.bind(this),
        );
    },
    async getAreasByLevel(level) {
      return new Promise((resolve, reject) => {
        this.loadingFilterAreas = true;

        this.$axios
          .get("/list-areas-by-level-int/" + level + "/" + this.timepoint)
          .then((response) => {
            resolve(response.data);
          })
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                title: "Error! Failed to get LA areas",
                message: error.response.data.message,
                timeout: 0,
                colour: "error",
              });

              reject(error);
            }.bind(this),
          )
          .finally(() => {
            this.loadingFilterAreas = false;
          });
      });
    },
    getAreasFilteredByLA() {
      this.loadingFilterAreas = true;

      this.$axios
        .get(
          "/areas-by-level-and-parent/" +
            this.timepoint +
            "/" +
            this.selectedAreaLevel +
            "/" +
            this.selectedParentArea,
        )
        .then(
          function (response) {
            // handle success
            this.areas = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get LA areas",
              message: error.response.data.message,
              timeout: 0,
              colour: "error",
            });
          }.bind(this),
        )
        .finally(() => {
          this.loadingFilterAreas = false;
        });
    },
    async getFilterLA() {
      return new Promise((resolve, reject) => {
        this.parentAreas = [];
        this.loadingFilterAreas = true;

        this.$axios
          .get(`/parent-la-standard-area/${this.timepoint}`)
          .then(
            function (response) {
              // handle success
              resolve(response.data);
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to get parent areas",
                timeout: -1,
                colour: "red",
              });

              reject(error.response.data);
            }.bind(this),
          )
          .finally(() => {
            this.loadingFilterAreas = false;
          });
      });
    },
    addArea(area) {
      // add the area if not already there, check by their id
      if (!this.selectedAreas.some((selected) => selected.id === area.id)) {
        this.selectedAreas.push(area);
      }
    },
    removeArea(area) {
      // remove the area from the selected areas
      this.selectedAreas = this.selectedAreas.filter((selected) => {
        return selected.id !== area.id;
      });
    },
    closeDialog() {
      this.openSelectAreasDialog = false;
      this.searchedAreas = [];
      this.selectedAreas = [];
      this.selectedAreaLevel = null;
      this.timepoint = null;
      this.selectedParentArea = null;
      this.search = "";
      this.boundaryColour = "#000000";
      this.areas = [];
      this.loadAreas = false;
      this.loadTimepoints = false;
      this.loadExisting = false;
    },
  },
  watch: {
    openSelectAreasDialog: function (newVal) {
      if (newVal === true) {
        if (this.$store.getters.customClientConfig.has_boundary) {
          this.getExistingBoundaryAreas();
        } else {
          this.loadExisting = false;
        }

        this.getAreaLevels();
        this.getTimepoints();
      }
    },
    selectedParentArea: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal !== null) {
        this.getAreasFilteredByLA();
      }
    },
  },
};
</script>
<style scoped>
.source {
  font-size: 10px;
  position: "absolute";
  bottom: "0";
  margin-top: 5px;
  padding-bottom: 10px;
}

.area-list {
  height: 42vh;
  overflow-y: auto;
}

.area-list-item {
  cursor: pointer;
  border: 1px solid #e0e0e0;
  margin-bottom: 1px;
}

.column-header {
  height: 180px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.loading-areas {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42vh;
}
</style>
