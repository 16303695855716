<template>
  <HeaderModule
    title="Datastore API"
    icon="book-variant"
    chip="BetaChip"
    description="Power your workflows with Local Insight data"
    :defaultTab="defaultTab"
    width="1224px"
    padding="10px"
    :key="headerModuleReloadKey"
    v-model:tabs="tabs"
    @scrollTabEvent="handleTabContentScroll"
  >
    <template #catalogue>
      <DatastoreCatalogue ref="catalogue" />
    </template>

    <template #queryBuilder>
      <DatastoreQueryBuilder ref="queryBuilder" />
    </template>

    <template #apiKeys>
      <DatastoreApiKeys
        ref="apikeys"
        @datastore:apikeysmounted="apiKeysMounted = true"
      />
    </template>

    <template #documentation>
      <DatastoreDocumentation />
    </template>
  </HeaderModule>
</template>
<script>
import HeaderModule from "@/components/HeaderModule.vue";
import DatastoreCatalogue from "@/components/DatastoreCatalogue.vue";
import DatastoreQueryBuilder from "@/components/DatastoreQueryBuilder.vue";
import DatastoreApiKeys from "@/components/DatastoreApiKeys.vue";
import DatastoreDocumentation from "@/components/DatastoreDocumentation.vue";
import { systemMessages } from "@/mixins/SystemMessages";

export default {
  name: "DatastorePage",
  mixins: [systemMessages],
  components: {
    HeaderModule,
    DatastoreCatalogue,
    DatastoreQueryBuilder,
    DatastoreApiKeys,
    DatastoreDocumentation,
  },
  props: {
    selectedTabRoute: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    analytics: {
      catalogue: { eventName: "datastore_catalogue_visits", timeStamp: 0 },
      "query-builder": {
        eventName: "datastore_query_builder_visits",
        timeStamp: 0,
      },
      "api-keys": { eventName: "datastore_api_keys_visits", timeStamp: 0 },
    },
    tabs: [
      { title: "Catalogue", value: "catalogue", route: "catalogue" },
      { title: "Query builder", value: "queryBuilder", route: "query-builder" },
      { title: "API Keys", value: "apiKeys", route: "api-keys" },
      {
        title: "Documentation",
        value: "documentation",
        route: "documentation",
      },
    ],
    defaultTab: "catalogue",
    headerModuleReloadKey: 0,
    apiKeysMounted: false,
  }),
  methods: {
    // handle lazy loading when scrolling through catalogue
    handleTabContentScroll(tab) {
      if (tab === "catalogue") {
        this.$refs.catalogue.getPaginatedIndicators();
      }
    },
  },
  watch: {
    $route(url) {
      const match = url.path.match(/datastore\/([^?]+)/);
      const event = match && match[1] ? match[1] : null;
      /**
       * catching super rare event - user in datastore clicks on datastore side nav
       * to reload the module.  Have to force header module to reload, as this component isn't
       * re-rendered and default tab isn't selected
       */
      if (!event) {
        this.headerModuleReloadKey += 1;
      }

      //reload api keys and stats if navigating to tab
      if (event == "api-keys") {
        if (this.apiKeysMounted) {
          this.$refs.apikeys.fetchApiKeys();
          this.$refs.apikeys.fetchApiStats();
        }
      }

      // analytics
      if (event in this.analytics) {
        // 120 second delay so we don't skew the stats
        if (
          Math.floor(Date.now() - this.analytics[event].timeStamp) / 1000 >
          120
        ) {
          this.analytics[event].timeStamp = Date.now();
          this.$axios.put("/send-analytics", {
            event: this.analytics[event].eventName,
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
