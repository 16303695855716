<template>
  <div class="text-center">
    <v-dialog
      v-model="computedMapService"
      max-width="600px"
      scrollable
      id="mapServicesDialog"
    >
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          <v-btn
            tile
            icon
            dark
            @click="computedMapService = false"
            aria-label="Close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Services </v-toolbar-title>
        </v-toolbar>
        <v-card-actions
          v-if="loadingTypes"
          class="d-flex align-center justify-center"
        >
          <v-progress-circular
            :size="150"
            :width="3"
            color="#51627C"
            indeterminate
            >Loading Types
          </v-progress-circular>
        </v-card-actions>
        <v-card-text v-else style="height: 600px">
          <v-card-actions class="sticky">
            <v-alert dense :type="alertType" :icon="icon" width="100%">
              {{ alertText }}
            </v-alert>
          </v-card-actions>
          <div
            v-if="typesLoaded && types.length > 0"
            class="overflow-y-auto overflow-x-hidden"
          >
            <v-row align="center">
              <v-spacer></v-spacer>
              <v-label class="mr-3">Select All</v-label>
              <v-checkbox
                v-model="selectAll"
                @change="toggleSelectAll"
                class="ml-auto mr-7 mt-4"
              ></v-checkbox>
            </v-row>
            <v-list id="serviceTypeList">
              <v-list-item
                v-for="item in types"
                :key="item.id"
                three-line
                id="serviceTypeItem"
              >
                <template #prepend>
                  <v-avatar class="mr-4">
                    <v-icon :color="item.colour">mdi-{{ item.icon }}</v-icon>
                  </v-avatar>
                </template>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.description">
                  {{ item.description }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Includes: {{ item.serviceCount }} Services
                </v-list-item-subtitle>
                <template #append>
                  <v-list-item-action style="height: 70px">
                    <div v-if="item.isLoading">
                      <v-progress-circular
                        :size="24"
                        color="#51627C"
                        indeterminate
                        class="mr-2"
                      ></v-progress-circular>
                    </div>
                    <div v-else>
                      <v-checkbox
                        :id="'serviceType' + item.id"
                        v-model="item.isSelected"
                        @change="updateSelectedTypes(item)"
                        class="mt-3"
                      ></v-checkbox>
                    </div>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list>
          </div>
          <div v-else>
            <v-card-text class="text-center"
              ><h4>
                <v-icon title="alert"> mdi-alert </v-icon> No Services Uploaded
              </h4>
            </v-card-text>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            tile
            color="error"
            variant="elevated"
            @click="computedMapService = false"
            aria-label="close"
          >
            close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            tile
            color="success"
            variant="elevated"
            :loading="isAnyTypeLoading"
            :disabled="typesLoaded && types.length == 0"
            @click="viewServices"
            id="viewServicesBtn"
            aria-label="View Services"
          >
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
            View Services
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MAPSERVICES",
  data: () => ({
    types: [],
    loadingTypes: false,
    typesLoaded: false,
    selectedTypes: [],
    selectAll: false,
  }),
  computed: {
    computedMapService: {
      get() {
        return this.mapServicesDialog;
      },
      set(val) {
        this.$emit("update:mapServicesDialog", val);
      },
    },
    canLoadServices() {
      // check the main flags first, and that we even have types
      if (
        !this.loadingTypes &&
        this.types.length > 0 &&
        this.types.every((type) => type.isLoading === false) &&
        this.totalSelectedServices < 100001 &&
        this.customAreaCount < 6
      ) {
        return false;
      }

      return true;
    },
    allTypesSelected: {
      get() {
        return this.types.every((type) => type.isSelected);
      },
      set(value) {
        this.selectAll = value;
        this.types.forEach((type) => (type.isSelected = value));
      },
    },
    totalSelectedServices() {
      return this.types.reduce((total, item) => {
        return item.isSelected ? total + item.services.length : total;
      }, 0);
    },
    isAnyTypeLoading() {
      return this.types.some((type) => type.isLoading);
    },
    alertType() {
      if (this.customAreaCount > 5) {
        return "warning";
      } else if (this.totalSelectedServices > 100001) {
        return "error";
      }
      return "info";
    },
    alertText() {
      if (this.customAreaCount > 5) {
        return "Displaying a large number of services may affect performance.";
      } else if (this.totalSelectedServices == 0) {
        return "Select your Service Types, you can select up to 100,000 Services";
      } else if (this.totalSelectedServices > 0) {
        return (
          "You currently have " +
          this.totalSelectedServices.toLocaleString() +
          "/100,000 Services selected"
        );
      } else {
        return "Manage Services";
      }
    },
    icon() {
      return this.customAreaCount > 5 ? "mdi-alert" : undefined;
    },
  },
  components: {},
  props: {
    serviceTypeIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    mapServicesDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    loadAllServices: {
      type: Boolean,
      required: true,
      default: false,
    },
    clearDialogs: {
      type: Boolean,
      required: true,
      default: false,
    },
    customAreaCount: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  mounted() {
    this.getClientServiceTypes();
  },
  methods: {
    viewServices(close = true) {
      if (close) {
        this.computedMapService = false;
      }
      // wait .5 seconds then emit to give it a chance to close
      //setTimeout(() => {
      this.$emit("selectedTypes", this.types);
      //}, 500);
    },
    getClientServiceTypes() {
      this.loadingTypes = true;
      this.emit.emit("systemBusy", true);
      this.$axios
        .get("/get-all-service-types")
        .then((response) => {
          this.types = response.data;
          this.types.sort((a, b) => a.order - b.order);

          // Show default service types, unless serviceTypeIds are passed
          let viewOnLoad = false;
          let promises = [];
          this.types.forEach((type) => {
            if (
              Array.isArray(this.serviceTypeIds) &&
              this.serviceTypeIds.length > 0
                ? this.serviceTypeIds.includes(type.id)
                : type.isSelected === true
            ) {
              type.isSelected = true;
              promises.push(this.updateSelectedTypes(type));
              viewOnLoad = true;
            }
          });

          // if any were selected to show, load them
          Promise.all(promises).then(() => {
            if (viewOnLoad) {
              this.viewServices(false);
            }

            this.emit.emit("systemBusy", false);
            this.typesLoaded = true;
            this.loadingTypes = false;
          });
        })
        .catch((error) => {
          this.typesLoaded = true;
          this.loadingTypes = false;
          console.error(error);
        });
    },
    updateSelectedTypes(item) {
      return new Promise((resolve, reject) => {
        if (item.isSelected) {
          if (item.services.length === 0) {
            item.isLoading = true;
            // find the type and check if its services array is empty
            this.$axios
              .post("/get-service-type-services", [item.id])
              .then((response) => {
                if (response.data.length > 0) {
                  // update the type with the services
                  item.services = response.data;
                }
                item.isLoading = false;
                item.hasLoaded = true;
                resolve();
              })
              .catch((error) => {
                item.isLoading = false;
                console.error(error);
                reject(error);
              });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    toggleSelectAll() {
      this.types.forEach((type) => (type.isSelected = this.selectAll));
      this.types.forEach((type) => {
        if (type.isSelected) {
          this.updateSelectedTypes(type);
        }
      });
    },
  },
  watch: {
    serviceTypeIds: {
      handler() {
        if (
          Array.isArray(this.serviceTypeIds) &&
          this.serviceTypeIds.length > 0
        ) {
          this.getClientServiceTypes();
        }
      },
    },
    types: {
      handler(newTypes) {
        this.selectAll = newTypes.every((type) => type.isSelected);
      },
      deep: true,
    },
    clearDialogs(newValue) {
      if (newValue) {
        this.types.forEach((type) => (type.isSelected = false));
      }
    },
  },
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
