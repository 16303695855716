// call this to display a date from a database, return format 24 February 2025
function formatDate(date) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString(undefined, options);
}
// call this to display a date from a database returns format 24-02-25
function formatDateDashes(dateStr) {
  // Split the string to separate date and time parts.
  const [datePart] = dateStr.split(" ");
  // Split the date part into year, month, and day.
  const [year, month, day] = datePart.split("-");
  // Construct the new format: day-month-last two digits of year.
  return `${day}-${month}-${year.slice(-2)}`;
}
// convert it into something the DB can use (YYYY-MM-DD) (the "toISOString().substring(0, 10)" way seems to lose a day when working with the vuetify date picker hence the long hand version below)
function formatToSQLDate(date = null) {
  if (date) {
    var value = new Date(date);
    value =
      value.getFullYear() +
      "-" +
      (value.getMonth() + 1 < 10
        ? "0" + (value.getMonth() + 1)
        : value.getMonth() + 1) +
      "-" +
      (value.getDate() < 10 ? "0" : "") +
      value.getDate();
    return value;
  } else {
    return null;
  }
}
export { formatDate, formatToSQLDate, formatDateDashes };
