<template>
  <v-card-text class="pa-0 pb-4 chips">
    <v-chip
      v-for="tag in tags"
      :key="tag.title"
      variant="elevated"
      style="background-color: #bfe0f8; color: #093f6b"
      :text="tag.title"
      class="mx-1"
      closable
      @click="editFilter(tag)"
      @click:close="removeFilter(tag)"
    />
    <v-btn
      @click="
        resetDialog();
        isEditMode = false;
        dialog = true;
      "
      color="#0E5B99"
      variant="text"
      :disabled="tags.length >= 30"
      prepend-icon="mdi-plus"
      >add filter</v-btn
    >
    <DynamicDialog
      :show="dialog"
      @dialogOk="
        setFilter();
        this.$emit('search');
      "
      @dialogCancel="
        dialog = false;
        isEditMode = false;
        resetDialog();
      "
      @update:showDyamicDialog="(state) => (dialog = state)"
      :okBtnDisabled="createBtnDisabled"
      max-width="550"
      min-height="250"
      okBtnColor="success"
      cancelBtnColor="none"
      cancelBtnVariant="text"
    >
      <template v-slot:title>{{
        isEditMode ? "Update filter" : "Create Filter"
      }}</template>
      <template v-slot:content>
        <v-form ref="form">
          <v-select
            v-model="filter.filterBy"
            :items="filters"
            :rules="[(v) => !!v || 'Item is required']"
            label="Filter"
            density="compact"
            rounded="0"
            variant="outlined"
            required
          ></v-select>

          <v-select
            v-if="!['coverage'].includes(filter.filterBy)"
            v-model="filter.operator"
            :items="shownOperators"
            :disabled="!filter.filterBy"
            label="Operator"
            density="compact"
            rounded="0"
            variant="outlined"
            required
          ></v-select>

          <v-text-field
            v-if="filter.filterBy === 'timepoint'"
            v-model="filter.dateLabel"
            :disabled="!filter.operator"
            label="Date"
            density="compact"
            readonly
            @focus="openDatePicker"
            rounded="0"
            variant="outlined"
            required
          ></v-text-field>

          <v-select
            v-else-if="filter.filterBy === 'coverage'"
            v-model="filter.country"
            :items="countries"
            label="Country"
            density="compact"
            rounded="0"
            variant="outlined"
            required
            chips
            multiple
          ></v-select>

          <div v-else>
            <v-text-field
              v-if="
                !['warnings', 'coverage', 'data_type'].includes(filter.filterBy)
              "
              v-model="filter.text"
              :rules="textRules"
              :disabled="!filter.filterBy"
              label="Text"
              density="compact"
              rounded="0"
              variant="outlined"
              required
            ></v-text-field>
          </div>
        </v-form>
      </template>
      <template v-slot:okBtnTitle>{{
        isEditMode ? "Update filter" : "add filter"
      }}</template>
    </DynamicDialog>

    <!-- Pick a single date -->
    <DynamicDialog
      :show="pickSingleDate"
      @dialogOk="setSingleDate"
      @dialogCancel="pickSingleDate = false"
      @update:showDyamicDialog="(state) => (pickSingleDate = state)"
      :okBtnDisabled="setSingleDateBtnDisabled"
      max-width="400"
      okBtnColor="success"
      cancelBtnColor="none"
      cancelBtnVariant="text"
    >
      <template v-slot:title>Pick date</template>
      <template v-slot:content>
        <YearMonthPicker v-model="singleDateFilter" />
      </template>
      <template v-slot:okBtnTitle>set</template>
    </DynamicDialog>

    <!-- Pick a date range -->
    <DateRangePicker
      v-model:dialogProp="pickDateRange"
      @setRange="setRangeDate"
      validate-date-range
    />
  </v-card-text>
</template>

<script>
import DynamicDialog from "@/components/DynamicDialog.vue";
import YearMonthPicker from "@/components/YearMonthPicker.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";

export default {
  name: "IndicatorSearchTags",
  components: {
    DynamicDialog,
    YearMonthPicker,
    DateRangePicker,
  },
  data: () => ({
    dialog: false,
    pickSingleDate: false,
    pickDateRange: false,
    isEditMode: false,
    filter: {
      title: "",
      filterBy: null,
      operator: null,
      text: null,
      date: null,
      dateLabel: null,
      country: null,
    },
    singleDateFilter: null,
    dateRangeFilter: null,
    operators: {
      default: [
        { title: "Contains", value: "ILIKE" },
        { title: "Does not contain", value: "NOT ILIKE" },
        { title: "Is", value: "=" },
      ],
      timepoint_operators: [
        { title: "Between", value: "between" },
        { title: "Before", value: "before" },
        { title: "After", value: "after" },
        { title: "On", value: "on" },
      ],
      warnings_operators: [
        { title: "Is set", value: "isSet" },
        { title: "Is not set", value: "IsNotSet" },
      ],
      data_type_operators: [
        { title: "Custom data", value: "custom_data" },
        { title: "OCSI data", value: "ocsi_data" },
      ],
    },
    countries: [
      { title: "England", value: "eng" },
      { title: "Scotland", value: "sct" },
      { title: "Wales", value: "wls" },
    ],
    textRules: [
      (v) => !!v || "Value is required",
      (v) =>
        (v && v.length <= 1000) || "Name cannot be more than 1000 characters",
    ],
  }),
  props: {
    tagsProp: {
      type: Array,
      default: () => [],
      required: true,
    },
    filterBlackList: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  computed: {
    tags: {
      get() {
        return this.tagsProp;
      },
      set(val) {
        this.$emit("update:tagsProp", val);
      },
    },
    /** create button may have different disabled conditions based on the filterBy value */
    createBtnDisabled() {
      if (!this.filter.filterBy) return true;
      if (this.filter.filterBy === "data_type") return false;
      if (this.filter.filterBy === "warnings") return !this.filter.operator;
      if (this.filter.filterBy === "coverage") return !this.filter.country;
      if (this.filter.filterBy === "timepoint")
        return !this.filter.operator || !this.filter.date;

      return !this.filter.operator || !this.filter.text;
    },
    setSingleDateBtnDisabled() {
      return (
        typeof this.singleDateFilter?.month !== "number" ||
        typeof this.singleDateFilter?.year !== "number"
      );
    },
    shownOperators() {
      return (
        this.operators[`${this.filter.filterBy}_operators`] ||
        this.operators.default
      );
    },
    filters() {
      const items = [
        { title: "Coverage", value: "coverage" },
        { title: "Data type", value: "data_type" },
        { title: "Description", value: "indicator_description" },
        { title: "Source", value: "source" },
        { title: "Tags", value: "tags" },
        { title: "Time Point", value: "timepoint" },
      ];

      // these filters cannot have duplicate tags
      const noDuplicateFilters = [{ title: "Warnings", value: "warnings" }];

      return (
        [
          ...items,
          ...noDuplicateFilters.filter(
            ({ value }) =>
              !this.tags.some(({ filterBy }) => filterBy === value),
          ),
        ]
          // filter out blacklisted items and sort alphabetically
          .filter(({ value }) => !this.filterBlackList.includes(value))
          .sort((a, b) => a.title.localeCompare(b.title))
      );
    },
  },
  methods: {
    setFilter() {
      // build the title
      const title = this.buildFilterTitle();

      // check for duplicates
      if (this.tags.filter((t) => t.title === title).length > 0) {
        this.dialog = false;
        return;
      }

      if (this.isEditMode) {
        this.tags = this.tags.map((t) => {
          if (t.title === this.filter.title) {
            return {
              title,
              filterBy: this.filter.filterBy,
              operator: this.filter.operator,
              text: this.filter.text,
              date: this.filter.date,
              dateLabel: this.filter.dateLabel,
              country: this.filter.country,
            };
          }
          return t;
        });
      } else {
        this.tags.push({
          title,
          filterBy: this.filter.filterBy,
          operator: this.filter.operator,
          text: this.filter.text,
          date: this.filter.date,
          dateLabel: this.filter.dateLabel,
          country: this.filter.country,
        });
      }

      this.sendAnalytics();

      // reset these
      this.dialog = false;
      this.isEditMode = false;
      this.resetDialog();
    },
    editFilter(tag) {
      this.resetDialog();
      this.isEditMode = true;
      this.filter = JSON.parse(JSON.stringify(tag));
      this.dialog = true;
    },
    sendAnalytics() {
      // Build the object for analytics
      const payload = {
        event: "datastore_indicator_searches",
      };

      // Set the value of the event param depending on filter type
      switch (this.filter.filterBy) {
        case "timepoint":
          payload.filter_time_point = this.filter.operator;
          break;
        case "description":
          payload.filter_description = this.filter.operator;
          break;
        case "tags":
          payload.filter_tags = this.filter.operator;
          break;
        case "source":
          payload.filter_source = this.filter.operator;
          break;
        case "warnings":
          payload.filter_warnings = this.filter.operator;
          break;
        case "coverage":
          if (this.filter.country && Array.isArray(this.filter.country)) {
            payload.filter_coverage = this.filter.country.join(",");
          }
          break;
        default:
          break;
      }

      // Send the analytics data for the current tag
      this.$axios.put("/send-analytics", payload);
    },
    removeFilter(tag) {
      this.tags = this.tags.filter((t) => t.title !== tag.title);

      this.$emit("search");
    },
    buildFilterTitle() {
      const filterBy = this.filters.find(
        (f) => f.value === this.filter.filterBy,
      )?.title;

      const operator = this.shownOperators.find(
        (f) => f.value === this.filter.operator,
      )?.title;

      const value =
        this.filter.filterBy === "timepoint"
          ? this.filter.dateLabel
          : this.filter.filterBy === "coverage"
            ? this.countries
                .filter((c) => this.filter.country.includes(c.value))
                .map((c) => c.title)
                .join(", ")
            : this.filter.text;

      if (this.filter.filterBy === "warnings") {
        return `${filterBy} ${operator.toLocaleLowerCase()}`;
      } else if (this.filter.filterBy === "coverage") {
        return `${filterBy}: ${value}`;
      } else if (this.filter.filterBy === "data_type") {
        const clientName =
          this.$store.state.config.customClientConfig.client_name;

        return `${this.filter.operator === "custom_data" ? clientName + " " + operator.toLocaleLowerCase() : operator}`;
      } else {
        return `${filterBy} ${operator.toLocaleLowerCase()} ${value}`;
      }
    },
    openDatePicker() {
      if (this.filter.operator === "between") {
        this.pickDateRange = true;
      } else {
        this.pickSingleDate = true;
      }
    },
    setSingleDate() {
      this.filter.date = this.singleDateFilter.sqlVersion; // for the backend

      // set label
      this.filter.dateLabel = this.singleDateFilter.label;

      this.pickSingleDate = false;
    },
    setRangeDate(range) {
      this.filter.date = range.dateSelection;
      this.filter.dateLabel = range.label;
    },
    resetDialog() {
      // this.isEditMode = false;
      this.filter = {
        title: "",
        filterBy: null,
        operator: null,
        text: null,
        date: null,
        dateLabel: null,
        country: null,
      };
    },
    /**
     * given an operator like Is or Between searches the operators
     * object of arrays of objects for a match to grab the operator value
     */
    getOperatorByTitle(title) {
      // loop each operators property (default, timepoint_operators, warnings_operators etc)
      for (const category of Object.values(this.operators)) {
        // Look for an object with matching title
        const match = category.find(
          (item) => item.title.toLowerCase() === title.toLowerCase(),
        );
        if (match) {
          return match.value; // Return the operator value.
        }
      }
      // If not found return null
      return null;
    },
    /**
     * given a minimal set of tag filter data, builds an array of filter tags with title,
     * full operator, country etc.  Injects these into the filter tags to set them and trigger
     * a search.  Removes initial partial tags
     * @param tags array of minimal tag filter data e.g. filterBy, operator, comparator
     */
    setSuggestionFilters(tags) {
      let passedInFilters = [];
      //loop partial tag data to set title etc
      tags.forEach((f) => {
        if (!f.title) {
          this.filter = f;
          if (this.filter.operator) {
            this.filter.operator = this.getOperatorByTitle(
              this.filter.operator,
            );
          }
          this.setFilter();

          //save index of the partial tag for removal
          let tagIndex = this.tags.findIndex((tag) => tag === f);
          if (tagIndex !== "-1") {
            passedInFilters.push(tagIndex);
          }
        }
      });

      //remove partial tags to leave full tags only
      passedInFilters
        .sort(function (a, b) {
          return b - a;
        })
        .forEach((idx) => {
          this.tags.splice(idx, 1);
        });

      this.$emit("search");
    },
  },
  watch: {
    // if we switch to or from timepoint, reset the other fields
    "filter.filterBy": {
      handler: function (newVal, oldVal) {
        if (newVal && oldVal) {
          const isTimepointChanged =
            newVal === "timepoint" || oldVal === "timepoint";

          if (isTimepointChanged) {
            this.filter.operator = null;
            this.filter.date = null;
            this.filter.dateLabel = null;
            this.filter.text = null;
          }

          if (newVal === "coverage") {
            this.filter.operator = null;
            this.filter.date = null;
            this.filter.dateLabel = null;
            this.filter.text = null;
          }

          if (newVal === "warnings") {
            this.filter.operator = null;
            this.filter.date = null;
            this.filter.dateLabel = null;
            this.filter.text = null;
          }
        }
      },
    },
    "filter.operator": {
      handler: function (newVal, oldVal) {
        if (
          newVal &&
          oldVal &&
          this.filter?.filterBy === "timepoint" &&
          (newVal === "between" || oldVal === "between")
        ) {
          this.filter.date = null;
          this.filter.dateLabel = null;
        }
      },
    },
    tags: {
      handler: function (newVal, oldVal) {
        //listen for filter tags being injected via tagprop e.g. parent filter suggestions
        if (newVal && oldVal && newVal.length > oldVal.length) {
          this.setSuggestionFilters(newVal);
        }
      },
    },
  },
};
</script>

<style scoped>
/* Add your component styles here */
</style>
