/** vuex store for themes manager module  */
export const themesManager = {
  namespaced: true,
  /** state (data) */
  state: () => ({
    themes: [],
    indicators: [],
  }),
  /** setters */
  mutations: {
    setThemes(state, value) {
      state.themes = value;
    },
    setIndicators(state, value) {
      state.indicators = value;
    },
  },
  /** getters */
  getters: {
    themes: (state) => state.themes,
    indicators: (state) => state.indicators,
  },
};
