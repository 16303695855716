<template>
  <v-theme-provider theme="localInsightLightTheme">
    <v-container>
      <!-- Monthly Data Usage Section -->
      <v-row class="mb-4">
        <v-col class="pl-0 pr-0">
          <v-card class="usage-card py-2" style="word-break: break-word">
            <v-card-title>Monthly data usage limit</v-card-title>
            <v-card-text>
              The progress bar below displays the total data transferred out by
              all users in this organisation. Please note that any unused
              allowance will not roll over into the next calendar month.
            </v-card-text>
            <div class="d-flex">
              <v-progress-linear
                :color="usage_bar_colour"
                bg-color="rgba(0, 0, 0, 0.12)"
                height="8"
                :model-value="client_usage_percent"
                rounded="lg"
                rounded-bar
                class="mt-4 mb-1 mx-4"
              ></v-progress-linear>
            </div>
            <div class="text-right mx-4 mb-4 text-caption">
              {{ formattedClientTransferred }} / {{ formattedClientLimit }} ({{
                client_usage_percent
              }}% used)
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- My Usage Section -->
      <v-row align="end" class="mt-2">
        <v-col align="left" class="pl-0 py-0">
          <h2 class="text-h6 text">My Usage</h2>
        </v-col>
        <v-col align="right" class="pr-0 py-0">
          <v-select
            density="compact"
            variant="outlined"
            rounded="0"
            max-width="215px"
            hide-details="true"
            :items="usageFilterArray"
            item-value="value"
            item-text="title"
            v-model="selectedUsageFilter"
            class="bg-white"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-0">
          <v-card
            class="kpi-card"
            style="border-bottom: 4px solid rgba(14, 91, 153, 1)"
          >
            <v-card-title class="title">{{ user_requests }}</v-card-title>
            <v-card-subtitle class="subtitle">
              <v-icon icon="mdi-room-service-outline"></v-icon>
              Requests
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col>
          <v-card
            class="kpi-card"
            style="border-bottom: 4px solid rgba(176, 0, 32, 1)"
          >
            <v-card-title class="title">{{ user_errors }}</v-card-title>
            <v-card-subtitle class="subtitle">
              <v-icon icon="mdi-alert"></v-icon>
              Errors
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-col class="pr-0">
          <v-card
            class="kpi-card"
            style="border-bottom: 4px solid rgba(38, 89, 40, 1)"
          >
            <v-card-title class="title"
              >{{ user_transferred_mb }} MB</v-card-title
            >
            <v-card-subtitle class="subtitle">
              <v-icon icon="mdi-download"></v-icon>
              Data transferred out
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-divider class="my-3"></v-divider>
      </v-row>

      <!-- API Keys Section -->
      <v-row>
        <v-col align-self="end" align="left" class="pl-0 py-0 mb-3">
          <h2 class="text-h6 text">My API Keys</h2>
        </v-col>
        <v-col align="right" class="pr-0 py-0">
          <v-btn
            v-if="!noApiKeysMessage"
            variant="flat"
            rounded="0"
            color="primary"
            align="right"
            class="my-3"
            @click="newKeyDialog()"
          >
            <v-icon icon="mdi-plus" size="16" class="pr-3"></v-icon>
            New API Key
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="bg-white rounded elevation-1 mb-2">
        <v-card-actions v-if="noApiKeysMessage" class="mx-auto w-auto">
          <AvatarState avatarSize="102" icon="mdi-key-variant" iconSize="54">
            <template #title>Create Your First API Key </template>
            <template #body>
              Unlock the power of our the Datastore by creating an API key. API
              keys enable seamless and secure access to the datastore
            </template>
            <template #footer>
              <div>
                <v-btn
                  prepend-icon="mdi-plus"
                  variant="elevated"
                  color="primary"
                  @click="newKeyDialog()"
                  rounded="0"
                  class="w-100 my-4"
                  >create api key</v-btn
                >
              </div>
              <div v-if="false">
                <v-btn
                  variant="text"
                  density="compact"
                  color="primary"
                  class="w-50"
                  >learn more</v-btn
                >
              </div>
            </template>
          </AvatarState>
        </v-card-actions>
        <v-data-table
          v-else
          align="left"
          class="elevation-1 rounded"
          v-model:sort-by="tableSort"
          :headers="apiTableHeaders"
          :loading="fetchingApiKeys"
          :items="apiKeys"
          :items-per-page="keysPerPage"
          :hide-default-footer="apiKeys.length <= keysPerPage"
        >
          <!-- created column -->
          <template v-slot:[`item.created`]="{ item }">
            {{
              new Date(item.created).toLocaleString(this.userLocale, {
                timezone: this.userTimeZone,
              })
            }}
          </template>
          <!-- last used column -->
          <template v-slot:[`item.last_used`]="{ item }">
            <span v-if="item.last_used">
              {{
                new Date(item.last_used).toLocaleString(this.userLocale, {
                  timezone: this.userTimeZone,
                })
              }}
            </span>
            <span v-else> - </span>
          </template>
          <!-- action column -->
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              :id="'api-row-' + item.id"
              variant="plain"
              size="small"
              :slim="true"
              @click="setSelectedRow(item)"
            >
              <v-icon icon="mdi-cog" size="20"></v-icon>
              <v-menu :activator="'#api-row-' + item.id">
                <v-list density="compact" class="pa-0">
                  <v-list-item class="pa-0">
                    <v-btn
                      variant="text"
                      class="w-100 justify-start"
                      @click="showRenameDialog = true"
                    >
                      <v-icon icon="mdi-pencil" size="24"></v-icon>
                      <span class="ml-3 text-body-1">Rename</span>
                    </v-btn>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item class="pa-0">
                    <v-btn
                      variant="text"
                      class="w-100 justify-start text-error"
                      @click="deleteConfirmation()"
                    >
                      <v-icon icon="mdi-delete" size="24"></v-icon>
                      <span class="ml-3 text-body-1">Delete</span>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>
          </template>
        </v-data-table>
      </v-row>

      <!-- delete confirmation dialog-->
      <DynamicDialog
        :show="showDeleteDialog"
        @dialogOk="deleteKey(selectedRow)"
        @dialogCancel="showDeleteDialog = false"
        @update:showDyamicDialog="(state) => (showDeleteDialog = state)"
        max-width="500"
        okBtnColor="error"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>Delete API Key</template>
        <template v-slot:content>
          <p class="pb-4">
            Are you sure you want to delete this API key? This action cannot be
            undone, and any applications using this key will lose access
            immediately.
          </p>
        </template>
        <template v-slot:okBtnTitle>DELETE KEY</template>
      </DynamicDialog>

      <!-- rename confirmation dialog-->
      <DynamicDialog
        :show="showRenameDialog"
        @dialogOk="renameKey(selectedRow)"
        @dialogCancel="showRenameDialog = false"
        @update:showDyamicDialog="(state) => (showRenameDialog = state)"
        :okBtnDisabled="selectedRow?.name.length == 0 ?? false"
        max-width="500"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>Rename API Key</template>
        <template v-slot:content>
          <p class="pb-4">
            Enter a new name for this API key. Renaming will not affect the
            key’s functionality or access.
          </p>
          <v-text-field
            variant="outlined"
            label="Nickname"
            v-model="selectedRow.name"
            :rules="[validationRules.required]"
            autocomplete="off"
          ></v-text-field>
        </template>
        <template v-slot:okBtnTitle>RENAME KEY</template>
      </DynamicDialog>

      <!-- new key nickname dialog-->
      <DynamicDialog
        :show="showNewKeyNicknameDialog"
        @dialogOk="generateNewKey()"
        @dialogCancel="resetNewKey()"
        @update:showDyamicDialog="(state) => (showNewKeyNicknameDialog = state)"
        :okBtnDisabled="
          newKey.name == null || newKey.name.length == 0 ? true : false
        "
        max-width="500"
        cancelBtnColor="none"
        cancelBtnVariant="text"
      >
        <template v-slot:title>New API Key</template>
        <template v-slot:content>
          <p class="pb-4">
            Give your new API key a nickname to easily identify it later.
          </p>
          <v-text-field
            variant="outlined"
            label="Nickname"
            v-model="newKey.name"
            :rules="[validationRules.required]"
            autocomplete="off"
          ></v-text-field>
        </template>
        <template v-slot:okBtnTitle>GENERATE KEY</template>
      </DynamicDialog>

      <!-- generated key dialog-->
      <DynamicDialog
        :show="showGeneratedKeyDialog"
        @dialogOk="resetNewKey()"
        @dialogCancel="resetNewKey()"
        @update:showDyamicDialog="(state) => (showGeneratedKeyDialog = state)"
        :cancelBtnHidden="true"
        max-width="500"
        okBtnColor="primary"
      >
        <template v-slot:title>New API Key</template>
        <template v-slot:content>
          <p class="pb-4">
            Your new API key "{{ newKey.name }}" has been created. Copy and
            store it securely, this is the only time you'll see it. If lost,
            you'll need to generate a new one.
          </p>
          <v-text-field
            variant="outlined"
            label="API Key"
            v-model="newKey.token"
            append-inner-icon="mdi-content-copy"
            @click:append-inner="valueToClipboard(newKey.token)"
            readonly
            autocomplete="off"
          >
          </v-text-field>
        </template>
        <template v-slot:okBtnTitle>Close</template>
      </DynamicDialog>
    </v-container>
  </v-theme-provider>
</template>

<script>
import { systemMessages } from "@/mixins/SystemMessages";
import { copyToClipboard } from "@/mixins/CopyToClipboard";
import DynamicDialog from "@/components/DynamicDialog.vue";
import AvatarState from "@/components/AvatarState.vue";

export default {
  name: "DatastoreApiKeys",
  components: { DynamicDialog, AvatarState },
  mixins: [systemMessages, copyToClipboard],
  emits: ["datastore:apikeysmounted"],
  data: () => ({
    kpis: {
      all_time: {},
      client: {},
      last_30_days: {},
      month_to_date: {},
      this_year: {},
    },
    apiTableHeaders: [],
    tableSort: [{ key: "created_at", order: "asc" }],
    keysPerPage: 10,
    usageFilterArray: [],
    selectedUsageFilter: null,
    showDeleteDialog: false,
    showRenameDialog: false,
    showNewKeyNicknameDialog: false,
    showGeneratedKeyDialog: false,
    selectedRow: null,
    newKey: { nickname: null, key: null },
    validationRules: {
      required: (value) => !!value || "A value is required",
    },
    snackbarMsg: null,
    userLocale: null,
    userTimeZone: null,
  }),
  created() {
    this.userLocale = navigator.language || "en-GB";
    this.userTimeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";
    this.setUsageFilter();
    this.setApiTableHeaders();
    this.setSnackbarMsg();
    this.fetchApiStats();
    this.fetchApiKeys();
  },
  mounted() {
    this.$emit("datastore:apikeysmounted");
  },
  computed: {
    formattedClientTransferred() {
      return this.client_transferred_mb >= 1000
        ? `${this.round2dp(this.client_transferred_mb / 1000)} GB`
        : `${this.client_transferred_mb} MB`;
    },
    formattedClientLimit() {
      return this.client_limit_mb >= 1000
        ? `${this.round2dp(this.client_limit_mb / 1000)} GB`
        : `${this.client_limit_mb} MB`;
    },
    fetchingApiKeys: {
      get() {
        return this.$store.getters["datastore/fetchingApiKeys"];
      },
      set(val) {
        this.$store.commit("datastore/setFetchingApiKeys", val);
      },
    },
    apiKeys: {
      get() {
        return this.$store.getters["datastore/apiKeys"];
      },
      set(val) {
        this.$store.commit("datastore/setApiKeys", val);
      },
    },
    client_transferred_mb: {
      get() {
        if (!this.kpis.client.client_transferred_kb) {
          return 0;
        }

        return this.round2dp(this.kpis.client.client_transferred_kb / 1024);
      },
    },
    client_limit_mb: {
      get() {
        if (!this.kpis.client.client_limit_mb) {
          return 0;
        }

        return this.round2dp(this.kpis.client.client_limit_mb);
      },
    },
    client_usage_percent: {
      get() {
        if (this.client_transferred_mb == 0) {
          return 0;
        }

        return this.round2dp(
          (this.client_transferred_mb / this.client_limit_mb) * 100,
        );
      },
    },
    usage_bar_colour: {
      get() {
        let colour = "success";

        if (
          this.client_usage_percent >= 51 &&
          this.client_usage_percent <= 90
        ) {
          colour = "warning";
        }
        if (this.client_usage_percent >= 91) {
          colour = "error";
        }

        return colour;
      },
    },
    user_requests: {
      get() {
        return this.kpis[this.selectedUsageFilter].user_requests ?? "0";
      },
    },
    user_errors: {
      get() {
        return this.kpis[this.selectedUsageFilter].user_errors ?? "0";
      },
    },
    user_transferred_mb: {
      get() {
        if (!this.kpis[this.selectedUsageFilter].user_transferred_kb) {
          return 0;
        }

        return this.round2dp(
          this.kpis[this.selectedUsageFilter].user_transferred_kb / 1024,
        );
      },
    },
    noApiKeysMessage() {
      return !this.apiKeys?.length && !this.fetchingApiKeys;
    },
  },
  methods: {
    /** populate the usage dropdown */
    setUsageFilter(selected = "month_to_date") {
      this.usageFilterArray = [
        {
          title: "Month to date",
          value: "month_to_date",
        },
        {
          title: "Last 30 days",
          value: "last_30_days",
        },
        {
          title: "This year",
          value: "this_year",
        },
        {
          title: "All time",
          value: "all_time",
        },
      ];

      this.selectedUsageFilter = selected;
    },

    /** define api keys table headers */
    setApiTableHeaders() {
      this.apiTableHeaders = [
        { title: "Nickname", value: "name", sortable: true },
        { title: "Created", value: "created", sortable: true },
        { title: "Last used", value: "last_used", sortable: true },
        { value: "action", align: "end" },
      ];
    },

    /** define snackbar messaged based on action being performed */
    setSnackbarMsg() {
      this.snackbarMsg = {
        rename: {
          success: "Api key renamed",
          error: "There was a problem renaming api key",
        },
        delete: {
          success: "API key deleted successfully",
          error: "There was a problem deleting api key",
        },
      };
    },

    /** obfuscate string in api keys table key column except last 6 digits */
    obfuscateString(value, visibleChars = 6) {
      return value.replace(
        new RegExp(".(?=.{" + visibleChars + "})", "g"),
        "*",
      );
    },

    /** copy supplied value to clipboard canvas */
    async valueToClipboard(value) {
      try {
        //Copy Success
        await copyToClipboard(value);
        this.successPill({
          icon: "mdi-content-copy",
          title: "Copied to clipboard",
        });
      } catch (error) {
        //Copy Error
        this.errorPill({
          icon: "mdi-content-copy",
          title: error.message,
        });
      }
    },

    /** fetch api keys from the backend and update vuex */
    async fetchApiKeys() {
      try {
        const response = await this.$axios.get("/datastore/apikeys");
        this.apiKeys = response.data;
      } catch (error) {
        this.errorMsg({
          title: "There was an error",
          message: "there was a problem fetching your API keys",
        });
      }
    },

    /** fetch usage stats from backend to populate kpis */
    async fetchApiStats() {
      try {
        const response = await this.$axios.get("/datastore/apistats");
        this.kpis = response.data;
      } catch (error) {
        this.errorMsg({
          title: "There was an error",
          message: "there was a problem fetching API usage stats",
        });
      }
    },

    /** update selected api table row */
    setSelectedRow(row) {
      this.selectedRow = { ...row }; //clone row object
    },

    /** save row changes to backend */
    async updateRow(row) {
      try {
        await this.$axios.put("/datastore/apikeys/" + row.id, row);
        this.showRenameDialog = false;
        this.fetchApiKeys();
        this.successPill({
          title: this.snackbarMsg.rename.success,
        });
      } catch (error) {
        this.errorMsg({
          title: this.snackbarMsg.rename.error,
        });

        throw error; //rethrow for catching downstream
      }
    },

    /** rename an api key */
    async renameKey(row) {
      row.name = row.name.trim();

      try {
        await this.updateRow(row, "rename");
        this.showRenameDialog = false;
        this.fetchApiKeys();
      } catch {
        //handled by parent
      }
    },

    /** reactivate an api key */
    reactivateConfirmation() {
      this.showReactivateDialog = true;
    },

    /** show delete confirmation dialog */
    deleteConfirmation() {
      this.showDeleteDialog = true;
    },

    /** delete an api key */
    async deleteKey(row) {
      try {
        await this.$axios.delete("/datastore/apikeys/" + row.id);
        this.showDeleteDialog = false;
        this.fetchApiKeys();
        this.infoPill({
          title: this.snackbarMsg.delete.success,
        });
      } catch (error) {
        this.errorMsg({
          title: this.snackbarMsg.delete.error,
        });

        throw error; //rethrow for catching downstream
      }
    },

    /** show new api key nickname dialog */
    newKeyDialog() {
      this.showNewKeyNicknameDialog = true;
    },

    /** create a new key record on the backend and display to user*/
    async generateNewKey() {
      try {
        const response = await this.$axios.post(
          "/datastore/apikeys",
          this.newKey,
        );
        this.showNewKeyNicknameDialog = false;
        this.newKey.token = response.data.token;
        this.showGeneratedKeyDialog = true;
        this.fetchApiKeys();
      } catch (error) {
        this.errorMsg({
          title: "There was an error",
          message: "unable to generate new api key",
        });
      }
    },

    /** clear new key details once dialog is closed - ready for next one */
    resetNewKey() {
      this.showNewKeyNicknameDialog = false;
      this.showGeneratedKeyDialog = false;
      this.newKey = { nickname: null, key: null };
    },

    /** helper function to round any number to 2dp e.g 3.44666666 to 3.45*/
    round2dp(num) {
      return Math.round(num * 100) / 100;
    },
  },
};
</script>

<style scoped>
.kpi-card,
.usage-card {
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #4d4d4d;
}

.kpi-card .title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1em;
}

.usage-card .title {
  font-size: 22px;
}

.kpi-card .subtitle {
  font-size: 14px;
  font-weight: 500;
}

.kpi-card .subtitle i {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

:deep(.v-data-table__td .v-btn) {
  min-width: 20px !important;
  width: 20px !important;
  padding: 4px !important;
}
</style>
