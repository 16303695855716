<template>
  <div v-if="viewInfo.indicatorInfo.id">
    <IndicatorDetailsDialog
      v-model:dialog="mapAboutIndDialog"
      :indicator="viewInfo?.indicatorInfo"
    />
    <MapYourAreas
      v-model:mapYourAreasDialog="mapYourAreasDialog"
      :viewInfo="viewInfo"
    />
    <div>
      <v-card flat class="pa-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-title>
              <template #default="{}">
                <div class="subheader text-left">
                  {{ viewInfo.indicatorInfo.indicator_name }}
                </div>
              </template>
              <template #actions>
                <!-- Correct placement of actions -->
                <!-- Condition: Not open and not loading -->
                <v-icon
                  v-if="!isOpen && viewInfo.quintiles.q1_min !== 'loading'"
                  title="information"
                  id="informationIcon"
                  color="success"
                  size="32"
                >
                  mdi-information-outline
                </v-icon>
                <!-- Condition: Open and not loading -->
                <v-icon
                  v-else-if="isOpen && viewInfo.quintiles.q1_min !== 'loading'"
                  color="error"
                  size="32"
                >
                  mdi-arrow-up-drop-circle-outline
                </v-icon>
                <!-- Condition: Loading -->
                <v-progress-circular
                  v-else
                  indeterminate
                  color="#51627C"
                  size="25"
                />
              </template>
            </v-expansion-panel-title>
            <v-divider></v-divider>
            <v-expansion-panel-text class="text-left">
              <div style="margin-bottom: 20px">
                {{ viewInfo.indicatorInfo.indicator_legend }}
              </div>
              <!-- data display level dropdown -->
              <MapDataLevelSelect
                class="mb-5"
                :selectedDataLevelId="this.viewInfo.viewportInfo.dataLevel"
                :lowestDataLevelId="
                  this.viewInfo.indicatorInfo.lowest_show_level
                "
                :lockedView="lockedView"
                :mapAggregationLevels="mapAggregationLevels"
                @selectedDataLevelChanged="
                  (newLevel) => this.$emit('selectedDataLevelChanged', newLevel)
                "
                @lockViewChanged="
                  (state) => this.$emit('lockViewChanged', state)
                "
              ></MapDataLevelSelect>
              <!-- quintiles -->
              <div class="d-flex mb-2">
                <MapQuintiles
                  :viewInfo="viewInfo"
                  :colourScheme="colourScheme"
                />
                <v-card-text
                  v-if="viewInfo.quintiles.q1_min == 'loading'"
                  class="pa-0"
                  style="margin-top: 18px; margin-bottom: 18px"
                >
                  <div class="text-center">
                    <v-progress-circular
                      :size="70"
                      :width="3"
                      color="#51627C"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </v-card-text>
              </div>
              <!-- buttons -->
              <v-card-text
                v-if="viewInfo.quintiles.q1_min === null"
                style="height: 150px !important"
                class="text-center"
              >
                <v-row justify="space-around">
                  <v-icon
                    x-large
                    color="red"
                    style="margin-top: 10% !important"
                  >
                    >mdi-database-remove</v-icon
                  >
                </v-row>
                <v-card-text> No Data </v-card-text>
              </v-card-text>
              <v-row align="center" justify="space-around" class="my-2">
                <v-btn
                  color="primary"
                  :width="hideDownload ? '130px' : '180px'"
                  @click="mapAboutIndDialog = true"
                  tile
                  aria-label="Details"
                >
                  Details
                </v-btn>
                <v-btn
                  color="primary"
                  :width="hideDownload ? '130px' : '180px'"
                  @click="
                    sendAnalytics();
                    mapYourAreasDialog = true;
                  "
                  tile
                  aria-label="Your Areas"
                >
                  Your Areas
                </v-btn>
                <v-btn
                  :loading="downloading"
                  v-if="hideDownload"
                  color="primary"
                  width="130px"
                  @click="
                    sendAnalytics(true);
                    downloadDataset();
                  "
                  tile
                  :aria-label="
                    isDemoAccount
                      ? 'Downloading unavailable during the trial'
                      : 'Download this dataset'
                  "
                >
                  <v-tooltip
                    v-if="isDemoAccount"
                    activator="parent"
                    location="top"
                    >Downloading unavailable during the trial</v-tooltip
                  >
                  Download
                </v-btn>
              </v-row>
              <v-row>
                <v-switch
                  id="toggleswitch"
                  v-if="showDataBoundariesSwitch"
                  v-model="dataVisToggleState"
                  @change="
                    this.$emit('showDataOutsideBoundaries', dataVisToggleState)
                  "
                  label="Display data visualisations outside area boundaries"
                  color="black"
                  class="text-left text-black"
                ></v-switch>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>
    <div>
      <v-divider></v-divider>
      <v-card
        elevation="0"
        height="50px"
        class="d-flex ma-3"
        flat
        tile
        :style="
          this.areaMouseOverInfo.freeze
            ? 'outline-style: solid; outline-color: black;'
            : 'outline-style: inherit; outline-color: white;'
        "
      >
        <div class="text-start" tile elevation="0">
          <b>{{ levelNameAbr }}: </b>{{ areaName ? areaName : "None" }}
        </div>
        <v-spacer></v-spacer>
        <div class="text-start" tile elevation="0">
          <b>Value: </b> {{ this.getAreaValue(value) }}
        </div>
      </v-card>
    </div>
    <v-divider></v-divider>
  </div>
</template>

<script>
import IndicatorDetailsDialog from "@/components/IndicatorDetailsDialog";
import MapYourAreas from "@/components/MapYourAreas";
import { formatDataValue } from "@/mixins/FormatDataValue";
import { exportToExcel } from "../mixins/ExportExcelFile";
import MapQuintiles from "@/components/MapQuintiles.vue";
import MapDataLevelSelect from "@/components/MapDataLevelSelect.vue";

export default {
  name: "MAPINFO",
  data: () => ({
    downloading: false,
    mapAboutIndDialog: false,
    mapYourAreasDialog: false,
    areaName: null,
    value: null,
    panelToggle: false,
    lockView: false,
    isOpen: false,
    dataVisToggleState: false,
  }),
  computed: {
    isDemoAccount() {
      return this.$store.state.config.customClientConfig.client_type === "demo"
        ? true
        : false;
    },
    hideDownload() {
      return !this.$store.state.config.userProfile.is_public_site_user_profile;
    },
    headerColour() {
      return this.$store.state.config.siteConfig.toolbar_colour;
    },
    computedMapInfoDialog: {
      get() {
        return this.mapInfoDialog;
      },
      set(val) {
        this.$emit("update:mapInfoDialog", val);
      },
    },
    levelName() {
      if (!this.viewInfo.viewportInfo.dataLevel) {
        return "Unknown";
      } else {
        const level = this.$store.state.config.siteConfig.area_level_names.find(
          (item) => item.area_level === this.viewInfo.viewportInfo.dataLevel,
        );
        return level ? level.area_level_name : "Unknown";
      }
    },
    levelNameAbr() {
      if (!this.viewInfo.viewportInfo.dataLevel) {
        return "Unknown";
      } else {
        const level = this.$store.state.config.siteConfig.area_level_names.find(
          (item) => item.area_level === this.viewInfo.viewportInfo.dataLevel,
        );
        return level ? level.area_level_name_abr : "Unknown";
      }
    },
  },
  components: {
    MapQuintiles,
    IndicatorDetailsDialog,
    MapYourAreas,
    MapDataLevelSelect,
  },
  props: {
    mapInfoDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    viewInfo: {
      type: Object,
      default: () => ({}),
    },
    areaMouseOverInfo: {
      type: Object,
      default: () => ({}),
    },
    colourScheme: {
      type: Array,
      default: () => [],
    },
    lockedView: {
      type: Boolean,
      default: false,
    },
    showDataBoundariesSwitch: {
      type: Boolean,
      default: false,
    },
    showDataOutsideBoundaries: {
      type: Boolean,
      default: true,
    },
    mapAggregationLevels: {
      type: Array,
      default: () => [1, 2, 5, 6],
    },
  },
  mounted() {},
  methods: {
    downloadDataset() {
      if (this.isDemoAccount) {
        return;
      }
      this.downloading = true;
      this.$axios
        .get(
          "/indicator-data/" +
            this.viewInfo.viewportInfo.ind_id +
            "/" +
            this.viewInfo.viewportInfo.dataLevel,
        )
        .then((response) => {
          // Build sheets
          const { metadata, data } = response.data["sheets"];
          const sheets = [
            {
              sheetName: metadata["sheetName"],
              headers: metadata["headers"],
              items: metadata["items"],
            },
            {
              sheetName: data["sheetName"],
              headers: data["headers"],
              items: data["items"],
            },
          ];
          exportToExcel(sheets, response.data["filename"]);
          this.downloading = false;
        })
        .catch(() => {
          this.emit.emit("systemMessage", {
            message: "There was an error downloading the data",
            title: "Error! Data download failed",
            timeout: -1,
            colour: "red",
          });
          this.downloading = false;
        });
    },
    getAreaValue(value) {
      if (this.viewInfo.indicatorInfo) {
        // return something nicer if there's nothing there
        if (value === null || value === undefined) {
          return "None";
        }

        //any % over 100 should be capped
        const max_value =
          this.viewInfo.indicatorInfo.suffix === "%" ? 100 : false;

        // round it to the decimal places
        value = formatDataValue(
          value,
          this.viewInfo.indicatorInfo.decimal_places,
          max_value,
        );

        let prefix = this.viewInfo.indicatorInfo.prefix
          ? this.viewInfo.indicatorInfo.prefix
          : "";
        let suffix = this.viewInfo.indicatorInfo.suffix
          ? this.viewInfo.indicatorInfo.suffix
          : "";

        // send it back with any prefix or suffix
        return prefix + value + suffix;
      } else {
        return "-";
      }
    },
    toggleLockView() {
      this.lockView = !this.lockView;
      console.log("lock = " + this.lockView);
      this.$emit("lockViewChanged", this.lockView);
    },
    togglePanelCloseIcon() {
      if (this.panelToggle == false) {
        this.panelToggle = true;
      } else {
        this.panelToggle = false;
      }
    },
    sendAnalytics(downLoadData = false) {
      if (this.isDemoAccount && downLoadData) {
        return;
      }
      this.$axios.put("/send-analytics", {
        event: "map_indicator_downloaded",
        moduleName: this.$route.path,
        pageName: this.$route.name,
        indID: this.viewInfo.viewportInfo.ind_id,
        dataLevel: this.viewInfo.viewportInfo.dataLevel,
      });
    },
  },
  watch: {
    lockedView: {
      handler() {
        this.lockView = this.lockedView;
      },
      immediate: true,
    },
    areaMouseOverInfo: {
      handler() {
        if (this.areaMouseOverInfo.areaInfo) {
          if (!this.areaMouseOverInfo.freeze) {
            this.areaName =
              this.areaMouseOverInfo.areaInfo.feature.getProperty("area_name");
            this.value =
              this.areaMouseOverInfo.areaInfo.feature.getProperty("value");
          }
        } else {
          this.areaName = null;
          this.value = null;
        }
      },
      deep: true,
    },
    showDataOutsideBoundaries: {
      handler() {
        this.dataVisToggleState = this.showDataOutsideBoundaries;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.infoButtons {
  position: absolute;
  top: 60px;
  right: 20px;
  margin-right: 5px;
  z-index: 1;
  max-width: 400px;
}

.subheader {
  font-size: 20px;
  font-weight: 520;
  padding: 10px;
}

.header-hover:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.v-switch :deep(.v-label) {
  opacity: 1;
}
</style>
