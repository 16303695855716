<template>
  <span>
    <v-card-text :style="'height:' + this.height + 'vh; overflow: auto;'">
      <v-card-title class="text-start"
        ><h1 class="h1">Geometry Importer</h1></v-card-title
      >
      <v-divider></v-divider>
      <!-- GeoJSON card -->
      <v-card-text tile width="100%" pa-5>
        <v-card-subtitle class="text-start">Import GeoJSON</v-card-subtitle>
        <v-card-text class="text-start">
          Import complete GeoJSON files for both population centroids and
          standard areas. For Boundaries, you will need to know the 'Area Code'
          & 'Area_Name', for Centroids, just the 'Area Code' field is required.
          <br />
          You can't upload a few countries at the same time. (use QGIS to split
          it into different files)
        </v-card-text>
        <v-container class="d-flex justify-center">
          <v-card width="1000">
            <GeoJSONImporter></GeoJSONImporter>
          </v-card>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <!-- Lookup upload -->
      <v-card-text tile width="100%" pa-5>
        <v-card-subtitle class="text-start">Lookup upload</v-card-subtitle>
        <v-card-text class="text-start">
          <p>
            Upload a .csv file to overwrite an area level lookup to another. You
            will need two columns, 'child_area_code' & 'parent_area_code'. Only
            existing lookups of the mentioned child_level -> parent_level in you
            file (withing the specified timepoint) will be binned and replaced
            with the ones you provide
          </p>
          <p>
            Please be aware this will remove the entire lookup for the
            child-parent combination, so make sure your prodived file is
            complete.
          </p>
        </v-card-text>
        <v-container class="d-flex justify-center">
          <v-card width="1000">
            <LookupUpload></LookupUpload>
          </v-card>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <!-- GeoJSON Postcode card -->
      <v-card-text tile width="100%" pa-5>
        <v-card-subtitle class="text-start"
          >Import/Update Postcodes</v-card-subtitle
        >
        <v-card-text class="text-start">
          Import the latest ONSPD GeoJSON file and import/update any new
          postcodes
        </v-card-text>
        <v-container class="d-flex justify-center">
          <v-card width="1000">
            <GeoJSONPostcodeImporter></GeoJSONPostcodeImporter>
          </v-card>
        </v-container>
      </v-card-text>
    </v-card-text>
  </span>
</template>
<script>
import GeoJSONImporter from "@/components/GeoJSONImporter";
import GeoJSONPostcodeImporter from "@/components/GeoJSONPostcodeImporter";
import LookupUpload from "@/components/LookupUpload.vue";
import { useDisplay } from "vuetify";

export default {
  name: "GeometryImporter",
  data: () => ({
    breakpoint: useDisplay().name,
  }),
  file: null,
  geojson: null,
  components: {
    GeoJSONImporter,
    GeoJSONPostcodeImporter,
    LookupUpload,
  },
  computed: {
    height() {
      switch (this.breakpoint) {
        case "xs":
          return 60;
        case "sm":
          return 70;
        case "md":
          return 90;
        case "lg":
          return 90;
        case "xl":
          return 100;
        default:
          return 90;
      }
    },
  },
  props: {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style scoped></style>
