<template>
  <v-container fluid class="pa-2" style="width: 100%">
    <v-card tile min-height="100%">
      <v-card-title id="cam-title"
        ><h1 class="h1 text-left mt-4">Custom Areas</h1></v-card-title
      >
      <div>
        <div class="topPanel">
          <v-card-title class="justify-center"
            >Create New Custom Area
          </v-card-title>
          <!--    panel buttons -->
          <v-card-actions>
            <v-row justify="center" align="center">
              <CustomAreaManagerUpsert
                v-model:standardCustomAreaDialog="standardCustomAreaDialog"
                v-model:areaIndex="customAreasIndex"
                :schema="customAreaFieldSchema"
                :categories="categories"
                @getCustomAreasByCategory="getCustomAreasByCategory"
              />
              <CreateAreaDrawOnMap
                v-model:drawCustomAreaDialog="drawCustomAreaDialog"
                v-model:areaIndex="customAreasIndex"
                :schema="customAreaFieldSchema"
                :categories="categories"
                :customAreaToEdit="customAreaToEdit"
                @getCustomAreasByCategory="getCustomAreasByCategory"
                @clearEditArea="customAreaToEdit = {}"
              />
              <CreateAreaPostcodes
                v-if="!isAustralianSite"
                :postcodeCustomAreaDialog="postcodeCustomAreaDialog"
                @dialog="(action) => (postcodeCustomAreaDialog = action)"
                v-model:areaIndex="customAreasIndex"
                :schema="customAreaFieldSchema"
                :categories="categories"
                @getCustomAreasByCategory="getCustomAreasByCategory"
              />
              <CustomAreaBulk
                v-model:bulkCustomAreaDialog="bulkCustomAreaDialog"
                v-model:areaIndex="customAreasIndex"
                :schema="customAreaFieldSchema"
                :categories="categories"
                @getCustomAreasByCategory="getCustomAreasByCategory"
              />
              <CustomAreaBasicEdit
                v-model:dialog="geojsonDialog"
                v-model:areaIndex="customAreasIndex"
                :schema="customAreaFieldSchema"
                :categories="categories"
                @getCustomAreasByCategory="getCustomAreasByCategory"
              />
              <CreateAreaGeoJSON
                :schema="customAreaFieldSchema"
                :categories="categories"
                @getCustomAreasByCategory="getCustomAreasByCategory"
              />
            </v-row>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions class="searchBlock">
            <v-autocomplete
              v-model="userSelectedSearchCustomArea"
              :items="allCustomAreasList"
              :loading="customAreasSearchLoading"
              v-model:search="search"
              id="custom-area-search-box"
              item-title="name"
              label="Search for Custom Areas"
              placeholder="Start typing to Search"
              prepend-icon="mdi-magnify"
              return-object
              :no-data-text="
                customAreasSearchLoading ? 'Searching' : 'No Results'
              "
              @update:modelValue="
                onAutoCompleteSelect(userSelectedSearchCustomArea)
              "
              style="max-width: 700px"
              density="compact"
              rounded="0"
              clearable
              variant="outlined"
              autocomplete="off"
            >
            </v-autocomplete>
            <v-btn
              color="primary"
              variant="elevated"
              @click="addCategory"
              id="createNewCategory"
              tile
              class="ml-5 mb-5"
              aria-label="New Category"
            >
              New Category
            </v-btn>

            <v-btn
              v-if="selectedAreasForPublicReport.length"
              @click="comparatorDialog = true"
              class="ml-5 mb-5"
              variant="elevated"
              color="warning"
              title="Select comparators for public summary reports"
              tile
              aria-label="select comparators"
            >
              <template v-slot:append>
                <v-badge
                  color="red"
                  class="mb-9"
                  :content="selectedAreasForPublicReport.length.toString()"
                >
                </v-badge>
              </template>

              select comparators
            </v-btn>
            <CustomAreaCategoriesUpsert
              :createMode="createMode"
              v-model:categoryDialog="categoryDialog"
              v-model:filterCategories="filterCategories"
              :categoryIndex="categoryIndex"
              @getCategories="getCategories"
            />
          </v-card-actions>
        </div>
        <!-- expansion panels -->
        <v-card-text
          v-if="pageLoading"
          :style="'height:' + (height - 340) + 'px;'"
        >
          <div class="progressCategory">
            <v-progress-circular
              :size="180"
              :width="3"
              color="#51627C"
              indeterminate
            >
              Loading Categories
            </v-progress-circular>
          </div>
        </v-card-text>
        <v-card-text
          :style="'height:' + (height - 340) + 'px; overflow: auto;'"
          v-else
        >
          <v-expansion-panels
            focusable
            id="reportCategories"
            v-model="openedPanel"
          >
            <v-expansion-panel
              v-for="(categoryItem, i) in filterCategories"
              :key="categoryItem.id"
            >
              <v-expansion-panel-title
                @click="
                  selectedCategory = categoryItem;
                  userSelectedSearchCustomArea = null;
                "
                :id="i"
              >
                {{ categoryItem.name }}
                <v-spacer />
                <!--- card buttons for all category cards -->
                <v-card-actions
                  class="mr-2"
                  style="padding: 0; min-height: 10px; height: 2px"
                >
                  <v-btn
                    v-if="
                      i === openedPanel &&
                      selectedCustomAreas.length > 0 &&
                      $store.state.config.userProfile.email.includes(
                        '@ocsi.co.uk',
                      )
                    "
                    color="error"
                    id="deleteBulkCustomArea"
                    tile
                    variant="elevated"
                    @click.stop="showBulkDeleteCustomAreaDialog = true"
                    aria-label="Delete Selected Custom Areas"
                  >
                    <template v-slot:prepend>
                      <v-badge
                        color="secondary"
                        class="mb-9"
                        :content="selectedCustomAreas.length.toString()"
                      >
                      </v-badge>
                    </template>
                    Delete Selected
                  </v-btn>
                </v-card-actions>
                <!--- card buttons for catgeory cards excluding 'All Categories' and 'Uncategorised' -->
                <v-card-actions
                  v-if="i !== 0 && i !== 1"
                  style="padding: 0; min-height: 10px; height: 2px"
                >
                  <ExportCustomAreas
                    v-if="
                      i === openedPanel &&
                      $store.state.config.customClientConfig.client_type !==
                        'demo'
                    "
                    :category="categoryItem"
                  >
                    <template v-slot:activator="{ onClick }">
                      <v-btn
                        color="primary"
                        tile
                        variant="elevated"
                        prepend-icon="mdi-download"
                        aria-label="Export GeoJSON"
                        @click.stop="onClick"
                      >
                        Export
                      </v-btn>
                    </template>
                  </ExportCustomAreas>
                  <v-btn
                    v-if="i === openedPanel"
                    color="error"
                    :id="'deleteCategory_' + i"
                    tile
                    variant="elevated"
                    @click.stop="showDeleteCategoryDialog = true"
                    aria-label="Delete Category"
                  >
                    Delete Category
                  </v-btn>
                  <v-btn
                    class="mr-6"
                    color="primary"
                    tile
                    variant="elevated"
                    @click.stop="editCategory(i)"
                    aria-label="Edit Category Name"
                  >
                    Edit Category Name
                  </v-btn>
                </v-card-actions>
              </v-expansion-panel-title>
              <v-expansion-panel-text
                v-if="customAreasLoaded"
                :style="
                  $store.state.config.userConfig.permission_level === 1
                    ? 'width: 100%; max-width: 1200px !important'
                    : 'width: 100%; max-width: 900px !important'
                "
              >
                <!--   list of custom areas -->
                <v-data-table
                  fixed-header
                  height="32vh"
                  id="catAreas"
                  :loading="!customAreasLoaded"
                  :headers="tableHeaders"
                  :items="customAreas"
                  :items-per-page="10"
                  v-model:search="searchedAreaName"
                  class="elevation-0 text-left"
                  style="width: 100%"
                >
                  <!--    bulk select column    -->
                  <template
                    v-slot:[`header.bulk_select`]
                    v-if="
                      $store.state.config.userProfile.email.includes(
                        '@ocsi.co.uk',
                      )
                    "
                  >
                    <th class="d-flex flex-column align-center justify-center">
                      <v-checkbox
                        v-model="selectAllCustomAreas"
                        @change="toggleAllCustomAreaCheckboxes(customAreas)"
                        class="ma-n8 mt-1 ml-3 text-center"
                        hide-details
                      >
                        <v-tooltip activator="parent" location="bottom"
                          >Select All (OCSI Only)
                        </v-tooltip>
                      </v-checkbox>
                    </th>
                  </template>
                  <template
                    v-slot:[`item.bulk_select`]="{ item }"
                    v-if="
                      $store.state.config.userProfile.email.includes(
                        '@ocsi.co.uk',
                      )
                    "
                  >
                    <td class="d-flex flex-column align-center justify-center">
                      <v-checkbox
                        :id="item.id.toString()"
                        :value="item.id"
                        v-model="selectedCustomAreas"
                        @change="bulkCheckboxChecked"
                        class="ma-n8 pa-n8 ml-2"
                        title="OCSI Only"
                      ></v-checkbox>
                    </td>
                  </template>
                  <template v-slot:[`item.creator`]="{ item }">
                    {{
                      item.creator_id === null
                        ? "unknown"
                        : item.creator_id === 0
                          ? "OCSI"
                          : item.creator.name
                    }}
                  </template>

                  <template v-slot:[`item.date_last_viewed`]="{ item }">
                    {{
                      item.date_last_viewed
                        ? formatDateDashes(item.date_last_viewed)
                        : "unknown"
                    }}
                  </template>
                  <template v-slot:[`item.views_count`]="{ item }">
                    {{ item.views_count }}
                  </template>
                  <!--     Actions column     -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <span>
                      <v-tooltip activator="parent" location="top">{{
                        isDemoAccount
                          ? "Downloading unavailable during the trial"
                          : `export ${item?.name}`
                      }}</v-tooltip>
                      <ExportCustomAreas :customArea="item">
                        <template v-slot:activator="{ onClick }">
                          <v-icon
                            size="small"
                            :aria-label="
                              isDemoAccount
                                ? 'Downloading unavailable during the trial'
                                : `export ${item?.name}`
                            "
                            class="mr-2"
                            @click="onClick"
                            @keydown.enter="onClick"
                          >
                            mdi-download
                          </v-icon>
                        </template>
                      </ExportCustomAreas>
                    </span>
                    <v-icon
                      size="small"
                      aria-label="edit custom area"
                      id="editCustomAreaIcon"
                      :name="item.name"
                      class="mr-2"
                      @click="editCustomArea(item)"
                      @keydown.enter="editCustomArea(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      size="small"
                      id="deleteCustomAreaIcon"
                      aria-label="delete custom area"
                      @click="
                        showDeleteCustomAreaDialog = true;
                        customAreaToDelete = item;
                      "
                      @keydown.enter="
                        showDeleteCustomAreaDialog = true;
                        customAreaToDelete = item;
                      "
                    >
                      mdi-delete
                    </v-icon>
                  </template>

                  <!--      -->
                  <template
                    v-slot:[`header.public_summary_report`]="{ column }"
                  >
                    <div
                      v-if="
                        $store.state.config.siteConfig.is_public_site &&
                        $store.state.config.userProfile.email.includes(
                          '@ocsi.co.uk',
                        )
                      "
                      class="mb-5 d-flex flex-column align-center justify-center"
                    >
                      <v-layout class="mt-2 mb-5">{{ column.title }}</v-layout>
                      <v-checkbox
                        v-model="selectAllAreas"
                        @change="tickAllCheckboxesForPublicReport()"
                        class="ma-n8 mt-1 ml-3 text-center"
                        hide-details
                      >
                        <v-tooltip activator="parent" location="bottom"
                          >Only visible for OCSI stuff
                        </v-tooltip>
                      </v-checkbox>
                    </div>
                  </template>

                  <!--     Public summary report checkbox      -->
                  <template v-slot:[`item.public_summary_report`]="{ item }">
                    <th
                      v-if="
                        $store.state.config.siteConfig.is_public_site &&
                        $store.state.config.userProfile.email.includes(
                          '@ocsi.co.uk',
                        )
                      "
                      class="d-flex flex-column align-center justify-center"
                    >
                      <v-checkbox
                        v-model="item.public_summary_report"
                        @change="togglePublicReport(item)"
                        class="ma-n8 pa-n8 ml-2"
                        title="This only visible for OCSI stuff"
                      ></v-checkbox>
                    </th>
                  </template>

                  <!-- Add header for Show on public site -->
                  <template v-slot:[`header.show_on_public_site`]="{ column }">
                    <div
                      v-if="$store.state.config.siteConfig.is_public_site"
                      class="mb-5 d-flex flex-column align-center justify-center"
                    >
                      <v-layout class="mt-2 mb-5">{{ column.title }}</v-layout>
                      <v-checkbox
                        v-model="selectAllShowOnPublicSite"
                        @change="toggleAllShowOnPublicSite"
                        class="ma-n8 mt-1 ml-3 text-center"
                        hide-details
                      >
                        <v-tooltip activator="parent" location="bottom"
                          >Show all on public site
                        </v-tooltip>
                      </v-checkbox>
                    </div>
                  </template>

                  <!-- Add item for Show on public site -->
                  <template v-slot:[`item.show_on_public_site`]="{ item }">
                    <th
                      v-if="$store.state.config.siteConfig.is_public_site"
                      class="d-flex flex-column align-center justify-center"
                    >
                      <v-checkbox
                        v-model="item.show_on_public_site"
                        @change="toggleShowOnPublicSite(item)"
                        class="ma-n8 pa-n8 ml-2"
                        title="Show on public site"
                      ></v-checkbox>
                    </th>
                  </template>
                </v-data-table>
              </v-expansion-panel-text>
              <v-expansion-panel-text v-else style="width: 100%">
                <div class="smallSpinner">
                  <v-progress-circular
                    :size="100"
                    :width="2"
                    color="#51627C"
                    indeterminate
                    >Loading
                  </v-progress-circular>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </div>
      <SearchAreasFullscreen
        v-model:dialog="comparatorDialog"
        v-model:selectedAreasForPublicReportProp="selectedAreasForPublicReport"
        @clear="clearCheckboxes"
        :theseLevelsOnly="['LA', 'CSTM', 'CTRY', 'CTY', 'RGN']"
        :maximumSelectionLimit="2"
        :minimumSelectionLimit="2"
        defaultLevel="RGN"
      />
    </v-card>

    <v-dialog v-model="showDeleteCategoryDialog" max-width="500px">
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h5 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title>Confirm Delete category </v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row style="padding: 23px">
              <v-card-text class="text-center">
                Are you sure you want to delete the
                <b>"{{ selectedCategory.name }}" </b>
                category?
              </v-card-text>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="success"
            tile
            variant="elevated"
            @click="showDeleteCategoryDialog = false"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer />
          <v-btn
            :disable="this.$store.state.busy"
            id="confirmDeleteCategory"
            color="error"
            tile
            variant="elevated"
            @click="deleteCategory()"
            aria-label="delete"
          >
            delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteCustomAreaDialog" max-width="500px">
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h5 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title>Confirm Delete custom area </v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row style="padding: 23px">
              <v-card-text class="text-center">
                Are you sure you want to delete the
                <b>"{{ customAreaToDelete.name }}" </b>
                custom area?
              </v-card-text>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="success"
            tile
            @click="showDeleteCustomAreaDialog = false"
            aria-label="cancel"
            variant="elevated"
          >
            cancel
          </v-btn>
          <v-spacer />
          <v-btn
            :disable="this.$store.state.busy"
            color="error"
            tile
            id="confirmDeleteCustomArea"
            @click="deleteCustomArea()"
            aria-label="delete"
            variant="elevated"
          >
            delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showBulkDeleteCustomAreaDialog" max-width="500px">
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h5 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title>Confirm Bulk Delete custom areas </v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row style="padding: 23px">
              <v-card-text class="text-center">
                Are you sure you want to delete the following custom areas?
                <p>
                  <b v-for="(ca, index) in selectedCustomAreas" :key="index">
                    <span v-if="index !== 0">; </span>
                    "{{ this.customAreas.find((a) => a.id === ca).name }}"
                  </b>
                </p>
              </v-card-text>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="success"
            tile
            @click="showBulkDeleteCustomAreaDialog = false"
            aria-label="cancel"
            variant="elevated"
          >
            cancel
          </v-btn>
          <v-spacer />
          <v-btn
            :disable="this.$store.state.busy"
            color="error"
            tile
            id="confirmBulkDeleteCustomArea"
            @click="bulkDeleteCustomAreas()"
            aria-label="delete"
            variant="elevated"
          >
            delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import CustomAreaBasicEdit from "@/components/CustomAreaBasicEdit.vue";
import CustomAreaSchema from "@/schemas/CustomAreaManager";
import CustomAreaManagerUpsert from "@/components/CustomAreaManagerUpsert";
import CreateAreaDrawOnMap from "@/components/CustomAreaManagerDraw";
import CreateAreaGeoJSON from "@/components/CustomAreaGeoJSON";
import CreateAreaPostcodes from "@/components/CustomAreaManagerPostcodes";
import CustomAreaCategoriesUpsert from "@/components/CustomAreaCategoriesUpsert";
import CustomAreaBulk from "@/components/CustomAreaManagerBulk";
import SearchAreasFullscreen from "@/components/SearchAreasFullscreen.vue";
import ExportCustomAreas from "@/components/ExportCustomAreas.vue";
import { useDisplay } from "vuetify";
import { systemMessages } from "@/mixins/SystemMessages";
import { formatDateDashes } from "@/mixins/FormatDate";

export default {
  name: "CustomAreaManager",
  data: () => ({
    formatDateDashes: formatDateDashes,
    selectAllShowOnPublicSite: false,
    geojsonDialog: false,
    drawCustomAreaDialog: false,
    standardCustomAreaDialog: false,
    postcodeCustomAreaDialog: false,
    bulkCustomAreaDialog: false,
    height: useDisplay().height,
    userSelectedSearchCustomArea: null,
    openedPanel: null,
    pageLoading: true,
    customAreasSearchLoading: false,
    allCustomAreasList: [],
    search: "",
    createMode: false,
    filterCategoriesLoaded: false,
    customAreasLoaded: false,
    customAreaToDelete: {},
    showBulkDeleteCustomAreaDialog: false,
    showDeleteCustomAreaDialog: false,
    showDeleteCategoryDialog: false,
    categoryIndex: null,
    selectedCategory: {},
    customAreaFieldSchema: CustomAreaSchema,
    categories: [],
    filterCategories: null,
    selectedAreasForPublicReport: [],
    categoryDialog: false,
    searchIteration: null,
    comparatorDialog: false,
    selectAllAreas: false,
    customAreaToEdit: {},
    customAreasIndex: null,
    selectAllCustomAreas: false,
    selectedCustomAreas: [],
  }),
  components: {
    CustomAreaBasicEdit,
    CustomAreaManagerUpsert,
    CreateAreaDrawOnMap,
    CreateAreaGeoJSON,
    CreateAreaPostcodes,
    CustomAreaCategoriesUpsert,
    CustomAreaBulk,
    SearchAreasFullscreen,
    ExportCustomAreas,
  },
  mixins: [systemMessages],
  computed: {
    isDemoAccount() {
      return this.$store.state.config.customClientConfig.client_type === "demo"
        ? true
        : false;
    },
    tableHeaders() {
      let headers = [
        {
          value: "bulk_select",
          sortable: false,
        },
        {
          title: "Custom Area",
          align: "start",
          value: "name",
          width: "58%",
        },
        {
          title: "Public summary report",
          value: "public_summary_report",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          title: "Show On Public Site",
          value: "show_on_public_site",
          align: "center",
          sortable: false,
          width: "12%",
        },
        {
          title: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          width: "20%",
        },
      ];
      if (
        [1, 2].includes(this.$store.state.config.userConfig.permission_level)
      ) {
        headers[1].width = "20%";
        headers[4].width = "15%";
        // Remove the "Actions" header from its original position
        const actionsHeader = headers.splice(4, 1)[0];
        headers.push({
          title: "Creator",
          value: "creator",
          align: "left",
          sortable: true,
          width: "18%",
        });
        headers.push({
          title: "Last Used",
          value: "date_last_viewed",
          align: "left",
          sortable: true,
          width: "15%",
        });
        headers.push({
          title: "Usage Count",
          value: "views_count",
          align: "center",
          sortable: true,
          width: "10%",
        });
        headers.push(actionsHeader);
      }
      return headers;
    },
    isAustralianSite() {
      return this.$store.state.config.siteConfig.site_country === "aus";
    },
    customAreas: {
      get() {
        return this.$store.state.customAreas;
      },
      set(value) {
        this.$store.commit("setCustomAreas", value);
      },
    },
    searchedAreaName() {
      if (this.search) {
        return this.search;
      } else {
        return this.userSelectedSearchCustomArea?.name ?? "";
      }
    },
  },
  mounted() {
    this.getCategories();
    this.getFilterCategories();
  },
  methods: {
    toggleShowOnPublicSite(item) {
      this.checkSelectAllShowOnPublicSite();
      // Update the custom area
      let update = [
        {
          id: item.id,
          show_on_public_site: item.show_on_public_site,
        },
      ];
      this.$axios
        .put("/custom-areas/bulk-update-show-on-public-site", update)
        .then()
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to update Show on Public Site",
              message: error.response.data.message,
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    toggleAllShowOnPublicSite() {
      this.customAreas.forEach((item) => {
        item.show_on_public_site = this.selectAllShowOnPublicSite;
      });
      let update = this.customAreas.map((item) => {
        return {
          id: item.id,
          show_on_public_site: item.show_on_public_site,
        };
      });
      this.$axios
        .put("/custom-areas/bulk-update-show-on-public-site", update)
        .then()
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to update Show on Public Site",
              message: error.response.data.message,
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    // Update select all button depending on the state of the checkboxes
    checkSelectAllShowOnPublicSite() {
      this.selectAllShowOnPublicSite = this.customAreas.every(
        (item) => item.show_on_public_site,
      );
    },
    togglePublicReport(item) {
      if (item.public_summary_report === true) {
        // tick the checkbox
        this.selectedAreasForPublicReport.push(item);
      } else {
        // untick the checkbox
        this.selectedAreasForPublicReport =
          this.selectedAreasForPublicReport.filter(
            (area) => area.id !== item.id,
          );
      }

      // if all areas are selected then tick the select all checkbox
      this.selectAllAreas = this.areAllCustomAreasPublic();
    },
    areAllCustomAreasPublic() {
      // check if all areas in the opened category are selected
      return this.customAreas.every((area) => area.public_summary_report);
    },
    tickAllCheckboxesForPublicReport() {
      for (let index = 0; index < this.customAreas.length; index++) {
        this.customAreas[index].public_summary_report = this.selectAllAreas;

        if (this.selectAllAreas) {
          // if the area is not already in the selected areas array then add it
          if (
            !this.selectedAreasForPublicReport.some(
              (selectedArea) => selectedArea.id === this.customAreas[index].id,
            )
          ) {
            this.selectedAreasForPublicReport.push(this.customAreas[index]);
          }
        } else {
          // uncheck all checkboxes in the opened category
          this.selectedAreasForPublicReport =
            this.selectedAreasForPublicReport.filter(
              (selectedArea) => selectedArea.id !== this.customAreas[index].id,
            );
        }
      }
    },
    clearCheckboxes() {
      this.comparatorDialog = false;
      this.selectedAreasForPublicReport = [];
      this.selectAllAreas = false;
      this.customAreas.forEach((area) => (area.public_summary_report = false));
    },
    onAutoCompleteSelect(customArea) {
      if (customArea === null) return;

      // if this is null then assume we are dealing with uncategorised custom areas
      if (customArea.custom_area_category_id === null) {
        this.selectedCategory = this.filterCategories[1];
        this.openedPanel = 1;
        this.scrollToItem(1);
        // we are dealing a specific category
      } else {
        for (let i = 0; i <= this.filterCategories.length; i++) {
          if (
            this.filterCategories[i].id === customArea.custom_area_category_id
          ) {
            this.openedPanel = i;
            this.scrollToItem(i);
            this.selectedCategory = this.filterCategories[i];
            break;
          }
        }
      }
    },
    fetchCustomAreas() {
      // to pass the special characters through the URL we need to encode string
      let searchString = encodeURIComponent(this.search.replace("%", "\\%"));

      this.customAreasSearchLoading = true;
      this.$axios
        .get("/custom-areas/" + searchString + "/" + 0)
        .then((response) => {
          this.allCustomAreasList = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.customAreasSearchLoading = false));
    },
    scrollToItem(index) {
      setTimeout(() => {
        const panel = document.getElementById(index);
        if (panel) {
          panel.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 400);
    },
    deleteCategory() {
      this.emit.emit("systemMessage", {
        title: "Deleting Category",
        message: "Please wait...",
        timeout: 4000,
        colour: "warning",
      });

      // make the call!
      this.$axios
        .delete("/custom-areas/category/" + this.selectedCategory.id)
        .then(() => {
          // now update frontend
          this.selectedCategory = {};
          this.getCategories();
          this.getFilterCategories();
          this.customAreas = null;
          this.openedPanel = null;
          this.showDeleteCategoryDialog = false;
          this.emit.emit("systemMessage", {
            title: "Category Deleted",
            message: "Success!",
            timeout: 3000,
            colour: "green",
          });
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            title: "Error! Category delete failed",
            message: error.response.data.message,
            timeout: -1,
            colour: "red",
          });
          this.apiValidationErrors(error.response.data.errors);
        });
    },
    editCategory(categoryIndex) {
      this.createMode = false;
      this.categoryDialog = true;
      this.categoryIndex = categoryIndex;
    },
    addCategory() {
      this.createMode = true;
      this.categoryDialog = true;
    },
    editCustomArea(item) {
      this.customAreasIndex = this.customAreas.indexOf(item);
      this.customAreaToEdit = item;

      // open the correct dialog according tot he area type
      switch (item.type_id) {
        // standard area
        case 1:
          this.standardCustomAreaDialog = true;
          break;
        // draw on map
        case 2:
          this.drawCustomAreaDialog = true;
          break;
        // postcode
        case 3:
          this.postcodeCustomAreaDialog = true;
          break;
        case 4:
          this.geojsonDialog = true;
          break;
        default:
          // currently just don't open a dialog
          break;
      }
    },
    deleteCustomArea() {
      this.emit.emit("systemMessage", {
        title: "Deleting Custom Area",
        message: "Please wait...",
        timeout: 4000,
        colour: "warning",
      });
      this.showDeleteCustomAreaDialog = false;

      // make the call!
      this.$axios
        .delete("/custom-areas/" + this.customAreaToDelete.id)
        .then(() => {
          // now update frontend
          this.getCustomAreasByCategory();
          this.showDeleteCustomAreaDialog = false;
          //update bulk checkbox and selected count
          if (this.selectedCustomAreas.includes(this.customAreaToDelete.id)) {
            this.selectedCustomAreas.splice(
              this.selectedCustomAreas.indexOf(this.customAreaToDelete.id),
              1,
            );
          }
          this.customAreaToDelete = {};
          this.emit.emit("systemMessage", {
            title: "Success!",
            message: "Custom Area Deleted",
            timeout: 3000,
            colour: "green",
          });
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            title: "Error! Custom area delete failed",
            message: error.response.data.message,
            timeout: -1,
            colour: "red",
          });
          this.apiValidationErrors(error.response.data.errors);
        });
    },
    bulkDeleteCustomAreas() {
      //user info
      this.warnMsg({
        title: "Bulk Deleting Custom Areas",
        message: "Please wait...",
        timeout: 2000,
      });
      this.showBulkDeleteCustomAreaDialog = false;

      //post array to backend using form and ask laravel to handle as DELETE request
      this.$axios
        .post("/bulk-custom-areas", {
          _method: "DELETE",
          categoryId: this.selectedCategory.id,
          customAreas: this.selectedCustomAreas,
        })
        .then((response) => {
          this.closeMsg();

          //all areas deleted
          if (response.data.errors.length === 0) {
            this.successMsg({
              title: "Success!",
              message: "Custom Areas Bulk Deleted",
              timeout: 3000,
            });
          }

          //some areas failed to delete
          if (response.data.errors.length > 0) {
            this.warnMsg({
              title: "Warning!",
              message:
                "The following custom areas could not be deleted: \n" +
                response.data.errors.map((e) => Object.keys(e)[0]).join("; "),
              timeout: -1,
            });
          }

          //update selected custom areas based on remaining areas
          this.selectedCustomAreas = this.selectedCustomAreas.filter((sa) =>
            response.data.customAreas.includes(sa),
          );

          //update remaining custom areas for reactivity
          this.customAreas = response.data.customAreas;
        })
        //all custom areas failed to bulk delete
        .catch((error) => {
          this.closeMsg();

          this.errorMsg({
            title: "Error! Bulk custom area deletion failed",
            message: error.response.data.message,
          });
        });
    },
    filterSelectedAreasForPublicReport() {
      if (this.selectedAreasForPublicReport.length) {
        this.customAreas = this.customAreas.map((area) => {
          area.public_summary_report = this.selectedAreasForPublicReport.some(
            (selectedArea) => selectedArea.id === area.id,
          );
          return area;
        });

        // tick the select all checkbox if all areas are selected
        this.selectAllAreas = this.areAllCustomAreasPublic();
      }
    },
    getCustomAreasByCategory() {
      this.customAreasLoaded = false;
      this.customAreas = [];
      if (Object.keys(this.selectedCategory).length > 0) {
        this.categoryIndex = this.filterCategories.indexOf(
          this.selectedCategory,
        );
        var apiUrl;
        // Get different custom areas depending on category filter choice
        switch (this.selectedCategory.name) {
          case "All Categories":
            apiUrl = "/custom-areas";
            break;
          case "Uncategorised":
            apiUrl = "/uncategorised-custom-areas";
            break;
          default:
            apiUrl = "/custom-areas-by-category/" + this.selectedCategory.id;
        }
        this.$axios
          .get(apiUrl)
          .then(
            function (response) {
              // handle success
              this.customAreas = response.data;
              this.customAreasLoaded = true;

              if (this.selectedAreasForPublicReport.length) {
                this.filterSelectedAreasForPublicReport();
              }
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                title: "Error! Failed to get custom areas",
                message: error.response.data.message,
                timeout: -1,
                colour: "red",
              });
              this.customAreasLoaded = true;
            }.bind(this),
          );
      }
    },
    // Gets categories except for all / uncategorised
    getCategories() {
      this.$axios
        .get("/custom-area-categories")
        .then(
          function (response) {
            // handle success
            this.categories = response.data;
            this.customAreaFieldSchema.custom_area_category_id.selectItems =
              this.categories;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get all clients",
              message: error.response.data.message,
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    // Gets categories for the filter, which includes the special all and uncategorised ones
    getFilterCategories() {
      this.filterCategoriesLoaded = false;
      this.$axios
        .get("/custom-area-filter-categories")
        .then(
          function (response) {
            // handle success
            this.filterCategories = response.data;
            this.filterCategoriesLoaded = true;
            this.pageLoading = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: error.response.data.message,
              message: "Error! Failed to get all clients",
              timeout: -1,
              colour: "red",
            });
            this.pageLoading = false;
          }.bind(this),
        );
    },
    //toggle custom area bulk selection checkboxes
    toggleAllCustomAreaCheckboxes(customAreas) {
      customAreas.forEach((ca) => {
        //Add custom areas not already selected
        if (
          this.selectAllCustomAreas &&
          !this.selectedCustomAreas.includes(ca.id)
        ) {
          this.selectedCustomAreas.push(ca.id);
        }

        //Remove all custom areas if all are selected
        if (
          !this.selectAllCustomAreas &&
          this.selectedCustomAreas.length === this.customAreas.length
        ) {
          this.selectedCustomAreas.splice(0);
        }
      });
    },
    //a single custom area bulk selection checkbox has changed
    bulkCheckboxChecked() {
      //uncheck the select all checkbox if not all boxes are checked
      if (
        this.selectAllCustomAreas &&
        this.selectedCustomAreas.length !== this.customAreas.length
      ) {
        this.selectAllCustomAreas = false;
      }
    },
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal) {
        clearTimeout(this.searchIteration);

        this.searchIteration = setTimeout(() => {
          if (val) {
            this.fetchCustomAreas();
          }
        }, 500);
      }
    },
    selectedCategory: {
      handler() {
        if (this.selectedCategory) {
          this.getCustomAreasByCategory();
        }
      },
      deep: true,
    },
    openedPanel: {
      handler() {
        this.selectAllCustomAreas = false; //uncheck bulk select all checkbox
        this.selectedCustomAreas.splice(0); //deselect any bulk custom area selections when panel changes
      },
    },
  },
};
</script>

<style scoped>
.progressCategory {
  position: relative;
  text-align: center;
  top: 15vh;
  width: 30vw;
  left: 35vw;
}

.smallSpinner {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.topPanel {
  position: sticky;
  top: 0;
  z-index: 2;
}
</style>
