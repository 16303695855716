/** vuex store for datastore module  */
export const datastore = {
  namespaced: true,
  /** state (data) */
  state: () => ({
    fetchingApiKeys: false,
    apiKeys: [],
    selectedIndicator: null,
  }),
  /** setters */
  mutations: {
    setApiKeys(state, value) {
      state.apiKeys = value;
    },
    setSelectedIndicator(state, value) {
      state.selectedIndicator = value;
    },
    setFetchingApiKeys(state, value) {
      state.fetchingApiKeys = value;
    },
  },
  /** getters */
  getters: {
    fetchingApiKeys: (state) => state.fetchingApiKeys,
    apiKeys: (state) => state.apiKeys,
    selectedIndicator: (state) => state.selectedIndicator,
  },
};
