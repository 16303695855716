<template>
  <v-card
    class="mt-4 grey-background"
    variant="outlined"
    density="compact"
    style="border: 1px solid rgba(0, 0, 0, 0.12)"
  >
    <v-card-text class="code-block-header pa-3 pr-6 rounded-t"
      ><v-row>
        <v-col cols="3" class="text-left d-flex flex-row">
          <v-select
            :disabled="disable"
            density="compact"
            class="d-flex align-self-center"
            v-model="language"
            :items="Object.keys(snippetLanguage)"
            variant="outlined"
          ></v-select>
        </v-col>
        <v-col class="text-right d-flex flex-row" cols="9">
          <v-btn
            class="ml-auto pr-0 align-self-center"
            variant="text"
            prepend-icon="mdi-content-copy"
            @click.stop="copySnippet()"
          >
            COPY
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card rounded="0">
      <pre :class="`language-${snippetLanguage[language]} no-margin`">
<code ref="codeBlock">
{{ code }}
</code>
</pre>
    </v-card>
  </v-card>
</template>

<script>
import { systemMessages } from "@/mixins/SystemMessages";
import { copyToClipboard } from "@/mixins/CopyToClipboard";
import Prism from "prismjs";

// Import the Prism CSS theme
import "prismjs/themes/prism.css";

// Import required language components in the proper order.
import "prismjs/components/prism-json";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-markup-templating";
import "prismjs/components/prism-php";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-python";
import "prismjs/components/prism-r";

export default {
  name: "CodeBlock",
  mixins: [systemMessages],
  data: () => ({
    snippetLanguage: {
      CURL: "php",
      Guzzle: "php",
      Axios: "javascript",
      Python: "python",
      "R Project": "r",
      JSON: "json",
    },
    language: null,
  }),
  props: {
    initialLanguage: {
      type: String,
      required: true,
      default: null,
    },
    code: {
      type: String,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    omitLanguages: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
  },
  computed: {},
  methods: {
    copySnippet() {
      // if (!this.disable) {
      copyToClipboard(this.code);
      this.successPill({
        icon: "mdi-content-copy",
        title: "Copied to clipboard",
        showClose: false,
      });
      // }
    },
  },
  created() {
    // remove langauges we do not need
    for (let i = 0; i < this.omitLanguages.length; i++) {
      delete this.snippetLanguage[this.omitLanguages[i]];
    }
    // Set the language here, before mounting
    this.language = this.initialLanguage || "Axios";
  },
  mounted() {
    Prism.highlightElement(this.$refs.codeBlock);
  },
  watch: {
    code() {
      this.$nextTick(() => {
        Prism.highlightElement(this.$refs.codeBlock);
      });
    },
    language() {
      this.$emit("update:languageChanged", this.language);
      this.$nextTick(() => {
        Prism.highlightElement(this.$refs.codeBlock);
      });
    },
  },
};
</script>

<style scoped>
pre {
  overflow: auto;
}
.no-margin {
  margin: 0 !important;
}
.code-block-header {
  background-color: #ccccccd1;
}
</style>
