<template>
  <div v-if="load" class="pt-0">
    <!-- This can be uncommented if data validation is needed again -->
    <!-- <v-alert type="error" v-if="dataErrorCanvasID === chartConfig.canvasID">Mixed % and non-% data / missing data</v-alert> -->

    <!-- Bar Chart -->
    <div
      v-if="item.chartType === 'Bar'"
      :class="{
        centerChart: !$store.state.config.userProfile.full_screen_report_view,
      }"
    >
      <div
        :id="'chartContainer_' + chartConfig.canvasID"
        v-if="displayChart"
        class="chart-container"
        :style="{
          position: 'relative',
          height: computedChartHeight,
          width: computedChartWidth,
        }"
      >
        <BarChart
          :chart-id="chartConfig.canvasID"
          :chartConfig="chartConfig"
          @data-error="setDataErrorAlert"
        />
      </div>
    </div>
    <!-- Pyramid Chart -->
    <div
      v-if="item.chartType === 'Pyramid'"
      :class="{
        centerChart: !$store.state.config.userProfile.full_screen_report_view,
      }"
    >
      <div
        :id="'chartContainer_' + chartConfig.canvasID"
        v-if="displayChart"
        class="chart-container"
        :style="{
          position: 'relative',
          height: computedChartHeight,
          width: computedChartWidth,
        }"
      >
        <PyramidChartChart
          :chart-id="chartConfig.canvasID"
          :chartConfig="chartConfig"
          :stacked="true"
          @data-error="setDataErrorAlert"
        />
      </div>
    </div>
    <!-- Horizontal Bar Chart -->
    <div
      v-if="item.chartType === 'Horizontal Bar'"
      :class="{
        centerChart: !$store.state.config.userProfile.full_screen_report_view,
      }"
    >
      <div
        :id="'chartContainer_' + chartConfig.canvasID"
        v-if="displayChart"
        class="chart-container"
        :style="{
          position: 'relative',
          height: computedChartHeight,
          width: computedChartWidth,
        }"
      >
        <HorizontalBarChart
          :chart-id="chartConfig.canvasID"
          :chartConfig="chartConfig"
          @data-error="setDataErrorAlert"
        />
      </div>
    </div>
    <!-- Stacked Bar Chart -->
    <div
      v-if="item.chartType === 'Stacked Bar'"
      :class="{
        centerChart: !$store.state.config.userProfile.full_screen_report_view,
      }"
    >
      <div
        :id="'chartContainer_' + chartConfig.canvasID"
        v-if="displayChart"
        class="chart-container"
        :style="{
          position: 'relative',
          height: computedChartHeight,
          width: computedChartWidth,
        }"
      >
        <BarChart
          :chart-id="chartConfig.canvasID"
          :chartConfig="chartConfig"
          :stacked="true"
          @data-error="setDataErrorAlert"
        />
      </div>
    </div>
    <!-- Line Chart -->
    <div
      v-if="item.chartType === 'Line'"
      :class="{
        centerChart: !$store.state.config.userProfile.full_screen_report_view,
      }"
    >
      <div
        :id="'chartContainer_' + chartConfig.canvasID"
        v-if="displayChart"
        class="chart-container"
        :style="{
          position: 'relative',
          height: computedChartHeight,
          width: computedChartWidth,
        }"
      >
        <LineChart
          :chart-id="chartConfig.canvasID"
          :chartConfig="chartConfig"
          @data-error="setDataErrorAlert"
        />
      </div>
    </div>
    <v-card-actions>
      <p class="min-accessible-size">Source: {{ item.itemSource }}</p>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2 button-icon-text"
        variant="elevated"
        color="blue-grey"
        @click="exportData"
        :aria-label="
          isDemoAccount
            ? 'Downloading unavailable during the trial'
            : 'Download ' + getFileName()
        "
      >
        <v-icon size="large">mdi-microsoft-excel</v-icon>
        <v-tooltip activator="parent" location="top">{{
          isDemoAccount
            ? "Downloading unavailable during the trial"
            : "Download " + getFileName()
        }}</v-tooltip>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import BarChart from "@/components/BarChart.vue";
import PyramidChartChart from "@/components/PyramidChart.vue";
import HorizontalBarChart from "@/components/HorizontalBarChart.vue";
import LineChart from "@/components/LineChart.vue";
import { exportCSVFile } from "@/mixins/ExportCSVFile";
import { useDisplay } from "vuetify";

export default {
  name: "CHARTS",
  data: () => ({
    exportCSVFile: exportCSVFile,
    chartIndicatorValues: null,
    colours: ["#913BAD", "#51627C", "#8692A4"],
    displayChart: false,
    chartAxis: null,
    chartConfig: {
      labels: [],
      datasets: [],
    },
    dataErrorCanvasID: null,
    displayProps: null,
  }),
  components: {
    BarChart,
    PyramidChartChart,
    LineChart,
    HorizontalBarChart,
  },
  computed: {
    isDemoAccount() {
      return this.$store.state.config.customClientConfig.client_type === "demo"
        ? true
        : false;
    },
    screenWidth() {
      if (this.useScreenWidth) {
        return this.useScreenWidth;
      }

      return this.displayProps.width;
    },
    computedChartHeight() {
      if (this.chartHeight) {
        return this.chartHeight;
      }
      if (this.$vuetify.display.xs) {
        return "auto";
      }
      if (this.$vuetify.display.sm) {
        return "auto";
      }
      if (this.$vuetify.display.md) {
        return "60vh";
      }
      if (this.$vuetify.display.lg) {
        return "60vh";
      }
      if (this.$vuetify.display.xl) {
        return "60vh";
      }
      return "60vh";
    },
    computedChartWidth() {
      // if width was passed then it's another way of viewing report e.g. discovery tool
      if (this.chartWidthProp) return this.chartWidthProp;

      //default chart width - 96vw converted to px value for available screen width
      const defaultVwPx = (96 * this.screenWidth) / 100;

      let width = null;
      const isFullScreenViewMode =
        this.$store.state.config.userProfile.full_screen_report_view;

      //if report view is NOT full screen mode
      if (!isFullScreenViewMode) {
        const chartType = this.item.chartType;

        const fixedWidthRules = {
          "Horizontal Bar": this.screenWidth > 800 ? "800" : defaultVwPx,
          Pyramid: this.screenWidth > 800 ? "1200" : defaultVwPx,
          "Stacked Bar": this.screenWidth > 800 ? "800" : defaultVwPx,
          Line: this.screenWidth > 1400 ? "1400" : defaultVwPx,
        };

        if (chartType === "Bar") {
          const datasetCount = this.chartConfig.labels.length;
          width = ["720", "870", "1020", "1400"][Math.min(datasetCount, 4) - 1];

          width = this.screenWidth > parseInt(width) ? width : defaultVwPx;
        }

        // If width is still unset, use the width guide for other chart types
        width = width || fixedWidthRules[chartType];
      }

      return (width ?? defaultVwPx) + "px";
    },
  },
  props: {
    load: {
      type: Boolean,
      required: false,
      default: false,
    },
    reportThemeObject: {
      type: Object,
      default() {
        return {};
      },
    },
    indicatorValues: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    comparators: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    comparatorsList: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    chartWidthProp: {
      type: String,
      required: false,
      default: "",
    },
    chartHeight: {
      type: String,
      required: false,
    },
    useScreenWidth: {
      type: Number,
      required: false,
    },
  },
  created() {
    this.displayProps = useDisplay();
  },
  methods: {
    chartTitle(title) {
      title = !this.item.chartDescription
        ? ""
        : this.item.chartDescription.replace("<p>", "").replace("</p>", "");
      // if pyramid than add the comparator name into the chart title too
      if (
        this.item.chartType === "Pyramid" &&
        this.item.chartDescription !== ""
      ) {
        var comparatorKeyPair = {
          C1: 0,
          C2: 1,
          C3: 2,
        };
        return title.concat(
          " for " +
            this.comparatorsList[comparatorKeyPair[this.item.comparator]]
              .area_name,
        );
      }
      return title;
    },
    pyramidLables() {
      let labels = [];
      for (var i = 0; i < this.item.indicators.length; i++) {
        if (i % 2 === 0) continue;
        labels.push(this.item.indicators[i].y_label);
      }
      return labels;
    },
    getFileName(underscores = false) {
      let filename =
        `Local Insight ${this.reportThemeObject.name} ${this.item.itemName || ""}`.trim();

      return underscores ? filename.replaceAll(" ", "_") : filename;
    },
    setChartIndicatorValues() {
      let requiredIndicatorValues = {};

      // get the HTML tables indicators
      if (this.item.syncChartWithHTMLTableRef !== null) {
        for (let i = 0; i < this.reportThemeObject.config.length; i++) {
          if (
            this.reportThemeObject.config[i].indicatorHTMLTableRef ===
            this.item.syncChartWithHTMLTableRef
          ) {
            for (
              let j = 0;
              j < this.reportThemeObject.config[i].indicators.length;
              j++
            ) {
              for (let key in this.indicatorValues) {
                let split = key.split(".");
                if (
                  this.reportThemeObject.config[i].indicators[j]
                    .indicatorCode === split[1] &&
                  this.reportThemeObject.config[i].indicators[j].date ===
                    split[2]
                ) {
                  requiredIndicatorValues[key] = this.indicatorValues[key];
                }
              }
            }
          }
        }
      } else {
        // else we are using the manually selected chart indicators
        for (let j = 0; j < this.item.indicators.length; j++) {
          for (let key in this.indicatorValues) {
            let split = key.split(".");
            if (
              this.item.indicators[j].indicatorCode === split[1] &&
              this.item.indicators[j].date === split[2]
            ) {
              requiredIndicatorValues[key] = this.indicatorValues[key];
            }
          }
        }
      }
      this.chartIndicatorValues = requiredIndicatorValues;
    },
    exportData() {
      if (this.isDemoAccount) {
        return;
      }
      var data = [];
      let i;
      var row;
      let filename = this.getFileName(true);

      // First row of headers
      row = {
        header: this.item.chartType === "Line" ? "Date" : "Indicator",
      };

      for (i = 0; i < this.comparators.length; i++) {
        row[this.comparators[i]] = this.comparitorName(this.comparators[i]);
      }

      data.push(row);

      // Organise data into a 2d grid
      var grid = [];
      for (var key in this.chartIndicatorValues) {
        // ignore null values
        if (!this.chartIndicatorValues[key]) {
          continue;
        }
        var split = key.split(".");

        // Find the date coverage text for the indicator code + date
        for (var indicatorIndex in this.item.indicators) {
          if (
            this.item.indicators[indicatorIndex].indicatorCode == split[1] &&
            this.item.indicators[indicatorIndex].date == split[2]
          ) {
            var dateCoverageText =
              this.item.chartType === "Line"
                ? this.item.indicators[indicatorIndex].dateCoverageText
                : this.item.indicators[indicatorIndex].indicatorName;
          }
        }

        var areaCode = split[0];
        var indicator = split[1] + "." + split[2];

        // Ignore counts if dynamic indicator
        if (split[1].includes("*")) {
          if (typeof split[4] === "undefined") {
            continue;
          }
        }

        // Init array if it doesn't exist already
        if (typeof grid[indicator] === "undefined") {
          grid[indicator] = [];
          grid[indicator].name = name;
        }

        grid[indicator].name = dateCoverageText;

        grid[indicator][areaCode] = this.chartIndicatorValues[key].replace(
          /[,]/g,
          "",
        );
      }

      // Build csv data
      for (indicator in grid) {
        row = {};
        // Left side are indicator names
        row[grid[indicator].name] = grid[indicator].name;

        for (areaCode in this.comparators) {
          var value = grid[indicator][this.comparators[areaCode]];
          // Make sure there is an empty cell if there is no value, so the columns line up
          if (!value) {
            value = " ";
          }
          row[this.comparators[areaCode]] = value;
        }
        data.push(row);
      }

      this.exportCSVFile(null, data, filename);
    },
    buildChartConfig() {
      // if the chart is synced with a HTML table
      if (this.item.syncChartWithHTMLTableRef !== null) {
        if (this.item.inverseChartData) {
          this.invertedChartConfig();
        } else {
          this.nonInvertedChartConfig();
        }
        // if a chart is NOT synced with a HTML table
      } else {
        // is this manual data?
        if (this.item.chartYCommaSeperatedValues !== null) {
          let datasets = null;
          // are we looking at json?
          if (this.item.chartYCommaSeperatedValues.includes("{")) {
            datasets = JSON.parse(this.item.chartYCommaSeperatedValues);

            // Default colours if not specified
            var colourCounter = 0;
            for (let i = 0; i < datasets.length; i++) {
              if (!datasets[i].backgroundColor) {
                datasets[i].backgroundColor = this.colours[colourCounter];
              }
              colourCounter++;
              // Go back to first colour if out of colours
              colourCounter %= this.colours.length;
            }
            // nah...just a straight forward list
          } else {
            datasets = [
              {
                label: "",
                backgroundColor: this.colours[0],
                data: this.item.chartYCommaSeperatedValues.split(","),
              },
            ];
          }
          this.chartConfig = {
            comparator: this.item.comparator,
            left_label: this.item.left_label,
            right_label: this.item.right_label,
            canvasID: this.item.chartRef,
            labels:
              this.item.chartType === "Pyramid"
                ? this.pyramidLables()
                : this.item.chartXCommaSeperatedValues.split(","),
            datasets: datasets,
            title: this.chartTitle(this.item.chartDescription),
            display_data_point_markers: this.item.display_data_point_markers,
          };
          // change some colours if this is a line chart
          if (this.item.chartType === "Line") {
            for (let i = 0; i < this.chartConfig.datasets.length; i++) {
              this.chartConfig.datasets[i].borderColor =
                this.chartConfig.datasets[i].backgroundColor;
              this.chartConfig.datasets[i].backgroundColor = "transparent";
              this.chartConfig.datasets[i].pointBackgroundColor = "#000000";
              this.chartConfig.datasets[i].tension = 0.2;
            }
          }
          // so this is looking like manually selected indicators
        } else {
          if (this.item.inverseChartData) {
            this.invertedChartConfig();
          } else {
            this.nonInvertedChartConfig();
          }
        }
      }
    },
    nonInvertedChartConfig() {
      // catch this just incase this flag slips the net (pyramids don't do non-inverted!)
      if (this.item.chartType === "Pyramid") {
        this.invertedChartConfig();
        return;
      }
      // set these
      var split = [];
      var tempLabels = [];
      var tempDatasets = [];
      var colourCounter = 0;
      var indicatorValue;
      var label;
      // sort out the labels
      for (var key in this.chartIndicatorValues) {
        split = key.split(".");
        if (typeof tempLabels[split[0]] === "undefined") {
          tempLabels[split[0]] = this.comparitorName(split[0]);
        }
      }
      // sort out the datasets
      for (key in this.chartIndicatorValues) {
        split = key.split(".");

        // Find the date coverage text for the indicator code + date
        for (var indicatorIndex in this.item.indicators) {
          if (
            this.item.indicators[indicatorIndex].indicatorCode == split[1] &&
            this.item.indicators[indicatorIndex].date == split[2]
          ) {
            // short or long name?
            if (
              this.item.indicators[indicatorIndex].use_short_indicator_name &&
              this.item.indicators[indicatorIndex].shortName !== null
            ) {
              label = this.item.indicators[indicatorIndex].shortName;
            } else {
              label = split[3];
            }

            // add the date to the label?
            var dateCoverageText = this.item.concatenate_dates
              ? " - " + this.item.indicators[indicatorIndex].dateCoverageText
              : "";
            break;
          }
        }

        if (
          typeof tempDatasets[split[1] + "." + split[2] + "." + label] ===
          "undefined"
        ) {
          tempDatasets[split[1] + "." + split[2] + "." + label] = {
            label: label + " - " + dateCoverageText,
            backgroundColor: this.colours[colourCounter],
            data: [],
          };
          // clean up trailing slash
          tempDatasets[split[1] + "." + split[2] + "." + label].label =
            tempDatasets[split[1] + "." + split[2] + "." + label].label.replace(
              / - $/,
              "",
            );

          if (this.item.chartType === "Line") {
            tempDatasets[
              split[1] + "." + split[2] + "." + label
            ].backgroundColor = "transparent";
            tempDatasets[split[1] + "." + split[2] + "." + label].borderColor =
              this.colours[colourCounter];
            tempDatasets[
              split[1] + "." + split[2] + "." + label
            ].pointBackgroundColor = "#000000";
          }
          // add the data
          for (let keyII in this.chartIndicatorValues) {
            let splitII = keyII.split(".");
            if (
              split[1] + "." + split[2] + "." + label ===
              splitII[1] + "." + splitII[2] + "." + label
            ) {
              if (keyII.includes("*")) {
                indicatorValue = this.chartIndicatorValues[keyII + ".rate"];
              } else {
                indicatorValue = this.chartIndicatorValues[keyII];
              }
              if (indicatorValue) {
                indicatorValue = indicatorValue.replace(/,/g, "");
              }
              if (typeof indicatorValue !== "undefined") {
                tempDatasets[split[1] + "." + split[2] + "." + label].data.push(
                  indicatorValue,
                );
              }
            }
          }
          colourCounter++;
          // Go back to first colour if out of colours
          colourCounter %= this.colours.length;
        }
      }
      this.chartConfig = {
        comparator: this.item.comparator,
        left_label: this.item.left_label,
        right_label: this.item.right_label,
        canvasID: this.item.chartRef,
        labels:
          this.item.chartType === "Pyramid"
            ? this.pyramidLables()
            : Object.values(tempLabels),
        datasets: Object.values(tempDatasets),
        title: this.chartTitle(this.item.chartDescription),
        chartYLabel: this.item.chartYLabel,
        display_data_point_markers: this.item.display_data_point_markers,
      };
      // Remove all elements apart from first
      if (!this.item.showComparators) {
        this.chartConfig.labels.splice(1);
        for (let i = 0; i < this.chartConfig.datasets.length; i++) {
          this.chartConfig.datasets[i].data.splice(1);
        }
      }
    },
    invertedChartConfig() {
      // set these
      var split = [];
      var tempLabels = [];
      var tempDatasets = [];
      var tempIndicatorValueKeys = [];
      var colourCounter = 0;
      var indicatorValue;
      var label = null;

      // sort out the labels
      for (var key in this.chartIndicatorValues) {
        // ignore null values
        if (!this.chartIndicatorValues[key]) {
          continue;
        }

        split = key.split(".");

        // Find the date coverage text for the indicator code + date and figure out if we need a short name as a label
        for (var indicatorIndex in this.item.indicators) {
          if (
            this.item.indicators[indicatorIndex].indicatorCode == split[1] &&
            this.item.indicators[indicatorIndex].date == split[2]
          ) {
            // short or long name?
            if (
              this.item.indicators[indicatorIndex].use_short_indicator_name &&
              this.item.indicators[indicatorIndex].shortName !== null
            ) {
              label = this.item.indicators[indicatorIndex].shortName;
            } else {
              label = split[3];
            }
            // add the date to the label?
            var dateCoverageText = this.item.concatenate_dates
              ? " - " + this.item.indicators[indicatorIndex].dateCoverageText
              : "";
          }
        }
        if (
          typeof tempLabels[split[1] + "." + split[2] + "." + label] ===
          "undefined"
        ) {
          if (this.item.timeSeries) {
            tempLabels[split[1] + "." + split[2] + "." + label] =
              dateCoverageText.replace(/^\s*-/, "");
          } else {
            tempLabels[split[1] + "." + split[2] + "." + label] =
              label + dateCoverageText;
          }
          tempIndicatorValueKeys.push(split[1] + "." + split[2] + "." + label);
        }
      }
      // sort out the datasets
      for (key in this.chartIndicatorValues) {
        split = key.split(".");
        if (typeof tempDatasets[split[0]] === "undefined") {
          tempDatasets[split[0]] = {
            label: this.comparitorName(split[0]),
            backgroundColor: this.colours[colourCounter],
            data: [],
          };
          if (this.item.chartType === "Line") {
            tempDatasets[split[0]].backgroundColor = "transparent";
            tempDatasets[split[0]]["borderColor"] = this.colours[colourCounter];
            tempDatasets[split[0]]["pointBackgroundColor"] = "#000000";
            tempDatasets[split[0]]["tension"] = 0.2; // adjust this to make the lines more curvy (the lower the less curvy)
          }
          // add the data
          for (let i = 0; i < tempIndicatorValueKeys.length; i++) {
            // dynamic rate?
            if (tempIndicatorValueKeys[i].includes("*")) {
              indicatorValue =
                this.chartIndicatorValues[
                  split[0] + "." + tempIndicatorValueKeys[i] + ".rate"
                ];
            } else {
              indicatorValue =
                this.chartIndicatorValues[
                  split[0] + "." + tempIndicatorValueKeys[i]
                ];
            }
            if (indicatorValue) {
              indicatorValue = indicatorValue.replace(/,/g, "");
            }
            tempDatasets[split[0]].data.push(indicatorValue);
          }
          colourCounter++;
          // Go back to first colour if out of colours
          colourCounter %= this.colours.length;
        }
      }
      this.chartConfig = {
        comparator: this.item.comparator,
        left_label: this.item.left_label,
        right_label: this.item.right_label,
        canvasID: this.item.chartRef,
        labels:
          this.item.chartType === "Pyramid"
            ? this.pyramidLables()
            : Object.values(tempLabels),
        datasets: Object.values(tempDatasets),
        title: this.chartTitle(this.item.chartDescription),
        chartYLabel: this.item.chartYLabel,
        display_data_point_markers: this.item.display_data_point_markers,
      };
      // Remove all elements apart from first
      if (!this.item.showComparators) {
        this.chartConfig.datasets.splice(1);
      }
    },
    comparitorName(comparator) {
      for (let i = 0; i < this.comparatorsList.length; i++) {
        if (this.comparatorsList[i].area_code === comparator) {
          return this.comparatorsList[i].area_name;
        }
      }
    },
    setDataErrorAlert(canvasID) {
      this.dataErrorCanvasID = canvasID;
    },
  },
  watch: {
    // indicatorValues: {
    //   handler() {
    //     this.buildChartConfig();
    //   },
    //   deep: true,
    // },
    load: {
      handler() {
        if (this.load) {
          this.setChartIndicatorValues();
          this.buildChartConfig();
          this.displayChart = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.centerChart {
  display: flex;
  justify-content: center;
}
</style>
