<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    style="overflow-y: hidden"
    scrollable
    persistent
  >
    <v-card style="max-height: 93vh; overflow-y: hidden">
      <v-toolbar
        :style="
          'background-color: ' +
          this.$store.state.config.siteConfig.toolbar_colour +
          '; color:#ffffff;'
        "
        class="text-h6 text-center"
        max-height="65px"
      >
        <v-spacer>
          <v-toolbar-title class="pa-2 text-start" style="white-space: normal">
            <v-btn tile icon dark @click="$emit('close')" aria-label="Close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            Custom Area Details
          </v-toolbar-title>
        </v-spacer>
      </v-toolbar>
      <v-card-text
        v-if="areaDetails.Name === ''"
        class="d-flex justify-center align-center pa-6"
        style="height: 50vh"
      >
        <v-progress-circular indeterminate size="160" color="#51627C">
          Getting details
        </v-progress-circular>
      </v-card-text>
      <v-card-text v-else class="py-4">
        <div>
          <div v-for="(value, key) in areaDetails" :key="key">
            <v-card-text class="ma-0 pa-0">
              <b>{{ key }}</b>
            </v-card-text>
            <div>
              {{ key === "Created" ? getFormattedValue(value) : value }}
            </div>
            <v-divider class="mb-6" />
          </div>
        </div>
        <v-card-text class="ma-0 pa-0">
          <b> Constituent Areas </b>
        </v-card-text>
        <v-card-text class="area-list">
          <div
            v-for="region in constituentAreas"
            class="region-item"
            :key="region.name"
          >
            {{ region }}
          </div>
        </v-card-text>
        <v-divider class="mb-6" />
        <v-card-text class="ma-0 pa-0">
          <b> Services </b>
        </v-card-text>
        <div v-if="gettingDetails">
          <v-progress-circular indeterminate color="#51627C" size="20" />
          Fetching Details...
        </div>
        <div v-else-if="serviceDetails.length === 0">No services</div>
        <div v-else class="d-flex justify-between align-center">
          <div class="mr-2">
            Contains {{ serviceDetails.length }} services -
          </div>
          <v-btn
            color="success"
            tile
            variant="elevated"
            @click="downloadServiceDetails"
            :aria-label="
              isDemoAccount
                ? 'Downloading unavailable during the trial'
                : 'Download Service Details'
            "
          >
            <v-tooltip activator="parent" location="top">{{
              isDemoAccount
                ? "Downloading unavailable during the trial"
                : "ownload Service Details"
            }}</v-tooltip>
            Download Services</v-btn
          >
        </div>
      </v-card-text>
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            tile
            variant="elevated"
            @click="$emit('close')"
            aria-label="close"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate } from "@/mixins/FormatDate";
import { exportCSVFile } from "@/mixins/ExportCSVFile";

export default {
  name: "customAreaDetails",
  computed: {
    isDemoAccount() {
      return this.$store.state.config.customClientConfig.client_type === "demo"
        ? true
        : false;
    },
    dialog: {
      get() {
        return this.display ? true : false;
      },
    },
  },
  data: () => ({
    areaDetails: {
      Name: "",
      Category: "",
      Description: "",
      Type: "",
      Created: "",
    },
    constituentAreas: [],
    serviceDetails: 0,
    headers: ["custom area name", "postcode", "type"],
    gettingDetails: false,
  }),
  components: {},
  props: {
    display: Boolean,
    area: Object,
  },
  mounted() {},
  methods: {
    getFormattedValue(value) {
      return formatDate(value);
    },
    getCustomAreaServices(areaId) {
      this.gettingDetails = true;
      // get the services for the area
      this.$axios
        .get("/custom-area-services/" + areaId)
        .then((response) => {
          this.gettingDetails = false;
          this.headers = response.data.headers;
          this.serviceDetails = response.data.rows;
        })
        .catch(
          function (error) {
            this.gettingDetails = false;
            console.log(error);
          }.bind(this),
        );
    },
    downloadServiceDetails() {
      if (this.isDemoAccount) {
        return;
      }
      // download the service details
      let filename = this.areaDetails.Name + "_services.csv";

      // take a non reactive copy of the service details
      let serviceDetails = JSON.parse(JSON.stringify(this.serviceDetails));

      exportCSVFile(this.headers, serviceDetails, filename);
    },
  },
  watch: {
    // if we get a new area, update the dialog
    area: function (newVal) {
      if (!newVal) {
        this.areaDetails["Name"] = "";
        this.areaDetails["Category"] = "";
        this.areaDetails["Description"] = "";
        this.areaDetails["Type"] = "";
        this.areaDetails["Created"] = "";
        this.constituentAreas = [];
        return;
      }
      // get the basic details
      this.areaDetails["Name"] = newVal.name;
      this.areaDetails["Category"] = newVal.custom_areas_category_name;
      this.areaDetails["Description"] = newVal.description
        ? newVal.description
        : "None";
      this.areaDetails["Type"] = newVal.type_id;
      this.areaDetails["Created"] = newVal.created_at;

      // get the constituent areas
      this.constituentAreas = newVal.custom_areas_areas;

      // get the service details
      this.getCustomAreaServices(newVal.id);
    },
  },
};
</script>

<style scoped>
.region-item {
  display: inline-block;
  border-color: #e6e6e6;
  border-style: solid;
  border-radius: 10px;
  padding: 2px;
  background-color: #e6e6e6;
  margin: 2px;
}

.area-list {
  max-height: 27vh;
  overflow-y: auto;
}
</style>
