<template>
  <DynamicDialog
    :show="dialog"
    @update:showDyamicDialog="(state) => (dialog = state)"
    @dialogOk="addIndicatorToThemes"
    @dialogCancel="dialog = false"
    max-width="800"
    okBtnColor="success"
    cancelBtnColor="none"
    height="400px"
    cancelBtnVariant="text"
    contentClass="pa-0"
    :okBtnDisabled="!validPickedThemeIds?.length"
    :okBtnSpinner="addingThemes"
  >
    <template #title>Add indicator to theme</template>
    <template #content>
      <v-card-text v-if="!themes?.length" class="text-center text-subtitle-1">
        No have no themes created
      </v-card-text>
      <v-card-actions
        v-for="theme in themes"
        :key="theme.id"
        :class="{
          theme:
            !isIndicatorPreviouslyAddedToTheme(theme.id) && !validatingThemes,
        }"
        class="w-100 py-0 px-1 border-b-thin"
        style="min-height: 44px"
        @click.prevent="selectTheme(theme.id)"
      >
        <v-checkbox
          v-model="pickedThemeIds"
          :disabled="
            isIndicatorPreviouslyAddedToTheme(theme.id) || validatingThemes
          "
          color="primary"
          :value="theme.id"
          hide-details
        />
        <div
          style="max-width: 55%"
          :class="{ disabledText: isIndicatorPreviouslyAddedToTheme(theme.id) }"
        >
          {{ theme.name }}
        </div>
        <v-spacer />
        <v-chip
          v-if="isIndicatorPreviouslyAddedToTheme(theme.id)"
          variant="elevated"
          color="warning"
          class="mr-2"
          elevation="0"
          >Previously added to theme</v-chip
        >
        <v-chip elevation="0" class="mr-2" size="small">{{
          theme.number_of_indicators
        }}</v-chip>
      </v-card-actions>
    </template>
    <template #okBtnTitle>
      add to {{ validPickedThemeIds.length || "" }} theme{{
        validPickedThemeIds.length > 1 ? "s" : ""
      }}
    </template>
  </DynamicDialog>
</template>
<script>
import DynamicDialog from "@/components/DynamicDialog.vue";
import { systemMessages } from "@/mixins/SystemMessages";
import { dynamicEnding } from "@/mixins/DynamicEnding";

export default {
  name: "ThemesManagerSearchAddIndicator",
  components: {
    DynamicDialog,
  },
  mixins: [systemMessages, dynamicEnding],
  data: () => ({
    pickedThemeIds: [],
    themesAlreadyContainingIndicator: [],
    validatingThemes: false,
    addingThemes: false,
  }),
  props: {
    addIndicatorDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    selectedIndicator: {
      required: true,
      default: null,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.addIndicatorDialog;
      },
      set(val) {
        this.$emit("update:addIndicatorDialog", val);
      },
    },
    themes: {
      get() {
        return this.$store.getters["themesManager/themes"];
      },
      set(val) {
        this.$store.commit("themesManager/setThemes", val);
      },
    },
    validPickedThemeIds() {
      return this.pickedThemeIds.filter(
        (id) => !this.themesAlreadyContainingIndicator.includes(id),
      );
    },
  },
  methods: {
    async getIndicatorThemes() {
      try {
        this.validatingThemes = true;

        const response = await this.$axios.get(
          `/themes-indicator-flag/${this.selectedIndicator?.indicator_code}`,
        );
        this.themesAlreadyContainingIndicator = this.$cloneDeep(response.data);
        this.pickedThemeIds = this.$cloneDeep(response.data);

        this.validatingThemes = false;
      } catch (error) {
        this.errorMsg({
          title: "There was a problem validating your themes",
          message: error.response?.data?.message,
        });
      }
    },
    async addIndicatorToThemes() {
      const data = {
        themeIds: this.validPickedThemeIds,
        indicator_code: this.selectedIndicator?.indicator_code,
      };

      this.addingThemes = true;
      try {
        await this.$axios.post("/themes-manager-indicators", data);

        this.successPill({
          title: `Successfully added indicator to ${this.pickedThemeIds.length} themes`,
        });
        this.$emit("reloadThemesManager");
      } catch (error) {
        this.errorMsg({
          title: "There was an error adding the indicator to the themes",
          message: error.response?.data?.message,
        });
      } finally {
        // reset the dialog
        this.dialog = false;
      }
    },
    isIndicatorPreviouslyAddedToTheme(themeID) {
      return this.themesAlreadyContainingIndicator.includes(themeID);
    },
    selectTheme(themeID) {
      if (
        this.validatingThemes ||
        this.isIndicatorPreviouslyAddedToTheme(themeID)
      )
        return;

      if (this.pickedThemeIds.includes(themeID)) {
        this.pickedThemeIds = this.pickedThemeIds.filter(
          (id) => id !== themeID,
        );
      } else {
        this.pickedThemeIds.push(themeID);
      }
    },
  },
  watch: {
    async dialog(open) {
      if (open) {
        // if no themes, fetch them
        if (!this.themes.length) {
          const response = await await this.$axios.get(
            "/themes-manager-themes",
          );
          this.themes = response.data;
        }

        this.getIndicatorThemes();
      } else {
        // reset dialog
        this.addingThemes = false;
        this.pickedThemeIds = [];
        this.themesAlreadyContainingIndicator = [];
      }
    },
  },
};
</script>
<style scoped>
.theme:hover {
  background-color: #bfe0f8;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.disabledText {
  opacity: 0.5 !important;
}
</style>
