<template>
  <HeaderModule
    title="Themes & Indicators"
    icon="card-multiple-outline"
    width="1440px"
    description="Manage the themes and indicators used in the Map and Dashboards"
    :defaultTab="defaultTab"
    :key="headerModuleReloadKey"
    v-model:tabs="tabs"
    @scrollTabEvent="handleTabContentScroll"
  >
    <template #manage>
      <ThemesManagerManage ref="manage" />
    </template>

    <template #search>
      <ThemesManagerSearch
        ref="search"
        @reloadThemesManager="reloadThemesManager"
      />
    </template>
  </HeaderModule>
</template>
<script>
import HeaderModule from "@/components/HeaderModule.vue";
import ThemesManagerManage from "@/components/ThemesManagerManage.vue";
import ThemesManagerSearch from "@/components/ThemesManagerSearch.vue";

export default {
  name: "ThemesManagerV2",
  components: { HeaderModule, ThemesManagerManage, ThemesManagerSearch },
  data: () => ({
    tabs: [
      { title: "Manage Themes", value: "manage", route: "manage" },
      { title: "Search Indicators", value: "search", route: "search" },
    ],
    defaultTab: "manage",
    headerModuleReloadKey: 0,
  }),
  methods: {
    // handle lazy loading when scrolling through catalogue
    handleTabContentScroll(tab) {
      if (tab === "search") {
        this.$refs.search.getPaginatedIndicators();
      }
    },
    reloadThemesManager() {
      const themes = this.$refs.manage?.themes;
      const selectedTheme = this.$refs.manage?.selectedThemeId;

      if (themes?.length) {
        this.$refs.manage.fetchThemes();
      }
      if (selectedTheme) {
        this.$refs.manage.fetchindicators(selectedTheme);
      }
    },
  },
};
</script>
